import React, { useState, useEffect } from "react";
import { 
  //InputNumber, 
  Form, Input, Button, Row, Col, notification
 } from "antd";
//import { TagOutlined, EnvironmentOutlined, PhoneOutlined } from "@ant-design/icons";
import { updateCategoryApi } from "../../../../api/category";
import { getAccessToken } from "../../../../api/auth";
//import { numberValidation } from "../../../../utils/formValidation";

import "./EditCategoryForm.scss";

const { TextArea } = Input;

export default function EditCategoryForm(props) {
  const { category, setIsVisibleModal, setReloadCategories } = props;
  const [categoryData, setCategoryData] = useState({});

  const [modifDataToValid, setModifDataToValid] = useState({
    phone: false,
  });

  const [validData, setValidData] = useState({
    email: false,
  });

  //trae los datos de tipo de proveedor
  useEffect(() => {
    setCategoryData({
      name: category.name,
      description: category.description,
    });
  }, [category]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!categoryData.name) {
      notification["error"]({
        message: "Obligatorio: Nombre.",
      });
      errorExists = true;
    } else {
    /*
    else if (modifDataToValid.phone) {
      if (!validData.phone) {
        //solo si el email está vacio, falta la validación en el return de EditForm
        notification["error"]({
          message: "Ingreso de teléfono inválido"
        });
        errorExists = true;
      }
    }*/
      errorExists = false;
    }
    return errorExists;
  };

  const updateCategory = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let categoryUpdate = categoryData; //es let porque se va actualizando

      updateCategoryApi(token, categoryUpdate, category._id).then(
        (result) => {
          if (
            result.message === "ERR_CONNECTION_REFUSED" ||
            result.message === "Failed to fetch" ||
            result.message === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
            setIsVisibleModal(true);
          } else if (result.code !== 200) {
            notification["error"]({
              message: result.message,
            });
            setIsVisibleModal(true);
          } else {
            notification["success"]({
              message: result.message, //el mensaje que viene del server
            });
            //console.log("respuesta: " + result.message);
            setIsVisibleModal(false);
            setReloadCategories(true);
          }
        }
      );
    }
  };

  return (
    <div className="edit-category-form">
      <EditForm
        categoryData={categoryData}
        setCategoryData={setCategoryData}
        updateCategory={updateCategory}
        modifDataToValid={modifDataToValid}
        setModifDataToValid={setModifDataToValid}
        validData={validData}
        setValidData={setValidData}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    categoryData,
    setCategoryData,
    updateCategory,
    //setModifDataToValid, modifDataToValid, validData, setValidData,
  } = props;

  //validaciones de inputs
  // const inputValidation = (e) => {
  //   const { type, description } = e.target;

  //   setCategoryData({
  //     ...categoryData,
  //     [name]: e.target.value,
  //   });

  //   setModifDataToValid({
  //     ...modifDataToValid,
  //     [description]: true,
  //   });

  //   if (description === "phone") {
  //     setValidData({
  //       ...validData,
  //       [description]: numberValidation(e.target),
  //     });
  //   }
  // };

  return (
    <Form className="form-edit" onSubmit={updateCategory}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {/* <Col span={12}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={departmentData.department_code}
              onChange={(e) => setDepartmentData({ ...departmentData, department_code: e })}
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col> */}
        <Col span={24}>
          <label className="control-label">
            <span className="control-required">* </span>Nombre
          </label>
          <Form.Item>
            <Input
              placeholder="Nombre"
              value={categoryData.name}
              onChange={(e) =>
                setCategoryData({ ...categoryData, name: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
            Descripción
          </label>
          <Form.Item>
          <TextArea
              maxLength={100}
              onChange={(e) =>
                setCategoryData({ ...categoryData, description: e.target.value })
              }
              value={categoryData.description}
              style={{ height: 120, resize: 'none' , marginBottom:20}}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateCategory}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
