import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import {
  //getAbsencesActiveApi,
  getBranchesActiveApi,
} from "../../../api/branch";
import ListBranches from "../../../components/Admin/Branches/ListBranches/ListBranches";
import {useBranch} from "../../../hooks/useBranch";

export default function Branches(props) {
  //const {setReloadBranch}=props;
  //const [branchId, setBranchId] = useState();
  const [branchesActive, setBranchesActive] = useState([]);
  const [branchesInactive, setBranchesInactive] = useState([]);
  const [reloadBranches, setReloadBranches] = useState(false);
  //const [businessId, setBusinessId] = useState(null);
  const token = getAccessToken();
  const {branch, changeBranch}=useBranch();
  //  const branchId = props.location.state? props.location.state.id : false;

  // useEffect(() => {
  //   setBusinessId( props.location.state? props.location.state.id : false);
  // }, [token, props.location.state]);

  useEffect(() => {
    getBranchesActiveApi(token, true).then((response) => {
      setBranchesActive(response.branches);
      // if(response.branches && response.branches.length === 0 && branch!==""){
      //   changeBranch("");
      // }
    });
    setReloadBranches(false);
  }, [token, reloadBranches]);

  useEffect(() => {
    getBranchesActiveApi(token, false).then((response) => {
      setBranchesInactive(response.branches);
    });
    setReloadBranches(false);
  }, [token, reloadBranches]);

  return (
    <div className="branch">
      <ListBranches
        setReloadBranches={setReloadBranches}
        branchesActive={branchesActive}
        branchesInactive={branchesInactive}
      />
    </div>
  );
}
