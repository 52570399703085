import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import { TagOutlined, DollarCircleOutlined } from "@ant-design/icons";
import { updateItemApi } from "../../../../api/billing/item";
import { getAccessToken } from "../../../../api/auth";
import { getCategoriesActiveApi } from "../../../../api/billing/category";
//import { minLengthValidation, numberValidation } from "../../../../utils/formValidation";

import "./EditItemForm.scss";

export default function EditItemForm(props) {
  const { item, setIsVisibleModal, setReloadItems } = props;
  const [itemData, setItemData] = useState({});
  const [listCategoriesActive, setListCategoriesActive] = useState([]);

  //estado relacionado a si el usuario realizó modificación en los inputs que deben ser validados
  //const [modifDataToValid, setModifDataToValid] = useState({ item_code: false, description: false });
  //estado que almacena el resultado de validacion de los campos correspondientes
  //const [validData, setValidData] = useState({ item_code: false, });

  //trae los datos del producto
  useEffect(() => {
    setItemData({
      item_code: item.item_code,
      description: item.description,
      category: item.category,
      registration: item.registration,
      cost_price: item.cost_price,
      price1: item.price1,
      price2: item.price2,
    });
  }, [item]);

  useEffect(() => {
    //console.log(activeSuppliers);
    const accessToken = getAccessToken();
    getCategoriesActiveApi(accessToken, true).then((response) => {
      //console.log('response: ', response);
      setListCategoriesActive(response.categories);
      //console.log(response.suppliers);
    });
  }, []);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (
      !itemData.item_code ||
      !itemData.description ||
      !itemData.category ||
      !itemData.cost_price ||
      !itemData.price1
    ) {
      notification["error"]({
        message:
          "Obligatorios: Código, Descripcion, Rubro, Precio Costo y Precio 1.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const updateItem = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let itemUpdate = itemData; //es let porque se va actualizando

      updateItemApi(token, itemUpdate, item._id).then((result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //console.log("respuesta: " + result.message);
          setIsVisibleModal(false);
          setReloadItems(true);
        }
      });
    }
  };

  return (
    <div className="edit-item-form">
      <EditForm
        itemData={itemData}
        setItemData={setItemData}
        updateItem={updateItem}
        listCategoriesActive={listCategoriesActive}
      />
    </div>
  );
}

function EditForm(props) {
  const { itemData, setItemData, updateItem, listCategoriesActive } = props;
  const { Option } = Select;

  return (
    <Form className="form-edit" onSubmit={updateItem}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <Input
              prefix={<TagOutlined />}
              placeholder="Código"
              value={itemData.item_code}
              onChange={(e) =>
                setItemData({ ...itemData, item_code: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Descripción
          </label>
          <Form.Item>
            <Input
              placeholder="Descripcion"
              value={itemData.description}
              onChange={(e) =>
                setItemData({ ...itemData, description: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Rubro
          </label>
          <Form.Item>
            <Select
              placeholder="Selecciona Rubro"
              value={itemData.category ? itemData.category._id : null}
              onChange={(e) => setItemData({ ...itemData, category: e })}
            >
              {listCategoriesActive
                ? listCategoriesActive.map((category) => {
                    return (
                      <Option key={category._id}>{category.description}</Option>
                    );
                  })
                : null}
              {/* <Option value="products">Productos</Option>
              <Option value="services">Servicios</Option>
              <Option value="supplies">Insumos</Option>
              <Option value="fixed-assets">Bienes de uso</Option>
              <Option value="various">Varios</Option>
              <Option value="others">Otros</Option> */}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">Matrícula</label>
          <Form.Item>
            <Input
              placeholder="Matricula"
              name="registration"
              value={itemData.registration}
              onChange={(e) =>
                setItemData({ ...itemData, registration: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Precio Costo
          </label>
          <Form.Item>
            <InputNumber
              placeholder="Precio Costo"
              name="cost_price"
              value={itemData.cost_price}
              onChange={(e) => setItemData({ ...itemData, cost_price: e })}
              size="middle"
              decimalSeparator=","
              style={{ width: "100%" }}
              //   onChange={inputValidation}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Precio 1
          </label>
          <Form.Item>
            <InputNumber
              prefix={<DollarCircleOutlined />}
              name="price1"
              placeholder="Precio 1"
              value={itemData.price1}
              onChange={(e) => setItemData({ ...itemData, price1: e })}
              size="middle"
              decimalSeparator=","
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">Precio 2</label>
          <Form.Item>
            <InputNumber
              prefix={<DollarCircleOutlined />}
              placeholder="Precio 2"
              name="price2"
              value={itemData.price2}
              onChange={(e) => setItemData({ ...itemData, price2: e })}
              size="middle"
              decimalSeparator=","
              style={{ width: "100%" }}
              //   onChange={inputValidation}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateItem}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
