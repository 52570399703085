import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getSaleConditionsActiveApi } from "../../../api/billing/saleCondition";
import ListSaleConditions from "../../../components/Billing/SaleConditions/ListSaleConditions";


export default function SaleConditions() {
    const [saleConditionsActive, setSaleConditionsActive] = useState([]);
    const [saleConditionsInactive, setSaleConditionsInactive] = useState([]);
    const [reloadSaleConditions, setReloadSaleConditions ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getSaleConditionsActiveApi(token, true).then(response => {
            setSaleConditionsActive(response.saleConditions);
        });
        getSaleConditionsActiveApi(token, false).then(response => {
            setSaleConditionsInactive(response.saleConditions);
        });
        setReloadSaleConditions(false);
    }, [token, reloadSaleConditions]);

    return (
        <div className="saleconditions">

            <ListSaleConditions saleConditionsActive={saleConditionsActive} saleConditionsInactive={saleConditionsInactive} setReloadSaleConditions={setReloadSaleConditions}
                />

        </div>
    );
}