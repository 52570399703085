import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  Space
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  DownloadOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditItemForm from "../EditItemForm";
import AddItemForm from "../AddItemForm";
import { activateItemApi, deleteItemApi } from "../../../../api/billing/item";
import { listItemsPdf, itemDetailPdf } from "./pdfItems";
import { itemExistInMovementsApi } from "../../../../api/billing/movement";
import { itemExistInBuyBillsApi } from "../../../../api/billing/buyBill";
import { itemExistInBillSellsApi } from "../../../../api/billing/billsell";
import { getCategoriesApi } from "../../../../api/category";
import {convertCategory} from "../../../../utils/convert";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import useRouteProps from "../../../../hooks/UseRouteProps";
import { PluralizeEsp } from "../../../../utils/convert";
import jwtDecode from "jwt-decode";

import "./ListItems.scss";
import { deleteStockInventoryByItemApi } from "../../../../api/billing/stockInventory";


const { confirm } = ModalAntd;
const { Search } = Input;
const { Column } = Table;

export default function ListItems(props) {
  const { itemsActive, itemsInactive, setReloadItems } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewItemsActives, setViewItemsActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [itemsActiveFilter, setItemsActiveFilter] = useState([]);
  const [itemsInactiveFilter, setItemsInactiveFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState("description");
  const [titleModule, setTitleModule]=useState(useRouteProps().title);


  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, [roleById]);

  useEffect(() => {
    const listActive = [];
    if (itemsActive) {
      itemsActive.forEach((element) => {
        if (typeFilter === "description") {
          if (
            element.description
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        } else {
          if (
            element.item_code
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        }
      });
    }
    setItemsActiveFilter(listActive);
  }, [filterText, itemsActive]);

  useEffect(() => {
    const listInactive = [];
    if (itemsInactive) {
      itemsInactive.forEach((element) => {
        if (typeFilter === "description") {
          if (
            element.description
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        } else {
          if (
            element.item_code
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        }
      });
    }
    setItemsInactiveFilter(listInactive);
  }, [filterText, itemsInactive]);

  const addItemModal = () => {
    setIsVisibleModal(true);
    setModalTitle(`Crear Producto`);
    setModalContent(
      <AddItemForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadItems={setReloadItems}
      />
    );
  };

  const verifyItemDeletion = async (item) => {
    const accessToken = getAccessToken();
    //verifico si existe en alguna factura
    let responseBuyBills = await itemExistInBuyBillsApi(accessToken, item._id);

    let responseBillSells = await itemExistInBillSellsApi(
      accessToken,
      item._id
    );

    let responseMovements = await itemExistInMovementsApi(
      accessToken,
      item._id
    );

    if (
      responseBuyBills.exist ||
      responseBillSells.exist ||
      responseMovements.exist
    ) {
      return false;
    } else if (
      !responseBuyBills.exist &&
      !responseBillSells.exist &&
      !responseMovements.exist
    ) {
      return true;
    }
  };

  return (
    <div className="list-items">
      <div className="list-items__header">
        {checkAction(permissionsActive, userToken.role, "items", "all") ||
        checkAction(permissionsActive, userToken.role, "items", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-items__header-switch">
              <Switch
                defaultChecked
                onChange={() => setViewItemsActives(!viewItemsActives)}
              />
              <span>
                {viewItemsActives ? "Activos" : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
          <>
          </>
        )}

        {checkAction(permissionsActive, userToken.role, "items", "all") ||
        checkAction(permissionsActive, userToken.role, "items", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addItemModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "items", "all") ||
      checkAction(permissionsActive, userToken.role, "items", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-items__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <Radio.Group
              options={[
                { label: "Descripcion", value: "description" },
                { label: "Codigo", value: "item_code" },
              ]}
              onChange={(e) => {
                setTypeFilter(e.target.value);
              }}
              value={typeFilter}
            />
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewItemsActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "items", "all") ||
          checkAction(permissionsActive, userToken.role, "items", "pdf") || isAdmin(roleById) ? (
            <>
              <Space align="center" style={{ marginBottom: 16 }}>
                <Button
                  type="default"
                  shape="round"
                  onClick={() => {
                    listItemsPdf(itemsActiveFilter, `Listado de Productos`, false);
                  }}
                >
                  <DownloadOutlined />  {`Listado Productos`}
                </Button>
              </Space>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "items", "all") ||
          checkAction(permissionsActive, userToken.role, "items", "view") || isAdmin(roleById) ? (
            <>
              <ItemsActive
                itemsActive={itemsActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadItems={setReloadItems}
                verifyItemDeletion={verifyItemDeletion}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                titleModule={titleModule}
                //showDeleteConfirm={showDeleteConfirm}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "items", "all") ||
          checkAction(permissionsActive, userToken.role, "items", "pdf") || isAdmin(roleById) ? (
            <>
              <Space align="center" style={{ marginBottom: 16 }}>
                <Button
                  type="default"
                  shape="round"
                  onClick={() => {
                    listItemsPdf(itemsInactiveFilter,`Listado de Productos`, false);
                  }}
                >
                  <DownloadOutlined /> {`Listado Productos`}
                </Button>
              </Space>
            </>
          ) : (
            <>
            </>
          )}
          {checkAction(permissionsActive, userToken.role, "items", "all") ||
          checkAction(permissionsActive, userToken.role, "items", "view") || isAdmin(roleById) ? (
            <>
              <ItemsInactive
                itemsInactive={itemsInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadItems={setReloadItems}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                verifyItemDeletion={verifyItemDeletion}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                titleModule={titleModule}
                //showDeleteConfirm={showDeleteConfirm}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function ItemsActive(props) {
  const {
    itemsActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadItems,
    verifyItemDeletion,
    accessToken,
    userToken,
    permissionsActive,
    roleById,
    titleModule
  } = props; //showDeleteConfirm

  const editItem = (item) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${item.description ? item.description : "sin descripcion"}`
    );
    setModalContent(
      <EditItemForm
        item={item}
        setIsVisibleModal={setIsVisibleModal}
        setReloadItems={setReloadItems}
      />
    );
  };

  const desactivateItem = (item) => {
    const accessToken = getAccessToken();

    activateItemApi(accessToken, item._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadItems(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (item) => {
    const accessToken = getAccessToken();
    let result = await verifyItemDeletion(item);
    if (!result) {
      confirm({
        title: "Eliminando Producto",
        content: `No se puede eliminar ${item.description} existen facturas y/o movimientos asociados ¿Deseas desactivarlo?`,
        okText: "Desactivar",
        okType: "danger",
        cancelText: "Cancelar",
        onOk() {
          desactivateItem(item);
        },
      });
    } else {
      confirm({
        title: "Eliminando Producto",
        content: `¿Estás seguro que quieres eliminar a ${item.description}?`,
        okText: "Eliminar",
        okType: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteItemApi(accessToken, item._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                return deleteStockInventoryByItemApi(accessToken, item._id);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
                return false;
              } else if (response === "ERR_CONNECTION_REFUSED" ||response === "Failed to fetch" ||response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              }else {
                notification["error"]({
                  message: "Error del servidor",
                });
                return false;
              }
            })
            .then((response) => {
              if (response) {
                if (response.code === 200) {
                  notification["success"]({
                    message: response.message,
                  });
                } else if (response.code === 404) {
                  notification["warning"]({
                    message: response.message,
                  });
                } else if (response.code === 500) {
                  notification["error"]({
                    message: response.message,
                  });
                }
                else {
                  notification["error"]({
                    message: "Error del servidor",
                  });
                }
              }
              setReloadItems(true);
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const edit_item = (
    <div>
      {" "}
      <p>Editar</p>{" "}
    </div>
  );

  const desactivate_item = (
    <div>
      {" "}
      <p>Pasar a Producto inactivos</p>{" "}
    </div>
  );

  const delete_item = (
    <div>
      {" "}
      <p>Eliminar Producto</p>{" "}
    </div>
  );

  const download_item = (
    <div>
      {" "}
      <p>Descargar detalle</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "item_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Rubro",
      dataIndex: "category",
      render: (category) => category.description,
    },
    {
      title: "Precio 1",
      dataIndex: "price1",
      sorter: (a, b) => a.price1 - b.price1,
    },
    {
      title: "Precio 2",
      dataIndex: "price2",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, item) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "items", "all") ||
          checkAction(permissionsActive, userToken.role, "items", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_item}>
                <Button
                  className="button-item"
                  type="default"
                  onClick={() => editItem(item)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "items", "all") ||
          checkAction(permissionsActive, userToken.role, "items", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_item}>
                <Button
                  className="button-item"
                  type="primary"
                  onClick={() => desactivateItem(item)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "items", "all") ||
          checkAction(permissionsActive, userToken.role, "items", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_item}>
                <Button
                  className="button-item"
                  type="danger"
                  onClick={() => showDeleteConfirm(item)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
              <Popover content={download_item}>
                <Button
                  className="button-item"
                  type="primary"
                  onClick={() => itemDetailPdf(item,`Detalle de Producto`)}
                >
                  <DownloadOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={itemsActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function ItemsInactive(props) {
  const { itemsInactive,
          setReloadItems,
          verifyItemDeletion,
          accessToken,
          userToken,
          permissionsActive,
          roleById,
          titleModule
        } = props; //showDeleteConfirm

  const activateItem = (item) => {
    const accessToken = getAccessToken();

    activateItemApi(accessToken, item._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadItems(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (item) => {
    const accessToken = getAccessToken();
    let result = await verifyItemDeletion(item);
    if (!result) {
      confirm({
        title: "Eliminando Producto",
        content: `No se puede eliminar ${item.description} existen facturas y/o movimientos asociados`,
        okText: "Aceptar",
        okType: "danger",
        okCancel: false,
      });
    } else {
      confirm({
        title: "Eliminando Producto",
        content: `¿Estás seguro que quieres eliminar a ${item.description}?`,
        okText: "Eliminar",
        okType: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteItemApi(accessToken, item._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                return deleteStockInventoryByItemApi(accessToken, item._id);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
                return false;
              } else if (response === "ERR_CONNECTION_REFUSED" ||response === "Failed to fetch" ||response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              }
              else {
                notification["error"]({
                  message: "Error del servidor",
                });
                return false;
              }
            })
            .then((response) => {
              if (response) {
                if (response.code === 200) {
                  notification["success"]({
                    message: response.message,
                  });
                } else if (response.code === 404) {
                  notification["warning"]({
                    message: response.message,
                  });
                } else if (response.code === 500) {
                  notification["error"]({
                    message: response.message,
                  });
                }else {
                  notification["error"]({
                    message: "Error del servidor",
                  });
                }
              }
              setReloadItems(true);
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const activate_item = (
    <div>
      {" "}
      <p>Pasar a Productos activos</p>{" "}
    </div>
  );

  const delete_item = (
    <div>
      {" "}
      <p>Eliminar Producto</p>{" "}
    </div>
  );
  const download_item = (
    <div>
      {" "}
      <p>Descargar detalle</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Codigo",
      dataIndex: "item_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Categoría",
      dataIndex: "category",
      render: (category) => category.description,
    },
    {
      title: "Precio 1",
      dataIndex: "price1",
      sorter: (a, b) => a.price1 - b.price1,
    },
    {
      title: "Precio 2",
      dataIndex: "price2",
    },
    {
      title: "Accion",
      key: "action",
      render: (text, item) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "items", "all") ||
          checkAction(permissionsActive, userToken.role, "items", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_item}>
                <Button
                  className="button-item"
                  type="primary"
                  onClick={() => activateItem(item)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "items", "all") ||
          checkAction(permissionsActive, userToken.role, "items", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_item}>
                <Button
                  className="button-item"
                  type="danger"
                  onClick={() => showDeleteConfirm(item)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "items", "all") ||
          checkAction(permissionsActive, userToken.role, "items", "pdf") || isAdmin(roleById) ? (
            <>
              <Popover content={download_item}>
                <Button
                  className="button-item"
                  type="primary"
                  onClick={() => itemDetailPdf(item, `Detalle de Producto`)}
                >
                  <DownloadOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={itemsInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
