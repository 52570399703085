import { downloadPdf, openPdf , getHeader, getStyles, getLogo} from "../../../../utils/pdfManager";

export function listSuppliersPdf(data,isDownload) {
    const _format = (data) => {
        return data.map(item => {
            return ([
                {text: item.denomination},
                {text: item.person.name},
                {text: item.person.lastname},
                {text: item.person.address},
                {text: item.person.phone1},
                {text: item.person.email},
            ]);
        });
    }

    const formattedData=_format(data);

    const docDefinition =
    {
        pageSize:'A4',
        pageOrientation: 'vertical',
        header:getHeader(),
        content:[
            getLogo(),
            '\n',
            '\n',
            { text:'Listado de Proveedores' , style:'title'},
            '\n',
            '\n',
            {
                table: {
                    headerRow:1,
                    dontBreakRows:true,
                    widths:['*','*','*','auto','auto','auto'],
                    body:
                    [
                        [
                            {text: 'Denominacion', style:'tableHeader'},
                            {text: 'Nombre', style:'tableHeader'},
                            {text: 'Apellido', style:'tableHeader'},
                            {text: 'Domicilio', style:'tableHeader'},
                            {text: 'Telefono 1', style:'tableHeader'},
                            {text: 'Email', style:'tableHeader'},
                        ],
                        ...formattedData,
                    ]
                }
            },
        ],
        styles:getStyles()
    }
    if (isDownload){
        downloadPdf(docDefinition,"ListadoProveedores");
    } else {
        openPdf(docDefinition);
    }
}

export function supplierDetailPdf(data,isDownload){

    const _format = (data)=>{
        return ([
            {text:`Denominacion: ${data.denomination? data.denomination : ""}`, style:'list'},
            {text:`Impuestos: ${data.taxes? data.taxes : ""}`, style:'list'},
            {text:`Nombre: ${data.person.name? data.person.name : ""}`, style:'list'},
            {text:`Apellido: ${data.person.lastname? data.person.lastname : ""}`, style:'list'},
            {text:`Domicilio: ${data.person.address? data.person.address : ""}`, style:'list'},
            //{text:`País: ${data.country? data.country : ""}`},
            {text:`Telefono 1: ${data.person.phone1? data.person.phone1 : ""}`, style:'list'},
            {text:`Telefono 2: ${data.person.phone2? data.person.phone2 : ""}`, style:'list'},
            // {text:`Tipo: ${data.type? data.type : ""}`, style:'list'},
            // {text:`Tipo IVA: ${data.type_iva? data.type_iva : ""}`, style:'list'},
            // {text:`Tipo de documento: ${data.documentType? data.documentType : ""}`, style:'list'},
            // {text:`Numero de documento: ${data.documentNumber? data.documentNumber : ""}`, style:'list'},
            {text:`E-mail: ${data.person.email? data.person.email : ""}`, style:'list'},
            //{text:`Cuenta: ${data.account? data.account : ""}`},
            {text:`Observaciones: ${data.observation? data.observation : ""}`, style:'list'},
        ]);
    };

    const formattedData=_format(data);

    const docDefinition =
    {
        pageSize:'A4',
        pageOrientation: 'vertical',
        header:getHeader(),
        content:
        [
            getLogo(),
            '\n',
            { text:'Detalle de Proveedor' , style:'title'},
            '\n',
            '\n',
            {
                type:'none',
                ol:[
                    ...formattedData,
                ],
            }
        ],
        styles:getStyles()
    }
    let title=`${data.name}`;
    if (isDownload) {
        downloadPdf(docDefinition,title);
    } else {
        openPdf(docDefinition);
    }
}