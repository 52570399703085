import React from "react";
import { getAccessToken } from "../../../api/auth";
import ListImports from "../../../components/Admin/Imports/ListImports";

export default function Imports() {
    const token = getAccessToken();

    return (
        <div className="exports">

            <ListImports token={token}
                />
        </div>
    );
}