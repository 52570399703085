import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  BackTop
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditVariationCategoryForm from "../EditVariationCategoryForm";
import AddVariationCategoryForm from "../AddVariationCategoryForm";
import { activateVariationCategoryApi, deleteVariationCategoryApi } from "../../../../api/variationCategory";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListVariationCategories.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListVariantionCategories(props) {
  const {
    variationCategoriesActive,
    variationCategoriesInactive,
    setReloadVariationCategories,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewVariationCategoriesActives, setViewVariationCategoriesActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [variationCategoriesActiveFilter, setVariationCategoriesActiveFilter] = useState(
    []
  );
  const [ variationCategoriesInactiveFilter, setVariationCategoriesInactiveFilter ] = useState([]);

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    const listActive = [];
    if (variationCategoriesActive) {
      variationCategoriesActive.forEach((element) => {
        if (
          element.name
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
          element.variationCategory_code.toString()
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            listActive.push(element);
          }
      });
    }
    setVariationCategoriesActiveFilter(listActive);
  }, [filterText, variationCategoriesActive]);

  useEffect(() => {
    const listInactive = [];
    if (variationCategoriesInactive) {
      variationCategoriesInactive.forEach((element) => {
        if (
            element.name
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
          element.variationCategory_code.toString()
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
      });
    }
    setVariationCategoriesInactiveFilter(listInactive);
  }, [filterText, variationCategoriesInactive]);

  const addVariationCategoryModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Categoria Variacion");
    setModalContent(
      <AddVariationCategoryForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadVariationCategories={setReloadVariationCategories}
      />
    );
  };

  return (
    <div className="list-variations">
      <div className="list-variations__header">
        {checkAction(permissionsActive, userToken.role, "variationcategories", "all") ||
        checkAction(permissionsActive, userToken.role, "variationcategories", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-variations__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewVariationCategoriesActives(!viewVariationCategoriesActives)
                }
              />
              <span>
                {viewVariationCategoriesActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
            <>
            </>
        )}

        {checkAction(permissionsActive, userToken.role, "variationcategories", "all") ||
        checkAction(permissionsActive, userToken.role, "variationcategories", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addVariationCategoryModal}>
              Nueva Categoria Variación
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "variationcategories", "all") ||
      checkAction(permissionsActive, userToken.role, "variationcategories", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-variations__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <h2>

            </h2>
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewVariationCategoriesActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "variationcategories", "all") ||
          checkAction(permissionsActive, userToken.role, "variationcategories", "view") || isAdmin(roleById) ? (
            <>
              <VariationCategoriesActive
                variationCategoriesActive={variationCategoriesActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadVariationCategories={setReloadVariationCategories}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "variationcategories", "all") ||
          checkAction(permissionsActive, userToken.role, "variationcategories", "view") || isAdmin(roleById) ? (
            <>
              <VariationCategoriesInactive
                variationCategoriesInactive={variationCategoriesInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadVariationCategories={setReloadVariationCategories}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>

      <BackTop/>
    </div>
  );
}

function VariationCategoriesActive(props) {
  const {
    variationCategoriesActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadVariationCategories,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editVariationCategory = (variationCategory) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${variationCategory.name ? variationCategory.name : "sin nombre"}`
    );
    setModalContent(
      <EditVariationCategoryForm
        variationCategory={variationCategory}
        setIsVisibleModal={setIsVisibleModal}
        setReloadVariationCategories={setReloadVariationCategories}
      />
    );
  };

  const desactivateVariationCategory = (variationCategory) => {
    activateVariationCategoryApi(accessToken, variationCategory._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadVariationCategories(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (variationCategory) => {
    // let response = await getCoursesByTypeCourseApi(accessToken, typeCourse._id);
    let response = false;
    if (response) {
      confirm({
        title: "Eliminando -> Categoria Variacion",
        content: `No se puede eliminar '${variationCategory.name}' porque existen productos asociados a él ¿Deseas desactivarlo?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          desactivateVariationCategory(variationCategory);
        },
      });
    } else {
      confirm({
        title: "Eliminando -> Categoria Variacion",
        content: `¿Estás seguro que quieres eliminar a ${variationCategory.name}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteVariationCategoryApi(accessToken, variationCategory._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadVariationCategories(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const edit_variation_category= (
    <div>
      {" "}
      <p>Editar Categoria de variacion</p>{" "}
    </div>
  );

  const desactivate_variation_category = (
    <div>
      {" "}
      <p>Pasar a Categoria de variacion inactivos</p>{" "}
    </div>
  );

  const delete_variation_category = (
    <div>
      {" "}
      <p>Eliminar Categoria de ariacion</p>{" "}
    </div>
  );

  const columns = [
    // {
    //   title: "Código",
    //   dataIndex: "variationCategory_code",
    // },
    {
      title: "Nombre",
      dataIndex: "name",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, variationCategory) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "variationcategories", "all") ||
          checkAction(permissionsActive, userToken.role, "variationcategories", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_variation_category}>
                <Button
                  className="button-edit"
                  onClick={() => editVariationCategory(variationCategory)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "variationcategories", "all") ||
          checkAction(permissionsActive, userToken.role, "variationcategories", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_variation_category}>
                <Button
                  className="button-typecourse"
                  type="primary"
                  onClick={() => desactivateVariationCategory(variationCategory)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "variationcategories", "all") ||
          checkAction(permissionsActive, userToken.role, "variationcategories", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_variation_category}>
                <Button
                  className="button-variation"
                  type="danger"
                  onClick={() => showDeleteConfirm(variationCategory)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={variationCategoriesActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function VariationCategoriesInactive(props) {
  const { variationCategoriesInactive,
          setReloadVariationCategories,
          accessToken,
          userToken,
          permissionsActive,
          roleById
        } = props;

  const activateVariationCategory = (variationCategory) => {
    activateVariationCategoryApi(accessToken, variationCategory._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadVariationCategories(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (variationCategory) => {
    // let response = await getCoursesByTypeCourseApi(accessToken, typeCourse._id);
    let response=false;
    if(response.items.length > 0){
      ModalAntd.info({
        title: "Eliminando -> Categoria de variacion",
        content:`No se puede eliminar '${variationCategory.name}' porque existen productos asociados a él`,
        onOk(){},
      });
    } else {
      confirm({
        title: "Eliminando -> Categoria de variacion",
        content: `¿Estás seguro que quieres eliminar a ${variationCategory.name}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteVariationCategoryApi(accessToken, variationCategory._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadVariationCategories(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const activate_variation_category = (
    <div>
      {" "}
      <p>Pasar a Categoria de variacion activos</p>{" "}
    </div>
  );

  const delete_variation_category = (
    <div>
      {" "}
      <p>Eliminar Categoria de variacion</p>
    </div>
  );

  const columns = [
    // {
    //   title: "Código",
    //   dataIndex: "variationCategory_code",
    // },
    {
      title: "Nombre",
      dataIndex: "name",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, variationCategory) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "variationcategories", "all") ||
          checkAction(permissionsActive, userToken.role, "variationcategories", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_variation_category}>
                <Button
                  className="button-activate"
                  onClick={() => activateVariationCategory(variationCategory)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "variationcategories", "all") ||
          checkAction(permissionsActive, userToken.role, "variationcategories", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_variation_category}>
                <Button
                  className="button-typecourse"
                  type="danger"
                  onClick={() => showDeleteConfirm(variationCategory)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return variationCategoriesInactive.length > 0? `Total de registros: ${variationCategoriesInactive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={variationCategoriesInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
