import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditDocumentTypeForm from "../EditDocumentTypeForm";
import AddDocumentTypeForm from "../AddDocumentTypeForm";
import {
  activateDocumentTypeApi,
  deleteDocumentTypeApi,
} from "../../../../api/billing/documentType";

import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListDocumentTypes.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListDocumentTypes(props) {
  const {
    documentTypesActive,
    documentTypesInactive,
    setReloadDocumentTypes,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewDocumentTypesActives, setViewDocumentTypesActives] = useState(
    true
  );
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [documentTypesActiveFilter, setDocumentTypesActiveFilter] = useState(
    []
  );
  const [
    documentTypesInactiveFilter,
    setDocumentTypesInactiveFilter,
  ] = useState([]);
  const [typeFilter, setTypeFilter] = useState("description");

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, [roleById]);

  useEffect(() => {
    const listActive = [];
    if (documentTypesActive) {
      documentTypesActive.forEach((element) => {
        if (typeFilter === "description") {
          if (
            element.description
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        } else {
          if (
            element.documenttype_code
              .toString()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        }
      });
    }
    setDocumentTypesActiveFilter(listActive);
  }, [filterText, documentTypesActive]);

  useEffect(() => {
    const listInactive = [];
    if (documentTypesInactive) {
      documentTypesInactive.forEach((element) => {
        if (typeFilter === "description") {
          if (
            element.description
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        } else {
          if (
            element.documenttype_code
              .toString()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        }
      });
    }
    setDocumentTypesInactiveFilter(listInactive);
  }, [filterText, documentTypesInactive]);

  const addDocumentTypeModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Tipo de documento");
    setModalContent(
      <AddDocumentTypeForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadDocumentTypes={setReloadDocumentTypes}
      />
    );
  };

  return (
    <div className="list-documentTypes">
      <div className="list-documentTypes__header">
        {checkAction(permissionsActive, userToken.role, "documenttypes", "all") ||
        checkAction(permissionsActive, userToken.role, "documenttypes", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-documentTypes__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewDocumentTypesActives(!viewDocumentTypesActives)
                }
              />
              <span>
                {viewDocumentTypesActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
        ) : (
          <>
          </>
        )}

        {checkAction(permissionsActive, userToken.role, "documenttypes", "all") ||
        checkAction(permissionsActive, userToken.role, "documenttypes", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/> }onClick={addDocumentTypeModal}>
              Nuevo 
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "documenttypes", "all") ||
      checkAction(permissionsActive, userToken.role, "documenttypes", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-documentTypes__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <Radio.Group
              options={[
                { label: "Descripción", value: "description" },
                { label: "Código", value: "code" },
              ]}
              onChange={(e) => {
                setTypeFilter(e.target.value);
              }}
              value={typeFilter}
            />
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> ejemplos de tipos de documento 'DNI, CI, pasaporte, carnet'
            </h3>
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewDocumentTypesActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "documenttypes", "all") ||
          checkAction(permissionsActive, userToken.role, "documenttypes", "view") || isAdmin(roleById) ? (
            <>
              <DocumentTypesActive
                documentTypesActive={documentTypesActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadDocumentTypes={setReloadDocumentTypes}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "documenttypes", "all") ||
          checkAction(permissionsActive, userToken.role, "documenttypes", "view") || isAdmin(roleById) ? (
            <>
              <DocumentTypesInactive
                documentTypesInactive={documentTypesInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadDocumentTypes={setReloadDocumentTypes}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function DocumentTypesActive(props) {
  const {
    documentTypesActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadDocumentTypes,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editDocumentType = (docType) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${docType.description ? docType.description : "sin descripción"}`
    );
    setModalContent(
      <EditDocumentTypeForm
        documentType={docType}
        setIsVisibleModal={setIsVisibleModal}
        setReloadDocumentTypes={setReloadDocumentTypes}
      />
    );
  };

  const desactivateDocumentType = (docType) => {
    const accessToken = getAccessToken();

    activateDocumentTypeApi(accessToken, docType._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadDocumentTypes(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (docType) => {
    const accessToken = getAccessToken();
    confirm({
      title: "Eliminando Tipo de Documento",
      content: `¿Estás seguro que quieres eliminar a ${docType.description}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteDocumentTypeApi(accessToken, docType._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadDocumentTypes(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const edit_documentType = (
    <div>
      {" "}
      <p>Editar Tipo de Documento</p>{" "}
    </div>
  );

  const desactivate_documentType = (
    <div>
      {" "}
      <p>Pasar a Tipos de documento inactivos</p>{" "}
    </div>
  );

  const delete_documentType = (
    <div>
      {" "}
      <p>Eliminar Tipo de documento</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "documenttype_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, docType) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "documenttypes", "all") ||
          checkAction(permissionsActive, userToken.role, "documenttypes", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_documentType}>
                <Button
                  className="button-documentType"
                  type="default"
                  onClick={() => editDocumentType(docType)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "documenttypes", "all") ||
          checkAction(permissionsActive, userToken.role, "documenttypes", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_documentType}>
                <Button
                  className="button-documentType"
                  type="primary"
                  onClick={() => desactivateDocumentType(docType)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "documenttypes", "all") ||
          checkAction(permissionsActive, userToken.role, "documenttypes", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_documentType}>
                <Button
                  className="button-documentType"
                  type="danger"
                  onClick={() => showDeleteConfirm(docType)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={documentTypesActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function DocumentTypesInactive(props) {
  const {
          documentTypesInactive,
          setReloadDocumentTypes,
          userToken,
          permissionsActive,
          roleById
        } = props;

  const activateDocumentType = (docType) => {
    const accessToken = getAccessToken();

    activateDocumentTypeApi(accessToken, docType._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadDocumentTypes(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (docType) => {
    const accessToken = getAccessToken();
    confirm({
      title: "Eliminando Tipo de documento",
      content: `¿Estás seguro que quieres eliminar a ${docType.description}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteDocumentTypeApi(accessToken, docType._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadDocumentTypes(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const activate_documentType = (
    <div>
      {" "}
      <p>Pasar a Tipos de documento activos</p>{" "}
    </div>
  );

  const delete_documentType = (
    <div>
      {" "}
      <p>Eliminar Tipo de documento</p>
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "documenttype_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, docType) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "documenttypes", "all") ||
          checkAction(permissionsActive, userToken.role, "documenttypes", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_documentType}>
                <Button
                  className="button-documentType"
                  type="primary"
                  onClick={() => activateDocumentType(docType)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "documenttypes", "all") ||
          checkAction(permissionsActive, userToken.role, "documenttypes", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_documentType}>
                <Button
                  className="button-documentType"
                  type="danger"
                  onClick={() => showDeleteConfirm(docType)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={documentTypesInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
