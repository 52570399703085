import React, {
  useState,
  useEffect,
  useCallback,
  //useRef
} from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  Avatar,
  Select,
  Space,
  Card,
  List,
  Collapse,
  Upload,
  message,
  Popconfirm,
  Switch,
  Typography,
  Divider,
  //InputNumber, Tag, Badge
} from "antd";
import {
  PlusOutlined,
  MinusCircleOutlined,
  LoadingOutlined,
  DragOutlined,
  UploadOutlined,
  DeleteOutlined,
  EditOutlined,
  EditFilled,
  DeleteFilled,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { addItemApi, uploadItemAvatarApi, getVariationCategoriesByBusinessOrBranchApi } from "../../../../api/item";
import { getVariationCategoriesActiveApi } from "../../../../api/variationCategory";
import { getAccessToken } from "../../../../api/auth";
import Modal from "../../../Modal";
import NoAvatar from "../../../../assets/img/png/no-avatar.png";
import { useDropzone } from "react-dropzone";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import EditVariationCategoryForm from "../../VariationCategories/EditVariationCategoryForm/EditVariationCategoryForm";
import {
  addItemBranchMenuByBranchApi,
  addItemBranchMenuAllBranchesApi,
} from "../../../../api/branchMenu";

//import "./AddItemCategoryForm.scss";

const { Text } = Typography;
const { Option } = Select;
const { Dragger } = Upload;
const { Meta } = Card;

const { TextArea } = Input;
const { Panel } = Collapse;

export default function AddItemCategoryForm(props) {
  const {
    setIsVisibleModal,
    setReloadCategories,
    setReloadItems,
    category,
    categories,
    branch,
    business
  } = props;

  console.log(business)

  const [inputs, setInputs] = useState({
    //item_code: "",
    name: "",
    description: "",
    category: category ? category._id : null,
  });

  const [imageUrl, setImageUrl] = useState(null);
  const [listCategories, setListCategories] = useState([]);
  const [variationCategoriesActive, setVariationCategoriesActive] = useState(
    []
  );
  const [reloadVariationCategories, setReloadVariationCategories] =
    useState(false);
  const [listVariantionCategories, setListVariationCategories] = useState([]);

  useEffect(() => {
    if (categories) {
      setListCategories(categories);
    }
  }, [categories]);

  useEffect(() => {
    const accessToken = getAccessToken();
    getVariationCategoriesByBusinessOrBranchApi(accessToken, business, branch).then((response) => {
      console.log(response)
      setVariationCategoriesActive(response.variationCategories);
    });
  }, []);

  useEffect(() => {
    if (reloadVariationCategories) {
      // const accessToken = getAccessToken();
      // getVariationCategoriesActiveApi(accessToken, true).then((response) => {
      //   setVariationCategoriesActive(response.variationCategories);
      // });
      setReloadVariationCategories(false);
    }
  }, [reloadVariationCategories]);

  const isFormValid = (e) => {
    //console.log(inputs);
    let errorExists = false;
    if (!inputs.description) {
      notification["error"]({
        message: "Obligatorio: descripcion.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const handleImageUpload = (url) => {
    setImageUrl(url);
  };

  const addItem = async (e) => {
    e.preventDefault();
    console.log("guardo el item", branch);
    if (!isFormValid()) {
      const accessToken = getAccessToken();

      if (category) {
        inputs.category = category ? category._id : null;
      }
      if (imageUrl) {
        inputs.image = imageUrl;
      }
      if (listVariantionCategories) {
        const simplifiedData = listVariantionCategories.map((item) => ({
          // order: item.order,
          // variationCategory: item.variationCategory._id,
          ...item,
          name: item.name,
        }));
        inputs.variationCategories = simplifiedData;
      }
      if (branch && branch !== 0 && branch !== "0") {
        inputs.branch = branch;
        inputs.isGeneral = false;
      }else{
        inputs.isGeneral = true;
        inputs.business = business;
      }
      await addItemApi(accessToken, inputs)
        // .then((response) => {
        //   if (
        //     response === "ERR_CONNECTION_REFUSED" ||
        //     response === "Failed to fetch" ||
        //     response === undefined
        //   ) {
        //     notification["error"]({
        //       message: "Servidor caido",
        //     });
        //   } else if (response.code !== 200) {
        //     notification["error"]({
        //       message: response.message,
        //     });
        //   } else {
        //     notification["success"]({
        //       message: "Producto creado",
        //     });
        //     setIsVisibleModal(false);
        //   }
        //   setReloadCategories(true);
        //   setReloadItems(true);
        // })
        .then((response) => {
          if (response.code === 200) {
            return response.item;
          } else if (response.code === 404 || response.code === 500) {
            return false;
          } else if (
            response === "ERR_CONNECTION_REFUSED" ||
            response === "Failed to fetch" ||
            response === undefined
          ) {
            return false;
          } else {
            return false;
          }
        })
        .then(async (result) => {
          if (result) {
            let newData = {
              available: true,
              item: result._id,
            };
            if (branch && branch !== 0 && branch !== "0") {
              let response = await addItemBranchMenuByBranchApi(
                accessToken,
                branch,
                newData
              );
              if (response.code === 200) {
                notification["success"]({
                  message: "El producto ha sido creado",
                });
                setIsVisibleModal(false);
                setReloadCategories(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            } else if(business && business !== 0 && business !== "0") {
              let response = await addItemBranchMenuAllBranchesApi(
                accessToken,
                newData,
                business
              );
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadCategories(true);
                setIsVisibleModal(false);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            }
          } else {
            notification["error"]({
              message: "Error al crear el producto",
            });
          }
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  return (
    <div className="add-item-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        addItem={addItem}
        handleImageUpload={handleImageUpload}
        listCategories={listCategories}
        variationCategories={variationCategoriesActive}
        setReloadVariationCategories={setReloadVariationCategories}
        listVariantionCategories={listVariantionCategories}
        setListVariationCategories={setListVariationCategories}
      />
    </div>
  );
}

function UploadItemAvatar(props) {
  const { avatar, setAvatar } = props;
  const [avatarUrl, setAvatarUrl] = useState(null);

  useEffect(() => {
    if (avatar) {
      if (avatar.preview) {
        setAvatarUrl(avatar.preview);
      } else {
        setAvatarUrl(avatar);
      }
    }
  }, [avatar]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      //console.log("file: " + file);
      if (file === undefined) {
        notification["error"]({
          message: "Formato de imagen no válido, aceptados: png, jpg, bpm.",
        });
        return;
      } else {
        //const file = acceptedFiles[0];
        //console.log("URL.createObjectURL(file): " + URL.createObjectURL(file));
        setAvatar({ file, preview: URL.createObjectURL(file) });
      }
      //const file = acceptedFiles[0];
      //setAvatar({ file, preview: URL.createObjectURL(file) });
    },
    [setAvatar]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/jpeg, image/png, image/bmp",
    noKeyboard: true,
    onDrop,
  });

  return (
    <div className="upload-avatar" {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <Avatar
          size={{ xs: 24, sm: 32, md: 40, lg: 50, xl: 60, xxl: 80 }}
          src={NoAvatar}
          shape="square"
        />
      ) : (
        <Avatar
          size={{ xs: 24, sm: 32, md: 40, lg: 50, xl: 60, xxl: 80 }}
          src={avatarUrl ? avatarUrl : NoAvatar}
          shape="square"
        />
      )}
    </div>
  );
}

function AddForm(props) {
  const {
    inputs,
    setInputs,
    addItem,
    handleImageUpload,
    listCategories,
    variationCategories,
    setReloadVariationCategories,
    listVariantionCategories,
    setListVariationCategories,
  } = props;

  return (
    <Form className="form-add" onSubmit={addItem} layout="vertical">
      {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}> */}
      <Row>
        <Col span={4}>
          <UploadAvatar onImageUpload={handleImageUpload} />
        </Col>
        <Col span={20}>
          <Row>
            <Col span={12}>
              <Form.Item label="Nombre">
                <Input
                  value={inputs.name}
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    setInputs({ ...inputs, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={9}>
              {/* <Tag
                                      style={{ margin: "30px 0px 0px 10px" }}
                                      color="success"
                                    >
                                      <Badge status="success" text="Disponible" />
                                    </Tag> */}
              <Form.Item label="Estado">
                <Switch
                  checkedChildren="Disponible"
                  unCheckedChildren="No disponible"
                  style={{
                    // marginTop:35,
                    marginLeft: 10,
                  }}
                  onChange={(e) => setInputs({ ...inputs, available: e })}
                />
                {/* <Select
                  //prefix={<DollarCircleOutlined />}
                  showSearch
                  // placeholder="Selecciona el ciclo"
                  value={inputs.available}
                  onChange={(e) => setInputs({ ...inputs, available: e })}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{
                    width: "100%",
                    // marginTop:35,
                    marginLeft: 10,
                  }}
                  options={[
                    {
                      value: true,
                      label: "Disponible",
                    },
                    {
                      value: false,
                      label: "No disponible",
                    },
                  ]}
                >
                </Select> */}
              </Form.Item>
            </Col>
            <Row>
              <Col>
                <Form.Item label="Descripcion">
                  <TextArea
                    showCount
                    value={inputs.description}
                    maxLength={500}
                    style={{
                      width: 800,
                      height: 80,
                    }}
                    onChange={(e) =>
                      setInputs({ ...inputs, description: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Row>
          <Row>
            <Col span={12}>
              <label>Categoria </label>
              <Select
                showSearch
                value={inputs.category}
                onChange={(e) => setInputs({ ...inputs, category: e })}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                style={{
                  width: "100%",
                }}
              >
                {listCategories
                  ? listCategories.map((category) => {
                      return (
                        <Option key={category._id} value={category._id}>
                          {" "}
                          {category.name}
                        </Option>
                      );
                    })
                  : null}
              </Select>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <Button type="default">Precio único</Button>
              <Button type="default">Precio múltiple</Button>
            </Col>
          </Row> */}
        </Col>
      </Row>
      <Divider orientation="left" orientationMargin="0">
        {" "}
        Establecer precio
      </Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={5} offset={4}>
          <label>Precio</label>
          <Input
            type="number"
            addonBefore="$"
            style={{ width: "100%" }}
            value={inputs.price}
            step="0.01"
            //keyboard={true}
            onChange={(e) => setInputs({ ...inputs, price: e.target.value })}
            // onChange={onInputChange("price", index)}
          />
        </Col>
        <Col span={5} offset={1}>
          <label>Precio anterior </label>
          <Input
            type="number"
            addonBefore="$"
            style={{
              width: "100%",
              textDecoration: "line-through",
              pointerEvents: "none", // Asegúrate de que el texto tachado no interfiera con la interacción del input
            }}
            value={inputs.previousprice}
            step="0.01"
            //keyboard={true}
            onChange={(e) =>
              setInputs({ ...inputs, previousprice: e.target.value })
            }
            // onChange={onInputChange("price", index)}
          />
        </Col>
        <Col span={5} offset={1}>
          <label>Porcentaje de IVA </label>
          <Input
            addonAfter="%"
            type="number"
            style={{ width: "70%" }}
            value={inputs.iva}
            step="0.01"
            //keyboard={true}
            onChange={(e) => setInputs({ ...inputs, iva: e.target.value })}
          />
        </Col>
      </Row>
      <Row>
        {/* <Col col={3} offset={4}>
          <label>Porcentaje de IVA </label>
          <Input
            addonAfter="%"
            type="number"
            style={{ width: "70%" }}
            value={inputs.iva}
            step="0.01"
            //keyboard={true}
            onChange={(e) => setInputs({ ...inputs, iva: e.target.value })}
          />
        </Col> */}
      </Row>
      <Divider orientation="left" orientationMargin="0">
        {" "}
        Agregar Adicional
      </Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={8}></Col>
        <Col span={16} offset={3}>
          {/* <ListVariations variationCategories={variationCategories}/> */}
        </Col>
      </Row>
      {/* </Row> */}
      <ListVariations2
        variationCategories={variationCategories}
        items={listVariantionCategories}
        setItems={setListVariationCategories}
        setReloadVariationCategories={setReloadVariationCategories}
      />
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          style={{ width: "100%" }}
          onClick={addItem}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}

function Variations(props) {
  const {
    itemData,
    setStateDaysData,
    stateDaysData,
    setDayHoursData,
    dayHoursData,
  } = props;

  const [checked, setChecked] = useState(false);
  const [visibleHours, setVisibleHours] = useState(false);
  const [hours, setHours] = useState([]);

  const onChange = (checked) => {
    setVisibleHours(checked);
    setChecked(checked);
    // setStateDaysData({ ...stateDaysData, [dayId]: checked }); //CHEQUEAR COMO AGREGAR ESTE CAMPO AL ARRAY
  };

  // useEffect(() => {
  //   if (branchData && branchData.days) {
  //     let index = branchData.days.findIndex((i) => i.day === dayId);
  //     if (index !== -1) {
  //       let data = branchData.days[index];
  //       setChecked(data.state ? data.state : false);
  //       setVisibleHours(data.state);
  //       if (data.hours) {
  //         let array = data.hours.map((i, index) => {
  //           // return {
  //           //   hour_start: moment(i.hour_start),
  //           //   hour_end: moment(i.hour_end)
  //           // }
  //           return {
  //             key: index,
  //             0: moment(i.hour_start),
  //             1: moment(i.hour_end),
  //           };
  //         });
  //         // console.log(array);
  //         setHours(array);
  //       }
  //     }
  //   }
  // }, [branchData]);

  // useEffect(() => {
  //   setDayHoursData({ ...dayHoursData, [dayId]: hours });
  // }, [hours]);

  const handleDelete = (key) => {
    const newData = hours.filter((item) => item.key !== key);
    setHours(newData);
  };

  const handleAdd = () => {
    let newData = {
      key: hours.length,
    };
    setHours([...hours, newData]);
  };

  return (
    <Card
      style={{
        width: 1000,
      }}
      bordered={false}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={3}>
          {/* <label className="control-label">{title}</label> */}
        </Col>
        {/* <Col span={3}>
          <Switch
            checkedChildren="Abierto"
            unCheckedChildren="Cerrado"
            checked={checked}
            onChange={onChange}
          />
        </Col> */}
        {visibleHours ? (
          <>
            {hours && hours.length > 0 ? (
              <Col span={10}>
                <List
                  dataSource={hours}
                  renderItem={(item, index) => (
                    <SingleVariation
                      key={item.key}
                      hour={item}
                      hours={hours}
                      setHours={setHours}
                      handleDelete={handleDelete}
                    />
                  )}
                />
              </Col>
            ) : null}
            <Col span={5}>
              <Form.Item>
                <Button
                  type="link"
                  onClick={() => handleAdd()}
                  block
                  icon={<PlusOutlined />}
                >
                  Agregar horas
                </Button>
              </Form.Item>
            </Col>
          </>
        ) : null}
      </Row>
    </Card>
  );
}

function SingleVariation(props) {
  const { hour, hours, setHours, handleDelete } = props;

  const handleInputChange = (event) => {
    const newData = [...hours];
    //newData[hour.key] = { key: hour.key, 0: event[0], [1]: event[1] };
    newData[hour.key] = { key: hour.key, 0: event[0], 1: event[1] };
    setHours(newData);
  };

  return (
    <Space
      style={{
        display: "flex",
        marginBottom: 8,
      }}
      align="baseline"
    >
      <Form.Item>
        {/* <EditableCollapse/> */}
        {/* <TimePicker.RangePicker
          format="HH:mm"
          placeholder={["Hora desde", "Hora hasta"]}
          value={hour}
          onChange={(e) => handleInputChange(e)}
          minuteStep={10}
        /> */}
      </Form.Item>
      <MinusCircleOutlined onClick={() => handleDelete(hour.key)} />
    </Space>
  );
}

// const EditablePanel = ({ panelKey, defaultTitle, onSave }) => {
//   const [title, setTitle] = useState(defaultTitle);
//   const [isEditing, setEditing] = useState(false);

//   const handleSave = () => {
//     setEditing(false);
//     onSave(panelKey, title);
//   };

//   return (
//     <Panel
//       header={
//         isEditing ? (
//           <Input
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             onBlur={handleSave}
//             onPressEnter={handleSave}
//             autoFocus
//           />
//         ) : (
//           <div onClick={() => setEditing(true)}>{title}</div>
//         )
//       }
//       key={panelKey}
//     >
//       <p>This is panel content for {title}</p>
//     </Panel>
//   );
// };

// const EditableCollapse = () => {
//   const handleSaveTitle = (key, newTitle) => {
//     // Implement logic to save the new title, e.g., update state or make an API call
//     console.log(`Saved new title "${newTitle}" for panel with key ${key}`);
//   };

//   return (
//     <Collapse>
//       <EditablePanel
//         panelKey="1"
//         defaultTitle="Panel 1"
//         onSave={handleSaveTitle}
//       />
//       <EditablePanel
//         panelKey="2"
//         defaultTitle="Panel 2"
//         onSave={handleSaveTitle}
//       />
//       <EditablePanel
//         panelKey="3"
//         defaultTitle="Panel 3"
//         onSave={handleSaveTitle}
//       />
//     </Collapse>
//   );
// };

function UploadAvatar(props) {
  const { onImageUpload } = props;
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [editing, setEditing] = useState(false);
  // const [fileName, setFileName] = useState('');

  // Maneja el cambio en el estado del archivo
  const handleChange = (info) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      setFileList([info.file]); // Solo mostrar una imagen
      setPreviewImage(URL.createObjectURL(info.file.originFileObj));
      setEditing(false); // Termina el estado de edición
      onImageUpload(info.file.name); // Guardar el nombre del archivo
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const customRequest = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append("file", file);
    const accessToken = getAccessToken();
    try {
      const response = await uploadItemAvatarApi(accessToken, formData);
      if (response.status === 200) {
        onSuccess();
      } else {
        onError(new Error("Upload failed"));
      }
    } catch (error) {
      onError(error);
    }
  };

  const handleRemove = () => {
    setFileList([]);
    setPreviewImage(null);
    onImageUpload("");
    message.success("Image removed successfully");
  };

  // Maneja la edición de la imagen
  const handleEdit = () => {
    setEditing(true);
  };
  return (
    <div>
      <Upload
        customRequest={customRequest}
        onChange={handleChange}
        showUploadList={false} // No mostrar la lista de archivos
        accept="image/*"
        multiple={false} // Permitir solo un archivo
        disabled={editing} // Deshabilitar la carga durante la edición
      >
        <div>
          {previewImage ? (
            <>
              <Avatar
                size={100}
                src={previewImage}
                shape="square"
                style={{ display: "block", marginBottom: 8 }}
              />
              {/* <Text>{fileName}</Text> */}
              <div style={{ marginTop: 8 }}>
                <Button
                  type="link"
                  icon={<EditOutlined />}
                  onClick={handleEdit}
                  style={{ marginRight: 8 }}
                ></Button>
                <Button
                  type="link"
                  icon={<DeleteOutlined />}
                  onClick={handleRemove}
                  // style={{ position: 'absolute', top: 0, right: 0 }}
                />
              </div>
            </>
          ) : (
            <Button icon={<UploadOutlined />} onClick={() => setEditing(true)}>
              Upload Image
            </Button>
          )}
        </div>
      </Upload>
    </div>
  );
}

// // Componente para cada elemento de la lista
// const SortableItem = SortableElement(({ item, index, onEdit, onDelete, onChange, isEditing }) => (
//   <List.Item
//     actions={[
//       isEditing ? (
//         <>
//           <Button type="link" onClick={() => onEdit(index, false)}>Save</Button>
//           <Button type="link" onClick={() => onEdit(null, false)}>Cancel</Button>
//         </>
//       ) : (
//         <>
//           <Button type="link" onClick={() => onEdit(index, true)}>Edit</Button>
//           <Popconfirm
//             title="Are you sure to delete this item?"
//             onConfirm={() => onDelete(index)}
//             okText="Yes"
//             cancelText="No"
//           >
//             <Button type="link" danger>Delete</Button>
//           </Popconfirm>
//         </>
//       ),
//     ]}
//     style={listItemStyle}
//   >
//     <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
//       {isEditing ? (
//         <Input
//           value={item.name}
//           onChange={(e) => onChange(e.target.value)}
//           onBlur={() => onEdit(null, false)}
//           style={{ flex: 1 }}
//         />
//       ) : (
//         <span style={{ flex: 1 }}>{item.name}</span>
//       )}
//       <DragOutlined style={dragHandleStyle} />
//     </div>
//   </List.Item>
// ));

// // Contenedor para la lista de elementos arrastrables
// const SortableList = SortableContainer(({ items, onEdit, onDelete, onChange, editIndex }) => (
//   <List
//     bordered
//     dataSource={items}
//     renderItem={(item, index) => (
//       <SortableItem
//         index={index}
//         item={item}
//         onEdit={onEdit}
//         onDelete={onDelete}
//         onChange={onChange}
//         isEditing={editIndex === index}
//       />
//     )}
//   />
// ));

// Estilo adicional para la lista y los elementos
const listItemStyle = { marginLeft: "30px" };
const dragHandleStyle = { cursor: "grab", marginLeft: 8 };
// const SortableItem = SortableElement(
//   ({ item, index, order, onEdit, onDelete }) => (
//     <List.Item
//       actions={[
//         <>
//           <Button shape="circle" onClick={() => onEdit(order, true)}>
//             <EditFilled />
//           </Button>
//           <Divider type="vertical" />
//           <Popconfirm
//             title="¿Está seguro de eliminar este adicional?"
//             onConfirm={() => onDelete(order)}
//             okText="Si"
//             cancelText="No"
//           >
//             <Button shape="circle">
//               <DeleteFilled color="red" />
//             </Button>
//           </Popconfirm>
//         </>,
//       ]}
//     >
//       <DragOutlined style={dragHandleStyle} />
//       {/* <div */}
//       <Divider type="vertical" />
//       <p
//         style={{
//           "white-space": "nowrap",
//           overflow: "hidden" /* Oculta el contenido que excede el contenedor */,
//           "text-overflow": "ellipsis",
//           //   // backgroundColor: "#0095f8",
//           //   // marginBottom: "8px",
//         }}
//       >
//         {item.variation.name}
//       </p>
//     </List.Item>
//   )
// );

const SortableItem2 = SortableElement(
  ({ item, index, order, onEdit, onDelete }) => (
    <List.Item
      actions={[
        <>
          {/* <Button shape="circle" onClick={() => onEdit(item.variationCategory)}> */}
          <Button shape="circle" onClick={() => onEdit(order, item)}>
            <EditFilled />
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            title="¿Está seguro de eliminar este adicional?"
            onConfirm={() => onDelete(order)}
            okText="Si"
            cancelText="No"
          >
            <Button shape="circle">
              <DeleteFilled color="red" />
            </Button>
          </Popconfirm>
        </>,
      ]}
    >
      <DragOutlined style={dragHandleStyle} />
      {/* <div */}
      <Divider type="vertical" />
      <List.Item.Meta
        title={<div style={{ color: "gray" }}>{"Seleccion " + order + 1}</div>}
        description={<div style={{ color: "black" }}>{item.name}</div>}
      />
    </List.Item>
  )
);

const SortableList = SortableContainer(({ items, onEdit, onDelete }) => (
  <List
    className="list"
    size="small"
    dataSource={items}
    renderItem={(item, index) => (
      <SortableItem2
        index={index}
        order={index}
        item={item}
        onDelete={onDelete}
        onEdit={onEdit}
      />
    )}
  />
));

function ListVariations(props) {
  const { variationCategories, setReloadVariationCategories, items, setItems } =
    props;
  // const [items, setItems] = useState([]);
  const [count, setCount] = useState(items.length);
  const [showSelect, setShowSelect] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [editValue, setEditValue] = useState("");
  // const [reloadItems, setReloadItems] = useState(false);

  useEffect(() => {
    //actualizo las categorias por si modificaron valor en la actualizacion
    // Actualizar la mainList con los valores de updateList
    const updatedMainList = items.map((item) => {
      // Encontrar el elemento correspondiente en updateList
      const updateItem = variationCategories.find(
        (updateItem) => updateItem._id === item.variationCategory._id
      );

      // Si se encuentra un elemento en updateList, combinar los valores; de lo contrario, mantener el original
      if (updateItem) {
        let newData = {
          variation: updateItem,
          order: item.order,
        };
        return newData;
      } else {
        return item;
      }
    });
    setItems(updatedMainList);
  }, [variationCategories]);

  const handleEdit = (variationCategory) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${variationCategory.name ? variationCategory.name : "sin nombre"}`
    );
    setModalContent(
      <EditVariationCategoryForm
        variationCategory={variationCategory}
        setIsVisibleModal={setIsVisibleModal}
        setReloadVariationCategories={setReloadVariationCategories}
      />
    );
  };
  // Función para eliminar un elemento
  const handleDelete = (index) => {
    let newArry = items.filter((_, i) => i !== index);
    setItems(newArry);
  };

  const handleClick = () => {
    setShowSelect(!showSelect); // Alterna la visibilidad del Select
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newItems = Array.from(items);
    const [movedItem] = newItems.splice(oldIndex, 1);
    newItems.splice(newIndex, 0, movedItem);
    setItems(newItems);
  };

  const handleAdd = (data) => {
    data.order = items.length;
    setItems([...items, data]);
    setCount(count + 1);
  };

  return (
    <div style={{ width: "60%", marginLeft: "120px" }}>
      {items.length > 0 ? (
        <SortableList
          items={items}
          onSortEnd={onSortEnd}
          axis="y"
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      ) : null}
      {showSelect ? (
        <AddVariationCategory
          variationCategories={variationCategories}
          addVariationCategory={handleAdd}
          handleClick={handleClick}
          setShowSelect={setShowSelect}
        />
      ) : (
        <Button
          type="dashed"
          onClick={handleClick}
          style={{ marginBottom: 30, marginTop: 10, marginLeft: 30 }}
        >
          <PlusOutlined /> Agregar categoria
        </Button>
      )}
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function ListVariations2(props) {
  const { variationCategories, setReloadVariationCategories, items, setItems } =
    props;
  // const [items, setItems] = useState([]);
  const [count, setCount] = useState(items.length);
  const [showSelect, setShowSelect] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [editValue, setEditValue] = useState("");
  // const [reloadItems, setReloadItems] = useState(false);
  console.log(variationCategories)
  useEffect(() => {
    //actualizo las categorias por si modificaron valor en la actualizacion
    // Actualizar la mainList con los valores de updateList
    const updatedMainList = items.map((item) => {
      // Encontrar el elemento correspondiente en updateList
      const updateItem = variationCategories.find(
        (updateItem) => updateItem.name === item.name
      );
      console.log(variationCategories, updateItem)
      // Si se encuentra un elemento en updateList, combinar los valores; de lo contrario, mantener el original
      if (updateItem) {
        let newData = {
          variation: updateItem,
          order: item.order,
        };
        return newData;
      } else {
        return item;
      }
    });
    setItems(updatedMainList);
  }, [variationCategories]);

  const editData = (index, newData) => {
    items[index] = newData;
    setItems(items);
  };

  const handleEdit = (index, variationCategory) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${
        variationCategory.name
          ? variationCategory.name
          : "sin nombre"
      }`
    );
    setModalContent(
      <EditVariationCategoryForm
        id={index}
        variationCategory={variationCategory}
        setIsVisibleModal={setIsVisibleModal}
        setData={editData}
        setReloadVariationCategories={setReloadVariationCategories}
      />
    );
  };
  // Función para eliminar un elemento
  const handleDelete = (index) => {
    let newArry = items.filter((_, i) => i !== index);
    setItems(newArry);
  };

  const handleClick = () => {
    setShowSelect(!showSelect); // Alterna la visibilidad del Select
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newItems = Array.from(items);
    const [movedItem] = newItems.splice(oldIndex, 1);
    newItems.splice(newIndex, 0, movedItem);
    setItems(newItems);
  };

  const handleAdd = (data) => {
    data.order = items.length;
    setItems([...items, data]);
    setCount(count + 1);
  };

  return (
    <div style={{ width: "60%", marginLeft: "120px" }}>
      {items.length > 0 ? (
        <SortableList
          items={items}
          onSortEnd={onSortEnd}
          axis="y"
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      ) : null}
      {showSelect ? (
        <AddVariationCategory
          variationCategories={variationCategories}
          addVariationCategory={handleAdd}
          handleClick={handleClick}
          setShowSelect={setShowSelect}
        />
      ) : (
        <Button
          type="dashed"
          onClick={handleClick}
          style={{ marginBottom: 30, marginTop: 10, marginLeft: 30 }}
        >
          <PlusOutlined /> Agregar categoria
        </Button>
      )}
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        modalWidth={800}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function AddVariationCategory(props) {
  const {
    variationCategories,
    addVariationCategory,
    setShowSelect,
    handleClick,
  } = props;

  const [selectedOption, setSelectedOption] = useState("");
  const [name, setName] = useState('');
  const [items, setItems] = useState([...variationCategories]);

  const handleSelectChange = (value) => {
    setSelectedOption(value);
  };


  const handleConfirmAdd = () => {
    if (selectedOption) {
      let categorySelected = items.find(
        (v) => v === selectedOption
      );
      const newData = {
        //key: Date.now(), // Un identificador único para cada fila
        name: categorySelected,
      };
      addVariationCategory(newData);
      //setDataSource([...dataSource, newData]);
      setSelectedOption(""); // Limpiar la selección después de añadir
      setShowSelect(false); // Ocultar el Select después de añadir
    }
  };
  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = () => {
    setItems([...items, name ]);
    setName('');
  };


  return (
    <Row style={{ marginTop: "30px", marginBottom: "30px" }}>
      <Col span={16} offset={2}>
        <Select
          //prefix={<DollarCircleOutlined />}
          showSearch
          value={selectedOption}
          onChange={handleSelectChange}
        //   optionFilterProp="children"
           style={{ width: "100%" }}
        //   filterOption={(input, option) =>
        //     option.children
        //       .toString()
        //       .toLowerCase()
        //       .indexOf(input.toLowerCase()) >= 0
        //   }
        // >
        //   {variationCategories
        //     ? variationCategories.map((variationCategory) => {
        //         return (
        //           <Option key={variationCategory}>
        //             {" "}
        //             {variationCategory}
        //           </Option>
        //         );
        //       })
        //     : null}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider
              style={{
                margin: '8px 0',
              }}
            />
            <Space
              style={{
                padding: '0 8px 4px',
              }}
            >
              <Input
                placeholder="Please enter item"
                value={name}
                onChange={onNameChange}
              />
              <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                Añadir Categoria
              </Button>
            </Space>
          </>
        )}
        options={items.map((item) => ({
          label: item,
          value: item,
        }))}
      />
        {/* </Select> */}
      </Col>
      <Col span={3}>
        <Button
          type="primary"
          onClick={handleConfirmAdd}
          style={{ marginLeft: 4 }}
        >
          <CheckOutlined />
        </Button>
      </Col>
      <Col span={3}>
        <Button
          type="primary"
          onClick={handleClick}
          style={{ marginBottom: 6 }}
          danger
        >
          <CloseOutlined />
        </Button>
      </Col>
    </Row>
  );
}
