import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Row,
  Col,
  notification,
  Select,
  Transfer,
  Popconfirm,
  Table,
} from "antd";
import {
  TagOutlined,
  PlusOutlined,
  SaveOutlined,
  CloseOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { updatePaymentOrderApi } from "../../../../api/billing/paymentOrder";
import { getSuppliersActiveApi } from "../../../../api/billing/supplier";
import {
  getBuyBillsSupplierApi,
  updateBuyBillApi,
} from "../../../../api/billing/buyBill";
import { getPaymentTypesActiveApi } from "../../../../api/billing/paymentType";
import { getAccessToken } from "../../../../api/auth";
import { formatDateCalendar } from "../../../../utils/formatDate";
import TextArea from "antd/lib/input/TextArea";

import "./EditPaymentOrderForm.scss";

const { Option } = Select;

export default function EditPaymentOrderForm(props) {
  const { paymentOrder, setIsVisibleModal, setReloadPaymentOrders } = props;
  const [paymentOrderData, setPaymentOrderData] = useState({});
  const [activeSuppliers, setActiveSuppliers] = useState([]);
  const [activePaymentTypes, setActivePaymentTypes] = useState([]);
  const [buyBillsData, setBuyBillsData] = useState([]);
  const [buyBillsId, setBuyBillsId] = useState([]);
  const [listChecks, setListChecks] = useState([]);
  const accessToken = getAccessToken();

  const [updateBills, setUpdateBills] = useState(false);

  useEffect(() => {
    setPaymentOrderData({
      paymentorder_code: paymentOrder.paymentorder_code,
      date: formatDateCalendar(paymentOrder.date),
      supplier: paymentOrder.supplier._id,
      paymentType: paymentOrder.paymentType,
      cash: paymentOrder.cash,
      amount: paymentOrder.amount,
      transferNumber: paymentOrder.transferNumber,
      bills: paymentOrder.bills,
      checks: paymentOrder.checks,
      total: paymentOrder.total,
      observation: paymentOrder.observation,
    });
  }, [paymentOrder]);

  useEffect(() => {
    getSuppliersActiveApi(accessToken, true).then((response) => {
      setActiveSuppliers(response.suppliers);
    });
  }, []);

  useEffect(() => {
    getPaymentTypesActiveApi(accessToken, true).then((response) => {
      setActivePaymentTypes(response.paymentTypes);
    });
  }, []);

  useEffect(() => {
    setListChecks(paymentOrder.checks);
    const list = paymentOrder.bills.map((item) => item._id);
    setBuyBillsId(list);
  }, [paymentOrder]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;

    if (
      !paymentOrderData.paymentorder_code ||
      !paymentOrderData.supplier ||
      !paymentOrderData.date ||
      !paymentOrderData.paymentType
    ) {
      notification["error"]({
        message:
          "Obligatorios: Código, Proveedor, Fecha, forma de pago y facturas.",
      });
      errorExists = true;
    } else if (buyBillsId.length === 0) {
      notification["error"]({
        message: "Debe seleccionar al menos una factura a pagar.",
      });
      errorExists = true;
    } else if (!verifyPayments()) {
      notification["error"]({
        message: "La suma de los importes a pagar deben coincidir con el total",
      });
      errorExists = true;
    } else if (paymentOrderData.amount > 0) {
      if (!paymentOrderData.transferNumber) {
        notification["error"]({
          message: "Debe ingresar el numero de transferencia asociado",
        });
        errorExists = true;
      }
    }
    return errorExists;
  };

  const verifyPayments = () => {
    const amount = paymentOrderData.amount ? paymentOrderData.amount : 0;
    const cash = paymentOrderData.cash ? paymentOrderData.cash : 0;
    const checks = listChecks.reduce((t, { amount }) => t + amount, 0);
    // console.log(paymentOrderData.total ,amount, cash, checks);
    if (paymentOrderData.total - amount - cash - checks === 0) {
      return true;
    } else {
      return false;
    }
  };
  const updatePaymentOrder = (e) => {
    e.preventDefault();

    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      paymentOrderData.bills = buyBillsId;
      let paymentOrderUpdate = paymentOrderData; //es let porque se va actualizando
      // buyBills.map((bill)=>{
      //   paymentOrderData.bills.push(bill._id);
      //   return null;
      // })

      updatePaymentOrderApi(token, paymentOrderUpdate, paymentOrder._id).then(
        (result) => {
          if (
            result.message === "ERR_CONNECTION_REFUSED" ||
            result.message === "Failed to fetch" ||
            result.message === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
            setIsVisibleModal(true);
          } else if (result.code !== 200) {
            notification["error"]({
              message: result.message,
            });
            setIsVisibleModal(true);
          } else {
            notification["success"]({
              message: result.message, //el mensaje que viene del server
            });
            //console.log("respuesta: " + result.message);
            setIsVisibleModal(false);
            setUpdateBills(true);
            setReloadPaymentOrders(true);
          }
        }
      );
    }
  };

  const checkForDeleted = () => {
    paymentOrder.bills.map((bill) => {
      let billDelete = paymentOrderData.bills.find((i) => i === bill._id);
      // console.log(paymentOrderData.bills, paymentOrder.bills);
      // console.log(paymentOrderData.bills.findIndex((i) => i === bill._id));
      if (!billDelete) {
        updateBuyBillApi(accessToken, { paid: false }, bill._id).then(
          (result) => {
            if (
              result.message === "ERR_CONNECTION_REFUSED" ||
              result.message === "Failed to fetch" ||
              result.message === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
              setIsVisibleModal(true);
            } else if (result.code !== 200) {
              notification["error"]({
                message: result.message,
              });
              setIsVisibleModal(true);
            } else {
              // notification["success"]({
              //   message: result.message, //el mensaje que viene del server
              // });
            }
          }
        );
      }
      return true;
    });
  };

  useEffect(() => {
    if (updateBills) {
      //Verifico si existen comprobantes que han sido eliminados del detalle
      checkForDeleted();
      //Verifico el nuevo detalle de comprobantes
      paymentOrderData.bills.map((bill) => {
        updateBuyBillApi(accessToken, { paid: true }, bill).then(
          (result) => {
            if (
              result.message === "ERR_CONNECTION_REFUSED" ||
              result.message === "Failed to fetch" ||
              result.message === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
              setIsVisibleModal(true);
            } else if (result.code !== 200) {
              notification["error"]({
                message: result.message,
              });
              setIsVisibleModal(true);
            } else {
              // notification["success"]({
              //   message: result.message, //el mensaje que viene del server
              // });
            }
          }
        );
        return true;
      });
    }
  }, [updateBills]);

  return (
    <div className="edit-paymentorder-form">
      <EditForm
        paymentOrderData={paymentOrderData}
        setPaymentOrderData={setPaymentOrderData}
        updatePaymentOrder={updatePaymentOrder}
        activeSuppliers={activeSuppliers}
        activePaymentTypes={activePaymentTypes}
        buyBillsData={buyBillsData}
        setBuyBillsData={setBuyBillsData}
        buyBillsId={buyBillsId}
        setBuyBillsId={setBuyBillsId}
        listChecks={listChecks}
        setListChecks={setListChecks}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    paymentOrderData,
    setPaymentOrderData,
    updatePaymentOrder,
    activeSuppliers,
    activePaymentTypes,
    buyBillsData,
    setBuyBillsData,
    buyBillsId,
    setBuyBillsId,
    listChecks,
    setListChecks,
  } = props;

  const [maxAmount, setMaxAmount] = useState(1000000);
  const [maxCash, setMaxCash] = useState(1000000);
  const [maxAmountChecks, setMaxAmountChecks] = useState(1000000);

  const onChangeBills = (newTargetKeys, direction, moveKeys) => {
    // console.log(newTargetKeys, direction, moveKeys);
    setBuyBillsId(newTargetKeys);
  };

  const addCheck = () => {
    const newData = {
      keycheck: listChecks.length + 1,
      number: "",
      amount: "",
      date: "",
      bank: "",
    };
    setListChecks([...listChecks, newData]);
  };

  useEffect(() => {
    if (paymentOrderData.supplier) {
      const accessToken = getAccessToken();
      getBuyBillsSupplierApi(accessToken, paymentOrderData.supplier).then(
        (response) => {
          if (response.buyBills) {
            console.log(response.buyBills, buyBillsId);
            const billsUnPaid = response.buyBills.filter(
              (i) => i.paid === false
            );
            const newbills = paymentOrderData.bills.concat(billsUnPaid);
            setBuyBillsData(newbills);
          }
        }
      );
    }
  }, [paymentOrderData.supplier]);

  useEffect(() => {
    let totals = 0;
    buyBillsId.forEach((b) => {
      const bill = buyBillsData.find((elem) => elem._id === b);
      totals += bill ? bill.total : 0;
    });
    setPaymentOrderData({ ...paymentOrderData, total: totals });
  }, [buyBillsId, buyBillsData]);

  useEffect(() => {
    const amount = paymentOrderData.amount ? paymentOrderData.amount : 0;
    const cash = paymentOrderData.cash ? paymentOrderData.cash : 0;
    const checks = listChecks.reduce((t, { amount }) => t + amount, 0);
    if (paymentOrderData.total - amount - cash - checks >= 0) {
      setMaxAmount(paymentOrderData.total - cash - checks);
      setMaxCash(paymentOrderData.total - amount - checks);
      setMaxAmountChecks(paymentOrderData.total - amount - cash);
    }
  }, [
    paymentOrderData.total,
    paymentOrderData.amount,
    paymentOrderData.cash,
    listChecks,
  ]);

  return (
    <Form className="form-edit" onSubmit={updatePaymentOrder}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={8}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Código"
              value={paymentOrderData.paymentorder_code}
              onChange={(e) =>
                setPaymentOrderData({
                  ...paymentOrderData,
                  paymentorder_code: e,
                })
              }
              min={1}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <label className="control-label">
            <span className="control-required">*</span> Proveedor
          </label>
          <Form.Item rules={[{ required: true }]}>
            <Select
              showSearch
              name="supplier"
              value={paymentOrderData.supplier}
              onChange={(e) =>
                setPaymentOrderData({
                  ...paymentOrderData,
                  supplier: e,
                })
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {activeSuppliers
                ? activeSuppliers.map((i) => {
                    return <Option key={i._id}>{i.name}</Option>;
                  })
                : "No hay proveedores disponibles"}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <label className="control-label">
            <span className="control-required">*</span> Fecha
          </label>
          <Form.Item>
            <Input
              type="date"
              placeholder="fecha"
              value={paymentOrderData.date}
              onChange={(e) =>
                setPaymentOrderData({
                  ...paymentOrderData,
                  date: e.target.value.toUpperCase(),
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
            <span className="control-required">*</span> Facturas
          </label>
          <Form.Item rules={[{ required: true }]}>
            <Transfer
              dataSource={buyBillsData}
              listStyle={{
                width: 400,
                height: 150,
              }}
              titles={["Debe", "A pagar"]}
              targetKeys={buyBillsId}
              onChange={onChangeBills}
              render={(item) => item.code}
              rowKey={(item) => item._id}
              leftColumns={{
                dataIndex: "code",
                title: "Codigo",
              }}
              rightColumns={{
                dataIndex: "code",
                title: "Codigo",
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item>
            <label className="control-label">
              <span className="control-required">*</span> Total
            </label>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Total"
              value={paymentOrderData.total}
              // onChange={(e) => setPaymentOrderData({ ...paymentOrderData, total: e })}
              disabled={true}
              style={{ width: "100%" }}
              required
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Forma de pago
          </label>
          <Form.Item rules={[{ required: true }]}>
            <Select
              showSearch
              name="paymentType"
              value={paymentOrderData.paymentType}
              onChange={(e) =>
                setPaymentOrderData({
                  ...paymentOrderData,
                  paymentType: e,
                })
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {activePaymentTypes
                ? activePaymentTypes.map((i) => {
                    return <Option key={i.description}>{i.description}</Option>;
                  })
                : "No hay forma de pago disponibles"}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label"> Efectivo</label>
          <Form.Item rules={[{ required: true }]}>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="efectivo"
              value={paymentOrderData.cash}
              onChange={(e) =>
                setPaymentOrderData({ ...paymentOrderData, cash: e })
              }
              min={0}
              max={maxCash}
              style={{ width: "100%" }}
              required
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label"> Importe</label>
          <Form.Item rules={[{ required: true }]}>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Importe"
              value={paymentOrderData.amount}
              onChange={(e) =>
                setPaymentOrderData({ ...paymentOrderData, amount: e })
              }
              min={0}
              max={maxAmount}
              style={{ width: "100%" }}
              required
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label"> Nro Transferencia</label>
          <Form.Item>
            <Input
              placeholder="Numero de transferencia"
              value={paymentOrderData.transferNumber}
              onChange={(e) =>
                setPaymentOrderData({
                  ...paymentOrderData,
                  transferNumber: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label"> Cheques</label>
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => addCheck()}
              style={{ width: "60%" }}
              icon={<PlusOutlined />}
            >
              Agregar Cheque
            </Button>
          </Form.Item>
          {listChecks.length > 0 ? (
            <Checks
              listChecks={listChecks}
              setListChecks={setListChecks}
              maxAmount={maxAmountChecks}
            />
          ) : null}
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">Retencion ganancias</label>
          <Form.Item rules={[{ required: true }]}>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Retencion de ganancias"
              value={paymentOrderData.profitRetention}
              onChange={(e) =>
                setPaymentOrderData({ ...paymentOrderData, profitRetention: e })
              }
              min={1}
              style={{ width: "100%" }}
              required
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">Observaciones</label>
          <Form.Item>
            <TextArea
              placeholder="Observaciones"
              name="observation"
              value={paymentOrderData.observation}
              onChange={(e) =>
                setPaymentOrderData({
                  ...paymentOrderData,
                  observation: e.target.value,
                })
              }
              size="middle"
              //onChange={inputValidation}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updatePaymentOrder}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}

function Checks(props) {
  const { listChecks, setListChecks, maxAmount } = props;

  const columns = [
    {
      title: "",
      dataIndex: "keycheck",
      editable: false,
      width: "5%",
    },
    {
      title: "Nro.",
      dataIndex: "number",
      editable: true,
      width: "30%",
    },
    {
      title: "Importe",
      dataIndex: "amount",
      editable: true,
      width: "15%",
    },
    {
      title: "Fecha",
      dataIndex: "date",
      editable: true,
      width: "20%",
    },
    {
      title: "Banco",
      dataIndex: "bank",
      editable: true,
      width: "20%",
    },
  ];
  return (
    <EditableChecksTable
      columnsData={columns}
      data={listChecks}
      setData={setListChecks}
      rowKey="keycheck"
      maxAmount={maxAmount}
    />
  );
}

function EditableChecksTable(props) {
  const { data, setData, columnsData, rowKey, maxAmount } = props;
  const [form] = Form.useForm();
  // const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const subtotal = data.reduce((t, { amount }) => t + amount, 0);
  const maxAmountCheck = maxAmount - subtotal;

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "number" ? (
        <InputNumber max={maxAmount} valueDefault={maxAmountCheck} />
      ) : inputType === "date" ? (
        <Input type="date" />
      ) : (
        <Input />
      );
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Por favor ingrese ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const isEditing = (record) => record.keycheck === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      number: "",
      amount: "",
      ...record,
    });
    setEditingKey(record.keycheck);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.keycheck);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const showDeleteConfirm = (record) => {
    const d = data.filter((i) => i !== record);
    if (d.length === 0) {
      setData([]);
    } else {
      setData(d);
    }
  };

  const columns = [
    ...columnsData,
    {
      title: "Acción",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              type="primary"
              size="small"
              onClick={() => save(record.keycheck)}
            >
              <SaveOutlined />
            </Button>
            <Popconfirm title="Está seguro de cancelar?" onConfirm={cancel}>
              <Button type="danger" size="small">
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Button
              type="default"
              size="small"
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <EditOutlined />
            </Button>
            <Button
              className="button-item"
              type="danger"
              size="small"
              onClick={(e) => showDeleteConfirm(record)}
            >
              <DeleteOutlined />
            </Button>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "amount"
            ? "number"
            : col.dataIndex === "date"
            ? "date"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        size="small"
        scroll="scroll"
        tableLayout="fixed"
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        rowKey={rowKey}
      />
    </Form>
  );
}
