import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  BackTop
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditDurationForm from "../EditDurationForm";
import AddDurationForm from "../AddDurationForm";
import { activateDurationApi, deleteDurationApi } from "../../../../api/duration";
import { getCoursesByDurationApi } from "../../../../api/course";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListDurations.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListDurations(props) {
  const {
    durationsActive,
    durationsInactive,
    setReloadDurations,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewDurationsActives, setViewDurationsActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [durationsActiveFilter, setDurationsActiveFilter] = useState(
    []
  );
  const [ durationsInactiveFilter, setDurationsInactiveFilter ] = useState([]);

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    const listActive = [];
    if (durationsActive) {
      durationsActive.forEach((element) => {
          if (
            element.description
              .toUpperCase()
              .includes(filterText.toUpperCase()) ||
            element.duration_code.toString()
              .toUpperCase()
              .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            listActive.push(element);
          }
      });
    }
    setDurationsActiveFilter(listActive);
  }, [filterText, durationsActive]);

  useEffect(() => {
    const listInactive = [];
    if (durationsInactive) {
      durationsInactive.forEach((element) => {
          if (
            element.description
              .toUpperCase()
              .includes(filterText.toUpperCase()) ||
            element.duration_code.toString()
              .toUpperCase()
              .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
      });
    }
    setDurationsInactiveFilter(listInactive);
  }, [filterText, durationsInactive]);

  const addDurationModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Duración");
    setModalContent(
      <AddDurationForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadDurations={setReloadDurations}
      />
    );
  };

  return (
    <div className="list-durations">
      <div className="list-durations__header">
        {checkAction(permissionsActive, userToken.role, "durations", "all") ||
        checkAction(permissionsActive, userToken.role, "durations", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-durations__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewDurationsActives(!viewDurationsActives)
                }
              />
              <span>
                {viewDurationsActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
            <>
            </>
        )}

        {checkAction(permissionsActive, userToken.role, "durations", "all") ||
        checkAction(permissionsActive, userToken.role, "durations", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addDurationModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "durations", "all") ||
      checkAction(permissionsActive, userToken.role, "durations", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-durations__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> ejemplos de duración
            </h3>
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewDurationsActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "durations", "all") ||
          checkAction(permissionsActive, userToken.role, "durations", "view") || isAdmin(roleById) ? (
            <>
              <DurationsActive
                durationsActive={durationsActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadDurations={setReloadDurations}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "durations", "all") ||
          checkAction(permissionsActive, userToken.role, "durations", "view") || isAdmin(roleById) ? (
            <>
              <DurationsInactive
                durationsInactive={durationsInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadDurations={setReloadDurations}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>

      <BackTop/>
    </div>
  );
}

function DurationsActive(props) {
  const {
    durationsActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadDurations,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editDuration = (duration) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${duration.description ? duration.description : "sin descripcion"}`
    );
    setModalContent(
      <EditDurationForm
        duration={duration}
        setIsVisibleModal={setIsVisibleModal}
        setReloadDurations={setReloadDurations}
      />
    );
  };

  const desactivateDuration = (duration) => {
    activateDurationApi(accessToken, duration._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadDurations(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (duration) => {
    let response = await getCoursesByDurationApi(accessToken, duration._id);
    //console.log(response.courses);
    if (response.courses.length > 0) {
      confirm({
        title: "Eliminando -> Duración",
        content: `No se puede eliminar '${duration.description}' porque existen cursos asociados a él ¿Deseas desactivarlo?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          desactivateDuration(duration);
        },
      });
    } else {
      confirm({
        title: "Eliminando Duración",
        content: `¿Estás seguro que quieres eliminar a ${duration.description? duration.description : "sin descripción"}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteDurationApi(accessToken, duration._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadDurations(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const edit_duration= (
    <div>
      {" "}
      <p>Editar Duración</p>{" "}
    </div>
  );

  const desactivate_duration = (
    <div>
      {" "}
      <p>Pasar a duración inactivos</p>{" "}
    </div>
  );

  const delete_duration = (
    <div>
      {" "}
      <p>Eliminar duración</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Codigo",
      dataIndex: "duration_code",
    },
    {
      title: "Descripcion",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, duration) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "durations", "all") ||
          checkAction(permissionsActive, userToken.role, "durations", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_duration}>
                <Button
                  className="button-edit"
                  type="default"
                  onClick={() => editDuration(duration)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "durations", "all") ||
          checkAction(permissionsActive, userToken.role, "durations", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_duration}>
                <Button
                  className="button-duration"
                  type="primary"
                  onClick={() => desactivateDuration(duration)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "durations", "all") ||
          checkAction(permissionsActive, userToken.role, "durations", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_duration}>
                <Button
                  className="button-duration"
                  type="danger"
                  onClick={() => showDeleteConfirm(duration)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return durationsActive.length > 0? `Total de registros: ${durationsActive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={durationsActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function DurationsInactive(props) {
  const { durationsInactive,
          setReloadDurations,
          accessToken,
          userToken,
          permissionsActive,
          roleById
        } = props;

  const activateDuration = (duration) => {
    activateDurationApi(accessToken, duration._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadDurations(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (duration) => {
    let response = await getCoursesByDurationApi(accessToken, duration._id);
    if(response.courses.length > 0){
      ModalAntd.info({
        title: "Eliminando -> Duración",
        content:`No se puede eliminar '${duration.description}' porque existen cursos asociados a él`,
        onOk(){},
      });
    } else {
      confirm({
        title: "Eliminando -> Duración",
        content: `¿Estás seguro que quieres eliminar a ${duration.description? duration.description : "sin descripción"}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteDurationApi(accessToken, duration._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadDurations(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const activate_duration = (
    <div>
      {" "}
      <p>Pasar a duración activos</p>{" "}
    </div>
  );

  const delete_duration = (
    <div>
      {" "}
      <p>Eliminar duración</p>
    </div>
  );

  const columns = [
    {
      title: "Codigo",
      dataIndex: "duration_code",
    },
    {
      title: "Descripcion",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, duration) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "durations", "all") ||
          checkAction(permissionsActive, userToken.role, "durations", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_duration}>
                <Button
                  className="button-activate"
                  onClick={() => activateDuration(duration)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "durations", "all") ||
          checkAction(permissionsActive, userToken.role, "durations", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_duration}>
                <Button
                  className="button-duration"
                  type="danger"
                  onClick={() => showDeleteConfirm(duration)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return durationsInactive.length > 0? `Total de registros: ${durationsInactive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={durationsInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
