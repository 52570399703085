import React, {
  useState,
  useEffect,
  useCallback,
  //useRef
} from "react";
import "antd/dist/antd.css";
import {
  Switch,
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  BackTop,
  Avatar,
  Row,
  Col,
  List,
  Card,
  Space,
  Collapse,
  Upload,
  message,
  Image,
  Popover,
  Divider,
  Form,
  Select,
  
  //Popconfirm, Table, Radio, Select, Badge, Tag, InputNumber, Form,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  EyeOutlined,
  BgColorsOutlined,
  WhatsAppOutlined,
  FacebookOutlined,
  InstagramOutlined,
  FilePdfOutlined,
  InfoCircleTwoTone,
  StarFilled,
  EyeInvisibleOutlined,
  //SettingOutlined, StopOutlined, CheckOutlined, MenuOutlined, SaveOutlined,CopyOutlined,
} from "@ant-design/icons";
import NoAvatar from "../../../../assets/img/png/no-avatar.png";
import { useDropzone } from "react-dropzone";
import Modal from "../../../Modal";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import {
  uploadLogoApi,
  getLogoApi,
  getCoverApi,
  uploadCoverApi,
  getBranchApi,
  updateBranchApi,
} from "../../../../api/branch";
import {
  availableCategoryApi,
  deleteCategoryApi,
  getCategoriesApi,
} from "../../../../api/category";
import {
  getItemsByCategoryApi,
  deleteItemApi,
  activateItemApi,
  getItemsByBranchAndCategoryApi
} from "../../../../api/item";
import {
  getBranchMenuByBranchApi,
  getItemsBranchMenuByCategoryApi,
  availableItemBranchMenuByBranchApi,
  availableCategoryBranchMenuByBranchApi,
} from "../../../../api/branchMenu";
import jwtDecode from "jwt-decode";
import {EditSocialMediaForm, EditSocialMediaBusinessForm }from "../EditSocialMedia/EditSocialMediaForm";
import EditItemForm from "../EditItemForm";
import EditItemGeneralForm from "../EditItemGeneralForm";
import AddItemCategoryForm from "../AddItemCategoryForm";
import NoImage from "../../../../assets/img/png/no-image.png";
import { useBranch } from "../../../../hooks/useBranch";

import "./ListBranchItems.scss";
import { updateBusinessApi } from "../../../../api/business";
import EditCategoryForm from "../../Category/EditCategoryForm";

const { confirm } = ModalAntd;
const { TextArea } = Input;
const { Panel } = Collapse;
const { Meta } = Card;

export default function ListBranchItems(props) {
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [modalWidth, setModalWidth] = useState(0);


  const [imageCoverData, setImageCoverData] = useState(null);
  const [imageCoverUrl, setImageCoverUrl] = useState(null);

  const [imageLogoData, setImageLogoData] = useState(null);
  const [imageLogoUrl, setImageLogoUrl] = useState(null);

  const [categoriesActive, setCategoriesActive] = useState([]);
  const [categoriesInactive, setCategoriesInactive] = useState([]);

  const [reloadCategories, setReloadCategories] = useState([]);
  const [branchData, setBranchData] = useState({});
  const [businessData, setBusinessData] = useState({});
  const [branchMenuData, setBranchMenuData] = useState({});
  const [reloadBranch, setReloadBranch] = useState(false);

  const { branch } = useBranch();

  useEffect(() => {
    if (reloadCategories === true) {
      getBranchMenuByBranchApi(accessToken, branch).then((response) => {
        setBranchMenuData(response.branchMenu);
        const listCategories = response.branchMenu.categories.map(
          (i) => i.category
        );
        const listActive = response.branchMenu.categories.filter(
          (item) => item.available === true
        );
        const listActive2=listActive.map(
          (i) => i.category
        );
        const listInactive = response.branchMenu.categories.filter(
          (item) => item.available === false
        );
        const listInactive2=listInactive.map(
          (i) => i.category
        );
        setCategoriesActive(listActive2);
        setCategoriesInactive(listInactive2);
      });
      setReloadCategories(false);
    }
  }, [reloadCategories]);

  useEffect(() => {
    if (branch && branch !== "" && branch!==0 && branch!=="0") {
      getBranchMenuByBranchApi(accessToken, branch).then((response) => {
        setBranchMenuData(response.branchMenu);
        const listCategories = response.branchMenu.categories.map(
          (i) => i.category
        );
        const listActive = response.branchMenu.categories.filter(
          (item) => item.available === true
        );
        const listActive2=listActive.map(
          (i) => i.category
        );
        const listInactive = response.branchMenu.categories.filter(
          (item) => item.available === false
        );
        const listInactive2=listInactive.map(
          (i) => i.category
        );
        setCategoriesActive(listActive2);
        setCategoriesInactive(listInactive2);
        //setCategoriesActive(listCategories);
      });
    }else if(branch===0){
      getCategoriesApi(accessToken).then((response) => {
        setCategoriesActive(response.categories);
    });
    }
  }, [branch]);


  useEffect(() => {
    if (branch && branch !== "" && branch!=="0" && branch!==0) {
      getBranchApi(accessToken, branch).then((response) => {
        setBranchData(response.branch);
        console.log(response.branch)
        if(response.branch.isMain){
          setBusinessData(response.branch.business);
        }
      });
    }
  }, [branch]);

  useEffect(() => {
    if (branch && branch !== "" && branch!=="0" && branch!==0 && reloadBranch === true) {
      getBranchApi(accessToken, branch).then((response) => {
        setBranchData(response.branch);
        setReloadBranch(false);
        if(response.branch.isMain){
          setBusinessData(response.branch.business);
        }
      });
    }
  }, [reloadBranch]);

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  //si tiene logo lo trae, sino lo deja vacio
  useEffect(() => {
    if (branchData && branchData._id) {
      getBranchApi(accessToken, branchData._id).then((result) => {
          if (result.branch.logo) {
            getLogoApi(result.branch.logo).then((response) => {
              setImageLogoUrl(response);
            });
            setImageLogoData(result.branch.logo);
          }
      });
    }
  }, [branchData, imageLogoUrl]);

  //si tiene logo lo trae, sino lo deja vacio
  useEffect(() => {
    if (branchData && branchData._id) {
      getBranchApi(accessToken, branchData._id).then((result) => {
          if (result.branch.cover) {
            getCoverApi(result.branch.cover).then((response) => {
              setImageCoverUrl(response);
            });
            setImageCoverData(result.branch.cover);
          }
      });
    }
  }, [branchData, imageCoverUrl]);

  const handlePressEnter = () => {
    const updateBranch = branchData;
    updateBranchApi(accessToken, updateBranch, branchData._id).then((result) => {
      getBranchApi(accessToken, updateBranch._id).then((response) => {
        setBranchData(response.branch);
      });
    });
  };

  const handlePressEnterBusiness = () => {
    const updateBusiness = businessData;
    updateBusinessApi(accessToken, updateBusiness, businessData._id).then((result) => {
      getBranchApi(accessToken, branchData._id).then((response) => {
        setBranchData(response.branch);
        if(response.branch.isMain){
          setBusinessData(response.branch.business);
        }
      });
    });
  };

  const handleImageUpload = (url) => {
    setImageCoverUrl(url);
  };

  const handleImageLogoUpload = (url) => {
    setImageLogoUrl(url);
  };

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then((response) => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then((response) => {
      setRoleById(response.role);
    });
  }, []);

  const editSocialMedia = () => {
    setIsVisibleModal(true);
    setModalTitle(`Editar Redes sociales`);
    if(branchData.isMain){
      setModalContent(
        <EditSocialMediaBusinessForm
          setIsVisibleModal={setIsVisibleModal}
          business={businessData}
          setReloadBranch={setReloadBranch}
        />
      );

    }else{
      setModalContent(
        <EditSocialMediaForm
          setIsVisibleModal={setIsVisibleModal}
          branch={branchData}
          setReloadBranch={setReloadBranch}
        />
      );
    }
  };

  return (
    <div className="list-items">
      <div
      >
        {checkAction(permissionsActive, userToken.role, "items", "all") ||
        checkAction(permissionsActive, userToken.role, "items", "enable") ||
        isAdmin(roleById) ? (
          <>
            <div>
              <UploadImagenes
                onImageUpload={handleImageUpload}
                imageCoverUrl={imageCoverUrl}
                setImageCover={setImageCoverData}
                onImageLogoUpload={handleImageLogoUpload}
                imageLogoUrl={imageLogoUrl}
                setImageLogo={setImageLogoData}
                branchData={branchData}
              />
            </div>
            <div>
              <Row>
                <Col span={7}>
                  <label style={{ color: "black" }}>Nombre del Negocio</label>
                  {branchData.isMain?
                    <Input
                    showCount
                    maxLength={50}
                    value={businessData.name ? businessData.name : ""}
                    onChange={(e) =>
                      setBusinessData({ ...businessData, name: e.target.value })
                    }
                    onPressEnter={handlePressEnterBusiness}
                  />:
                  <Input
                    showCount
                    maxLength={50}
                    value={branchData.name ? branchData.name : ""}
                    onChange={(e) =>
                      setBranchData({ ...branchData, name: e.target.value })
                    }
                    onPressEnter={handlePressEnter}
                  />
                }
                </Col>
                <Col span={3}>
                  <Space
                    style={{
                      margin: "15px 0px 0px 10px",
                      padding: "8px 8px 8px",
                      //border: "1px solid #40a9ff"
                    }}
                  >
                    <StarFilled style={{ fontSize: "16px", color: "yellow" }} />
                    <Switch defaultUnChecked />
                  </Space>
                </Col>
                <Col span={5} offset={9}>
                  <Space
                    direction="horizontal"
                    style={{
                      margin: "15px 0px 0px 10px",
                      padding: "8px 8px 8px",
                    }}
                  >
                    <>
                      <Popover content={"Editar redes sociales"}>
                        <Button
                          type="link"
                          //className="button-edit"
                          onClick={() => editSocialMedia()}
                        >
                          {" "}
                          Editar
                        </Button>
                        <Divider type="vertical" />
                        <WhatsAppOutlined style={{ background: "black" }} />
                        <Divider type="vertical" />
                        <FacebookOutlined style={{ background: "black" }} />
                        <Divider type="vertical" />
                        <InstagramOutlined style={{ background: "black" }} />
                      </Popover>
                    </>
                  </Space>
                </Col>
              </Row>
              <Row className="my-row">
                <Col span={3}>
                  <Button> <BgColorsOutlined /> Cambiar Colores</Button>
                </Col>
                <Col span={8} offset={1}>
                  <label>Mostrar SKU</label> <InfoCircleTwoTone />{" "}
                  <Switch defaultUnChecked />
                </Col>
                <Col span={2} offset={6}>
                  <Button type="default" style={{ width: "80%" }}>
                    <FilePdfOutlined />
                  </Button>
                </Col>
                <Col span={4}>
                  <Button
                    type="primary"
                    style={{ backgroundColor: "red", width: "100%" }}
                  >
                    <EyeOutlined /> Ver en linea
                  </Button>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <></>
        )}

      </div>

      {checkAction(permissionsActive, userToken.role, "items", "all") ||
      checkAction(permissionsActive, userToken.role, "items", "view") ||
      isAdmin(roleById) ? (
        <>
          <div className="list-items__categories">
            {/* <MyCollapseWithActions/> */}
            <Divider orientation="left">Disponibles</Divider>
            <CategoriesActive
              categoriesActive={categoriesActive}
              setIsVisibleModal={setIsVisibleModal}
              setModalTitle={setModalTitle}
              setModalContent={setModalContent}
              setReloadCategories={setReloadCategories}
              accessToken={accessToken}
              userToken={userToken}
              permissionsActive={permissionsActive}
              roleById={roleById}
              setModalWidth={setModalWidth}
              branch={branch}
              isAvailable={true}
            />
       <Divider orientation="left">No disponibles</Divider>
         <CategoriesActive
              categoriesActive={categoriesInactive}
              setIsVisibleModal={setIsVisibleModal}
              setModalTitle={setModalTitle}
              setModalContent={setModalContent}
              setReloadCategories={setReloadCategories}
              accessToken={accessToken}
              userToken={userToken}
              permissionsActive={permissionsActive}
              roleById={roleById}
              setModalWidth={setModalWidth}
              branch={branch}
              isAvailable={false}
            />

            {/* <ListDraggable someData={listData} /> */}
          </div>
        </>
      ) : (
        <></>
      )}
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        modalWidth={modalWidth}
      >
        {modalContent}
      </Modal>
      <BackTop />
    </div>
  );
}


function UploadImagenes(props) {
  const {
    onImageUpload,
    imageCoverUrl,
    setImageCover,
    imageLogoUrl,
    setImageLogo,
    onImageLogoUpload,
    branchData,
  } = props;
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (imageCoverUrl) {
      setPreviewImage(imageCoverUrl);
    } else {
      setPreviewImage("");
    }
  }, [imageCoverUrl]);

  // Maneja el cambio en el estado del archivo
  const handleChange = (info) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      setFileList([info.file]); // Solo mostrar una imagen
      setPreviewImage(URL.createObjectURL(info.file.originFileObj));
      setEditing(false); // Termina el estado de edición
      onImageUpload(info.file.name); // Guardar el nombre del archivo
      setImageCover(info.file.name);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const customRequest = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append("file", file);
    const accessToken = getAccessToken();
    try {
      const response = await uploadCoverApi(
        accessToken,
        formData,
        branchData._id
      );
      console.log(response);
      if (response.status === 200) {
        onSuccess();
      } else {
        onError(new Error("Upload failed"));
      }
    } catch (error) {
      onError(error);
    }
  };

  const handleRemove = () => {
    setFileList([]);
    setPreviewImage(null);
    onImageUpload("");
    message.success("Image removed successfully");
  };

  // Maneja la edición de la imagen
  const handleEdit = () => {
    setEditing(true);
  };
  return (
    <div className="cover-container">
      <Image
        width="100%"
        height="280px"
        src={previewImage}
        preview={false}
        className="cover-image"
      />
      <Upload
        className="upload-button"
        customRequest={customRequest}
        onChange={handleChange}
        showUploadList={false} // No mostrar la lista de archivos
        accept="image/*"
        multiple={false} // Permitir solo un archivo
        disabled={editing} // Deshabilitar la carga durante la edición
      >
        <Button icon={<EditOutlined />}>Editar Imagen 1440 x 360px</Button>
      </Upload>
      <UploadImageAvatar
        onImageUpload={onImageLogoUpload}
        imageUrl={imageLogoUrl}
        setImage={setImageLogo}
        branchData={branchData}
      />
    </div>
  );
}

function UploadImageAvatar(props) {
  const { onImageUpload, imageUrl, setImage, branchData } = props;
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (imageUrl) {
      setPreviewImage(imageUrl);
    } else {
      setPreviewImage("");
    }
  }, [imageUrl]);

  // Maneja el cambio en el estado del archivo
  const handleChange = (info) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} el archivo ha subido correctamente`);
      setFileList([info.file]); // Solo mostrar una imagen
      setPreviewImage(URL.createObjectURL(info.file.originFileObj));
      setEditing(false); // Termina el estado de edición
      onImageUpload(URL.createObjectURL(info.file.originFileObj));
      setImage(info.file.name); // Guardar el nombre del archivo
      setLoading(false);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} la carga del archivo falló.`);
      setLoading(false);
    }
  };

  const customRequest = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append("file", file);
    const accessToken = getAccessToken();
    try {
      const response = await uploadLogoApi(
        accessToken,
        formData,
        branchData._id
      );
      console.log(response);
      if (response.status === 200) {
        onSuccess();
      } else {
        onError(new Error("La carga falló."));
      }
    } catch (error) {
      onError(error);
    }
  };

  // const handleRemove = () => {
  //   setFileList([]);
  //   setPreviewImage(null);
  //   onImageUpload("");
  //   setImage(null);
  //   message.success("Imagen eliminada correctamente");
  // };

  // Maneja la edición de la imagen
  const handleEdit = () => {
    setEditing(true);
  };
  return (
    <div className="avatar">
      <Upload
        customRequest={customRequest}
        onChange={handleChange}
        showUploadList={false} // No mostrar la lista de archivos
        accept="image/*"
        multiple={false} // Permitir solo un archivo
        disabled={editing} // Deshabilitar la carga durante la edición
      >
          {previewImage && previewImage !== null ? (
            <>
              <Avatar
                size={{
                  xs: 24,
                  sm: 32,
                  md: 60,
                  lg: 100,
                  xl: 140,
                  xxl: 220,
                }}
                src={previewImage !== "" ? previewImage : NoImage}
                shape="square"
                //style={{ display: "block", marginBottom: 8 }}
                // style={{ display: "block", marginBottom: 8 }}
              />
              {/* <Text>{fileName}</Text> */}
              <div className="button-edit-avatar">
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={handleEdit}
                  // style={{ marginRight: 8 }}
                ></Button>
                {/* <Button
                  type="link"
                  icon={<DeleteOutlined />}
                  onClick={handleRemove}
                  // style={{ position: 'absolute', top: 0, right: 0 }}
                /> */}
              </div>
            </>
          ) : (
            <>
              <Avatar
                size={{
                  xs: 24,
                  sm: 32,
                  md: 60,
                  lg: 100,
                  xl: 140,
                  xxl: 220,
                }}
                src={NoImage}
                shape="square"
                // style={{ display: "block", marginBottom: 8 }}
              />
              <div className="button-edit-avatar">
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={handleEdit}
                  // style={{ marginRight: 8 }}
                ></Button>
              </div>
            </>
          )}
      </Upload>
    </div>
  );
}

function CategoriesActive(props) {
  const {
    categoriesActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadCategories,
    accessToken,
    userToken,
    permissionsActive,
    roleById,
    setModalWidth,
    reloadCategories,
    branch,
    isAvailable
  } = props;

  const [listData, setListData] = useState([]);
  const [reload, setReload] = useState(false); //para que se actualicen los items dentro del panel
  const [reloadItems, setReloadItems] = useState(false);

  useEffect(() => {
    const renderFiltering = categoriesActive.map((filter, index) => {
      // const list= await getItemsByCategory(filter);
      return (
        <Panel className="ant-panel" header={filter.name} key={filter._id} extra={extraNode(filter)}>
          <ListItems
            categoryId={filter._id}
            accessToken={accessToken}
            setIsVisibleModal={setIsVisibleModal}
            setModalTitle={setModalTitle}
            setModalContent={setModalContent}
            setModalWidth={setModalWidth}
            branch={branch}
            reloadItems={reloadItems}
            setReloadItems={setReloadItems}
          />
        </Panel>
      );
    });
    setListData(renderFiltering);
    console.log("se actualiza lista");
  }, [categoriesActive, reloadCategories]);

  const handleExtraNodeClick = (panelKey) => {
    console.log(`Haz hecho clic en un nodo extra del panel ${panelKey._id}`);
    // Aquí puedes agregar la lógica correspondiente al nodo extra del panel
  };

  const handleDeleteCategory = async (category) => {
    // let response = await getStudentsByAdviserApi(accessToken, adviser._id);
    //Verifico que la categoria no tenga productos de la sucursal
    let response = await getItemsBranchMenuByCategoryApi(accessToken, branch, category._id);
    //let response = true;
    if (response === false) {
      confirm({
        title: "Eliminando -> Categoria",
        content: `No se puede eliminar '${category.name}' porque existen productos asociados ¿Deseas desactivarlo?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          // desactivateAdviser(adviser);
        },
      });
    } else {
      confirm({
        title: "Eliminando -> Categoria",
        content: `¿Estás seguro que quieres eliminar a ${category.name}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteCategoryApi(accessToken, category._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadCategories(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const handleEditCategory = (category) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar ${category.name ? category.name : "sin nombre"}`);
    setModalContent(
    <EditCategoryForm
      category={category}
      setIsVisibleModal={setIsVisibleModal}
      setReloadCategories={setReloadCategories}
    />);
  };

  const handleAddItemCategory = (category) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Agregar Producto ${category.name ? category.name : "sin nombre"}`
    );
    setModalWidth(1000);
    setModalContent(
      <AddItemCategoryForm
        category={category}
        categories={categoriesActive}
        setIsVisibleModal={setIsVisibleModal}
        setReloadCategories={setReloadCategories}
        setReloadItems={reloadItems}
        width={1000}
        branch={branch}
      />
    );
  };

  const handleDesactivateCategory = (category) => {
    console.log(category)
    availableCategoryBranchMenuByBranchApi(accessToken, branch, category._id,false)
      .then((response) => {
        if(response && response.code===200){
          return true;
        }
        return false;
      }).then(async(result)=>{
        if(result){
          if(category.isGeneral===false){
            let responseFinal= await availableCategoryApi(accessToken, category._id, false)
            if(responseFinal && responseFinal.code===200){
              notification["success"]({
                message: "La categoria no esta disponible en la sucursal",
              });
              setReloadCategories(true);
            }
          }else{
            notification["success"]({
              message: "La categoria no esta disponible en la sucursal",
            });
            setReloadCategories(true);
          }
        }
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const handleActivateCategory = (category) => {

    availableCategoryBranchMenuByBranchApi(accessToken, branch, category._id,true)
    //activateCategoryApi(accessToken, category._id, true)
      .then((response) => {
        if(response && response.code===200){
          return true;
        }
        return false;
      }).then(async(result)=>{
        if(result){
          if(category.isGeneral===false){
            let responseFinal= await availableCategoryApi(accessToken, category._id, true)
            if(responseFinal && responseFinal.code===200){
              notification["success"]({
                message: "La categoria esta disponible en la sucursal",
              });
              setReloadCategories(true);
            }
          }else{
            notification["success"]({
              message: "La categoria esta disponible en la sucursal",
            });
            setReloadCategories(true);
          }
        }
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const extraNode = (category) => (
    
    <div onClick={(e) => e.stopPropagation()}>
        <Button
        className="button-available"
        onClick={() => {
          isAvailable === true
            ? handleDesactivateCategory(category)
            : handleActivateCategory(category);
        }}
      >
        {isAvailable === true ? <EyeOutlined /> : <EyeInvisibleOutlined />}
      </Button> 
      <Button  className="button-add" onClick={() => handleAddItemCategory(category)}>
        <PlusOutlined />
      </Button>
      <Button  className="button-edit" onClick={() => handleEditCategory(category)}>
        <EditOutlined />
      </Button>
      <Button  className="button-delete" onClick={() => handleDeleteCategory(category)}>
        <DeleteOutlined />
      </Button>
    </div>
  );

  const handleButtonClick = (panelKey) => {
    console.log(`Acción en el panel ${panelKey}`);
    // Aquí podrías agregar lógica adicional según la acción realizada en el panel
  };

  return (
    <Collapse defaultActiveKey={["1"]} className="antd-collapse" accordion>
      {listData}
    </Collapse>
  );
}

function ListItems(props) {
  const {
    categoryId,
    accessToken,
    setIsVisibleModal,
    setModalContent,
    setModalTitle,
    setModalWidth,
    reload,
    branch,
    reloadItems,
    setReloadItems
  } = props;

  const [listData, setListData] = useState([]);
  // const [reloadItems, setReloadItems] = useState(false);

  useEffect(() => {
    getBranchMenuByBranchApi(accessToken,branch).then(response=>{
      if(response.branchMenu && response.branchMenu.items){
        const itemsCategory = response.branchMenu.items.filter(producto => producto.item.category === categoryId);
        const data=itemsCategory.map((x)=>{return x.item})
        setListData(data);
      }
    })
    setReloadItems(false);
  }, [categoryId, reloadItems]);

  return (
    <List
      // grid={{ gutter: 16, column: 3 }} //
      itemLayout="vertical"
      size="large"
      dataSource={listData}
      renderItem={(item, index) => (
        <List.Item>
          <ItemCard
            item={item}
            index={index}
            setReloadItems={setReloadItems}
            accessToken={accessToken}
            setModalWidth={setModalWidth}
            setIsVisibleModal={setIsVisibleModal}
            setModalTitle={setModalTitle}
            setModalContent={setModalContent}
            branch={branch}
          />
        </List.Item>
      )}
    />
  );
}

function ItemCard(props) {
  const {
    item,
    index,
    setReloadItems,
    setIsVisibleModal,
    setModalContent,
    setModalTitle,
    setModalWidth,
    accessToken,
    branch
  } = props;

  const handleDeleteItem = async (item) => {
    // let response = await getStudentsByAdviserApi(accessToken, adviser._id);
    let response = true;
    if (response === false) {
      confirm({
        title: "Eliminando -> Producto",
        content: `No se puede eliminar '${item.name}' porque existen ... asociados ¿Deseas desactivarlo?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          // desactivateAdviser(adviser);
        },
      });
    } else {
      confirm({
        title: "Eliminando -> Producto",
        content: `¿Estás seguro que quieres eliminar a ${item.name}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteItemApi(accessToken, item._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadItems(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const handleEditItem = (item) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar ${item.name ? item.name : "sin nombre"}`);
    setModalWidth(1000);
    setModalContent();
    // <EditItemForm
    //   item={item}
    //   setIsVisibleModal={setIsVisibleModal}
    //   setReloadItems={setReloadItems}
    // />
  };

  ///TODO
  const handleNotAvailableItem = (item) => {
    availableItemBranchMenuByBranchApi(accessToken, branch, item._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadItems(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const handleActivateItem = (item) => {
    activateItemApi(accessToken, item._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadItems(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  return (
    <Card
    style={{
      width: "100%",
      borderRadius: '10px',
      borderWidth: "2px"
    }}
  >
    {item && item.isGeneral && item.isGeneral===true?
      <EditItemGeneralForm
      item={item}
      setIsVisibleModal={setIsVisibleModal}
      setReloadItems={setReloadItems}
      isEditingModal={false}
      handleDeleteItem={handleDeleteItem}
      handleActivateItem={handleActivateItem}
      handleDesactivateItem={handleNotAvailableItem} //cambiar en el edit form
    />
    :
    <EditItemForm
    item={item}
    setIsVisibleModal={setIsVisibleModal}
    setReloadItems={setReloadItems}
    isEditingModal={false}
    handleDeleteItem={handleDeleteItem}
    handleActivateItem={handleActivateItem}
    handleDesactivateItem={handleNotAvailableItem} //cambiar en el edit form
  />}
  </Card>
  );
}
