import React, { useState, useEffect} from 'react';

import{getMenuApi} from "../../../api/menu";
import { getAccessToken } from "../../../api/auth";

import ListMenu from "../../../components/Admin/Menu/ListMenu";


export default function Menu(){
    const [menu, setMenu]=useState([]);
    const[reloadMenu, setReloadMenu]=useState(false);

    useEffect(() => {
       const token= getAccessToken();
       getMenuApi(token).then(response=>{
            setMenu(response.menu);
        });
         setReloadMenu(false);
    }, [reloadMenu]);

    return (
        <div className="menu">
            <ListMenu menu={menu} setReloadMenu={setReloadMenu}/>
        </div>
    );
}