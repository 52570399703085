import React from 'react';
//import { PluralizeEsp} from "../../utils/convert"

export default function Admin() {
    return (
        <div>
            {/* PONER UN MENSAJE DE BIENVENIDA, POR EJ BIENVENIDO 'USUARIO'
                <h1>Estamos en Admin</h1>
            */}
            {/*<h1>{PluralizeEsp("rol")}</h1>*/}

        </div>
    );
}