import React from "react";
import { Result, Button } from "antd";
import { useHistory } from "react-router-dom";

export default function Error403(props) {

    let history = useHistory();

    const handleBack=()=>{
        return history.goBack();
    }


    return (
        <Result
        status="403"
        title="403"
        subTitle="Lo sentimos, no esta autorizado el acceso a esta pagina."
        extra={<Button type="primary" onClick={()=>handleBack()}>Volver atras</Button>}
      />
    );
}