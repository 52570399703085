import React, { useState, useEffect, useCallback } from "react";
import {
  Avatar,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import {
  UserOutlined,
  MailOutlined,
  EnvironmentOutlined,
  DollarCircleOutlined,
  IdcardOutlined,
  PhoneOutlined,
  ScheduleOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { useDropzone } from "react-dropzone";
import NoAvatar from "../../../../assets/img/png/no-avatar.png";
import {
  getSupplierAvatarApi,
  uploadSupplierAvatarApi,
  updateSupplierApi,
} from "../../../../api/billing/supplier";
import { getAccessToken } from "../../../../api/auth";
import { getPersonsActiveApi } from "../../../../api/person";
import { getSupplierTypesActiveApi } from "../../../../api/billing/supplierType";

import "./EditSupplierForm.scss";

export default function EditSupplierForm(props) {
  const { supplier, setIsVisibleModal, setReloadSuppliers } = props;
  const [avatar, setAvatar] = useState(null);
  const [supplierData, setSupplierData] = useState({});
  const [listPersonsActive, setListPersonsActive] = useState([]);
  const [listSupplierTypesActive, setListSupplierTypesActive] = useState([]);

  //estado relacionado a si el usuario realizó modificación en los inputs que deben ser validados
  const [modifDataToValid, setModifDataToValid] = useState({
    person: false,
    denomination: false,
    taxPosition: false,
  });

  //trae los datos del supplier
  useEffect(() => {
    setSupplierData({
      person: supplier.person._id,
      denomination: supplier.denomination,
      taxPosition: supplier.taxPosition,
    });
  }, [supplier]);

  useEffect(() => {
    const accessToken = getAccessToken();
    getPersonsActiveApi(accessToken, true).then((response) => {
      setListPersonsActive(response.persons);
    });
  }, []);

  useEffect(() => {
    const accessToken = getAccessToken();
    getSupplierTypesActiveApi(accessToken, true).then((response) => {
      setListSupplierTypesActive(response.supplierTypes);
    });
  }, []);

  // //si tiene avatar lo trae, sino lo deja vacio
  // useEffect(() => {
  //   if (supplier.avatar) {
  //     getSupplierAvatarApi(supplier.avatar).then((response) => {
  //       setAvatar(response);
  //     });
  //   } else {
  //     setAvatar(null);
  //   }
  // }, [supplier]);

  // //si se carga un avatar lo prepara para enviar a la db
  // useEffect(() => {
  //   if (avatar) {
  //     setSupplierData({ ...supplierData, avatar: avatar.file });
  //   }
  // }, [avatar]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (
      !supplierData.person ||
      !supplierData.denomination
    ) {
      notification["error"]({
        message: "Campos obligatorios: Persona y denominacion",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const updateSupplier = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let supplierUpdate = supplierData; //es let porque se va actualizando

      if (typeof supplierUpdate.avatar === "object") {
        //actualiza el avatar del supplier
        uploadSupplierAvatarApi(
          token,
          supplierUpdate.avatar,
          supplier._id
        ).then((response) => {
          supplierUpdate.avatar = response.avatarName;
          updateSupplierApi(token, supplierUpdate, supplier._id).then(
            (result) => {
              notification["success"]({
                message: result.message, //el mensaje que viene del server
              });
              setIsVisibleModal(false);
              setReloadSuppliers(true);
            }
          );
        });
      } else {
        //actualiza el supplier menos el avatar
        updateSupplierApi(token, supplierUpdate, supplier._id).then(
          (result) => {
            if (
              result.message === "ERR_CONNECTION_REFUSED" ||
              result.message === "Failed to fetch" ||
              result.message === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
              setIsVisibleModal(true);
            } else if (
              result.message === "e-mail ya registrado...seleccione otro"
            ) {
              notification["error"]({
                message: "e-mail ya registrado...seleccione otro",
              });
              setIsVisibleModal(true);
            } else {
              notification["success"]({
                message: result.message, //el mensaje que viene del server
              });
              //console.log("respuesta: " + result.message);
              setIsVisibleModal(false);
              setReloadSuppliers(true);
            }
          }
        );
      }
    }
  };

  return (
    <div className="edit-supplier-form">
      {/* <UploadSupplierAvatar avatar={avatar} setAvatar={setAvatar} /> */}
      <EditForm
        supplierData={supplierData}
        setSupplierData={setSupplierData}
        updateSupplier={updateSupplier}
        setModifDataToValid={setModifDataToValid}
        modifDataToValid={modifDataToValid}
        listPersonsActive={listPersonsActive}
        listSupplierTypesActive={listSupplierTypesActive}
      />
    </div>
  );
}

// function UploadSupplierAvatar(props) {
//   const { avatar, setAvatar } = props;
//   const [avatarUrl, setAvatarUrl] = useState(null);

//   useEffect(() => {
//     if (avatar) {
//       if (avatar.preview) {
//         setAvatarUrl(avatar.preview);
//       } else {
//         setAvatarUrl(avatar);
//       }
//     }
//   }, [avatar]);

//   const onDrop = useCallback(
//     (acceptedFiles) => {
//       const file = acceptedFiles[0];
//       //console.log("file: " + file);
//       if (file === undefined) {
//         notification["error"]({
//           message: "Formato de imagen no válido, aceptados: png, jpg, bpm.",
//         });
//         return;
//       } else {
//         //const file = acceptedFiles[0];
//         console.log("URL.createObjectURL(file): " + URL.createObjectURL(file));
//         setAvatar({ file, preview: URL.createObjectURL(file) });
//       }
//     },
//     [setAvatar]
//   );

//   const { getRootProps, getInputProps, isDragActive } = useDropzone({
//     accept: "image/jpeg, image/png, image/bmp",
//     noKeyboard: true,
//     onDrop,
//   });

//   return (
//     <div className="upload-avatar" {...getRootProps()}>
//       <input {...getInputProps()} />
//       {isDragActive ? (
//         <Avatar size={150} src={NoAvatar} />
//       ) : (
//         <Avatar size={150} src={avatarUrl ? avatarUrl : NoAvatar} />
//       )}
//     </div>
//   );
// }

function EditForm(props) {
  const {
    supplierData,
    setSupplierData,
    setModifDataToValid,
    modifDataToValid,
    updateSupplier,
    listPersonsActive,
    listSupplierTypesActive,
  } = props;
  const { Option } = Select;

  return (
    <Form
      className="form-edit"
      onSubmit={updateSupplier} //onFinish={updateSupplier}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
            <span className="control-required">*</span>Persona
          </label>
          <Form.Item>
            <Select
              showSearch
              placeholder="Selecciona una persona"
              value={supplierData.person}
              onChange={(e) => setSupplierData({ ...supplierData, person: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listPersonsActive
                ? listPersonsActive.map((person) => {
                    return (
                      <Option key={person._id}>
                        {person.name + " " + person.lastname}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item>
            <label className="control-label">Denominacion</label>
            <Input
              type="text"
              placeholder="Denominacion"
              value={supplierData.denomination}
              onChange={(e) =>
                setSupplierData({
                  ...supplierData,
                  denomination: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
        {/* <Col span={6}>
          <Form.Item>
            <label className="control-label">Tipo</label>
            <Select
              prefix={<TeamOutlined />}
              className="select-form"
              placeholder="Selecciona el tipo de proveedor"
              onChange={(e) => setSupplierData({ ...supplierData, type: e })}
              value={supplierData.type}
            > */}
        {/*
              <Option value="products">Productos</Option>
              <Option value="services">Servicios</Option>
              <Option value="supplies">Insumos</Option>
              <Option value="fixed-assets">Bienes de uso</Option>
              <Option value="various">Varios</Option>
              <Option value="others">Otros</Option>
              */}
        {/* {listSupplierTypesActive
                ? listSupplierTypesActive.map((supplierType) => {
                    return (
                      <Option key={supplierType._id} value={supplierType.description}>
                        {supplierType.description}
                      </Option>
                    );
                  })
                : null} */}
        {/* </Select>
          </Form.Item>
        </Col> */}
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label"> Posicion de impuestos</label>
          <Form.Item>
            <Input
              name="tax_position"
              placeholder="Posicion en impuestos"
              value={supplierData.taxPosition}
              onChange={(e) => {
                setSupplierData({
                  ...supplierData,
                  taxPosition: e.target.value,
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateSupplier}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
