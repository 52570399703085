import React, { useState, useEffect } from "react";
import { Switch, List, Avatar, Button, notification, Modal as ModalAntd, Popover, Space, BackTop } from "antd";
import { EditOutlined, StopOutlined, DeleteOutlined, CheckOutlined, 
    //ExportOutlined, 
    PlusOutlined, DownloadOutlined } from '@ant-design/icons';

import NoAvatar from "../../../../assets/img/png/no-avatar.png";
import Modal from "../../../Modal";
import EditUserForm from "../EditUserForm";
import AddUserForm from "../AddUserForm";
import { listUsersPdf, userDetailPdf } from "./pdfUsers";
import { getUserAvatarApi, activateUserApi, deleteUserApi } from "../../../../api/user";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";
//import useRouteProps from "../../../../hooks/UseRouteProps";
//import { PluralizeEsp } from "../../../../utils/convert";


import "./ListUsers.scss";
//import { getUserAvatar } from "../../../../../../server/controllers/user";

const { confirm } = ModalAntd;

export default function ListUsers(props) {
    const { usersActive, usersInactive, setReloadUsers } = props;
    const [permissionsActive, setPermissionsActive] = useState([]);
    const [roleById, setRoleById] = useState([]);
    const [ viewUsersActives, setViewUsersActives ] = useState(true);
    const [ isVisibleModal, setIsVisibleModal ] = useState(false);
    const [ modalTitle, setModalTitle ] = useState("");
    const [ modalContent, setModalContent ] = useState(null)
    const [
        filterText, 
        //setFilterText
    ] = useState("");
    const [
        usersActiveFilter, 
        setUsersActiveFilter
    ] = useState([]);
    const [usersInactiveFilter, setUsersInactiveFilter] = useState([]);
    const [
        typeFilter, 
        //setTypeFilter
    ] = useState("name");
    //const [title, setTitle]=useState(useRouteProps().title);

    // console.log(useRouteProps());
    const accessToken = getAccessToken();
    const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

    useEffect(() => {
        getPermissionsActiveApi(accessToken, true).then(response => {
            setPermissionsActive(response.permissions);
        });
    }, []);

    useEffect(() => {
        getRoleByIdApi(accessToken, userToken.role).then(response => {
            setRoleById(response.role);
        });
    }, []);

    useEffect(() => {
        const listActive = [];
        if (usersActive) {
            usersActive.forEach((element) => {
                if (element.name){
                    if (typeFilter === "name") {
                        if (
                          element.name.toUpperCase().indexOf(filterText.toUpperCase()) !==
                            -1 ||
                          filterText === ""
                        ) {
                          listActive.push(element);
                        }
                    } else {
                        if (element.email.toString().indexOf(filterText) !== -1 || filterText === "") {
                          listActive.push(element);
                        }
                    }
                }
          });
        }
        setUsersActiveFilter(listActive);
      }, [filterText, usersActive]);
    
      useEffect(() => {
        const listInactive = [];
        if (usersInactive) {
            usersInactive.forEach((element) => {
                if (element.name){
                    if (typeFilter === "name") {
                    if (
                        element.name.toUpperCase().indexOf(filterText.toUpperCase()) !==
                        -1 ||
                        filterText === ""
                        ) {
                            listInactive.push(element);
                    }
                    } else {
                        if (element.email.toString().indexOf(filterText) !== -1 || filterText === "") {
                            listInactive.push(element);
                        }
                    }
                }
          });
        }
        setUsersInactiveFilter(listInactive);
      }, [filterText, usersInactive]);

    const addUserModal = () => {
        setIsVisibleModal(true);
        setModalTitle(`Crear ${"Usuario"}`);
        setModalContent(
            <AddUserForm
                setIsVisibleModal={setIsVisibleModal}
                setReloadUsers={setReloadUsers}
            />
        );
    };

    // TO DO: optimizar funcion para que sea llamada desde aqui (en userActive/userInactive)
    /*const showDeleteConfirm = (props) => {
        console.log("este es el user: ");
        console.log(user);
        const accessToken = getAccessToken();

        confirm({
            title: "Eliminando usuario",
            content: `¿Estás seguro que quieres eliminar a ${user.email}?`,
            okText: "Eliminar",
            okType: "danger",
            cancelText: "Cancelar",
            onOk() {
                deleteUserApi(accessToken, user._id)
                    .then(response => {
                        notification["success"]({
                            message: response
                        });
                        setReloadUsers(true);
                    })
                    .catch(err => {
                        notification["error"]({
                            message: err
                        });
                    });
            }
        });
    };*/

    //console.log(usersActive);
    //console.log(usersInactive);

    return (
        <div className="list-users">

            <div className="list-users__header">
                {checkAction(permissionsActive, userToken.role, "users", "all") ||
                checkAction(permissionsActive, userToken.role, "users", "enable") || isAdmin(roleById) ? (
                <>
                    <div className="list-users__header-switch">
                        <Switch
                            defaultChecked
                            onChange={() => setViewUsersActives(!viewUsersActives)}
                        />
                        <span>
                            {viewUsersActives ? `Activos` : `Inactivos`}
                        </span>
                    </div>
                </>
                ) : (
                <>
                </>
                )}

                {checkAction(permissionsActive, userToken.role, "users", "all") ||
                checkAction(permissionsActive, userToken.role, "users", "add") || isAdmin(roleById) ? (
                <>
                    <Button type="primary" icon={<PlusOutlined/>} onClick={addUserModal}>
                       {`Nuevo`}
                    </Button>
                </>
                ) : (
                <>
                </>
                )}
            </div>

            {viewUsersActives ? (
                <>
                    {checkAction(permissionsActive, userToken.role, "users", "all") ||
                    checkAction(permissionsActive, userToken.role, "users", "pdf") || isAdmin(roleById) ? (
                    <>
                        <Space align="center" style={{ marginBottom: 16 }}>
                        <Button
                            type="default"
                            shape="round"
                            onClick={() => {
                                listUsersPdf(usersActive, false);
                            }}
                        >
                            <DownloadOutlined /> Listado Usuarios
                        </Button>
                        </Space>
                    </>
                    ) : (
                        <>
                        </>
                    )}
                    {checkAction(permissionsActive, userToken.role, "users", "all") ||
                    checkAction(permissionsActive, userToken.role, "users", "view") || isAdmin(roleById) ? (
                        <>
                            <UsersActive
                                usersActive={usersActive}
                                setIsVisibleModal={setIsVisibleModal}
                                setModalTitle={setModalTitle}
                                setModalContent={setModalContent}
                                setReloadUsers={setReloadUsers}
                                accessToken={accessToken}
                                userToken={userToken}
                                permissionsActive={permissionsActive}
                                roleById={roleById}
                                //showDeleteConfirm={showDeleteConfirm}
                            />
                        </>
                    ) : (
                        <>
                        </>
                    )}
                </>
                ) : (
                    <>
                    {checkAction(permissionsActive, userToken.role, "users", "all") ||
                    checkAction(permissionsActive, userToken.role, "users", "pdf") || isAdmin(roleById) ? (
                        <>
                            <Space align="center" style={{ marginBottom: 16 }}>
                                <Button
                                    type="default"
                                    shape="round"
                                    onClick={() => {
                                        listUsersPdf(usersInactiveFilter, false);
                                    }}
                                    >
                                    <DownloadOutlined /> Listado Usuarios
                                </Button>
                            </Space>
                        </>
                    ) : (
                        <>
                        </>
                    )}
                    {checkAction(permissionsActive, userToken.role, "users", "all") ||
                    checkAction(permissionsActive, userToken.role, "users", "view") || isAdmin(roleById) ? (
                        <>
                            <UsersInactive
                                usersInactive={usersInactive}
                                setReloadUsers={setReloadUsers}
                                accessToken={accessToken}
                                userToken={userToken}
                                permissionsActive={permissionsActive}
                                roleById={roleById}
                                //showDeleteConfirm={showDeleteConfirm}
                            />
                        </>
                    ) : (
                        <>
                        </>
                    )}
                </>
            )}

            <Modal
                title={modalTitle}
                isVisible={isVisibleModal}
                setIsVisible={setIsVisibleModal}
            >
                {modalContent}
            </Modal>

            <BackTop/>
        </div>
    );
}

function UsersActive(props) {
    const { usersActive,
            setIsVisibleModal,
            setModalTitle,
            setModalContent,
            setReloadUsers,
            accessToken,
            userToken,
            permissionsActive,
            roleById
        } = props; //showDeleteConfirm

    const editUser = user => {
        //console.log(user);
        setIsVisibleModal(true);
        setModalTitle(`Editar usuario ${user.email ? user.username : "usuario"}`);
        setModalContent(<EditUserForm user={user} setIsVisibleModal={setIsVisibleModal} setReloadUsers={setReloadUsers} />);
        //setModalContent(<MenuTop user={user} setIsVisibleModal={setIsVisibleModal} setReloadUsers={setReloadUsers} />);
    };

    return (
        <List
            className="users-active"
            itemLayout="horizontal"
            dataSource={usersActive}
            renderItem={user => (
                <UserActive user={user}
                    editUser={editUser}
                    setReloadUsers={setReloadUsers}
                    accessToken={accessToken}
                    userToken={userToken}
                    permissionsActive={permissionsActive}
                    roleById={roleById}
                />
            )} //showDeleteConfirm={showDeleteConfirm}
        />
    );
}

function UserActive(props) {
    const { user, editUser, setReloadUsers, accessToken, userToken, permissionsActive, roleById } = props; //showDeleteConfirm
    const [ avatar, setAvatar ] = useState(null);

    useEffect(() => {
        if(user.avatar) {
            getUserAvatarApi(user.avatar).then(response => {
                setAvatar(response);
            })
        } else {
            setAvatar(null);
        }
    }, [user]);

    const desactivateUser = () => {
        activateUserApi(accessToken, user._id, false)
            .then(response => {
                notification["success"]({
                    message: response
                });
                setReloadUsers(true);
            })
            .catch(err => {
                notification["error"]({
                    message: err
                });
            });
    };

    const showDeleteConfirm = () => {
        confirm({
            title: "Eliminando usuario",
            content: `¿Estás seguro que quieres eliminar a ${user.username}?`,
            okText: "Eliminar",
            okType: "danger",
            cancelText: "Cancelar",
            onOk() {
                deleteUserApi(accessToken, user._id)
                    .then(response => {
                        notification["success"]({
                            message: response
                        });
                        setReloadUsers(true);
                    })
                    .catch(err => {
                        notification["error"]({
                            message: err
                        });
                    });
            }
        });
    };

    const edit_user = (
        <div> <p>Editar usuario</p> </div>
    );

    const desactivate_user = (
        <div> <p>Pasar a usuarios desactivados</p> </div>
    );

    const delete_user = (
        <div> <p>Eliminar usuario</p> </div>
    );
    const download_user = (
        <div>
            {" "}
            <p>Descargar detalle usuario</p>{" "}
        </div>
    );

    return (
        <List.Item
            actions={[
                <div>
                    {checkAction(permissionsActive, userToken.role, "users", "all") ||
                    checkAction(permissionsActive, userToken.role, "users", "edit") || isAdmin(roleById) ? (
                    <>
                        <Popover content={edit_user} >
                            <Button
                                className="button-edit"
                                onClick={() => editUser(user)}
                            >
                                <EditOutlined />
                            </Button>
                        </Popover>
                    </>
                    ) : (
                        <>
                        </>
                    )}
                </div>,
                <div>
                    {checkAction(permissionsActive, userToken.role, "users", "all") ||
                    checkAction(permissionsActive, userToken.role, "users", "enable") || isAdmin(roleById) ? (
                    <>
                        <Popover content={desactivate_user} >
                            <Button
                                type="primary"
                                onClick={desactivateUser}
                            >
                                <StopOutlined />
                            </Button>
                        </Popover>
                    </>
                    ) : (
                        <>
                        </>
                    )}
                </div>,
                <div>
                    {checkAction(permissionsActive, userToken.role, "users", "all") ||
                    checkAction(permissionsActive, userToken.role, "users", "delete") || isAdmin(roleById) ? (
                    <>
                        <Popover content={delete_user} >
                            <Button
                                type="danger"
                                onClick={showDeleteConfirm}
                            >
                                <DeleteOutlined />
                            </Button>
                        </Popover>
                        </>
                    ) : (
                        <>
                        </>
                    )}
                </div>,
                <div>
                    {checkAction(permissionsActive, userToken.role, "users", "all") ||
                    checkAction(permissionsActive, userToken.role, "users", "pdf") || isAdmin(roleById) ? (
                    <>
                        <Popover content={download_user}>
                            <Button type="primary" onClick={() => {
                                userDetailPdf(user, false);
                                }}
                            >
                                <DownloadOutlined />
                            </Button>
                        </Popover>
                        </>
                    ) : (
                        <>
                        </>
                    )}
                </div>,
            ]}
        >
            <List.Item.Meta
                avatar={<Avatar src={avatar ? avatar : NoAvatar} />}
                title={`
                    ${user.username ? user.email : "..."}
                `}
                description={user.email}
            />
        </List.Item>
    )
}

function UsersInactive(props) {
    const { usersInactive, setReloadUsers, accessToken, userToken, permissionsActive, roleById } = props; //showDeleteConfirm
    return (
        <List
            className="users-active"
            itemLayout="horizontal"
            dataSource={usersInactive}
            renderItem={user => (
                <UserInactive
                    user={user}
                    setReloadUsers={setReloadUsers}
                    accessToken={accessToken}
                    userToken={userToken}
                    permissionsActive={permissionsActive}
                    roleById={roleById}
                 />)} //showDeleteConfirm={showDeleteConfirm}
        />
    );
}

function UserInactive(props){
    const { user, setReloadUsers, accessToken, userToken, permissionsActive, roleById } = props; //showDeleteConfirm
    const [ avatar, setAvatar ] = useState(null);

    useEffect(() => {
        if(user.avatar) {
            getUserAvatarApi(user.avatar).then(response => {
                setAvatar(response);
            })
        } else {
            setAvatar(null);
        }
    }, [user]);

    const activateUser = () => {
        activateUserApi(accessToken, user._id, true)
            .then(response => {
                notification["success"]({
                    message: response
                });
                setReloadUsers(true);
            })
            .catch(err => {
                notification["error"]({
                    message: err
                });
            });
    };

    const showDeleteConfirm = () => {
        confirm({
            title: "Eliminando usuario",
            content: `¿Estás seguro que quieres eliminar a ${user.username}?`,
            okText: "Eliminar",
            okType: "danger",
            cancelText: "Cancelar",
            onOk() {
                deleteUserApi(accessToken, user._id)
                    .then(response => {
                        notification["success"]({
                            message: response
                        });
                        setReloadUsers(true);
                    })
                    .catch(err => {
                        notification["error"]({
                            message: err
                        });
                    });
            }
        });
    };

    const activate_user = (
        <div> <p>Pasar a usuarios activos</p> </div>
    );

    const delete_user = (
        <div> <p>Eliminar usuario</p> </div>
    );

    const download_user = (
        <div>
            {" "}
            <p>Descargar detalle usuario</p>{" "}
        </div>
    );

    return (
        <List.Item
        actions={[
            <div>
                {checkAction(permissionsActive, userToken.role, "users", "all") ||
                checkAction(permissionsActive, userToken.role, "users", "enable") || isAdmin(roleById) ? (
                <>
                    <Popover content={activate_user} >
                        <Button
                            className="button-activate"
                            onClick={ activateUser }
                        >
                            <CheckOutlined />
                        </Button>
                    </Popover>
                </>
                ) : (
                    <>
                    </>
                )}
            </div>,
            <div>
                {checkAction(permissionsActive, userToken.role, "users", "all") ||
                checkAction(permissionsActive, userToken.role, "users", "delete") || isAdmin(roleById) ? (
                <>
                    <Popover content={delete_user} >
                        <Button
                            type="danger"
                            onClick={showDeleteConfirm}
                        >
                            <DeleteOutlined />
                        </Button>
                    </Popover>
                </>
                ) : (
                    <>
                    </>
                )}
            </div>,
            <div>
                {checkAction(permissionsActive, userToken.role, "users", "all") ||
                checkAction(permissionsActive, userToken.role, "users", "pdf") || isAdmin(roleById) ? (
                <>
                    <Popover content={download_user}>
                            <Button
                                type="primary"
                                onClick={() => {
                                    userDetailPdf(user, false);
                                }}
                                >
                                <DownloadOutlined />
                            </Button>
                    </Popover>
                </>
                ) : (
                    <>
                    </>
                )}
            </div>,
        ]}
    >
        <List.Item.Meta
            avatar={<Avatar src={avatar ? avatar : NoAvatar} />}
            title={`
                ${user.username ? user.username : "..."}
            `}
            description={user.email}
        />
    </List.Item>
    )
}