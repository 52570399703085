import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getPaymentOrdersActiveApi } from "../../../api/billing/paymentOrder";
import ListPaymentOrders from "../../../components/Billing/PaymentOrders/ListPaymentOrders";


export default function PaymentOrders() {
    const [paymentOrdersActive, setPaymentOrdersActive] = useState([]);
    const [paymentOrdersInactive, setPaymentOrdersInactive] = useState([]);
    const [reloadPaymentOrders, setReloadPaymentOrders ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getPaymentOrdersActiveApi(token, true).then(response => {
            setPaymentOrdersActive(response.paymentOrders);
        });
        getPaymentOrdersActiveApi(token, false).then(response => {
            setPaymentOrdersInactive(response.paymentOrders);
        });
        setReloadPaymentOrders(false);
    }, [token, reloadPaymentOrders]);

    return (
        <div className="paymentOrders">

            <ListPaymentOrders paymentOrdersActive={paymentOrdersActive} paymentOrdersInactive={paymentOrdersInactive} setReloadPaymentOrders={setReloadPaymentOrders}
                />

        </div>
    );
}