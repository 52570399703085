import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  BackTop
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditRoleForm from "../EditRoleForm";
import AddRoleForm from "../AddRoleForm";
import { activateRoleApi, deleteRoleApi, getRoleByIdApi } from "../../../../api/role";
import { getUsersByRoleApi } from "../../../../api/user";
import { getPermissionsByRoleApi } from "../../../../api/permission";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListRoles.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListRoles(props) {
  const { rolesActive, rolesInactive, setReloadRoles } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewRolesActives, setViewRolesActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [rolesActiveFilter, setRolesActiveFilter] = useState(
    []
  );
  const [ rolesInactiveFilter, setRolesInactiveFilter ] = useState([]);

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    const listActive = [];
    if (rolesActive) {
      rolesActive.forEach((element) => {
          if (
            element.name
              .toUpperCase()
              .includes(filterText.toUpperCase()) ||
            element.role_code
              .toString()
              .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            listActive.push(element);
          }
      }
      );
    }
    setRolesActiveFilter(listActive);
  }, [filterText, rolesActive]);

  useEffect(() => {
    const listInactive = [];
    if (rolesInactive) {
      rolesInactive.forEach((element) => {
          if (
            element.name
              .toUpperCase()
              .includes(filterText.toUpperCase()) ||
            element.role_code
              .toString()
              .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
      });
    }
    setRolesInactiveFilter(listInactive);
  }, [filterText, rolesInactive]);

  const addRoleModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Rol");
    setModalContent(
      <AddRoleForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadRoles={setReloadRoles}
      />
    );
  };

  return (
    <div className="list-roles">
      <div className="list-roles__header">
        {checkAction(permissionsActive, userToken.role, "roles", "all") ||
        checkAction(permissionsActive, userToken.role, "roles", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-roles__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewRolesActives(!viewRolesActives)
                }
              />
              <span>
                {viewRolesActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
            <>
            </>
        )}

        {checkAction(permissionsActive, userToken.role, "roles", "all") ||
        checkAction(permissionsActive, userToken.role, "roles", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addRoleModal}>
              Nuevo
            </Button>
          </>
          ) : (
            <>
            </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "roles", "all") ||
      checkAction(permissionsActive, userToken.role, "roles", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-roles__search">
            <Search
              className="Buscar"
              allowClear
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
          </div>
        </>
        ) : (
        <>
        </>
      )}

      {viewRolesActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "roles", "all") ||
          checkAction(permissionsActive, userToken.role, "roles", "view") || isAdmin(roleById) ? (
            <>
              <RolesActive
                rolesActive={rolesActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadRoles={setReloadRoles}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
            ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "roles", "all") ||
          checkAction(permissionsActive, userToken.role, "roles", "view") || isAdmin(roleById) ? (
            <>
              <RolesInactive
                rolesInactive={rolesInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadRoles={setReloadRoles}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>

      <BackTop/>
    </div>
  );
}

function RolesActive(props) {
  const {
    rolesActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadRoles,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editRole = (role) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${role.name ? role.name : "sin nombre"}`
    );
    setModalContent(
      <EditRoleForm
        role={role}
        setIsVisibleModal={setIsVisibleModal}
        setReloadRoles={setReloadRoles}
      />
    );
  };

  const desactivateRole = (role) => {
    activateRoleApi(accessToken, role._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadRoles(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (role) => {
    let response = await getUsersByRoleApi(accessToken, role._id);
    let response2 = await getPermissionsByRoleApi(accessToken, role._id);
    
    if ( (response.users && response.users.length > 0) || (response2.users && response2.users.length > 0)) {
      confirm({
        title: "Eliminando -> Rol",
        content: `No se puede eliminar '${role.name}' porque existen usuarios o permisos asociados a él ¿Deseas desactivarlo?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          desactivateRole(role);
        },
      });
    }
    else {
      confirm({
        title: "Eliminando -> Rol",
        content: `¿Estás seguro que quieres eliminar a ${role.name}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteRoleApi(accessToken, role._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadRoles(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const edit_role = (
    <div>
      {" "}
      <p>Editar Rol</p>{" "}
    </div>
  );

  const desactivate_role = (
    <div>
      {" "}
      <p>Pasar a Roles inactivos</p>{" "}
    </div>
  );

  const delete_role = (
    <div>
      {" "}
      <p>Eliminar Rol</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "role_code",
    },
    {
      title: "Nombre",
      dataIndex: "name",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, role) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "roles", "all") ||
          checkAction(permissionsActive, userToken.role, "roles", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_role}>
                <Button
                  className="button-edit"
                  onClick={() => editRole(role)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "roles", "all") ||
          checkAction(permissionsActive, userToken.role, "roles", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_role}>
                <Button
                  className="button-role"
                  type="primary"
                  onClick={() => desactivateRole(role)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "roles", "all") ||
          checkAction(permissionsActive, userToken.role, "roles", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_role}>
                <Button
                  className="button-role"
                  type="danger"
                  onClick={() => showDeleteConfirm(role)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return rolesActive.length > 0? `Total de registros: ${rolesActive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={rolesActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function RolesInactive(props) {
  const { rolesInactive,
          setReloadRoles,
          accessToken,
          userToken,
          permissionsActive,
          roleById
        } = props;

  const activateRole = (role) => {
    activateRoleApi(accessToken, role._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadRoles(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (role) => {
    let response = await getUsersByRoleApi(accessToken, role._id);
    let response2 = await getPermissionsByRoleApi(accessToken, role._id);
    if ( (response.users && response.users.length > 0) || (response2.users && response2.users.length > 0)) {
      ModalAntd.info({
        title: "Eliminando -> Rol",
        content:`No se puede eliminar '${role.name}' porque existen usuarios o permisos asociados a él`,
        onOk(){},
      });
    } else {
      confirm({
        title: "Eliminando -> Rol",
        content: `¿Estás seguro que quieres eliminar a ${role.name}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteRoleApi(accessToken, role._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadRoles(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const activate_role = (
    <div>
      {" "}
      <p>Pasar a Roles activos</p>{" "}
    </div>
  );

  const delete_role = (
    <div>
      {" "}
      <p>Eliminar Rol</p>
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "role_code",
    },
    {
      title: "Nombre",
      dataIndex: "name",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, role) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "roles", "all") ||
          checkAction(permissionsActive, userToken.role, "roles", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_role}>
                <Button
                  className="button-activate"
                  onClick={() => activateRole(role)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "roles", "all") ||
          checkAction(permissionsActive, userToken.role, "roles", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_role}>
                <Button
                  className="button-role"
                  type="danger"
                  onClick={() => showDeleteConfirm(role)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return rolesInactive.length > 0? `Total de registros: ${rolesInactive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={rolesInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
