import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getBuyOrdersActiveApi, getBuyOrdersPaidApi } from "../../../api/billing/buyOrder";
import ListBuyOrders from "../../../components/Billing/BuyOrders/ListBuyOrders";

import "./BuyOrders.scss";

export default function BuyOrders() {
    const [buyOrdersActive, setBuyOrdersActive] = useState([]);
    const [buyOrdersInactive, setBuyOrdersInactive] = useState([]);
    const [ reloadBuyOrders, setReloadBuyOrders ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getBuyOrdersActiveApi(token, true).then(response => {
            setBuyOrdersActive(response.buyOrders);
        });
        setReloadBuyOrders(false);
    }, [token, reloadBuyOrders]);

    useEffect(() => {
        getBuyOrdersActiveApi(token, false).then(response => {
            setBuyOrdersInactive(response.buyOrders);
        });
        setReloadBuyOrders(false);
    }, [token, reloadBuyOrders]);

    return (
        <div className="billorders">
            <ListBuyOrders buyOrdersActive={buyOrdersActive} BuyOrdersInactive={buyOrdersInactive}
                            setReloadBuyOrders={setReloadBuyOrders} token={token} />
        </div>
    );
}