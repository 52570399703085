import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getSellersActiveApi } from "../../../api/billing/seller";
import ListSellers from "../../../components/Billing/Sellers/ListSellers";


export default function Sellers() {
    const [sellersActive, setSellersActive] = useState([]);
    const [sellersInactive, setSellersInactive] = useState([]);
    const [reloadSellers, setReloadSellers ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getSellersActiveApi(token, true).then(response => {
            setSellersActive(response.sellers);
        });
        getSellersActiveApi(token, false).then(response => {
            setSellersInactive(response.sellers);
        });
        setReloadSellers(false);
    }, [token, reloadSellers]);

    return (
        <div className="sellers">

            <ListSellers sellersActive={sellersActive} sellersInactive={sellersInactive} setReloadSellers={setReloadSellers}
                />

        </div>
    );
}