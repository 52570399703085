import {
  downloadPdf,
  openPdf,
  getLogo,
  getHeader,
  getStyles,
} from "../../../../utils/pdfManager";
import { convertState, convertPaid } from "../../../../utils/convert";
import { formatDateView } from "../../../../utils/formatDate";
import Logo from "../../../../assets/img/png/ball.png";


function billsDetailsPdf(data) {
  const arrayBills = data.map(function (bill) {
    return ` ${bill.code} `;
  });

  return [{ text: `${arrayBills}` }];
}

function checksDetailsPdf(data) {
  return data.map((check) => {
    return [
      { text: `Nro Cheque: ${check.number ? check.number : ""} `},
      { text: `Importe: ${check.amount ? check.amount : ""}` },
      { text: `Fecha: ${check.date ? formatDateView(check.date) : ""}` },
      { text: `Banco: ${check.bank ? check.bank : ""}` },
    ];
  });
}
export function listPaymentOrderPdf(data, isDownload) {
  console.log(data);
  const _format = (data) => {
    return data.map((order) => {
      return [
        { text: order.code },
        { text: order.supplier },
        { text: order.total },
        { text: formatDateView(order.date) },
        { text: convertPaid(order.paid) },
        { text: order.observation },
        { text: order.bills },
      ];
    });
  };

  const formattedData = _format(data);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "landscape",
    header: getHeader(),
    content: [
      { text: "Listado de Orden de pago" },
      "\n",
      {
        table: {
          headerRow: 1,
          dontBreakRows: true,
          body: [
            [
              { text: "Código", style: "tableHeader" },
              { text: "Proveedor", style: "tableHeader" },
              { text: "Total", style: "tableHeader" },
              { text: "Estado", style: "tableHeader" },
              { text: "Fecha", style: "tableHeader" },
              { text: "¿Pagado?", style: "tableHeader" },
              { text: "Observaciones", style: "tableHeader" },
              { text: "bills", style: "tableHeader" },
            ],
            ...formattedData,
          ],
        },
      },
    ],
  };
  if (isDownload) {
    downloadPdf(docDefinition, "ListadoFacturasDeCompra");
  } else {
    openPdf(docDefinition);
  }
}

export function paymentOrderDetailPdf(data, isDownload) {
  //console.log(data.items);

  const _format = (data) => {
    return [
      { text: `Orden de Pago Nro.: ${data.code ? data.code : ""}` },
      { text: `Proveedor: ${data.supplier.name ? data.supplier.name : ""}` },
      { text: `Total: $ ${data.total ? data.total : ""}` },
      { text: `Fecha: ${formatDateView(data.date)}` },
      { text: `Observaciones: ${data.observation ? data.observation : ""}` },
      //{text:`Items: ${itemsPdf(data.items)}`},
    ];
  };

  const formattedData2 = checksDetailsPdf(data.checks);
  const formattedData3 = billsDetailsPdf(data.bills);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "vertical",
    content: [
      {
          columns:[{
            text: `NOMBRE DE LA EMPRESA`,
           alignment: "left",
           bold: true,
          },{ image: Logo, width: 40, heigth: 40, alignment: "left"},
          {text:"Orden de Pago", alignment:"right", bold: true}]
      },
      "\n",
      {
        table: {
          widths: ["*", 25, 25, "*"],
          headerRows: 1,
          // keepWithHeaderRows: 1,
          body: [
            [
              {
                text: [
                  { text: `Orden de Pago Nro: `, bold: true, italics: true },
                  {
                    text: `${data.code ? data.code : ""}\n`,
                    bold: true,
                    fontSize: 14,
                  },
                  "\n",
                  { text: ` Proveedor: `, bold: true, italics: true},
                  {
                    text: `${data.supplier ? data.supplier.name : ""}\n`,
                  },
                  "\n",
                  "\n",
                  "\n",
                  { text: ` Total a pagar: `, bold: true, italics: true },
                  {
                    text: `${data.total ? data.total : ""}\n`,
                  },
                ],
                alignament: "center",
                border: [false, true, false, false],
                colSpan: 2,
              },
              {},
              {
                text: [
                  { text: `Fecha de emision: `, bold: true, italics: true },
                  { text: `${formatDateView(data.date)}\n` },
                  "\n",
                  { text: `Importe: `, bold: true, italics: true },
                  { text: `${data.total}\n` },
                  "\n",
                  { text: ` Retencion de Ganancias `, bold: true, italics: true },
                  { text: ` xxxxx\n` },
                  "\n",
                  { text: ` Retencion de IIBB BS AS  `, bold: true, italics: true },
                  { text: ` xxxxxxxx\n` },
                  "\n",
                  { text: ` Retencion de IIBB CABA `, bold: true, italics: true },
                  { text: ` xxxxxxxx\n` },
                ],
                alignament: "center",
                colSpan: 2,
                border: [false, true, false, false],
              },
              {},
            ],
          ],
        },
      },
      "\n",
      "\n",
      {
        table: {
          widths: ["*"],
          headerRows: 1,
          body:[
              [ {
                text: [{ text: `Facturas: `, bold: true, italics: true }, { text: formattedData3 }],
                border:[false, true, false, false]
              }],
              
           ],
        }
      },
      "\n",
      "\n",
      {
        table: {
          widths: ["*"],
          headerRows: 1,
          body:[
              [ {
                text: [ { text: `Formas de pago: `, bold: true, italics: true }, { text:`${data.paymentType}` }],
                border:[false, false, false, false]
              }],
              
           ],
        }
      },
      "\n",
      {
        table: {
          widths: ["*", "*", "*", "*"],
          headerRows: 1,
          // keepWithHeaderRows: 1,
          body: [...formattedData2],
          border: [false, false, false, true],
        },
      },
      "\n",
      {
        table: {
          widths: ["*"],
          headerRows: 1,
          body:[
              [ {
                text: [ { text: `Efectivo: `, bold: true, italics: true }, { text:`${data.cash}`}],
                border:[false, false, false, false]
              }],
              
           ],
        },
      },
      "\n",
      "\n",
      {
        table: {
          widths: ["*"],
          headerRows: 1,
          body:[
              [ { text: `Observaciones:\n`, bold: true,italics: true, border: [false, true, false, false]}],
              [ { text: `${data.observation}`, border: [false, false, false, false] },
              ],
              
           ],
            colSpan: 4,
        }
      },
      "\n",
    ],
    styles: getStyles(),
  };
  let title = `${data.name}`;
  if (isDownload) {
    downloadPdf(docDefinition, title);
  } else {
    openPdf(docDefinition);
  }
}
