import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  //Switch, TimePicker, Tabs, Space, Card, Tag, Checkbox, List, Select,
} from "antd";

import {
  CheckCircleTwoTone,
  WhatsAppOutlined,
  TwitterOutlined,
  FacebookOutlined,
  InstagramOutlined
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import {
  //getStoreApi,
  addBranchApi,
  updateBranchApi,
} from "../../../../api/branch"; //getCourseApi
import { getAccessToken } from "../../../../api/auth";
import "./EditSocialMediaForm.scss";
import { updateBusinessApi } from "../../../../api/business";

export function EditSocialMediaForm(props) {
  const {  
    //setStoreId, 
    branch,setIsVisibleModal,setReloadBranch
   } = props;
   const [disabledSubmit, setDisabledSubmit] = useState(false);
   const [ branchData, setBranchData ] = useState({});

  const [form] = Form.useForm();

  const accessToken = getAccessToken();

  //para VOLVER HACIA ATRAS
  const history = useHistory();

  useEffect(() => {
    setBranchData({
        _id:branch._id,
        name: branch.name,
      whatsapp: branch.whatsapp,
      facebook: branch.facebook,
      instagram: branch.instagram,
      twitter: branch.twitter,
      tiktok: branch.tiktok,
    });
  }, [branch]);

  const onCheck = async () => {
    try {
      let errorExists = false;
      if (!branchData.name || !branchData.whatsapp) {
        notification["error"]({
          message: "No hay datos para guardar.",
        });
        errorExists = true;
      }
      return errorExists;
    } catch (errorInfo) {
      //console.log("Failed:", errorInfo);
      return false;
    }
  };

  const isSuccess = (e) => {
    if (!e || e === "") {
      return <CheckCircleTwoTone />;
    } else {
      return <CheckCircleTwoTone twoToneColor="#52c41a" />;
    }
  };

  const isFormValid = (e) => {
    //console.log(inputs);
    let errorExists = false;
    if (!branchData.name) {
      notification["error"]({
        message: "Requeridos: Nombre.",
      });
      errorExists = true;
    }
    // else if (formValid.email === false && inputs.email !== "") {
    //   //BUG
    //   notification["error"]({
    //     message: "Email inválido",
    //   });
    //   errorExists = true;
    // }
    return errorExists;
  };

  const addUpdateBranch= async (e) => {
    e.preventDefault();

    //if (!isFormValid()) {
      const accessToken = getAccessToken();
      let branchUpdate = branchData; //es let porque se va actualizando

      await updateBranchApi(accessToken, branchUpdate, branchData._id)
        .then((response) => {
          if (
            response === "ERR_CONNECTION_REFUSED" ||
            response === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              message: "Redes sociales actualizadas",
            });
            setIsVisibleModal(false);
            setReloadBranch(true);
          }
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    //}
  };

  return (
    <div className="edit-item-form">
      <EditForm
        data={branchData}
        setData={setBranchData}
        addUpdate={addUpdateBranch}
        form={form}
      />
    </div>
  );
}

export function EditSocialMediaBusinessForm(props) {
  const {  
    //setStoreId, 
    business,setIsVisibleModal,setReloadBranch
   } = props;
   const [disabledSubmit, setDisabledSubmit] = useState(false);
   const [ businessData, setBusinessData ] = useState({});

  const [form] = Form.useForm();

  const accessToken = getAccessToken();

  //para VOLVER HACIA ATRAS
  const history = useHistory();

  useEffect(() => {
    setBusinessData({
        _id:business._id,
        name: business.name,
      whatsapp: business.whatsapp,
      facebook: business.facebook,
      instagram: business.instagram,
      twitter: business.twitter,
      tiktok: business.tiktok,
    });
  }, [business]);

  const onCheck = async () => {
    try {
      let errorExists = false;
      if (!businessData.name || !businessData.whatsapp) {
        notification["error"]({
          message: "No hay datos para guardar.",
        });
        errorExists = true;
      }
      return errorExists;
    } catch (errorInfo) {
      //console.log("Failed:", errorInfo);
      return false;
    }
  };

  const isSuccess = (e) => {
    if (!e || e === "") {
      return <CheckCircleTwoTone />;
    } else {
      return <CheckCircleTwoTone twoToneColor="#52c41a" />;
    }
  };

  const addUpdateBusiness= async (e) => {
    e.preventDefault();

    //if (!isFormValid()) {
      const accessToken = getAccessToken();
      let businessUpdate = businessData; //es let porque se va actualizando

      await updateBusinessApi(accessToken, businessUpdate, businessData._id)
        .then((response) => {
          if (
            response === "ERR_CONNECTION_REFUSED" ||
            response === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              message: "Redes sociales actualizadas",
            });
            setIsVisibleModal(false);
            setReloadBranch(true);
          }
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    //}
  };

  return (
    <div className="edit-item-form">
      <EditForm
        data={businessData}
        setData={setBusinessData}
        addUpdate={addUpdateBusiness}
        form={form}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    data,
    setData,
    addUpdate,
    form
  } = props;

  return (
    <Form className="form-edit" form={form} onSubmit={addUpdate}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">Número de WhatsApp</label>
          <Form.Item>
            <Input
              prefix={<WhatsAppOutlined />}
              //placeholder="Automático"
              value={data.whatsapp}
              onChange={(e) =>
                setData({ ...data, whatsapp: e.target.value })
              }
              style={{ width: "100%" }}
              //disabled={true}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">Facebook (opcional)</label>
          <Form.Item>
            <Input
              prefix={<FacebookOutlined />}
              //placeholder="Automático"
              value={data.facebook}
              onChange={(e) =>
                setData({ ...data, facebook: e.target.value })
              }
              style={{ width: "100%" }}
              //disabled={true}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">Instagram (opcional)</label>
          <Form.Item>
            <Input
              prefix={<InstagramOutlined />}
              //placeholder="Automático"
              value={data.instagram}
              onChange={(e) =>
                setData({ ...data, instagram: e.target.value })
              }
              style={{ width: "100%" }}
              //disabled={true}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">TikTok (opcional)</label>
          <Form.Item>
            <Input
              //prefix={<WhatsAppOutlined />}
              //placeholder="Automático"
              value={data.tiktok}
              onChange={(e) =>
                setData({ ...data, tiktok: e.target.value })
              }
              style={{ width: "100%" }}
              //disabled={true}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">Twitter (opcional)</label>
          <Form.Item>
            <Input
              prefix={<TwitterOutlined />}
              //placeholder="Automático"
              value={data.twitter}
              onChange={(e) =>
                setData({ ...data, twitter: e.target.value })
              }
              style={{ width: "100%" }}
              //disabled={true}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addUpdate}
        >
          Guardar cambios
        </Button>
      </Form.Item>
    </Form>
  );
}
