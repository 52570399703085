import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import {
  TagOutlined,
} from "@ant-design/icons";
import { updateDeliveryTermApi } from "../../../../api/deliveryTerm";
import { getAccessToken } from "../../../../api/auth";

import "./EditDeliveryTermForm.scss";

export default function EditDeliveryTermForm(props) {
  const { deliveryTerm, setIsVisibleModal, setReloadDeliveryTerms } = props;
  const [ deliveryTermData, setDeliveryTermData ] = useState({});


  //trae los datos de tipo de documento
  useEffect(() => {
    setDeliveryTermData({
      deliveryterm_code: deliveryTerm.deliveryterm_code,
      description: deliveryTerm.description
    });
  }, [deliveryTerm]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!deliveryTermData.deliveryterm_code || !deliveryTermData.description) {
      notification["error"]({
        message: "Obligatorios: Código y Descripción.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const updateDeliveryTerm = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let deliveryTermUpdate = deliveryTermData; //es let porque se va actualizando

      updateDeliveryTermApi(token, deliveryTermUpdate, deliveryTerm._id).then((result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //console.log("respuesta: " + result.message);
          setIsVisibleModal(false);
          setReloadDeliveryTerms(true);
        }
      });
    }
  };

  return (
    <div className="edit-deliveryTerm-form">
      <EditForm
        deliveryTermData={deliveryTermData}
        setDeliveryTermData={setDeliveryTermData}
        updateDeliveryTerm={updateDeliveryTerm}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    deliveryTermData,
    setDeliveryTermData,
    updateDeliveryTerm,
  } = props;

  return (
    <Form className="form-edit" onSubmit={updateDeliveryTerm}>
      <Row gutter={24}>
        <Col span={12}>
          <label className="control-label">Código</label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Código"
              value={deliveryTermData.deliveryterm_code}
              onChange={(e) =>
                setDeliveryTermData({ ...deliveryTermData, deliveryterm_code: e })
              }
              min={1}
              style={{width:"90%"}}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label"><span className="control-required">* </span>Descripción</label>
          <Form.Item>
            <Input
              placeholder="Descripción"
              value={deliveryTermData.description}
              onChange={(e) =>
                setDeliveryTermData({ ...deliveryTermData, description: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateDeliveryTerm}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
