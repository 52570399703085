import React, { useState } from "react";
import { Form, Input, Button, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
//import { emailValidation, minLengthValidation } from '../../../utils/formValidation';
import { signInApi } from "../../../api/user";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../../../utils/constants";
import "./LoginForm.scss";
//import { refreshAccessToken } from "../../../api/auth";

export default function LoginForm() {
  //return 'LoginForm componente...';
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const changeForm = (e) => {
    //console.log("asasassa");
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const login = async (e) => {
    //async se usa con await
    e.preventDefault(); //esto evita que se refresque la pantalla al presionar el boton de submit
    //console.log(inputs);
    const result = await signInApi(inputs); //le dice a la funcion que pare en esta ejec hasta que termine y siga con las prox lineas
    if (result === "Failed to fetch") {
      notification["error"]({
        message: "Error del servidor",
      });
    } else if (result.message) {
      notification["error"]({
        message: result.message,
      });
    } else {
      const { accessToken, refreshToken } = result;
      //verificar inspeccionando el cod fuente en Application -> Storage -> LocalStorage -> http://localhost:3000
      if (accessToken && refreshToken) {
        localStorage.setItem(ACCESS_TOKEN, accessToken);
        localStorage.setItem(REFRESH_TOKEN, refreshToken);

        notification["success"]({
          message: "Login correcto.",
        });

        window.location.href = "/admin"; //redireccion una vez logueado
      }
    }

    // console.log(result);
  };

  // en desuso
  //const recoverPassword = (e) => {
  //  console.log("a vista recuperar contraseña");
  //};

  return (
    <Form className="login-form" onChange={changeForm} onSubmit={login}>
      <Form.Item>
        <Input
          prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25" }} />}
          type="email"
          name="email"
          placeholder="Correo electrónico"
          className="login-form__input"
        />
      </Form.Item>
      <Form.Item>
        <Input
          prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25" }} />}
          type="password"
          name="password"
          placeholder="Contraseña"
          className="login-form__input"
        />
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          className="login-form__button"
          onClick={login}
        >
          Ingresar
        </Button>
      </Form.Item>
      {/* 
            <Form.Item>
                <a className="login-form__recover" href="RegisterForm.js" onClick={recoverPassword}>
                    Olvidé mi contraseña
                </a>
            </Form.Item>
            */}
    </Form>
  );
}
