import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"; // para localhost
//import { HashRouter as Router, Route, Switch } from 'react-router-dom'; // para netlify
import routes from "./config/routes";
import AuthProvider from "./providers/AuthProvider";
import BusinessProvider from "./providers/BusinessProvider";
import BranchProvider from "./providers/BranchProvider";
import "./App.scss";

/*
const BrowserRouter = require('react-router-dom'); //USAR BrowserRouter.Router, BrowserRouter.Switch, BrowserRouter.Route
const appscss = require('./App.scss')
*/

//ESTE USAMOS
function App() {
  console.log({ REACT_APP_API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT });
  return (
    <AuthProvider>
      <Router basename="/">
      <BusinessProvider>
        <BranchProvider>
          <Switch>
            {routes.map((route, index) => (
              <RouteWithSubRoutes key={index} {...route} />
            ))}
          </Switch>
        </BranchProvider>
        </BusinessProvider>
      </Router>
    </AuthProvider>
  );
}

function RouteWithSubRoutes(route) {
  //console.log(route);
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component routes={route.routes} {...props} />}
    />
  );
}

export default App;
