import React, { useState } from "react";
import { Form, Input, InputNumber, Button, Row, Col, notification } from "antd";
import { TagOutlined } from "@ant-design/icons";
import { addIvaConditionApi } from "../../../../api/billing/ivaCondition";
import { getAccessToken } from "../../../../api/auth";

import "./AddIvaConditionForm.scss";

export default function AddIvaConditionForm(props) {
  const { setIsVisibleModal, setReloadIvaConditions } = props;

  const [inputs, setInputs] = useState({
    ivacondition_code: "",
    description: "",
  });

  const isFormValid = (e) => {
    //console.log(inputs);
    let errorExists = false;
    if (!inputs.description) {
      notification["error"]({
        message: "Obligatorios: descripción.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addIvaCondition = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      const accessToken = getAccessToken();

      await addIvaConditionApi(accessToken, inputs)
        .then((response) => {
          if (
            response === "ERR_CONNECTION_REFUSED" ||
            response === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              message: "Condición IVA creada",
            });
            setIsVisibleModal(false);
          }
          setReloadIvaConditions(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  return (
    <div className="add-ivacondition-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        //inputValidation={inputValidation}
        addIvaCondition={addIvaCondition}
      />
    </div>
  );
}

function AddForm(props) {
  const { inputs, setInputs, addIvaCondition } = props;

  return (
    <Form className="form-add" onSubmit={addIvaCondition}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Código"
              value={inputs.ivacondition_code}
              onChange={(e) => setInputs({ ...inputs, ivacondition_code: e })}
              style={{ width: "90%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Descripción
          </label>
          <Form.Item>
            <Input
              placeholder="Descripción"
              value={inputs.description}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  description: e.target.value.toUpperCase(),
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addIvaCondition}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
