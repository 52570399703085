import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditIvaConditionForm from "../EditIvaConditionForm";
import AddIvaConditionForm from "../AddIvaConditionForm";
import {
  activateIvaConditionApi,
  deleteIvaConditionApi,
} from "../../../../api/billing/ivaCondition";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListIvaConditions.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListIvaConditions(props) {
  const {
    ivaConditionsActive,
    ivaConditionsInactive,
    setReloadIvaConditions,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewIvaConditionsActives, setViewIvaConditionsActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [ivaConditionsActiveFilter, setIvaConditionsActiveFilter] = useState(
    []
  );
  const [ ivaConditionsInactiveFilter, setIvaConditionsInactiveFilter ] = useState([]);
  const [typeFilter, setTypeFilter] = useState("description");

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, [roleById]);

  useEffect(() => {
    const listActive = [];
    if (ivaConditionsActive) {
      ivaConditionsActive.forEach((element) => {
        if (typeFilter === "description") {
          if (
            element.description
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        } else {
          if (
            element.ivacondition_code
              .toString()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        }
      });
    }
    setIvaConditionsActiveFilter(listActive);
  }, [filterText, ivaConditionsActive]);

  useEffect(() => {
    const listInactive = [];
    if (ivaConditionsInactive) {
      ivaConditionsInactive.forEach((element) => {
        if (typeFilter === "description") {
          if (
            element.description
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        } else {
          if (
            element.ivacondition_code
              .toString()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        }
      });
    }
    setIvaConditionsInactiveFilter(listInactive);
  }, [filterText, ivaConditionsInactive]);

  const addIvaConditionModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Condición IVA");
    setModalContent(
      <AddIvaConditionForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadIvaConditions={setReloadIvaConditions}
      />
    );
  };

  return (
    <div className="list-ivaconditions">
      <div className="list-ivaconditions__header">
        {checkAction(permissionsActive, userToken.role, "ivaconditions", "all") ||
        checkAction(permissionsActive, userToken.role, "ivaconditions", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-ivaconditions__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewIvaConditionsActives(!viewIvaConditionsActives)
                }
              />
              <span>
                {viewIvaConditionsActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
        ) : (
          <>
          </>
        )}

        {checkAction(permissionsActive, userToken.role, "ivaconditions", "all") ||
        checkAction(permissionsActive, userToken.role, "ivaconditions", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/> }onClick={addIvaConditionModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "ivaconditions", "all") ||
      checkAction(permissionsActive, userToken.role, "ivaconditions", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-ivaconditions__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <Radio.Group
              options={[
                { label: "Descripción", value: "description" },
                { label: "Código", value: "code" },
              ]}
              onChange={(e) => {
                setTypeFilter(e.target.value);
              }}
              value={typeFilter}
            />
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> ejemplos de condición de IVA 'sujeto exento, responsable inscripto, responsable no inscripto, responsable monotributo, consumidor final'
            </h3>
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewIvaConditionsActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "ivaconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "ivaconditions", "view") || isAdmin(roleById) ? (
            <>
              <IvaConditionsActive
                ivaConditionsActive={ivaConditionsActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadIvaConditions={setReloadIvaConditions}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "ivaconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "ivaconditions", "view") || isAdmin(roleById) ? (
            <>
              <IvaConditionsInactive
                ivaConditionsInactive={ivaConditionsInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadIvaConditions={setReloadIvaConditions}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function IvaConditionsActive(props) {
  const {
    ivaConditionsActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadIvaConditions,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editIvaCondition = (ivaCondition) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${ivaCondition.description ? ivaCondition.description : "sin descripción"}`
    );
    setModalContent(
      <EditIvaConditionForm
        ivaCondition={ivaCondition}
        setIsVisibleModal={setIsVisibleModal}
        setReloadIvaConditions={setReloadIvaConditions}
      />
    );
  };

  const desactivateIvaCondition = (ivaCondition) => {
    const accessToken = getAccessToken();

    activateIvaConditionApi(accessToken, ivaCondition._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadIvaConditions(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (ivaCondition) => {
    const accessToken = getAccessToken();
    confirm({
      title: "Eliminando Condición IVA",
      content: `¿Estás seguro que quieres eliminar a ${ivaCondition.description}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteIvaConditionApi(accessToken, ivaCondition._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadIvaConditions(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const edit_ivaCondition = (
    <div>
      {" "}
      <p>Editar Condición IVA</p>{" "}
    </div>
  );

  const desactivate_ivaCondition = (
    <div>
      {" "}
      <p>Pasar a Condición IVA inactivos</p>{" "}
    </div>
  );

  const delete_ivaCondition = (
    <div>
      {" "}
      <p>Eliminar Condición IVA</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "ivacondition_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, ivaCondition) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "ivaconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "ivaconditions", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_ivaCondition}>
                <Button
                  className="button-ivacondition"
                  type="default"
                  onClick={() => editIvaCondition(ivaCondition)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "ivaconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "ivaconditions", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_ivaCondition}>
                <Button
                  className="button-ivacondition"
                  type="primary"
                  onClick={() => desactivateIvaCondition(ivaCondition)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "ivaconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "ivaconditions", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_ivaCondition}>
                <Button
                  className="button-ivacondition"
                  type="danger"
                  onClick={() => showDeleteConfirm(ivaCondition)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={ivaConditionsActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function IvaConditionsInactive(props) {
  const {
          ivaConditionsInactive,
          setReloadIvaConditions,
          userToken,
          permissionsActive,
          roleById
        } = props;

  const activateIvaCondition = (ivaCondition) => {
    const accessToken = getAccessToken();

    activateIvaConditionApi(accessToken, ivaCondition._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadIvaConditions(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (ivaCondition) => {
    const accessToken = getAccessToken();
    confirm({
      title: "Eliminando Condición IVA",
      content: `¿Estás seguro que quieres eliminar a ${ivaCondition.description}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteIvaConditionApi(accessToken, ivaCondition._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadIvaConditions(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const activate_ivaCondition = (
    <div>
      {" "}
      <p>Pasar a Condición IVA activos</p>{" "}
    </div>
  );

  const delete_ivaCondition = (
    <div>
      {" "}
      <p>Eliminar Condición IVA</p>
    </div>
  );

  const columns = [
    {
      title: "Codigo",
      dataIndex: "ivacondition_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, ivaCondition) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "ivaconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "ivaconditions", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_ivaCondition}>
                <Button
                  className="button-ivacondition"
                  type="primary"
                  onClick={() => activateIvaCondition(ivaCondition)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "ivaconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "ivaconditions", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_ivaCondition}>
                <Button
                  className="button-ivacondition"
                  type="danger"
                  onClick={() => showDeleteConfirm(ivaCondition)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={ivaConditionsInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
