import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  BackTop,
  Row,
  Col,
  //Select
} from "antd";
import { 
  Link, 
  //withRouter
 } from "react-router-dom";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";

import Modal from "../../../Modal";
import AddEditBranchForm from "../AddEditBranchForm";
import { activateBranchApi, deleteBranchApi } from "../../../../api/branch";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
//import { getCourseApi } from "../../../../api/course";
import { getPermissionsActiveApi } from "../../../../api/permission";
//import { getStateCoursesActiveApi } from "../../../../api/stateCourse";
//import { getSchoolYearsActiveApi } from "../../../../api/schoolYear";
import jwtDecode from "jwt-decode";
// import useRouteProps from "../../../../hooks/UseRouteProps"
// import { PluralizeEsp } from "../../../../utils/convert";
//import { formatDateView } from "../../../../utils/formatDate";

import "./ListBranches.scss";
import { deleteBranchMenuByBranchApi } from "../../../../api/branchMenu";
import AddEditBusiness from "../../../../pages/Admin/AddEditBusiness";
import AddEditBusinessForm from "../../Businesses/AddEditBusinessForm";

const { confirm } = ModalAntd;
const { Search } = Input;
//const { Option } = Select;

export default function ListBranches(props) {
  const { 
    business, branchesActive, branchesInactive, setReloadBranches, 
    //setPaginationActive, setPaginationInactive 
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewBranchesActives, setViewBranchesActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [modalWidth, setModalWidth] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [branchesActiveFilter, setBranchesActiveFilter] = useState([]);
  const [branchesInactiveFilter, setBranchesInactiveFilter] = useState([]);
  //const [propsPaginationActive, setPropsPaginationActive] = useState({});
  //const [propsPaginationInactive, setPropsPaginationInactive] = useState({});

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    const listActive = [];
    if (branchesActive) {
      branchesActive.forEach((element) => {
          if (
            (element.name &&
            element.name
              .toUpperCase()
              .includes(filterText.toUpperCase())) ||
            filterText === ""
          ) {
            listActive.push(element);
          }
      });
    }
    setBranchesActiveFilter(listActive);
  }, [filterText, branchesActive]);

  useEffect(() => {
    const listInactive = [];
    if (branchesInactive) {
      branchesInactive.forEach((element) => {
          if (
            element.name
              .toUpperCase()
              .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
      });
    }
    setBranchesInactiveFilter(listInactive);
  }, [filterText, branchesInactive]);
   

  const addBranchModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear -> Sucursal");
    setModalWidth(1000);
    setModalContent(
       <AddEditBranchForm
         business={business}
         setIsVisibleModal={setIsVisibleModal}
         setReloadBranches={setReloadBranches}
       />
     );
  };


  return (
    <div className="list-branches">
         <div className="list-branches__header">
        {checkAction(permissionsActive, userToken.role, "absences", "all") ||
        checkAction(permissionsActive, userToken.role, "absences", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-branches__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewBranchesActives(!viewBranchesActives)
                }
              />
              <span>
                {viewBranchesActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
            <>
            </>
        )}

        {checkAction(permissionsActive, userToken.role, "absences", "all") ||
        checkAction(permissionsActive, userToken.role, "absences", "add") || isAdmin(roleById) ? (
          <>
           {/* <Link to="/admin/config/branch"> */}
           <Button type="primary" icon={<PlusOutlined/>} 
             onClick={addBranchModal}
           >
              Nuevo
            </Button>
    {/* </Link> */}
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "absences", "all") ||
      checkAction(permissionsActive, userToken.role, "absences", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-branches__search">
            <Row gutter={24}>
      <Col span={20}>
            <Search
              className="Buscar"
              allowClear
              placeholder="Buscar"
              onSearch={(e) => setFilterText(e)}
              enterButton
            />
            </Col>
            </Row>
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong>
            </h3>
          </div>
        </>
        ) : (
          <>
          </>
      )}

      {viewBranchesActives ? (
        <>

          {checkAction(permissionsActive, userToken.role, "absences", "all") ||
          checkAction(permissionsActive, userToken.role, "absences", "view") || isAdmin(roleById) ? (
            <>
              <BranchesActive
                branchesActive={branchesActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadBranches={setReloadBranches}
                setModalWidth={setModalWidth}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                filterText={filterText}
                //showDeleteConfirm={showDeleteConfirm}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "absences", "all") ||
          checkAction(permissionsActive, userToken.role, "absences", "view") || isAdmin(roleById) ? (
            <>
              <BranchesInactive
                branchesInactive={branchesInactiveFilter}
                setReloadBranches={setReloadBranches}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                filterText={filterText}
                // listStateCoursesActive={listStateCoursesActive}
                //showDeleteConfirm={showDeleteConfirm}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        modalWidth={modalWidth}
      >
        {modalContent}
      </Modal>

      <BackTop/>
    </div>
  );
}

function BranchesActive(props) {
  const { branchesActive, setReloadBranches, setIsVisibleModal, setModalTitle, setModalWidth, 
    setModalContent,accessToken, userToken, permissionsActive, roleById,
    setPagination, propsPagination, filterText} = props; //showDeleteConfirm

  const editBranch= (branch) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar : ${branch.name ? branch.name : "negocio"}`);
    setModalWidth(1000);
    if(branch.isMain){
      setModalContent(
        <AddEditBusinessForm
          business={branch.business}
          setIsVisibleModal={setIsVisibleModal}
          setReloadBusinesses={setReloadBranches}
        />
      );
    }else{
      setModalContent(
        <AddEditBranchForm
          branch={branch}
          setIsVisibleModal={setIsVisibleModal}
          setReloadBranches={setReloadBranches}
        />
      );
    }
 
  };

  const desactivateBranch = (branch) => {
    //const accessToken = getAccessToken();

    activateBranchApi(accessToken, branch._id, false)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setReloadBranches(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = (branch) => {
    //const accessToken = getAccessToken();

    confirm({
      title: "Eliminando -> Negocio",
      content: `¿Estás seguro que quieres eliminar a ${branch.name ? branch.name : "negocio"}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteBranchMenuByBranchApi(accessToken, branch._id).then((response) => {
          // notification["success"]({
          //   message: response,
          // });
          if(response.code === 200){
            return true;
          }else{
            return false;
          }
        }).then(async result=>{
          if(result){
            const response2= await  deleteBranchApi(accessToken, branch._id)
            if(response2) {
              notification["success"]({
                message: "Sucursal eliminada correctamente",
              });
              if(response2.code === 200){
              }
              
              setReloadBranches(true);
          }}
        }
   )
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const edit_branch = (
    <div>
      {" "}
      <p>Editar negocio</p>{" "}
    </div>
  );

  const desactivate_branch = (
    <div>
      {" "}
      <p>Pasar a negocio inactivos</p>{" "}
    </div>
  );

  const delete_branch = (
    <div>
      {" "}
      <p>Eliminar inasistencia</p>{" "}
    </div>
  );
  // const download_absence = (
  //   <div>
  //     {" "}
  //     <p>Descargar detalle inasistencia</p>{" "}
  //   </div>
  // );
  const columns = [
    {
      title: "Nombre",
      // dataIndex: "name",
      render: branch =>{if(branch.isMain){
        return branch.business.name
      }
      else{
        return branch.name}
      },

    },
    {
      title: "Acción",
      key: "action",
      render: (text, branch) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "absences", "all") ||
          checkAction(permissionsActive, userToken.role, "absences", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_branch}>
              {/* <Link to={{
                  pathname: `/admin/config/branch/${branch.name}`,
                  state: { id: branch._id },
                }}> */}
                <Button
                  className="button-edit"
                  onClick={() => editBranch(branch)}
                >
                  <EditOutlined />
                </Button>
              {/* </Link> */}
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "absences", "all") ||
          checkAction(permissionsActive, userToken.role, "absences", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_branch}>
                <Button
                  className="button-branch"
                  type="primary"
                  onClick={() => desactivateBranch(branch)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "absences", "all") ||
          checkAction(permissionsActive, userToken.role, "absences", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_branch}>
                <Button
                  className="button-branch"
                  type="danger"
                  onClick={() => showDeleteConfirm(branch)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return branchesActive? `Total de registros: ${branchesActive.length}` : false}}
      pagination={{
        ...propsPagination,
        showSizeChanger:false,
        onChange:(newPage) => {
          setPagination({page:newPage,
            state:1,
            filter:filterText
          })
          }
        }}
      dataSource={branchesActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function BranchesInactive(props) {
  const { branchesInactive,
          setReloadBranches,
          accessToken,
          userToken,
          permissionsActive,
          roleById,
          propsPagination,
          setPagination,
          filterText
        } = props; //showDeleteConfirm

  const activateBranch= (branch) => {
    activateBranchApi(accessToken, branch._id, true)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setReloadBranches(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = (branch) => {
    confirm({
      title: "Eliminando -> Negocio",
      content: `¿Estás seguro que quieres eliminar a ${branch.name ? branch.name : "sucursal"}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteBranchMenuByBranchApi(accessToken, branch._id).then((response) => {
          // notification["success"]({
          //   message: response,
          // });
          if(response.code === 200){
            return true;
          }else{
            return false;
          }
        }).then(async result=>{
          if(result){
            const response2= await  deleteBranchApi(accessToken, branch._id)
            if(response2) {
              notification["success"]({
                message: "Sucursal eliminada correctamente",
              });
              if(response2.code === 200){
              }
              
              setReloadBranches(true);
          }}
        }
   )
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const activate_branch = (
    <div>
      {" "}
      <p>Pasar a negocios activos</p>{" "}
    </div>
  );

  const delete_branch = (
    <div>
      {" "}
      <p>Eliminar negocio</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, branch) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "absences", "all") ||
          checkAction(permissionsActive, userToken.role, "absences", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_branch}>
                <Button
                  className="button-activate"
                  onClick={() => activateBranch(branch)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "absences", "all") ||
          checkAction(permissionsActive, userToken.role, "absences", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_branch}>
                <Button
                  className="button-branch"
                  type="danger"
                  onClick={() => showDeleteConfirm(branch)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return branchesInactive? `Total de registros: ${branchesInactive.length}` : false}}
      pagination={{
      ...propsPagination,
      showSizeChanger:false,
      onChange:(newPage) => {
        setPagination({page:newPage,
          state:2,
          filter:filterText,
        })
        }
      }}
      dataSource={branchesInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
