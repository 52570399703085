import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditBillSystemForm from "../EditBillSystemForm";
import AddBillSystemForm from "../AddBillSystemForm";
import {
  activateBillSystemApi,
  deleteBillSystemApi,
} from "../../../../api/billing/billSystem";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListBillSystems.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListBillSystems(props) {
  const { billSystemsActive, billSystemsInactive, setReloadBillSystems } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewBillSystemsActives, setViewBillSystemsActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [billSystemsActiveFilter, setBillSystemsActiveFilter] = useState(
    []
  );
  const [ billSystemsInactiveFilter, setBillSystemsInactiveFilter ] = useState([]);
  const [typeFilter, setSystemFilter] = useState("description");

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado
  //console.log("userToken", userToken);

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, [roleById]);

  useEffect(() => {
    const listActive = [];
    if (billSystemsActive) {
      billSystemsActive.forEach((element) => {
        if (typeFilter === "description") {
          if (
            element.description
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        } else {
          if (
            element.billsystem_code
              .toString()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        }
      });
    }
    setBillSystemsActiveFilter(listActive);
  }, [filterText, billSystemsActive]);

  useEffect(() => {
    const listInactive = [];
    if (billSystemsInactive) {
      billSystemsInactive.forEach((element) => {
        if (typeFilter === "description") {
          if (
            element.description
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        } else {
          if (
            element.item_code
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        }
      });
    }
    setBillSystemsInactiveFilter(listInactive);
  }, [filterText, billSystemsInactive]);

  const addBillSystemModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Sistema de facturación");
    setModalContent(
      <AddBillSystemForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadBillSystems={setReloadBillSystems}
      />
    );
  };

  return (
    <div className="list-billsystems">
      <div className="list-billsystems__header">
        {checkAction(permissionsActive, userToken.role, "billsystems", "all") ||
        checkAction(permissionsActive, userToken.role, "billsystems", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-billsystems__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewBillSystemsActives(!viewBillSystemsActives)
                }
              />
              <span>
                {viewBillSystemsActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
          <>
          </>
        )}

        {checkAction(permissionsActive, userToken.role, "billsystems", "all") ||
        checkAction(permissionsActive, userToken.role, "billsystems", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addBillSystemModal}>
              Nuevo 
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "billsystems", "all") ||
      checkAction(permissionsActive, userToken.role, "billsystems", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-billsystems__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <Radio.Group
              options={[
                { label: "Descripción", value: "description" },
                { label: "Código", value: "code" },
              ]}
              onChange={(e) => {
                setSystemFilter(e.target.value);
              }}
              value={typeFilter}
            />
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> ejemplos de sistemas de facturacion 'web, online, papel'
            </h3>
          </div>
        </>
        ) : (
          <>
          </>
      )}

      {viewBillSystemsActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "billsystems", "all") ||
          checkAction(permissionsActive, userToken.role, "billtypes", "view") || isAdmin(roleById) ? (
            <>
              <BillSystemsActive
                billSystemsActive={billSystemsActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadBillSystems={setReloadBillSystems}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "billsystems", "all") ||
          checkAction(permissionsActive, userToken.role, "billtypes", "view") || isAdmin(roleById) ? (
            <>
              <BillSystemsInactive
                billSystemsInactive={billSystemsInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadBillSystems={setReloadBillSystems}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function BillSystemsActive(props) {
  const {
    billSystemsActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadBillSystems,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editBillSystem = (billSystem) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${billSystem.description ? billSystem.description : "sin descripción"}`
    );
    setModalContent(
      <EditBillSystemForm
        billSystem={billSystem}
        setIsVisibleModal={setIsVisibleModal}
        setReloadBillSystems={setReloadBillSystems}
      />
    );
  };

  const desactivateBillSystem = (billSystem) => {
    const accessToken = getAccessToken();

    activateBillSystemApi(accessToken, billSystem._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadBillSystems(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (billSystem) => {
    const accessToken = getAccessToken();
    confirm({
      title: "Eliminando Sistema de facturación",
      content: `¿Estás seguro que quieres eliminar a ${billSystem.description}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteBillSystemApi(accessToken, billSystem._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadBillSystems(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const edit_billSystem = (
    <div>
      {" "}
      <p>Editar Sistema de facturación'¿</p>{" "}
    </div>
  );

  const desactivate_billSystem = (
    <div>
      {" "}
      <p>Pasar a Sistemas de facturación inactivos</p>{" "}
    </div>
  );

  const delete_billSystem = (
    <div>
      {" "}
      <p>Eliminar Sistema de facturación</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "billsystem_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, billSystem) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "billsystems", "all") ||
          checkAction(permissionsActive, userToken.role, "billsystems", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_billSystem}>
                <Button
                  className="button-billsystem"
                  type="default"
                  onClick={() => editBillSystem(billSystem)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "billsystems", "all") ||
          checkAction(permissionsActive, userToken.role, "billsystems", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_billSystem}>
                <Button
                  className="button-billsystem"
                  type="primary"
                  onClick={() => desactivateBillSystem(billSystem)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "billsystems", "all") ||
          checkAction(permissionsActive, userToken.role, "billsystems", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_billSystem}>
                <Button
                  className="button-billsystem"
                  type="danger"
                  onClick={() => showDeleteConfirm(billSystem)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
            ) : (
              <>
              </>
            )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={billSystemsActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function BillSystemsInactive(props) {
  const { billSystemsInactive,
          setReloadBillSystems,
          accessToken,
          userToken,
          permissionsActive,
          roleById
        } = props;

  const activateBillSystem = (billSystem) => {
    const accessToken = getAccessToken();

    activateBillSystemApi(accessToken, billSystem._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadBillSystems(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (billSystem) => {
    const accessToken = getAccessToken();
    confirm({
      title: "Eliminando Sistema de facturación",
      content: `¿Estás seguro que quieres eliminar a ${billSystem.description}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteBillSystemApi(accessToken, billSystem._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadBillSystems(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const activate_billSystem = (
    <div>
      {" "}
      <p>Pasar a Sistemas de facturación activos</p>{" "}
    </div>
  );

  const delete_billSystem = (
    <div>
      {" "}
      <p>Eliminar Sistema de facturación</p>
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "billsystem_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, billSystem) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "billsystems", "all") ||
          checkAction(permissionsActive, userToken.role, "billsystems", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_billSystem}>
                <Button
                  className="button-billsystem"
                  type="primary"
                  onClick={() => activateBillSystem(billSystem)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "billsystems", "all") ||
          checkAction(permissionsActive, userToken.role, "billsystems", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_billSystem}>
                <Button
                  className="button-billsystem"
                  type="danger"
                  onClick={() => showDeleteConfirm(billSystem)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={billSystemsInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
