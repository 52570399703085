import { downloadPdf, openPdf , getStyles, getHeader, getLogo} from "../../../../utils/pdfManager"; //docDefinitionTable
import { convertTypeIva } from "../../../../utils/convert";
//import { formatDateView } from "../../../../utils/formatDate";
//import Logo from '../../../../assets/img/png/ball.png';
//import moment from "moment";

export function listClientsPdf(data, isDownload) {
  const _format = (data) => {
    return data.map((item) => {
      return [
        { text: item.denomination },
        { text: item.person.name },
        { text: item.person.lastname },
        { text: item.person.address },
        { text: item.person.phone1 },
        { text: item.person.email },
      ];
    });
  };

  const formattedData = _format(data);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "vertical",
    header:getHeader(),
    content: [
        getLogo(),
        [
            { text:'Listado de Clientes', style:'title'},
        ],
      '\n',
      '\n',
      {
        table: {
          headerRow: 1,
          widths:['*','*','*','auto','auto','auto'],
          body: [
            [
              { text: "Denominacion", style: "tableHeader" },
              { text: "Nombre", style: "tableHeader" },
              { text: "Apellido", style: "tableHeader" },
              { text: "Domicilio", style: "tableHeader" },
              { text: "Telefono", style: "tableHeader" },
              { text: "Email", style: "tableHeader" },
            ],
            ...formattedData,
          ],
        },
      },
    ],
    styles:getStyles()
  };
  if (isDownload) {
    downloadPdf(docDefinition, "ListadoClientes");
  } else {
    openPdf(docDefinition);
  }
}

export function clientDetailPdf(data, isDownload) {
  const _format = (data) => {
    return [
      { text: `Nombre: ${data.person.name ? data.person.name : ""}`, style:'list' },
      { text: `Apellido: ${data.person.lastname ? data.person.lastname : ""}` , style:'list'},
      { text: `Domicilio: ${data.person.address ? data.person.address : ""}` , style:'list'},
      { text: `Telefono 1: ${data.person.phone1 ? data.person.phone1 : ""}`, style:'list' },
      { text: `Tipo de documento: ${data.person.documentType ? data.person.documentType : ""}`, style:'list' },
      { text: `Numero de documento: ${data.person.documentNumber ? data.person.documentNumber : ""}` , style:'list'},
      { text: `E-mail: ${data.person.email ? data.person.email : ""}`, style:'list' },
      { text: `Denominacion: ${data.denomination ? data.denomination : ""}`, style:'list' },
      { text: `Condicion de IVA: ${data.ivaCondition ? data.ivaCondition.description : ""}`, style:'list' },
      // { text: `Observaciones: ${data.person.observation ? data.person.observation : ""}`, style:'list' },
    ];
  };

  const formattedData = _format(data);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "vertical",
    header:getHeader(),
    content: [
      getLogo(),
      '\n',
      [
          { text:'Detalle de Cliente', style:'title'},
      ],
      '\n',
      '\n',
      {
        type: "none",
        ol: [...formattedData],
      },
    ],
    styles:getStyles()
  };
  let title = `${data.name}`;
  if (isDownload) {
    downloadPdf(docDefinition, title);
  } else {
    openPdf(docDefinition);
  }
}
