import React, { useState, useEffect } from "react";
import {
  Switch,
  List,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Space,
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  DownloadOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";

import Modal from "../../../Modal";
import EditClientForm from "../EditClientForm";
import AddClientForm from "../AddClientForm";
import { activateClientApi, deleteClientApi } from "../../../../api/billing/client";
import { listClientsPdf, clientDetailPdf } from "./pdfClients";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";
import useRouteProps from "../../../../hooks/UseRouteProps"
import { PluralizeEsp } from "../../../../utils/convert";

import "./ListClients.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListClients(props) {
  const { clientsActive, clientsInactive, setReloadClients } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewClientsActives, setViewClientsActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [modalWidth, setModalWidth] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [clientsActiveFilter, setClientsActiveFilter] = useState([]);
  const [clientsInactiveFilter, setClientsInactiveFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState("name");

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado
  //console.log("userToken", userToken);
  // console.log(useRouteProps());

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, [roleById]);

  useEffect(() => {
    const listActive = [];
    if (clientsActive) {
      clientsActive.forEach((element) => {
        if (typeFilter === "name") {
          if (
            (element.person.name && element.person.name.toUpperCase().indexOf(filterText.toUpperCase()) !==
              -1 ) ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        } else if (typeFilter === "documentNumber") {
          if (
            (element.person.documentNumber && element.person.documentNumber.toString().indexOf(filterText) !== -1) ||
            filterText === ""
          ) {
            listActive.push(element);
          }

        } else if (typeFilter === "cuit_cuil" ) {
          if (
            (element.person.cuit_cuil && element.person.cuit_cuil.toString().indexOf(filterText) !== -1) ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        }
      });
    }
    setClientsActiveFilter(listActive);
  }, [filterText, clientsActive]);

  useEffect(() => {
    const listInactive = [];
    if (clientsInactive) {
      clientsInactive.forEach((element) => {
        if (typeFilter === "name" ) {
          if (
            (element.person.name && element.person.name.toUpperCase().indexOf(filterText.toUpperCase()) !==
              -1 )||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        } else if (typeFilter === "documentNumber") {
          if (
            (element.person.documentNumber && element.person.documentNumber.toString().indexOf(filterText) !== -1 )||
            filterText === ""
          ) {
            listInactive.push(element);
          }

        } else if (typeFilter === "cuit_cuil") {
          if (
            (element.person.cuit_cuil && element.person.cuit_cuil.toString().indexOf(filterText) !== -1) ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        }
      });
    }
    setClientsInactiveFilter(listInactive);
  }, [filterText, clientsInactive]);

  const addClientModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear -> Cliente");
    setModalWidth(800);
    setModalContent(
      <AddClientForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadClients={setReloadClients}
      />
    );
  };

  return (
    <div className="list-clients">
      <div className="list-clients__header">
        {checkAction(permissionsActive, userToken.role, "clients", "all") ||
        checkAction(permissionsActive, userToken.role, "clients", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-clients__header-switch">
              <Switch
                defaultChecked
                onChange={() => setViewClientsActives(!viewClientsActives)}
              />
              <span>
                {viewClientsActives ? "Activos" : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
          <>
          </>
        )}

        {checkAction(permissionsActive, userToken.role, "clients", "all") ||
        checkAction(permissionsActive, userToken.role, "clients", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addClientModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "clients", "all") ||
      checkAction(permissionsActive, userToken.role, "clients", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-clients__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <Radio.Group
              options={[
                { label: "Nombre", value: "name" },
                { label: "Número de Documento", value: "documentNumber" },
                { label: "CUIL/CUIT", value: "cuit_cuil" },
              ]}
              onChange={(e) => {
                setTypeFilter(e.target.value);
              }}
              value={typeFilter}
            />
          </div>
        </>
        ) : (
          <>
          </>
      )}

      {viewClientsActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "clients", "all") ||
          checkAction(permissionsActive, userToken.role, "clients", "pdf") || isAdmin(roleById) ? (
            <>
              <Space align="center" style={{ marginBottom: 16 }}>
                <Button
                  type="default"
                  shape="round"
                  onClick={() => {
                    listClientsPdf(clientsActiveFilter, false);
                  }}
                >
                  <DownloadOutlined /> Listado Clientes
                </Button>
              </Space>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "clients", "all") ||
          checkAction(permissionsActive, userToken.role, "clients", "view") || isAdmin(roleById) ? (
            <>
              <ClientsActive
                clientsActive={clientsActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadClients={setReloadClients}
                setModalWidth={setModalWidth}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                //showDeleteConfirm={showDeleteConfirm}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "clients", "all") ||
          checkAction(permissionsActive, userToken.role, "clients", "pdf") || isAdmin(roleById) ? (
            <>
              <Space align="center" style={{ marginBottom: 16 }}>
                <Button
                  type="default"
                  shape="round"
                  onClick={() => {
                    listClientsPdf(clientsInactiveFilter, false);
                  }}
                >
                  <DownloadOutlined /> Listado Clientes
                </Button>
              </Space>
            </>
          ) : (
            <>
            </>
          )}
          {checkAction(permissionsActive, userToken.role, "clients", "all") ||
          checkAction(permissionsActive, userToken.role, "clients", "view") || isAdmin(roleById) ? (
            <>
              <ClientsInactive
                clientsInactive={clientsInactiveFilter}
                setReloadClients={setReloadClients}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                //showDeleteConfirm={showDeleteConfirm}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        modalWidth={modalWidth}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function ClientsActive(props) {
  const {
    clientsActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadClients,
    setModalWidth,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props; //showDeleteConfirm

  const editClient = (client) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar cliente ${client.person.name ? client.denomination : "sin nombre"}`);
    setModalWidth(800);
    setModalContent(
      <EditClientForm
        client={client}
        setIsVisibleModal={setIsVisibleModal}
        setReloadClients={setReloadClients}
      />
    );
  };

  return (
    <List
      className="clients-active"
      itemLayout="horizontal"
      dataSource={clientsActive}
      renderItem={(client) => (
        <ClientActive
          client={client}
          editClient={editClient}
          setReloadClients={setReloadClients}
          accessToken={accessToken}
          userToken={userToken}
          permissionsActive={permissionsActive}
          roleById={roleById}
        />
      )} //showDeleteConfirm={showDeleteConfirm}
    />
  );
}

function ClientActive(props) {
  const { client, editClient, setReloadClients, accessToken, userToken, permissionsActive, roleById } = props; //showDeleteConfirm

  const desactivateClient = () => {
    //const accessToken = getAccessToken();

    activateClientApi(accessToken, client._id, false)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setReloadClients(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = () => {
    //const accessToken = getAccessToken();

    confirm({
      title: "Eliminando -> Cliente",
      content: `¿Estás seguro que quieres eliminar a ${client.denomination ? client.person.name : "cliente"}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteClientApi(accessToken, client._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadClients(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const edit_client = (
    <div>
      {" "}
      <p>Editar cliente</p>{" "}
    </div>
  );

  const desactivate_client = (
    <div>
      {" "}
      <p>Pasar a clientes inactivos</p>{" "}
    </div>
  );

  const delete_client = (
    <div>
      {" "}
      <p>Eliminar cliente</p>{" "}
    </div>
  );
  const download_client = (
    <div>
      {" "}
      <p>Descargar detalle cliente</p>{" "}
    </div>
  );

  return (
    <List.Item
      actions={[
        <div>
          {checkAction(permissionsActive, userToken.role, "clients", "all") ||
          checkAction(permissionsActive, userToken.role, "clients", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_client}>
                <Button type="default" onClick={() => editClient(client)}>
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "clients", "all") ||
          checkAction(permissionsActive, userToken.role, "clients", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_client}>
                <Button type="primary" onClick={desactivateClient}>
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "clients", "all") ||
          checkAction(permissionsActive, userToken.role, "clients", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_client}>
                <Button type="danger" onClick={showDeleteConfirm}>
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "clients", "all") ||
          checkAction(permissionsActive, userToken.role, "clients", "pdf") || isAdmin(roleById) ? (
            <>
              <Popover content={download_client}>
                <Button type="primary" onClick={() => {
                    clientDetailPdf(client, false);
                  }}
                >
                  <DownloadOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
      ]}
    >
      <List.Item.Meta
        title={`
                    ${client.person ? client.person.name : "..."}
                `}
        description={client.denomination}
      />
    </List.Item>
  );
}

function ClientsInactive(props) {
  const { clientsInactive, setReloadClients, accessToken, userToken, permissionsActive, roleById } = props; //showDeleteConfirm
  return (
    <List
      className="clients-active"
      itemLayout="horizontal"
      dataSource={clientsInactive}
      renderItem={(client) => (
        <ClientInactive
          client={client}
          setReloadClients={setReloadClients}
          accessToken={accessToken}
          userToken={userToken}
          permissionsActive={permissionsActive}
          roleById={roleById}
        />
      )} //showDeleteConfirm={showDeleteConfirm}
    />
  );
}

function ClientInactive(props) {
  const { client,
          setReloadClients,
          accessToken,
          userToken,
          permissionsActive,
          roleById
        } = props; //showDeleteConfirm

  const activateClient = () => {
    const accessToken = getAccessToken();

    activateClientApi(accessToken, client._id, true)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setReloadClients(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = () => {
    const accessToken = getAccessToken();

    confirm({
      title: "Eliminando -> Cliente",
      content: `¿Estás seguro que quieres eliminar a ${client.denomination ? client.person.name : "cliente"}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteClientApi(accessToken, client._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadClients(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const activate_client = (
    <div>
      {" "}
      <p>Pasar a clientes activos</p>{" "}
    </div>
  );

  const delete_client = (
    <div>
      {" "}
      <p>Eliminar cliente</p>{" "}
    </div>
  );
  const download_client = (
    <div>
      {" "}
      <p>Descargar detalle cliente</p>{" "}
    </div>
  );

  return (
    <List.Item
      actions={[
        <div>
          {checkAction(permissionsActive, userToken.role, "clients", "all") ||
          checkAction(permissionsActive, userToken.role, "clients", "enable") || isAdmin(roleById) ? (
          <>
            <Popover content={activate_client}>
              <Button type="primary" onClick={activateClient}>
                <CheckOutlined />
              </Button>
            </Popover>
          </>
          ) : (
            <>
            </>
          )}
        </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "clients", "all") ||
          checkAction(permissionsActive, userToken.role, "clients", "delete") || isAdmin(roleById) ? (
          <>
            <Popover content={delete_client}>
              <Button type="danger" onClick={showDeleteConfirm}>
                <DeleteOutlined />
              </Button>
            </Popover>
          </>
          ) : (
            <>
            </>
          )}
        </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "clients", "all") ||
          checkAction(permissionsActive, userToken.role, "clients", "pdf") || isAdmin(roleById) ? (
          <>
            <Popover content={download_client}>
              <Button
                type="primary"
                onClick={() => {
                  clientDetailPdf(client, false);
                }}
              >
                <DownloadOutlined />
              </Button>
            </Popover>
          </>
          ) : (
            <>
            </>
          )}
        </div>,
      ]}
    >
      <List.Item.Meta
        title={` ${client.person ? client.person.name : "..."} `}
        description={` ${client.denomination}`}
      />
    </List.Item>
  );
}
