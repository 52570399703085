// TiendaSelector.js
import React, { useEffect, useState } from "react";
import { Select,Menu,Dropdown } from "antd";
import { useBranch } from "../../../hooks/useBranch";
import { getBranchesActiveApi, getBranchesActiveByBusinessApi } from "../../../api/branch";
import { getAccessToken } from "../../../api/auth";
import { useBusiness } from "../../../hooks/useBusiness";
import { ShopOutlined, GlobalOutlined, PoweroffOutlined, UserOutlined } from '@ant-design/icons';

const { Option } = Select;

export default function BranchSelector(props) {
  const { branch, changeBranch } = useBranch();
  const { business } = useBusiness();
  const [listBranchesActive, setListBranchesActive] = useState([]);
  const [branchSelected, setBranchSelected]=useState("");

  useEffect(() => {
    if(business && business!=="0" && business!==0){
      const accessToken = getAccessToken();
      getBranchesActiveByBusinessApi(accessToken, true, business).then((response) => {
        let newList=response.branches? response.branches : [];
        newList.push({_id:0,name:"todos"})
        setListBranchesActive(newList);
        const sucursal = newList.find(s => s._id === branch);
        setBranchSelected(sucursal ? sucursal.name : '');
      });
    }else{
      changeBranch("0")
    }
  }, [business]);

  useEffect(()=>{
    const sucursal = listBranchesActive.find(s => s._id === branch);
    setBranchSelected(sucursal ? sucursal.name : '');
  },[branch])

  const handleChange = (value) => {
    changeBranch(value.key);
  };

  const menu = (
    <Menu onClick={handleChange}>
      {listBranchesActive.map((sucursal) => (
             <Menu.Item key={sucursal._id}>{sucursal.name}</Menu.Item>
          ))}
    </Menu>
  );

  const spanStyle={
    color:"black",
    width:"130px",
    textAlign: "left"
  }


  return (
    <div>
      {listBranchesActive && listBranchesActive.length > 0? 
      // <Select
      //   //prefix={<DollarCircleOutlined />}
      //   showSearch
      //   placeholder="Selecciona el negocio"
      //   value={branch}
      //   defaultValue={0}
      //   onChange={(e) => changeBranch(e)}
      //   optionFilterProp="children"
      //   style={{ width: "150%" }}
      //   filterOption={(input, option) =>
      //     option.children
      //       .toString()
      //       .toLowerCase()
      //       .indexOf(input.toLowerCase()) >= 0
      //   }
      // >
      //   {listBranchesActive
      //     ? listBranchesActive.map((branch) => {
      //       if(branch.business){
      //         if(branch.isMain){
      //           return <Option key={branch._id}> {"Principal"}</Option> 
      //         }else{
      //           return <Option key={branch._id}> {branch.name}</Option>
      //         }
      //       }else{
      //         return <Option key={branch._id}> {branch.name}</Option>
      //       }
      //       })
      //     : null}
      // </Select>
      <Dropdown.Button className="ant-dropdown-button" overlay={menu} trigger={['click']}>
      <span style={spanStyle}>
      {" "} <ShopOutlined/> {" "}{branchSelected!=="" && branchSelected!=="todos"? branchSelected : "Todas las sucursales"}
      </span>
    </Dropdown.Button>
      : null}
    </div>
  );
}
