import { useContext } from 'react';
import { BusinessContext } from '../providers/BusinessProvider';

// Hook personalizado para consumir el contexto
export const useBusiness= () => {
    const context = useContext(BusinessContext);
    if (!context) {
      throw new Error('useBusiness debe ser usado dentro de un BusinessProvider');
    }
    return context;
  };