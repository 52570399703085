import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import {
  getVariationsActiveApi,
  getVariationsAvailableApi,
  getVariationsIsGeneralAndAvailableApi,
  getVariationsActiveAndIsGeneralByBusinessApi,
  getVariationsAvailableAndIsGeneralByBusinessApi
} from "../../../api/variation";
import ListVariations from "../../../components/Admin/Variations/ListVariations";

import "./Variations.scss";
import { useBranch } from "../../../hooks/useBranch";
import { useBusiness } from "../../../hooks/useBusiness";
import { useHistory  } from "react-router-dom/cjs/react-router-dom";
import {BranchVariations} from "../BranchVariations/BranchVariations";

export default function Variations() {
  const [variationsAvailable, setVariationsAvailable] = useState([]);
  const [variationsNotAvailable, setVariationsNotAvailable] = useState([]);
  const [reloadVariations, setReloadVariations] = useState(false);
  const token = getAccessToken();

  const { branch } = useBranch();
  const { business } = useBusiness();

  const history = useHistory();

  useEffect(() => {
    if(business){

      //getVariationsActiveAndIsGeneralByBusinessApi(token, true, true, business).then((response) => {
      getVariationsAvailableAndIsGeneralByBusinessApi(token, true, true, business).then((response) => {
        setVariationsAvailable(response.variations);
      });
      getVariationsAvailableAndIsGeneralByBusinessApi(token, false, true, business).then((response) => {
        setVariationsNotAvailable(response.variations);
      });
      setReloadVariations(false);

    }
  }, [token, reloadVariations]);

  useEffect(() => {
    if(branch && branch!==0 && branch!=="0"){
     //return  history.push("/admin/digitalmenu/branch-variations");
      return window.location.href = "/admin/digitalmenu/branch-variations";

    }
  }, [branch]);

  return (
    <div className="variations">
      <ListVariations
        variationsAvailable={variationsAvailable}
        variationsNotAvailable={variationsNotAvailable}
        setReloadVariations={setReloadVariations}
      />
    </div>
  );
}
