import React, { useState, useEffect } from "react";
import { Form, Input, InputNumber, Button, Row, Col, notification } from "antd";
import { TagOutlined } from "@ant-design/icons";
import { updateIvaConditionApi } from "../../../../api/billing/ivaCondition";
import { getAccessToken } from "../../../../api/auth";

import "./EditIvaConditionForm.scss";

export default function EditIvaConditionForm(props) {
  const { ivaCondition, setIsVisibleModal, setReloadIvaConditions } = props;
  const [ivaConditionData, setIvaConditionData] = useState({});

  //trae los datos de condicion IVA
  useEffect(() => {
    setIvaConditionData({
      ivacondition_code: ivaCondition.ivacondition_code,
      description: ivaCondition.description,
    });
  }, [ivaCondition]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!ivaConditionData.ivacondition_code || !ivaConditionData.description) {
      notification["error"]({
        message: "Obligatorios: Código y Descripción.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const updateIvaCondition = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let ivaConditionUpdate = ivaConditionData; //es let porque se va actualizando

      updateIvaConditionApi(token, ivaConditionUpdate, ivaCondition._id).then(
        (result) => {
          if (
            result.message === "ERR_CONNECTION_REFUSED" ||
            result.message === "Failed to fetch" ||
            result.message === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
            setIsVisibleModal(true);
          } else if (result.code !== 200) {
            notification["error"]({
              message: result.message,
            });
            setIsVisibleModal(true);
          } else {
            notification["success"]({
              message: result.message, //el mensaje que viene del server
            });
            //console.log("respuesta: " + result.message);
            setIsVisibleModal(false);
            setReloadIvaConditions(true);
          }
        }
      );
    }
  };

  return (
    <div className="edit-ivacondition-form">
      <EditForm
        ivaConditionData={ivaConditionData}
        setIvaConditionData={setIvaConditionData}
        updateIvaCondition={updateIvaCondition}
      />
    </div>
  );
}

function EditForm(props) {
  const { ivaConditionData, setIvaConditionData, updateIvaCondition } = props;

  return (
    <Form className="form-edit" onSubmit={updateIvaCondition}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Código"
              value={ivaConditionData.ivacondition_code}
              onChange={(e) =>
                setIvaConditionData({
                  ...ivaConditionData,
                  ivacondition_code: e,
                })
              }
              min={1}
              style={{ width: "90%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">* </span>Descripción
          </label>
          <Form.Item>
            <Input
              placeholder="Descripción"
              value={ivaConditionData.description}
              onChange={(e) =>
                setIvaConditionData({
                  ...ivaConditionData,
                  description: e.target.value.toUpperCase(),
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateIvaCondition}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
