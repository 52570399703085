import React, { useState, useEffect } from "react";
import {
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
} from "antd";
import {DeleteOutlined, EyeOutlined, PlusOutlined} from "@ant-design/icons"; //EditOutlined
import { Popover } from "antd";
import Modal from "../../../Modal";

import { getItemsApi } from "../../../../api/item";
//import {convertCategory} from "../../../../utils/convert";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListStockInventory.scss";

import { deleteStockInventoryApi } from "../../../../api/billing/stockInventory";
import AddStockInventoryForm from "../AddStockInventoryForm/AddStockInventoryForm";
import EditStockInventoryForm from "../EditStockInventoryForm/EditStockInventoryForm";
import ViewStockInventoryForm from "../ViewStockInventoryForm/ViewStockInventoryForm";


const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListStockInventory(props) {
  const {stockInventories, setReloadStockInventory } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);

  const [listItems, setListItems]=useState([]);

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [stockInventoriesFilter, setStockInventoriesFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState("description");

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, [roleById]);

  useEffect(() => {
    const listInventory = [];

    if (stockInventories) {
      stockInventories.forEach((element) => {
          if(!element.item)
          {
            deleteInventoryNotItem(element);
          }
          else{
          if (typeFilter === "description") {
            if (
              element.item.description.toUpperCase().indexOf(filterText.toUpperCase()) !== -1 ||
              filterText === ""
            ) {
              listInventory.push(element);
            }
          } else {
            if (
              element.item.item_code.toUpperCase().indexOf(filterText.toUpperCase()) !== -1 ||
              filterText === ""
            ) {
              listInventory.push(element);
            }
          }
        }
      });
    }
    setStockInventoriesFilter(listInventory);
    getItemsApi(getAccessToken()).then(response=>{
      setListItems(response.items)
    });
  }, [filterText, stockInventories]);


  const deleteInventoryNotItem = (element) => {
    const accessToken = getAccessToken();

    confirm({
      title: "Eliminando Inventario",
      content: `Encontramos en el sistema el ${element.observation} cuyo articulo no existe, ¿Desea eliminarlo?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
          deleteStockInventoryApi(accessToken, element._id)
          .then((response) => {
              notification["success"]({
                message: response.message,
              });
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        }
      });
    };

    const deleteStockInventory = (e) => {
      const accessToken = getAccessToken();

      confirm({
        title: "Eliminando Inventario",
        content: `¿Esta seguro que desea eliminar el inventario del Articulo: ${e.item.description} ?`,
        okText: "Eliminar",
        okType: "danger",
        cancelText: "Cancelar",
        onOk() {
            deleteStockInventoryApi(accessToken, e._id)
            .then((response) => {
                if(response.code !==200){
                  notification["error"]({
                    message: response.message,
                  });

                }else{
                  notification["success"]({
                    message: response.message,
                  });
                  setReloadStockInventory(true);
              }
              })
              .catch((err) => {
                notification["error"]({
                  message: err,
                });
              });
          }
        });
      };


  const addStockInventoryModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Inventario");
    setModalContent(
      <AddStockInventoryForm
        listItems={listItems}
        stockInventories={stockInventories}
        setIsVisibleModal={setIsVisibleModal}
        setReloadStockInventory={setReloadStockInventory}
      />
    );

  };

  const editStockInventoryModal = (inventory) => {
    setIsVisibleModal(true);
    setModalTitle("Editar Inventario");
    setModalContent(
      <EditStockInventoryForm
        itemStockInventory={inventory}
        setIsVisibleModal={setIsVisibleModal}
        setReloadStockInventory={setReloadStockInventory}
      />
    );
  };

  const viewStockInventoryModal = (inventory) => {
    setIsVisibleModal(true);
    setModalTitle("Ver Inventario");
    setModalContent(
      <ViewStockInventoryForm
        itemStockInventory={inventory}
      />
    );
  };


  return (
    <div className="list-inventories">
      <div className="list-inventories">
        <div className="list-inventories__header">
        <div className="list-inventories__header-switch">

        </div>

        {checkAction(permissionsActive, userToken.role, "stockinventories", "all") ||
        checkAction(permissionsActive, userToken.role, "stockinventories", "add") || isAdmin(roleById) ? (
          <>
            <Button className="button-add" icon={<PlusOutlined/>}  type="primary" onClick={addStockInventoryModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
        </div>

      </div>

      {checkAction(permissionsActive, userToken.role, "stockinventories", "all") ||
      checkAction(permissionsActive, userToken.role, "stockinventories", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-inventories__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <Radio.Group
              options={[
                { label: "Descripcion", value: "description" },
                { label: "Codigo", value: "item_code" },
              ]}
              onChange={(e) => {
                setTypeFilter(e.target.value);
              }}
              value={typeFilter}
            />
          </div>
        </>
      ) : (
        <>
        </>
      )}

        <StockInventory
          stockInventories={stockInventoriesFilter}
          editStockInventoryModal={editStockInventoryModal}
          viewStockInventoryModal={viewStockInventoryModal}
          deleteStockInventory={deleteStockInventory}
          accessToken={accessToken}
          userToken={userToken}
          permissionsActive={permissionsActive}
          roleById={roleById}
        />

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function StockInventory(props) {
    const {
      stockInventories,
      viewStockInventoryModal,
      deleteStockInventory,
      userToken,
      permissionsActive,
      roleById
    } = props; //editStockInventoryModal,

    const edit_inventory = (
      <div> <p>Editar inventario</p> </div>
    );

    const delete_inventory = (
      <div> <p>Eliminar inventario</p> </div>
    );

    const view_inventory = (
      <div> <p>Ver inventario</p> </div>
    );

    const columns = [
      {
        title: "Código",
        dataIndex: "item",
        render: (item) => item.item_code
      },
      {
        title: "Descripción",
        dataIndex: "item",
        render: (item) => item.description
      },
      {
        title: "Categoría",
        dataIndex:"item",
        render: (item) => item.category.description,
      },
      {
        title: "Precio 1",
        dataIndex: "item",
        render: (item) => item.price1,
        sorter: (a, b) => a.item.price1 - b.item.price1,
      },
      {
        title: "Precio 2",
        dataIndex: "item",
        render: (item) => item.price2,
        sorter: (a, b) => a.item.price2 - b.item.price2,
      },
      {
        title: "Stock",
        dataIndex: "stock",
        sorter: (a, b) => a.stock - b.stock,
      },
      {
        title: "Activo",
        dataIndex: "item",
        filters: [
          {
            text: 'Si',
            value: true,
          },
          {
            text: 'No',
            value: false,
          },
        ],
        filterMultiple: false,
        render: (item) => item.active? "Si" : "No",
        onFilter: (value, record) => record.item.active === value,
      },

      {
        title: "Acción",
        key: "action",
        render: (text, inventory) => (
          <div>
            {checkAction(permissionsActive, userToken.role, "stockinventories", "all") ||
            checkAction(permissionsActive, userToken.role, "stockinventories", "view") || isAdmin(roleById) ? (
              <>
                <Popover content={view_inventory} >
                  <Button className="button-inventory" type="default" onClick={() => viewStockInventoryModal(inventory)}>
                    <EyeOutlined />
                  </Button>
                </Popover>
                {/* <Popover content={edit_inventory} >
                  <Button className="button-inventory" type="default" onClick={() => editStockInventoryModal(inventory)}>
                    <EditOutlined />
                  </Button>
                </Popover> */}
              </>
            ) : (
              <>
              </>
            )}

            {checkAction(permissionsActive, userToken.role, "stockinventories", "all") ||
            checkAction(permissionsActive, userToken.role, "stockinventories", "delete") || isAdmin(roleById) ? (
              <>
                <Popover content={delete_inventory} >
                  <Button className="button-inventory" type="danger" onClick={() => deleteStockInventory(inventory)}>
                    <DeleteOutlined />
                  </Button>
                </Popover>
              </>
            ) : (
              <>
              </>
            )}
          </div>
        )
      }
    ];

    return (
      <>
        {checkAction(permissionsActive, userToken.role, "stockinventories", "all") ||
        checkAction(permissionsActive, userToken.role, "stockinventories", "view") || isAdmin(roleById) ? (
          <>
            <Table pagination={false} dataSource={stockInventories} columns={columns} rowKey="_id" />
          </>
        ) : (
          <>
          </>
        )}
      </>
    );
  }