import { basePath, apiVersion } from '../config';

/**FUNCIONES PARA MANEJO DEL MODULO BILLSELL QUE CONSUMEN LOS ENDPOINTS DEL SERVER*/

export function getBillSellExportApi(token) {
    const url = `${basePath}/${apiVersion}/billsell-export-csv`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "text/csv",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.text();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'billSell.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(err => {
            return err.message;
        });
}

export function postBillSellImportApi(token, file) {
    const url = `${basePath}/${apiVersion}/billsell-import-csv`;
    const formData = new FormData();
    formData.append("file", file, file.name);

    const params = {
        method: "POST",
        body: formData,
        headers: {
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch(err => {
            return err.message;
        });
}

export function addBillSellApi(token, data, userId){
    const url = `${basePath}/${apiVersion}/add-billsell/${userId}`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
    };

    return fetch(url, params)
        .then(response => {
            //console.log(response);
            return response.json();
        })
        .then(result => {
            return result;
        }) 
        .catch(err => {
            return {
                ok: false,
                message: err.message
            };
        });
}

export function getBillSellsApi(token) {
    const url = `${basePath}/${apiVersion}/billsells`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getBillSellsActiveApi(token, status) {
    const url = `${basePath}/${apiVersion}/billsells-active?active=${status}`;
    //console.log(url);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getBillSellsPaidApi(token, status) {
    const url = `${basePath}/${apiVersion}/billsells-paid?paid=${status}`;
    //console.log(url);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function updateBillSellApi(token, billSellData, billSellId) {
    const url = `${basePath}/${apiVersion}/update-billsell/${billSellId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(billSellData)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function activateBillSellApi(token, billSellId, status) {
    const url = `${basePath}/${apiVersion}/activate-billsell/${billSellId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            active: status
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result.message;
        })
        .catch(err => {
            return err.message;
        });
}

export function paidBillSellApi(token, billSellId, status) {
    const url = `${basePath}/${apiVersion}/paid-billsell/${billSellId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            paid: status
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result.message;
        })
        .catch(err => {
            return err.message;
        });
}

export function deleteBillSellApi(token, billSellId) {
    const url = `${basePath}/${apiVersion}/delete-billsell/${billSellId}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result.message;
        })
        .catch(err => {
            return err.message;
        });
}

export function itemExistInBillSellsApi(token,idItem) {
    const url = `${basePath}/${apiVersion}/billsells-exist-item/${idItem}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
             Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}
