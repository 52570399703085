import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getBillTypesActiveApi } from "../../../api/billing/billType";
import ListBillTypes from "../../../components/Billing/BillTypes/ListBillTypes";


export default function BillTypes() {
    console.log("entra en billtypes")
    const [billTypesActive, setBillTypesActive] = useState([]);
    const [billTypesInactive, setBillTypesInactive] = useState([]);
    const [reloadBillTypes, setReloadBillTypes ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getBillTypesActiveApi(token, true).then(response => {
            setBillTypesActive(response.billTypes);
        });
        getBillTypesActiveApi(token, false).then(response => {
            setBillTypesInactive(response.billTypes);
        });
        setReloadBillTypes(false);
    }, [token, reloadBillTypes]);

    return (
        <div className="billTypes">

            <ListBillTypes billTypesActive={billTypesActive} billTypesInactive={billTypesInactive} setReloadBillTypes={setReloadBillTypes}
                />

        </div>
    );
}