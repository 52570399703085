// Layout
import LayoutAdmin from "../layouts/LayoutAdmin";
import LayoutBasic from "../layouts/LayoutBasic";

// Admin Pages
// se puede poner otro nombre cuando la exportacion es default, si es normal hay que aclarar: import AdminHome, { pepe } from
import AdminHome from "../pages/Admin";
import AdminSignIn from "../pages/Admin/SignIn";
import AdminUsers from "../pages/Admin/Users";
import AdminReset from "../pages/Admin/Reset";
import AdminExports from "../pages/Admin/Exports";
import AdminImports from "../pages/Admin/Imports";
import AdminEditMenu from "../pages/Admin/Menu";
import AdminRoles from "../pages/Admin/Roles";
import AdminPermissions from "../pages/Admin/Permissions";
import AdminDays from "../pages/Admin/Days";
import AdminDurations from "../pages/Admin/Durations";
import AdminBranches from "../pages/Admin/Branches";
import AdminAddEditBranch from "../pages/Admin/AddEditBranch";
import AdminAddEditBusiness from "../pages/Admin/AddEditBusiness";
import AdminItems from "../pages/Admin/Items";
import AdminCategories from "../pages/Admin/Categories";
import AdminBranchCategories from "../pages/Admin/BranchCategories";
import AdminVariations from "../pages/Admin/Variations";
import AdminVariationCategories from "../pages/Admin/VariationCategories";
import AdminBranchVariations from "../pages/Admin/BranchVariations";
import AdminBranchItems from "../pages/Admin/BranchItems";
import AdminBusinesses from "../pages/Admin/Businesses";
import AdminBusinessBranches from "../pages/Admin/BusinessBranches";
/*
SISTEMA FACTURACION
*/

import AdminSuppliers from "../pages/Billing/Suppliers";
import AdminClients from "../pages/Billing/Clients";
import AdminBuyBills from "../pages/Billing/BuyBills";
import AdminBillSells from "../pages/Billing/BillSells";
import AdminStockInventory from "../pages/Billing/StockInventory";
import AdminMovements from "../pages/Billing/Movements";
import AdminDocumentTypes from "../pages/Billing/DocumentTypes";
import AdminIvaConditions from "../pages/Billing/IvaConditions";
import AdminSellers from "../pages/Billing/Sellers";
import AdminStateBills from "../pages/Billing/StateBills";
import AdminConcepts from "../pages/Billing/Concepts";
import AdminSalePoints from "../pages/Billing/SalePoints";
import AdminSaleConditions from "../pages/Billing/SaleConditions";
import AdminSupplierTypes from "../pages/Billing/SupplierTypes";
import AdminBillTypes from "../pages/Billing/BillTypes";
import AdminBillSystems from "../pages/Billing/BillSystems";
import AdminPaymentTypes from "../pages/Billing/PaymentTypes";
import AdminPaymentMethods from "../pages/Billing/PaymentMethods";
import AdminPaymentOrders from "../pages/Billing/PaymentOrders";
import AdminPersons from "../pages/Billing/Persons";
import AdminDeliveryTerms from "../pages/Billing/DeliveryTerms";
import AdminStateBuyOrders from "../pages/Billing/StateBuyOrders";
import AdminBuyOrders from "../pages/Billing/BuyOrders";
import AdminBillingItems from "../pages/Billing/Items";
import AdminBillingCategories from "../pages/Billing/Categories";

// User Pages
import Home from "../pages/Home";
import Contact from "../pages/Contact";

// Other
import Error404 from "../pages/Error404";

//todo lo que se centraliza aqui para agregar o modificar
//import { getMenuApi } from "../api/menu"; //getMenuViewApi
//import { getAccessToken } from "../api/auth";

//const token = getAccessToken();

/*
const componenteModule = {
    users: AdminUsers,
    //students: AdminStudents,
    //courses: AdminCourses,
    //"menu-edition": AdminEditMenu,
    permissions: AdminPermissions,
    roles: AdminRoles,
    //advisers: AdminAdvisers,
    //statecourses: AdminStateCourses,
    //schoolYears: AdminSchoolYears,
    exports: AdminExports,
    imports: AdminImports,
};
*/

/*
// PARA LOS MENUES
function routeMenu() {
    const arrayRoutes = [];
    getMenuApi(token).then((response) => {
        if (response.menu) {
        response.menu.map((i) => {
            if (i.nameModule) {
                arrayRoutes.push({
                path: i.url,
                component: componenteModule[i.nameModule],
                exact: true,
                name: `route.${i.nameModule}`,
                title: i.title
            });
            }
        });
        }
        arrayRoutes.push({
        component: Error404,
        });
    });
    return arrayRoutes;
}
*/

//const formatMenu = routeMenu();

const routes = [
    //LAYOUT ADMIN
    {
        path: "/admin",
        component: LayoutAdmin,
        exact: false,
        routes:
        // formatMenu,
        [
            {
                path: "/admin",
                component: AdminHome,
                exact: true,
                name:"route.admin"

            },
            {
                path: "/admin/login",
                component: AdminSignIn,
                exact: true,
                name:"route.login",
                title:"Login"
            },

            {
                path: "/admin/users",
                component: AdminUsers,
                exact: true,
                name:"route.users",
                title:"Usuario"
            },
            {
                path: "/admin/durations",
                component: AdminDurations,
                exact: true,
                name:"route.durations",
                title:"Duración"
            },
            {
                path: "/admin/config/days",
                component: AdminDays,
                exact: true,
                name:"route.days",
                title:"Dias"
            },
            {
                path: "/admin/exports",
                component: AdminExports,
                exact: true,
                name:"route.exports",
                title:"Export"
            },
            {
                path: "/admin/imports",
                component: AdminImports,
                exact: true,
                name:"route.imports",
                title:"Import"
            },
            {
                path: "/admin/editmenu",
                component: AdminEditMenu,
                exact: true,
                name:"route.editmenu",
                title:"Menu"
            },
            {
                path: "/admin/config/roles",
                component: AdminRoles,
                exact: true,
                name:"route.roles",
                title:"Rol"
            },
            {
                path: "/admin/config/permissions",
                component: AdminPermissions,
                exact: true,
                name:"route.permissions",
                title:"Permiso"
            },
            {
                path: "/admin/config/businesses",
                component: AdminBusinesses,
                exact: true,
                name:"route.permissions",//cambiar por el nombre correcto
                title:"Permiso"//cambiar por el nombre correcto
            },  
            {
                path: "/admin/config/business/:id",
                component: AdminAddEditBusiness,
                exact: true,
                name:"route.permissions",//cambiar por el nombre correcto
                title:"Permiso"//cambiar por el nombre correcto
            },    
            {
                path: "/admin/config/branches",
                component: AdminBranches,
                exact: true,
                name:"route.permissions",//cambiar por el nombre correcto
                title:"Permiso"//cambiar por el nombre correcto
            },     
            {
                path: "/admin/config/branches/:id",
                component: AdminBranches,
                exact: true,
                name:"route.permissions",//cambiar por el nombre correcto
                title:"Permiso"//cambiar por el nombre correcto
            },    
            {
                path: "/admin/config/business-branches/:name",
                component: AdminBusinessBranches,
                exact: true,
                name:"route.permissions",//cambiar por el nombre correcto
                title:"Permiso"//cambiar por el nombre correcto
            }, 
            {
                path: "/admin/config/branch",
                component: AdminAddEditBranch,
                exact: true,
                name:"route.permissions",//cambiar por el nombre correcto
                title:"Permiso"//cambiar por el nombre correcto
            },  
            {
                path: "/admin/config/branch/:id",
                component: AdminAddEditBranch,
                exact: true,
                name:"route.permissions",//cambiar por el nombre correcto
                title:"Permiso"//cambiar por el nombre correcto
            },   
            {
                path: "/admin/digitalmenu/items",
                component: AdminItems,
                exact: true,
                name:"route.permissions",//cambiar por el nombre correcto
                title:"Permiso"//cambiar por el nombre correcto
            },
            {
                path: "/admin/digitalmenu/branch-items",
                component: AdminBranchItems,
                exact: true,
                name:"route.permissions",//cambiar por el nombre correcto
                title:"Permiso"//cambiar por el nombre correcto
            },
            {
                path: "/admin/digitalmenu/categories",
                component: AdminCategories,
                exact: true,
                name:"route.permissions",//cambiar por el nombre correcto
                title:"Permiso"//cambiar por el nombre correcto
            },
            {
                path: "/admin/digitalmenu/branch-categories",
                component: AdminBranchCategories,
                exact: true,
                name:"route.permissions",//cambiar por el nombre correcto
                title:"Permiso"//cambiar por el nombre correcto
            },
            {
                path: "/admin/digitalmenu/variations",
                component: AdminVariations,
                exact: true,
                name:"route.permissions",//cambiar por el nombre correcto
                title:"Permiso"//cambiar por el nombre correcto
            },
            {
                path: "/admin/digitalmenu/branch-variations",
                component: AdminBranchVariations,
                exact: true,
                name:"route.permissions",//cambiar por el nombre correcto
                title:"Permiso"//cambiar por el nombre correcto
            },
            {
                path: "/admin/digitalmenu/variationcategories",
                component: AdminVariationCategories,
                exact: true,
                name:"route.permissions",//cambiar por el nombre correcto
                title:"Permiso"//cambiar por el nombre correcto
            },
            {
                path: "/reset/:resetPasswordToken",
                component: AdminReset,
                exact: true,
            },
            //SISTEMA FACTURACION
            {
                path: "/admin/billing/suppliers",
                component: AdminSuppliers,
                exact: true,
                name:"route.suppliers",
                title:"Proveedor"
            },
            {
                path: "/admin/billing/clients",
                component: AdminClients,
                exact: true,
                name:"route.clients",
                title:"Cliente"
            },
            {
                path: "/admin/billing/items",
                component: AdminBillingItems,
                exact: true,
                name:"route.items",
                title:"Producto"
            },
            {
                path: "/admin/billing/items/categories",
                component: AdminBillingCategories,
                exact: true,
                name:"route.categories",
                title:"Rubro"
            },
            {
                path: "/admin/billing/buyBills",
                component: AdminBuyBills,
                exact: true,
                name:"route.buyBills",
                title:"Comprobante Compra"
            },
            {
                path: "/admin/billing/buyOrders",
                component: AdminBuyOrders,
                exact: true,
                name:"route.buyOrders",
                title:"Comprobante Compra"
            },
            {
                path: "/admin/billing/billsells",
                component: AdminBillSells,
                exact: true,
                name:"route.billSells",
                title:"Comprobante Venta"
            },
            {
                path: "/admin/billing/items/inventory-stock",
                component: AdminStockInventory,
                exact: true,
                name:"route.itemsInventoryStock",
                title:"Inventario"
            },
            {
                path: "/admin/billing/items/inventory-movements",
                component: AdminMovements,
                exact: true,
                name:"route.movements",
                title:"Movimiento"
            },
            {
                path: "/admin/billing/config/documentType",
                component: AdminDocumentTypes,
                exact: true,
                name:"route.documentType",
                title:"Tipo de documento"
            },
            {
                path: "/admin/billing/config/ivaConditions",
                component: AdminIvaConditions,
                exact: true,
                name:"route.ivaConditions",
                title:"Condicion IVA"
            },
            {
                path: "/admin/billing/config/supplierTypes",
                component: AdminSupplierTypes,
                exact: true,
                name:"route.supplierTypes",
                title:"Tipo de proveedores"
            },
            {
                path: "/admin/billing/config/billTypes",
                component: AdminBillTypes,
                exact: true,
                name:"route.billTypes",
                title:"Tipo de Comprobante"
            },
            {
                path: "/admin/billing/billTypes",
                component: AdminBillTypes,
                exact: true,
                name:"route.billTypes",
                title:"Tipo de Comprobante"
            },
            {
                path: "/admin/billing/config/billSystems",
                component: AdminBillSystems,
                exact: true,
                name:"route.billSystems",
                title:""
            },
            {
                path: "/admin/billing/paymentTypes",
                component: AdminPaymentTypes,
                exact: true,
                name:"route.paymentTypes",
                title:"Tipo de Pago"
            },
            /*
            {
                path: "/admin/config/paymentMethods",
                component: AdminPaymentMethods,
                exact: true,
                name:"route.paymentMethods"
            },*/
            {
                path: "/admin/billing/paymentOrders",
                component: AdminPaymentOrders,
                exact: true,
                name:"route.paymentOrders",
                title:"Orden de pago"
            },
            {
                path: "/admin/billing/config/saleConditions",
                component: AdminSaleConditions,
                exact: true,
                name:"route.saleConditions",
                title:"Condicion de Venta"
            },
            {
                path: "/admin/billing/sellers",
                component: AdminSellers,
                exact: true,
                name:"route.sellers",
                title:"Vendedor"
            },
            {
                path: "/admin/billing/stateBills",
                component: AdminStateBills,
                exact: true,
                name:"route.stateBills",
                title:"Estado de Comprobante"
            },
            {
                path: "/admin/billing/concepts",
                component: AdminConcepts,
                exact: true,
                name:"route.concepts",
                title:"Conceptos"
            },
            {
                path: "/admin/billing/config/salePoints",
                component: AdminSalePoints,
                exact: true,
                name:"route.salePoints",
                title:"Punto de venta"
            },
            {
                path: "/admin/billing/persons",
                component: AdminPersons,
                exact: true,
                name:"route.persons",
                title:"Personas"
            },
            {
                path: "/admin/billing/config/deliveryTerms",
                component: AdminDeliveryTerms,
                exact: true,
                name:"route.deliveryTerms",
                title:"Plazo de entrega"
            },
            {
                path: "/admin/billing/config/stateBuyOrders",
                component: AdminStateBuyOrders,
                exact: true,
                name:"route.stateBuyOrders",
                title:"Estados de ordenes de compra"
            },

            {
                component: Error404
            },
        ]
    },

    //LAYOUT BASIC
    {
        path: "/",
        component: LayoutBasic,
        exact: false,
        routes: [
            {
                path: "/",
                component: Home,
                exact: true,
            },
            {
                path: "/contact",
                component: Contact,
                exact: true,
            },
            {
                path: "/reset/:resetPasswordToken",
                component: AdminReset,
                exact: true,
            },
            {
                component: Error404,
            },
        ],
    },
];

export default routes;
