import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import {
  UserOutlined,
  MailOutlined,
  CarOutlined,
  ScheduleOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
  DollarCircleOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import { updateClientApi } from "../../../../api/billing/client";
import { getPersonsActiveApi } from "../../../../api/person";
import { getIvaConditionsActiveApi } from "../../../../api/billing/ivaCondition";
import { getAccessToken } from "../../../../api/auth";
import { getDocumentTypesActiveApi } from "../../../../api/billing/documentType";

import "./EditClientForm.scss";

export default function EditClientForm(props) {
  const { client, setIsVisibleModal, setReloadClients } = props;
  const [clientData, setClientData] = useState({});
  const [ listPersonsActive, setListPersonsActive ] = useState([]);
  const [ ivaConditionsActive, setIvaConditionsActive ] = useState([]);

  //trae los datos del cliente
  useEffect(() => {
    setClientData({
      person: client.person._id,
      denomination: client.denomination,
      ivaCondition: client.ivaCondition._id,
      // taxes: client.taxes,
    });
  }, [client]);

  useEffect(() => {
    const accessToken=getAccessToken();
    getPersonsActiveApi(accessToken,true).then((response) => {
      console.log(response.persons);
      setListPersonsActive(response.persons);
    });
  }, []);

  useEffect(() => {
    const accessToken=getAccessToken();
    getIvaConditionsActiveApi(accessToken,true).then((response) => {
      setIvaConditionsActive(response.ivaConditions);
    });
  }, []);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!clientData.person || !clientData.denomination || !clientData.ivaCondition) { //BUG
      notification["error"]({
        message: "Obligatorios: Persona, denominacion y condicion de IVA.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const updateClient = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let clientUpdate = clientData; //es let porque se va actualizando

      updateClientApi(token, clientUpdate, client._id).then((result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //console.log("respuesta: " + result.message);
          setIsVisibleModal(false);
          setReloadClients(true);
        }
      });
    }
  };

  return (
    <div className="edit-client-form">
      <EditForm
        clientData={clientData}
        setClientData={setClientData}
        updateClient={updateClient}
        ivaConditionsActive={ivaConditionsActive}
        listPersonsActive={listPersonsActive}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    clientData,
    setClientData,
    listPersonsActive,
    updateClient,
    ivaConditionsActive,
  } = props;
  const { Option } = Select;

  return (
    <Form className="form-edit" onSubmit={updateClient}>
      {/* //onFinish={updateUser} */}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
             <span className="control-required">*</span>Persona
          </label>
          <Form.Item>
            <Select
              showSearch
              placeholder="Selecciona una persona"
              value={clientData.person}
              onChange={(e) => setClientData({ ...clientData, person: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listPersonsActive
                ? listPersonsActive.map((person) => {
                    return (
                      <Option key={person._id}>
                        {person.name + " " + person.lastname}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item>
            <label className="control-label">
               <span className="control-required">*</span>Denominacion</label>
            <Input
              type="text"
              placeholder="Denominacion"
              value={clientData.denomination}
              onChange={(e) =>
                setClientData({
                  ...clientData,
                  denomination: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item>
            <label className="control-label">
               <span className="control-required">*</span>Condicion de IVA</label>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona una condicion de IVA"
              value={clientData.ivaCondition}
              onChange={(e) => setClientData({ ...clientData, ivaCondition: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {ivaConditionsActive
                ? ivaConditionsActive.map((condition) => {
                    return (
                      <Option key={condition._id}>
                        {condition.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label"> Impuestos</label>
          <Form.Item>
            <Input
              name="taxes"
              placeholder="Posicion en impuestos"
              value={clientData.taxes}
              onChange={(e) => {
                setClientData({
                  ...clientData,
                  taxes: e.target.value,
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>    
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateClient}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
