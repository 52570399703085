import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getClientsActiveApi } from "../../../api/billing/client";
import ListClients from "../../../components/Billing/Clients/ListClients";

import "./Clients.scss";

export default function Clients() {
    const [clientsActive, setClientsActive] = useState([]);
    const [clientsInactive, setClientsInactive] = useState([]);
    const [reloadClients, setReloadClients ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getClientsActiveApi(token, true).then(response => {
            setClientsActive(response.clients);
        });
        getClientsActiveApi(token, false).then(response => {
            setClientsInactive(response.clients);
        });
        setReloadClients(false);
    }, [token, reloadClients]);

    return (
        <div className="clients">

            <ListClients clientsActive={clientsActive} clientsInactive={clientsInactive} setReloadClients={setReloadClients} 
                />
        
        
        </div>
    );
}