import { switchPath, apiVersion } from './config';

/**FUNCIONES PARA MANEJO DEL MODULO SUCURSAL QUE CONSUMEN LOS ENDPOINTS DEL SERVER*/

export function getBranchExportApi(token) {
    const url = `${switchPath()}/${apiVersion}/branch-export-csv`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "text/csv",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.text();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'branch.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(err => {
            return err.message;
        });
}

export function postBranchImportApi(token, file) {
    const url = `${switchPath()}/${apiVersion}/branch-import-csv`;
    const formData = new FormData();
    formData.append("file", file, file.name);

    const params = {
        method: "POST",
        body: formData,
        headers: {
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch(err => {
            return err.message;
        });
}

export function addBranchApi(token, data){
    const url = `${switchPath()}/${apiVersion}/add-branch`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
    };

    return fetch(url, params)
        .then(response => {
            //console.log(response);
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return {
                ok: false,
                message: err.message
            };
        });
}

export function getBranchesApi(token) {
    const url = `${switchPath()}/${apiVersion}/branches`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getBranchesPaginateApi(token,state, limit, page, filter) {
    const url = `${switchPath()}/${apiVersion}/branches-paginate?state=${state}&limit=${limit}&page=${page}&filter=${filter}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getBranchesActiveApi(token, status) {
    const url = `${switchPath()}/${apiVersion}/branches-active?active=${status}`;
    //console.log(url);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getBranchesActiveByBusinessApi(token, status,businessId) {
    const url = `${switchPath()}/${apiVersion}/branches-active-business/${businessId}/${status}`;
    //console.log(url);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function updateBranchApi(token, branchData, branchId) {
    const url = `${switchPath()}/${apiVersion}/update-branch/${branchId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(branchData)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function getBranchApi(token, branchId) {
    const url = `${switchPath()}/${apiVersion}/branch/${branchId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function activateBranchApi(token, branchId, status) {
    const url = `${switchPath()}/${apiVersion}/activate-branch/${branchId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            active: status
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result.message;
        })
        .catch(err => {
            return err.message;
        });
}

export function deleteBranchApi(token, branchId) {
    const url = `${switchPath()}/${apiVersion}/delete-branch/${branchId}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function uploadLogoApi(token, data, branchId) {
    const url = `${switchPath()}/${apiVersion}/upload-logo-branch/${branchId}`;

    const params = {
        method: "POST",
        body: data,
        headers: {
            //'Content-Type': 'multipart/form-data',
            Authorization: token
        }
    }
    return fetch(url, params)
        .then(response => {
            return response;
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getLogoApi(logoName) {
    const url = `${switchPath()}/${apiVersion}/get-logo-branch/${logoName}`;
    return fetch(url)
    .then(response => {
        return response.url;
    })
    .catch(err => {
        return err.message;
    });
}

export function uploadCoverApi(token, data, branchId) {
    const url = `${switchPath()}/${apiVersion}/upload-cover-branch/${branchId}`;
    const params = {
        method: "POST",
        body: data,
        headers: {
            //'Content-Type': 'multipart/form-data',
            Authorization: token
        }
    }
    return fetch(url, params)
        .then(response => {
            return response;
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getCoverApi(coverName) {
    const url = `${switchPath()}/${apiVersion}/get-cover-branch/${coverName}`;
    return fetch(url)
    .then(response => {
        return response.url;
    })
    .catch(err => {
        return err.message;
    });
}

export function getBranchesByBusinessApi(token, businessId) {
    const url = `${switchPath()}/${apiVersion}/branches-business/${businessId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function uploadBranchesLogoApi(token, data, business) {
    //console.log(data)
    const url = `${switchPath()}/${apiVersion}/upload-logo-branches/${business}`;

    const params = {
        method: "POST",
        headers: {
            //"Content-Type": "application/json",
            Authorization: token
        },
        body: data,
    }
    return fetch(url, params)
        .then(response => {
            return response;
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function uploadBranchesCoverApi(token, data, business) {
    //console.log(data)
    const url = `${switchPath()}/${apiVersion}/upload-cover-branches/${business}`;

    const params = {
        method: "POST",
        headers: {
            //"Content-Type": "application/json",
            Authorization: token
        },
        body: data,
    }
    return fetch(url, params)
        .then(response => {
            return response;
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}
