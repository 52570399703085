import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  notification,
  Divider,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  updateBillSellApi,
} from "../../../../api/billing/billsell";
import { getClientsActiveApi } from "../../../../api/billing/client";
import { getStateBillsActiveApi } from "../../../../api/billing/stateBill";
import { getItemsActiveApi } from "../../../../api/item";
import { getBillTypesActiveApi } from "../../../../api/billing/billType";
import { getAccessToken } from "../../../../api/auth";
import { formatDateCalendar } from "../../../../utils/formatDate";

import {
  updateAddStockInventoryApi,
  updateSubStockInventoryApi,
  getStockInventoriesApi,
} from "../../../../api/billing/stockInventory";
import { addMovementApi } from "../../../../api/billing/movement";
import moment from "moment";
import jwtDecode from "jwt-decode";

import "./EditBillSellForm.scss";
import TextArea from "antd/lib/input/TextArea";
const { Text } = Typography;

export default function EditBillSellForm(props) {
  const { billSell, setIsVisibleModal, setReloadBillSells } = props;
  const [billSellData, setBillSellData] = useState({
    items:[]
  });
  const [listStockInventory, setListStockInventory] = useState([]);
  const [activeClients, setActiveClients] = useState([]);
  const [activeStateBills, setActiveStateBills] = useState([]);
  const [activeItems, setActiveItems] = useState([]);
  const [supplierDB, setSupplierDB] = useState([]);
  const [activeBillTypes, setActiveBillTypes] = useState([]);

  const accessToken = getAccessToken();

  const [updateItems, setUpdateItems] = useState(false);
  const [billsData, setBillsData] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);

  //estado relacionado a si el usuario realizó modificación en los inputs que deben ser validados
  /*
  const [modifDataToValid, setModifDataToValid] = useState({
    billbuy_code: false,
    state: false
  });
  //estado que almacena el resultado de validacion de los campos correspondientes
  const [validData, setValidData] = useState({
    billbuy_code: false,
  });*/

  useEffect(() => {
    getClientsActiveApi(accessToken, true).then((response) => {
      setActiveClients(response.clients);
    });
  }, []);

  useEffect(() => {
    getStateBillsActiveApi(accessToken, true).then((response) => {
      setActiveStateBills(response.stateBills);
    });
  }, []);

  useEffect(() => {
    getItemsActiveApi(accessToken, true).then((response) => {
      setActiveItems(response.items);
    });
  }, []);

  useEffect(() => {
    getBillTypesActiveApi(accessToken, true).then((response) => {
      setActiveBillTypes(response.billTypes);
    });
  }, []);

  useEffect(() => {
    getStockInventoriesApi(accessToken).then((response) => {
      setListStockInventory(response.stockInventories);
    });
  }, []);

  useEffect(() => {
    setBillSellData({
      code: billSell.code,
      billType: billSell.billType,
      items: billSell.items,
      // subtotal: billSell.subtotal,
      total: billSell.total,
      state: billSell.state,
      // observation: billSell.observation,
      date: formatDateCalendar(billSell.date),
      expirationDate: billSell.expirationDate
        ? formatDateCalendar(billSell.expirationDate)
        : null,
      periodFrom: billSell.periodFrom
        ? formatDateCalendar(billSell.periodFrom)
        : null,
      periodoUntil: billSell.periodUntil
        ? formatDateCalendar(billSell.periodoUntil)
        : null,
      //uploadDate: formatDateCalendar(billSell.uploadDate),
      deliveryDate: formatDateCalendar(billSell.deliveryDate),
      // saleCondition: billSell.saleCondition,
      // concept: billSell.concept,
      // paymentType: billSell.paymentType,
      // bills: billSell.bills,
      client: billSell.client._id,
      address: billSell.address,
      // seller: billSell.seller,
      // salePoint: billSell.salePoint,
      _item: billSell._item,
      paid: billSell.paid,
      active: billSell.active,
      // discount: billSell.discount ? billSell.discount : 0,
      quantity: 1,
    });
    // setItemsData(billSell.items);
    // setBillsData(billSell.bills);
  }, [billSell]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!billSellData.code || !billSellData.client || !billSellData.date ) {
      notification["error"]({
        message: "Obligatorios: Código, Cliente y fecha de emision.",
      });
      errorExists = true;
    }
    if (
      billSellData.length === 0
    ) {
      notification["error"]({
        message:
          "Obligatorio: Debe seleccionar al menos un producto.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const updateBillSell = async (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      // setButtonLoading(true);
      
      const token = getAccessToken();
     
      billSellData.client = billSellData.client._id;
      let billSellUpdate = billSellData;

      await updateBillSellApi(token, billSellUpdate, billSell._id).then(
        (result) => {
          if (
            result.message === "ERR_CONNECTION_REFUSED" ||
            result.message === "Failed to fetch" ||
            result.message === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
            setIsVisibleModal(true);
          } else if (result.code !== 200) {
            notification["error"]({
              message: result.message,
            });
            setIsVisibleModal(true);
          } else {
            notification["success"]({
              message: result.message, //el mensaje que viene del server
            });
            //console.log("respuesta: " + result.message);
            setIsVisibleModal(false);
            setUpdateItems(true);
            setReloadBillSells(true);
          }
        }
      );
    }
  };

  const addStock = (accessToken, inventoryId, data, item) => {
    updateAddStockInventoryApi(accessToken, inventoryId, data)
      .then((response) => {
        if (
          response.message === "ERR_CONNECTION_REFUSED" ||
          response.message === "Failed to fetch" ||
          response === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          return false;
        } else if (response.code !== 200) {
          notification["error"]({
            message: response.message,
          });
          return false;
        } else {
          // notification["success"]({
          //   message: "Stock actualizado",
          // });
          let movementData = {
            date: formatDateCalendar(moment()),
            movement_type: "input",
            item: item._item,
            origin: "billSell",
            billSell: billSell._id,
            quantity: data.quantity,
            user: jwtDecode(accessToken).id,
            observation: "comprobante modificado",
          };
          return addMovementApi(accessToken, movementData);
        }
      })
      .then((response) => {
        if (response) {
          if (
            response.message === "ERR_CONNECTION_REFUSED" ||
            response.message === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          } else {
            // notification["success"]({
            //   message: "Movimiento creado",
            // });
          }
        }
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const subStock = (accessToken, inventoryId, data, item) => {
    updateSubStockInventoryApi(accessToken, inventoryId, data)
      .then((response) => {
        if (
          response.message === "ERR_CONNECTION_REFUSED" ||
          response.message === "Failed to fetch" ||
          response === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          return false;
        } else if (response.code !== 200) {
          notification["error"]({
            message: response.message,
          });
          return false;
        } else {
          // notification["success"]({
          //   message: "Stock actualizado",
          // });
          let movementData = {
            date: formatDateCalendar(moment()),
            movement_type: "output",
            item: item._item,
            origin: "billSell",
            billSell: billSell._id,
            quantity: data.quantity,
            user: jwtDecode(accessToken).id,
            observation: "comprobante modificado",
          };
          return addMovementApi(accessToken, movementData);
        }
      })
      .then((response) => {
        if (response) {
          if (
            response.message === "ERR_CONNECTION_REFUSED" ||
            response.message === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          } else {
            // notification["success"]({
            //   message: "Movimiento creado",
            // });
          }
        }
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const checkForDeleted = () => {
    billSell.items.map((item) => {
      //busco si item que se elimino
      let itemDelete = billSellData.items.find((i) => i._id === item._id);
      if (!itemDelete) {
        //verifico que tenga inventario
        let inventory = listStockInventory.find(
          (i) => i.item._id === item._item._id || i.item._id === item._item._id
        );
        if (inventory) {
          let data = {
            quantity: item.quantity,
          };
          addStock(accessToken, inventory._id, data, item);
        }
      }
      return true;
    });
  };
  useEffect(() => {
    //verifico que el estado sea vigente
    //if (billSellData.state === "Vigente") {
    //verifico que exista actualizacion en los items
    if(updateItems) {
      //Verifico si existen items que han sido eliminados del detalle
      checkForDeleted();
      //Verifico el nuevo detalle de items
      billSellData.items.map((item) => {
        //primero verifico si tiene inventario
        let inventory = listStockInventory.find(
          (i) => i.item._id === item._item || i.item._id === item._item._id
        );

        if (inventory) {
          //verifico que este en la lista anterior de items sino lo agrego
          let exits = billSell.items.find(
            (i) => i._item._id === item._item || i._item === item._item
          );
          if (exits) {
            //console.log("busco valor anterior");
            let preValue = billSell.items.find(
              (i) => i._item._id === item._item || i._item === item._item
            );
            //si tiene valor anterior
            if (preValue) {
              let difference = 0;
              if (preValue.quantity !== item.quantity) {
                difference = preValue.quantity - item.quantity;
                console.log(difference);
                if (difference !== 0) {
                  let data = {
                    quantity: Math.abs(difference),
                  };

                  if (difference <= -1) {
                    subStock(accessToken, inventory._id, data, item);
                  } else if (difference >= 1) {
                    addStock(accessToken, inventory._id, data, item);
                  }
                }
              }
            }
          } else {
            // console.log("es nuevo");
            let data = {
              quantity: item.quantity,
            };
            addStock(accessToken, inventory._id, data, item);
          }
        }
        return true;
      });
    }
  }, [updateItems]);

  return (
    <div className="edit-billsell-form">
      <EditForm
        billSellData={billSellData}
        setBillSellData={setBillSellData}
        updateBillSell={updateBillSell}
        activeClients={activeClients}
        // activeSellers={activeSellers}
        activeStateBills={activeStateBills}
        // activeConcepts={activeConcepts}
        // activeSalePoints={activeSalePoints}
        activeItems={activeItems}
        // activePaymentTypes={activePaymentTypes}
        // activeBillSells={activeBillSells}
        activeBillTypes={activeBillTypes}
        supplierDB={supplierDB}
        setSupplierDB={setSupplierDB}
        // itemsData={itemsData}
        // setItemsData={setItemsData}
        billsData={billsData}
        setBillsData={setBillsData}
        accessToken={accessToken}
        buttonLoading={buttonLoading}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    billSellData,
    setBillSellData,
    updateBillSell,
    activeClients,
    activeSellers,
    activeStateBills,
    activeConcepts,
    activeSalePoints,
    activeItems,
    activePaymentTypes,
    activeBillSells,
    activeBillTypes,
    // itemsData,
    // setItemsData,
    billsData,
    setBillsData,
    accessToken,
    buttonLoading
  } = props;
  const { Option } = Select;
  const [activeSellerSalePoints, setActiveSellerSalePoints] = useState([]);

  useEffect(() => {
    if (activeClients.length > 0 && billSellData.client) {
      let client = activeClients.find((i) => i._id === billSellData.client);
      setBillSellData({...billSellData, address:client.person.address});
    }
  }, [billSellData.client]);

  //useEffect(() => {

  //   if (billSellData.seller) {
  //     if (billSellData.seller._id) {
  //       getSalePointsBySellerApi(accessToken, billSellData.seller._id).then(
  //         (response) => {
  //           setActiveSellerSalePoints(response.salePoints);
  //         }
  //       );
  //     } else {
  //       getSalePointsBySellerApi(accessToken, billSellData.seller).then(
  //         (response) => {
  //           setActiveSellerSalePoints(response.salePoints);
  //         }
  //       );
  //     }
  //   }
  //   // else{
  //   //     setActiveSellerSalePoints([]);
  //   //  }
  // }, [billSellData.seller]);

  const setListItems = (items) =>{
    setBillSellData({...billSellData, items: items})
  }

  const addItems = () => {
    if (billSellData.item && billSellData.quantity) {
      //Verifico si el item no esta en el detalle
      let itemExistInDetail = billSellData.items.find(
        (i) => i._item === billSellData.item || i._item._id === billSellData.item
      );
      if (!itemExistInDetail) {
        let item = activeItems.filter((i) => i._id === billSellData.item)[0];
        let description = item.description;
        let item_code = item.item_code;

        const newData = {
          _item: billSellData.item,
          item_code: item_code,
          description: description,
          unitValue: billSellData.unitValue,
          quantity: billSellData.quantity,
        };
        // setItemsData([...itemsData, newData]);
        setBillSellData({ ...billSellData, items: [...billSellData.items,newData]});
      } else {
        notification["error"]({
          message: "El producto ya se encuentra en el detalle de la factura",
        });
      }
    } else {
      notification["error"]({
        message:
          "Debe elegir al menos un producto e ingresar la cantidad requerida",
      });
    }
  };

  useEffect(() => {
    let totals = 0;
    billSellData.items.forEach(({ unitValue, quantity }) => {
      totals += unitValue * quantity;
    });

    setBillSellData({ ...billSellData, total: totals });
  }, [billSellData.items]);

  return (
    <Form className="form-edit" size="middle" onSubmit={updateBillSell}>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Código
          </label>
          <Form.Item rules={[{ required: true }]}>
            <InputNumber
              name="code"
              placeholder="Código"
              value={billSellData.code}
              onChange={(e) => setBillSellData({ ...billSellData, code: e })}
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Tipo de Comprobante
          </label>
          <Form.Item rules={[{ required: true }]}>
            <Select
              showSearch
              name="billType"
              value={billSellData.billType}
              onChange={(e) =>
                setBillSellData({ ...billSellData, billType: e })
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {activeBillTypes
                ? activeBillTypes.map((i) => {
                    return <Option key={i.description}>{i.description}</Option>;
                  })
                : "No tipo de comprobantes"}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
          <span className="control-required">*</span>Cliente</label>
          <Form.Item>
            <Select
              showSearch
              placeholder="Seleccione el cliente"
              value={billSellData.client}
              onChange={(e) => setBillSellData({ ...billSellData, client: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {activeClients
                ? activeClients.map((i) => {
                    return <Option key={i._id}>{i.denomination}</Option>;
                  })
                : "No hay clientes activos"}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Domicilio</label>
          <Form.Item>
            <Input
              value={billSellData.address}
              onChange={(e) =>
                setBillSellData({
                  ...billSellData,
                  address: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Fecha Emisión
          </label>
          <Form.Item rules={[{ required: true }]}>
            <Input
              type="date"
              name="date"
              placeholder="Fecha de Factura"
              value={billSellData.date}
              onChange={(e) =>
                setBillSellData({
                  ...billSellData,
                  date: e.target.value,
                })
              }
              required
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
             Fecha Entrega
          </label>
          <Form.Item rules={[{ required: true }]}>
            <Input
              type="date"
              name="deliveryDate"
              placeholder="Fecha de entrega"
              value={billSellData.deliveryDate}
              onChange={(e) =>
                setBillSellData({
                  ...billSellData,
                  deliveryDate: e.target.value,
                })
              }
              required
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            Período desde
          </label>
          <Form.Item>
            <Input
              type="date"
              name="periodFrom"
              placeholder="desde"
              value={billSellData.periodFrom}
              onChange={(e) =>
                setBillSellData({
                  ...billSellData,
                  periodFrom: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            Hasta
          </label>
          <Form.Item>
            <Input
              type="date"
              placeholder="hasta"
              value={billSellData.periodUntil}
              onChange={(e) =>
                setBillSellData({
                  ...billSellData,
                  periodUntil: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>

      {/* <Row gutter={24}>
        <Col span={12}>
          <label className="control-label">Vendedor</label>
          <Form.Item>
            <Select
              placeholder="Vendedor"
              name="seller"
              value={billSellData.seller ? billSellData.seller.name : ""}
              //defaultValue={supplierDB.name}
              onChange={(e) =>
                setBillSellData({
                  ...billSellData,
                  seller: e,
                  salePoint: "",
                })
              }
            >
              {activeSellers
                ? activeSellers.map((i) => {
                    return <Option key={i._id}>{i.name}</Option>;
                  })
                : "No hay vendedores disponibles"}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">Punto de venta</label>
          <Form.Item>
            <Select
              placeholder="Punto de venta"
              value={
                billSellData.salePoint
                  ? billSellData.salePoint.salepoint_code
                  : ""
              }
              //defaultValue={supplierDB.name}
              onChange={(e) =>
                setBillSellData({ ...billSellData, salePoint: e })
              }
            >
              {activeSellerSalePoints
                ? activeSellerSalePoints.map((i) => {
                    return (
                      <Option key={i._id}>
                        {i.salepoint_code} {i.address} {i.number}{" "}
                        {i.postal_code} {i.locality}
                      </Option>
                    );
                  })
                : "No hay puntos de venta disponibles"}
            </Select>
          </Form.Item>
        </Col>
      </Row> */}

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">
            Fecha Vencimiento
          </label>
          <Form.Item>
            <Input
              type="date"
              name="expirationDate"
              placeholder="Vencimiento"
              value={billSellData.expirationDate}
              onChange={(e) =>
                setBillSellData({
                  ...billSellData,
                  expirationDate: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider orientation="left" style={{margin:5}}></Divider>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">Producto/Servicio</label>
          <Form.Item>
            <Select
              placeholder="Seleccione un producto/servicio"
              value={billSellData.item}
              onChange={(e) =>
                setBillSellData({
                  ...billSellData,
                  item: e,
                  unitValue: activeItems.filter((i) => i._id === e)[0].price1,
                })
              }
            >
              {activeItems
                ? activeItems.map((i) => {
                    return <Option key={i._id}>{i.description}</Option>;
                  })
                : "No hay productos disponibles"}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Valor Unitario</label>
          <Form.Item>
            <Input
              name="unitValue"
              value={billSellData.unitValue}
              onChange={(e) =>
                setBillSellData({
                  ...billSellData,
                  unitValue: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Cantidad</label>
          <Form.Item>
            <InputNumber
              placeholder="Cantidad"
              value={billSellData.quantity}
              defaultValue={1}
              onChange={(e) =>
                setBillSellData({ ...billSellData, quantity: e })
              }
              min={1}
              max={100}
              style={{ width: '100%' }}
              max={1000000}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item>
            <Button type="primary" className="button-add" size="middle" onClick={addItems}>
              <PlusOutlined /> Agregar
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={24}>
        {/* {itemsData.length > 0 ? ( */}
          <Items
            itemsData={billSellData.items}
            setItemsData={setListItems}
            // showDeleteConfirm={showDeleteConfirm}
          />
        {/* ) : null} */}
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">Total</label>
          <Form.Item>
            <Input
              type="text"
              name="total"
              value={billSellData.total}
              size="middle"
              decimalSeparator=","
              disabled={true}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateBillSell}
          // loading={buttonLoading}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}

function Bills(props) {
  const { billsData, showDeleteBillConfirm } = props;
  const columns = [
    // { title: "Tipo comprobante", dataIndex: "billType" },
    { title: "Codigo", dataIndex: "code" },
    {
      title: "",
      key: "action",
      render: (text, item) => (
        <span>
          <Button
            className="button-item"
            type="danger"
            onClick={(e) => showDeleteBillConfirm(item)}
          >
            <CloseOutlined />
          </Button>
        </span>
      ),
    },
  ];
  return (
    <Table
      pagination={false}
      columns={columns}
      dataSource={billsData}
      rowKey="_id"
    />
  );
}

function Items(props) {
  const { itemsData, setItemsData } = props;

  const columns = [
    {
      title: "Código",
      dataIndex: "item_code",
      render: (_item, row) =>
        row.item_code ? row.item_code : row._item.item_code,
      width: "15%",
      editable: false,
    },
    {
      title: "Producto",
      dataIndex: "description",
      render: (_item, row) =>
        row.description ? row.description : row._item.description,
      width: "25%",
      editable: false,
    },
    {
      title: "Valor Unitario",
      dataIndex: "unitValue",
      width: "20%",
      editable: true,
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      width: "15%",
      editable: true,
    },
    {
      title: "Total",
      // dataIndex:"quantity",
      render: (_item, row) =>
             row.unitValue ? row.unitValue * row.quantity : 0,
      width: "10%",
      editable: false,
    },
  ];
  return (
    <EditableItemTable
      columnsData={columns}
      data={itemsData}
      setData={setItemsData}
      rowKey="_item"
    />
  );
}

function EditableItemTable(props) {
  const { data, setData, columnsData, rowKey } = props;
  const [form] = Form.useForm();
  // const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Por favor ingrese ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const isEditing = (record) => record._item === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      description: "",
      unitValue: "",
      quantity: "",
      ...record,
    });
    setEditingKey(record._item);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item._item);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      // console.log('Validate Failed:', errInfo);
    }
  };

  const showDeleteConfirm = (record) => {
    const d = data.filter((i) => i !== record);
    if (d.length === 0) {
      setData([]);
    } else {
      setData(d);
    }
  };

  const columns = [
    ...columnsData,
    {
      title: "Acción",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              type="primary"
              size="small"
              onClick={() => save(record._item)}
            >
              <SaveOutlined />
            </Button>
            <Popconfirm title="Está seguro de cancelar?" onConfirm={cancel}>
              <Button type="danger" size="small">
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Button
              type="default"
              size="small"
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <EditOutlined />
            </Button>
            <Button
              className="button-item"
              type="danger"
              size="small"
              onClick={(e) => showDeleteConfirm(record)}
            >
              <DeleteOutlined />
            </Button>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "quantity" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        size="small"
        style={{margin:20}}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        rowKey={rowKey}
        scroll={{y:150}}
        summary={(pageData) => {
          let total = 0;

          pageData.forEach(({ unitValue, quantity }) => {
            total += unitValue * quantity;
          });
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell>Total</Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text type="danger">{total}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </Form>
  );
}
