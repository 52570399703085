import React, { useState, useEffect } from "react";
import {
  Switch,
  List,
  Input,
  onHover,
  Radio,
  Button,
  notification,
  Row,
  Col,
  Form,
  Select,
  Modal as ModalAntd,
} from "antd";
import {
  EditOutlined,
  EyeOutlined,
  FileExclamationOutlined,
  CloseSquareOutlined,
  DownloadOutlined,
  PlusOutlined,
  DeleteOutlined
} from "@ant-design/icons";

import { Popover } from "antd";

import Modal from "../../../Modal";
import EditBillSellForm from "../EditBillSellForm";
import EditStateForm from "../EditStateForm";
import ViewBillSellForm from "../ViewBillSellForm";
import AddBillSellForm from "../AddBillSellForm";
import { deleteBillSellApi } from "../../../../api/billing/billsell";
import SearchByDates from "../../SearchByDates/SearchByDates";
import { billSellDetailPdf } from "./pdfBillSells";
import { convertState } from "../../../../utils/convert";
//import format from "date-fns/format"
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";
import { formatDateView } from "../../../../utils/formatDate";
import "./ListBillSells.scss";

const { confirm } = ModalAntd;
const { Search } = Input;
//import TextArea from "antd/lib/input/TextArea";

export default function ListBillSells(props) {
  const {
    token,
    billSellsActive,
    billSellsInactive,
    billSellsPaid,
    billSellsUnpaid,
    setReloadBillSells,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  //console.log(billSellsUnpaid);
  const [viewBillSellsPaids, setViewBillSellsPaids] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [modalWidth, setModalWidth] = useState(null);
  const [filterText, setFilterText] = useState("");

  const [billSellsPaidFilter, setBillSellsPaidFilter] = useState([]);
  const [billSellsUnpaidFilter, setBillSellsUnpaidFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState("code");

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, [roleById]);

  useEffect(() => {
    const listPaid = [];
    if (billSellsPaid) {
      billSellsPaid.forEach((element) => {
        if (typeFilter === "state") {
            if ( (element.state &&
              element.state.name.toUpperCase().indexOf(filterText.toUpperCase()) !==
                -1) ||
              filterText === ""
            ) {
              listPaid.push(element);
            }
        } else {
          if (
            element.code
              .toString()
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listPaid.push(element);
          }
        }
      });
    }
    setBillSellsPaidFilter(listPaid);
  }, [filterText, billSellsPaid]);

  useEffect(() => {
    const listUnpaid = [];
    if (billSellsUnpaid) {
      billSellsUnpaid.forEach((element) => {
        if (typeFilter === "state") {
            if ( (element.state &&
              element.state.name.toUpperCase().indexOf(filterText.toUpperCase()) !==
                -1) ||
              filterText === "")
            {
              listUnpaid.push(element);
            }
        } else {
          if (
            element.code
              .toString()
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listUnpaid.push(element);
          }
        }
      });
    }
    setBillSellsUnpaidFilter(listUnpaid);
  }, [filterText, billSellsUnpaid]);

  const addBillSellModal = async () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Comprobante de venta");
    setModalWidth(900);
    await setModalContent(
      <AddBillSellForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadBillSells={setReloadBillSells}
      />
    );
  };

  // //const [inputs, setInputs] = useState({ state: "", date_buy: "", observation: "" });

  return (
    <div className="list-billsells">
      <div className="list-billsells__header">
        {/*
        <div className="list-billbuys__header-switch">
          <Switch
            defaultChecked
            onChange={() => setViewBillBuysActives(!viewBillBuysActives)}
          />
          <span>
            {viewBillBuysActives
              ? "Facturas de compra habilitadas"
              : "Facturas de compra inhabilitadas"}
          </span>
        </div>
        */}
        {checkAction(permissionsActive, userToken.role, "billsells", "all") ||
        checkAction(permissionsActive, userToken.role, "billsells", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-billsells__header-switch">
              <Switch
                defaultChecked
                onChange={() => setViewBillSellsPaids(!viewBillSellsPaids)}
              />
              <span>{viewBillSellsPaids ? "Pagadas" : "Impagas"}</span>
            </div>
          </>
        ) : (
          <>
          </>
        )}

        {checkAction(permissionsActive, userToken.role, "billsells", "all") ||
        checkAction(permissionsActive, userToken.role, "billsells", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addBillSellModal}>
              Nuevo 
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "billsells", "all") ||
      checkAction(permissionsActive, userToken.role, "billsells", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-billsells__search">
            <Row>
              <Col span={20}>
                <Search
                  className="Buscar"
                  placeholder=""
                  onChange={(e) => setFilterText(e.target.value)}
                  enterButton
                />
                <Radio.Group
                  options={[
                    { label: "Código", value: "code" },
                    { label: "Estado", value: "state" }, //TODO: lee el state de la db, no el convertido
                  ]}
                  onChange={(e) => {
                    setTypeFilter(e.target.value);
                  }}
                  value={typeFilter}
                />
              </Col>
              <Col span={4}>
                <SearchByDates
                  searchList={viewBillSellsPaids ? BillSellsPaid : billSellsUnpaid}
                  setSearchList={
                    viewBillSellsPaids
                      ? setBillSellsPaidFilter
                      : setBillSellsUnpaidFilter
                  }
                  setReload={setReloadBillSells}
                  dataSearch={"date"}
                />
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewBillSellsPaids ? (
        <>
          {checkAction(permissionsActive, userToken.role, "billsells", "all") ||
          checkAction(permissionsActive, userToken.role, "billsells", "view") || isAdmin(roleById) ? (
            <>
              <BillSellsPaid
                billSellsPaid={billSellsPaidFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setModalWidth={setModalWidth}
                setReloadBillSells={setReloadBillSells}
                token={token}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "billsells", "all") ||
          checkAction(permissionsActive, userToken.role, "billsells", "view") || isAdmin(roleById) ? (
            <>
              <BillSellsUnpaid
                billSellsUnpaid={billSellsUnpaidFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setModalWidth={setModalWidth}
                setReloadBillSells={setReloadBillSells}
                token={token}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        modalWidth={modalWidth}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function BillSellsPaid(props) {
  const {
    billSellsPaid,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setModalWidth,
    setReloadBillSells,
    token,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props; //showDeleteConfirm

  const viewBillSell = (billSell) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Ver Comprobante ${billSell.code ? billSell.code : "sin código"}`
    );
    setModalWidth(900);
    setModalContent(
      <ViewBillSellForm
        billSell={billSell}
        setIsVisibleModal={setIsVisibleModal}
        setReloadBillSells={setReloadBillSells}
      />
    );
  };

  const editState = (billSell, bpaid) => {
    setIsVisibleModal(true);
    setModalTitle(`Estado de Comprobante de venta ${billSell.code}`);
    setModalWidth(400);
    setModalContent(
      <EditStateForm
        billSell={billSell}
        bpaid={bpaid}
        setIsVisibleModal={setIsVisibleModal}
        setReloadBillSells={setReloadBillSells}
      />
    );
  };

  const editBillSell = (billSell) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar ${billSell.code ? billSell.code : "sin código"}`);
    setModalWidth(900);
    setModalContent(
      <EditBillSellForm
        billSell={billSell}
        setIsVisibleModal={setIsVisibleModal}
        setReloadBillSells={setReloadBillSells}
      />
    );
  };

  const addAnulationBillSell = async (billSell) => {
    setIsVisibleModal(true);
    setModalTitle("Comprobante de venta - Anulación");
    setModalWidth(900);
    await setModalContent(
      <AddBillSellForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadBillSells={setReloadBillSells}
        associatedBillSell={billSell}
      />
    );
  };

  const showDeleteConfirm = (billSell) => {
    //const accessToken = getAccessToken();

    confirm({
      title: "Eliminando -> Comprobante de Venta",
      content: `¿Estás seguro que quieres eliminar a ${billSell.code ? billSell.code: "sin codigo"}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteBillSellApi(accessToken, billSell._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadBillSells(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  return (
    <List
      className="billsell"
      itemLayout="horizontal"
      dataSource={billSellsPaid}
      renderItem={(billSell) => (
        <BillSellPaid
          billSell={billSell}
          viewBillSell={viewBillSell}
          editState={editState}
          editBillSell={editBillSell}
          addAnulationBillSell={addAnulationBillSell}
          showDeleteConfirm={showDeleteConfirm}
          setReloadBillSells={setReloadBillSells}
          token={token}
          accessToken={accessToken}
          userToken={userToken}
          permissionsActive={permissionsActive}
          roleById={roleById}
        />
      )} 
    />
  );
}

function BillSellPaid(props) {
  const {
    billSell,
    viewBillSell,
    editState,
    editBillSell,
    addAnulationBillSell,
    showDeleteConfirm,
    setReloadBillSells,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props; //showDeleteConfirm
  var clientName = "";

  if (billSell.client) {
    clientName = billSell.client.denomination;
  }

  const view_billsell = (
    <div>
      {" "}
      <p>Ver Comprobante</p>{" "}
    </div>
  );

  const changeState = (
    <div>
      {" "}
      <p>Editar Estado</p>{" "}
    </div>
  );

  const edit_billsell = (
    <div>
      {" "}
      <p>Editar Comprobante</p>{" "}
    </div>
  );
  const cancelBill = (
    <div>
      {" "}
      <p>Anular Comprobante</p>{" "}
    </div>
  );

  const download_billsell = (
    <div>
      {" "}
      <p>Descargar Comprobante</p>{" "}
    </div>
  );

  const delete_billsell = (
    <div>
      {" "}
      <p>Eliminar Comprobante</p>{" "}
    </div>
  );


  return (
    <List.Item
      actions={[
        <div>
          {checkAction(permissionsActive, userToken.role, "billsells", "all") ||
          checkAction(permissionsActive, userToken.role, "billsells", "view") || isAdmin(roleById) ? (
            <>
              <Popover content={view_billsell}>
                <Button type="primary" onClick={() => viewBillSell(billSell)}>
                  <EyeOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "billsells", "all") ||
          checkAction(permissionsActive, userToken.role, "billsells", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_billsell}>
                <Button type="default" onClick={() => editBillSell(billSell)}>
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "billsells", "all") ||
          checkAction(permissionsActive, userToken.role, "billsells", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={changeState}>
                <Button
                  className="bg-warning"
                  onClick={() => editState(billSell, false)}
                >
                  <FileExclamationOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
        // <div>
        //   {checkAction(permissionsActive, userToken.role, "billsells", "all") ||
        //   checkAction(permissionsActive, userToken.role, "billsells", "delete") || isAdmin(roleById) ? (
        //     <>
        //       <Popover content={cancelBill}>
        //         <Button type="danger" onClick={() => addAnulationBillSell(billSell)}>
        //           <CloseSquareOutlined />
        //         </Button>
        //       </Popover>
        //     </>
        //   ) : (
        //     <>
        //     </>
        //   )}
        // </div>,
        <div>
        {checkAction(permissionsActive, userToken.role, "billsells", "all") ||
        checkAction(permissionsActive, userToken.role, "billsells", "delete") || isAdmin(roleById) ? (
          <>
            <Popover content={delete_billsell}>
              <Button type="danger" onClick={() => showDeleteConfirm(billSell)}>
                <DeleteOutlined />
              </Button>
            </Popover>
          </>
        ) : (
          <>
          </>
        )}
      </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "billsells", "all") ||
          checkAction(permissionsActive, userToken.role, "billsells", "pdf") || isAdmin(roleById) ? (
            <>
              <Popover content={download_billsell}>
                <Button
                  type="primary"
                  onClick={() => {
                    billSellDetailPdf(billSell, false);
                  }}
                >
                  <DownloadOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
      ]}
    >
      <List.Item.Meta
        title={`
                  ${ billSell.code ? billSell.code : "sin código"
                  },
                  ${
                    formatDateView(billSell.date)
                      ? formatDateView(billSell.date)
                      : "sin fecha"
                  },
                  ${clientName ? clientName : "no hay cliente"}
                `} //${supplierPaid.name ? supplierPaid.name : "no hay proveedor"}
        description={` ${
          "$" + billSell.total ? "$" + billSell.total : "no hay total"
        } , ${billSell.state? billSell.state.name : "sin estado"} `}
      />
    </List.Item>
  );
}

function BillSellsUnpaid(props) {
  const {
    billSellsUnpaid,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setModalWidth,
    setReloadBillSells,
    token,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props; //showDeleteConfirm

  const viewBillSell = (billSell) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Ver Comprobante  ${billSell.code ? billSell.code : "sin código"}`
    );
    setModalContent(<ViewBillSellForm billSell={billSell} />);
    setModalWidth(900);
  };

  const editState = (billSell, bpaid) => {
    setIsVisibleModal(true);
    setModalTitle(`Estado de Comprobante de venta ${billSell.code}`);
    setModalWidth(400);
    setModalContent(
      <EditStateForm
        billSell={billSell}
        bpaid={bpaid}
        setIsVisibleModal={setIsVisibleModal}
        setReloadBillSells={setReloadBillSells}
      />
    );
  };

  const editBillSell = (billSell) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar ${billSell.code ? billSell.code : "sin código"}`);
    setModalWidth(900);
    setModalContent(
      <EditBillSellForm
        billSell={billSell}
        setIsVisibleModal={setIsVisibleModal}
        setReloadBillSells={setReloadBillSells}
      />
    );
  };

  const addAnulationBillSell = async (billSell) => {
    setIsVisibleModal(true);
    setModalTitle("Comprobante de venta - Anulación");
    setModalWidth(900);
    await setModalContent(
      <AddBillSellForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadBillSells={setReloadBillSells}
        associatedBillSell={billSell}
      />
    );
  };

  const showDeleteConfirm = (billSell) => {
    //const accessToken = getAccessToken();

    confirm({
      title: "Eliminando -> Factura de Venta",
      content: `¿Estás seguro que quieres eliminar a ${billSell.code ? billSell.code: "sin codigo"}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteBillSellApi(accessToken, billSell._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadBillSells(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  return (
    <List
      className="billsell"
      itemLayout="horizontal"
      dataSource={billSellsUnpaid}
      renderItem={(billSell) => (
        <BillSellUnpaid
          billSell={billSell}
          viewBillSell={viewBillSell}
          editState={editState}
          editBillSell={editBillSell}
          addAnulationBillSell={addAnulationBillSell}
          showDeleteConfirm={showDeleteConfirm}
          setReloadBillSells={setReloadBillSells}
          token={token}
          accessToken={accessToken}
          userToken={userToken}
          permissionsActive={permissionsActive}
          roleById={roleById}
        />
      )} //showDeleteConfirm={showDeleteConfirm}
    />
  );
}

function BillSellUnpaid(props) {
  const {
    billSell,
    editState,
    viewBillSell,
    editBillSell,
    addAnulationBillSell,
    showDeleteConfirm,
    setReloadBillSells,
    token,
    userToken,
    permissionsActive,
    roleById
  } = props; //showDeleteConfirm
  var clientName = "";

  if (billSell.client) {
    clientName = billSell.client.denomination;
  }

  const view_billsell = (
    <div>
      {" "}
      <p>Ver Comprobante</p>{" "}
    </div>
  );

  const changeState = (
    <div>
      {" "}
      <p>Editar estado</p>{" "}
    </div>
  );

  const edit_billsell = (
    <div>
      {" "}
      <p>Editar Comprobante</p>{" "}
    </div>
  );

  const download_billsell = (
    <div>
      {" "}
      <p>Descargar Comprobante</p>{" "}
    </div>
  );

  const cancel_billsell = (
    <div>
      {" "}
      <p>Anular Comprobante</p>{" "}
    </div>
  );

  const delete_billsell = (
    <div>
      {" "}
      <p>Borrar Comprobante</p>{" "}
    </div>
  );

  return (
    <List.Item
      actions={[
        <div>
          {checkAction(permissionsActive, userToken.role, "billsells", "all") ||
          checkAction(permissionsActive, userToken.role, "billsells", "view") || isAdmin(roleById) ? (
            <>
              <Popover content={view_billsell}>
                <Button type="primary" onClick={() => viewBillSell(billSell)}>
                  <EyeOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "billsells", "all") ||
          checkAction(permissionsActive, userToken.role, "billsells", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_billsell}>
                <Button type="default" onClick={() => editBillSell(billSell)}>
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "billsells", "all") ||
          checkAction(permissionsActive, userToken.role, "billsells", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={changeState}>
                <Button
                  className="bg-warning"
                  onClick={() => editState(billSell, true)}
                >
                  <FileExclamationOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
        // <div>
        //   {checkAction(permissionsActive, userToken.role, "billsells", "all") ||
        //   checkAction(permissionsActive, userToken.role, "billsells", "delete") || isAdmin(roleById) ? (
        //     <>
        //       <Popover content={cancel_billsell}>
        //         <Button type="danger" onClick={() => addAnulationBillSell(billSell)}>
        //           <CloseSquareOutlined />
        //         </Button>
        //       </Popover>
        //     </>
        //   ) : (
        //     <>
        //     </>
        //   )}
        // </div>,
        <div>
        {checkAction(permissionsActive, userToken.role, "billsells", "all") ||
        checkAction(permissionsActive, userToken.role, "billsells", "delete") || isAdmin(roleById) ? (
          <>
            <Popover content={delete_billsell}>
              <Button type="danger" onClick={() => showDeleteConfirm(billSell)}>
                <DeleteOutlined />
              </Button>
            </Popover>
          </>
        ) : (
          <>
          </>
        )}
      </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "billsells", "all") ||
          checkAction(permissionsActive, userToken.role, "billsells", "pdf") || isAdmin(roleById) ? (
            <>
              <Popover content={download_billsell}>
                <Button
                  type="primary"
                  onClick={() => {
                    billSellDetailPdf(billSell, false);
                  }}
                >
                  <DownloadOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
      ]}
    >
      <List.Item.Meta
        title={`
                  ${billSell.code ? billSell.code : "sin código"},
                  ${
                    formatDateView(billSell.date)
                      ? formatDateView(billSell.date)
                      : "sin fecha"
                  },
                  ${clientName ? clientName : "no hay cliente"}
                `}
        description={` ${
          "$" + billSell.total ? "$" + billSell.total : "no hay total"
        }, ${billSell.state? billSell.state.name : "sin estado"} `}
      />
    </List.Item>
  );
}
