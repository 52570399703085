import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  List,
  Col,
  notification,
  Switch,
  TimePicker,
  Tabs,
  Space,
  Card,
  //Tag,
  Checkbox,
} from "antd";

import {
  InfoCircleOutlined,
  //EnvironmentOutlined,
  ClockCircleOutlined,
  MinusCircleOutlined,
  CheckCircleTwoTone,
  ShoppingOutlined,
  CreditCardOutlined,
  TwitterOutlined,
  FacebookOutlined,
  InstagramOutlined
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
//import difference from 'lodash/difference';
import { 
  //DeleteOutlined, TagOutlined, 
  PlusOutlined
 } from "@ant-design/icons";
import moment from "moment";
import {
  addBusinessApi,
  updateBusinessApi,
} from "../../../../api/business"; //getCourseApi
import { getAccessToken } from "../../../../api/auth";
import { getDaysActiveApi } from "../../../../api/day";
import { COUNTRIES } from "../../../../utils/countries";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";

import "./AddEditBusinessFormPage.scss";
//import { formatDateHour, formatDateHour2 } from "../../../../utils/formatDate";
//import { numberValidation } from "../../../../utils/formValidation";
import TextArea from "antd/lib/input/TextArea";
import { addBranchMenuApi } from "../../../../api/branchMenu";
import { addBranchApi } from "../../../../api/branch";
const TabPane = Tabs.TabPane;
const { Option } = Select;

const labelStyle = "original";
const timezones = {
  ...allTimezones,
  "Europe/Berlin": "Frankfurt",
};

export default function AddEditBusinessFormPage(props) {
  const {  
    //setStoreId, 
    business, setReloadBusiness
   } = props;
  const [listDays, setListDays] = useState([]);

  const [businessData, setBusinessData] = useState({
    name: "",
    whatsapp: "",
    facebook: "",
    instagram: "",
    twitter: "",
    tiktok: "",
    phone: "",
  });
  
  
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [stateDaysData, setStateDaysData] = useState([]);
  const [hoursDaysData, setHoursDaysData] = useState([]);
  const [paymentmethodsData, setPaymentmethodsData] = useState([]);
  const [salesmethodsData, setSalesmethodsData] = useState([]);
  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones,
  });
  const [dayHoursData, setDayHoursData] = useState([]);
  const [form] = Form.useForm();

  const accessToken = getAccessToken();
  //para VOLVER HACIA ATRAS
  const history = useHistory();

  useEffect(() => {
    getDaysActiveApi(accessToken, true).then((response) => {
      setListDays(response.days);
    });
  }, []);

  useEffect(() => {
    if (business && business !== undefined) {
      setBusinessData(business);
      setPaymentmethodsData({...business.paymentmethods});
      setSalesmethodsData({...business.salesmethods});
    }
  }, [business]);


  const onChange = (key) => {
    //console.log(key);
  };

  const isFormValid = (e) => {
    //console.log(inputs);
    let errorExists = false;
    if (!businessData.name) {
      notification["error"]({
        message: "Requeridos: Nombre.",
      });
      errorExists = true;
    }

    return errorExists;
  };

  const onCheck = async () => {
    try {
      let errorExists = false;
      if (!businessData.name || !businessData.whatsapp) {
        notification["error"]({
          message: "No hay datos para guardar.",
        });
        errorExists = true;
      }
      return errorExists;
    } catch (errorInfo) {
      //console.log("Failed:", errorInfo);
      return false;
    }
  };

  const isSuccess = (e) => {
    if (!e || e === "") {
      return <CheckCircleTwoTone />;
    } else {
      return <CheckCircleTwoTone twoToneColor="#52c41a" />;
    }
  };

  const updateDaysData = async (values) => {
    const list = [];
    //Si existe la lista de dias en el businessData para actualizarla
    if (businessData.days && businessData.days.length > 0) {
      businessData.days.map((i) => {
        let array = { ...i };
        array.state = stateDaysData.hasOwnProperty(i.day)
          ? stateDaysData[i.day]
          : array.state;
        if (dayHoursData[i.day]) {
          //array.hours = values[i.day];
          array.hours =
            dayHoursData[i.day].length > 0
              ? dayHoursData[i.day].map((item) => {
                  let newHour = { hour_start: item[0], hour_end: item[1] };
                  return newHour;
                })
              : [];
        }
        list.push({ ...array });
        //para que devuelva algo
        return true;
      });
    } else {
      listDays.map((i) => {
        let array = { day: i._id };
        array.state = stateDaysData.hasOwnProperty(i._id)
          ? stateDaysData[i._id]
          : false;
        // if (values[i._id]) {
        if (dayHoursData[i._id]) {
          // array.hours = values[i._id];
          array.hours =
            dayHoursData[i._id].length > 0
              ? dayHoursData[i._id].map((item) => {
                  let newHour = item[0]
                    ? { hour_start: item[0], hour_end: item[1] }
                    : null;
                  return newHour;
                })
              : [];
        }
        list.push({ ...array });
        //para que devuelva algo
        return true;
      });
    }
    // console.log("horas", list);
    return list;
  };

  const addUpdateBusiness = async (values) => {
    if (!isFormValid()) {
      if (!disabledSubmit) {
        //setDisabledSubmit(true);
        const accessToken = getAccessToken();
        if (!business 
          // && Object.keys(business).length === 0
        ) {
          let result = await updateDaysData(values);
          businessData.days = result;
          businessData.paymentmethods=paymentmethodsData;
          businessData.salesmethods=salesmethodsData;
          await addBusinessApi(accessToken, businessData)
            .then((response) => {
              if (
                response === "ERR_CONNECTION_REFUSED" ||
                response.message === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
                //setDisabledSubmit(false);
              } else if (response.code !== 200) {
                notification["error"]({
                  message: response.message,
                });
                //setDisabledSubmit(false);
                //setReloadStore(true);
              } else {
                notification["success"]({
                  message: "Negocio creado",
                });
                //Redirigir pagina
                // history.push({
                //   pathname: `/admin/config/branch/${response.branch.name}`,
                //   state: { id: response.branch._id },
                // });
                return response.business? response.business : null;
                //window.location.reload(false);
              }
            }).then(async (business)=>{
              //Creo la sucursal principal
              if(business && business!==null){
                const branchData={
                  business:business._id,
                  isMain:true,
                  active:true
                }
                const result = await addBranchApi(accessToken,branchData);
                if (
                  result === "ERR_CONNECTION_REFUSED" ||
                  result.message === "Failed to fetch" ||
                  result === undefined
                ) {
                  return false;
                } else if (result.code !== 200) {
                  return false;
                } else {
                  return result.branch
                }
              }
            }).then(async (branch)=>{
            //Creo la sucursal principal
            if(branch && branch!==null){
              const dataMenu={
                branch:branch._id,
                active:true
              }
              const result = await addBranchMenuApi(accessToken,dataMenu);
              if (
                result === "ERR_CONNECTION_REFUSED" ||
                result.message === "Failed to fetch" ||
                result === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
                //setDisabledSubmit(false);
              } else if (result.code !== 200) {
                notification["error"]({
                  message: result.message,
                });
                //setDisabledSubmit(false);
                //setReloadBusiness(true);
              } else {
                notification["success"]({
                  message: "Menu creado",
                });
                //Redirigir pagina
                // history.push({
                //   pathname: `/admin/config/business/${business.name}`,
                //   state: { id: business._id },
                // });
                //window.location.reload(false);
                setReloadBusiness(true);
              }
            }
          })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        } else {
          //getDaysData();
          let result = await updateDaysData(values);
          businessData.days = result;
          businessData.paymentmethods=paymentmethodsData;
          businessData.salesmethods=salesmethodsData;
          await updateBusinessApi(accessToken, businessData, business._id)
            .then((response) => {
              console.log(response)
              if (
                response === "ERR_CONNECTION_REFUSED" ||
                response.message === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
                //setDisabledSubmit(false);
              } else if (response.code !== 200) {
                notification["error"]({
                  message: response.message,
                });
                //setDisabledSubmit(false);
                setReloadBusiness(true);
              } else {
                console.log("#Acacacacac")
                notification["success"]({
                  message: "Negocio actualizado",
                });

                // history.push({
                //   pathname: `/admin/config/business/${businessData.name}`,
                //   state: { id: business._id },
                // });
                //window.location.reload(false);
              }
              setReloadBusiness(true);
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        }
      }
    }
  };

  return (
    <div className="add-edit-business-form">
      <div className="add-edit-business-form__header">
        <Tabs defaultActiveKey="1" onChange={onChange} centered type="card">
          <TabPane
            tab={
              <span>
                <InfoCircleOutlined /> General
              </span>
            }
            key="1"
          >
            <General
              businessData={businessData}
              addUpdateBusiness={addUpdateBusiness}
              setBusinessData={setBusinessData}
              isSuccess={isSuccess}
              onCheck={onCheck}
              form={form}
            />
          </TabPane>
          <TabPane
            tab={
              <span>
                <ClockCircleOutlined /> Horarios
              </span>
            }
            key="2"
          >
            <Hours
              businessData={businessData}
              dayHoursData={dayHoursData}
              setDayHoursData={setDayHoursData}
              addUpdateBusiness={addUpdateBusiness}
              listDays={listDays}
              setBusinessData={setBusinessData}
              isSuccess={isSuccess}
              setStateDaysData={setStateDaysData}
              stateDaysData={stateDaysData}
              hoursDaysData={hoursDaysData}
              setHoursDaysData={setHoursDaysData}
              options={options}
              parseTimezone={parseTimezone}
            />
          </TabPane>
          <TabPane
            tab={
              <span>
                <ShoppingOutlined /> Métodos de venta
              </span>
            }
            key="3"
          >
            <SalesMethods
               businessData={businessData}
              dayHoursData={dayHoursData}
              setDayHoursData={setDayHoursData}
              addUpdateBusiness={addUpdateBusiness}
              salesmethodsData={salesmethodsData}
              setSalesmethodsData={setSalesmethodsData}
              listDays={listDays}
              setBusinessData={setBusinessData}
              isSuccess={isSuccess}
              setStateDaysData={setStateDaysData}
              stateDaysData={stateDaysData}
              hoursDaysData={hoursDaysData}
              setHoursDaysData={setHoursDaysData}
              options={options}
              parseTimezone={parseTimezone}
            />
          </TabPane>
          <TabPane
            tab={
              <span>
                <CreditCardOutlined />Métodos de pago
              </span>
            }
            key="4"
          >
            <PaymentMethods
              businessData={businessData}
              dayHoursData={dayHoursData}
              setDayHoursData={setDayHoursData}
              addUpdateBusiness={addUpdateBusiness}
              paymentmethodsData={paymentmethodsData}
              setPaymentmethodsData={setPaymentmethodsData}
              listDays={listDays}
              setBusinessData={setBusinessData}
              isSuccess={isSuccess}
              setStateDaysData={setStateDaysData}
              stateDaysData={stateDaysData}
              hoursDaysData={hoursDaysData}
              setHoursDaysData={setHoursDaysData}
              options={options}
              parseTimezone={parseTimezone}
            />
          </TabPane>
          <TabPane
            tab={
              <span>
                <ShoppingOutlined /> Redes Sociales
              </span>
            }
            key="5"
          >
            <SocialNetworks
              businessData={businessData}
              dayHoursData={dayHoursData}
              setDayHoursData={setDayHoursData}
              addUpdateBusiness={addUpdateBusiness}
              listDays={listDays}
              setBusinessData={setBusinessData}
              isSuccess={isSuccess}
              setStateDaysData={setStateDaysData}
              stateDaysData={stateDaysData}
              hoursDaysData={hoursDaysData}
              setHoursDaysData={setHoursDaysData}
              options={options}
              parseTimezone={parseTimezone}
              //socialMediaData={socialMediaData}
              //setSocialMediaData={setSocialMediaData}
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}

function General(props) {
  const { form, businessData, setBusinessData, addUpdateBusiness, isSuccess } = props;

  return (
    <Form
      className="form-addedit"
      form={form}
      //onFinish={addUpdateStore}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <Space>
                {isSuccess(businessData.name)}
                <label className="control-label">Nombre del negocio</label>
              </Space>
            </Col>
            <Col span={18}>
              <Form.Item>
                <Input
                  value={businessData.name}
                  onChange={(e) =>
                    setBusinessData({ ...businessData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <Space>
                {isSuccess(businessData.description)}
                <label className="control-label">Descripción</label>
              </Space>
            </Col>
            <Col span={18}>
              <Form.Item>
                <TextArea
                  value={businessData.description}
                  onChange={(e) =>
                    setBusinessData({ ...businessData, description: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <Space>
                {isSuccess(businessData.whatsapp)}
                <label className="control-label">WhatsApp para pedidos</label>
              </Space>
            </Col>
            <Col span={18}>
              <Form.Item>
                <Input
                  value={businessData.whatsapp}
                  onChange={(e) =>
                    setBusinessData({ ...businessData, whatsapp: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <Space>
                {isSuccess(businessData.phone)}
                <label className="control-label">Teléfono</label>
              </Space>
            </Col>
            <Col span={18}>
              <Form.Item
                rules={[
                  {
                    type: "number",
                    required: true,
                    message: "Wrong number",
                  },
                ]}
              >
                <Input
                  value={businessData.phone}
                  onChange={(e) =>
                    setBusinessData({ ...businessData, phone: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            {/* <Col span={6}>
              <label className="control-label">
                Moneda
              </label>
            </Col> */}
            {/* <Col span={18}>
              <Form.Item>
                <Select
                  showSearch
                  placeholder="Seleccione tipo de moneda"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {listStudentsCourse
                ? listStudentsCourse.map((i) => {
                  return <Option key={i._id}>{i.student.name + " " + i.student.lastname + " (" + (i.student.email? i.student.email : "")+ ") "}</Option>;
                  })
                : "No hay estudiantes disponibles"}
                </Select>
              </Form.Item>
            </Col> */}
          </Row>
        </Col>
        <Col span={24}>
        <Row>
        <Col span={6}>
          <Space>
            {isSuccess(businessData.address && businessData.country)}{" "}
            <label className="control-label">Dirección</label>
          </Space>
        </Col>
        <Col span={18}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <label className="control-label">País</label>
              <Form.Item>
                <Select
                  showSearch
                  // defaultValue={"Argentina"}
                  value={businessData.country}
                  onChange={(e) => setBusinessData({ ...businessData, country: e })}
                  placeholder="Seleccione el país"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {COUNTRIES
                    ? COUNTRIES.map((i) => {
                        return <Option key={i}>{i}</Option>;
                      })
                    : "No hay paises disponibles"}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <label className="control-label">Dirección</label>
              <Form.Item>
                <Input
                  value={businessData.address}
                  onChange={(e) =>
                    setBusinessData({ ...businessData, address: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
        </Col>
      </Row>
      <Form.Item
        wrapperCol={{
          span: 12,
          offset: 22,
        }}
      >
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-submit"
            onClick={addUpdateBusiness}
          >
            Guardar
          </Button>
        </Space>
      </Form.Item>
      {/* <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          loading={loading}
          onClick={updateAbsence}
        >
          Actualizar
        </Button>
      </Form.Item> */}
    </Form>
  );
}

// eslint-disable-next-line no-lone-blocks
{/* //onFinish={updateUser} debajo className="form-addedit"*/}

// eslint-disable-next-line no-lone-blocks

function Hours(props) {
  const {
    businessData,
    addUpdateBusiness,
    listDays,
    isSuccess,
    setBusinessData,
    //setDaysData,
    stateDaysData,
    setStateDaysData,
    parseTimezone,
    options,
    dayHoursData,
    setDayHoursData,
  } = props;

  return (
    <Form className="form-addedit" onFinish={addUpdateBusiness}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={4}>
          <Space>
            {isSuccess(businessData.timezone)}
            <label className="control-label">Horarios</label>
          </Space>
        </Col>
        <Col span={20}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <label className="control-label">Zona Horaria</label>
              <Form.Item>
                <Select
                  showSearch
                  value={businessData.timezone ? businessData.timezone.value : null}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) =>
                    setBusinessData({ ...businessData, timezone: parseTimezone(e) })
                  }
                >
                  {options.map((i, index) => (
                    <Option key={index} value={i.value}>
                      {i.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Space direction="vertical" size="large">
              {listDays.map((i, index) => {
                return (
                  <Days
                    key={index}
                    title={i.description}
                    businessData={businessData}
                    dayHoursData={dayHoursData}
                    setDayHoursData={setDayHoursData}
                    setBusinessData={setBusinessData}
                    dayId={i._id}
                    isSuccess={isSuccess}
                    setStateDaysData={setStateDaysData}
                    stateDaysData={stateDaysData}
                  />
                );
              })}
            </Space>
          </Row>
        </Col>
      </Row>
      <Form.Item
        wrapperCol={{
          span: 12,
          offset: 22,
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          //onClick={addUpdateStore}
        >
          Guardar
        </Button>
      </Form.Item>
    </Form>
  );
}

function Days(props) {
  const {
    businessData,
    title,
    dayId,
    setStateDaysData,
    stateDaysData,
    setDayHoursData,
    dayHoursData,
  } = props;

  const [checked, setChecked] = useState(false);
  const [visibleHours, setVisibleHours] = useState(false);
  const [hours, setHours] = useState([]);

  const onChange = (checked) => {
    setVisibleHours(checked);
    setChecked(checked);
    setStateDaysData({ ...stateDaysData, [dayId]: checked }); //CHEQUEAR COMO AGREGAR ESTE CAMPO AL ARRAY
  };

  useEffect(() => {
    if (businessData && businessData.days) {
      let index = businessData.days.findIndex((i) => i.day === dayId);
      if (index !== -1) {
        let data = businessData.days[index];
        setChecked(data.state ? data.state : false);
        setVisibleHours(data.state);
        if (data.hours) {
          let array = data.hours.map((i, index) => {
            // return {
            //   hour_start: moment(i.hour_start),
            //   hour_end: moment(i.hour_end)
            // }
            return {
              key: index,
              0: moment(i.hour_start),
              1: moment(i.hour_end),
            };
          });
          // console.log(array);
          setHours(array);
        }
      }
    }
  }, [businessData]);

  useEffect(() => {
    setDayHoursData({ ...dayHoursData, [dayId]: hours });
  }, [hours]);

  const handleDelete = (key) => {
    const newData = hours.filter((item) => item.key !== key);
    setHours(newData);
  };

  const handleAdd = () => {
    let newData = {
      key: hours.length,
    };
    setHours([...hours, newData]);
  };

  return (
    <Card
      style={{
        width: 1000,
      }}
      bordered={false}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={3}>
          <label className="control-label">{title}</label>
        </Col>
        <Col span={3}>
          <Switch
            checkedChildren="Abierto"
            unCheckedChildren="Cerrado"
            checked={checked}
            onChange={onChange}
          />
        </Col>
        {visibleHours ? (
          <>
            {hours && hours.length > 0 ? (
              <Col span={10}>
                <List
                  dataSource={hours}
                  renderItem={(item, index) => (
                    <SingleHour
                      key={item.key}
                      hour={item}
                      hours={hours}
                      setHours={setHours}
                      handleDelete={handleDelete}
                    />
                  )}
                />
              </Col>
            ) : null}
            <Col span={5}>
              <Form.Item>
                <Button
                  type="link"
                  onClick={() => handleAdd()}
                  block
                  icon={<PlusOutlined />}
                >
                  Agregar horas
                </Button>
              </Form.Item>
            </Col>
          </>
        ) : null}
      </Row>
    </Card>
  );
}

function SingleHour(props) {
  const { hour, hours, setHours, handleDelete } = props;

  const handleInputChange = (event) => {
    const newData = [...hours];
    //newData[hour.key] = { key: hour.key, 0: event[0], [1]: event[1] };
    newData[hour.key] = { key: hour.key, 0: event[0], 1: event[1] };
    setHours(newData);
  };

  return (
    <Space
      style={{
        display: "flex",
        marginBottom: 8,
      }}
      align="baseline"
    >
      <Form.Item>
        <TimePicker.RangePicker
          format="HH:mm"
          placeholder={["Hora desde", "Hora hasta"]}
          value={hour}
          onChange={(e) => handleInputChange(e)}
          minuteStep={10}
        />
      </Form.Item>
      <MinusCircleOutlined onClick={() => handleDelete(hour.key)} />
    </Space>
  );
}

function SalesMethods(props) {
  const { 
    form, addUpdateStore, setSalesmethodsData, salesmethodsData,
     } = props;

  const onChange = (checkedValues) => {
    // console.log("checked = ", checkedValues);
    if(checkedValues){
        let data={...salesmethodsData};
        data[checkedValues.target.value]=checkedValues.target.checked;
        setSalesmethodsData(data);
    }
  };

  return (
    <Form
      className="form-addedit"
      form={form}
      //onFinish={addUpdateStore}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          {/* <Checkbox.Group
            style={{ display: "inline-block", marginRight: 10 }}
            options={options}
            defaultValue={["Apple"]}
            onChange={onChange}
          /> */}
          {/* <Checkbox.Group
              style={{
                width: '100%',
              }}
              onChange={onChange}
            > */}
              <Row>
                <Space direction="vertical" size="large" >               
                <Col span={24}>
                  <Checkbox onChange={onChange} checked={salesmethodsData? salesmethodsData.salonorders? salesmethodsData.salonorders : false : false} value="salonorders">Pedidos de Salón</Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox onChange={onChange} checked={salesmethodsData? salesmethodsData.takeaway? salesmethodsData.takeaway : false : false} value="takeaway">Retiro de sucursal (take away)</Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox onChange={onChange} checked={salesmethodsData? salesmethodsData.delivery? salesmethodsData.delivery : false : false}  value="delivery">Envío a domicilio (delivery)</Checkbox>
                </Col>
                </Space>
              </Row>
            {/* </Checkbox.Group> */}
          <br />
        </Col>
      </Row>
      <Form.Item
        wrapperCol={{
          span: 12,
          offset: 22,
        }}
      >
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-submit"
            onClick={addUpdateStore}
          >
            Guardar
          </Button>
        </Space>
      </Form.Item>
      {/* <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          loading={loading}
          onClick={updateAbsence}
        >
          Actualizar
        </Button>
      </Form.Item> */}
    </Form>
  );
}

function PaymentMethods(props) {
  const { 
    form, addUpdateBusiness, setPaymentmethodsData, paymentmethodsData,
     } = props;
  
  const onChange = (checkedValues) => {
    // console.log("checked = ", checkedValues);
    if(checkedValues){
        let data={...paymentmethodsData};
        if(checkedValues.target.value ==="card"){
          data[checkedValues.target.value]=checkedValues.target.checked;
        }else{
          data[checkedValues.target.value]={active:checkedValues.target.checked}
        }
        setPaymentmethodsData(data);
    }
  };

  return (
    <Form
      className="form-addedit"
      form={form}
      //onFinish={addUpdateStore}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          {/* <Checkbox.Group
            style={{ display: "inline-block", marginRight: 10 }}
            options={options}
            defaultValue={["Apple"]}
            onChange={onChange}
          /> */}
          {/* <Checkbox.Group
              style={{
                width: '100%',
              }}
              onChange={onChange}
            > */}
              <Row>
                <Space direction="vertical" size="large" >               
                <Col span={24}>
                  <Checkbox onChange={onChange} checked={paymentmethodsData? paymentmethodsData.banktransfer? paymentmethodsData.banktransfer.active : false : false} value="banktransfer">Transferencia bancaria</Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox  onChange={onChange} checked={paymentmethodsData? paymentmethodsData.card? paymentmethodsData.card : false : false} value="card">Tarjeta crédito / débito manual</Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox onChange={onChange} checked={paymentmethodsData? paymentmethodsData.cash? paymentmethodsData.cash.active : false : false} value="cash">Pago en efectivo</Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox onChange={onChange} checked={paymentmethodsData? paymentmethodsData.mercadopago? paymentmethodsData.mercadopago.active : false : false} value="mercadopago">MercadoPago con Integración</Checkbox>
                </Col>
                </Space>
              </Row>
            {/* </Checkbox.Group> */}
          <br />
        </Col>
      </Row>
      <Form.Item
        wrapperCol={{
          span: 12,
          offset: 22,
        }}
      >
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-submit"
            onClick={addUpdateBusiness}
          >
            Guardar
          </Button>
        </Space>
      </Form.Item>
      {/* <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          loading={loading}
          onClick={updateAbsence}
        >
          Actualizar
        </Button>
      </Form.Item> */}
    </Form>
  );
}

function SocialNetworks(props) {
  const { 
    form, businessData, setBusinessData, 
    //socialMediaData, setSocialMediaData, 
    addUpdateBusiness, isSuccess
  } = props;

  return (
    <Form
      className="form-addedit"
      form={form}
      //onFinish={addUpdateStore}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <Space>
                {isSuccess(businessData.facebook)}
                <label className="control-label"> <FacebookOutlined /> Facebook</label>
              </Space>
            </Col>
            <Col span={18}>
              <Form.Item>
                <Input
                  value={businessData.facebook}
                  onChange={(e) =>
                    setBusinessData({ ...businessData, facebook: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <Space>
                {isSuccess(businessData.instagram)}
                <label className="control-label"> <InstagramOutlined /> Instagram</label>
              </Space>
            </Col>
            <Col span={18}> 
              <Form.Item>
                <Input
                  value={businessData.instagram}
                  onChange={(e) =>
                    setBusinessData({ ...businessData, instagram: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <Space>
                {isSuccess(businessData.twitter)}
                <label className="control-label"> <TwitterOutlined /> Twitter</label>
              </Space>
            </Col>
            <Col span={18}>
              <Form.Item
              >
                <Input
                  value={businessData.twitter}
                  onChange={(e) =>
                    setBusinessData({ ...businessData, twitter: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <Space>
                {isSuccess(businessData.tiktok)}
                <label className="control-label"> <TwitterOutlined /> TikTok</label>
              </Space>
            </Col>
            <Col span={18}>
              <Form.Item
              >
                <Input
                  value={businessData.tiktok}
                  onChange={(e) =>
                    setBusinessData({ ...businessData, tiktok: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Form.Item
        wrapperCol={{
          span: 12,
          offset: 22,
        }}
      >
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-submit"
            onClick={addUpdateBusiness}
          >
            Guardar
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}

