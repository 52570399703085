import React, { useState, useEffect } from "react";

import { Form, Input, Select, Button, Row, Col, notification } from "antd";

import { MODULES } from "../../../../utils/constants";
import {
  convertModulesEngToEsp,
  //convertActionsEngToEsp,
} from "../../../../utils/convert";

import "./AddMenuForm.scss";

import { addMenuApi, getMenusActiveApi } from "../../../../api/menu";

import { getAccessToken } from "../../../../api/auth";

export default function AddMenuForm(props) {
  const { setIsVisibleModal, setReloadMenu } = props;
  const [menuData, setMenuData] = useState({});
  const [menuItemsList, setMenuItemsList] = useState([]);

  useEffect(() => {
    //lista que almacena los menus
    const token = getAccessToken();
    getMenusActiveApi(token, true).then((response) => {
      setMenuItemsList(response.menu);
    });
  }, []);

  const addMenu = (e) => {
    if (!menuData.title || !menuData.url) {
      notification.error({ message: "obligatorios: Titulo y URL" });
    } else {
      const accessToken = getAccessToken();
      // finalData.active = false;
      menuData.order = 1000;
      addMenuApi(accessToken, menuData).then((response) => {
        // console.log(response);
        if (
          response === "ERR_CONNECTION_REFUSED" ||
          response === "Failed to fetch" ||
          response === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
        } else if (response.code !== 200) {
          notification["error"]({
            message: response.message,
          });
        } else {
          notification["success"]({
            message: "Menu creado",
          });
          setIsVisibleModal(false);
        }
        setReloadMenu(true);
      });
    }
  };
  return (
    <div className="add-menu-form">
      <AddForm
        menuData={menuData}
        setMenuData={setMenuData}
        addMenu={addMenu}
        menuItemsList={menuItemsList}
        MODULES={MODULES}
      />
    </div>
  );
}

function AddForm(props) {
  const { menuData, setMenuData, addMenu, menuItemsList, MODULES } = props;
  const { Option } = Select;

  const SelectBefore = (
    <Select
      defaultValue="http://"
      style={{ width: 90 }}
      onChange={(e) => setMenuData({ ...menuData, http: e })}
    >
      <Option value="http//">http://</Option>
      <Option value="https://">https://</Option>
    </Select>
  );

  return (
    <Form className="form-add" onFinish={addMenu}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
            Modulo
          </label>
          <Form.Item>
            <Select
              // style={{ width: 100}}
              showSearch
              onChange={(e) => setMenuData({ ...menuData, nameModule: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {MODULES
                ? MODULES.map((i) => {
                    return <Option key={i}>{convertModulesEngToEsp(i)}</Option>;
                  })
                : "No hay modulos disponibles"}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
            <span className="control-required">*</span> Titulo
          </label>
          <Form.Item>
            <Input
              placeholder="Titulo"
              value={menuData.title}
              onChange={(e) => {
                setMenuData({ ...menuData, title: e.target.value });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
            Superior
          </label>
          <Form.Item>
            <Select
              // style={{ width: 100 }}
              onChange={(e) => setMenuData({ ...menuData, higher: e })}
            >
              {menuItemsList
                ? menuItemsList.map((i) => {
                    return <Option key={i._id}>{i.title}</Option>;
                  })
                : "No hay menus disponibles"}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
            <span className="control-required">*</span> URL
          </label>
          <Form.Item>
            <Input
              addonBefore={SelectBefore}
              placeholder="URL"
              value={menuData.url}
              onChange={(e) => {
                setMenuData({ ...menuData, url: e.target.value });
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="btn-submit">
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
