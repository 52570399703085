import React, { createContext, useState, useContext, useEffect } from 'react';

// Creamos el contexto
export const BusinessContext = createContext();

// Proveedor del contexto
export default function BusinessProvider (props) {
  const { children } = props;
  const [business, setBusiness] = useState(() => {
    const storedBranch = localStorage.getItem('business');
    return storedBranch ? storedBranch : '';
  }); // Estado inicial del idioma, por defecto '' 
  
  const changeBusiness= (newData) => {
    setBusiness(newData);
    window.location.reload(); // Refrescar la página
  };

  useEffect(() => {
    localStorage.setItem('business', business);
  }, [business]);

  return (
    <BusinessContext.Provider value={{ business, changeBusiness }}>
      {children}
    </BusinessContext.Provider>
  );
};

// // Hook personalizado para consumir el contexto
// export const useBranch= () => {
//   const context = useContext(BranchContext);
//   if (!context) {
//     throw new Error('useSitio debe ser usado dentro de un SitioProvider');
//   }
//   return context;
// };
