import {downloadPdf, openPdf, getHeader, getLogo, getStyles} from "../../../../utils/pdfManager";
import { formatDateView } from "../../../../utils/formatDate";
import { convertMovementType, convertOrigin } from "../../../../utils/convert";

export function listMovementsPdf(data,isDownload){
    const _format = (data)=>{
        return data.map(movement=>{
            return ([
                {text: formatDateView(movement.date)},
                {text:convertMovementType(movement.movement_type)},
                {text:convertOrigin(movement.origin)},
                {text: movement.item? `${movement.item.item_code}-${movement.item.description}` : ""},
                {text:movement.buyBill? movement.buyBill.code : movement.billSell? movement.billSell.code : "" },
                {text:movement.quantity},
                {text:movement.observation},
                {text:movement.user? `${movement.user.name} ${movement.user.lastname}` : ""}
            ]);
        });
    }

    const formattedData=_format(data);

    const docDefinition={
        pageSize:'A4',
        pageOrientation: 'vertical',
        header:getHeader(),
        content: [
            getLogo(),
            [
                { text:'Listado de Movimientos', style:'title'},
            ],
        '\n',
        '\n',
            {
                table:{
                    headerRow:1,
                    dontBreakRows:true,
                    body:[
                        [
                            {text:'Fecha', style:'tableHeader'},
                            {text:'Tipo',style:"tableHeader"},
                            {text:'Origen',style:"tableHeader"},
                            {text:'Producto',style:"tableHeader"},
                            {text:'Factura',style:"tableHeader"},
                            {text:'Cantidad',style:"tableHeader"},
                            {text:'Observacion',style:"tableHeader"},
                            {text:'Responsable',style:"tableHeader"},
                        ],
                        ...formattedData,
                    ]
                }
            }
        ],
        styles:getStyles()
    }
    if(isDownload){
        downloadPdf(docDefinition,"ListadoMovimientos");
    }else{
        openPdf(docDefinition);
    }
}

export function movementDetailPdf(data,isDownload){

    const _format = (data)=>{
        return ([
            {text: `Fecha: ${formatDateView(data.date)}`, style:'list'},
            {text: `Tipo: ${convertMovementType(data.movement_type)}`, style:'list'},
            {text: `Origen: ${convertOrigin(data.origin)}`, style:'list'},
            data.item? {text:`Codigo: ${data.item.item_code}`, style:'list'} : null,
            data.item? {text: `Descripcion: ${data.item.description}`, style:'list'} : null,
            data.buyBill? {text: `Factura: ${data.buyBill.code}`, style:'list'} : null,
            data.billSell? {text:`Factura: ${data.billSell.code}`, style:'list'} : null,
            {text:`Cantidad: ${data.quantity}`, style:'list'},
            {text:`Observaciones: ${data.observation}`, style:'list'},
            {text:`Responsable: ${data.user.name}`, style:'list'}
        ]);
    };

    const formattedData=_format(data);

    const docDefinition={
        pageSize:'A4',
        pageOrientation: 'vertical',
        header:getHeader(),
        content: [
            getLogo(),
            [
                { text:'Detalle del Movimiento', style:'title'},
            ],
        '\n',
        '\n',
            {
                type:'none',
                ol:[
                    ...formattedData,
                ],
            }
            ],
            styles:getStyles()
        }
    if(isDownload){
        downloadPdf(docDefinition,"detalleMovimiento");
    }else{
        openPdf(docDefinition);
    }

}