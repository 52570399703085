import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditPaymentMethodForm from "../EditPaymentMethodForm";
import AddPaymentMethodForm from "../AddPaymentMethodForm";
import { activatePaymentMethodApi, deletePaymentMethodApi } from "../../../../api/billing/paymentMethod";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListPaymentMethods.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListPaymentMethods(props) {
  const {
    paymentMethodsActive,
    paymentMethodsInactive,
    setReloadPaymentMethods,
  } = props;
  const [viewPaymentMethodsActives, setViewPaymentMethodsActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [paymentMethodsActiveFilter, setPaymentMethodsActiveFilter] = useState(
    []
  );
  const [ paymentMethodsInactiveFilter, setPaymentMethodsInactiveFilter ] = useState([]);
  const [typeFilter, setMethodFilter] = useState("description");

  useEffect(() => {
    const listActive = [];
    if (paymentMethodsActive) {
      paymentMethodsActive.forEach((element) => {
        if (typeFilter === "description") {
          if (
            element.description
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        } else {
          if (
            element.paymentmethod_code
              .toString()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        }
      });
    }
    setPaymentMethodsActiveFilter(listActive);
  }, [filterText, paymentMethodsActive]);

  useEffect(() => {
    const listInactive = [];
    if (paymentMethodsInactive) {
      paymentMethodsInactive.forEach((element) => {
        if (typeFilter === "description") {
          if (
            element.description
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        } else {
          if (
            element.item_code
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        }
      });
    }
    setPaymentMethodsInactiveFilter(listInactive);
  }, [filterText, paymentMethodsInactive]);

  const addPaymentMethodModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Método de pago");
    setModalContent(
      <AddPaymentMethodForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadPaymentMethods={setReloadPaymentMethods}
      />
    );
  };

  return (
    <div className="list-paymentmethods">
      <div className="list-paymentmethods__header">
        <div className="list-paymentmethods__header-switch">
          <Switch
            defaultChecked
            onChange={() =>
              setViewPaymentMethodsActives(!viewPaymentMethodsActives)
            }
          />
          <span>
            {viewPaymentMethodsActives
              ? "Activos"
              : "Inactivos"}
          </span>
        </div>
        <Button type="primary" icon={<PlusOutlined/>} onClick={addPaymentMethodModal}>
          Nuevo
        </Button>
      </div>

      <div className="list-paymentmethods__search">
        <Search
          className="Buscar"
          placeholder=""
          onChange={(e) => setFilterText(e.target.value)}
          enterButton
        />
        <Radio.Group
          options={[
            { label: "Descripción", value: "description" },
            { label: "Código", value: "code" },
          ]}
          onChange={(e) => {
            setMethodFilter(e.target.value);
          }}
          value={typeFilter}
        />
        <h2>

        </h2>
        <h3>
          <strong>Info:</strong> ejemplos de métodos de pago 'efectivo, transferencia bancaria, débito, crédito, cheque'
        </h3>
      </div>

      {viewPaymentMethodsActives ? (
        <>
          <PaymentMethodsActive
            paymentMethodsActive={paymentMethodsActiveFilter}
            setIsVisibleModal={setIsVisibleModal}
            setModalTitle={setModalTitle}
            setModalContent={setModalContent}
            setReloadPaymentMethods={setReloadPaymentMethods}
          />
        </>
      ) : (
        <>
          <PaymentMethodsInactive
            paymentMethodsInactive={paymentMethodsInactiveFilter}
            setIsVisibleModal={setIsVisibleModal}
            setReloadPaymentMethods={setReloadPaymentMethods}
            setModalTitle={setModalTitle}
            setModalContent={setModalContent}
          />
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function PaymentMethodsActive(props) {
  const {
    paymentMethodsActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadPaymentMethods,
  } = props;

  const editPaymentMethod = (paymentMethod) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${paymentMethod.description ? paymentMethod.description : "sin descripción"}`
    );
    setModalContent(
      <EditPaymentMethodForm
        paymentMethod={paymentMethod}
        setIsVisibleModal={setIsVisibleModal}
        setReloadPaymentMethods={setReloadPaymentMethods}
      />
    );
  };

  const desactivatePaymentMethod = (paymentMethod) => {
    const accessToken = getAccessToken();

    activatePaymentMethodApi(accessToken, paymentMethod._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadPaymentMethods(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (paymentMethod) => {
    const accessToken = getAccessToken();
    confirm({
      title: "Eliminando Método de pago",
      content: `¿Estás seguro que quieres eliminar a ${paymentMethod.description}?`,
      okText: "Eliminar",
      okMethod: "danger",
      cancelText: "Cancelar",
      onOk() {
        deletePaymentMethodApi(accessToken, paymentMethod._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadPaymentMethods(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const edit_paymentMethod = (
    <div>
      {" "}
      <p>Editar Método de pago</p>{" "}
    </div>
  );

  const desactivate_paymentMethod = (
    <div>
      {" "}
      <p>Pasar a Métodos de pago inactivos</p>{" "}
    </div>
  );

  const delete_paymentMethod = (
    <div>
      {" "}
      <p>Eliminar Método de pago</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "paymentmethod_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, paymentMethod) => (
        <span>
          <Popover content={edit_paymentMethod}>
            <Button
              className="button-paymentmethod"
              type="default"
              onClick={() => editPaymentMethod(paymentMethod)}
            >
              <EditOutlined />
            </Button>
          </Popover>
          <Popover content={desactivate_paymentMethod}>
            <Button
              className="button-paymentmethod"
              type="primary"
              onClick={() => desactivatePaymentMethod(paymentMethod)}
            >
              <StopOutlined />
            </Button>
          </Popover>
          <Popover content={delete_paymentMethod}>
            <Button
              className="button-paymentmethod"
              type="danger"
              onClick={() => showDeleteConfirm(paymentMethod)}
            >
              <DeleteOutlined />
            </Button>
          </Popover>
        </span>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={paymentMethodsActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function PaymentMethodsInactive(props) {
  const { paymentMethodsInactive, setReloadPaymentMethods } = props;

  const activatePaymentMethod = (paymentMethod) => {
    const accessToken = getAccessToken();

    activatePaymentMethodApi(accessToken, paymentMethod._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadPaymentMethods(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (paymentMethod) => {
    const accessToken = getAccessToken();
    confirm({
      title: "Eliminando Método de pago",
      content: `¿Estás seguro que quieres eliminar a ${paymentMethod.description}?`,
      okText: "Eliminar",
      okMethod: "danger",
      cancelText: "Cancelar",
      onOk() {
        deletePaymentMethodApi(accessToken, paymentMethod._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadPaymentMethods(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const activate_paymentMethod = (
    <div>
      {" "}
      <p>Pasar a Métodos de pago activos</p>{" "}
    </div>
  );

  const delete_paymentMethod = (
    <div>
      {" "}
      <p>Eliminar Método de pago</p>
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "paymentmethod_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, paymentMethod) => (
        <span>
          <Popover content={activate_paymentMethod}>
            <Button
              className="button-paymentmethod"
              type="primary"
              onClick={() => activatePaymentMethod(paymentMethod)}
            >
              <CheckOutlined />
            </Button>
          </Popover>
          <Popover content={delete_paymentMethod}>
            <Button
              className="button-paymentmethod"
              type="danger"
              onClick={() => showDeleteConfirm(paymentMethod)}
            >
              <DeleteOutlined />
            </Button>
          </Popover>
        </span>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={paymentMethodsInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
