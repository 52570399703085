import {downloadPdf, openPdf, getStyles, getHeader, getLogo} from "../../../../utils/pdfManager";

export function listItemsPdf(data,mainTitle,isDownload){
    const _format = (data)=>{
        return data.map(item=>{
            return ([
              {text: item.item_code},
              {text: item.description},
              {text: item.category.description},
              {text: item.registration},
              {text: item.cost_price},
              {text: item.price1},
              {text: item.price2},
            ]);
        });
    }

    const formattedData=_format(data);

    const docDefinition={
        pageSize:'A4',
        pageOrientation: 'vertical',
        header:getHeader(),
        content:[
            //getLogo(),
        [
            { text:`${mainTitle}`, style:'title'},
        ],
      '\n',
      '\n',
            {
                table:{
                    headerRow:1,
                    widths:['auto','*','*','auto','auto','auto','auto'],
                    body:[
                      [
                        {text: 'Código', style:'tableHeader'},
                        {text: 'Descripción', style:'tableHeader'},
                        {text: 'Rubro', style:'tableHeader'},
                        {text: 'Matrícula', style:'tableHeader'},
                        {text: 'Precio costo', style:'tableHeader'},
                        {text: 'Precio 1', style:'tableHeader'},
                        {text: 'Precio 2', style:'tableHeader'},
                      ],
                        ...formattedData,
                    ],
                    style:"tableHeader"
                }
            }
        ],
        styles:getStyles()
    }

    if(isDownload){
        downloadPdf(docDefinition,`Listado${mainTitle}`);
    }else{
        openPdf(docDefinition);
    }

}

export function itemDetailPdf(data,mainTitle,isDownload){

    const _format = (data)=>{
        return ([
            {text:`Codigo: ${data.item_code? data.item_code : ""}`,style:'list'},
            {text:`Descripcion: ${data.description? data.description : ""}`,style:'list'},
            {text:`Rubro: ${data.category? (data.category.description) : ""}`,style:'list'},
            {text:`Matricula: ${data.registration? data.registration : ""}`,style:'list'},
            {text:`Precio Costo: ${data.cost_price? data.cost_price : ""}`,style:'list'},
            {text:`Precio 1: ${data.price1? data.price1 : ""}`,style:'list'},
            {text:`Precio 2: ${data.price2? data.price2 : ""}`,style:'list'},
            {text:`Observaciones: ${data.observation? data.observation : ""}`,style:'list'},
          
        ]);
    };

    const formattedData=_format(data);

    const docDefinition={
        pageSize:'A4',
        pageOrientation: 'vertical',
        header:getHeader(),
        content:[
            //getLogo(),
            '\n',
            [
                { text:`${mainTitle}`, style:'title'},
            ],
            '\n',
            '\n',
            {
                type:'none',
                ol:[
                    ...formattedData,
                ],
             } 
            ],
            styles:getStyles()
        }     

    let title=`${data.description}`;
    if(isDownload){
        downloadPdf(docDefinition,title);
    }else{
        openPdf(docDefinition);
    }

}