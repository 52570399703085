
import { switchPath, apiVersion } from './config';


/**FUNCIONES PARA MANEJO DEL MODULO CONDICION FINAL QUE CONSUMEN LOS ENDPOINTS DEL SERVER*/

export function getItemExportApi(token) {
    const url = `${switchPath()}/${apiVersion}/item-export-csv`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "text/csv",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.text();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'item.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(err => {
            return err.message;
        });
}

export function postItemImportApi(token, file) {
    const url = `${switchPath()}/${apiVersion}/item-import-csv`;
    const formData = new FormData();
    formData.append("file", file, file.name);

    const params = {
        method: "POST",
        body: formData,
        headers: {
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch(err => {
            return err.message;
        });
}

export function addItemApi(token, data){
    const url = `${switchPath()}/${apiVersion}/add-item`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
    };

    return fetch(url, params)
        .then(response => {
            //console.log(response);
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return {
                ok: false,
                message: err.message
            };
        });
}

export function getItemsApi(token) {
    const url = `${switchPath()}/${apiVersion}/items`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getItemsActiveApi(token, status) {
    const url = `${switchPath()}/${apiVersion}/items-active?active=${status}`;
    //console.log(url);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getItemsByCategoryApi(token, categoryId) {
    const url = `${switchPath()}/${apiVersion}/items-category/${categoryId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getItemsByVariationApi(token, variationId) {
    const url = `${switchPath()}/${apiVersion}/items-variation/${variationId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getItemsGeneralByCategoryApi(token, categoryId,status) {
    const url = `${switchPath()}/${apiVersion}/items-general-category/${categoryId}?status=${status}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}


export function getItemsByBranchAndCategoryApi(token, branchId,categoryId) {
    const url = `${switchPath()}/${apiVersion}/items-branch-category/${branchId}/${categoryId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}


export function updateItemApi(token, itemData, itemId) {
    const url = `${switchPath()}/${apiVersion}/update-item/${itemId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(itemData)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}


export function activateItemApi(token, itemId, status) {
    const url = `${switchPath()}/${apiVersion}/activate-item/${itemId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            active: status
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}


export function deleteItemApi(token, itemId) {
    const url = `${switchPath()}/${apiVersion}/delete-item/${itemId}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });
}

export function uploadItemAvatarApi(token, data) {
    const url = `${switchPath()}/${apiVersion}/upload-avatar-item/`;

    const params = {
        method: "POST",
        body: data,
        headers: {
            //'Content-Type': 'multipart/form-data',
            Authorization: token
        }
    }
    return fetch(url, params)
        .then(response => {
            return response;
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getItemAvatarApi(avatarName) {
    const url = `${switchPath()}/${apiVersion}/get-avatar-item/${avatarName}`;
    return fetch(url)
    .then(response => {
        return response.url;
    })
    .catch(err => {
        return err.message;
    });
}

export function getVariationCategoriesByBusinessOrBranchApi(token,businessId,branchId) {
    const url = `${switchPath()}/${apiVersion}/items-variation-categories/${businessId}/${branchId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        })
    }