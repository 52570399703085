import React from 'react'; //useState
import { 
    Route, 
    //Switch, 
    Redirect, 
    useLocation} from 'react-router-dom';
//import { Layout } from 'antd';
import useAuth from '../hooks/useAuth';
import AdminSignIn from '../pages/Admin/SignIn';
//import AdminUsers from '../pages/Admin/Users';
import AdminReset from '../pages/Admin/Reset';
//import moment from 'moment';
//import { getUserByResetTokenApi } from "../api/user";

import "./LayoutBasic.scss";
//import ResetForm from '../components/Admin/ResetForm';

export default function LayoutBasic(props){
    //const { routes } = props;
    //const { Header, Content, Footer } = Layout;
    //const [ menuCollapsed, setMenuCollapsed ] = useState(false); //true: colapsado
    const { isLoading } = useAuth(); //userAuth, user
    //console.log(props);
    //console.log(routes);

    //PRIMERO PASA POR LAYOUT BASIC Y DESPUES POR LAYOUT ADMIN
    //console.log(useLocation().pathname);

    //console.log(useLocation().pathname.split("/").filter((ítem)=> ítem));
    const urlparams = useLocation().pathname.split("/").filter((ítem)=> ítem)
    //console.log(algo.length);
    //if (algo.length >= 2){
    //    console.log("useLocation length");
    //}
    /*
    if (useLocation().pathname === "/reset/*") {
        console.log("useLocation");
    }
    */


    if (urlparams.length >= 2) { //si la url es de reset password
        return (
            <>
                <Route path={useLocation().pathname} component={AdminReset} />
                <Redirect to={useLocation().pathname} />
            </>
        );
    }
    else if(!useAuth().user && !isLoading) { //si el usuario no está logueado
        return (
            <>
                <Route path='/admin/login' component={AdminSignIn} />
                <Redirect to="/admin/login" />
            </>
        );
    } else { //si se crea este modulo deberia redirigir a '/' admin en este caso
        return (
            <>
                {/* <Route path='/admin/users' component={AdminUsers} /> */}
                <Redirect to="/admin" />
                {/*
                <Route path='/reset/'{...user.resetPasswordToken} component={ResetForm} />
                <Redirect to='/reset/'{...user.resetPasswordToken} />
                */}
            </>
        );
    }

    //HASTA QUE SE IMPLEMENTE EL HOME
    /*
    return (
        <Layout>
            <Layout>
                <Content>
                    <LoadRoutes routes={routes} />
                </Content>
                <Footer className="layout-basic__footer">
                    &copy; { moment().format('MMMM Do YYYY, h:mm:ss a')  }
                </Footer>
            </Layout>
        </Layout>
    );
    */
}

/*
function LoadRoutes({ routes }) {
    //console.log(routes);
    return (
        <Switch>
            { routes.map((route, index) => (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                />
            ))}
        </Switch>
    );
}
*/