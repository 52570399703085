import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Select,
  Row,
  Col,
  notification,
} from "antd";
import { TagOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { updateSalePointApi } from "../../../../api/billing/salePoint";
//import { getSellersActiveApi } from "../../../../api/billing/seller";
import { getUsersActiveApi } from "../../../../api/user";
import { getBillSystemsActiveApi } from "../../../../api/billing/billSystem";
import { getAccessToken } from "../../../../api/auth";
//import { numberValidation } from "../../../../utils/formValidation";

import "./EditSalePointForm.scss";
//import { activateBillSystem } from "../../../../../../server/controllers/billSystem";

export default function EditSalePointForm(props) {
  const { salepoint, setIsVisibleModal, setReloadSalePoints } = props;
  const [ salePointData, setSalePointData ] = useState({});
  //const [activeSellers, setActiveSellers] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [activeBillSystems, setActiveBillSystems] = useState([]);
  const accessToken = getAccessToken();

  /*
  const [modifDataToValid, setModifDataToValid] = useState({
    phone: false
  });
  const [validData, setValidData] = useState({
    email: false,
  });*/

  /*
  useEffect(() => {
    getSellersActiveApi(accessToken, true).then(response => {
      setActiveSellers(response.sellers);
    });
  }, []);
  */

  useEffect(() => {
    getUsersActiveApi(accessToken, true).then((response) => {
      setActiveUsers(response.users);
    });
  }, []);

  useEffect(() => {
    getBillSystemsActiveApi(accessToken, true).then(response => {
      setActiveBillSystems(response.billSystems);
    });
  }, []);


  //trae los datos de punto de venta
  useEffect(() => {
    setSalePointData({
      salepoint_code: salepoint.salepoint_code,
      //seller: salepoint.seller,
      user: salepoint.user,
      fantasy_name: salepoint.fantasy_name,
      address: salepoint.address,
      number: salepoint.number,
      postal_code: salepoint.postal_code,
      locality: salepoint.locality,
      province: salepoint.province,
      system_bill: salepoint.system_bill
    });
  }, [salepoint]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    //if (!salePointData.salepoint_code || !salePointData.fantasy_name) {
    //  notification["error"]({
    //    message: "Obligatorios: Todos.",
    //  });
    //  errorExists = true;
    //}
    /*
    else if (modifDataToValid.phone) {
      if (!validData.phone) {
        //solo si el email está vacio, falta la validación en el return de EditForm
        notification["error"]({
          message: "Ingreso de teléfono inválido"
        });
        errorExists = true;
      }
    }*/
    //else {
    //  errorExists = false;
    //}
    return errorExists;
  };

  const updateSalePoint = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let salePointUpdate = salePointData; //es let porque se va actualizando

      updateSalePointApi(token, salePointUpdate, salepoint._id).then((result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //console.log("respuesta: " + result.message);
          setIsVisibleModal(false);
          setReloadSalePoints(true);
        }
      });
    }
  };

  return (
    <div className="edit-salepoint-form">
      <EditForm
        salePointData={salePointData}
        setSalePointData={setSalePointData}
        updateSalePoint={updateSalePoint}
        //activeSellers={activeSellers}
        activeUsers={activeUsers}
        activeBillSystems={activeBillSystems}
        //modifDataToValid={modifDataToValid}
        //setModifDataToValid={setModifDataToValid}
        //validData={validData}
        //setValidData={setValidData}
      />
    </div>
  );
}

function EditForm(props) {
  const { salePointData, setSalePointData, updateSalePoint, activeUsers, activeBillSystems } = props; //setModifDataToValid, modifDataToValid, validData, setValidData
  const { Option } = Select;

    //validaciones de inputs
    const inputValidation = (e) => {
      const { name } = e.target; //type

      setSalePointData({
        ...salePointData,
        [name]: e.target.value,
      });
      /*
      setModifDataToValid({
        ...modifDataToValid,
        [name]: true,
      });
      if (name === "phone"){
        setValidData({
            ...validData,
            [name]: numberValidation(e.target)
        });
      }*/
  };

  return (
    <Form className="form-edit" onSubmit={updateSalePoint}>
      <Row gutter={24}>
        <Col span={12}>
          <label className="control-label">Código</label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Código"
              value={salePointData.salepoint_code}
              onChange={(e) =>
                setSalePointData({ ...salePointData, salepoint_code: e })
              }
              min={1}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label"><span className="control-required">* </span>Nombre de fantasía</label>
          <Form.Item>
            <Input
              placeholder="Nombre de fantasía"
              value={salePointData.fantasy_name}
              onChange={(e) =>
                setSalePointData({ ...salePointData, fantasy_name: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <label className="control-label">Domicilio</label>
          <Form.Item>
            <Input
              prefix={<EnvironmentOutlined />}
              placeholder="Calle"
              name="address"
              value={salePointData.address}
              onChange={(e) =>
                setSalePointData({ ...salePointData, address: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">Número del domicilio</label>
          <Form.Item>
            <Input
              prefix={<EnvironmentOutlined />}
              placeholder="Altura"
              name="number"
              value={salePointData.number}
              //onChange={(e) => setInputs({ ...inputs, phone: e.target.value })}
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <label className="control-label">Código Postal</label>
          <Form.Item>
            <Input
              prefix={<EnvironmentOutlined />}
              placeholder="Código postal"
              name="postal_code"
              value={salePointData.postal_code}
              //onChange={(e) => setInputs({ ...inputs, phone: e.target.value })}
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">Localidad</label>
          <Form.Item>
            <Input
              prefix={<EnvironmentOutlined />}
              placeholder="Localidad"
              name="locality"
              value={salePointData.locality}
              onChange={(e) =>
                setSalePointData({ ...salePointData, locality: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <label className="control-label">Provincia</label>
          <Form.Item>
            <Input
              prefix={<EnvironmentOutlined />}
              placeholder="Provincia"
              name="province"
              value={salePointData.province}
              //onChange={(e) => setInputs({ ...inputs, phone: e.target.value })}
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <label className="control-label">Vendedor (usuario)</label>
          <Form.Item>
            <Select
              placeholder="Vendedor"
              value={salePointData.user? salePointData.user.name : ""}
              onChange={(e) =>
                setSalePointData({ ...salePointData, user: e })
              }
            >
            {activeUsers
              ? activeUsers.map(i => {
                  return <Option key={i._id}>{i.name}</Option>;
                })
              : "No hay vendedores disponibles"}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">Sistema de facturación</label>
          <Form.Item>
            <Select
              placeholder="Sistema de facturación"
              value={salePointData.system_bill? salePointData.system_bill.description : ""}
              onChange={(e) =>
                setSalePointData({ ...salePointData, system_bill: e })
              }
            >
            {activeBillSystems
              ? activeBillSystems.map(i => {
                  return <Option key={i._id}>{i.description}</Option>;
                })
              : "No hay sistemas de facturación disponibles"}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateSalePoint}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
