import React, { useState, useEffect } from "react";
import { Form, Input, InputNumber, Button, Row, Col, notification, Switch } from "antd";
import {
  TagOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { updateVariationApi } from "../../../../api/variation";
import { getAccessToken } from "../../../../api/auth";

import "./EditVariationForm.scss";

export default function EditVariationForm(props) {
  const { variation, setIsVisibleModal, setReloadVariations } = props;
  const [variationData, setVariationData] = useState({});

  useEffect(() => {
    setVariationData({
      variation_code: variation.variation_code,
      name: variation.name,
      price: variation.price,
      available: variation.available
    });
  }, [variation]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!variationData.name) {
      notification["error"]({
        message: "Obligatorio: Nombre.",
      });
      errorExists = true;
    } 
    if (!variationData.price) {
      notification["error"]({
        message: "Obligatorio: Precio.",
      });
      errorExists = true;
    } 
    else {
      errorExists = false;
    }
    return errorExists;
  };

  const updateVariation = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let variationUpdate = variationData; //es let porque se va actualizando

      updateVariationApi(token, variationUpdate, variation._id).then(
        (result) => {
          if (
            result.message === "ERR_CONNECTION_REFUSED" ||
            result.message === "Failed to fetch" ||
            result.message === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
            setIsVisibleModal(true);
          } else if (result.code !== 200) {
            notification["error"]({
              message: result.message,
            });
            setIsVisibleModal(true);
          } else {
            notification["success"]({
              message: result.message, //el mensaje que viene del server
            });
            //console.log("respuesta: " + result.message);
            setIsVisibleModal(false);
            setReloadVariations(true);
          }
        }
      );
    }
  };

  return (
    <div className="edit-variation-form">
      <EditForm
        variationData={variationData}
        setVariationData={setVariationData}
        updateVariation={updateVariation}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    variationData,
    setVariationData,
    updateVariation
  } = props;

  return (
    <Form className="form-edit" onSubmit={updateVariation}>
         <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Nombre
          </label>
          <Form.Item>
            <Input
              value={variationData.name}
              onChange={(e) => setVariationData({ ...variationData, name: e.target.value })}
            />
          </Form.Item>
        </Col>

      <Col span={12}>
          <label className="control-label"> <span className="control-required">*</span>Precio</label>
          <Form.Item>
            <Input
              type="number"
              addonBefore="$"
              style={{ width: "100%" }}
              value={variationData.price}
              step="0.01"
              onChange={(e) => setVariationData({ ...variationData, price: e.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
             Minima Cantidad
          </label>
          <Form.Item>
            <Input
              value={variationData.minQuantity}
              type="number"
              style={{ width: "100%" }}
              step="0.01"
              onChange={(e) => setVariationData({ ...variationData, minQuantity: e })}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">Maxima Cantidad</label>
          <Form.Item>
            <Input
              type="number"
              style={{ width: "100%" }}
              value={variationData.maxQuantity}
              step="0.01"
              onChange={(e) => setVariationData({ ...variationData, maxQuantity: e })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateVariation}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
