import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  BackTop
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditPermissionForm from "../EditPermissionForm";
import AddPermissionForm from "../AddPermissionForm";
import {
  activatePermissionApi, 
  deletePermissionApi, 
  //getPermissionsActiveApi, activateModulePermissionApi 
} from "../../../../api/permission";
import { convertModulesEngToEsp, convertActionsEngToEsp } from "../../../../utils/convert";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import jwtDecode from "jwt-decode";
//import { getRoleByIdApi } from "../api/role";

import "./ListPermissions.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListPermissions(props) {
  const {
    permissionsActive,
    permissionsInactive,
    setReloadPermissions,
  } = props;
  //const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewPermissionsActives, setViewPermissionsActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [permissionsActiveFilter, setPermissionsActiveFilter] = useState([]);
  const [ permissionsInactiveFilter, setPermissionsInactiveFilter ] = useState([]);
  //const [roleById, setRoleById] = useState([]);
  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  /*
  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);
  */

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    const listActive = [];
    if (permissionsActive) {
      permissionsActive.forEach((element) => {
          if (
            convertModulesEngToEsp(element.module)
              .toUpperCase()
              .includes(filterText.toUpperCase()) ||
            (element.role && element.role.name
              .toUpperCase()
              .includes(filterText.toUpperCase())) ||
            (element.permission_code && element.permission_code.toString()
                .toUpperCase()
                .includes(filterText.toUpperCase())) ||
            (convertActionsEngToEsp(element.action)
              .toUpperCase()
              .includes(filterText.toUpperCase())) ||
            filterText === ""
          ) {
            listActive.push(element);
          }
      });
    }
    setPermissionsActiveFilter(listActive);
  }, [filterText, permissionsActive]);

  useEffect(() => {
    const listInactive = [];
    if (permissionsInactive) {
      permissionsInactive.forEach((element) => {
          if (
            convertModulesEngToEsp(element.module)
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
            (element.role && element.role.name
              .toUpperCase()
              .includes(filterText.toUpperCase())) ||
            (element.permission_code && element.permission_code.toString()
              .toUpperCase()
              .includes(filterText.toUpperCase())) ||
            (convertActionsEngToEsp(element.action)
                .toUpperCase()
                .includes(filterText.toUpperCase())) ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
      });
    }
    setPermissionsInactiveFilter(listInactive);
  }, [filterText, permissionsInactive]);

  const addPermissionModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Permiso");
    setModalContent(
      <AddPermissionForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadPermissions={setReloadPermissions}
      />
    );
  };

  return (
    <div className="list-permissions">
      <div className="list-permissions__header">
        {checkAction(permissionsActive, userToken.role, "permissions", "all") ||
        checkAction(permissionsActive, userToken.role, "permissions", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-permissions__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewPermissionsActives(!viewPermissionsActives)
                }
              />
              <span>
                {viewPermissionsActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
        ) : (
          <>
          </>
        )}

        {checkAction(permissionsActive, userToken.role, "permissions", "all") ||
        checkAction(permissionsActive, userToken.role, "permissions", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addPermissionModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      <div className="list-permissions__search">
        {checkAction(permissionsActive, userToken.role, "permissions", "all") ||
        checkAction(permissionsActive, userToken.role, "permissions", "view") || isAdmin(roleById) ? (
          <>
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> crea permisos a roles para que accedan a los distintos módulos
            </h3>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {viewPermissionsActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "permissions", "all") ||
          checkAction(permissionsActive, userToken.role, "permissions", "view") || isAdmin(roleById) ? (
            <>
              <PermissionsActive
                permissionsActive={permissionsActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadPermissions={setReloadPermissions}
                accessToken={accessToken}
                userToken={userToken}
                //permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "permissions", "all") ||
          checkAction(permissionsActive, userToken.role, "permissions", "view") || isAdmin(roleById) ? (
            <>
              <PermissionsInactive
                permissionsInactive={permissionsInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadPermissions={setReloadPermissions}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                //permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>

      <BackTop/>
    </div>
  );
}

function PermissionsActive(props) {
  const {
    permissionsActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadPermissions,
    accessToken,
    userToken,
    roleById,
  } = props;

  const editPermission = (permission) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${permission.permission_code ? permission.permission_code : "sin nombre"}`
    );
    setModalContent(
      <EditPermissionForm
        permission={permission}
        setIsVisibleModal={setIsVisibleModal}
        setReloadPermissions={setReloadPermissions}
      />
    );
  };

  const desactivatePermission = (permission) => {
    activatePermissionApi(accessToken, permission._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadPermissions(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (permission) => {
    confirm({
      title: "Eliminando Permiso",
      content: `¿Estás seguro que quieres eliminarlo?`,
      okText: "Eliminar",
      okMethod: "danger",
      cancelText: "Cancelar",
      onOk() {
        deletePermissionApi(accessToken, permission._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadPermissions(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const edit_permission = (
    <div>
      {" "}
      <p>Editar Permiso</p>{" "}
    </div>
  );

  const desactivate_permission = (
    <div>
      {" "}
      <p>Desactivar Permiso</p>{" "}
    </div>
  );

  const delete_permission = (
    <div>
      {" "}
      <p>Eliminar Permiso</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "permission_code",
    },
    {
      title: "Rol",
      dataIndex: "role",
      render: (role) => role.name,
    },
    {
      title: "Módulo",
      dataIndex: "module",
      render: (module) => convertModulesEngToEsp(module)
    },
    {
      title: "Acción",
      dataIndex: "action",
      render: (action) => convertActionsEngToEsp(action)
    },
    {
      title: "Acciones",
      key: "action",
      render: (text, permission) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "permissions", "all") ||
          checkAction(permissionsActive, userToken.role, "permissions", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_permission}>
                <Button
                  className="button-edit"
                  onClick={() => editPermission(permission)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
            ) : (
              <>
              </>
          )}

          {checkAction(permissionsActive, userToken.role, "permissions", "all") ||
          checkAction(permissionsActive, userToken.role, "permissions", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_permission}>
                <Button
                  className="button-permission"
                  type="primary"
                  onClick={() => desactivatePermission(permission)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
            ) : (
              <>
              </>
          )}

          {checkAction(permissionsActive, userToken.role, "permissions", "all") ||
          checkAction(permissionsActive, userToken.role, "permissions", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_permission}>
                <Button
                  className="button-permission"
                  type="danger"
                  onClick={() => showDeleteConfirm(permission)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
            ) : (
              <>
              </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return permissionsActive.length > 0? `Total de registros: ${permissionsActive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={permissionsActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function PermissionsInactive(props) {
  const { permissionsInactive,
          setReloadPermissions,
          accessToken,
          userToken,
          permissionsActive,
          roleById
        } = props;

  const activatePermission = (permission) => {
    activatePermissionApi(accessToken, permission._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadPermissions(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (permission) => {
    confirm({
      title: "Eliminando Permiso",
      content: `¿Estás seguro que quieres eliminar a ${permission.permission_code}?`,
      okText: "Eliminar",
      okMethod: "danger",
      cancelText: "Cancelar",
      onOk() {
        deletePermissionApi(accessToken, permission._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadPermissions(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const activate_permission = (
    <div>
      {" "}
      <p>Activar Permiso</p>{" "}
    </div>
  );

  const delete_permission = (
    <div>
      {" "}
      <p>Eliminar Permiso</p>
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "permission_code",
    },
    {
      title: "Rol",
      dataIndex: "role",
      render: (role) => role.name,
    },
    {
      title: "Módulo",
      dataIndex: "module",
      render: (module) => convertModulesEngToEsp(module),
    },
    {
      title: "Acción",
      dataIndex: "action",
      render: (action) => convertActionsEngToEsp(action)
    },
    {
      title: "Acciones",
      key: "action",

      render: (text, permission) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "permissions", "all") ||
          checkAction(permissionsActive, userToken.role, "permissions", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_permission}>
                <Button
                  className="button-activate"
                  onClick={() => activatePermission(permission)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
            ) : (
              <>
              </>
          )}

          {checkAction(permissionsActive, userToken.role, "permissions", "all") ||
          checkAction(permissionsActive, userToken.role, "permissions", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_permission}>
                <Button
                  className="button-permission"
                  type="danger"
                  onClick={() => showDeleteConfirm(permission)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
            ) : (
              <>
              </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return permissionsInactive.length > 0? `Total de registros: ${permissionsInactive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={permissionsInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
