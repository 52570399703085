import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getDurationsActiveApi } from "../../../api/duration";
import ListDurations from "../../../components/Admin/Durations/ListDurations";


export default function Durations() {
    const [durationsActive, setDurationsActive] = useState([]);
    const [durationsInactive, setDurationsInactive] = useState([]);
    const [reloadDurations, setReloadDurations ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getDurationsActiveApi(token, true).then(response => {
            setDurationsActive(response.durations);
        });
        getDurationsActiveApi(token, false).then(response => {
            setDurationsInactive(response.durations);
        });
        setReloadDurations(false);
    }, [token, reloadDurations]);

    return (
        <div className="durations">
             <h3>Listado de Duración</h3>
            <ListDurations durationsActive={durationsActive} durationsInactive={durationsInactive} setReloadDurations={setReloadDurations}
                />
        </div>
    );
}