import { useContext } from 'react';
import { BranchContext } from '../providers/BranchProvider';

// Hook personalizado para consumir el contexto
export const useBranch= () => {
    const context = useContext(BranchContext);
    if (!context) {
      throw new Error('useBranch debe ser usado dentro de un StoreProvider');
    }
    return context;
  };