import React, { useState, useEffect } from "react";
import { Form, Descriptions, Upload, Button, notification} from "antd"; //Table
import { UploadOutlined , InfoCircleOutlined } from "@ant-design/icons";
// import { getUserExportApi } from "../../../../api/user";
import { postUserImportApi } from "../../../../api/user";
import { postDayImportApi } from "../../../../api/day";
import { postDurationImportApi } from "../../../../api/duration";
/*
import { postStudentImportApi } from "../../../../api/student";
import { postSchoolYearImportApi } from "../../../../api/schoolYear";
import { postAdviserImportApi } from "../../../../api/adviser";
import { postFinalConditionImportApi } from "../../../../api/finalCondition";
import { postEntryConditionImportApi } from "../../../../api/entryCondition";
import { postWeekImportApi } from "../../../../api/week";
import { postDepartmentImportApi } from "../../../../api/department";
import { postPlaceImportApi } from "../../../../api/place";
import { postWorkloadImportApi } from "../../../../api/workload";
import { postStateCourseImportApi } from "../../../../api/stateCourse";
import { postStateSchoolYearImportApi } from "../../../../api/stateSchoolYear";
import { postSpecialtyImportApi } from "../../../../api/specialty";
import { postTeacherImportApi } from "../../../../api/teacher";
import { postDocTypeImportApi } from "../../../../api/docType";
import { postCourseImportApi } from "../../../../api/course";
import { postInscriptionImportApi } from "../../../../api/inscription";
import { postTeacherCollabImportApi } from "../../../../api/teacherCollab";
import { postStateStudentCourseImportApi } from "../../../../api/stateStudentCourse";
import { postTypeCourseImportApi } from "../../../../api/typeCourse";
import { postAbsenceImportApi } from "../../../../api/absence";
*/

import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role"; //postRoleImportApi
import { getPermissionsActiveApi } from "../../../../api/permission"; //postPermissionImportApi
import { postMenuImportApi } from "../../../../api/menu";
import jwtDecode from "jwt-decode";

//import JSZip from "jszip";
import "./ListImports.scss";

export default function ListImports(props) {
  const { token } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  return (
    <div className="list-export">
      <ViewForm
        token={token}
        //accessToken={accessToken}
        userToken={userToken}
        permissionsActive={permissionsActive}
        roleById={roleById}
      />
    </div>
  );
}
/*
<Items
billSellData={billSellData}
/>
*/

function ViewForm(props) {
  const { token,
          //accessToken,
          userToken,
          permissionsActive,
          roleById
        } = props;

  const validateFile = (data, filter)=>{
      if (data.indexOf(filter)) {
        notification["error"]({
          message: "El nombre del archivo debe contener el nombre del módulo a importar"
        });
        return false;
      }
      return true;
  }

  /*
  const propsStudent = {
    name: "files",
    accept: ".csv",
    showUploadList: false,
    customRequest: (files) => {
      const result = validateFile(files.file.name, "student");
      if(result){
        postStudentImportApi(token, files.file)
        .then((response) => {
          notification["success"]({
            message: response.message
          });
        })
        .catch((err) => {
          notification["error"]({
            message: "Servidor caido",
          });
        });

      }
    },
  };
  */

  const propsUsers = {
    name: "files",
    accept: ".csv",
    showUploadList: false,
    customRequest: (files) => {
      const result = validateFile(files.file.name, "user");
      if(result){
        postUserImportApi(token, files.file)
        .then((response) => {
          notification["success"]({
            message: response.message
          });
        })
        .catch((err) => {
          notification["error"]({
            message: "Servidor caido",
          });
        });
      }
    },
  };

  /*
  const propsSchoolYears = {
    name: "files",
    accept: ".csv",
    showUploadList: false,
    customRequest: (files) => {
      const result = validateFile(files.file.name, "schoolyear");
      if(result){
        postSchoolYearImportApi(token, files.file)
        .then((response) => {
          notification["success"]({
            message: response.message
          });
        })
        .catch((err) => {
          notification["error"]({
            message: "Servidor caido",
          });
        });
      }
    },
  };
  

  const propsCourses = {
    name: "files",
    accept: ".csv",
    showUploadList: false,
    customRequest: (files) => {
      const result = validateFile(files.file.name, "course");
      if(result){
        postCourseImportApi(token, files.file)
        .then((response) => {
          notification["success"]({
            message: response.message
          });
        })
        .catch((err) => {
          notification["error"]({
            message: "Servidor caido",
          });
        });
      }
    },
  };

  const propsAdviser = {
    name: "files",
    accept: ".csv",
    showUploadList: false,
    customRequest: (files) => {
      const result = validateFile(files.file.name, "adviser");
      if(result){
        postAdviserImportApi(token, files.file)
        .then((response) => {
          notification["success"]({
            message: response.message
          });
        })
        .catch((err) => {
          notification["error"]({
            message: "Servidor caido",
          });
        });
      }
    },
  };

  const propsFinalConditions = {
    name: "files",
    accept: ".csv",
    showUploadList: false,
    customRequest: (files) => {
      const result = validateFile(files.file.name, "finalcondition");
      if(result){
        postFinalConditionImportApi(token, files.file)
        .then((response) => {
          notification["success"]({
            message: response.message
          });
        })
        .catch((err) => {
          notification["error"]({
            message: "Servidor caido",
          });
        });
      }
    },
  };

  const propsEntryConditions = {
    name: "files",
    accept: ".csv",
    showUploadList: false,
    customRequest: (files) => {
      const result = validateFile(files.file.name, "entrycondition");
      if(result){
        postEntryConditionImportApi(token, files.file)
        .then((response) => {
          notification["success"]({
            message: response.message
          });
        })
        .catch((err) => {
          notification["error"]({
            message: "Servidor caido",
          });
        });
      }
    },
  };
  */

  const propDays = {
    name: "files",
    accept: ".csv",
    showUploadList: false,
    customRequest: (files) => {
      const result = validateFile(files.file.name, "day");
      if(result){
        postDayImportApi(token, files.file)
        .then((response) => {
          notification["success"]({
            message: response.message
          });
        })
        .catch((err) => {
          notification["error"]({
            message: "Servidor caido",
          });
        });
      }
    },
  };
  /*
  const propsWeeks = {
    name: "files",
    accept: ".csv",
    showUploadList: false,
    customRequest: (files) => {
      const result = validateFile(files.file.name, "week");
      if(result){
        postWeekImportApi(token, files.file)
        .then((response) => {
          notification["success"]({
            message: response.message
          });
        })
        .catch((err) => {
          notification["error"]({
            message: "Servidor caido",
          });
        });
      }
    },
  };

  const propsDepartments = {
    name: "files",
    accept: ".csv",
    showUploadList: false,
    customRequest: (files) => {
      const result = validateFile(files.file.name, "department");
      if(result){
        postDepartmentImportApi(token, files.file)
        .then((response) => {
          notification["success"]({
            message: response.message
          });
        })
        .catch((err) => {
          notification["error"]({
            message: "Servidor caido",
          });
        });
      }
    },
  };
  

  const propsPlaces = {
    name: "files",
    accept: ".csv",
    showUploadList: false,
    customRequest: (files) => {
      const result = validateFile(files.file.name, "place");
      if(result){
        postPlaceImportApi(token, files.file)
        .then((response) => {
          notification["success"]({
            message: response.message
          });
        })
        .catch((err) => {
          notification["error"]({
            message: "Servidor caido",
          });
        });
      }
    },
  };

  const propsWorkloads = {
    name: "files",
    accept: ".csv",
    showUploadList: false,
    customRequest: (files) => {
      const result = validateFile(files.file.name, "workload");
      if(result){
        postWorkloadImportApi(token, files.file)
        .then((response) => {
          notification["success"]({
            message: response.message
          });
        })
        .catch((err) => {
          notification["error"]({
            message: "Servidor caido",
          });
        });
      }
    },
  };
  */

  const propsDurations = {
    name: "files",
    accept: ".csv",
    showUploadList: false,
    customRequest: (files) => {
      const result = validateFile(files.file.name, "duration");
      if(result){
        postDurationImportApi(token, files.file)
        .then((response) => {
          notification["success"]({
            message: response.message
          });
        })
        .catch((err) => {
          notification["error"]({
            message: "Servidor caido",
          });
        });
      }
    },
  };

  /*
  const propsStateCourses = {
    name: "files",
    accept: ".csv",
    showUploadList: false,
    customRequest: (files) => {
      const result = validateFile(files.file.name, "statecourse");
      if(result){
        postStateCourseImportApi(token, files.file)
        .then((response) => {
          notification["success"]({
            message: response.message
          });
        })
        .catch((err) => {
          notification["error"]({
            message: "Servidor caido",
          });
        });
      }
    },
  };

  const propsStateSchoolYears = {
    name: "files",
    accept: ".csv",
    showUploadList: false,
    customRequest: (files) => {
      const result = validateFile(files.file.name, "stateschoolyear");
      if(result){
        postStateSchoolYearImportApi(token, files.file)
        .then((response) => {
          notification["success"]({
            message: response.message
          });
        })
        .catch((err) => {
          notification["error"]({
            message: "Servidor caido",
          });
        });
      }
    },
  };

  const propsSpecialties = {
    name: "files",
    accept: ".csv",
    showUploadList: false,
    customRequest: (files) => {
      const result = validateFile(files.file.name, "specialty");
      if(result){
        postSpecialtyImportApi(token, files.file)
        .then((response) => {
          notification["success"]({
            message: response.message
          });
        })
        .catch((err) => {
          notification["error"]({
            message: "Servidor caido",
          });
        });
      }
    },
  };

  const propsTeachers = {
    name: "files",
    accept: ".csv",
    showUploadList: false,
    customRequest: (files) => {
      const result = validateFile(files.file.name, "teacher");
      if(result){
        postTeacherImportApi(token, files.file)
        .then((response) => {
          notification["success"]({
            message: response.message
          });
        })
        .catch((err) => {
          notification["error"]({
            message: "Servidor caido",
          });
        });
      }
    },
  };

  const propsDocTypes = {
    name: "files",
    accept: ".csv",
    showUploadList: false,
    customRequest: (files) => {
      const result = validateFile(files.file.name, "doctype");
      if(result){
        postDocTypeImportApi(token, files.file)
        .then((response) => {
          notification["success"]({
            message: response.message
          });
        })
        .catch((err) => {
          notification["error"]({
            message: "Servidor caido",
          });
        });
      }
    },
  };
  */

  const propsMenu = {
    name: "files",
    accept: ".csv",
    showUploadList: false,
    customRequest: (files) => {
      const result = validateFile(files.file.name, "menu");
      if(result){
        postMenuImportApi(token, files.file)
        .then((response) => {
          notification["success"]({
            message: response.message
          });
        })
        .catch((err) => {
          notification["error"]({
            message: "Servidor caido",
          });
        });
      }
    },
  };


  return (
    <div>
      {checkAction(permissionsActive, userToken.role, "imports", "menu-sider") || isAdmin(roleById) ? (
          <>
            <Form className="form-view">
              <h1 className="title">Importar registros</h1>
            <h4>
              <InfoCircleOutlined /> Importante: el nombre del archivo debe hacer referencia al modulo a importar
              </h4>

              <Descriptions column={2} layout="vertical" size="small" bordered>
                <label className="title">Usuarios</label>
                <Descriptions.Item className="normal" span={2}>
                  <Upload {...propsUsers}>
                    <Button type="primary">
                      CSV
                      <UploadOutlined />
                    </Button>
                  </Upload>
                </Descriptions.Item>
              {/**
                <label className="title">Estudiantes</label>
                <Descriptions.Item className="normal" span={2}>
                  <Upload {...propsStudent}>
                    <Button type="primary">
                      CSV
                      <UploadOutlined />
                    </Button>
                  </Upload>
                </Descriptions.Item>
                <label className="title">Docentes</label>
                <Descriptions.Item className="normal" span={2}>
                  <Upload {...propsTeachers}>
                    <Button type="primary">
                      CSV
                      <UploadOutlined />
                    </Button>
                  </Upload>
                </Descriptions.Item>
                <label className="title">Años lectivos</label>
                <Descriptions.Item className="normal" span={2}>
                  <Upload {...propsSchoolYears}>
                    <Button type="primary">
                      CSV
                      <UploadOutlined />
                    </Button>
                  </Upload>
                </Descriptions.Item>
                <label className="title">Estados Año lectivo</label>
                <Descriptions.Item className="normal" span={2}>
                  <Upload {...propsStateSchoolYears}>
                    <Button type="primary">
                      CSV
                      <UploadOutlined />
                    </Button>
                  </Upload>
                </Descriptions.Item>
                <label className="title">Especialidades</label>
                <Descriptions.Item className="normal" span={2}>
                  <Upload {...propsSpecialties}>
                    <Button type="primary">
                      CSV
                      <UploadOutlined />
                    </Button>
                  </Upload>
                </Descriptions.Item>
                <label className="title">Condicion Final</label>
                <Descriptions.Item className="normal" span={2}>
                  <Upload {...propsFinalConditions}>
                    <Button type="primary">
                      CSV
                      <UploadOutlined />
                    </Button>
                  </Upload>
                </Descriptions.Item>
                <label className="title">Condicion de Ingreso</label>
                <Descriptions.Item className="normal" span={2}>
                  <Upload {...propsEntryConditions}>
                    <Button type="primary">
                      CSV
                      <UploadOutlined />
                    </Button>
                  </Upload>
                </Descriptions.Item>
                <label className="title">Tipos de documento</label>
                <Descriptions.Item className="normal" span={2}>
                  <Upload {...propsDocTypes}>
                    <Button type="primary">
                      CSV
                      <UploadOutlined />
                    </Button>
                  </Upload>
                </Descriptions.Item>
                <label className="title">Consejeros</label>
                <Descriptions.Item className="normal" span={2}>
                  <Upload {...propsAdviser}>
                    <Button type="primary">
                      CSV
                      <UploadOutlined />
                    </Button>
                  </Upload>
                </Descriptions.Item>

                <label className="title">Cursos</label>
                <Descriptions.Item className="normal" span={2}>
                  <Upload {...propsCourses}>
                    <Button type="primary">
                      CSV
                      <UploadOutlined />
                    </Button>
                  </Upload>
                </Descriptions.Item>

                <label className="title">Estados Curso</label>
                <Descriptions.Item className="normal" span={2}>
                  <Upload {...propsStateCourses}>
                    <Button type="primary">
                      CSV
                      <UploadOutlined />
                    </Button>
                  </Upload>
                </Descriptions.Item>
              **/}
                <label className="title">Días</label>
                <Descriptions.Item className="normal" span={2}>
                  <Upload {...propDays}>
                    <Button type="primary">
                      CSV
                      <UploadOutlined />
                    </Button>
                  </Upload>
                </Descriptions.Item>
              {/**
                <label className="title">Semanas</label>
                <Descriptions.Item className="normal" span={2}>
                  <Upload {...propsWeeks}>
                    <Button type="primary">
                      CSV
                      <UploadOutlined />
                    </Button>
                  </Upload>
                </Descriptions.Item>
                <label className="title">Departamentos</label>
                <Descriptions.Item className="normal" span={2}>
                  <Upload {...propsDepartments}>
                    <Button type="primary">
                      CSV
                      <UploadOutlined />
                    </Button>
                  </Upload>
                </Descriptions.Item>
                <label className="title">Lugares</label>
                <Descriptions.Item className="normal" span={2}>
                  <Upload {...propsPlaces}>
                    <Button type="primary">
                      CSV
                      <UploadOutlined />
                    </Button>
                  </Upload>
                </Descriptions.Item>
                <label className="title">Carga Horaria</label>
                <Descriptions.Item className="normal" span={2}>
                  <Upload {...propsWorkloads}>
                    <Button type="primary">
                      CSV
                      <UploadOutlined />
                    </Button>
                  </Upload>
                </Descriptions.Item>
                **/}
                <label className="title">Duración</label>
                <Descriptions.Item className="normal" span={2}>
                  <Upload {...propsDurations}>
                    <Button type="primary">
                      CSV
                      <UploadOutlined />
                    </Button>
                  </Upload>
                </Descriptions.Item>
                
                <label className="title">Menu</label>
                <Descriptions.Item className="normal" span={2}>
                  <Upload {...propsMenu}>
                    <Button type="primary">
                      CSV
                      <UploadOutlined />
                    </Button>
                  </Upload>
                </Descriptions.Item>
              </Descriptions>
            </Form>
          </>
        ) : (
          <>
          </>
        )}
      </div>
  );
}
