import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getStateBuyOrdersActiveApi } from "../../../api/billing/stateBuyOrder";
import ListStateBuyOrders from "../../../components/Billing/StateBuyOrders/ListStateBuyOrders";


export default function StateBuyOrders() {
    const [stateBuyOrdersActive, setStateBuyOrdersActive] = useState([]);
    const [stateBuyOrdersInactive, setStateBuyOrdersInactive] = useState([]);
    const [reloadStateBuyOrders, setReloadStateBuyOrders ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getStateBuyOrdersActiveApi(token, true).then(response => {
            setStateBuyOrdersActive(response.stateBuyOrders);
        });
        getStateBuyOrdersActiveApi(token, false).then(response => {
            setStateBuyOrdersInactive(response.stateBuyOrders);
        });
        setReloadStateBuyOrders(false);
    }, [token, reloadStateBuyOrders]);

    return (
        <div className="stateBuyOrders">
            <ListStateBuyOrders stateBuyOrdersActive={stateBuyOrdersActive} stateBuyOrdersInactive={stateBuyOrdersInactive} setReloadStateBuyOrders={setReloadStateBuyOrders}
                />
        </div>
    );
}