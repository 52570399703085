import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import { TagOutlined } from "@ant-design/icons";
import { updatePermissionApi } from "../../../../api/permission";
import { getRolesActiveApi } from "../../../../api/role";
import { getAccessToken } from "../../../../api/auth";
import { MODULES, ACTIONS } from '../../../../utils/constants';
import { convertModulesEngToEsp, convertActionsEngToEsp  } from "../../../../utils/convert"; //convertModulesEspToEng, convertActionsEspToEng

import "./EditPermissionForm.scss";

export default function EditPermissionForm(props) {
  const { permission, setIsVisibleModal, setReloadPermissions } = props;
  const [ permissionData, setPermissionData ] = useState({});
  const [activeRoles, setActiveRoles] = useState([]);
  const accessToken = getAccessToken();

  //trae los datos de permiso
  useEffect(() => {
    setPermissionData({
      permission_code: permission.permission_code,
      role: permission.role._id,
      module: permission.module,
      action: permission.action,
      description: permission.description,
    });
  }, [permission]);

  useEffect(() => {
    getRolesActiveApi(accessToken, true).then((response) => {
      setActiveRoles(response.roles);
    });
  }, []);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!permission.permission_code || !permission.role || !permission.module || !permission.action) {
      notification["error"]({
        message: "Obligatorios: Código, rol, módulo y acción.",
      });
      errorExists = true;
    }
    else {
      errorExists = false;
    }
    return errorExists;
  };

  const updatePermission = async (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let permissionUpdate = permissionData; //es let porque se va actualizando
      //console.log("permissionUpdate: ", permissionUpdate);
      //console.log("permissionData: ", permissionData);

      await updatePermissionApi(token, permissionUpdate, permission._id).then((result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //console.log("respuesta: " + result.message);
          setIsVisibleModal(false);
          setReloadPermissions(true);
        }
      });
    }
  };

  return (
    <div className="edit-permission-form">
      <EditForm
        permissionData={permissionData}
        setPermissionData={setPermissionData}
        updatePermission={updatePermission}
        activeRoles={activeRoles}
        MODULES={MODULES}
        ACTIONS={ACTIONS}
        //modifDataToValid={modifDataToValid}
        //setModifDataToValid={setModifDataToValid}
        //validData={validData}
        //setValidData={setValidData}
      />
    </div>
  );
}

function EditForm(props) {
  const { permissionData, setPermissionData, updatePermission, activeRoles, MODULES, ACTIONS } = props; //setModifDataToValid, modifDataToValid, validData, setValidData
  const { Option } = Select;

  /*
  //validaciones de inputs
  const inputValidation = (e) => {
    const { name } = e.target; //type

    setPermissionData({
      ...permissionData,
      [name]: e.target.value,
    });
    setModifDataToValid({
      ...modifDataToValid,
      [name]: true,
    });
  };*/

  return (
    <Form className="form-edit" onSubmit={updatePermission}>
      <Row gutter={24}>
        <Col span={12}>
          <label className="control-label">Código</label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Código"
              value={permissionData.permission_code}
              onChange={(e) =>
                setPermissionData({ ...permissionData, permission_code: e })
              }
              min={1}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label"><span className="control-required">*</span> Rol</label>
          <Form.Item >
            <Select
                className="select-form"
                placeholder="Selecciona un rol"
                onChange={(e) => setPermissionData({ ...permissionData, role: e })}
                value={permissionData.role}
              >
              {activeRoles
                ? activeRoles.map((i) => {
                  if ((i.name !== 'administrador') &&
                    (i.name !== 'Administrador') &&
                    (i.name !== 'ADMINISTRADOR') &&
                    (i.name !== 'admin') &&
                    (i.name !== 'Admin') &&
                    (i.name !== 'ADMIN')
                    ) {
                    return <Option key={i._id}>{i.name}</Option>;
                  } else {
                    return null; //lo agrego porque sino aparece como warning
                  }
                  //return true;
                })
                : "No hay roles disponibles"}
              </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
            <label className="control-label"><span className="control-required">*</span> Módulo</label>
            <Form.Item >
              <Select
                className="select-form"
                placeholder="Módulo"
                value={permissionData.module}
                onChange={(e) =>
                  setPermissionData({ ...permissionData, module: e })
                }
              >
              {MODULES
                ? MODULES.map((i) => {
                    return <Option key={i}>{convertModulesEngToEsp(i)}</Option>;
                  })
                : "No hay módulos disponibles"}
              </Select>
            </Form.Item>
        </Col>
        <Col span={12}>
            <label className="control-label"><span className="control-required">*</span> Acción</label>
            <Form.Item >
              <Select
                className="select-form"
                placeholder="Selecciona la acción"
                value={permissionData.action}
                onChange={(e) => setPermissionData({ ...permissionData, action: e })}
                //style={{textAlign: "left"}}
              >
              {/* TODO: FALTA FILTRAR PARA ROLE-USER*/}
              {
                permissionData.module === "exports" || permissionData.module === "imports" ||
                permissionData.module === "persons-menu" || permissionData.module === "config-menu" ||
                permissionData.module === "courses-menu" || permissionData.module === "menu-edition"
                  ? <Option key="menu-sider">{convertActionsEngToEsp("menu-sider")}</Option>
                :
                permissionData.module === "inscriptions"
                  ? <Option key="all">{convertActionsEngToEsp("all")} </Option>
                :
                  ACTIONS
                    ? ACTIONS.map((i) => {
                      return <Option key={i}>{convertActionsEngToEsp(i)}</Option>;
                    })
                    : "No hay acciones disponibles"
              }
              </Select>
            </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <label className="control-label">Descripción</label>
          <Form.Item>
            <Input
              //prefix={<EnvironmentOutlined />}
              placeholder="Descripción"
              name="description"
              value={permissionData.description}
              onChange={(e) =>
                setPermissionData({ ...permissionData, description: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updatePermission}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
