import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getUsersActiveApi } from "../../../api/user";
import ListUsers from "../../../components/Admin/Users/ListUsers";
import jwtDecode from "jwt-decode";
// import {Helmet} from "react-helmet";

import "./Users.scss";

export default function Users() {
    const [usersActive, setUsersActive] = useState([]);
    const [usersInactive, setUsersInactive] = useState([]);
    const [ reloadUsers, setReloadUsers ] = useState(false);
    const token = getAccessToken();
    const userToken = jwtDecode(token);
    //let usersOn = [];
    //let usersOff = [];

    /*
    function removeItemFromArr (arr, item) {
        var i = arr.indexOf(item);
        if ( i !== -1 ) {
            arr.splice( i, 1 );
        }
    }*/

    useEffect(() => {
        getUsersActiveApi(token, true).then(response => {
            //removeItemFromArr(response.users, userToken);
            var usersOn = response.users;
            //console.log("usersOn1", usersOn);
            //var usersNew = [];
            //var fuckingItem;
            for (var r in usersOn) {
                //let index = usersOn.indexOf(r);
                if ((usersOn)[r].email === userToken.email) {
                    //console.log((usersOn)[r].email);
                    //console.log(userToken.email);
                    (usersOn).splice(r, 1);
                    //console.log((usersOn).splice(r, 1));
                    //(usersNew).push(index, r);
                }
            }
            setUsersActive(usersOn);
            //console.log("usersOn2" ,usersOn);
        });
        getUsersActiveApi(token, false).then(response => {
            var usersOff = response.users;
            //console.log("usersOff1", usersOff);
            for (var p in usersOff) {
                let index = (usersOff).indexOf(p);
                if ((usersOff)[p].email !== userToken.email) {
                    //console.log((usersOff)[p].email);
                    //console.log(userToken.email);
                    (usersOff).splice(index, p);
                }
                /*else {
                    usersOff.push(index, p);
                }*/
            }
            setUsersInactive(usersOff);
            //console.log("usersOff2" ,usersOff);
        });
        setReloadUsers(false);
    }, [token, reloadUsers]);

    //console.log('usersActive', usersActive);
    //console.log('usersInactive', usersInactive);
    //console.log('userToken', userToken);

    return (
        <div className="users">
            <>
            {/* <Helmet>
            <title>React App-Usuarios</title>
             <meta  
                name="description"
                content="React App"
                data-react-helmet="true"
                /> 
            </Helmet>  */}
            <h3>Listado de Usuarios</h3>
            <ListUsers usersActive={usersActive} usersInactive={usersInactive} setReloadUsers={setReloadUsers} />
            </>
        </div>
    );
}