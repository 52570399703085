import React, { useState, useEffect, useCallback } from "react";
import {
  Avatar,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import { Modal as ModalAntd } from "antd";
import { UserOutlined, MailOutlined, LockOutlined, PhoneOutlined } from "@ant-design/icons";
import { useDropzone } from "react-dropzone";
import NoAvatar from "../../../../assets/img/png/no-avatar.png";
import {
  getUserAvatarApi,
  //uploadUserAvatarApi,
  updateUserApi,
} from "../../../../api/user";
import { logout, getAccessToken } from "../../../../api/auth";
import { getRolesActiveApi } from "../../../../api/role";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import {
  emailValidation,
  passwordValidation,
  phoneValidation,
  // numberValidation
} from "../../../../utils/formValidation"; //minLengthValidation,
import jwtDecode from "jwt-decode";

import "./EditUserForm.scss";

const { confirm } = ModalAntd;

export default function EditUserForm(props) {
  const { user, setIsVisibleModal, setReloadUsers } = props;
  const [avatar, setAvatar] = useState(null);
  const [userData, setUserData] = useState({});
  const [activeRoles, setActiveRoles] = useState([]);
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  //console.log('userData: ', userData);

  //estado relacionado a si el usuario realizó modificación en los inputs que deben ser validados
  const [modifDataToValid, setModifDataToValid] = useState({
    email: false,
    phone: false,
    password: false,
  });
  //estado que almacena el resultado de validacion de los campos correspondientes
  const [validData, setValidData] = useState({
    email: false,
    phone: false,
    password: false,
  });

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then((response) => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then((response) => {
      setRoleById(response.role);
    });
  }, [roleById]);

  //trae los datos del user
  useEffect(() => {
      setUserData({
        username: user.username,
        user_id: user._id,
        email: user.email,
        role: user.role,
        phone: user.phone,
        avatar: user.avatar
      });
  }, [user]);

  //console.log('salePoints: ', salePointsData);
  //console.log('user_id: ', userData.user_id);
  //console.log('user._id: ', user._id);

  //para verificar despues si el rol cambia
  const role1 = user.role;
  //console.log('rol 1: ', role1);
  //console.log('userToken: ', userToken._id);

  //si tiene avatar lo trae, sino lo deja vacio
  useEffect(() => {
    if (user.avatar) {
      getUserAvatarApi(user.avatar).then((response) => {
        setAvatar(response);
      });
    } else {
      setAvatar(null);
    }
  }, [user]);

  //si se carga un avatar lo prepara para enviar a la db
  useEffect(() => {
    if (avatar) {
      setUserData({ ...userData, avatar: avatar.file });
    }
  }, [avatar]);

  useEffect(() => {
    getRolesActiveApi(accessToken, true).then((response) => {
      setActiveRoles(response.roles);
    });
  }, []);

  const logoutUser = () => {
    logout(); //borra los token del localStorage
    //ahora se puede hacer un redirect o windows reload
    window.location.reload();
  };
  const showLogoutConfirm = () => {
    confirm({
      title: "Atención",
      content: `Para aplicar el cambio de rol debes volver a iniciar sesión`,
      okText: "OK",
      okType: "warning",
      cancelText: "No",
      cancelButtonProps: {
        //disabled: true
        hidden: true
      },
      onOk() {
          logoutUser();
      },
    });
  };

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    //console.log("userData:", userData);
    //console.log("modifDataToValid:", modifDataToValid);
    //console.log("validData:", validData);

    let errorExists = false;
    if (
      !userData.username ||
      !userData.role ||
      !userData.email // || !userData.phone
    ) {
      notification["error"]({
        message: "Todos los campos son obligatorios",
      });
      errorExists = true;
    }
    //si el campo email fue modificado, verifica la validacion
    if (modifDataToValid.email) {

      if (!validData.email) {
        //solo si el email está vacio, falta la validación en el return de EditForm
        notification["error"]({
          message: "El email es inválido",
        });
        errorExists = true;
      }
    }
    
    //si el campo teléfono fue modificado, verifica la validacion
    if (modifDataToValid.phone) {
      //console.log("Validando teléfono...");
      if (!validData.phone) {
        console.log("Teléfono inválido");
        //solo si el telefono está vacio, falta la validación en el return de EditForm
        notification["error"]({
          message: "El teléfono es inválido",
        });
        errorExists = true;
      }
    }
    
    //si el campo password fue modificado, verifica la validacion
    if (modifDataToValid.password) {
      if (!validData.password) {
        notification["error"]({
          message: "Las contraseñas deben tener al menos 8 carácteres y debe contener mayúsculas, minúsculas y dígitos"
        });
        errorExists = true;
      } else{
      //solo si uno de ambos es escrita, partiendo de los campos vacios, falta validaciones en el return de EditForm
      if (userData.password || userData.repeatPassword) {
        if (userData.password !== userData.repeatPassword) {
            //si no son iguales...
            notification["error"]({
              message: "Las contraseñas tienen que ser iguales",
            });
            errorExists = true;
          }
        }
      }
    }
    return errorExists;
  };

  const updateUser = (e) => {
    e.preventDefault();
    //console.log("Antes de isFormValid - userData:", userData);
    //console.log("Antes de isFormValid - modifDataToValid:", modifDataToValid);
    //console.log("Antes de isFormValid - validData:", validData);
    const error = isFormValid(e);
    if (!error) {
      const token = getAccessToken();
      let userUpdate = userData; //es let porque se va actualizando

      //si el usuario que se quiere editar es el logueado
      if (user._id === userToken._id) {
        //console.log('mismo usuario');
        //si el rol se modificó (hay que actualizar y cerrar sesión)
        if (userUpdate.role !== role1){
          //console.log('rol 2: ', userUpdate.role);
          //console.log('rol no coincide');
          updateUserApi(token, userUpdate, user._id).then((result) => {
            if(result.message === "ERR_CONNECTION_REFUSED" || result.message === "Failed to fetch" || result.message === undefined) {
              notification["error"]({
                  message: "Servidor caido"
              });
              setIsVisibleModal(true);
            } else if (result.message === "El email ya está registrado o la persona ya tiene asociado un usuario") {
                notification["error"]({
                  message: "El email ya está registrado"
                });
                setIsVisibleModal(true);
            } else {
                notification["success"]({
                  message: result.message, //el mensaje que viene del server
                });
                //console.log("respuesta: " + result.message);
                setIsVisibleModal(false);
                setReloadUsers(true);
                //si el rol se editó hay que cerrar sesión:
                showLogoutConfirm();
            }
          });
          //si el rol se editó hay que cerrar sesión
        } else {
          //console.log('rol 2: ', userUpdate.role);
          //console.log('rol coincide');
          updateUserApi(token, userUpdate, user._id).then((result) => {
            if(result.message === "ERR_CONNECTION_REFUSED" || result.message === "Failed to fetch" || result.message === undefined) {
              notification["error"]({
                  message: "Servidor caido"
              });
              setIsVisibleModal(true);
            } else if (result.message === "El email ya está registrado o la persona ya tiene asociado un usuario") {
                notification["error"]({
                  message: "El email ya está registrado"
                });
                setIsVisibleModal(true);
            } else {
                notification["success"]({
                  message: result.message, //el mensaje que viene del server
                });
                //console.log("respuesta: " + result.message);
                setIsVisibleModal(false);
                setReloadUsers(true);
            }
          });
        }
      } else {
        //console.log('usuario diferente');
        updateUserApi(token, userUpdate, user._id).then((result) => {
          if(result.message === "ERR_CONNECTION_REFUSED" || result.message === "Failed to fetch" || result.message === undefined) {
            notification["error"]({
                message: "Servidor caido"
            });
            setIsVisibleModal(true);
          } else if (result.message === "El email ya está registrado o la persona ya tiene asociado un usuario") {
              notification["error"]({
                message: "El email ya está registrado"
              });
              setIsVisibleModal(true);
          } else {
              notification["success"]({
                message: result.message, //el mensaje que viene del server
              });
              //console.log("respuesta: " + result.message);
              setIsVisibleModal(false);
              setReloadUsers(true);
          }
        });
      }

      //console.log("userData", userData);
      //console.log("userUpdate", userUpdate);
      /*
      if (typeof userUpdate.avatar === "object") {
        //actualiza el avatar del user
        uploadUserAvatarApi(token, userUpdate.person.avatar, user._id).then((response) => {
          userUpdate.avatar = response.avatarName;
          updateUserApi(token, userUpdate, user._id).then((result) => {
            notification["success"]({
              message: result.message, //el mensaje que viene del server
            });
            setIsVisibleModal(false);
            setReloadUsers(true);
          });
        });
      } else {*/
        //actualiza el user menos el avatar
        /*
        updateUserApi(token, userUpdate, user._id).then((result) => {
          if(result.message === "ERR_CONNECTION_REFUSED" || result.message === "Failed to fetch" || result.message === undefined) {
            notification["error"]({
                message: "Servidor caido"
            });
            setIsVisibleModal(true);
          } else if (result.message === "El email ya esta registrado") {
              notification["error"]({
                message: "El email ya está registrado"
              });
              setIsVisibleModal(true);
          } else {
              notification["success"]({
                message: result.message, //el mensaje que viene del server
              });
              //console.log("respuesta: " + result.message);
              setIsVisibleModal(false);
              setReloadUsers(true);
          }
        });*/
      //}
    }
  };

  return (
    <div className="edit-user-form">
      <UploadUserAvatar avatar={avatar} setAvatar={setAvatar} />
      <EditForm
        userData={userData}
        setUserData={setUserData}
        updateUser={updateUser}
        setModifDataToValid={setModifDataToValid}
        validData={validData}
        modifDataToValid={modifDataToValid}
        setValidData={setValidData}
        activeRoles={activeRoles}
        roleById={roleById}
        permissionsActive={permissionsActive}
        userToken={userToken}
      />
    </div>
  );
}

function UploadUserAvatar(props) {
  const { avatar, setAvatar } = props;
  const [avatarUrl, setAvatarUrl] = useState(null);

  useEffect(() => {
    if (avatar) {
      if (avatar.preview) {
        setAvatarUrl(avatar.preview);
      } else {
        setAvatarUrl(avatar);
      }
    }
  }, [avatar]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      //console.log("file: " + file);
      if (file === undefined) {
          notification["error"]({
            message: "Formato de imagen no válido, aceptados: png, jpg, bpm.",
          });
          return;
      } else {
        //const file = acceptedFiles[0];
        console.log("URL.createObjectURL(file): " + URL.createObjectURL(file));
        setAvatar({ file, preview: URL.createObjectURL(file) });
      }
      //const file = acceptedFiles[0];
      //setAvatar({ file, preview: URL.createObjectURL(file) });
    },
    [setAvatar]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/jpeg, image/png, image/bmp",
    noKeyboard: true,
    onDrop,
  });

  return (
    <div className="upload-avatar" {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <Avatar size={150} src={NoAvatar} />
      ) : (
        <Avatar size={150} src={avatarUrl ? avatarUrl : NoAvatar} />
      )}
    </div>
  );
}

function EditForm(props) {
  const {
    userData,
    setUserData,
    setModifDataToValid,
    modifDataToValid,
    setValidData,
    validData,
    updateUser,
    activeRoles,
    roleById,
    permissionsActive,
    userToken
  } = props;
  const { Option } = Select;

  //validaciones de inputs
  const inputValidation = (e) => {
    const { type, name } = e.target;
    //console.log("Ingresando a inputValidation");

    setUserData({
      ...userData,
      [name]: e.target.value,
    });
    setModifDataToValid({
      ...modifDataToValid,
      [name]: true,
    });

    if (type === "email") {
      setValidData({
        ...validData,
        email: emailValidation(e.target),
      });
    }
    
    //POR ALGUN MOTIVO NO LEE EL TYPE PHONE, HAY QUE PONER CUALQUIER OTRO NOMBRE
    if (type === "tel") {
      setValidData({
        ...validData,
        phone: phoneValidation(e.target),
      });
    }
    

    /*
    if (type === "phone") {
      const num = e.target.value.replace(/\D+/g, ''); // Elimina todo lo que no es dígito
  
      const numberValid = /^(?:(?:\+|0{0,2})54)?(?:\s?|\()?([2368]\d)?(?:(?=\d{0,2}15)\d{2})??(\d{8})$/;
  
      const resultValidation = numberValid.test(num);
  
      if (resultValidation) {
        e.target.classList.add("success");
        setValidData({
          ...validData,
          phone: true,
        });
      } else {
        e.target.classList.add("error");
        setValidData({
          ...validData,
          phone: false,
        });
      }
    }
    */

    /*
    if (type == "phone") {
      console.log("entrando a type === phone");
      const validationResult = phoneValidation({ value: e.target.value });
      setValidData((prevData) => ({
        ...prevData,
        phone: validationResult,
      }));
      console.log("validData después de la actualización:", validData);
    }
    */

    /*
    if (type === "phone") {
      // Prueba con número fijo
      const resultFixed = phoneValidation({ value: '01123456789' });
      console.log("Resultado con número fijo:", resultFixed);
  
      // Prueba con el valor actual del evento
      const resultCurrent = phoneValidation(e);
      console.log("Resultado con valor actual del evento:", resultCurrent);
  
      // Usa el resultado actual para la actualización del estado
      setValidData({
        ...validData,
        phone: resultCurrent,
      });
    }
    */

    /*
    if (type === "phone") {
      // Obtén el resultado de la validación
      const validationResult = numberValidation(e.target);
    
      // Usa el resultado para la actualización del estado
      setValidData({
        ...validData,
        phone: validationResult,
      });
    }
    */
    
   
    if (type === "password") {
      setValidData({
        ...validData,
        password: passwordValidation(e.target),
      });
    }
  };

  return (
    <Form className="form-edit" onSubmit={updateUser}//onFinish={updateUser}
    >
      {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item>
            <Select
              showSearch
              placeholder="Selecciona una persona"
              value={userData.person}
              onChange={(e) => setUserData({ ...userData, person: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listPersonsActive
                ? listPersonsActive.map((person) => {
                    return (
                      <Option key={person._id}>
                        {person.name + " " + person.lastname}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row> */}

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item>
            <Input
              prefix={<UserOutlined />}
              placeholder="Nombre de usuario"
              value={userData.username}
              onChange={(e) =>
                setUserData({ ...userData, username: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item>
            <Input
              prefix={<MailOutlined />}
              type="email"
              name="email"
              placeholder="Correo electrónico"
              value={userData.email}
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item>
            <Input
              prefix={<PhoneOutlined />}
              type="tel"
              name="phone"
              placeholder="Teléfono personal"
              value={userData.phone}
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
        {checkAction(permissionsActive, userToken.role, "user-role", "edit") ||
        isAdmin(roleById) ? (
          <Form.Item>
            <Select
              className="select-form"
              placeholder="Selecciona un rol"
              onChange={(e) => setUserData({ ...userData, role: e })}
              value={userData.role}
            >
            {activeRoles
              ? activeRoles.map((i) => {
                  return <Option key={i._id}>{i.name}</Option>;
                })
              : "No hay roles disponibles"}
            </Select>
          </Form.Item>
        ) :
          <Form.Item>
            <Select
              className="select-form"
              placeholder="Selecciona un rol"
              onChange={(e) => setUserData({ ...userData, role: e })}
              value={userData.role}
              disabled={true}
            >
            {activeRoles
              ? activeRoles.map((i) => {
                  return <Option key={i._id}>{i.name}</Option>;
                })
              : "No hay roles disponibles"}
            </Select>
          </Form.Item>
        }
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item>
            <Input
              prefix={<LockOutlined />}
              type="password"
              name="password"
              placeholder="Contraseña"
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item>
            <Input
              prefix={<LockOutlined />}
              type="password"
              name="repeatPassword"
              placeholder="Repita la Contraseña"
              onChange={(e) =>
                setUserData({ ...userData, repeatPassword: e.target.value })
              }
              //onChange={ inputValidation }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="btn-submit" onClick={updateUser}>
          Actualizar
        </Button>
      </Form.Item>
      <br></br>

    </Form>

  );
}
