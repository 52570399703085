import React, { useState } from "react"; //useEffect
import { Form, Input, InputNumber, Button, Row, Col, notification } from "antd"; //Select
import { TagOutlined } from "@ant-design/icons"; //EnvironmentOutlined
import { addSaleConditionApi } from "../../../../api/billing/saleCondition";
import { getAccessToken } from "../../../../api/auth";
//import jwtDecode from "jwt-decode";
//import { numberValidation } from "../../../../utils/formValidation";

import "./AddSaleConditionForm.scss";

export default function AddSaleConditionForm(props) {
  const { setIsVisibleModal, setReloadSaleConditions } = props;

  //const accessToken = getAccessToken();
  //const metaToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  const [inputs, setInputs] = useState({
    salecondition_code: "",
    description: "",
  });

  //  const [formValid, setFormValid] = useState({
  //    item_code: false,
  //    name: false
  //  });

  // const inputValidation = (e) => {
  //   const { type, name } = e.target;

  //   setInputs({
  //     ...inputs,
  //     [name]: e.target.value,
  //   });

  //   setFormValid({
  //     ...formValid,
  //     [item_code_valid]:"sucess"
  //   });
  // };
  /*
  const [formValid, setFormValid] = useState({
    phone: false
  });*/

  const inputValidation = (e) => {
    const { name } = e.target; //type

    setInputs({
      ...inputs,
      [name]: e.target.value,
    });
    /*
    if (name === "phone"){
      setFormValid({
        ...formValid,
            [name]: numberValidation(e.target)
        });
    }*/
  };

  const isFormValid = (e) => {
    //console.log(inputs);
    let errorExists = false;
    if (!inputs.description) {
      notification["error"]({
        message: "Obligatorios: descripción.",
      });
      errorExists = true;
    }
    /*
    if (!formValid.phone && inputs.phone !== "") {
      notification["error"]({
        message: "Ingreso de telefono inválido",
      });
      errorExists = true;
    }*/
    return errorExists;
  };

  const addSaleCondition = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      const accessToken = getAccessToken();

      await addSaleConditionApi(accessToken, inputs)
        .then((response) => {
          if (
            response === "ERR_CONNECTION_REFUSED" ||
            response === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              message: "Condición de venta creada",
            });
            setIsVisibleModal(false);
          }
          setReloadSaleConditions(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  return (
    <div className="add-salecondition-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        inputValidation={inputValidation}
        addSaleCondition={addSaleCondition}
      />
    </div>
  );
}

function AddForm(props) {
  const { inputs, setInputs, addSaleCondition } = props; //inputValidation
  //const { Option } = Select;

  return (
    <Form className="form-add" onSubmit={addSaleCondition}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={inputs.salecondition_code}
              onChange={(e) => setInputs({ ...inputs, salecondition_code: e })}
              min={1}
              style={{ width: "90%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Descripción
          </label>
          <Form.Item>
            <Input
              placeholder="Descripción"
              value={inputs.description}
              onChange={(e) =>
                setInputs({ ...inputs, description: e.target.value })
              }
              required
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addSaleCondition}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
