import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getDocumentTypesActiveApi } from "../../../api/billing/documentType";
import ListDocumentTypes from "../../../components/Billing/DocumentTypes/ListDocumentTypes";


export default function DocumentTypes() {
    const [documentTypesActive, setDocumentTypesActive] = useState([]);
    const [documentTypesInactive, setDocumentTypesInactive] = useState([]);
    const [reloadDocumentTypes, setReloadDocumentTypes ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getDocumentTypesActiveApi(token, true).then(response => {
            setDocumentTypesActive(response.documentTypes);
        });
        getDocumentTypesActiveApi(token, false).then(response => {
            setDocumentTypesInactive(response.documentTypes);
        });
        setReloadDocumentTypes(false);
    }, [token, reloadDocumentTypes]);

    return (
        <div className="documentTypes">

            <ListDocumentTypes documentTypesActive={documentTypesActive} documentTypesInactive={documentTypesInactive} setReloadDocumentTypes={setReloadDocumentTypes} 
                />

        </div>
    );
}