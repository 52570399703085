import React from "react"; //, { useState, useEffect }
import { getAccessToken } from "../../../api/auth";
import ListExports from "../../../components/Admin/Exports/ListExports";

import "./Exports.scss";

export default function Exports() {
    const token = getAccessToken();

    return (
        <div className="exports">

            <ListExports token={token}
                />
        </div>
    );
}