import React, { useState, useEffect } from "react";
import { Form, Input, InputNumber, Button, Row, Col, notification } from "antd";
import {
  TagOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { updateConceptApi } from "../../../../api/billing/concept";
import { getAccessToken } from "../../../../api/auth";
import { numberValidation } from "../../../../utils/formValidation";

import "./EditConceptForm.scss";

export default function EditConceptForm(props) {
  const { concept, setIsVisibleModal, setReloadConcepts } = props;
  const [conceptData, setConceptData] = useState({});

  const [modifDataToValid, setModifDataToValid] = useState({
    phone: false,
  });
  const [validData, setValidData] = useState({
    email: false,
  });

  //trae los datos de tipo de proveedor
  useEffect(() => {
    setConceptData({
      //concept_code: seller.seller_code,
      name: concept.name,
    });
  }, [concept]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!conceptData.name) {
      notification["error"]({
        message: "Obligatorios: Código y Nombre.",
      });
      errorExists = true;
    } else {
    /*
    else if (modifDataToValid.phone) {
      if (!validData.phone) {
        //solo si el email está vacio, falta la validación en el return de EditForm
        notification["error"]({
          message: "Ingreso de teléfono inválido"
        });
        errorExists = true;
      }
    }*/
      errorExists = false;
    }
    return errorExists;
  };

  const updateConcept = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let conceptUpdate = conceptData; //es let porque se va actualizando

      updateConceptApi(token, conceptUpdate, concept._id).then((result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //console.log("respuesta: " + result.message);
          setIsVisibleModal(false);
          setReloadConcepts(true);
        }
      });
    }
  };

  return (
    <div className="edit-concept-form">
      <EditForm
        conceptData={conceptData}
        setConceptData={setConceptData}
        updateConcept={updateConcept}
        modifDataToValid={modifDataToValid}
        setModifDataToValid={setModifDataToValid}
        validData={validData}
        setValidData={setValidData}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    conceptData,
    setConceptData,
    updateConcept,
    setModifDataToValid,
    modifDataToValid,
    validData,
    setValidData,
  } = props;

  //validaciones de inputs
  const inputValidation = (e) => {
    const { type, name } = e.target;

    setConceptData({
      ...conceptData,
      [name]: e.target.value,
    });
    setModifDataToValid({
      ...modifDataToValid,
      [name]: true,
    });
    /*
      if (name === "phone"){
        setValidData({
            ...validData,
            [name]: numberValidation(e.target)
        });
      }*/
  };

  return (
    <Form className="form-edit" onSubmit={updateConcept}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
            <span className="control-required">* </span>Nombre
          </label>
          <Form.Item>
            <Input
              placeholder="Nombre"
              value={conceptData.name}
              onChange={(e) =>
                setConceptData({ ...conceptData, name: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateConcept}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
