import React from 'react';
// import {Helmet} from "react-helmet";

export default function Home() {
    return (
        <>
         {/* <Helmet>
            <title>Home</title>
             <meta  
                name="description"
                content="React App"
                data-react-helmet="true"
                />
        </Helmet>  */}
        <div>
            <h1>Estamos en Home</h1>
        </div>
        </>
    );
}