import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  BackTop,
  List,
  Collapse,
  Card,
  Row,
  Col,
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined,
  CaretRightOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditCategoryForm from "../EditCategoryForm";
import AddCategoryForm from "../AddCategoryForm";
import AddItemCategoryForm from "../../Items/AddItemCategoryForm/AddItemCategoryForm";
import EditItemForm from "../../Items/EditItemForm";
import {
  activateCategoryApi,
  deleteCategoryApi,
  availableCategoryApi
} from "../../../../api/category";
import {
  getItemsByCategoryApi,
  getItemAvatarApi,
  deleteItemApi,
  activateItemApi,
} from "../../../../api/item";
import { availableCategoryBranchMenuAllBranchesApi, deleteCategoryBranchMenuAllBranchesApi, deleteItemBranchMenuAllBranchesApi, deleteItemBranchMenuByBranchApi } from "../../../../api/branchMenu";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";
import NoImage from "../../../../assets/img/png/no-image.png";

import "./ListCategories.scss";
import { useBranch } from "../../../../hooks/useBranch";
import { useBusiness } from "../../../../hooks/useBusiness";

const { Meta } = Card;

const { confirm } = ModalAntd;
const { Search } = Input;
const { Panel } = Collapse;

export default function ListCategories(props) {
  const { categoriesActive, categoriesInactive, setReloadCategories } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewCategoriesActives, setViewCategoriesActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [modalWidth, setModalWidth] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [categoriesAvailableFilter, setCategoriesAvailableFilter] = useState([]);
  const [categoriesNotAvailableFilter, setCategoriesNotAvailableFilter] = useState([]);
  // const [typeFilter, setMethodFilter] = useState("description");

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  const {branch}=useBranch();
  const {business}=useBusiness();

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then((response) => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then((response) => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    const listAvailable = [];
    if (categoriesActive) {
      categoriesActive.forEach((element) => {
        // if (typeFilter === "description") {
        if (
          element.name.toString().includes(filterText.toUpperCase()) ||
          filterText === ""
        ) {
          listAvailable.push(element);
        }
        // }
      });
    }
    setCategoriesAvailableFilter(listAvailable);
  }, [filterText, categoriesActive]);

  useEffect(() => {
    const listNotAvailable = [];
    if (categoriesInactive) {
      categoriesInactive.forEach((element) => {
        // if (typeFilter === "description") {
        if (
          element.name.toUpperCase().includes(filterText.toUpperCase()) ||
          filterText === ""
        ) {
          listNotAvailable.push(element);
        }
        // }
      });
    }
    setCategoriesNotAvailableFilter(listNotAvailable);
  }, [filterText, categoriesInactive]);

  const addCategoryModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Categoria");
    setModalContent(
      <AddCategoryForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadCategories={setReloadCategories}
      />
    );
  };

  return (
    <div className="list-categories">
      <div className="list-categories__header">
        {checkAction(permissionsActive, userToken.role, "categories", "all") ||
        checkAction(
          permissionsActive,
          userToken.role,
          "categories",
          "enable"
        ) ||
        isAdmin(roleById) ? (
          <>
            <div className="list-categories__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewCategoriesActives(!viewCategoriesActives)
                }
              />
              <span>{viewCategoriesActives ? "Activos" : "Inactivos"}</span>
            </div>
          </>
        ) : (
          <></>
        )}

        {checkAction(permissionsActive, userToken.role, "categories", "all") ||
        checkAction(permissionsActive, userToken.role, "categories", "add") ||
        isAdmin(roleById) ? (
          <>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={addCategoryModal}
            >
              Nuevo
            </Button>
          </>
        ) : (
          <></>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "categories", "all") ||
      checkAction(permissionsActive, userToken.role, "categories", "view") ||
      isAdmin(roleById) ? (
        <>
          <div className="list-categories__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <h2></h2>
          </div>
        </>
      ) : (
        <></>
      )}

      {viewCategoriesActives ? (
        <>
          {checkAction(
            permissionsActive,
            userToken.role,
            "categories",
            "all"
          ) ||
          checkAction(
            permissionsActive,
            userToken.role,
            "categories",
            "view"
          ) ||
          isAdmin(roleById) ? (
            <>
              <CategoriesAvailable
                categoriesAvailable={categoriesAvailableFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadCategories={setReloadCategories}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                setModalWidth={setModalWidth}
                business={business}
              />
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {checkAction(
            permissionsActive,
            userToken.role,
            "categories",
            "all"
          ) ||
          checkAction(
            permissionsActive,
            userToken.role,
            "categories",
            "view"
          ) ||
          isAdmin(roleById) ? (
            <>
              <CategoriesNotAvailable
                categoriesNotAvailable={categoriesNotAvailableFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadCategories={setReloadCategories}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                setModalWidth={setModalWidth}
                business={business}
              />
            </>
          ) : (
            <></>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        modalWidth={modalWidth}
      >
        {modalContent}
      </Modal>

      <BackTop />
    </div>
  );
}

function CategoriesAvailable(props) {
  const {
    categoriesAvailable,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadCategories,
    accessToken,
    userToken,
    permissionsActive,
    roleById,
    setModalWidth,
    reloadCategories,
    business
  } = props;

  const [listData, setListData] = useState([]);
  const [reload, setReload] = useState(false); //para que se actualicen los items dentro del panel

  useEffect(() => {
    const renderFiltering = categoriesAvailable.map((filter, index) => {
      // const list= await getItemsByCategory(filter);
      return (
        <Panel className="ant-panel" header={filter.name} key={filter._id} extra={extraNode(filter)}>
          <ListItems
            categoryId={filter._id}
            accessToken={accessToken}
            setIsVisibleModal={setIsVisibleModal}
            setModalTitle={setModalTitle}
            setModalContent={setModalContent}
            setModalWidth={setModalWidth}
            business={business}
          />
        </Panel>
      );
    });
    setListData(renderFiltering);
  }, [categoriesAvailable]);

  const handleDeleteCategory = async (category) => {
    let result = await getItemsByCategoryApi(accessToken, category._id);
    let response=result.items? result.items.length > 0? false : true : false;
    //let response = true;
    if (response === false) {
      confirm({
        title: "Eliminando -> Categoria",
        content: `No se puede eliminar '${category.name}' porque existen productos asociados ¿Deseas desactivarlo?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          // desactivateAdviser(adviser);
        },
      });
    } else {
      confirm({
        title: "Eliminando -> Categoria",
        content: `¿Estás seguro que quieres eliminar a ${category.name}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          //Luego elimino la categoria de todos los branch menu para eliminar definitivamente
          deleteCategoryBranchMenuAllBranchesApi(accessToken,business,category._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              return true;
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .then(async (result)=>{
            if(result==true){
              let response= await deleteCategoryApi(accessToken, category._id);
                if (response.code === 200) {
                  notification["success"]({
                    message: response.message,
                  });
                  setReloadCategories(true);
                } else if (response.code === 404 || response.code === 500) {
                  notification["error"]({
                    message: response.message,
                  });
                } else if (
                  response === "ERR_CONNECTION_REFUSED" ||
                  response === "Failed to fetch" ||
                  response === undefined
                ) {
                  notification["error"]({
                    message: "Servidor caido",
                  });
                } else {
                  notification["error"]({
                    message: "Error del servidor",
                  });
                }
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
          })
        },
      });
    }
  };

  const handleEditCategory = (category) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar ${category.name ? category.name : "sin nombre"}`);
    setModalContent(
      <EditCategoryForm
        category={category}
        setIsVisibleModal={setIsVisibleModal}
        setReloadCategories={setReloadCategories}
      />
    );
  };

  const handleAddItemCategory = (category) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Agregar Producto ${category.name ? category.name : "sin nombre"}`
    );
    setModalWidth(1000);
    setModalContent(
      <AddItemCategoryForm
        category={category}
        categories={categoriesAvailable}
        setIsVisibleModal={setIsVisibleModal}
        setReloadCategories={setReloadCategories}
        width={1000}
        business={business}
      />
    );
  };

  const handleDesactivateCategory = (category) => {
    availableCategoryBranchMenuAllBranchesApi(accessToken, business, category._id, false)
    .then((response) => {
      notification["success"]({
        message: "La categoria ya no esta disponible en ninguna sucursal",
      });
      availableCategoryApi(accessToken, category._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadCategories(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
      //setReloadCategories(true);
    })
    .catch((err) => {
      notification["error"]({
        message: err,
      });
    });
  };

  const extraNode = (category) => (
    <div onClick={(e) => e.stopPropagation()}>
      <Button className="button-available" onClick={() => handleDesactivateCategory(category)}>
        <EyeInvisibleOutlined/>
      </Button>
      <Button className="button-add" onClick={() => handleAddItemCategory(category)}>
        <PlusOutlined />
      </Button>
      <Button className="button-edit" onClick={() => handleEditCategory(category)}>
        <EditOutlined />
      </Button>
      <Button className="button-delete" onClick={() => handleDeleteCategory(category)}>
        <DeleteOutlined />
      </Button>
    </div>
  );

  const handleButtonClick = (panelKey) => {
    //console.log(`Acción en el panel ${panelKey}`);
    // Aquí podrías agregar lógica adicional según la acción realizada en el panel
  };

  return (
    <Collapse defaultActiveKey={["1"]} accordion  expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />} className="ant-collapse">
      {listData}
    </Collapse>
  );
}

function CategoriesNotAvailable(props) {
  const {
    categoriesNotAvailable,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadCategories,
    accessToken,
    userToken,
    permissionsActive,
    roleById,
    setModalWidth,
    business
  } = props;

  const [listData, setListData] = useState([]);

  useEffect(() => {
    const renderFiltering = categoriesNotAvailable.map((filter, index) => {
      // const list= await getItemsByCategory(filter);
      return (
        <Panel className="ant-panel" header={filter.name} key={filter._id} extra={extraNode(filter)}>
          <ListItems
            categoryId={filter._id}
            accessToken={accessToken}
            setIsVisibleModal={setIsVisibleModal}
            setModalTitle={setModalTitle}
            setModalContent={setModalContent}
            setModalWidth={setModalWidth}
          />
        </Panel>
      );
    });

    setListData(renderFiltering);
  }, [categoriesNotAvailable]);

  const handleDeleteCategory = async (category) => {
    // let response = await getStudentsByAdviserApi(accessToken, adviser._id);
    let response = true;
    if (response === false) {
      confirm({
        title: "Eliminando -> Categoria",
        content: `No se puede eliminar '${category.name}' porque existen productos asociados ¿Deseas desactivarlo?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          // desactivateAdviser(adviser);
        },
      });
    } else {
      confirm({
        title: "Eliminando -> Categoria",
        content: `¿Estás seguro que quieres eliminar a ${category.name}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          //primero elimino asociacion a todos los branch menu y luego la elimino
          deleteCategoryBranchMenuAllBranchesApi(accessToken, category._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                //setReloadCategories(true);
                return category;
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .then((category) => {
              deleteCategoryApi(accessToken, category._id).then((response) => {
                if (response.code === 200) {
                  notification["success"]({
                    message: response.message,
                  });
                  setReloadCategories(true);
                } else if (response.code === 404 || response.code === 500) {
                  notification["error"]({
                    message: response.message,
                  });
                } else if (
                  response === "ERR_CONNECTION_REFUSED" ||
                  response === "Failed to fetch" ||
                  response === undefined
                ) {
                  notification["error"]({
                    message: "Servidor caido",
                  });
                } else {
                  notification["error"]({
                    message: "Error del servidor",
                  });
                }
              });
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const handleEditCategory = (category) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar ${category.name ? category.name : "sin nombre"}`);
    setModalContent(
      <EditCategoryForm
        category={category}
        setIsVisibleModal={setIsVisibleModal}
        setReloadCategories={setReloadCategories}
      />
    );
  };

  const handleAddItemCategory = (category) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Agregar Producto ${category.name ? category.name : "sin nombre"}`
    );
    setModalWidth(1000);
    setModalContent(
      <AddItemCategoryForm
        category={category}
        setIsVisibleModal={setIsVisibleModal}
        setReloadCategories={setReloadCategories}
        width={1000}
        business={business}
      />
    );
  };

  const handleAvailableCategory = (category) => {
    availableCategoryBranchMenuAllBranchesApi(accessToken, business, category._id, true)
    .then((response) => {
      notification["success"]({
        message: "La categoria esta disponible en todas las sucursales",
      });
      availableCategoryApi(accessToken, category._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadCategories(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
      //setReloadCategories(true);
    })
    .catch((err) => {
      notification["error"]({
        message: err,
      });
    });
  };

  const extraNode = (category) => (
    <div onClick={(e) => e.stopPropagation()}>
      <Button className="button-available" onClick={() => handleAvailableCategory(category)}>
        <EyeOutlined />
      </Button>
      <Button className="button-add" onClick={() => handleAddItemCategory(category)}>
        <PlusOutlined />
      </Button>
      <Button className="button-edit" onClick={() => handleEditCategory(category)}>
        <EditOutlined />
      </Button>
      <Button className="button-delete" onClick={() => handleDeleteCategory(category)}>
        <DeleteOutlined />
      </Button>
    </div>
  );

  const handleButtonClick = (panelKey) => {
    //console.log(`Acción en el panel ${panelKey}`);
    // Aquí podrías agregar lógica adicional según la acción realizada en el panel
  };

  return (
    <Collapse defaultActiveKey={["1"]} className="ant-collapse"  expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />} accordion>
      {listData}
    </Collapse>
  );
}

function ListItems(props) {
  const {
    categoryId,
    accessToken,
    setIsVisibleModal,
    setModalContent,
    setModalTitle,
    setModalWidth,
    reload,
  } = props;

  const [listData, setListData] = useState([]);
  const [reloadItems, setReloadItems] = useState(false);

  useEffect(() => {
    getItemsByCategoryApi(accessToken, categoryId, false).then((response) => {
      setListData(response.items);
    });
    setReloadItems(false);
  }, [categoryId, reloadItems]);

  return (
    <List
      grid={{ gutter: 16, column: 3 }} //
      dataSource={listData}
      renderItem={(item, index) => (
        <List.Item>
          <ItemCard
            item={item}
            index={index}
            setReloadItems={setReloadItems}
            accessToken={accessToken}
            setModalWidth={setModalWidth}
            setIsVisibleModal={setIsVisibleModal}
            setModalTitle={setModalTitle}
            setModalContent={setModalContent}
          />
        </List.Item>
      )}
    />
  );
}

function ItemCard(props) {
  const {
    item,
    index,
    setReloadItems,
    setIsVisibleModal,
    setModalContent,
    setModalTitle,
    setModalWidth,
    accessToken,
  } = props;


  const [imageUrl, setImageUrl] = useState(null);
  //si tiene avatar lo trae, sino lo deja vacio
  useEffect(() => {
    if (item.image) {
      getItemAvatarApi(item.image).then((response) => {
        setImageUrl(response);
      });
    } else {
      setImageUrl(null);
    }
  }, [item]);

   const handleDeleteItem = async (item) => {
    //Es un producto general por lo que se debe eliminar de todos los menu branch y luego eliminar difinitivamente
    let response = item.branch? item.branch!==""? false: false: true;
    if (response === false) {
      confirm({
        title: "Eliminando -> Producto",
        content: `¿Estás seguro que quieres eliminar a ${item.name} esta asociado a un negocio?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteItemBranchMenuByBranchApi(accessToken, item.branch, item)
            .then((response) => {
              if (response.code === 200) {
                return true;
              } else if (response.code === 404 || response.code === 500) {
                return false;
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                return false;
              } else {
                return false;
              }
            })
            .then(async (result) => {
              if (result === true) {
                let response = await deleteItemApi(accessToken, item._id);
                if (response.code === 200) {
                  notification["success"]({
                    message: response.message,
                  });
                  setReloadItems(true);
                } else if (response.code === 404 || response.code === 500) {
                  notification["error"]({
                    message: response.message,
                  });
                } else if (
                  response === "ERR_CONNECTION_REFUSED" ||
                  response === "Failed to fetch" ||
                  response === undefined
                ) {
                  notification["error"]({
                    message: "Servidor caido",
                  });
                } else {
                  notification["error"]({
                    message: "Error del servidor",
                  });
                }
              } else {
                notification["error"]({
                  message: "Error al eliminar el producto",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
     } else {
      //Es un producto general
      confirm({
        title: "Eliminando -> Producto",
        content: `¿Estás seguro que quieres eliminar a ${item.name} de todas las sucursales?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteItemBranchMenuAllBranchesApi(accessToken, item._id)
            .then((response) => {
              if (response.code === 200) {
                return true;
              } else if (response.code === 404 || response.code === 500) {
                return false;
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                return false;
              } else {
                return false;
              }
            })
            .then(async (result) => {
              if (result === true) {
                let response = await deleteItemApi(accessToken, item._id);
                if (response.code === 200) {
                  notification["success"]({
                    message: response.message,
                  });
                  setReloadItems(true);
                } else if (response.code === 404 || response.code === 500) {
                  notification["error"]({
                    message: response.message,
                  });
                } else if (
                  response === "ERR_CONNECTION_REFUSED" ||
                  response === "Failed to fetch" ||
                  response === undefined
                ) {
                  notification["error"]({
                    message: "Servidor caido",
                  });
                } else {
                  notification["error"]({
                    message: "Error del servidor",
                  });
                }
              } else {
                notification["error"]({
                  message: "Error al eliminar el producto",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const handleEditItem = (item) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar ${item.name ? item.name : "sin nombre"}`);
    setModalWidth(1000);
    setModalContent(
      <EditItemForm
        item={item}
        setIsVisibleModal={setIsVisibleModal}
        setReloadItems={setReloadItems}
        isEditingModal={true}
      />
    );
  };

  return (
    <Col key={index} span={8}>
      <Card
        style={{
          width: 300,
        }}
        cover={
          <img
            alt="example"
            src={imageUrl ? imageUrl : NoImage}
            style={{ width: "100%", height: "200px", objectFit: "contain" }}
          />
        }
        actions={[
          <Button
            style={{ width: "100%" }}
            type="link"
            onClick={() => {
              handleEditItem(item);
            }}
            ghost
          >
            <EditOutlined key="edit" />
          </Button>,
          <Button
            style={{ width: "100%" }}
            type="link"
            onClick={() => {
              handleDeleteItem(item);
            }}
            danger
          >
            <DeleteOutlined key="delete" />
          </Button>,
        ]}
      >
        <Meta title={item.name} description={item.description} />
      </Card>
    </Col>
  );
}
