import React, { useState, useEffect } from "react";
import {
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  Tag,
  Space,
  Col,
  Row
} from "antd";

//import { Popover } from "antd";

import { DeleteOutlined , EyeOutlined, DownloadOutlined , PlusOutlined} from "@ant-design/icons"; //EditOutlined

import Modal from "../../../Modal";

import AddMovementForm from "../../Movements/AddMovementForm";
import EditMovementForm from "../../Movements/EditMovementForm";
import ViewMovementForm from "../../Movements/ViewMovementForm";


import { deleteMovementApi } from "../../../../api/billing/movement";
import { listMovementsPdf, movementDetailPdf} from "./pdfMovements";
// import { getStockInventoriesApi } from "../../../../api/billing/stockInventory";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListMovements.scss";
import { formatDateView } from "../../../../utils/formatDate";
import SearchByDates from "../../SearchByDates/SearchByDates";
import { convertMovementType, convertOrigin } from "../../../../utils/convert";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListMovements(props) {
  const { movements, setReloadMovements } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);

  // const [listStockInventory, setListStockInventory]=useState([]);

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [movementsFilter, setMovementsFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState("description");

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, [roleById]);

  useEffect(() => {
    const listMovements = [];

    if (movements) {
      movements.forEach((element) => {
        if (!element.item) {
          //   showDeleteConfirm(element);
        } else {
          if (typeFilter === "description") {
            if (
              element.item.description.toUpperCase().indexOf(filterText.toUpperCase()) !== -1 ||
              filterText === ""
            ) {
              listMovements.push(element);
            }
          } else {
            if (
              element.item.item_code.toUpperCase().indexOf(filterText.toUpperCase()) !== -1 ||
              filterText === ""
            ) {
              listMovements.push(element);
            }
          }
        }
      });
    }
    setMovementsFilter(listMovements);
  }, [filterText, movements]);

  //   const showDeleteConfirm = (element) => {
  //     const accessToken = getAccessToken();

  //     confirm({
  //       title: "Eliminando Inventario",
  //       content: `Encontramos en el sistema el ${element.description} cuyo Producto no existe, ¿Desea eliminarlo?`,
  //       okText: "Eliminar",
  //       okType: "danger",
  //       cancelText: "Cancelar",
  //       onOk() {
  //         //itemsInventoryDelete.forEach(element=>{
  //           deleteInventoryStockApi(accessToken, element._id)
  //           .then((response) => {
  //               notification["success"]({
  //                 message: response.message,
  //               });
  //             })
  //             .catch((err) => {
  //               notification["error"]({
  //                 message: err,
  //               });
  //             });
  //         }
  //       });
  //     };

  const addMovementModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Movimiento");
    setModalContent(
      <AddMovementForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadMovements={setReloadMovements}
      />
    );
  };

  const editMovementModal = (movement) => {
    setIsVisibleModal(true);
    setModalTitle("Editar Movimiento");
    setModalContent(
      <EditMovementForm
        movement={movement}
        setIsVisibleModal={setIsVisibleModal}
        setReloadMovements={setReloadMovements}
      />
    );
  };

  const showDeleteConfirm = (movement) => {
    const accessToken = getAccessToken();

    confirm({
      title: "Eliminando Movimiento",
      content: `¿Estás seguro que quieres eliminar el movimiento?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteMovementApi(accessToken, movement._id)
          .then((response) => {
            notification["success"]({
              message: response.message,
            });
            setReloadMovements(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err.message,
            });
          });
      },
    });
  };

  const viewMovementModal = (movement) => {
    setIsVisibleModal(true);
    setModalTitle("Ver Movimiento");
    setModalContent(
      <ViewMovementForm
        movement={movement}
      />
    );
  };

  return (
    <div className="list-movements">
      <div className="list-movements__header">
        <div></div>

        {checkAction(permissionsActive, userToken.role, "movements", "all") ||
        checkAction(permissionsActive, userToken.role, "movements", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>}  onClick={addMovementModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "movements", "all") ||
      checkAction(permissionsActive, userToken.role, "movements", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-movements__search">
            <Row>
              <Col span={20}>
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <Radio.Group
              options={[
                { label: "Descripcion", value: "description" },
                { label: "Codigo", value: "item_code" },
              ]}
              onChange={(e) => {
                setTypeFilter(e.target.value);
              }}
              value={typeFilter}
            />
            </Col>
            <Col span={4}>
            <SearchByDates
                searchList={movementsFilter}
                setSearchList={setMovementsFilter}
                setReload={setReloadMovements}
                dataSearch={"date"}
              />
            </Col>
            </Row>
          </div>
          </>
        ) : (
          <>
          </>
      )}

      <TableMovements
        movements={movementsFilter}
        editMovementModal={editMovementModal}
        viewMovementModal={viewMovementModal}
        showDeleteConfirm={showDeleteConfirm}
        accessToken={accessToken}
        userToken={userToken}
        permissionsActive={permissionsActive}
        roleById={roleById}
      />

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function TableMovements(props) {
  const {
          movements,
          editMovementModal,
          viewMovementModal,
          showDeleteConfirm,
          accessToken,
          userToken,
          permissionsActive,
          roleById
        } = props;

  // const [selectedRowArray, setSelectedRowArray]=useState([]);

  // const rowSelection={
  //   selectedRowArray,
  //   onChange:(selectedRowKeys) => {
  //      setSelectedRowArray(selectedRowKeys);
  //     },

  //   }

  const columns = [
    {
      title: "Fecha",
      dataIndex: "date",
      render: (date) => formatDateView(date),
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
    },
    {
      title: "Tipo",
      dataIndex: "movement_type",
      render: (movement_type) => convertMovementType(movement_type)==="Entrada"? <Tag color="#02ac66">Entrada</Tag> : <Tag color="#ef280f">Salida</Tag>,
      sorter: (a, b) => a.movement_type - b.movement_type,
    },
    {
      title: "Origen",
      dataIndex: "origin",
      render: (origin) => convertOrigin(origin),
    },
    {
      title: 'Producto',
      children: [
        {
          title: "Código",
          dataIndex: "item",
          render: (item) => item.item_code,
        },
        {
          title: "Descripción",
          dataIndex: "item",
          render: (item) => item.description,
        },
      ]
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, movement) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "movements", "all") ||
          checkAction(permissionsActive, userToken.role, "movements", "view") || isAdmin(roleById) ? (
            <>
              <Button
                className="button-movement"
                type="default"
                onClick={() => viewMovementModal(movement)}
              >
              <EyeOutlined />
              </Button>
            </>
          ) : (
            <>
            </>
          )}
          {/* <Button
            className="button-movement"
            type="default"
            onClick={() => editMovementModal(movement)}
          >
            <EditOutlined />
          </Button> */}
          {checkAction(permissionsActive, userToken.role, "movements", "all") ||
          checkAction(permissionsActive, userToken.role, "movements", "delete") || isAdmin(roleById) ? (
            <>
              <Button
                className="button-movement"
                type="danger"
                onClick={() => showDeleteConfirm(movement)}
              >
                <DeleteOutlined />
              </Button>
            </>
          ) : (
            <>
            </>
        )}
          {checkAction(permissionsActive, userToken.role, "movements", "all") ||
          checkAction(permissionsActive, userToken.role, "movements", "pdf") || isAdmin(roleById) ? (
            <>
              <Button
                type="primary"
                className="button-movement"
                onClick={()=>{
                  movementDetailPdf(movement,false);}}>
                <DownloadOutlined />
              </Button>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },

  ];

  return (
    <>
      {checkAction(permissionsActive, userToken.role, "movements", "all") ||
      checkAction(permissionsActive, userToken.role, "movements", "pdf") || isAdmin(roleById) ? (
        <>
          <Space align="center" style={{ marginBottom: 16 }}>
          <Button
              type="default"
              shape="round"
              onClick={()=>{
                listMovementsPdf(movements,false);}}>
                  <DownloadOutlined/> Listado Movimientos
              </Button>
          </Space>
        </>
      ) : (
        <>
        </>
      )}

      {checkAction(permissionsActive, userToken.role, "movements", "all") ||
      checkAction(permissionsActive, userToken.role, "movements", "view") || isAdmin(roleById) ? (
        <>
          <Table pagination={false} dataSource={movements} columns={columns} rowKey="_id" />
        </>
      ) : (
        <>
        </>
      )}
    </>
  );
}
