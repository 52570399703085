import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import {
  //getAbsencesActiveApi,
  getBranchesActiveByBusinessApi,
} from "../../../api/branch";
import ListBranches from "../../../components/Admin/Branches/ListBranches/ListBranches";
import { useBranch } from "../../../hooks/useBranch";

export default function BusinessBranches(props) {
  //const {setReloadBranch}=props;
  //const [branchId, setBranchId] = useState();
  const [branchesActive, setBranchesActive] = useState([]);
  const [branchesInactive, setBranchesInactive] = useState([]);
  const [reloadBranches, setReloadBranches] = useState(false);
  const [businessId, setBusinessId] = useState(
    props.location.state ? props.location.state.id : false
  );
  const token = getAccessToken();
  const { branch, changeBranch } = useBranch();

  //  const branchId = props.location.state? props.location.state.id : false;

  useEffect(() => {
    setBusinessId(props.location.state ? props.location.state.id : false);
  }, [token, props.location.state]);

  useEffect(() => {
    if (businessId) {
      getBranchesActiveByBusinessApi(token, true, businessId).then(
        (response) => {
          setBranchesActive(response.branches);
          if (
            response.branches &&
            response.branches.length === 0 &&
            branch !== ""
          ) {
            changeBranch("");
          }
        }
      );
      setReloadBranches(false);
    }
  }, [token, reloadBranches]);

  useEffect(() => {
    if (businessId) {
      getBranchesActiveByBusinessApi(token, false, businessId).then(
        (response) => {
          setBranchesInactive(response.branches);
        }
      );
      setReloadBranches(false);
    }
  }, [token, reloadBranches]);

  return (
    <div className="branch">
      <ListBranches
        setReloadBranches={setReloadBranches}
        branchesActive={branchesActive}
        branchesInactive={branchesInactive}
        business={businessId}
      />
    </div>
  );
}
