import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  BackTop
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditDayForm from "../EditDayForm";
import AddDayForm from "../AddDayForm";
import { activateDayApi, deleteDayApi } from "../../../../api/day";
import { getCoursesByDayApi } from "../../../../api/course";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListDays.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListDays(props) {
  const {
    daysActive,
    daysInactive,
    setReloadDays,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewDaysActives, setViewDaysActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [daysActiveFilter, setDaysActiveFilter] = useState(
    []
  );
  const [ daysInactiveFilter, setDaysInactiveFilter ] = useState([]);

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    const listActive = [];
    if (daysActive) {
      daysActive.forEach((element) => {
          if (
            element.description
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
            element.day_code.toString()
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            listActive.push(element);
          }
      });
    }
    setDaysActiveFilter(listActive);
  }, [filterText, daysActive]);

  useEffect(() => {
    const listInactive = [];
    if (daysInactive) {
      daysInactive.forEach((element) => {
          if (
            element.description
              .toUpperCase()
              .includes(filterText.toUpperCase()) ||
            element.day_code.toString()
              .toUpperCase()
              .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
      });
    }
    setDaysInactiveFilter(listInactive);
  }, [filterText, daysInactive]);

  const addDayModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Día");
    setModalContent(
      <AddDayForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadDays={setReloadDays}
      />
    );
  };

  return (
    <div className="list-days">
      <div className="list-days__header">
        {checkAction(permissionsActive, userToken.role, "days", "all") ||
        checkAction(permissionsActive, userToken.role, "days", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-days__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewDaysActives(!viewDaysActives)
                }
              />
              <span>
                {viewDaysActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
            <>
            </>
        )}

        {checkAction(permissionsActive, userToken.role, "days", "all") ||
        checkAction(permissionsActive, userToken.role, "days", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addDayModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "days", "all") ||
      checkAction(permissionsActive, userToken.role, "days", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-days__search">
            <Search
              className="Buscar"
              allowClear
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> ejemplos de dias 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'
            </h3>
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewDaysActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "days", "all") ||
          checkAction(permissionsActive, userToken.role, "days", "view") || isAdmin(roleById) ? (
            <>
              <DaysActive
                daysActive={daysActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadDays={setReloadDays}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "days", "all") ||
          checkAction(permissionsActive, userToken.role, "days", "view") || isAdmin(roleById) ? (
            <>
              <DaysInactive
                daysInactive={daysInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadDays={setReloadDays}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>

      <BackTop/>
    </div>
  );
}

function DaysActive(props) {
  const {
    daysActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadDays,
    accessToken,
    userToken,
    permissionsActive,
    roleById,
  } = props;

  const editDay = (day) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${day.description ? day.description : "sin descripcion"}`
    );
    setModalContent(
      <EditDayForm
        day={day}
        setIsVisibleModal={setIsVisibleModal}
        setReloadDays={setReloadDays}
      />
    );
  };

  const desactivateDay = (day) => {
    activateDayApi(accessToken, day._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadDays(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (day) => {
    let response = await getCoursesByDayApi(accessToken, day._id);
    if(response.courses.length > 0){
      confirm({
        title: "Eliminando -> Día",
        content:`No se puede eliminar '${day.description? day.description : "sin descripcion"}' porque existen cursos asociados a él,¿Deseas desactivarlo?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          desactivateDay(day);
        },
      });
    } else {
      confirm({
        title: "Eliminando -> Día",
        content: `¿Estás seguro que quieres eliminar a ${day.description? day.description : "sin descripción"}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteDayApi(accessToken, day._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadDays(true);
            }else if(response.code===404){
              ModalAntd.error({
                title:"Eliminando Día",
                content:<p>{response.message}</p>,
                onOk(){},
              })

            } else if (response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
        },
      });
    }
  };

  const edit_day= (
    <div>
      {" "}
      <p>Editar Día</p>{" "}
    </div>
  );

  const desactivate_day = (
    <div>
      {" "}
      <p>Pasar a Día inactivos</p>{" "}
    </div>
  );

  const delete_day = (
    <div>
      {" "}
      <p>Eliminar Día</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Codigo",
      dataIndex: "day_code",
    },
    {
      title: "Descripcion",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, day) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "days", "all") ||
          checkAction(permissionsActive, userToken.role, "days", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_day}>
                <Button
                  className="button-edit"
                  onClick={() => editDay(day)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "days", "all") ||
          checkAction(permissionsActive, userToken.role, "days", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_day}>
                <Button
                  className="button-day"
                  type="primary"
                  onClick={() => desactivateDay(day)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "days", "all") ||
          checkAction(permissionsActive, userToken.role, "days", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_day}>
                <Button
                  className="button-day"
                  type="danger"
                  onClick={() => showDeleteConfirm(day)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return daysActive.length > 0? `Total de registros: ${daysActive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={daysActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function DaysInactive(props) {
  const { daysInactive,
          setReloadDays,
          accessToken,
          userToken,
          permissionsActive,
          roleById
        } = props;

  const activateDay = (day) => {
    activateDayApi(accessToken, day._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadDays(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (day) => {
    let response = await getCoursesByDayApi(accessToken, day._id);
    if(response.courses.length > 0){
      ModalAntd.info({
        title: "Eliminando -> Día",
        content:`No se puede eliminar '${day.description? day.description : "sin descripción"}' porque existen cursos asociados a él`,
       onOk(){}
      });
    } else {
      confirm({
        title: "Eliminando -> Día",
        content: `¿Estás seguro que quieres eliminar a ${day.description? day.description : "sin descripción"}?`,
      
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteDayApi(accessToken, day._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadDays(true);
            }else if(response.code===404){
              ModalAntd.error({
                title:"Eliminando Día",
                content:<p>{response.message}</p>,
                onOk(){},
              })

            } else if (response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
        },
      });
    }
  };

  const activate_day = (
    <div>
      {" "}
      <p>Pasar a Día activos</p>{" "}
    </div>
  );

  const delete_day = (
    <div>
      {" "}
      <p>Eliminar Día</p>
    </div>
  );

  const columns = [
    {
      title: "Codigo",
      dataIndex: "day_code",
    },
    {
      title: "Descripcion",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, day) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "days", "all") ||
          checkAction(permissionsActive, userToken.role, "days", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_day}>
                <Button
                  className="button-activate"
                  onClick={() => activateDay(day)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "days", "all") ||
          checkAction(permissionsActive, userToken.role, "days", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_day}>
                <Button
                  className="button-day"
                  type="danger"
                  onClick={() => showDeleteConfirm(day)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return daysInactive.length > 0? `Total de registros: ${daysInactive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={daysInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
