// TiendaSelector.js
import React, { useEffect, useState } from "react";
import { Select, Menu, Dropdown,Icon } from "antd";
import { useBusiness } from "../../../hooks/useBusiness";
import { getBusinessesActiveApi } from "../../../api/business";
import { getAccessToken } from "../../../api/auth";
import { DownOutlined, GlobalOutlined, PoweroffOutlined, UserOutlined } from '@ant-design/icons';
import { useBranch } from "../../../hooks/useBranch";
import "./BusinessSelector.scss";

const { Option } = Select;

export default function BusinessSelector(props) {
  const { business, changeBusiness } = useBusiness();
  const [listBusinessesActive, setListBusinessesActive] = useState([]);
  const [businessSelected, setBusinessSelected]=useState("");
  const { changeBranch } = useBranch();

  useEffect(() => {
    const accessToken = getAccessToken();
    getBusinessesActiveApi(accessToken, true).then((response) => {
      let newList = response.businesses ? response.businesses : [];
      newList.push({ _id: "0", name: "todos" });
      setListBusinessesActive(newList);
      const sucursal = newList.find(s => s._id === business);
      setBusinessSelected(sucursal ? sucursal.name : '');
    });
  }, []);

  const handleChange = (value) => {
    changeBusiness(value.key);
    changeBranch("0");
  };


  const menu = (
    <Menu onClick={handleChange}>
      {listBusinessesActive.map((sucursal) => (
             <Menu.Item key={sucursal._id}>{sucursal.name}</Menu.Item>
          ))}
    </Menu>
  );

  const spanStyle={
    color:"black",
    width:"130px",
    textAlign: "left"
  }

  return (
    <div>
      {/* {listBusinessesActive && listBusinessesActive.length > 0 ? (
        <Row>
          <Col xs={2} sm={4} md={6} lg={8} xl={10}>
            {" "}
            Negocio
          </Col>
          <Col xs={2} sm={4} md={6} lg={8} xl={10}>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona el negocio"
              value={business}
              defaultValue={0}
              onChange={(e) => changeBusiness(e)}
              optionFilterProp="children"
              style={{ width: "200%" }}
              filterOption={(input, option) =>
                option.children
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {listBusinessesActive
                ? listBusinessesActive.map((business) => {
                    return <Option key={business._id}> {business.name}</Option>;
                  })
                : null}
            </Select>{" "}
          </Col>
        </Row>
      ) : null} */}
        <Dropdown.Button className="ant-dropdown-button"  
        overlay={menu} 
        //trigger={['click']} 
        >
        <span style={spanStyle}>
          {" "} <GlobalOutlined/> {" "}{businessSelected!=="" && businessSelected!=="todos"? businessSelected : "Negocios"} 
          </span>

        </Dropdown.Button>
    </div>
  );
}
