import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Switch,
  Button,
  Row,
  Col,
  notification,
  Select,
  Checkbox,
  Table,
  Popconfirm,
  Divider
} from "antd";
import {
  TagOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
  MenuOutlined,
  DeleteFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { addVariationCategoryApi } from "../../../../api/variationCategory";
import { getAccessToken } from "../../../../api/auth";
import { arrayMoveImmutable } from "array-move";
import arrayMove from "array-move";
import { getVariationsActiveApi } from "../../../../api/variation";

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";

import "./AddVariationCategoryForm.scss";

const { Option } = Select;

export default function AddVariationCategoryForm(props) {
  const { setIsVisibleModal, setReloadVariationCategories } = props;

  const [inputs, setInputs] = useState({
    // variationCategory_code: "",
    name: "",
  });
  const [variations, setVariations]=useState([]);
  const [variationsActive, setVariationsActive] = useState([]);

  const isFormValid = (e) => {
    //console.log(inputs);
    let errorExists = false;
    if (!inputs.name) {
      notification["error"]({
        message: "Obligatorio: nombre.",
      });
      errorExists = true;
    }
    return errorExists;
  };
  useEffect(() => {
    const accessToken = getAccessToken();
    getVariationsActiveApi(accessToken, true).then((response) => {
      setVariationsActive(response.variations);
    });
  }, []);

  const addVariationCategory = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      const accessToken = getAccessToken();
      inputs.variations=variations;
      await addVariationCategoryApi(accessToken, inputs)
        .then((response) => {
          if (
            response === "ERR_CONNECTION_REFUSED" ||
            response === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              message: "Categoria de la variacion creada",
            });
            setIsVisibleModal(false);
          }
          setReloadVariationCategories(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  return (
    <div className="add-variation-category-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        addVariationCategory={addVariationCategory}
        variationsActive={variationsActive}
        variations={variations}
        setVariations={setVariations}
      />
    </div>
  );
}

function AddForm(props) {
  const { inputs, setInputs, addVariationCategory, variationsActive,
    variations, setVariations
   } = props;

  // const [dataSource, setDataSource] = useState(data);

  const changeSelectMultiple=(index, e)=>{
    let newData= {...inputs.selectMultiple};
    newData[index]=e.target.value
    setInputs({ ...inputs, selectMultiple: newData })
  }

  return (
    <Form className="form-add" onSubmit={addVariationCategory}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {/* <Col span={12}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={inputs.variationCategory_code}
              onChange={(e) => setInputs({ ...inputs, variationCategory_code: e })}
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col> */}
        <Col span={24}>
          <label className="control-label">
            <span className="control-required">*</span> Nombre de la categoria
          </label>
          <Form.Item>
            <Input
              value={inputs.name}
              onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation="left" orientationMargin="0">
      Editar categoria de topping
    </Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          Seleccionar la condición
        </Col>
        <Col span={12}>
          <Form.Item>
            <Checkbox
              checked={inputs.isRequired === true}
              onChange={(e) =>
                setInputs({ ...inputs, isRequired: e.target.value })
              }
              value={true}
            >
              Obligatorio
            </Checkbox>

            <Checkbox
              checked={inputs.isRequired === false}
              onChange={(e) =>
                setInputs({ ...inputs, isRequired: e.target.value })
              }
              value={false}
            >
              Opcional
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
            En esta categoria se pueden seleccionar
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Form.Item>
            <Checkbox
              checked={inputs.selectMultiple?inputs.selectMultiple.value === false? true: false : false}
              onChange={(e) =>
                changeSelectMultiple("value", e)
              }
              value={false}
            >
              Solo uno
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Row>
            <Form.Item>
              <Checkbox
                checked={inputs.selectMultiple? inputs.selectMultiple.value === true? true : false : false}
                onChange={(e) =>
                  changeSelectMultiple("value", e)
                }
                value={true}
              >
                Varios
              </Checkbox>
            </Form.Item>
          </Row>
          <Row>
            Min
            <Input
              style={{
                width: "20%",
              }}
              onChange={(e) =>
                changeSelectMultiple("valueMin", e)
              }
              value={inputs.selectionVariations}
            />
            Max
            <Input
              style={{
                width: "20%",
              }}
              onChange={(e) =>
                changeSelectMultiple("valueMax", e)
              }
              value={inputs.selectionVariations}
            />
          </Row>
        </Col>
      </Row>
      <Divider orientation="left" orientationMargin="0">
      Agregar los toppings a esta categoria
    </Divider>
      <Row>
        <div>
          <EditableTable dataSource={variations} setDataSource={setVariations} variationsActive={variationsActive} />
        </div>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addVariationCategory}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}

const initialData = [
  // {
  //   key: "1",
  //   variation: { name: "John Brown", price: "32" },
  //   maxQuantity: "20",
  //   index: 0,
  //   available: true,
  // },
  // {
  //   key: "2",
  //   variation: { name: "Jim Green", price: "42" },
  //   maxQuantity: "20",
  //   index: 1,
  //   available: true,
  // },
];

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

function EditableTable(props) {
  const { variationsActive, dataSource, setDataSource } = props;
  // const [dataSource, setDataSource] = useState(initialData);
  const [count, setCount] = useState(dataSource.length);
  const [showSelect, setShowSelect] = useState(false);

  const handleAdd = (data) => {
    data.key = count + 1;
    data.maxQuantity = 0;
    data.available = true;
    data.order = dataSource.length;
    // const newData = {
    //   key: count + 1,
    //   name: `New Name ${count + 1}`,
    //   maxQuantity: '0',
    //   available: true,
    // };
    setDataSource([...dataSource, data]);
    setCount(count + 1);
  };

  const handleClick = () => {
    setShowSelect(!showSelect); // Alterna la visibilidad del Select
  };

  const handleDelete = (id) => {
    setDataSource(dataSource.filter((item) => item._id !== id));
  };

  const handleSave = (key, index, data) => {
    // const newData = [...dataSource];
    // const index = newData.findIndex((item) => row.key === item.key);
    // if (index > -1) {
    //   const item = newData[index];
    //   newData.splice(index, 1, { ...item, ...row });
    //   setDataSource(newData);
    // }
    const newData = [...dataSource];
    newData[index][key] = data;
    setDataSource(newData);
  };

  // const onSortEnd = ({ oldIndex, newIndex }) => {
  //   setDataSource(arrayMove(dataSource, oldIndex, newIndex));
  // };

  const onInputChange = (key, index) => (e) => {
    const newData = [...dataSource];
    newData[index][key] = e.target.value;
    setDataSource(newData);
  };
  const DragHandle = SortableHandle(() => (
    <MenuOutlined
      style={{
        cursor: "grab",
        color: "#999",
      }}
    />
  ));

  const handleSwitchChange = (checked, key) => {
    // Actualiza el estado de la tabla
    const newData = dataSource.map((item) =>
      item.key === key ? { ...item, available: checked } : item
    );
    setDataSource(newData);
  };

  const columns = [
    {
      title: "",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Topping",
      dataIndex: "variation",
      editable: true,
      render: (text, record, index) => (
        <Input value={text.name} onChange={onInputChange("name", index)} />
      ),
    },
    {
      title: "Precio",
      dataIndex: "variation",
      editable: true,
      render: (text, record, index) => (
        // <EditableCell
        //   title="age"
        //   editable={true}
        //   children={text}
        //   record={record}
        //   index={ index}
        //   handleSave={handleSave}
        // />
        <Input value={text.price} onChange={onInputChange("price", index)} />
      ),
    },
    {
      title: "Cant. Max",
      editable: true,
      dataIndex: "maxQuantity",
      render: (text, record, index) => (
        <Input value={text} onChange={onInputChange("maxQuantity", index)} />
      ),
    },
    {
      title: "Disponible",
      editable: true,
      dataIndex: "available",
      render: (_, record) => (
        <Switch
          checked={record.available}
          onChange={(checked) => handleSwitchChange(checked, record.key)}
        />
      ),
    },
    {
      title: "",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="¿Está seguro que desea eliminarlo?"
            onConfirm={() => handleDelete(record._id)}
          >
            <DeleteFilled style={{ color: "red" }} />
          </Popconfirm>
        ) : null,
    },
  ];

  const components = {
    body: {
      row: SortableItem,
      cell: EditableCell,
    },
  };
  function sortArr(arr, order) {
    let newArr = [];
    //console.log("este devuelve", order)
    for (var i = 0; i < order.length; i++) {
      var index = arr.findIndex((x) => x.order === order[i].toString());
      //console.log("este devuelve", index)
      if (index >= 0) {
        newArr.push(arr.splice(index, 1));
      }
    }
    //newArr= updateOrderField(arr)
    return newArr.concat(arr.sort((a, b) => (a.order > b.order ? 1 : -1)));
  }

  const updateOrderField = (data) => {
    return data.map((item, index) => ({
      ...item,
      order: index + 1, // Actualiza el campo `order` basado en la posición actual
    }));
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      // const newData = arrayMove(dataSource, oldIndex, newIndex).filter(
      //   (el) => !!el,
      // );
      const newData = arrayMove(dataSource, oldIndex, newIndex)
      //const newData = sortArr(dataSource, newIndex);
      //console.log("Sorted items: ", newData);
      let newArr= updateOrderField(newData);
      setDataSource(newArr);
    }
  };
  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x) => x.order === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <div>
      <Table
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        dataSource={dataSource}
        columns={columns}
        //rowClassName="editable-row"
        className="no-border-table"
        pagination={false}
        onRow={(record, index) => ({
          index,
        })}
        bordered={false}
        onSortEnd={onSortEnd}
        useDragHandle
      />
      {showSelect ? (
        <AddVariation
          variationsActive={variationsActive}
          addVariation={handleAdd}
          handleClick={handleClick}
          setShowSelect={setShowSelect}
        />
      ) : (
        <Button
          type="dashed"
          onClick={handleClick}
          style={{ marginBottom: 16 }}
        >
          <PlusOutlined /> Agregar topping
        </Button>
      )}
    </div>
  );
}

const EditableCell = ({
  title,
  editable,
  children,
  record,
  handleSave,
  index,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(children);

  const save = () => {
    //{ ...record, [title]: value }
    handleSave(title, value, index);
    setEditing(false);
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <td {...restProps}>
      {editable ? (
        editing ? (
          <Input
            value={value}
            onChange={onChange}
            onBlur={save}
            onPressEnter={save}
          />
        ) : (
          <span onClick={() => setEditing(true)}>{value}</span>
        )
      ) : (
        children
      )}
    </td>
  );
};

function AddVariation(props) {
  const { variationsActive, addVariation, setShowSelect, handleClick } = props;
  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (value) => {
    setSelectedOption(value);
  };

  // const handleAddClick = () => {
  //   setShowSelect(!showSelect); // Alterna la visibilidad del Select
  // };

  const handleConfirmAdd = () => {
    if (selectedOption) {
      let variationSelected = variationsActive.find(
        (v) => v._id === selectedOption
      );
      const newData = {
        //key: Date.now(), // Un identificador único para cada fila
        variation: variationSelected,
      };
      addVariation(newData);
      //setDataSource([...dataSource, newData]);
      setSelectedOption(""); // Limpiar la selección después de añadir
      setShowSelect(false); // Ocultar el Select después de añadir
    }
  };

  return (
    <Row>
      <Col span={12}>
        <Select
          //prefix={<DollarCircleOutlined />}
          showSearch
          placeholder="Selecciona el ciclo"
          value={selectedOption}
          onChange={handleSelectChange}
          optionFilterProp="children"
          style={{ width: "100%" }}
          filterOption={(input, option) =>
            option.children
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {variationsActive
            ? variationsActive.map((variation) => {
                return <Option key={variation._id}> {variation.name}</Option>;
              })
            : null}
        </Select>
      </Col>
      <Col span={6}>
        <Button
          type="primary"
          onClick={handleConfirmAdd}
          style={{ marginLeft: 10 }}
        >
          Confirmar
        </Button>
      </Col>
      <Col span={6}>
        <Button
          type="primary"
          onClick={handleClick}
          style={{ marginBottom: 16 }}
          danger
        >
          Cancelar
        </Button>
      </Col>
    </Row>
  );
}
