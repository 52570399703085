import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getCategoriesActiveApi } from "../../../api/billing/category";
import ListCategories from "../../../components/Billing/Categories/ListCategories";

export default function Categories() {
    const [categoriesActive, setCategoriesActive] = useState([]);
    const [categoriesInactive, setCategoriesInactive] = useState([]);
    const [reloadCategories, setReloadCategories ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getCategoriesActiveApi(token, true).then(response => {
            setCategoriesActive(response.categories);
        });
        getCategoriesActiveApi(token, false).then(response => {
            setCategoriesInactive(response.categories);
        });
        setReloadCategories(false);
    }, [token, reloadCategories]);

    return (
        <div className="categories">

            <ListCategories categoriesActive={categoriesActive} categoriesInactive={categoriesInactive} setReloadCategories={setReloadCategories} 
                />
        
        
        </div>
    );
}