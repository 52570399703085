import React, { useState, useEffect } from "react";
import {
  Form,
  //Input,
  //InputNumber,
  Select,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import { updateBuyBillApi } from "../../../../api/billing/buyBill"; //paidStateBuyBillApi
import { getStateBillsActiveApi } from "../../../../api/billing/stateBill";
import { getAccessToken } from "../../../../api/auth";

import "./EditStateForm.scss";
//import { convertState } from "../../../../utils/convert";
//import TextArea from "antd/lib/input/TextArea";

export default function EditStateForm(props) {
  const { buyBill, bpaid, setIsVisibleModal, setReloadBuyBills } = props;
  const [buyBillData, setBuyBillData] = useState({});
  const [activeStateBills, setActiveStateBills] = useState([]);
  const accessToken = getAccessToken();

  useEffect(() => {
    getStateBillsActiveApi(accessToken, true).then((response) => {
      setActiveStateBills(response.stateBills);
    });
  }, []);

  useEffect(() => {
    setBuyBillData({
      id: buyBill._id,
      state: buyBill.state? buyBill.state._id : null,
    });
  }, [buyBill]);


  /*
  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!billBuyData.state) {
      notification["error"]({
        message: "Cambiar el estado es obligatorio.",
      });
      errorExists = true;
    }
    return errorExists;
  };*/

  /*
  const updateState = async (e) => {
    e.preventDefault();
    //const error = isFormValid();
    //if (!error) {
    const id = billBuy._id;
    let paid = billBuy.paid; //es let porque se va actualizando
    let state = billBuyData.state;
    //let billBuyUpdate = billBuyData;

    await paidStateBillBuyApi(accessToken, id, paid, state).then((result) => {
      if (
        result.message === "Error del servidor"
      ) {
        notification["error"]({
          message: "Servidor caido",
        });
        setIsVisibleModal(true);
      //} else if (result.code !== 200) {
      //  notification["error"]({
      //    message: result.message,
      //  });
      //  setIsVisibleModal(true);
      } else {
        notification["success"]({
          message: result.message, //el mensaje que viene del server
        });
        //console.log("respuesta: " + result.message);
        setIsVisibleModal(false);
        //setReloadBillBuys(true);
      }
    });
  };*/

const updateStatePaid = async (p) => {
  //e.preventDefault();
  //const error = isFormValid();
  //if (!error) {
    const token = getAccessToken();
    let buyBillUpdate = buyBillData; //es let porque se va actualizando
    buyBillUpdate.paid = p;

    await updateBuyBillApi(token, buyBillUpdate, buyBill._id).then((result) => {
      if (
        result.message === "ERR_CONNECTION_REFUSED" ||
        result.message === "Failed to fetch" ||
        result.message === undefined
      ) {
        notification["error"]({
          message: "Servidor caido",
        });
        setIsVisibleModal(true);
      } else if (result.code !== 200) {
        notification["error"]({
          message: result.message,
        });
        setIsVisibleModal(true);
      } else {
        notification["success"]({
          message: result.message, //el mensaje que viene del server
        });
        //console.log("respuesta: " + result.message);
        setIsVisibleModal(false);
        setReloadBuyBills(true);
      }
    });
  //}
};

return (
  <div className="edit-buybill-form">
    <EditForm
      buyBillData={buyBillData}
      bpaid={bpaid}
      setBuyBillData={setBuyBillData}
      updateStatePaid={updateStatePaid}
      activeStateBills={activeStateBills}
    />
  </div>
);
}

function EditForm(props) {
  const { updateStatePaid, buyBillData, setBuyBillData, activeStateBills } = props;
  const { Option } = Select;

  /*
  var advice;
  if (bpaid === true) {
    advice = <h4 className="h4-paid">La factura pasará a la lista de pagadas</h4>;
  } else {
    advice = <h4 className="h4-unpaid">La factura pasará a la lista de no pagadas</h4>;
  }*/

  var options;
  /*
  if (bpaid === true) {
    //billBuyData.state = "valid";
    options = <Select
                name="state"
                value={convertState(billBuyData.state)}
                onChange={(e) =>
                setBillBuyData({ ...billBuyData, state: e })
                }
                required
                >
                <Option value="valid">Vigente</Option>
              </Select>;
  } else {
    options = <Select
                name="state"
                value={convertState(billBuyData.state)}
                onChange={(e) =>
                setBillBuyData({ ...billBuyData, state: e })
                }
                required
                >
                <Option value="rejected">Rechazada</Option>
                <Option value="cancelled">Cancelada</Option>
                <Option value="owes">Adeuda</Option>
              </Select>;
  }*/

  options = <Select
              name="state"
              //value={convertState(buyBillData.state)}
              value={buyBillData.state}
              onChange={(e) =>
              setBuyBillData({ ...buyBillData, state: e })
              }
              required
              >
              {activeStateBills
                ? activeStateBills.map((i) => {
                    return <Option key={i._id}>{i.name}</Option>;
                  })
                : "No hay estados disponibles"}
            </Select>;
            //onSubmit={updateStatePaid}

  return (
    <Form className="form-edit" >

      <Row gutter={24}>
        <Col span={24}>
          <label className="control-label">Modifique el estado de la compra</label>
          <Form.Item>
            {options}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item>
            <Button
              htmlType="submit"
              className="bg-success"
              onClick={() => updateStatePaid(true)}
            >
              Pagada
            </Button>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            <Button
              type="danger"
              onClick={() => updateStatePaid(false)}
            >
              No pagada
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
