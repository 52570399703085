import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
// import { getItemsActiveApi } from "../../../api/item";
import ListBranchItems from "../../../components/Admin/Items/ListBranchItems";

import "./Items.scss";
import { useBranch } from "../../../hooks/useBranch";

export default function BranchItems() {
    const [itemsActive, setItemsActive] = useState([]);
    const [itemsInactive, setItemsInactive] = useState([]);
    const [reloadItems, setReloadItems ] = useState(false);
    const token = getAccessToken();

    const { branch } = useBranch();

    useEffect(() => {
        // getItemsActiveApi(token, true).then(response => {
        //     setItemsActive(response.items);
        // });
        // getItemsActiveApi(token, false).then(response => {
        //     setItemsInactive(response.items);
        // });
        // setReloadItems(false);
    }, [token, reloadItems]);

    
  useEffect(() => {
    if(branch && (branch===0 || branch==="0")){
     return window.location.href = "/admin/digitalmenu/items";
    }
  }, [branch]);

    return (
        <div className="items">

            <ListBranchItems itemsActive={itemsActive} itemsInactive={itemsInactive} setReloadItems={setReloadItems} 
                />
        
        
        </div>
    );
}