import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
/*
import { 
    //getAbsencesActiveApi, 
    getAbsencesPaginateApi 
} from "../../../api/absence";
*/
import { 
    //getAbsencesActiveApi, 
    getBranchApi 
} from "../../../api/branch";
import AddEditBranchFormPage from "../../../components/Admin/Branches/AddEditBranchFormPage";
// import EditSocialMediaForm from "../../../components/Admin/Items/EditSocialMedia/EditSocialMediaForm";


export default function AddEditBranch(props) {
     //const {setReloadBranch}=props;
     const [branchId, setBranchId] = useState();
     const [reloadBranch, setReloadBranch ] = useState(false);
     const [branch, setBranch ] = useState({});
     const token = getAccessToken();
    //  const branchId = props.location.state? props.location.state.id : false;

    // useEffect(() => {
    //     if(paginationActive.schoolYear !== null && paginationActive.schoolYear !==  undefined){
    //         getAbsencesPaginateApi(token, true, 50, paginationActive.page, paginationActive.filter, paginationActive.schoolYear).then(response => {
    //             setAbsencesActive(response.absences);
    //         });
    //         setReloadAbsences(false);
    //     }
    // }, [token, paginationActive, reloadAbsences]);

    // useEffect(() => {
    //     if(paginationInactive.schoolYear !== null && paginationInactive.schoolYear !==  undefined){
    //         getAbsencesPaginateApi(token, false, 50, paginationInactive.page, paginationInactive.filter, paginationActive.schoolYear).then(response => {
    //             setAbsencesInactive(response.absences);
    //         });
    //         setReloadAbsences(false);
    //     }
    // }, [token, paginationInactive, reloadAbsences]);

    useEffect(() => {
      setBranchId( props.location.state? props.location.state.id : false);
    }, [token, props.location.state]);

    useEffect(() => {
      let isMounted=true;
        if (branchId) {
          getBranchApi(token, branchId).then(
              (response) => {
                if(isMounted){
                  setBranch(response.branch);
                }
              }
          );
        }
        setReloadBranch(false);
        return ()=>{
          isMounted=false;
      }
    }, [token, reloadBranch, branchId]);

    

    return (
        <div className="branch">
            <AddEditBranchFormPage setReloadBranch={setReloadBranch} branch={branch} branchId={branchId} setBranchId={setBranchId}/>
            {/* <EditSocialMediaForm setReloadStore={setReloadStore} branch={branch} branchId={branchId} setBranchId={setBranchId}/> */}
        </div>
    );
}