import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getVariationCategoriesActiveApi } from "../../../api/variationCategory";
import ListVariationCategories from "../../../components/Admin/VariationCategories/ListVariationCategories";

import "./VariationCategories.scss";

export default function VariationCategories() {
  const [variationCategoriesActive, setVariationCategoriesActive] = useState(
    []
  );
  const [variationCategoriesInactive, setVariationCategoriesInactive] =
    useState([]);
  const [reloadVariationCAtegories, setReloadVariationCategories] =
    useState(false);
  const token = getAccessToken();

  useEffect(() => {
    getVariationCategoriesActiveApi(token, true).then((response) => {
      setVariationCategoriesActive(response.variationCategories);
    });
    getVariationCategoriesActiveApi(token, false).then((response) => {
      setVariationCategoriesInactive(response.variationCategories);
    });
    setReloadVariationCategories(false);
  }, [token, reloadVariationCAtegories]);

  return (
    <div className="variations">
      <ListVariationCategories
        variationCategoriesActive={variationCategoriesActive}
        variationCategoriesInactive={variationCategoriesInactive}
        setReloadVariationCategories={setReloadVariationCategories}
      />
    </div>
  );
}
