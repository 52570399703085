import React, { useState } from "react";

import { Button, Tag, DatePicker, Row, Col} from "antd";
import moment from "moment";

import { SearchOutlined, CalendarOutlined } from "@ant-design/icons";
//import formatDateView from "../../../utils/formatDate";
import Modal from "../../Modal";

import "./SearchByDates.scss";

const { RangePicker } = DatePicker;

export default function SearchByDates(props) {
  const { searchList, setSearchList, dataSearch, setReload } = props;

  const [initDate, setInitDate] = useState("");
  const [finishDate, setFinishDate] = useState("");
  const [titleTag, setTitleTag]= useState("");

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isIsVisibleTag, setIsVisibleTag] = useState(false);

  const searchByDates = () => {
    setReload(true);
    setIsVisibleTag(false);
    setIsVisibleModal(true);
  };

  const search = () => {
    if(initDate && finishDate){
      let newList = searchList.filter(
        (data) =>
          // moment(data.date).isSameOrAfter(moment(initDate).subtract(1,"days")) &&
          // moment(data.date).isSameOrBefore(moment(finishDate).add(1,"days"))
          moment(data[dataSearch]).isSameOrAfter(moment(initDate).subtract(1,"days")) &&
          moment(data[dataSearch]).isSameOrBefore(moment(finishDate))
      );
      setSearchList(newList);
      setIsVisibleTag(true);
    }
    setIsVisibleModal(false);
  };

  function closedTag(e) {
    setInitDate("");
    setFinishDate("");
    setIsVisibleTag(false);
    setReload(true);
  }

  const setDates = (dates, datesString) => {
    setInitDate(dates[0]);
    setFinishDate(dates[1]);
    setTitleTag(`${datesString[0]} al ${datesString[1]}`);
  };

  return (
    <div>
    <div className="search-date">
      <Button
        className="button-search"
        type="default"
        icon={<CalendarOutlined />}
        onClick={searchByDates}
      >
      </Button>

      <Modal
        title="Buscar por fechas"
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
      >
        <RangePicker
          ranges={{
            Today: [moment(), moment()],
          }}
          format="DD/MM/YYYY"
          placeholder={["desde", "hasta"]}
          onChange={setDates}
          required={true}
        />
        <Button type="primary" onClick={search}>
          Buscar
        </Button>
      </Modal>
    </div>
        <div className="search-date__tag">
          <Row>
            <Col span={2}>
              {isIsVisibleTag ? (
                <Tag color="blue" closable onClose={closedTag}>
                  {titleTag}
                </Tag>
              ) : null}
            </Col>
            </Row>
         </div>
      </div>

  );
}
