import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getBillSellsActiveApi, getBillSellsPaidApi } from "../../../api/billing/billsell";
import ListBillSells from "../../../components/Billing/BillSells/ListBillSells";

import "./BillSells.scss";

export default function BillSells() {
    const [billSellsActive, setBillSellsActive] = useState([]);
    const [billSellsInactive, setBillSellsInactive] = useState([]);
    const [billSellsPaid, setBillSellsPaid] = useState([]);
    const [billSellsUnpaid, setBillSellsUnpaid] = useState([]);
    const [ reloadBillSells, setReloadBillSells ] = useState(false);
    const token = getAccessToken();
    //console.log('billSellsActive', billSellsActive);
    //console.log('billSellsInactive', billSellsInactive);

    useEffect(() => {
        getBillSellsActiveApi(token, true).then(response => {
            setBillSellsActive(response.billSells);
        });
        getBillSellsActiveApi(token, false).then(response => {
            setBillSellsInactive(response.billSells);
        });
        setReloadBillSells(false);
    }, [token, reloadBillSells]);

    useEffect(() => {
        getBillSellsPaidApi(token, true).then(response => {
            setBillSellsPaid(response.billSells);
        });
        getBillSellsPaidApi(token, false).then(response => {
            setBillSellsUnpaid(response.billSells);
        });
        setReloadBillSells(false);
    }, [token, reloadBillSells]);

    return (
        <div className="billsells">
            <ListBillSells billSellsActive={billSellsActive} billSellsInactive={billSellsInactive}
                            billSellsPaid={billSellsPaid} billSellsUnpaid={billSellsUnpaid}
                            setReloadBillSells={setReloadBillSells} token={token} />
        </div>
    );
}