import React, { useState, useEffect } from "react";
import { Form, Input, Select, Button, Row, Col, notification } from "antd";

import jwtDecode from "jwt-decode";

import {
  getStockInventoriesApi,
  updateAddStockInventoryApi,
  updateSubStockInventoryApi,
} from "../../../../api/billing/stockInventory";
import { getAccessToken } from "../../../../api/auth";
import { addMovementApi } from "../../../../api/billing/movement";
import { getBuyBillsApi } from "../../../../api/billing/buyBill";
import { getBillSellsApi } from "../../../../api/billing/billsell";

import "./AddMovementForm.scss";

const { TextArea } = Input;

export default function AddMovementForm(props) {
  const { setIsVisibleModal, setReloadMovements } = props;

  const [inventorySelected, setInventorySelected] = useState("");
  const [listBills, setListBills] = useState([]);

  const [inputs, setInputs] = useState({
    date: "",
    movement_type: "",
    item: "",
    origin: "",
    buyBill: "",
    billSell: "",
    quantity: "",
    observation: "",
    user: "",
  });

  const [formValid, setFormValid] = useState({
    date: false,
    movement_type: false,
    item: false,
    origin: false,
  });

  const [listStockInventory, setListStockInventory] = useState([]);

  useEffect(() => {
    getStockInventoriesApi(getAccessToken()).then((response) => {
      if (response.stockInventories) {
        setListStockInventory(response.stockInventories);
      } else {
        notification["error"]({
          message: "Primero debe agregar un inventario",
        });
      }
    });
  }, [listStockInventory]);

  const isFormValid = (e) => {
    let errorExists = false;
    if (
      !inputs.date ||
      !inputs.movement_type ||
      !inputs.item ||
      !inputs.origin ||
      !inputs.quantity
    ) {
      notification["error"]({
        message: "Obligatorios: fecha, tipo, producto, origen, cantidad",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addMovement = async (e) => {
    // try{
    e.preventDefault();

    //Busco el item del inventario stock seleccionado
    let inventoryData = listStockInventory.filter(
      (i) => i._id === inventorySelected
    )[0];
    inputs.item = inventoryData.item._id;

    //Verifico los datos ingresados, en primer lugar actualizo stock y luego genero el movimiento
    if (!isFormValid()) {
      const accessToken = getAccessToken();
      let data = {
        quantity: inputs.quantity,
      };

      if (inputs.movement_type === "input") {
        await updateAddStockInventoryApi(accessToken, inventorySelected, data)
          .then((response) => {
            if (
              response.message === "ERR_CONNECTION_REFUSED" ||
              response.message === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
              return false;
            } else if (response.code !== 200) {
              notification["error"]({
                message: response.message,
              });
              return false;
            } else {
              notification["success"]({
                message: "Stock actualizado",
              });
              inputs.user = jwtDecode(accessToken).id;
              return addMovementApi(accessToken, inputs);
            }
          })
          .then((response) => {
            if (response) {
              if (
                response.message === "ERR_CONNECTION_REFUSED" ||
                response.message === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else if (response.code !== 200) {
                notification["error"]({
                  message: response.message,
                });
              } else {
                notification["success"]({
                  message: "Movimiento creado",
                });
                setIsVisibleModal(false);
              }
              setReloadMovements(true);
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      } else if (inputs.movement_type === "output") {
        await updateSubStockInventoryApi(accessToken, inventorySelected, data)
          .then((response) => {
            if (
              response.message === "ERR_CONNECTION_REFUSED" ||
              response.message === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else if (response.code !== 200) {
              notification["error"]({
                message: response.message,
              });
            } else {
              notification["success"]({
                message: "Stock actualizado",
              });
              inputs.user = jwtDecode(accessToken).id;
              return addMovementApi(accessToken, inputs);
            }
          })
          .then((response) => {
            if (response) {
              if (
                response.message === "ERR_CONNECTION_REFUSED" ||
                response.message === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else if (response.code !== 200) {
                notification["error"]({
                  message: response.message,
                });
              } else {
                notification["success"]({
                  message: "Movimiento creado",
                });
                setIsVisibleModal(false);
              }
              setReloadMovements(true);
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      }
    }
  };

  return (
    <div className="add-movement-form">
      <AddForm
        listStockInventory={listStockInventory}
        inputs={inputs}
        setInputs={setInputs}
        addMovement={addMovement}
        inventorySelected={inventorySelected}
        setInventorySelected={setInventorySelected}
        listBills={listBills}
        setListBills={setListBills}
      />
    </div>
  );
}

function AddForm(props) {
  const {
    listStockInventory,
    inputs,
    setInputs,
    inventorySelected,
    setInventorySelected,
    addMovement,
    listBills,
    setListBills,
  } = props;
  const { Option } = Select;

  // useEffect(() => {
  //   let accessToken = getAccessToken();
  //   if (inputs.origin === "billBuy") {
  //     getBillBuysApi(accessToken).then((response) => {
  //       setListBills(response.billBuys);
  //     });
  //   } else if (inputs.origin === "billSell") {
  //     getBillSellsApi(accessToken).then((response) => {
  //       setListBills(response.billSells);
  //     });
  //   }
  // }, [inputs.origin]);

  return (
    <Form className="form-add" onSubmit={addMovement}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Tipo
          </label>
          <Form.Item>
            <Select
              className="select-form"
              placeholder="Selecciona tipo de movimiento"
              onChange={(e) => setInputs({ ...inputs, movement_type: e })}
              value={inputs.movement_type}
            >
              <Option value="input">Entrada</Option>
              <Option value="output">Salida</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span>Fecha
          </label>
          <Form.Item>
            <Input
              type="date"
              placeholder="Fecha"
              value={inputs.date}
              onChange={(e) => setInputs({ ...inputs, date: e.target.value })}
              required
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span>Origen
          </label>
          <Form.Item>
            <Select
              className="select-form"
              placeholder="Selecciona origen"
              onChange={(e) => setInputs({ ...inputs, origin: e })}
              value={inputs.origin}
            >
              {/* <Option value="inventory">Inventario</Option> */}
              <Option value="adjustment">Ajuste a Inventario</Option>
              <Option value="transfer">Traslado</Option>
              {/* {inputs.movement_type === "input" ? (
                <Option value="billbuy">Factura de Compra</Option>
              ) : null}
              {inputs.movement_type === "output" ? (
                <Option value="billsell">Factura de Venta</Option>
              ) : null}
              {inputs.movement_type === "output" ? (
                <Option value="canceledBuy">Cancelacion Compra</Option>
              ) : null}
              {inputs.movement_type === "input" ? (
                <Option value="canceledSell">Cancelacion Venta</Option>
              ) : null} */}
            </Select>
          </Form.Item>
        </Col>
        {/* {inputs.origin === "billBuy" ? (
          <Col span={12}>
            <label className="control-label">
              <span className="control-required">*</span> Codigo Factura{" "}
            </label>
            <Form.Item>
              <Select
                showSearch
                className="select-form"
                placeholder="Selecciona el Factura"
                onChange={(e) => setInputs({ ...inputs, billBuy: e })}
                value={inputs.billBuy}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {listBills.map((b) => (
                  <Option key={b._id}>{`${b.billbuy_code}`}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        ) : null}
        {inputs.origin === "billSell" ? (
          <Col span={12}>
            <label className="control-label">
              <span className="control-required">*</span> Codigo Factura{" "}
            </label>
            <Form.Item>
              <Select
                showSearch
                className="select-form"
                placeholder="Selecciona el Factura"
                onChange={(e) => setInputs({ ...inputs, billSell: e })}
                value={inputs.billSell}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {listBills.map((b) => (
                  <Option key={b._id}>{`${b.billsell_code}`}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        ) : null} */}
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span>Producto
          </label>
          <Form.Item>
            <Select
              showSearch
              className="select-form"
              placeholder="Selecciona el producto"
              onChange={(e) => setInventorySelected(e)}
              value={inventorySelected}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listStockInventory.map((d) => (
                <Option
                  key={d._id}
                >{`${d.item.item_code} - ${d.item.description}`}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Cantidad
          </label>
          <Form.Item>
            <Input
              name="quantity_purchased"
              placeholder="Cantidad"
              value={inputs.quantity}
              onChange={(e) =>
                setInputs({ ...inputs, quantity: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">Observaciones</label>
          <Form.Item>
            <TextArea
              placeholder="Observaciones"
              value={inputs.observation}
              onChange={(e) =>
                setInputs({ ...inputs, observation: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addMovement}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
