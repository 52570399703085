import { switchPath, apiVersion } from './config';

/**FUNCIONES PARA MANEJO DEL MODULO NEGOCIO QUE CONSUMEN LOS ENDPOINTS DEL SERVER*/

export function getBusinessExportApi(token) {
    const url = `${switchPath()}/${apiVersion}/business-export-csv`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "text/csv",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.text();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'bunisess.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(err => {
            return err.message;
        });
}

export function postBusinessImportApi(token, file) {
    const url = `${switchPath()}/${apiVersion}/businnes-import-csv`;
    const formData = new FormData();
    formData.append("file", file, file.name);

    const params = {
        method: "POST",
        body: formData,
        headers: {
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch(err => {
            return err.message;
        });
}

export function addBusinessApi(token, data){
    const url = `${switchPath()}/${apiVersion}/add-business`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
    };

    return fetch(url, params)
        .then(response => {
            //console.log(response);
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return {
                ok: false,
                message: err.message
            };
        });
}

export function getBusinessesApi(token) {
    const url = `${switchPath()}/${apiVersion}/businesses`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function geTBusinessesPaginateApi(token,state, limit, page, filter) {
    const url = `${switchPath()}/${apiVersion}/businesses-paginate?state=${state}&limit=${limit}&page=${page}&filter=${filter}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getBusinessesActiveApi(token, status) {
    const url = `${switchPath()}/${apiVersion}/businesses-active?active=${status}`;
    //console.log(url);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function updateBusinessApi(token, businessData, businessId) {
    const url = `${switchPath()}/${apiVersion}/update-business/${businessId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(businessData)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function getBusinessApi(token, businessId) {
    const url = `${switchPath()}/${apiVersion}/business/${businessId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function activateBusinessApi(token, businessId, status) {
    const url = `${switchPath()}/${apiVersion}/activate-business/${businessId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            active: status
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result.message;
        })
        .catch(err => {
            return err.message;
        });
}

export function deleteBusinessApi(token, businessId) {
    const url = `${switchPath()}/${apiVersion}/delete-business/${businessId}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function uploadLogoApi(token, data, businessId) {
    const url = `${switchPath()}/${apiVersion}/upload-logo-business/${businessId}`;

    const params = {
        method: "POST",
        body: data,
        headers: {
            //'Content-Type': 'multipart/form-data',
            Authorization: token
        }
    }
    return fetch(url, params)
        .then(response => {
            return response;
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getLogoApi(logoName) {
    const url = `${switchPath()}/${apiVersion}/get-logo-business/${logoName}`;
    return fetch(url)
    .then(response => {
        return response.url;
    })
    .catch(err => {
        return err.message;
    });
}

export function uploadCoverApi(token, data, businessId) {
    const url = `${switchPath()}/${apiVersion}/upload-cover-business/${businessId}`;
    const params = {
        method: "POST",
        body: data,
        headers: {
            //'Content-Type': 'multipart/form-data',
            Authorization: token
        }
    }
    return fetch(url, params)
        .then(response => {
            return response;
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getCoverApi(coverName) {
    const url = `${switchPath()}/${apiVersion}/get-cover-business/${coverName}`;
    return fetch(url)
    .then(response => {
        return response.url;
    })
    .catch(err => {
        return err.message;
    });
}