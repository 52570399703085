import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getDeliveryTermsActiveApi } from "../../../api/deliveryTerm";
import ListDeliveryTerms from "../../../components/Billing/DeliveryTerms/ListDeliveryTerms";


export default function DeliveryTerms() {
    const [deliveryTermsActive, setDeliveryTermsActive] = useState([]);
    const [deliveryTermsInactive, setDeliveryTermsInactive] = useState([]);
    const [reloadDeliveryTerms, setReloadDeliveryTerms ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getDeliveryTermsActiveApi(token, true).then(response => {
            setDeliveryTermsActive(response.deliveryTerms);
        });
        getDeliveryTermsActiveApi(token, false).then(response => {
            setDeliveryTermsInactive(response.deliveryTerms);
        });
        setReloadDeliveryTerms(false);
    }, [token, reloadDeliveryTerms]);

    return (
        <div className="deliveryTerms">

            <ListDeliveryTerms deliveryTermsActive={deliveryTermsActive} deliveryTermsInactive={deliveryTermsInactive} setReloadDeliveryTerms={setReloadDeliveryTerms}
                />

        </div>
    );
}