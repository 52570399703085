import React, { useState, useEffect } from "react";

import {
  Switch,
  Table,
  Button,
  Modal as ModalAntd,
  notification,
} from "antd";
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import {
  EditOutlined,
  DeleteOutlined,
  MenuOutlined,
  PlusOutlined
} from "@ant-design/icons";

import Modal from "../../../Modal";
import { convertModulesEngToEsp } from "../../../../utils/convert"; //convertActionsEngToEsp

// import DragSortable from "./DragSortable";

import arrayMove from 'array-move';

// import DragSortableList from "react-drag-sortable";

import "./ListMenu.scss";
import { getAccessToken } from "../../../../api/auth";
import {
  activateMenuApi,
  updateMenuApi,
  deleteMenuApi,
} from "../../../../api/menu";

import AddMenuForm from "../AddMenuForm";

import EditMenuForm from "../EditMenuForm";

const { confirm } = ModalAntd;

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);


export default function ListMenu(props) {
  const { menu, setReloadMenu } = props;
  //listado de items
  const [listItems, setListItems] = useState([]);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  useEffect(() => {
    //lista que almacena los menus
    menu.map((item,index) => {
      item.index=index;
      return true;
    });
    setListItems(menu);
  }, [menu]);

  //Permite activar el menu
  const activateMenu = (menu, status) => {
    const accessToken = getAccessToken();
    activateMenuApi(accessToken, menu._id, status).then((response) => {
      notification.success({ message: response.message });
    });
  };

  const addMenuModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Menu");
    setModalContent(
      <AddMenuForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadMenu={setReloadMenu}
      />
    );
  };

  //Editar el menu seleccionado
  const editMenuModal = (menu) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar ${menu.title}`);
    setModalContent(
      <EditMenuForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadMenu={setReloadMenu}
        menu={menu}
      />
    );
  };

  const deleteMenu = (menu) => {
    const accesToken = getAccessToken();

    confirm({
      title: "Eliminando Menu",
      content: `¿Estas seguro que quieres eliminar a ${menu.title}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteMenuApi(accesToken, menu._id)
          .then((response) => {
            notification.success({
              message: response.message,
            });
            setReloadMenu(true);
          })
          .catch((err) => {
            notification.error({
              message: err,
            });
          });
      },
    });
  };

  return (
    <div className="list-menu">
      <div className="list-menu__header">
        <Button type="primary" icon={<PlusOutlined/>}onClick={addMenuModal}>
          Nuevo
        </Button>
      </div>

      <div className="list-menu__items">
            <MenuItems
              items={listItems}
              setItems={setListItems}
              activateMenu={activateMenu}
              editMenuModal={editMenuModal}
              deleteMenu={deleteMenu}
            />
        {/* /> */}
      </div>

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function MenuItems(props){
  const { items, setItems, activateMenu, editMenuModal, deleteMenu } = props;

  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: "Nombre Modulo",
      dataIndex: "nameModule",
      className: 'drag-visible',
      render: (name)=>(
        convertModulesEngToEsp(name)
      )
    },
    {
      title: "Titulo",
      dataIndex: "title",
    },
    {
      title: "url",
      dataIndex: "url",
    },
    {
      title: "Superior",
      dataIndex: "higher",
      render:(item)=>(item? item.title :  null),
    },
    {
      title: "Accion",
      key: "action",
      render: (text, item) => (
        <div>
          <>
            <Switch 
            className="item-button"
            defaultChecked={item.active}
            onChange={(e) => activateMenu(item, e)}
          />
          </>
          <>
          <Button type="primary" className="item-button" onClick={() => editMenuModal(item)}>
            <EditOutlined/>
          </Button>
          </>
          <>
          <Button type="danger" className="item-button" onClick={() => deleteMenu(item)}>
             <DeleteOutlined/>
          </Button>
          </>
        </div>
      ),
    },
  ];

  return (
    <DragSortable 
      data={items}
      setData={setItems}
      columns={columns}
    />
  );
}

function DragSortable(props) {

  const {data, setData, columns}=props;

  // const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

  // const SortableItem = sortableElement(props => <tr {...props} />);
  // const SortableContainer = sortableContainer(props => <tbody {...props} />);

  const onSortEnd = ( {oldIndex, newIndex} ) => {
    const accessToken=getAccessToken();
    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(data), oldIndex, newIndex).filter(el => !!el);
      newData.forEach((item, index)=>{
      const {_id}=item;
      const order=index + 1;
      item.order=order;
      updateMenuApi(accessToken,_id,{ order });
  });
      setData( newData );
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // const { dataSource } = this.state;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = data.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

    return (
      <Table
        footer={()=>{return data.length > 0? `Total de registros: ${data.length}`: false}}
        pagination={{
          pageSize: 50,
          showSizeChanger: false,
        }}
        dataSource={data}
        columns={columns}
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
    );
}