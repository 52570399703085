import React, { useState } from "react";
import { Form, Input, InputNumber, Button, Row, Col, notification } from "antd";
import {
  TagOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { addStateBillApi } from "../../../../api/billing/stateBill";
import { getAccessToken } from "../../../../api/auth";
import { numberValidation } from "../../../../utils/formValidation";

import "./AddStateBillForm.scss";

export default function AddStateBillForm(props) {
  const { setIsVisibleModal, setReloadStateBills } = props;

  const [inputs, setInputs] = useState({
    //seller_code: "",
    name: "",
  });

  const [formValid, setFormValid] = useState({
    phone: false,
  });

  const inputValidation = (e) => {
    const { type, name } = e.target;

    setInputs({
      ...inputs,
      [name]: e.target.value,
    });
    if (name === "phone") {
      setFormValid({
        ...formValid,
        [name]: numberValidation(e.target),
      });
    }
  };

  const isFormValid = (e) => {
    //console.log(inputs);
    let errorExists = false;
    if (!inputs.name) {
      notification["error"]({
        message: "Obligatorio: nombre.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addStateBill = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      const accessToken = getAccessToken();

      await addStateBillApi(accessToken, inputs)
        .then((response) => {
          if (
            response === "ERR_CONNECTION_REFUSED" ||
            response === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              message: "Estado creado",
            });
            setIsVisibleModal(false);
          }
          setReloadStateBills(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  return (
    <div className="add-statebill-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        inputValidation={inputValidation}
        addStateBill={addStateBill}
      />
    </div>
  );
}

function AddForm(props) {
  const { inputs, setInputs, addStateBill, inputValidation } = props;

  return (
    <Form className="form-add" onSubmit={addStateBill}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
            <span className="control-required">*</span> Nombre
          </label>
          <Form.Item>
            <Input
              placeholder="Nombre"
              value={inputs.name}
              onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addStateBill}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
