import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getSuppliersActiveApi } from "../../../api/billing/supplier";
import ListSuppliers from "../../../components/Billing/Suppliers/ListSuppliers";

import "./Suppliers.scss";

export default function Suppliers() {
    const [suppliersActive, setSuppliersActive] = useState([]);
    const [suppliersInactive, setSuppliersInactive] = useState([]);
    const [ reloadSuppliers, setReloadSuppliers ] = useState(false);
    const token = getAccessToken();
    //console.log('suppliersActive', suppliersActive);
    //console.log('suppliersInactive', suppliersInactive);

    useEffect(() => {
        getSuppliersActiveApi(token, true).then(response => {
            setSuppliersActive(response.suppliers);
        });
        getSuppliersActiveApi(token, false).then(response => {
            setSuppliersInactive(response.suppliers);
        });
        setReloadSuppliers(false);
    }, [token, reloadSuppliers]);

    return (
        <div className="suppliers">
            <ListSuppliers suppliersActive={suppliersActive} suppliersInactive={suppliersInactive} setReloadSuppliers={setReloadSuppliers} />
        </div>
    );
}
