import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getPaymentTypesActiveApi } from "../../../api/billing/paymentType";
import ListPaymentTypes from "../../../components/Billing/PaymentTypes/ListPaymentTypes";


export default function PaymentTypes() {
    const [paymentTypesActive, setPaymentTypesActive] = useState([]);
    const [paymentTypesInactive, setPaymentTypesInactive] = useState([]);
    const [reloadPaymentTypes, setReloadPaymentTypes ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getPaymentTypesActiveApi(token, true).then(response => {
            setPaymentTypesActive(response.paymentTypes);
        });
        getPaymentTypesActiveApi(token, false).then(response => {
            setPaymentTypesInactive(response.paymentTypes);
        });
        setReloadPaymentTypes(false);
    }, [token, reloadPaymentTypes]);

    return (
        <div className="paymentTypes">

            <ListPaymentTypes paymentTypesActive={paymentTypesActive} paymentTypesInactive={paymentTypesInactive} setReloadPaymentTypes={setReloadPaymentTypes}
                />

        </div>
    );
}