import React, { useState, useEffect } from "react";
import {
  Form,
  //Input,
  //InputNumber,
  Select,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import { updateBuyOrderApi } from "../../../../api/billing/buyOrder"; //paidStateBuyBillApi
import { getStateBuyOrdersActiveApi } from "../../../../api/billing/stateBuyOrder";
import { getAccessToken } from "../../../../api/auth";

import "./EditStateForm.scss";
//import { convertState } from "../../../../utils/convert";
//import TextArea from "antd/lib/input/TextArea";

export default function EditStateForm(props) {
  const { buyOrder, bpaid, setIsVisibleModal, setReloadBuyOrders } = props;
  const [buyOrderData, setBuyOrderData] = useState({});
  const [activeStateBuyOrders, setActiveStateBuyOrders] = useState([]);
  const accessToken = getAccessToken();

  useEffect(() => {
    getStateBuyOrdersActiveApi(accessToken, true).then((response) => {
      setActiveStateBuyOrders(response.stateBuyOrders);
    });
  }, []);

  useEffect(() => {
    setBuyOrderData({
      id: buyOrder._id,
      state: buyOrder.state? buyOrder.state._id : null,
    });
  }, [buyOrder]);


const updateState = async (p) => {
    const token = getAccessToken();
    let buyOrderUpdate = buyOrderData; //es let porque se va actualizando

    await updateBuyOrderApi(token, buyOrderUpdate, buyOrder._id).then((result) => {
      if (
        result.message === "ERR_CONNECTION_REFUSED" ||
        result.message === "Failed to fetch" ||
        result.message === undefined
      ) {
        notification["error"]({
          message: "Servidor caido",
        });
        setIsVisibleModal(true);
      } else if (result.code !== 200) {
        notification["error"]({
          message: result.message,
        });
        setIsVisibleModal(true);
      } else {
        notification["success"]({
          message: result.message, //el mensaje que viene del server
        });
        //console.log("respuesta: " + result.message);
        setIsVisibleModal(false);
        setReloadBuyOrders(true);
      }
    });
  //}
};

return (
  <div className="edit-state-form">
    <EditForm
      buyOrderData={buyOrderData}
      setBuyOrderData={setBuyOrderData}
      updateState={updateState}
      activeStateBuyOrders={activeStateBuyOrders}
    />
  </div>
);
}

function EditForm(props) {
  const { updateState, buyOrderData, setBuyOrderData, activeStateBuyOrders } = props;
  const { Option } = Select;

  var options;
  
  options = <Select
              name="state"
              value={buyOrderData.state}
              onChange={(e) =>
              setBuyOrderData({ ...buyOrderData, state: e })
              }
              required
              >
              {activeStateBuyOrders
                ? activeStateBuyOrders.map((i) => {
                    return <Option key={i._id}>{i.name}</Option>;
                  })
                : "No hay estados disponibles"}
            </Select>;

  return (
    <Form className="form-edit" >

      <Row gutter={24}>
        <Col span={24}>
          <label className="control-label">Modifique el estado</label>
          <Form.Item>
            {options}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              // className="bg-success"
              className="btn-submit"
              onClick={() => updateState(true)}
            >
                Confirmar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
