import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditDeliveryTermForm from "../EditDeliveryTermForm";
import AddDeliveryTermForm from "../AddDeliveryTermForm";
import {
  activateDeliveryTermApi,
  deleteDeliveryTermApi,
} from "../../../../api/deliveryTerm";

import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListDeliveryTerms.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListDeliveryTerms(props) {
  const {
    deliveryTermsActive,
    deliveryTermsInactive,
    setReloadDeliveryTerms,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewDeliveryTermsActives, setViewDeliveryTermsActives] = useState(
    true
  );
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [deliveryTermsActiveFilter, setDeliveryTermsActiveFilter] = useState(
    []
  );
  const [
    deliveryTermsInactiveFilter,
    setDeliveryTermsInactiveFilter,
  ] = useState([]);
  const [typeFilter, setTypeFilter] = useState("description");

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, [roleById]);

  useEffect(() => {
    const listActive = [];
    if (deliveryTermsActive) {
      deliveryTermsActive.forEach((element) => {
        if (typeFilter === "description") {
          if (
            element.description
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        } else {
          if (
            element.deliveryterm_code
              .toString()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        }
      });
    }
    setDeliveryTermsActiveFilter(listActive);
  }, [filterText, deliveryTermsActive]);

  useEffect(() => {
    const listInactive = [];
    if (deliveryTermsInactive) {
      deliveryTermsInactive.forEach((element) => {
        if (typeFilter === "description") {
          if (
            element.description
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        } else {
          if (
            element.deliveryterm_code
              .toString()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        }
      });
    }
    setDeliveryTermsInactiveFilter(listInactive);
  }, [filterText, deliveryTermsInactive]);

  const addDeliveryTermModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Plazo de entrega");
    setModalContent(
      <AddDeliveryTermForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadDeliveryTerms={setReloadDeliveryTerms}
      />
    );
  };

  return (
    <div className="list-deliveryTerms">
      <div className="list-deliveryTerms__header">
        {checkAction(permissionsActive, userToken.role, "deliveryterms", "all") ||
        checkAction(permissionsActive, userToken.role, "deliveryterms", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-deliveryTerms__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewDeliveryTermsActives(!viewDeliveryTermsActives)
                }
              />
              <span>
                {viewDeliveryTermsActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
        ) : (
          <>
          </>
        )}

        {checkAction(permissionsActive, userToken.role, "deliveryterms", "all") ||
        checkAction(permissionsActive, userToken.role, "deliveryterms", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/> }onClick={addDeliveryTermModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "deliveryterms", "all") ||
      checkAction(permissionsActive, userToken.role, "deliveryterms", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-deliveryTerms__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <Radio.Group
              options={[
                { label: "Descripción", value: "description" },
                { label: "Código", value: "code" },
              ]}
              onChange={(e) => {
                setTypeFilter(e.target.value);
              }}
              value={typeFilter}
            />
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> ????
            </h3>
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewDeliveryTermsActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "deliveryterms", "all") ||
          checkAction(permissionsActive, userToken.role, "deliveryterms", "view") || isAdmin(roleById) ? (
            <>
              <DeliveryTermsActive
                deliveryTermsActive={deliveryTermsActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadDeliveryTerms={setReloadDeliveryTerms}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "deliveryterms", "all") ||
          checkAction(permissionsActive, userToken.role, "deliveryterms", "view") || isAdmin(roleById) ? (
            <>
              <DeliveryTermsInactive
                deliveryTermsInactive={deliveryTermsInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadDeliveryTerms={setReloadDeliveryTerms}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function DeliveryTermsActive(props) {
  const {
    deliveryTermsActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadDeliveryTerms,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editDeliveryTerm = (docType) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${docType.description ? docType.description : "sin descripción"}`
    );
    setModalContent(
      <EditDeliveryTermForm
        deliveryTerm={docType}
        setIsVisibleModal={setIsVisibleModal}
        setReloadDeliveryTerms={setReloadDeliveryTerms}
      />
    );
  };

  const desactivateDeliveryTerm = (docType) => {
    const accessToken = getAccessToken();

    activateDeliveryTermApi(accessToken, docType._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadDeliveryTerms(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (deliveryTerm) => {
    const accessToken = getAccessToken();
    confirm({
      title: "Eliminando Plazo de entrega",
      content: `¿Estás seguro que quieres eliminar a ${deliveryTerm.description}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteDeliveryTermApi(accessToken, deliveryTerm._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadDeliveryTerms(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const edit_deliveryTerm = (
    <div>
      {" "}
      <p>Editar Plazo de entrega</p>{" "}
    </div>
  );

  const desactivate_deliveryTerm = (
    <div>
      {" "}
      <p>Pasar a Plazos de entrega inactivos</p>{" "}
    </div>
  );

  const delete_deliveryTerm = (
    <div>
      {" "}
      <p>Eliminar Plazo de entrega</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "deliveryterm_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, docType) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "deliveryterms", "all") ||
          checkAction(permissionsActive, userToken.role, "deliveryterms", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_deliveryTerm}>
                <Button
                  className="button-deliveryTerm"
                  type="default"
                  onClick={() => editDeliveryTerm(docType)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "deliveryterms", "all") ||
          checkAction(permissionsActive, userToken.role, "deliveryterms", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_deliveryTerm}>
                <Button
                  className="button-deliveryTerm"
                  type="primary"
                  onClick={() => desactivateDeliveryTerm(docType)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "deliveryterms", "all") ||
          checkAction(permissionsActive, userToken.role, "deliveryterms", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_deliveryTerm}>
                <Button
                  className="button-deliveryTerm"
                  type="danger"
                  onClick={() => showDeleteConfirm(docType)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={deliveryTermsActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function DeliveryTermsInactive(props) {
  const {
          deliveryTermsInactive,
          setReloadDeliveryTerms,
          userToken,
          permissionsActive,
          roleById
        } = props;

  const activateDeliveryTerm = (deliveryTerm) => {
    const accessToken = getAccessToken();

    activateDeliveryTermApi(accessToken, deliveryTerm._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadDeliveryTerms(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (deliveryTerm) => {
    const accessToken = getAccessToken();
    confirm({
      title: "Eliminando Plazo de entrega",
      content: `¿Estás seguro que quieres eliminar a ${deliveryTerm.description}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteDeliveryTermApi(accessToken, deliveryTerm._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadDeliveryTerms(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const activate_deliveryTerm = (
    <div>
      {" "}
      <p>Pasar a Plazos de entrega activos</p>{" "}
    </div>
  );

  const delete_deliveryTerm = (
    <div>
      {" "}
      <p>Eliminar Plazo de entrega</p>
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "deliveryterm_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, docType) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "deliveryterms", "all") ||
          checkAction(permissionsActive, userToken.role, "deliveryterms", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_deliveryTerm}>
                <Button
                  className="button-deliveryTerm"
                  type="primary"
                  onClick={() => activateDeliveryTerm(docType)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "deliveryterms", "all") ||
          checkAction(permissionsActive, userToken.role, "deliveryterms", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_deliveryTerm}>
                <Button
                  className="button-deliveryTerm"
                  type="danger"
                  onClick={() => showDeleteConfirm(docType)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={deliveryTermsInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
