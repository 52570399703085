import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getItemsActiveApi } from "../../../api/billing/item";
import ListItems from "../../../components/Billing/Items/ListItems";

import "./Items.scss";

export default function Items() {
    const [itemsActive, setItemsActive] = useState([]);
    const [itemsInactive, setItemsInactive] = useState([]);
    const [reloadItems, setReloadItems ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getItemsActiveApi(token, true).then(response => {
            setItemsActive(response.items);
        });
        getItemsActiveApi(token, false).then(response => {
            setItemsInactive(response.items);
        });
        setReloadItems(false);
    }, [token, reloadItems]);

    return (
        <div className="items">

            <ListItems itemsActive={itemsActive} itemsInactive={itemsInactive} setReloadItems={setReloadItems} 
                />
        
        
        </div>
    );
}