export let basePath = "http://localhost:3977/api"; // pega al server en localhost
export const basePathProd = "https://appdelivery.softasc.com/api"; //pega al server restaurant


export function switchPath() {
    if (process.env.NODE_ENV === 'production'){
        basePath = basePathProd;
        //console.log(process.env.NODE_ENV);
    //}else {
        //console.log(process.env.NODE_ENV);
    }
    return basePath;
}

export const apiVersion = "v1";
