import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import { TagOutlined } from "@ant-design/icons";
import { addItemApi } from "../../../../api/billing/item";
import { getAccessToken } from "../../../../api/auth";
import { getCategoriesActiveApi } from "../../../../api/billing/category";
//import {  minLengthValidation, numberValidation } from "../../../../utils/formValidation";

import "./AddItemForm.scss";

export default function AddItemForm(props) {
  const { setIsVisibleModal, setReloadItems } = props;
  const accessToken = getAccessToken();
  const [listCategoriesActive, setListCategoriesActive] = useState([]);

  useEffect(() => {
    getCategoriesActiveApi(accessToken, true).then((response) => {
      //console.log('response: ', response);
      setListCategoriesActive(response.categories);
    });
  }, []);

  const [inputs, setInputs] = useState({
    item_code: "",
    description: "",
    registration: "",
    category: "",
    cost_price: "",
    price1: "",
    price2: "",
  });

  //  const [formValid, setFormValid] = useState({
  //    item_code: false,
  //    description: false
  //  });

  // const inputValidation = (e) => {
  //   const { type, name } = e.target;

  //   setInputs({
  //     ...inputs,
  //     [name]: e.target.value,
  //   });

  //   setFormValid({
  //     ...formValid,
  //     [item_code_valid]:"sucess"
  //   });
  // };

  const isFormValid = (e) => {
    //console.log(inputs);
    let errorExists = false;
    if (
      !inputs.description ||
      !inputs.category ||
      !inputs.cost_price ||
      !inputs.price1
    ) {
      notification["error"]({
        message:
          "Obligatorios: Código, Descripción, Rubro, Precio Costo y Precio 1.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addItem = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      const accessToken = getAccessToken();

      await addItemApi(accessToken, inputs)
        .then((response) => {
          if (
            response === "ERR_CONNECTION_REFUSED" ||
            response === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              message: "Producto creado",
            });
            setIsVisibleModal(false);
          }
          setReloadItems(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  return (
    <div className="add-item-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        //inputValidation={inputValidation}
        addItem={addItem}
        listCategoriesActive={listCategoriesActive}
      />
    </div>
  );
}

function AddForm(props) {
  const { inputs, setInputs, addItem, listCategoriesActive } = props; //inputValidation
  const { Option } = Select;

  return (
    <Form className="form-add" onSubmit={addItem}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <Input
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={inputs.item_code}
              onChange={(e) =>
                setInputs({ ...inputs, item_code: e.target.value })
              }
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Descripción
          </label>
          <Form.Item>
            <Input
              placeholder="Descripción"
              value={inputs.description}
              onChange={(e) =>
                setInputs({ ...inputs, description: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Rubro
          </label>
          <Form.Item>
            <Select
              placeholder="Selecciona Rubro"
              value={inputs.category}
              onChange={(e) => setInputs({ ...inputs, category: e })}
            >
              {listCategoriesActive
                ? listCategoriesActive.map((category) => {
                    return (
                      <Option value={category._id}>
                        {category.description}
                      </Option>
                    );
                  })
                : null}
              {/* <Option value="products">Productos</Option>
              <Option value="services">Servicios</Option>
              <Option value="supplies">Insumos</Option>
              <Option value="fixed-assets">Bienes de uso</Option>
              <Option value="various">Varios</Option>
              <Option value="others">Otros</Option> */}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">Matrícula</label>
          <Form.Item>
            <Input
              placeholder="Matricula"
              name="registration"
              value={inputs.registration}
              onChange={(e) =>
                setInputs({ ...inputs, registration: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Precio Costo
          </label>
          <Form.Item>
            <InputNumber
              placeholder="Precio Costo"
              name="cost_price"
              value={inputs.cost_price}
              onChange={(e) => setInputs({ ...inputs, cost_price: e })}
              size="middle"
              decimalSeparator=","
              style={{ width: "100%" }}
              //   onChange={inputValidation}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Precio 1
          </label>
          <Form.Item>
            <InputNumber
              name="price1"
              placeholder="Precio 1"
              value={inputs.price1}
              onChange={(e) => setInputs({ ...inputs, price1: e })}
              size="middle"
              decimalSeparator=","
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">Precio 2</label>
          <Form.Item>
            <InputNumber
              placeholder="Precio 2"
              name="price2"
              value={inputs.price2}
              onChange={(e) => setInputs({ ...inputs, price2: e })}
              size="middle"
              decimalSeparator=","
              style={{ width: "100%" }}
              //   onChange={inputValidation}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addItem}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
