import React from "react";
import { Modal as ModalAntd } from "antd";

export default function Modal(props) {
    const { children, title, isVisible, setIsVisible, modalWidth} = props;

    const width = modalWidth ? modalWidth : 500;


    return (
        <ModalAntd
            title={title}
            centered={true}
            visible={isVisible}
            onCancel={() => setIsVisible(false)}
            destroyOnClose={true}
            footer={false}
            maskClosable={false}
            width={width}
            // bodyStyle={{height: 1000}}
        >
            {children}
        </ModalAntd>
    );
}