import React, { useState, useEffect } from "react";
import { Form, Input, InputNumber, Button, Row, Col, notification } from "antd";
import { TagOutlined } from "@ant-design/icons";
import { updateSupplierTypeApi } from "../../../../api/billing/supplierType";
import { getAccessToken } from "../../../../api/auth";

import "./EditSupplierTypeForm.scss";

export default function EditSupplierTypeForm(props) {
  const { supplierType, setIsVisibleModal, setReloadSupplierTypes } = props;
  const [supplierTypeData, setSupplierTypeData] = useState({});

  //trae los datos de tipo de proveedor
  useEffect(() => {
    setSupplierTypeData({
      suppliertype_code: supplierType.suppliertype_code,
      description: supplierType.description,
    });
  }, [supplierType]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!supplierTypeData.suppliertype_code || !supplierTypeData.description) {
      notification["error"]({
        message: "Obligatorios: Todos.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const updateSupplierType = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let supplierTypeUpdate = supplierTypeData; //es let porque se va actualizando

      updateSupplierTypeApi(token, supplierTypeUpdate, supplierType._id).then(
        (result) => {
          if (
            result.message === "ERR_CONNECTION_REFUSED" ||
            result.message === "Failed to fetch" ||
            result.message === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
            setIsVisibleModal(true);
          } else if (result.code !== 200) {
            notification["error"]({
              message: result.message,
            });
            setIsVisibleModal(true);
          } else {
            notification["success"]({
              message: result.message, //el mensaje que viene del server
            });
            //console.log("respuesta: " + result.message);
            setIsVisibleModal(false);
            setReloadSupplierTypes(true);
          }
        }
      );
    }
  };

  return (
    <div className="edit-suppliertype-form">
      <EditForm
        supplierTypeData={supplierTypeData}
        setSupplierTypeData={setSupplierTypeData}
        updateSupplierType={updateSupplierType}
      />
    </div>
  );
}

function EditForm(props) {
  const { supplierTypeData, setSupplierTypeData, updateSupplierType } = props;

  return (
    <Form className="form-edit" onSubmit={updateSupplierType}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Código"
              value={supplierTypeData.suppliertype_code}
              onChange={(e) =>
                setSupplierTypeData({
                  ...supplierTypeData,
                  suppliertype_code: e,
                })
              }
              min={1}
              style={{ width: "90%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">* </span>Descripción
          </label>
          <Form.Item>
            <Input
              placeholder="Descripción"
              value={supplierTypeData.description}
              onChange={(e) =>
                setSupplierTypeData({
                  ...supplierTypeData,
                  description: e.target.value.toUpperCase(),
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateSupplierType}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
