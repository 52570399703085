import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditStateBuyOrderForm from "../EditStateBuyOrderForm";
import AddStateBuyOrderForm from "../AddStateBuyOrderForm";
import {
  activateStateBuyOrderApi,
  deleteStateBuyOrderApi,
} from "../../../../api/billing/stateBuyOrder";

import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListStateBuyOrders.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListStateBuyOrders(props) {
  const {
    stateBuyOrdersActive,
    stateBuyOrdersInactive,
    setReloadStateBuyOrders,
  } = props;

  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewStateBuyOrdersActives, setViewStateBuyOrdersActives] = useState(
    true
  );
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [stateBuyOrdersActiveFilter, setStateBuyOrdersActiveFilter] = useState(
    []
  );
  const [
    stateBuyOrdersInactiveFilter,
    setStateBuyOrdersInactiveFilter,
  ] = useState([]);
  const [typeFilter, setTypeFilter] = useState("name");

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, [roleById]);

  useEffect(() => {
    const listActive = [];
    if (stateBuyOrdersActive) {
      stateBuyOrdersActive.forEach((element) => {
        if (typeFilter === "name") {
          if (
            element.name
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        } else {
          if (
            element.statebuyorder_code
              .toString()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        }
      });
    }
    setStateBuyOrdersActiveFilter(listActive);
  }, [filterText, stateBuyOrdersActive]);

  useEffect(() => {
    const listInactive = [];
    if (stateBuyOrdersInactive) {
      stateBuyOrdersInactive.forEach((element) => {
        if (typeFilter === "name") {
          if (
            element.name
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        } else {
          if (
            element.statebuyorder_code
              .toString()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        }
      });
    }
    setStateBuyOrdersInactiveFilter(listInactive);
  }, [filterText, stateBuyOrdersInactive]);

  const addStateBuyOrderModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Estado de Órden de Compra");
    setModalContent(
      <AddStateBuyOrderForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadStateBuyOrders={setReloadStateBuyOrders}
      />
    );
  };

  return (
    <div className="list-stateBuyOrders">
      <div className="list-stateBuyOrders__header">
        {checkAction(permissionsActive, userToken.role, "statebuyorders", "all") ||
        checkAction(permissionsActive, userToken.role, "statebuyorders", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-stateBuyOrders__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewStateBuyOrdersActives(!viewStateBuyOrdersActives)
                }
              />
              <span>
                {viewStateBuyOrdersActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
        ) : (
          <>
          </>
        )}

        {checkAction(permissionsActive, userToken.role, "statebuyorders", "all") ||
        checkAction(permissionsActive, userToken.role, "statebuyorders", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/> }onClick={addStateBuyOrderModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "statebuyorders", "all") ||
      checkAction(permissionsActive, userToken.role, "statebuyorders", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-stateBuyOrders__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <Radio.Group
              options={[
                { label: "Nombre", value: "name" },
                { label: "Código", value: "code" },
              ]}
              onChange={(e) => {
                setTypeFilter(e.target.value);
              }}
              value={typeFilter}
            />
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> Pendiente, Aceptada, Facturada, Rechazada, Despachada, etc.
            </h3>
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewStateBuyOrdersActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "statebuyorders", "all") ||
          checkAction(permissionsActive, userToken.role, "statebuyorders", "view") || isAdmin(roleById) ? (
            <>
              <StateBuyOrdersActive
                stateBuyOrdersActive={stateBuyOrdersActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadStateBuyOrders={setReloadStateBuyOrders}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "statebuyorders", "all") ||
          checkAction(permissionsActive, userToken.role, "statebuyorders", "view") || isAdmin(roleById) ? (
            <>
              <StateBuyOrdersInactive
                stateBuyOrdersInactive={stateBuyOrdersInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadStateBuyOrders={setReloadStateBuyOrders}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function StateBuyOrdersActive(props) {
  const {
    stateBuyOrdersActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadStateBuyOrders,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editStateBuyOrder = (stateBuyOrder) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${stateBuyOrder.name ? stateBuyOrder.name : "sin nombre"}`
    );
    setModalContent(
      <EditStateBuyOrderForm
        stateBuyOrder={stateBuyOrder}
        setIsVisibleModal={setIsVisibleModal}
        setReloadStateBuyOrders={setReloadStateBuyOrders}
      />
    );
  };

  const desactivateStateBuyOrder = (stateBuyOrder) => {
    const accessToken = getAccessToken();

    activateStateBuyOrderApi(accessToken, stateBuyOrder._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadStateBuyOrders(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (stateBuyOrder) => {
    const accessToken = getAccessToken();
    confirm({
      title: "Eliminando Estado de Órden de Compra",
      content: `¿Estás seguro que quieres eliminar a ${stateBuyOrder.name}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteStateBuyOrderApi(accessToken, stateBuyOrder._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadStateBuyOrders(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const edit_stateBuyOrder = (
    <div>
      {" "}
      <p>Editar Estado de Órden de Compra</p>{" "}
    </div>
  );

  const desactivate_stateBuyOrder = (
    <div>
      {" "}
      <p>Pasar a Estados de Órden de Compra inactivos</p>{" "}
    </div>
  );

  const delete_stateBuyOrder = (
    <div>
      {" "}
      <p>Eliminar Estado de Órden de Compra</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "statebuyorder_code",
    },
    {
      title: "Nombre",
      dataIndex: "name",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, stateBuyOrder) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "statebuyorders", "all") ||
          checkAction(permissionsActive, userToken.role, "statebuyorders", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_stateBuyOrder}>
                <Button
                  className="button-stateBuyOrder"
                  type="default"
                  onClick={() => editStateBuyOrder(stateBuyOrder)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "statebuyorders", "all") ||
          checkAction(permissionsActive, userToken.role, "statebuyorders", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_stateBuyOrder}>
                <Button
                  className="button-stateBuyOrder"
                  type="primary"
                  onClick={() => desactivateStateBuyOrder(stateBuyOrder)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "statebuyorders", "all") ||
          checkAction(permissionsActive, userToken.role, "statebuyorders", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_stateBuyOrder}>
                <Button
                  className="button-stateBuyOrder"
                  type="danger"
                  onClick={() => showDeleteConfirm(stateBuyOrder)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={stateBuyOrdersActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function StateBuyOrdersInactive(props) {
  const {
          stateBuyOrdersInactive,
          setReloadStateBuyOrders,
          userToken,
          permissionsActive,
          roleById
        } = props;

  const activateStateBuyOrder = (stateBuyOrder) => {
    const accessToken = getAccessToken();

    activateStateBuyOrderApi(accessToken, stateBuyOrder._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadStateBuyOrders(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (stateBuyOrder) => {
    const accessToken = getAccessToken();
    confirm({
      title: "Eliminando Estado de Órden de Compra",
      content: `¿Estás seguro que quieres eliminar a ${stateBuyOrder.name}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteStateBuyOrderApi(accessToken, stateBuyOrder._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadStateBuyOrders(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const activate_stateBuyOrder = (
    <div>
      {" "}
      <p>Pasar a Estados de Órden de Compra activos</p>{" "}
    </div>
  );

  const delete_stateBuyOrder = (
    <div>
      {" "}
      <p>Eliminar Estado de Órden de Compra</p>
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "statebuyorder_code",
    },
    {
      title: "Nombre",
      dataIndex: "name",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, stateBuyOrder) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "statebuyorders", "all") ||
          checkAction(permissionsActive, userToken.role, "statebuyorders", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_stateBuyOrder}>
                <Button
                  className="button-stateBuyOrder"
                  type="primary"
                  onClick={() => activateStateBuyOrder(stateBuyOrder)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "statebuyorders", "all") ||
          checkAction(permissionsActive, userToken.role, "statebuyorders", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_stateBuyOrder}>
                <Button
                  className="button-stateBuyOrder"
                  type="danger"
                  onClick={() => showDeleteConfirm(stateBuyOrder)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={stateBuyOrdersInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
