import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import {
  //getCoursesActiveApi, getCategoryExportApi, getCategoriesApi,
  getCategoriesActiveApi,
} from "../../../api/category";
import ListBranchVariations from "../../../components/Admin/Variations/ListBranchVariations";
import { useBranch } from "../../../hooks/useBranch";
import { getBranchMenuByBranchApi } from "../../../api/branchMenu";


export default function BranchVariations(props) {
  const [variationsAvailable, setvaritionsAvailable] = useState([]);
  const [variationsNotAvailable, setVariationsNotAvailable] = useState([]);
  const [reloadVariations, setReloadVariations] = useState(false);
  const token = getAccessToken();
  const { branch } = useBranch();

  useEffect(() => {
    let isMounted = true;

    if (branch && branch !== "") {
      getBranchMenuByBranchApi(token, branch).then((response) => {
        if (response && response.branchMenu) {
          const variations=response.branchMenu.variations? response.branchMenu.variations : [];
          const listActive = variations.filter(
            (item) => item.available === true
          );
          const listActive2=listActive.map((i)=>i.variation);
          const listInactive = variations.filter(
            (item) => item.available === false
          );
          const listInactive2=listInactive.map((i)=>i.variation);
          if (isMounted) {
            setvaritionsAvailable(listActive2);
            setVariationsNotAvailable(listInactive2);
          }
        }
      });
    }
    setReloadVariations(false);
    return () => {
      isMounted = false;
    };
  }, [token, branch, reloadVariations]);

  useEffect(() => {
    if(branch && (branch===0 || branch==="0")){
      return window.location.href = "/admin/digitalmenu/variations" ;
    }
  }, [branch]);


  return (
    <div className="variations">
      <h3>Listado de Variaciones del Negocio</h3>
      <ListBranchVariations
        variationsAvailable={variationsAvailable}
        variationsNotAvailable={variationsNotAvailable}
        setReloadVariations={setReloadVariations}
        branch={branch}
      />
    </div>
  );
}
