import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import { TagOutlined, EnvironmentOutlined, PhoneOutlined } from "@ant-design/icons";
import { updateSellerApi } from "../../../../api/billing/seller";
import { getAccessToken } from "../../../../api/auth";
import { numberValidation } from "../../../../utils/formValidation";

import "./EditSellerForm.scss";

export default function EditSellerForm(props) {
  const { seller, setIsVisibleModal, setReloadSellers } = props;
  const [ sellerData, setSellerData ] = useState({});

  const [modifDataToValid, setModifDataToValid] = useState({
    phone: false
  });
  const [validData, setValidData] = useState({
    email: false,
  });

  //trae los datos de tipo de proveedor
  useEffect(() => {
    setSellerData({
      seller_code: seller.seller_code,
      name: seller.name,
      address: seller.address,
      phone: seller.phone
    });
  }, [seller]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!sellerData.seller_code || !sellerData.name) {
      notification["error"]({
        message: "Obligatorios: Código y Nombre.",
      });
      errorExists = true;
    }
    else if (modifDataToValid.phone) {
      if (!validData.phone) {
        //solo si el email está vacio, falta la validación en el return de EditForm
        notification["error"]({
          message: "Ingreso de teléfono inválido"
        });
        errorExists = true;
      }
    }
    else {
      errorExists = false;
    }
    return errorExists;
  };

  const updateSeller = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let sellerUpdate = sellerData; //es let porque se va actualizando

      updateSellerApi(token, sellerUpdate, seller._id).then((result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //console.log("respuesta: " + result.message);
          setIsVisibleModal(false);
          setReloadSellers(true);
        }
      });
    }
  };

  return (
    <div className="edit-seller-form">
      <EditForm
        sellerData={sellerData}
        setSellerData={setSellerData}
        updateSeller={updateSeller}
        modifDataToValid={modifDataToValid}
        setModifDataToValid={setModifDataToValid}
        validData={validData}
        setValidData={setValidData}
      />
    </div>
  );
}

function EditForm(props) {
  const { sellerData, setSellerData, updateSeller, setModifDataToValid, modifDataToValid, validData, setValidData } = props;

    //validaciones de inputs
    const inputValidation = (e) => {
      const { name } = e.target; //type

      setSellerData({
        ...sellerData,
        [name]: e.target.value,
      });
      setModifDataToValid({
        ...modifDataToValid,
        [name]: true,
      });
      if (name === "phone"){
        setValidData({
            ...validData,
            [name]: numberValidation(e.target)
        });
      }
  };

  return (
    <Form className="form-edit" onSubmit={updateSeller}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label"><span className="control-required">* </span>Código</label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Código"
              value={sellerData.seller_code}
              onChange={(e) =>
                setSellerData({ ...sellerData, seller_code: e })
              }
              min={1}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label"><span className="control-required">* </span>Nombre</label>
          <Form.Item>
            <Input
              placeholder="Nombre"
              value={sellerData.name}
              onChange={(e) =>
                setSellerData({ ...sellerData, name: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">Domicilio</label>
          <Form.Item>
            <Input
              prefix={<EnvironmentOutlined />}
              placeholder="Domicilio"
              name="address"
              value={sellerData.address}
              onChange={(e) =>
                setSellerData({ ...sellerData, address: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">Teléfono</label>
          <Form.Item>
            <Input
              prefix={<PhoneOutlined />}
              placeholder="Teléfono"
              name="phone"
              value={sellerData.phone}
              //onChange={(e) => setInputs({ ...inputs, phone: e.target.value })}
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateSeller}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
