import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import { TagOutlined } from "@ant-design/icons";
import { addPermissionApi } from "../../../../api/permission";
import { getRolesActiveApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import { getAccessToken } from "../../../../api/auth";
import { MODULES, ACTIONS, administradores } from '../../../../utils/constants';
import { convertModulesEngToEsp, convertActionsEngToEsp } from "../../../../utils/convert";

import "./AddPermissionForm.scss";

export default function AddPermissionForm(props) {
  const { setIsVisibleModal, setReloadPermissions } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [permissionsInactive, setPermissionsInactive] = useState([]);
  const [activeRoles, setActiveRoles] = useState([]);
  const accessToken = getAccessToken();

  const [inputs, setInputs] = useState({
    permission_code: "",
    role: "",
    module: "",
    action: "",
    description: ""
  });
  //const MODULES_sort = MODULES.sort();

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getPermissionsActiveApi(accessToken, false).then(response => {
      setPermissionsInactive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRolesActiveApi(accessToken, true).then((response) => {
      setActiveRoles(response.roles);
    });
  }, []);

  /*
  const [formValid, setFormValid] = useState({
    phone: false
  });
  */
  //console.log("MODULOS", MODULES);

  /*  
  const inputValidation = (e) => {
    const { type, name } = e.target;

    setInputs({
      ...inputs,
      [name]: e.target.value,
    });
  };
  */

  const isFormValid = (e) => {
    //console.log(inputs);
    let errorExists = false;
    if (!inputs.role || !inputs.module || !inputs.action) {
      notification["error"]({
        message: "Obligatorios: Rol, módulo y acción.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const checkPermissionExist = async (e) => {
    e.preventDefault();
    let foundedActive = false;
    let foundedInactive = false;
    if (permissionsActive){
      permissionsActive.forEach((i) => {
        if ((inputs.action === i.action) && (inputs.role === i.role) && (inputs.module === i.module)){
          foundedActive = true;
          notification["warning"]({
            message: "El permiso ya existe y está activado",
          });
          //console.log("ENTRÓ");
          //return;
        }
      });
    }
    if (permissionsInactive){
      permissionsInactive.forEach((i) => {
        if ((inputs.action === i.action) && (inputs.role === i.role) && (inputs.module === i.module)){
          foundedInactive = true;
          notification["warning"]({
            message: "El permiso ya existe y está desactivado",
          });
          //console.log("ENTRÓ");
          //return;
        }
      });
    }
    if (!foundedActive && !foundedInactive) {
      addPermission();
    }
  }

  const addPermission= async (e) => {
    //e.preventDefault();

    if (!isFormValid()) {
      const accessToken = getAccessToken();

      await addPermissionApi(accessToken, inputs)
        .then((response) => {
          if (response === "ERR_CONNECTION_REFUSED" || response === "Failed to fetch" || response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          }
          else {
            notification["success"]({
              message: "Permiso creado",
            });
            setIsVisibleModal(false);
          }
          setReloadPermissions(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  return (
    <div className="add-permission-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        //inputValidation={inputValidation}
        checkPermissionExist={checkPermissionExist}
        addPermission={addPermission}
        activeRoles={activeRoles}
        MODULES={MODULES}
        ACTIONS={ACTIONS}
        administradores={administradores}
      />
    </div>
  );
}

function AddForm(props) {
  const { 
    inputs, setInputs, checkPermissionExist, 
    //addPermission, administradores, 
    activeRoles, MODULES, ACTIONS  
  } = props; //inputValidation
  const { Option } = Select;

  return (
    <Form className="form-add" onSubmit={checkPermissionExist}>
      <Row gutter={24}>
      <Col span={12}>
          <label className="control-label"> Código</label>
          <Form.Item> {/*rules={[{required:true}]}*/}
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={inputs.permission_code}
              onChange={(e) =>
                setInputs({ ...inputs, permission_code: e })
              }
              disabled={true}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label"><span className="control-required">*</span> Rol</label>
          <Form.Item >
            <Select
                className="select-form"
                placeholder="Selecciona un rol"
                onChange={(e) => setInputs({ ...inputs, role: e })}
                value={inputs.role}
                //style={{textAlign: "left"}}
              >
              {activeRoles
                ? activeRoles.map((i) => {
                    if ((i.name !== 'administrador') &&
                       (i.name !== 'Administrador') &&
                       (i.name !== 'ADMINISTRADOR') &&
                       (i.name !== 'admin') &&
                       (i.name !== 'Admin') &&
                       (i.name !== 'ADMIN')
                       ) {
                      //activeRoles.push(i.name);
                      return <Option key={i._id}>{i.name}</Option>;
                    }
                  //para que devuelva algo
                  return true;
                })
                : "No hay roles disponibles"
              }
              </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
            <label className="control-label"><span className="control-required">*</span> Módulo</label>
            <Form.Item >
              <Select
                className="select-form"
                placeholder="Módulo"
                value={inputs.module}
                onChange={(e) => setInputs({ ...inputs, module: e })}
              >
              {MODULES
                ? MODULES.map((i) => {
                    return <Option key={i}>{convertModulesEngToEsp(i)}</Option>;
                  })
                : "No hay módulos disponibles"}
              </Select>
            </Form.Item>
        </Col>
        <Col span={12}>
            <label className="control-label"><span className="control-required">*</span> Acción</label>
            <Form.Item >
              <Select
                className="select-form"
                placeholder="Selecciona la acción"
                onChange={(e) => setInputs({ ...inputs, action: e })}
                value={inputs.action}
                //style={{textAlign: "left"}}
              >
              {/* TODO: FALTA FILTRAR PARA ROLE-USER*/}
              {
                inputs.module === "exports" || inputs.module === "imports" ||
                inputs.module === "persons-menu" || inputs.module === "config-menu" ||
                inputs.module === "courses-menu" || inputs.module === "menu-edition"
                  ? <Option key="menu-sider">{convertActionsEngToEsp("menu-sider")}</Option>
                :
                inputs.module === "inscriptions"
                  ? <Option key="all">{convertActionsEngToEsp("all")} </Option>
                :
                ACTIONS ? ACTIONS.map((i) => {
                    if(inputs.module === "courses" && i==="enroll"){
                      return <Option key={i}>{convertActionsEngToEsp(i)}</Option>;
                    }else if(i!=="enroll"){
                      return <Option key={i}>{convertActionsEngToEsp(i)}</Option>;
                    }
                  //para que devuelva algo
                  return true;
                })
                : "No hay acciones disponibles"
              /*
                || inputs.module === "role-user"
                  ? <Option
                      key="add">{convertActionsEngToEsp("add")}
                    </Option>
                  :
                  ACTIONS
                    ? ACTIONS.map((i) => {
                        return <Option key={i}>{convertActionsEngToEsp(i)}</Option>;
                      })
                    : "No hay acciones disponibles"
                */
               /*key="edit">{convertActionsEngToEsp("edit")}*/
              }
              </Select>
            </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <label className="control-label">Descripción</label>
          <Form.Item>
            <Input
              //prefix={<EnvironmentOutlined />}
              placeholder="Descripción"
              name="description"
              value={inputs.description}
              onChange={(e) =>
                setInputs({ ...inputs, description: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={checkPermissionExist}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
