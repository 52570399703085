import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { 
    getPermissionsActivePopulateApi, 
    //getPermissionsActiveApi 
} from "../../../api/permission";
import ListPermissions from "../../../components/Admin/Permissions/ListPermissions";


export default function Permissions() {
    const [permissionsActive, setPermissionsActive] = useState([]);
    const [permissionsInactive, setPermissionsInactive] = useState([]);
    const [reloadPermissions, setReloadPermissions ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getPermissionsActivePopulateApi(token, true).then(response => {
            setPermissionsActive(response.permissions);
        });
        getPermissionsActivePopulateApi(token, false).then(response => {
            setPermissionsInactive(response.permissions);
        });
        setReloadPermissions(false);
    }, [token, reloadPermissions]);

    return (
        <div className="permissions">
            <h3>Listado de Permisos</h3>
            <ListPermissions permissionsActive={permissionsActive} permissionsInactive={permissionsInactive} setReloadPermissions={setReloadPermissions}
                />

        </div>
    );
}