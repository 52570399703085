//import { Result } from "antd";
import { switchPath, apiVersion } from './config';


export function getBranchMenuExportApi(token) {
  const url = `${switchPath()}/${apiVersion}/branch-menu-export-csv`;
  const params = {
      method: "GET",
      headers: {
          "Content-Type": "text/csv",
          Authorization: token
      }
  };

  return fetch(url, params)
        .then(response => {
            return response.text();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'menu.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(err => {
            return err.message;
        });
}

export function postBranchMenuImportApi(token, file) {
  const url = `${switchPath()}/${apiVersion}/branch-menu-import-csv`;
  const formData = new FormData();
  formData.append("file", file, file.name);

  const params = {
      method: "POST",
      body: formData,
      headers: {
          Authorization: token
      }
  };

  return fetch(url, params)
      .then(response => {
          return response.json();
      })
      .then(data => {
          return data;
      })
      .catch(err => {
          return err.message;
      });
}

export function getBranchMenuApi(token, idMenu) {
  const url = `${switchPath()}/${apiVersion}/branch-menu/${idMenu}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    }
  };

  return fetch(url,params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

export function getBranchMenuByBranchApi(token, idBranch) {
  const url = `${switchPath()}/${apiVersion}/branch-menu-branch/${idBranch}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    }
  };

  return fetch(url,params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

export function getBranchMenusApi(token) {
  const url = `${switchPath()}/${apiVersion}/branch-menus`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    }
  };

  return fetch(url,params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

export function getBranchMenuViewApi(token) {
  const url = `${switchPath()}/${apiVersion}/branch-menu-view`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    }
  };

  return fetch(url,params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

//Funcion para actualizar menu
export function updateBranchMenuApi(token, menuId, data) {
  const url = `${switchPath()}/${apiVersion}/update-branch-menu/${menuId}`;
  const params = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
    body: JSON.stringify(data)
  };
  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}
export function deleteBranchMenuByBranchApi(token, branchId) {
  const url = `${switchPath()}/${apiVersion}/delete-branch-menu-branch/${branchId}`;
  const params = {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
    // body: JSON.stringify(data)
  };
  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

//Manejo por categoria
export function addCategoryBranchMenuByBranchApi(token, branchId, data) {
  const url = `${switchPath()}/${apiVersion}/add-branch-menu-category/${branchId}`;
  const params = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
    body: JSON.stringify(data)
  };
  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

export function deleteCategoryBranchMenuByBranchApi(token, branchId, categoryId) {
  const url = `${switchPath()}/${apiVersion}/delete-branch-menu-category/${branchId}/${categoryId}`;
  const params = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
  };

  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}


export function addCategoryBranchMenuAllBranchesApi(token, data, businessId) {
  const url = `${switchPath()}/${apiVersion}/add-branches-menu-category/${businessId}`;
  const params = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
    body: JSON.stringify(data)
  };
  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

export function deleteCategoryBranchMenuAllBranchesApi(token, businessId, categoryId) {
  const url = `${switchPath()}/${apiVersion}/delete-branches-menu-category/${businessId}/${categoryId}`;
  const params = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
    // body: JSON.stringify(data)
  };

  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

//Manejor por items
export function addItemBranchMenuByBranchApi(token, branchId, data) {
  const url = `${switchPath()}/${apiVersion}/add-branch-menu-item/${branchId}`;
  const params = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
    body: JSON.stringify(data)
  };
  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

export function deleteItemBranchMenuByBranchApi(token, branchId, itemId) {
  const url = `${switchPath()}/${apiVersion}/delete-branch-menu-item/${branchId}`;
  const params = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
    body: JSON.stringify({item:itemId})
  };
 
  return fetch(url, params)
    .then(response => {

      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

export function updateItemBranchMenuByBranchApi(token, branchId, data) {
  const url = `${switchPath()}/${apiVersion}/update-branch-menu-item/${branchId}`;
  const params = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
    body: JSON.stringify(data)
  };

  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

export function getItemsBranchMenuByCategoryApi(token,categoryId) {
  const url = `${switchPath()}/${apiVersion}/branch-menu-category-items/${categoryId}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    }
  };

  return fetch(url,params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

export function getItemsBranchMenuBranchByCategoryApi(token, idBranch,categoryId) {
  const url = `${switchPath()}/${apiVersion}/branch-menu-branch-category-items/${idBranch}/${categoryId}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    }
  };

  return fetch(url,params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

export function addItemBranchMenuAllBranchesApi(token, data, businessId) {
  const url = `${switchPath()}/${apiVersion}/add-branches-menu-item/${businessId}`;
  const params = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
    body: JSON.stringify(data)
  };

  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

export function deleteItemBranchMenuAllBranchesApi(token, itemId) {
  const url = `${switchPath()}/${apiVersion}/delete-branches-menu-item/${itemId}`;
  const params = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
    // body: JSON.stringify(data)
  };
  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

//Manejor por variations
export function addVariationBranchMenuByBranchApi(token, branchId, data) {
  const url = `${switchPath()}/${apiVersion}/add-branch-menu-variation/${branchId}`;
  const params = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
    body: JSON.stringify(data)
  };
  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

export function deleteVariationBranchMenuByBranchApi(token, branchId, variationId) {
  const url = `${switchPath()}/${apiVersion}/delete-branch-menu-variation/${branchId}/${variationId}`;
  const params = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
   // body: JSON.stringify(data)
  };
 
  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

export function updateVariationBranchMenuByBranchApi(token, branchId, data) {
  const url = `${switchPath()}/${apiVersion}/update-branch-menu-variation/${branchId}`;
  const params = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
    body: JSON.stringify(data)
  };

  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

// export function getVariationsBranchMenuByCategoryApi(token, idBranch,categoryId) {
//   const url = `${switchPath()}/${apiVersion}/branch-menu-branch/${idBranch}/${categoryId}`;

//   const params = {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: token
//     }
//   };

//   return fetch(url,params)
//     .then(response => {
//       return response.json();
//     })
//     .then(result => {
//       return result;
//     })
//     .catch(err => {
//       return err.message;
//     });
// }

export function addVariationBranchMenuAllBranchesApi(token, data, businessId) {
  const url = `${switchPath()}/${apiVersion}/add-branches-menu-variation/${businessId}`;
  const params = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
    body: JSON.stringify(data)
  };

  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

export function deleteVariationBranchMenuAllBranchesApi(token, businessId,variationId) {
  const url = `${switchPath()}/${apiVersion}/delete-branches-menu-variation/${businessId}/${variationId}`;
  const params = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
    // body: JSON.stringify(data)
  };
  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}
//Funcion para activar el menu
export function activateBranchMenuApi(token, menuId, status) {
  const url = `${switchPath()}/${apiVersion}/activate-branch-menu/${menuId}`;

  const params = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
    body: JSON.stringify({ active: status })
  };

  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

//Funcion para agregar un nuevo menu
export function addBranchMenuApi(token, dataMenu) {
  const url = `${switchPath()}/${apiVersion}/add-branch-menu`;

  const params = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
    body: JSON.stringify(dataMenu)
  };

  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

export function getBranchMenusActiveApi(token, status) {
  const url = `${switchPath()}/${apiVersion}/branch-menu-active?active=${status}`;
  const params = {
      method: "GET",
      headers: {
          "Content-Type": "application/json",
          Authorization: token
      }
  };

  return fetch(url, params)
      .then(response => {
          return response.json();
      })
      .then(result => {
          //console.log(result);
          return result;
      })
      .catch(err => {
          return err.message;
      });
}


//Funcion para eliminar Menu
export function deleteBranchMenuApi(token, menuId) {
  const url = `${switchPath()}/${apiVersion}/delete-menu/${menuId}`;

  const params = {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    }
  };

  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

export function availableItemBranchMenuByBranchApi(token, branchId, itemId, status) {
  const url = `${switchPath()}/${apiVersion}/available-branch-menu-item/${branchId}/${itemId}`;

  const params = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
    body: JSON.stringify({ available: status })
  };

  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

export function availableItemBranchMenuAllBranchsApi(token, itemId, status) {
  const url = `${switchPath()}/${apiVersion}/available-branches-menu-item/${itemId}`;

  const params = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
    body: JSON.stringify({ available: status })
  };

  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

export function availableCategoryBranchMenuByBranchApi(token, branchId, categoryId, status) {
  const url = `${switchPath()}/${apiVersion}/available-branch-menu-category/${branchId}/${categoryId}`;

  const params = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
    body: JSON.stringify({ available: status })
  };

  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

export function availableCategoryBranchMenuAllBranchesApi(token, businessId, categoryId, status) {
  const url = `${switchPath()}/${apiVersion}/available-branches-menu-category/${businessId}/${categoryId}`;

  const params = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
    body: JSON.stringify({ available: status })
  };

  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

export function availableVariationBranchMenuByBranchApi(token, branchId, variationId, status) {
  const url = `${switchPath()}/${apiVersion}/available-branch-menu-variation/${branchId}/${variationId}`;

  const params = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
    body: JSON.stringify({ available: status })
  };

  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

export function availableVariationBranchMenuAllBranchesApi(token, businessId, variationId, status) {
  const url = `${switchPath()}/${apiVersion}/available-branches-menu-variation/${businessId}/${variationId}`;

  const params = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
    body: JSON.stringify({ available: status })
  };

  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}