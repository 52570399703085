import React, { useState, useEffect, createContext } from 'react';
import { getAccessToken, getRefreshToken, refreshAccessToken, logout } from "../api/auth";
import jwtDecode from 'jwt-decode';

export const AuthContext = createContext();

export default function AuthProvider(props) { //devuelve user y isLoading
    //console.log("userToken: ", userToken);
    const { children } = props;
    const [ user, setUser ] = useState({ //si user es "vituccibruno" lleva al home
        user: null,
        isLoading: true
    });

    useEffect(() => {
        checkUserLogin(setUser);
        const tokenCheckInterval = setInterval(() => {
            checkUserLogin(setUser);
        }, 10000); // Verificar cada 10 segundos, ajusta el intervalo según tus necesidades
        return () => {
            clearInterval(tokenCheckInterval);
        };
    }, []);

    return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;


    /*
    useEffect(() => {
        checkUserLogin(setUser);
    }, []);
    console.log("user: ", user);

    if (user !== null){
        return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
    } else {
        logout(); //borra los token del localStorage
        //ahora se puede hacer un redirect o windows reload
        window.location.reload();
        setUser({
            isLoading: false,
            user: null,
        });
    }
    */
    
}

//verifica si hay usuario logueado o no, si lo hay setea en "user" la info del mismo
async function checkUserLogin(setUser) {
    const accessToken = getAccessToken();

    if (!accessToken) {
        const refreshToken = getRefreshToken();

        if (!refreshToken) {
            logout();
            setUser({
                isLoading: false,
                user: null
            });
        } else {
            const newAccessToken = await refreshAccessToken(refreshToken);
            if (newAccessToken) {
                setUser({
                    isLoading: false,
                    user: jwtDecode(newAccessToken)
                });
            } else {
                logout();
                setUser({
                    isLoading: false,
                    user: null
                });
            }
        }
    } else {
        setUser({
            isLoading: false,
            user: jwtDecode(accessToken)
        });
    }
    /*
    if (!accessToken) {
        const refreshToken = getRefreshToken();
    
        if (!refreshToken) {
            logout(); //borra los token del localStorage
            //ahora se puede hacer un redirect o windows reload
            //window.location.reload();
            setUser({
                isLoading: false,
                user: null,
            });
        } else {
        try {
            const newAccessToken = await refreshAccessToken(refreshToken);
            setUser({
            isLoading: false,
            user: jwtDecode(newAccessToken),
            });
        } catch (error) {
            console.error("Error al refrescar el token:", error.message);
            //logout();
            logout(); //borra los token del localStorage
            //ahora se puede hacer un redirect o windows reload
            window.location.reload();
            setUser({
                isLoading: false,
                user: null,
            });
        }
        }
    } else {
        setUser({
            isLoading: false,
            user: jwtDecode(accessToken),
        });
    }
    */
}