import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getBillSystemsActiveApi } from "../../../api/billing/billSystem";
import ListBillSystems from "../../../components/Billing/BillSystems/ListBillSystems";


export default function BillSystems() {
    const [billSystemsActive, setBillSystemsActive] = useState([]);
    const [billSystemsInactive, setBillSystemsInactive] = useState([]);
    const [reloadBillSystems, setReloadBillSystems ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getBillSystemsActiveApi(token, true).then(response => {
            setBillSystemsActive(response.billSystems);
        });
        getBillSystemsActiveApi(token, false).then(response => {
            setBillSystemsInactive(response.billSystems);
        });
        setReloadBillSystems(false);
    }, [token, reloadBillSystems]);

    return (
        <div className="billSystems">

            <ListBillSystems billSystemsActive={billSystemsActive} billSystemsInactive={billSystemsInactive} setReloadBillSystems={setReloadBillSystems}
                />

        </div>
    );
}