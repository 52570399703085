import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getDaysActiveApi } from "../../../api/day";
import ListDays from "../../../components/Admin/Days/ListDays";


export default function Days() {
    const [daysActive, setDaysActive] = useState([]);
    const [daysInactive, setDaysInactive] = useState([]);
    const [reloadDays, setReloadDays ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getDaysActiveApi(token, true).then(response => {
            setDaysActive(response.days);
        });
        getDaysActiveApi(token, false).then(response => {
            setDaysInactive(response.days);
        });
        setReloadDays(false);
    }, [token, reloadDays]);

    return (
        <div className="days">
            <h3>Listado de Dias</h3>
            <ListDays daysActive={daysActive} daysInactive={daysInactive} setReloadDays={setReloadDays}
                />

        </div>
    );
}