import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import {
  TagOutlined,
} from "@ant-design/icons";
import { updateBillSystemApi } from "../../../../api/billing/billSystem";
import { getAccessToken } from "../../../../api/auth";

import "./EditBillSystemForm.scss";

export default function EditBillSystemForm(props) {
  const { billSystem, setIsVisibleModal, setReloadBillSystems } = props;
  const [ billSystemData, setBillSystemData ] = useState({});


  //trae los datos de Sistema de facturación
  useEffect(() => {
    setBillSystemData({
      billsystem_code: billSystem.billsystem_code,
      description: billSystem.description
    });
  }, [billSystem]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!billSystemData.billsystem_code || !billSystemData.description) {
      notification["error"]({
        message: "Obligatorios: Código y Descripción.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const updateBillSystem = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let billSystemUpdate = billSystemData; //es let porque se va actualizando

      updateBillSystemApi(token, billSystemUpdate, billSystem._id).then((result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //console.log("respuesta: " + result.message);
          setIsVisibleModal(false);
          setReloadBillSystems(true);
        }
      });
    }
  };

  return (
    <div className="edit-billsystem-form">
      <EditForm
        billSystemData={billSystemData}
        setBillSystemData={setBillSystemData}
        updateBillSystem={updateBillSystem}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    billSystemData,
    setBillSystemData,
    updateBillSystem,
  } = props;

  return (
    <Form className="form-edit" onSubmit={updateBillSystem}>
      <Row gutter={24}>
        <Col span={12}>
          <label className="control-label">Código</label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="codigo"
              value={billSystemData.billsystem_code}
              onChange={(e) =>
                setBillSystemData({ ...billSystemData, billsystem_code: e })
              }
              min={1}
              style={{ width: "90%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label"><span className="control-required">* </span>Descripción</label>
          <Form.Item>
            <Input
              placeholder="Descripción"
              value={billSystemData.description}
              onChange={(e) =>
                setBillSystemData({ ...billSystemData, description: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateBillSystem}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
