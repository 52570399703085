import { downloadPdf, openPdf , getHeader, getStyles, getLogo} from "../../../../utils/pdfManager";
import { formatDateView } from "../../../../utils/formatDate";

export function listPersonsPdf(data,isDownload) {
    const _format = (data) => {
        return data.map(item => {
            return ([
                {text: item.name},
                {text: item.lastname},
                {text: item.address},
                {text: item.phone1},
                {text: item.email},
            ]);
        });
    }

    const formattedData=_format(data);

    const docDefinition =
    {
        pageSize:'A4',
        pageOrientation: 'vertical',
        header:getHeader(),
        content:[
            getLogo(),
            '\n',
            '\n',
            { text:'Listado de Personas' , style:'title'},
            '\n',
            '\n',
            {
                table: {
                    headerRow:1,
                    dontBreakRows:true,
                    widths:['*','*','auto','auto','auto'],
                    body:
                    [
                        [
                            {text: 'Nombre', style:'tableHeader'},
                            {text: 'Apellido', style:'tableHeader'},
                            {text: 'Domicilio', style:'tableHeader'},
                            {text: 'Telefono 1', style:'tableHeader'},
                            {text: 'Email', style:'tableHeader'},
                        ],
                        ...formattedData,
                    ]
                }
            },
        ],
        styles:getStyles()
    }
    if (isDownload){
        downloadPdf(docDefinition,"ListadoPersonas");
    } else {
        openPdf(docDefinition);
    }
}

export function personDetailPdf(data,isDownload){

    const _format = (data)=>{
        return ([
            {text:`Nombre: ${data.name? data.name : ""}`, style:'list'},
            {text:`Apellido: ${data.lastname? data.lastname : ""}`, style:'list'},
            {text:`Domicilio: ${data.address? data.address : ""}`, style:'list'},
            //{text:`País: ${data.country? data.country : ""}`},
            {text:`Telefono 1: ${data.phone1? data.phone1 : ""}`, style:'list'},
            {text:`Telefono 2: ${data.phone2? data.phone2 : ""}`, style:'list'},
            // {text:`Tipo: ${data.type? data.type : ""}`, style:'list'},
            // {text:`Tipo IVA: ${data.type_iva? data.type_iva : ""}`, style:'list'},
            {text:`Tipo de documento: ${data.documentType? data.documentType : ""}`, style:'list'},
            {text:`Numero de documento: ${data.documentNumber? data.documentNumber : ""}`, style:'list'},
            {text:`E-mail: ${data.email? data.email : ""}`, style:'list'},
            {text:`Fecha de Nac.: ${data.birthDate? formatDateView(data.birthDate) : ""}` , style:'list'},
            {text:`Observaciones: ${data.observation? data.observation : ""}`, style:'list'},
        ]);
    };

    const formattedData=_format(data);

    const docDefinition =
    {
        pageSize:'A4',
        pageOrientation: 'vertical',
        header:getHeader(),
        content:
        [
            getLogo(),
            '\n',
            { text:'Detalle de Persona' , style:'title'},
            '\n',
            '\n',
            {
                type:'none',
                ol:[
                    ...formattedData,
                ],
            }
        ],
        styles:getStyles()
    }
    let title=`${data.name}`;
    if (isDownload) {
        downloadPdf(docDefinition,title);
    } else {
        openPdf(docDefinition);
    }
}