import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditSaleConditionForm from "../EditSaleConditionForm";
import AddSaleConditionForm from "../AddSaleConditionForm";
import { activateSaleConditionApi, deleteSaleConditionApi } from "../../../../api/billing/saleCondition";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListSaleConditions.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListSaleConditions(props) {
  const {
    saleConditionsActive,
    saleConditionsInactive,
    setReloadSaleConditions,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewSaleConditionsActives, setViewSaleConditionsActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [saleConditionsActiveFilter, setSaleConditionsActiveFilter] = useState(
    []
  );
  const [ saleConditionsInactiveFilter, setSaleConditionsInactiveFilter ] = useState([]);
  const [typeFilter, setMethodFilter] = useState("description");

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, [roleById]);

  useEffect(() => {
    const listActive = [];
    if (saleConditionsActive) {
      saleConditionsActive.forEach((element) => {
        if (typeFilter === "description") {
          if (
            element.description
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        } else {
          if (
            element.salecondition_code
              .toString()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        }
      });
    }
    setSaleConditionsActiveFilter(listActive);
  }, [filterText, saleConditionsActive]);

  useEffect(() => {
    const listInactive = [];
    if (saleConditionsInactive) {
      saleConditionsInactive.forEach((element) => {
        if (typeFilter === "description") {
          if (
            element.description
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        } else {
          if (
            element.salecondition_code
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        }
      });
    }
    setSaleConditionsInactiveFilter(listInactive);
  }, [filterText, saleConditionsInactive]);

  const addSaleConditionsModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Condicion de venta");
    setModalContent(
      <AddSaleConditionForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadSaleConditions={setReloadSaleConditions}
      />
    );
  };

  return (
    <div className="list-saleconditions">
      <div className="list-saleconditions__header">
        {checkAction(permissionsActive, userToken.role, "saleconditions", "all") ||
        checkAction(permissionsActive, userToken.role, "saleconditions", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-saleconditions__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewSaleConditionsActives(!viewSaleConditionsActives)
                }
              />
              <span>
                {viewSaleConditionsActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
            <>
            </>
        )}

        {checkAction(permissionsActive, userToken.role, "saleconditions", "all") ||
        checkAction(permissionsActive, userToken.role, "saleconditions", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addSaleConditionsModal}>
              Nuevo
            </Button>
          </>
          ) : (
            <>
            </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "saleconditions", "all") ||
      checkAction(permissionsActive, userToken.role, "saleconditions", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-saleconditions__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <Radio.Group
              options={[
                { label: "Descripción", value: "description" },
                { label: "Código", value: "code" },
              ]}
              onChange={(e) => {
                setMethodFilter(e.target.value);
              }}
              value={typeFilter}
            />
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> ejemplos de condición de venta 'efectivo, cheque, cuenta corriente'
            </h3>
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewSaleConditionsActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "saleconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "saleconditions", "view") || isAdmin(roleById) ? (
            <>
              <SaleConditionsActive
                saleConditionsActive={saleConditionsActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadSaleConditions={setReloadSaleConditions}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "saleconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "saleconditions", "view") || isAdmin(roleById) ? (
            <>
              <SaleConditionsInactive
                saleConditionsInactive={saleConditionsInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadSaleConditions={setReloadSaleConditions}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function SaleConditionsActive(props) {
  const {
    saleConditionsActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadSaleConditions,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editSaleCondition = (salecondition) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${salecondition.description ? salecondition.description : "sin descripción"}`
    );
    setModalContent(
      <EditSaleConditionForm
        salecondition={salecondition}
        setIsVisibleModal={setIsVisibleModal}
        setReloadSaleConditions={setReloadSaleConditions}
      />
    );
  };

  const desactivateSaleCondition = (salecondition) => {
    const accessToken = getAccessToken();

    activateSaleConditionApi(accessToken, salecondition._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadSaleConditions(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (salecondition) => {
    const accessToken = getAccessToken();
    confirm({
      title: "Eliminando Condición de venta",
      content: `¿Estás seguro que quieres eliminar a ${salecondition.description}?`,
      okText: "Eliminar",
      okMethod: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteSaleConditionApi(accessToken, salecondition._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadSaleConditions(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const edit_salecondition = (
    <div>
      {" "}
      <p>Editar Condición de venta</p>{" "}
    </div>
  );

  const desactivate_salecondition = (
    <div>
      {" "}
      <p>Pasar a Condición de venta inactivos</p>{" "}
    </div>
  );

  const delete_salecondition = (
    <div>
      {" "}
      <p>Eliminar Condición de venta</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "salecondition_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, salecondition) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "saleconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "saleconditions", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_salecondition}>
                <Button
                  className="button-salecondition"
                  type="default"
                  onClick={() => editSaleCondition(salecondition)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "saleconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "saleconditions", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_salecondition}>
                <Button
                  className="button-salecondition"
                  type="primary"
                  onClick={() => desactivateSaleCondition(salecondition)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "saleconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "saleconditions", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_salecondition}>
                <Button
                  className="button-salecondition"
                  type="danger"
                  onClick={() => showDeleteConfirm(salecondition)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={saleConditionsActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function SaleConditionsInactive(props) {
  const { saleConditionsInactive, setReloadSaleConditions, userToken, permissionsActive, roleById } = props;

  const activateSaleCondition = (salecondition) => {
    const accessToken = getAccessToken();

    activateSaleConditionApi(accessToken, salecondition._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadSaleConditions(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (salecondition) => {
    const accessToken = getAccessToken();
    confirm({
      title: "Eliminando Condición de venta",
      content: `¿Estás seguro que quieres eliminar a ${salecondition.description}?`,
      okText: "Eliminar",
      okMethod: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteSaleConditionApi(accessToken, salecondition._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadSaleConditions(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const activate_salecondition = (
    <div>
      {" "}
      <p>Pasar a Condición de venta activos</p>{" "}
    </div>
  );

  const delete_salecondition = (
    <div>
      {" "}
      <p>Eliminar Condición de venta</p>
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "salecondition_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, salecondition) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "saleconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "saleconditions", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_salecondition}>
                <Button
                  className="button-salecondition"
                  type="primary"
                  onClick={() => activateSaleCondition(salecondition)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "saleconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "saleconditions", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_salecondition}>
                <Button
                  className="button-salecondition"
                  type="danger"
                  onClick={() => showDeleteConfirm(salecondition)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={saleConditionsInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
