import React, { useState, useEffect } from "react";
import {
  Switch,
  List,
  Input,
  onHover,
  Radio,
  Button,
  notification,
  Row,
  Col,
  Form,
  Select,
  Modal as ModalAntd,
} from "antd";
import {
  EditOutlined,
  EyeOutlined,
  FileExclamationOutlined,
  CloseSquareOutlined,
  DeleteOutlined,
  DownloadOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";

import Modal from "../../../Modal";
import EditBuyOrderForm from "../EditBuyOrderForm";
import EditStateForm from "../EditStateForm";
import AddBuyOrderForm from "../AddBuyOrderForm";
import { deleteBuyOrderApi } from "../../../../api/billing/buyOrder";
import SearchByDates from "../../SearchByDates/SearchByDates";
// import { BuyOrdersDetailPdf } from "./pdfBuyOrders";
import { convertState } from "../../../../utils/convert";
//import format from "date-fns/format"
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";
import { formatDateView } from "../../../../utils/formatDate";
import "./ListBuyOrders.scss";

const { confirm } = ModalAntd;
const { Search } = Input;
//import TextArea from "antd/lib/input/TextArea";

export default function ListBuyOrders(props) {
  const {
    token,
    buyOrdersActive,
    buyOrdersInactive,
    // buyOrdersPaid,
    // buyOrdersUnpaid,
    setReloadBuyOrders,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  //console.log(billSellsUnpaid);
  const [viewBuyOrdersActive, setViewBuyOrdersActive] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [modalWidth, setModalWidth] = useState(null);
  const [filterText, setFilterText] = useState("");

  const [buyOrdersActiveFilter, setBuyOrdersActiveFilter] = useState([]);
  const [buyOrdersInactiveFilter, setBuyOrdersInactiveFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState("code");

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, [roleById]);

  useEffect(() => {
    const listPaid = [];
    if (buyOrdersActive) {
      buyOrdersActive.forEach((element) => {
        if (typeFilter === "state") {
            if ( (element.state &&
              element.state.name.toUpperCase().indexOf(filterText.toUpperCase()) !==
                -1)||
              filterText === ""
            ) {
              listPaid.push(element);
            }
        } else {
          if (
            element.code
              .toString()
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listPaid.push(element);
          }
        }
      });
    }
    setBuyOrdersActiveFilter(listPaid);
  }, [filterText, buyOrdersActive]);

  useEffect(() => {
    const listUnpaid = [];
    if (buyOrdersInactive) {
      buyOrdersInactive.forEach((element) => {
        if (typeFilter === "state") {
            if ( (element.state &&
              element.state.name.toUpperCase().indexOf(filterText.toUpperCase()) !==
                -1) ||
              filterText === ""
            ) {
              listUnpaid.push(element);
            }
        } else {
          if (
            element.code
              .toString()
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listUnpaid.push(element);
          }
        }
      });
    }
    setBuyOrdersInactiveFilter(listUnpaid);
  }, [filterText, buyOrdersInactive]);

  const addBillSellModal = async () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Orden de Compra");
    setModalWidth(900);
    await setModalContent(
      <AddBuyOrderForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadBuyOrders={setReloadBuyOrders}
      />
    );
  };

  return (
    <div className="list-buyorders">
      <div className="list-buyorders__header">
        {/*
        <div className="list-billbuys__header-switch">
          <Switch
            defaultChecked
            onChange={() => setViewBillBuysActives(!viewBillBuysActives)}
          />
          <span>
            {viewBillBuysActives
              ? "Facturas de compra habilitadas"
              : "Facturas de compra inhabilitadas"}
          </span>
        </div>
        */}
        {checkAction(permissionsActive, userToken.role, "buyorders", "all") ||
        checkAction(permissionsActive, userToken.role, "buyorders", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-buyorders__header-switch">
              <Switch
                defaultChecked
                onChange={() => setViewBuyOrdersActive(!viewBuyOrdersActive)}
              />
              <span>{viewBuyOrdersActive ? "Activas" : "Inactivas"}</span>
            </div>
          </>
        ) : (
          <>
          </>
        )}

        {checkAction(permissionsActive, userToken.role, "buyorders", "all") ||
        checkAction(permissionsActive, userToken.role, "buyorders", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addBillSellModal}>
              Nuevo 
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "buyorders", "all") ||
      checkAction(permissionsActive, userToken.role, "buyorders", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-buyorders__search">
            <Row>
              <Col span={20}>
                <Search
                  className="Buscar"
                  placeholder=""
                  onChange={(e) => setFilterText(e.target.value)}
                  enterButton
                />
                <Radio.Group
                  options={[
                    { label: "Código", value: "code" },
                    { label: "Estado", value: "state" }, //TODO: lee el state de la db, no el convertido
                  ]}
                  onChange={(e) => {
                    setTypeFilter(e.target.value);
                  }}
                  value={typeFilter}
                />
              </Col>
              <Col span={4}>
                <SearchByDates
                  searchList={viewBuyOrdersActive ? BuyOrdersActive : buyOrdersInactive}
                  setSearchList={
                    viewBuyOrdersActive
                      ? setBuyOrdersActiveFilter
                      : setBuyOrdersInactiveFilter
                  }
                  setReload={setReloadBuyOrders}
                  dataSearch={"date"}
                />
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewBuyOrdersActive ? (
        <>
          {checkAction(permissionsActive, userToken.role, "buyorders", "all") ||
          checkAction(permissionsActive, userToken.role, "buyorders", "view") || isAdmin(roleById) ? (
            <>
              <BuyOrdersActive
                buyOrdersActive={buyOrdersActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setModalWidth={setModalWidth}
                setReloadBuyOrders={setReloadBuyOrders}
                token={token}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "buyorders", "all") ||
          checkAction(permissionsActive, userToken.role, "buyorders", "view") || isAdmin(roleById) ? (
            <>
              <BuyOrdersInactive
                buyOrdersInactive={buyOrdersInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setModalWidth={setModalWidth}
                setReloadBuyOrders={setReloadBuyOrders}
                token={token}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        modalWidth={modalWidth}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function BuyOrdersActive(props) {
  const {
    buyOrdersActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setModalWidth,
    setReloadBuyOrders,
    token,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props; //showDeleteConfirm

  const editBuyOrder= (buyOrder) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar ${buyOrder.code ? buyOrder.code : "sin código"}`);
    setModalWidth(900);
    setModalContent(
      <EditBuyOrderForm
        buyOrder={buyOrder}
        setIsVisibleModal={setIsVisibleModal}
        setReloadBuyOrders={setReloadBuyOrders}
      />
    );
  };

  const editState = (bOrder) => {
    setIsVisibleModal(true);
    setModalTitle(`Estado de Orden de compra ${bOrder.code}`);
    setModalWidth(300);
    setModalContent(
      <EditStateForm
        buyOrder={bOrder}
        setIsVisibleModal={setIsVisibleModal}
        setReloadBuyOrders={setReloadBuyOrders}
      />
    );
  };

  // const addAnulationBuyOrder= async (buyOrder) => {
  //   setIsVisibleModal(true);
  //   setModalTitle("Orden de Compra- Anulación");
  //   setModalWidth(900);
  //   await setModalContent(
  //     <AddBuyOrderForm
  //       setIsVisibleModal={setIsVisibleModal}
  //       setReloadBuyOrders={setReloadBuyOrders}
  //       associatedBuyOrder={buyOrder}
  //     />
  //   );
  // };

  const showDeleteConfirm = (buyOrder) => {

    confirm({
      title: "Eliminando -> Orden de Compra",
      content: `¿Estás seguro que quieres eliminar a ${buyOrder.code ? buyOrder.code: "sin codigo"}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteBuyOrderApi(accessToken, buyOrder._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadBuyOrders(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  return (
    <List
      className="buyorders"
      itemLayout="horizontal"
      dataSource={buyOrdersActive}
      renderItem={(buyOrder) => (
        <BuyOrderActive
          buyOrder={buyOrder}
          editBuyOrder={editBuyOrder}
          editState={editState}
          showDeleteConfirm={showDeleteConfirm}
          setReloadBuyOrders={setReloadBuyOrders}
          token={token}
          accessToken={accessToken}
          userToken={userToken}
          permissionsActive={permissionsActive}
          roleById={roleById}
        />
      )} //showDeleteConfirm={showDeleteConfirm}
    />
  );
}

function BuyOrderActive(props) {
  const {
    buyOrder,
    editBuyOrder,
    editState,
    showDeleteConfirm,
    setReloadBuyOrders,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props; //showDeleteConfirm
  var supplierDenomination = "";

  if (buyOrder.supplier) {
    supplierDenomination = buyOrder.supplier.denomination;
  }

  const edit_buyorder = (
    <div>
      {" "}
      <p>Editar Orden</p>{" "}
    </div>
  );

  const changeState = (
    <div>
      {" "}
      <p>Editar estado</p>{" "}
    </div>
  );

  const deleteOrder = (
    <div>
      {" "}
      <p>Eliminar Orden</p>{" "}
    </div>
  );

  const download_buyorder = (
    <div>
      {" "}
      <p>Descargar Orden</p>{" "}
    </div>
  );

  return (
    <List.Item
      actions={[
        <div>
          {checkAction(permissionsActive, userToken.role, "buyorders", "all") ||
          checkAction(permissionsActive, userToken.role, "buyorders", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_buyorder}>
                <Button type="default" onClick={() => editBuyOrder(buyOrder)}>
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
         <div>
         {checkAction(permissionsActive, userToken.role, "buyorders", "all") ||
         checkAction(permissionsActive, userToken.role, "buyorders", "edit") || isAdmin(roleById) ? (
           <>
             <Popover content={changeState}>
               <Button
                 className="bg-warning"
                 onClick={() => editState(buyOrder)}
               >
                 <FileExclamationOutlined />
               </Button>
             </Popover>
           </>
         ) : (
           <>
           </>
         )}
       </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "buyorders", "all") ||
          checkAction(permissionsActive, userToken.role, "buyorders", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={deleteOrder}>
                <Button type="danger" onClick={() => showDeleteConfirm(buyOrder)}>
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
        // <div>
        //   {checkAction(permissionsActive, userToken.role, "buyorders", "all") ||
        //   checkAction(permissionsActive, userToken.role, "buyorders", "pdf") || isAdmin(roleById) ? (
        //     <>
        //       <Popover content={download_buyorder}>
        //         <Button
        //           type="primary"
        //           onClick={() => {
        //             // buyOrderDetailPdf(buyOrder, false);
        //           }}
        //         >
        //           <DownloadOutlined />
        //         </Button>
        //       </Popover>
        //     </>
        //   ) : (
        //     <>
        //     </>
        //   )}
        // </div>,
      ]}
    >
      <List.Item.Meta
        title={`
                  ${ buyOrder.code ? buyOrder.code : "sin código"
                  },
                  ${
                    formatDateView(buyOrder.date)
                      ? formatDateView(buyOrder.date)
                      : "sin fecha"
                  },
                  ${supplierDenomination ? supplierDenomination : "no hay proveedor"}
                `} //${supplierPaid.name ? supplierPaid.name : "no hay proveedor"}
        description={` ${
          "$" + buyOrder.total ? "$" + buyOrder.total : "no hay total"
        } , ${(buyOrder.state? buyOrder.state.name : "sin estado")} `}
      />
    </List.Item>
  );
}

function BuyOrdersInactive(props) {
  const {
    buyOrdersInactive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setModalWidth,
    setReloadBuyOrders,
    token,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props; //showDeleteConfirm

  const editBuyOrder = (buyOrder) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar ${buyOrder.code ? buyOrder.code : "sin código"}`);
    setModalWidth(900);
    setModalContent(
      <EditBuyOrderForm
        buyOrder={buyOrder}
        setIsVisibleModal={setIsVisibleModal}
        setReloadBuyOrders={setReloadBuyOrders}
      />
    );
  };

  const editState = (bOrder) => {
    setIsVisibleModal(true);
    setModalTitle(`Estado de Orden de compra ${bOrder.code}`);
    setModalWidth(300);
    setModalContent(
      <EditStateForm
        buyOrder={bOrder}
        setIsVisibleModal={setIsVisibleModal}
        setReloadBuyOrders={setReloadBuyOrders}
      />
    );
  };

  const showDeleteConfirm = (buyOrder) => {
    //const accessToken = getAccessToken();

    confirm({
      title: "Eliminando -> Orden de Compra",
      content: `¿Estás seguro que quieres eliminar a ${buyOrder.code ? buyOrder.code: "sin codigo"}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteBuyOrderApi(accessToken, buyOrder._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadBuyOrders(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  return (
    <List
      className="buyorders"
      itemLayout="horizontal"
      dataSource={buyOrdersInactive}
      renderItem={(buyOrder) => (
        <BuyOrderInactive
          buyOrder={buyOrder}
          editBuyOrder={editBuyOrder}
          editState={editState}
          showDeleteConfirm={showDeleteConfirm}
          setReloadBuyOrders={setReloadBuyOrders}
          token={token}
          accessToken={accessToken}
          userToken={userToken}
          permissionsActive={permissionsActive}
          roleById={roleById}
        />
      )} 
    />
  );
}

function BuyOrderInactive(props) {
  const {
    buyOrder,
    editState,
    viewBuyOrder,
    editBuyOrder,
    showDeleteConfirm,
    setReloadBuyOrder,
    token,
    userToken,
    permissionsActive,
    roleById
  } = props; //showDeleteConfirm
  var supplierDenomination = "";

  if (buyOrder.supplier) {
    supplierDenomination = buyOrder.supplier.denomination;
  }

  const edit_buyorder = (
    <div>
      {" "}
      <p>Editar Orden</p>{" "}
    </div>
  );

  const changeState = (
    <div>
      {" "}
      <p>Editar estado</p>{" "}
    </div>
  );

  const download_buyorder= (
    <div>
      {" "}
      <p>Descargar Orden</p>{" "}
    </div>
  );

  const delete_buyorder = (
    <div>
      {" "}
      <p>Eliminar Orden</p>{" "}
    </div>
  );

  return (
    <List.Item
      actions={[
       
        <div>
          {checkAction(permissionsActive, userToken.role, "buyorders", "all") ||
          checkAction(permissionsActive, userToken.role, "buyorders", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_buyorder}>
                <Button type="default" onClick={() => editBuyOrder(buyOrder)}>
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
        <div>
        {checkAction(permissionsActive, userToken.role, "buyorders", "all") ||
        checkAction(permissionsActive, userToken.role, "buyorders", "edit") || isAdmin(roleById) ? (
          <>
            <Popover content={changeState}>
              <Button
                className="bg-warning"
                onClick={() => editState(buyOrder)}
              >
                <FileExclamationOutlined />
              </Button>
            </Popover>
          </>
        ) : (
          <>
          </>
        )}
      </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "buyorders", "all") ||
          checkAction(permissionsActive, userToken.role, "buyorders", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_buyorder}>
                <Button type="danger" onClick={() => showDeleteConfirm(buyOrder)}>
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
        // <div>
        //   {checkAction(permissionsActive, userToken.role, "billsells", "all") ||
        //   checkAction(permissionsActive, userToken.role, "billsells", "pdf") || isAdmin(roleById) ? (
        //     <>
        //       <Popover content={download_buyorder}>
        //         <Button
        //           type="primary"
        //           onClick={() => {
        //             // buyOrderDetailPdf(buyOrder, false);
        //           }}
        //         >
        //           <DownloadOutlined />
        //         </Button>
        //       </Popover>
        //     </>
        //   ) : (
        //     <>
        //     </>
        //   )}
        // </div>,
      ]}
    >
      <List.Item.Meta
        title={`
                  ${buyOrder.code ? buyOrder.code : "sin código"},
                  ${
                    formatDateView(buyOrder.date)
                      ? formatDateView(buyOrder.date)
                      : "sin fecha"
                  },
                  ${supplierDenomination ? supplierDenomination : "no hay proveedor"}
                `}
        description={` ${
          "$" + buyOrder.total ? "$" + buyOrder.total : "no hay total"
        }, ${(buyOrder.state? buyOrder.state.name : "sin estado")} `}
      />
    </List.Item>
  );
}
