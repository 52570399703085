//import React from 'react';
import React, { useState, useEffect } from "react";
import { Button, Popover } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, PoweroffOutlined, UserOutlined } from '@ant-design/icons';
import {
    Modal as ModalAntd
} from "antd";
import {
    getUserAvatarApi,
    getUserByIdApi
} from "../../../api/user"; //uploadUserAvatarApi, updateUserApi

//import Icon from '@ant-design/icons';
import AppLogo from '../../../assets/img/png/resto.png';
import { logout, getAccessToken } from '../../../api/auth';
//import NoAvatar from "../../../assets/img/png/no-avatar.png";
import Modal from "../../Modal";
//import EditUserForm from "d:/REACT/default/mern-default/client/src/components/Admin/Users/EditUserForm";
import EditUserForm from "../Users/EditUserForm";
import BranchSelector from "../BranchSelector/BranchSelector";
import BusinessSelector from "../BusinessSelector/BusinessSelector";
import jwtDecode from "jwt-decode";

import "./MenuTop.scss";
import { useBranch } from "../../../hooks/useBranch";
import { useBusiness } from "../../../hooks/useBusiness";

const { confirm } = ModalAntd;

export default function MenuTop(props) {
    const { menuCollapsed, setMenuCollapsed } = props; //setReloadUser, userProfile
    const [avatar, setAvatar] = useState(null);
    const [ isVisibleModal, setIsVisibleModal ] = useState(false);
    const [ modalTitle, setModalTitle ] = useState("");
    const [ modalContent, setModalContent ] = useState(null)
    const [ reloadUsers, setReloadUsers ] = useState(false);
    const [userData, setUserData] = useState({});
    const [userProfile, setUserProfile] = useState([]);
    //const [isValid, setIsValid] = useState(false);

    const accessToken = getAccessToken();
    const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

    // const { changeBranch } = useBranch();
    // const { business } = useBusiness();

    useEffect(() => {
        getUserByIdApi(accessToken, userToken._id).then(response => {
            setUserProfile(response.user);
        });
        setReloadUsers(false);
    }, [accessToken, reloadUsers]);

    //trae los datos del user
    useEffect(() => {
        setUserData({
            name: userProfile.name,
            lastname: userProfile.lastname,
            email: userProfile.email,
            role: userProfile.role,
            avatar: userProfile.avatar
        });
    }, []);

    // useEffect(() => {
    //     changeBranch("")
    // }, [business]);

    //console.log("userProfile", userProfile);
    //console.log("userData", userData);

    //si tiene avatar lo trae, sino lo deja vacio
    useEffect(() => {
        if (userProfile.avatar) {
            //console.log(userProfile.avatar);
            getUserAvatarApi(userProfile.avatar).then((response) => {
                setAvatar(response);
            });
        } else {
            setAvatar(null);
        }
    }, []);

    //si se carga un avatar lo prepara para enviar a la db
    useEffect(() => {
        let avatar = userProfile.avatar
        //console.log("avatar", avatar);
        if (avatar) {
            setUserData({ ...userData, avatar: avatar.file });
        }
    }, [avatar]);

    const logoutUser = () => {
        logout(); //borra los token del localStorage
        //ahora se puede hacer un redirect o windows reload
        window.location.reload();
    };
    const showLogoutConfirm = () => {
        if(user.name){
            confirm({
                title: "Cerrar sesión",
                content: `¿Estás seguro que quieres cerrar la sesión de ${userProfile.name}?`,
                okText: "Si",
                okType: "warning",
                cancelText: "No",
                onOk() {
                    logoutUser();
                },
            });
        }
        else if(user.name === undefined) {
            confirm({
                title: "Cerrar sesión",
                content: `¿Estás seguro que quieres cerrar la sesión de ${userProfile.email}?`,
                okText: "Si",
                okType: "warning",
                cancelText: "No",
                onOk() {
                    logoutUser();
                },
            });
        } else {
            confirm({
                title: "Cerrar sesión",
                content: `¿Estás seguro que quieres cerrar la sesión de ${userProfile.email}?`,
                okText: "Si",
                okType: "warning",
                cancelText: "No",
                onOk() {
                    logoutUser();
                },
            });
        }

    };

    const user = userProfile;
    const editUser = () => {
        //console.log(user);
        setIsVisibleModal(true);
        setModalTitle(`Editar usuario ${userProfile.email ? userProfile.username : "usuario"}`);
        setModalContent(<EditUserForm user={user} setIsVisibleModal={setIsVisibleModal} setReloadUsers={setReloadUsers}/>);
    };

    const show_logout = (
        <div>
            {" "}
            <p>Cerrar sesión</p>{" "}
        </div>
    );

    const show_profile = (
        <div>
            {" "}
            <p>Ver perfil</p>{" "}
        </div>
    );

    return (
        <div className="menu-top">
            <div className="menu-top__left">
                <img
                    className="menu-top__left-logo"
                    src={AppLogo}
                    alt="BNV"
                />
                <Button type="link" onClick={() => setMenuCollapsed(!menuCollapsed)} >
                    {menuCollapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
                </Button>
            </div>
            <div className="menu-top__pre_right_business">
                <Popover content={show_profile}>
                    <BusinessSelector/>
                </Popover>
            </div>
            <div className="menu-top__pre_right_branch">
                <Popover content={show_profile}>
                    <BranchSelector/>
                </Popover>
            </div>
            <div className="menu-top__pre_right">
                {userProfile.username} &nbsp;
                <Popover content={show_profile}>
                    <Button type="link" onClick={editUser}>
                    {/*<Button type="link" onClick={() => editUser(user)}>*/}
                        {/*<Icon type="poweroff" />*/}
                        <UserOutlined />
                    </Button>
                </Popover>
            </div>
            <div className="menu-top__right">
                <Popover content={show_logout}>
                    <Button type="link" onClick={showLogoutConfirm}>
                        {/*<Icon type="poweroff" />*/}
                        <PoweroffOutlined />
                    </Button>
                </Popover>
            </div>
            <Modal
                title={modalTitle}
                isVisible={isVisibleModal}
                setIsVisible={setIsVisibleModal}
            >
                {modalContent}
            </Modal>
        </div>
    );
}
