//import { Result } from "antd";
import { switchPath, apiVersion } from './config';


export function getMenuExportApi(token) {
  const url = `${switchPath()}/${apiVersion}/menu-export-csv`;
  const params = {
      method: "GET",
      headers: {
          "Content-Type": "text/csv",
          Authorization: token
      }
  };

  return fetch(url, params)
        .then(response => {
            return response.text();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'menu.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(err => {
            return err.message;
        });
}

export function postMenuImportApi(token, file) {
  const url = `${switchPath()}/${apiVersion}/menu-import-csv`;
  const formData = new FormData();
  formData.append("file", file, file.name);

  const params = {
      method: "POST",
      body: formData,
      headers: {
          Authorization: token
      }
  };

  return fetch(url, params)
      .then(response => {
          return response.json();
      })
      .then(data => {
          return data;
      })
      .catch(err => {
          return err.message;
      });
}

//Funcion que permite obtener los menus
export function getMenuApi(token) {
  const url = `${switchPath()}/${apiVersion}/menu`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    }
  };

  return fetch(url,params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

export function getMenuViewApi(token) {
  const url = `${switchPath()}/${apiVersion}/menu-view`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token
    }
  };

  return fetch(url,params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

//Funcion para actualizar menu
export function updateMenuApi(token, menuId, data) {
  const url = `${switchPath()}/${apiVersion}/update-menu/${menuId}`;
  const params = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
    body: JSON.stringify(data)
  };
  console.log(url);
  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

//Funcion para activar el menu
export function activateMenuApi(token, menuId, status) {
  const url = `${switchPath()}/${apiVersion}/activate-menu/${menuId}`;

  const params = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
    body: JSON.stringify({ active: status })
  };

  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

//Funcion para agregar un nuevo menu
export function addMenuApi(token, dataMenu) {
  const url = `${switchPath()}/${apiVersion}/add-menu`;

  const params = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    },
    body: JSON.stringify(dataMenu)
  };

  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}

export function getMenusActiveApi(token, status) {
  const url = `${switchPath()}/${apiVersion}/menu-active?active=${status}`;
  console.log(url);
  const params = {
      method: "GET",
      headers: {
          "Content-Type": "application/json",
          Authorization: token
      }
  };

  return fetch(url, params)
      .then(response => {
        console.log(response);
          return response.json();
      })
      .then(result => {
          //console.log(result);
          return result;
      })
      .catch(err => {
          return err.message;
      });
}


//Funcion para eliminar Menu
export function deleteMenuApi(token, menuId) {
  const url = `${switchPath()}/${apiVersion}/delete-menu/${menuId}`;

  const params = {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      Authorization: token
    }
  };

  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return err.message;
    });
}
