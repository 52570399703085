import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import {
  TagOutlined,
} from "@ant-design/icons";
import { updateStateBuyOrderApi } from "../../../../api/billing/stateBuyOrder";
import { getAccessToken } from "../../../../api/auth";

import "./EditStateBuyOrderForm.scss";

export default function EditStateBuyOrderForm(props) {
  const { stateBuyOrder, setIsVisibleModal, setReloadStateBuyOrders } = props;
  const [ stateBuyOrderData, setStateBuyOrderData ] = useState({});


  //trae los datos de tipo de documento
  useEffect(() => {
    setStateBuyOrderData({
      statebuyorder_code: stateBuyOrder.statebuyorder_code,
      name: stateBuyOrder.name
    });
  }, [stateBuyOrder]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!stateBuyOrderData.statebuyorder_code || !stateBuyOrderData.description) {
      notification["error"]({
        message: "Obligatorios: Código y Descripción.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const updateStateBuyOrder = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let stateBuyOrderUpdate = stateBuyOrderData; //es let porque se va actualizando

      updateStateBuyOrderApi(token, stateBuyOrderUpdate, stateBuyOrder._id).then((result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //console.log("respuesta: " + result.message);
          setIsVisibleModal(false);
          setReloadStateBuyOrders(true);
        }
      });
    }
  };

  return (
    <div className="edit-stateBuyOrder-form">
      <EditForm
        stateBuyOrderData={stateBuyOrderData}
        setStateBuyOrderData={setStateBuyOrderData}
        updateStateBuyOrder={updateStateBuyOrder}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    stateBuyOrderData,
    setStateBuyOrderData,
    updateStateBuyOrder,
  } = props;

  return (
    <Form className="form-edit" onSubmit={updateStateBuyOrder}>
      <Row gutter={24}>
        <Col span={12}>
          <label className="control-label">Código</label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Código"
              value={stateBuyOrderData.statebuyorder_code}
              onChange={(e) =>
                setStateBuyOrderData({ ...stateBuyOrderData, statebuyorder_code: e })
              }
              min={1}
              style={{ width: "90%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label"><span className="control-required">* </span>Nombre</label>
          <Form.Item>
            <Input
              placeholder="Nombre"
              value={stateBuyOrderData.name}
              onChange={(e) =>
                setStateBuyOrderData({ ...stateBuyOrderData, name: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateStateBuyOrder}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
