import React, { createContext, useState, useContext, useEffect } from 'react';

// Creamos el contexto
export const BranchContext = createContext();

// Proveedor del contexto
export default function BranchProvider (props) {
  const { children } = props;
  const [branch, setBranch] = useState(() => {
    const storedBranch = localStorage.getItem('branch');
    return storedBranch ? storedBranch : '0';
  }); // Estado inicial del idioma, por defecto '' 

  const changeBranch = (newData) => {
    setBranch(newData);
    //window.location.reload(); // Refrescar la página
  };

  useEffect(() => {
    localStorage.setItem('branch', branch);
  }, [branch]);

  return (
    <BranchContext.Provider value={{ branch, changeBranch }}>
      {children}
    </BranchContext.Provider>
  );
};

// // Hook personalizado para consumir el contexto
// export const useBranch= () => {
//   const context = useContext(BranchContext);
//   if (!context) {
//     throw new Error('useSitio debe ser usado dentro de un SitioProvider');
//   }
//   return context;
// };
