import React from 'react';
import ReactDOM from 'react-dom';
import  "antd/dist/antd.css";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
//import { BrowserRouter } from "react-router-dom";

/*
const React = require('react');
const ReactDOM = require('react-dom');
const antdcss = require('antd/dist/antd.css');
const indexcss = require('./index.css');
const App = require('./App');
const serviceWorker = require('./serviceWorker');
*/


//ReactDOM.render(
ReactDOM.hydrate(
  <React.StrictMode>
       <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
