import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  Divider,
  notification,
} from "antd";

import { updateStockInventoryApi } from "../../../../api/billing/stockInventory";
import { getAccessToken } from "../../../../api/auth";

import "./EditStockInventoryForm.scss";

export default function EditStockInventoryForm(props) {
  const {
    itemStockInventory,
    setIsVisibleModal,
    setReloadStockInventory,
  } = props;

  const [itemInventoryStockData, setItemInventoryStockData] = useState({});

  //trae los datos del inventario
  useEffect(() => {
    setItemInventoryStockData({
      item: itemStockInventory.item,
      quantity_init: itemStockInventory.quantity_init,
      quantity_sold: itemStockInventory.quantity_sold,
      quantity_purchased: itemStockInventory.quantity_purchased,
      stock: itemStockInventory.stock,
      observation: itemStockInventory.observation,
    });
  }, [itemStockInventory]);

  const isFormValid = (e) => {
    let errorExists = false;
    if (!itemInventoryStockData.item) {
      notification["error"]({
        message: "Obligatorios: Articulo",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const editStockInventory = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      const accessToken = getAccessToken();
      await updateStockInventoryApi(
        accessToken,
        itemStockInventory._id,
        itemInventoryStockData
      )
        .then((response) => {
          if (
            response.message === "ERR_CONNECTION_REFUSED" ||
            response.message === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              //message: response
              message: "Inventario actualizado",
            });
            setIsVisibleModal(false);
          }
          setReloadStockInventory(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  return (
    <div className="edit-stock-inventory-form">
      <EditForm
        itemInventoryStockData={itemInventoryStockData}
        setItemInventoryStockData={setItemInventoryStockData}
        editStockInventory={editStockInventory}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    itemInventoryStockData,
    setItemInventoryStockData,
    editStockInventory,
  } = props;

  return (
    <Form className="form-edit" onSubmit={editStockInventory}>
      <Divider orientation="center">Articulo</Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">Codigo</label>
          <Form.Item>
            <Input
              className="select-form"
              placeholder="Codigo"
              value={
                itemInventoryStockData.item
                  ? itemInventoryStockData.item.item_code
                  : ""
              }
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">Descripcion</label>
          <Form.Item>
            <Input
              placeholder="descripcion"
              value={
                itemInventoryStockData.item
                  ? itemInventoryStockData.item.description
                  : ""
              }
              disabled={true}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">Precio 1</label>
          <Form.Item>
            <InputNumber
              name="price1"
              placeholder="Precio 1"
              value={
                itemInventoryStockData.item
                  ? itemInventoryStockData.item.price1
                  : ""
              }
              disabled={true}
              size="middle"
              decimalSeparator=","
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">Precio 2</label>
          <Form.Item>
            <InputNumber
              placeholder="Precio 2"
              name="price2"
              value={
                itemInventoryStockData.item
                  ? itemInventoryStockData.item.price2
                  : ""
              }
              disabled={true}
              size="middle"
              decimalSeparator=","
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation="center">Control Stock</Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">Cantidad Inicial</label>
          <Form.Item>
            <InputNumber
              placeholder="Cantidad inicial de articulos"
              name="quantity_init"
              value={itemInventoryStockData.quantity_init}
              disabled={true}
              // onChange={(e) =>
              //   setItemInventoryStockData({
              //     ...itemInventoryStockData,
              //     quantity_init: e,
              //   })
              // }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">Stock actual</label>
          <Form.Item>
            <InputNumber
              placeholder="stock"
              name="stock"
              value={itemInventoryStockData.stock}
              disabled={true}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">Cantidad Compra</label>
          <Form.Item>
            <InputNumber
              name="quantity_purchased"
              value={itemInventoryStockData.quantity_purchased}
              disabled={true}
              size="middle"
              decimalSeparator=","
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">Cantidad Venta</label>
          <Form.Item>
            <InputNumber
              placeholder="Precio 2"
              name="quantity_sold"
              value={itemInventoryStockData.quantity_sold}
              disabled={true}
              size="middle"
              decimalSeparator=","
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider></Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">Observaciones</label>
          <Form.Item>
            <Input
              placeholder="Observaciones"
              name="observation"
              value={itemInventoryStockData.observation}
              onChange={(e) =>
                setItemInventoryStockData({
                  ...itemInventoryStockData,
                  observation: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={editStockInventory}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
