import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Select,
  Row,
  Col,
  notification,
} from "antd";
import { TagOutlined } from "@ant-design/icons"; //EnvironmentOutlined
import { updateSaleConditionApi } from "../../../../api/billing/saleCondition";
import { getAccessToken } from "../../../../api/auth";
//import { numberValidation } from "../../../../utils/formValidation";

import "./EditSaleConditionForm.scss";
//import { activateBillSystem } from "../../../../../../server/controllers/billSystem";

export default function EditSaleConditionForm(props) {
  const { salecondition, setIsVisibleModal, setReloadSaleConditions } = props;
  const [saleConditionData, setSaleConditionData] = useState({});

  console.log("prueba");
  console.log(props);
  //const accessToken = getAccessToken();

  /*
  const [modifDataToValid, setModifDataToValid] = useState({
    phone: false
  });
  const [validData, setValidData] = useState({
    email: false,
  });*/

  //trae los datos de condicion de venta
  useEffect(() => {
    if (salecondition) {
      setSaleConditionData({
        description: salecondition.description,
        salecondition_code: salecondition.salecondition_code,
      });
    }
  }, [salecondition]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    //if (!saleConditionData.salecondition_code || !saleConditionData.fantasy_name) {
    //  notification["error"]({
    //    message: "Obligatorios: Todos.",
    //  });
    //  errorExists = true;
    //}
    /*
    else if (modifDataToValid.phone) {
      if (!validData.phone) {
        //solo si el email está vacio, falta la validación en el return de EditForm
        notification["error"]({
          message: "Ingreso de teléfono inválido"
        });
        errorExists = true;
      }
    }*/
    //else {
    //  errorExists = false;
    //}
    return errorExists;
  };

  const updateSaleCondition = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let saleConditionUpdate = saleConditionData; //es let porque se va actualizando

      updateSaleConditionApi(
        token,
        saleConditionUpdate,
        salecondition._id
      ).then((result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //console.log("respuesta: " + result.message);
          setIsVisibleModal(false);
          setReloadSaleConditions(true);
        }
      });
    }
  };

  return (
    <div className="edit-salecondition-form">
      <EditForm
        saleConditionData={saleConditionData}
        setSaleConditionData={setSaleConditionData}
        updateSaleCondition={updateSaleCondition}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    saleConditionData,
    setSaleConditionData,
    updateSaleCondition,
  } = props; //setModifDataToValid, modifDataToValid, validData, setValidData
  //const { Option } = Select;

  //validaciones de inputs
  const inputValidation = (e) => {
    const { type, name } = e.target;

    setSaleConditionData({
      ...saleConditionData,
      [name]: e.target.value,
    });
    /*
    setModifDataToValid({
      ...modifDataToValid,
      [name]: true,
    });
    if (name === "phone"){
      setValidData({
          ...validData,
          [name]: numberValidation(e.target)
      });
    }*/
  };

  return (
    <Form className="form-edit" onSubmit={updateSaleCondition}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Código"
              value={saleConditionData.salecondition_code}
              onChange={(e) =>
                setSaleConditionData({
                  ...saleConditionData,
                  salecondition_code: e,
                })
              }
              min={1}
              style={{ width: "90%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">* </span>Descripción
          </label>
          <Form.Item>
            <Input
              placeholder="Descripción"
              value={saleConditionData.description}
              onChange={(e) =>
                setSaleConditionData({
                  ...saleConditionData,
                  description: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateSaleCondition}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
