import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getBuyBillsActiveApi, getBuyBillsPaidApi } from "../../../api/billing/buyBill";
//import { getSupplierApi } from "../../../../api/billing/supplier";
import ListBuyBills from "../../../components/Billing/BuyBills/ListBuyBills";

import "./BuyBills.scss";

export default function BuyBills() {
    const [buyBillsActive, setBuyBillsActive] = useState([]);
    const [buyBillsInactive, setBuyBillsInactive] = useState([]);
    const [buyBillsPaid, setBuyBillsPaid] = useState([]);
    const [buyBillsUnpaid, setBuyBillsUnpaid] = useState([]);
    const [reloadBuyBills, setReloadBuyBills ] = useState(false);

    const token = getAccessToken();

    useEffect(() => {
        getBuyBillsActiveApi(token, true).then(response => {
            setBuyBillsActive(response.buyBills);
            //console.log(response);
        });
        getBuyBillsActiveApi(token, false).then(response => {
            setBuyBillsInactive(response.buyBills);
            //console.log(response);
        });

        setReloadBuyBills(false);
    }, [token, reloadBuyBills]);

    useEffect(() => {
        getBuyBillsPaidApi(token, true).then(response => {
            setBuyBillsPaid(response.buyBills);
        });
        getBuyBillsPaidApi(token, false).then(response => {
            setBuyBillsUnpaid(response.buyBills);
        });
        setReloadBuyBills(false);
    }, [token, reloadBuyBills]);

    return (
        <div>
            <div className="buybills">
                <ListBuyBills buyBillsActive={buyBillsActive} buyBillsInactive={buyBillsInactive}
                                buyBillsPaid={buyBillsPaid} buyBillsUnpaid={buyBillsUnpaid}
                                setReloadBuyBills={setReloadBuyBills} token={token}  />
            </div>
        </div>

    );
}