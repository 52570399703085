import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditSalePointForm from "../EditSalePointForm";
import AddSalePointForm from "../AddSalePointForm";
import { activateSalePointApi, deleteSalePointApi } from "../../../../api/billing/salePoint";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import { getAccessToken } from "../../../../api/auth";

import "./ListSalePoints.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListSalePoints(props) {
  const {
    salePointsActive,
    salePointsInactive,
    setReloadSalePoints,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewSalePointsActives, setViewSalePointsActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [salePointsActiveFilter, setSalePointsActiveFilter] = useState(
    []
  );
  const [ salePointsInactiveFilter, setSalePointsInactiveFilter ] = useState([]);
  const [typeFilter, setMethodFilter] = useState("name");

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, [roleById]);

  useEffect(() => {
    const listActive = [];
    if (salePointsActive) {
      salePointsActive.forEach((element) => {
        if (typeFilter === "name") {
          if (
            element.fantasy_name
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        } else {
          if (
            element.salepoint_code
              .toString()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        }
      });
    }
    setSalePointsActiveFilter(listActive);
  }, [filterText, salePointsActive]);

  useEffect(() => {
    const listInactive = [];
    if (salePointsInactive) {
      salePointsInactive.forEach((element) => {
        if (typeFilter === "name") {
          if (
            element.fantasy_name
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        } else {
          if (
            element.salepoint_code
              .toString()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        }
      });
    }
    setSalePointsInactiveFilter(listInactive);
  }, [filterText, salePointsInactive]);

  const addSalePointsModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Punto de venta");
    setModalContent(
      <AddSalePointForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadSalePoints={setReloadSalePoints}
      />
    );
  };

  return (
    <div className="list-salepoints">
      <div className="list-salepoints__header">
        {checkAction(permissionsActive, userToken.role, "salepoints", "all") ||
        checkAction(permissionsActive, userToken.role, "salepoints", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-salepoints__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewSalePointsActives(!viewSalePointsActives)
                }
              />
              <span>
                {viewSalePointsActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
        ) : (
          <>
          </>
        )}

        {checkAction(permissionsActive, userToken.role, "salepoints", "all") ||
        checkAction(permissionsActive, userToken.role, "salepoints", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addSalePointsModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "salepoints", "all") ||
      checkAction(permissionsActive, userToken.role, "salepoints", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-salepoints__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <Radio.Group
              options={[
                { label: "Nombre de fantasía", value: "name" },
                { label: "Código", value: "code" },
              ]}
              onChange={(e) => {
                setMethodFilter(e.target.value);
              }}
              value={typeFilter}
            />
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewSalePointsActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "salepoints", "all") ||
          checkAction(permissionsActive, userToken.role, "salepoints", "view") || isAdmin(roleById) ? (
            <>
              <SalePointsActive
                salePointsActive={salePointsActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadSalePoints={setReloadSalePoints}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "salepoints", "all") ||
          checkAction(permissionsActive, userToken.role, "salepoints", "view") || isAdmin(roleById) ? (
            <>
              <SalePointsInactive
                salePointsInactive={salePointsInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadSalePoints={setReloadSalePoints}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function SalePointsActive(props) {
  const {
    salePointsActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadSalePoints,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editSalePoint = (salepoint) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${salepoint.fantasy_name ? salepoint.fantasy_name : "sin nombre"}`
    );
    setModalContent(
      <EditSalePointForm
        salepoint={salepoint}
        setIsVisibleModal={setIsVisibleModal}
        setReloadSalePoints={setReloadSalePoints}
      />
    );
  };

  const desactivateSalePoint = (salepoint) => {
    const accessToken = getAccessToken();

    activateSalePointApi(accessToken, salepoint._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadSalePoints(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (salepoint) => {
    const accessToken = getAccessToken();
    confirm({
      title: "Eliminando Punto de venta",
      content: `¿Estás seguro que quieres eliminar a ${salepoint.fantasy_name}?`,
      okText: "Eliminar",
      okMethod: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteSalePointApi(accessToken, salepoint._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadSalePoints(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const edit_salepoint = (
    <div>
      {" "}
      <p>Editar Punto de venta</p>{" "}
    </div>
  );

  const desactivate_salepoint = (
    <div>
      {" "}
      <p>Pasar a Puntos de venta inactivos</p>{" "}
    </div>
  );

  const delete_salepoint = (
    <div>
      {" "}
      <p>Eliminar Punto de venta</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "salepoint_code",
    },
    {
      title: "Nombre de fantasía",
      dataIndex: "fantasy_name",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, salepoint) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "salepoints", "all") ||
          checkAction(permissionsActive, userToken.role, "salepoints", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_salepoint}>
                <Button
                  className="button-salepoint"
                  type="default"
                  onClick={() => editSalePoint(salepoint)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "salepoints", "all") ||
          checkAction(permissionsActive, userToken.role, "salepoints", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_salepoint}>
                <Button
                  className="button-salepoint"
                  type="primary"
                  onClick={() => desactivateSalePoint(salepoint)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "salepoints", "all") ||
          checkAction(permissionsActive, userToken.role, "salepoints", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_salepoint}>
                <Button
                  className="button-salepoint"
                  type="danger"
                  onClick={() => showDeleteConfirm(salepoint)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={salePointsActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function SalePointsInactive(props) {
  const { salePointsInactive,
          setReloadSalePoints,
          accessToken,
          userToken,
          permissionsActive,
          roleById
        } = props;

  const activateSalePoint = (salepoint) => {
    const accessToken = getAccessToken();

    activateSalePointApi(accessToken, salepoint._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadSalePoints(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (salepoint) => {
    const accessToken = getAccessToken();
    confirm({
      title: "Eliminando Punto de venta",
      content: `¿Estás seguro que quieres eliminar a ${salepoint.fantasy_name}?`,
      okText: "Eliminar",
      okMethod: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteSalePointApi(accessToken, salepoint._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadSalePoints(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const activate_salepoint = (
    <div>
      {" "}
      <p>Pasar a Puntos de venta activos</p>{" "}
    </div>
  );

  const delete_salepoint = (
    <div>
      {" "}
      <p>Eliminar Punto de venta</p>
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "salepoint_code",
    },
    {
      title: "Nombre de fantasía",
      dataIndex: "fantasy_name",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, salepoint) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "salepoints", "all") ||
          checkAction(permissionsActive, userToken.role, "salepoints", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_salepoint}>
                <Button
                  className="button-salepoint"
                  type="primary"
                  onClick={() => activateSalePoint(salepoint)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "salepoints", "all") ||
          checkAction(permissionsActive, userToken.role, "salepoints", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_salepoint}>
                <Button
                  className="button-salepoint"
                  type="danger"
                  onClick={() => showDeleteConfirm(salepoint)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={salePointsInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
