import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getMovementsApi } from "../../../api/billing/movement";
import ListMovements from "../../../components/Billing/Movements/ListMovements";


export default function Movements(props) {
    const [movements, setMovements] = useState([]);
    const [reloadMovements, setReloadMovements ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getMovementsApi(token).then(response => {
            setMovements(response.movements);
        });
        setReloadMovements(false);
    }, [token, reloadMovements]);

    return (
        <div className="m">

             <ListMovements movements={movements} setReloadMovements={setReloadMovements} 
                />
        
        </div>
    );
}