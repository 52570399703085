import React, { useState, useEffect } from "react";
import {
  Switch,
  List,
  Input,
  onHover,
  Radio,
  Button,
  notification,
  Row,
  Col,
  Form,
  Select,
  Modal as ModalAntd,
} from "antd";
import {
  EditOutlined,
  EyeOutlined,
  FileExclamationOutlined,
  CloseSquareOutlined,
  DownloadOutlined,
  PlusOutlined,
  DeleteOutlined
} from "@ant-design/icons";

import { Popover } from "antd";

import Modal from "../../../Modal";
import EditBuyBillForm from "../EditBuyBillForm";
import EditStateForm from "../EditStateForm";
import ViewBuyBillForm from "../ViewBuyBillForm";
import AddBuyBillForm from "../AddBuyBillForm";
//import { getAccessToken } from "../../../../api/auth";
import { deleteBuyBillApi } from "../../../../api/billing/buyBill";
import SearchByDates from "../../SearchByDates/SearchByDates";
import { buyBillDetailPdf } from "./pdfBuyBills";
import { convertState } from "../../../../utils/convert";
//import format from "date-fns/format"
import { formatDateView } from "../../../../utils/formatDate";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";
import "./ListBuyBills.scss";

const { confirm } = ModalAntd;
const { Search } = Input;
//import TextArea from "antd/lib/input/TextArea";

export default function ListBuyBills(props) {
  const {
    token,
    buyBillsActive,
    buyBillsInactive,
    buyBillsPaid,
    buyBillsUnpaid,
    setReloadBuyBills,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);

  const [viewBuyBillsPaids, setViewBuyBillsPaids] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [modalWidth, setModalWidth] = useState(null);
  const [filterText, setFilterText] = useState("");

  const [buyBillsPaidFilter, setBuyBillsPaidFilter] = useState([]);
  const [buyBillsUnpaidFilter, setBuyBillsUnpaidFilter] = useState(
    []
  );
  const [typeFilter, setTypeFilter] = useState("code");

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, [roleById]);

  /*
  useEffect(() => {
    const listActive = [];
    if (billBuysActive) {
      billBuysActive.forEach((element) => {
        if (typeFilter === "state") {
          if (element.state.indexOf(filterText) != -1 || filterText === "") {
            listActive.push(element);
          }
        } else {
          if (element.billbuy_code.toString() === filterText || filterText === "") {
            listActive.push(element);
          }
        }
      });
    }
    setBillBuysActiveFilter(listActive);
  }, [filterText, billBuysActive]);

  useEffect(() => {
    const listInactive = [];
    if (billBuysInactive) {
      billBuysInactive.forEach((element) => {
        if (typeFilter === "state") {
          if (element.state.indexOf(filterText) != -1 || filterText === "") {
            listInactive.push(element);
          }
        } else {
          if (element.billbuy_code.toString() === filterText || filterText === "") {
            listInactive.push(element);
          }
        }
      });
    }
    setBillBuysInactiveFilter(listInactive);
  }, [filterText, billBuysInactive]);
  */

  useEffect(() => {
    const listPaid = [];
    if (buyBillsPaid) {
      buyBillsPaid.forEach((element) => {
        if (typeFilter === "state") {
            if ( (element.state &&
              element.state.name.toUpperCase().indexOf(filterText.toUpperCase()) !==
                -1) ||
              filterText === ""
            ) {
              listPaid.push(element);
            }
        } else {
          if (
            element.code
              .toString()
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listPaid.push(element);
          }
        }
      });
    }
    setBuyBillsPaidFilter(listPaid);
  }, [filterText, buyBillsPaid]);

  useEffect(() => {
    const listUnpaid = [];
    if (buyBillsUnpaid) {
      buyBillsUnpaid.forEach((element) => {
        if (typeFilter === "state") {
            if ( (element.state &&
              element.state.name.toUpperCase().indexOf(filterText.toUpperCase()) !==
                -1) ||
              filterText === ""
            ) {
              listUnpaid.push(element);
            }
        } else {
          if (
            element.code
              .toString()
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listUnpaid.push(element);
          }
        }
      });
    }
    setBuyBillsUnpaidFilter(listUnpaid);
  }, [filterText, buyBillsUnpaid]);

  const addBuyBillModal = async () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Comprobante de compra");
    setModalWidth(1000);
    await setModalContent(
      <AddBuyBillForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadBuyBills={setReloadBuyBills}
      />
    );
  };

  // //const [inputs, setInputs] = useState({ state: "", date_buy: "", observation: "" });

  return (
    <div className="list-buybills">
      <div className="list-buybills__header">
        {/*
        <div className="list-billbuys__header-switch">
          <Switch
            defaultChecked
            onChange={() => setViewBillBuysActives(!viewBillBuysActives)}
          />
          <span>
            {viewBillBuysActives
              ? "Facturas de compra habilitadas"
              : "Facturas de compra inhabilitadas"}
          </span>
        </div>
        */}
        {checkAction(permissionsActive, userToken.role, "buybills", "all") ||
        checkAction(permissionsActive, userToken.role, "buybills", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-buybills__header-switch">
              <Switch
                defaultChecked
                onChange={() => setViewBuyBillsPaids(!viewBuyBillsPaids)}
              />
              <span>{viewBuyBillsPaids ? "Pagadas" : "Impagas"}</span>
            </div>
          </>
        ) : (
          <>
          </>
        )}

        {checkAction(permissionsActive, userToken.role, "buybills", "all") ||
        checkAction(permissionsActive, userToken.role, "buybills", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addBuyBillModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "buybills", "all") ||
      checkAction(permissionsActive, userToken.role, "buybills", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-buybills__search">
            <Row>
              <Col span={20}>
                <Search
                  className="Buscar"
                  placeholder=""
                  onChange={(e) => setFilterText(e.target.value)}
                  enterButton
                />
                <Radio.Group
                  options={[
                    { label: "Código", value: "code" },
                    { label: "Estado", value: "state" }, //TODO: lee el state de la db, no el convertido
                  ]}
                  onChange={(e) => {
                    setTypeFilter(e.target.value);
                  }}
                  value={typeFilter}
                />
              </Col>
              <Col span={4}>
                <SearchByDates
                  searchList={
                    viewBuyBillsPaids ? buyBillsPaid : buyBillsUnpaid
                  }
                  setSearchList={
                    viewBuyBillsPaids
                      ? setBuyBillsPaidFilter
                      : setBuyBillsUnpaidFilter
                  }
                  setReload={setReloadBuyBills}
                  dataSearch={"date"}
                />
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <>
        </>
      )}
      {/*
      {viewBillBuysActives ? (
        <BillBuysActive
          billBuysActive={billBuysActiveFilter}
          setIsVisibleModal={setIsVisibleModal}
          setModalTitle={setModalTitle}
          setModalContent={setModalContent}
          setReloadBillBuys={setReloadBillBuys}
          //showDeleteConfirm={showDeleteConfirm}
        />
      ) : (
        <BillBuysInactive
          billBuysInactive={billBuysInactiveFilter}
          setReloadBillBuys={setReloadBillBuys}
          //showDeleteConfirm={showDeleteConfirm}
        />
      )},
      */}

      {viewBuyBillsPaids ? (
        <>
          {checkAction(permissionsActive, userToken.role, "buybills", "all") ||
          checkAction(permissionsActive, userToken.role, "buybills", "view") || isAdmin(roleById) ? (
            <>
              <BuyBillsPaid
                buyBillsPaid={buyBillsPaidFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setModalWidth={setModalWidth}
                setReloadBuyBills={setReloadBuyBills}
                token={token}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "buybills", "all") ||
          checkAction(permissionsActive, userToken.role, "buybills", "view") || isAdmin(roleById) ? (
            <>
              <BuyBillsUnpaid
                buyBillsUnpaid={buyBillsUnpaidFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setModalWidth={setModalWidth}
                setReloadBuyBills={setReloadBuyBills}
                token={token}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        modalWidth={modalWidth}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

/*
function BillBuysActive(props) {
  const {
    billBuysActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadBillBuys,
  } = props; //showDeleteConfirm

  const editBillBuy = (billBuy) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar ${billBuy.billbuy_code ? billBuy.billbuy_code : "sin código"}`);
    setModalContent(
      <EditBillBuyForm
        billBuy={billBuy}
        setIsVisibleModal={setIsVisibleModal}
        setReloadBillBuys={setReloadBillBuys}
      />
    );
  };

  return (
    <List
      className="billbuys-active"
      itemLayout="horizontal"
      dataSource={billBuysActive}
      renderItem={(billBuy) => (
        <BillBuyActive
          billBuy={billBuy}
          editBillBuy={editBillBuy}
          setReloadBillBuys={setReloadBillBuys}
        />
      )} //showDeleteConfirm={showDeleteConfirm}
    />
  );
}

function BillBuyActive(props) {
  const { billBuy, editBillBuy, setReloadBillBuys } = props; //showDeleteConfirm

  const desactivateBillBuy = () => {
    const accessToken = getAccessToken();

    activateBillBuyApi(accessToken, billBuy._id, false)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setReloadBillBuys(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = () => {
    const accessToken = getAccessToken();

    confirm({
      title: "Eliminando factura",
      content: `¿Estás seguro que quieres eliminar a la factura ${billBuy.billbuy_code}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteBillBuyApi(accessToken, billBuy._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadBillBuys(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  return (
    <List.Item
      actions={[
        <Button type="default" onClick={() => editBillBuy(billBuy)}>
          <EditOutlined />
        </Button>,
        <Button type="primary" onClick={desactivateBillBuy}>
          <StopOutlined />
        </Button>,
        <Button type="danger" onClick={showDeleteConfirm}>
          <DeleteOutlined />
        </Button>,
      ]}
    >
      <List.Item.Meta
        title={`
                    ${billBuy.billbuy_code ? billBuy.billbuy_code : "..."}
                `}
        description= {` ${billBuy.state}`}
      />
    </List.Item>
  );
}

function BillBuysInactive(props) {
  const { billBuysInactive, setReloadBillBuys } = props; //showDeleteConfirm
  return (
    <List
      className="billbuys-active"
      itemLayout="horizontal"
      dataSource={billBuysInactive}
      renderItem={(billBuy) => (
        <BillBuyInactive
          billBuy={billBuy}
          setReloadBillBuys={setReloadBillBuys}
        />
      )} //showDeleteConfirm={showDeleteConfirm}
    />
  );
}

function BillBuyInactive(props) {
  const { billBuy, setReloadBillBuys } = props; //showDeleteConfirm


  const activateBillBuy = () => {
    const accessToken = getAccessToken();

    activateBillBuyApi(accessToken, billBuy._id, true)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setReloadBillBuys(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = () => {
    const accessToken = getAccessToken();

    confirm({
      title: "Eliminando factura",
      content: `¿Estás seguro que quieres eliminar a la factura ${billBuy.billbuy_code}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteBillBuyApi(accessToken, billBuy._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadBillBuys(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  return (
    <List.Item
      actions={[
        <Button type="primary" onClick={activateBillBuy}>
          <CheckOutlined />
        </Button>,
        <Button type="danger" onClick={showDeleteConfirm}>
          <DeleteOutlined />
        </Button>,
      ]}
    >
      <List.Item.Meta
        title={`
                ${billBuy.billbuy_code ? billBuy.billbuy_code : "..."}
            `}
        description={billBuy.state}
      />
    </List.Item>
  );
}
*/

function BuyBillsPaid(props) {
  const {
    buyBillsPaid,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setModalWidth,
    setReloadBuyBills,
    token,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props; //showDeleteConfirm

  const viewBuyBill = (pBill) => {
    setIsVisibleModal(true);
    setModalTitle(`Ver Comprobante  ${pBill.code ? pBill.code : "sin código"}`);
    setModalWidth(900);
    setModalContent(
      <ViewBuyBillForm
        buyBill={pBill}
        setIsVisibleModal={setIsVisibleModal}
        setReloadBuyBills={setReloadBuyBills}
      />
    );
  };

  const editState = (bBill, bpaid) => {
    setIsVisibleModal(true);
    setModalTitle(`Estado de Comprobante de compra ${bBill.code}`);
    setModalWidth(300);
    setModalContent(
      <EditStateForm
        buyBill={bBill}
        bpaid={bpaid}
        setIsVisibleModal={setIsVisibleModal}
        setReloadBuyBills={setReloadBuyBills}
      />
    );
  };

  const editBuyBill = (bill) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar ${bill.code ? bill.code : "sin código"}`);
    setModalWidth(900);
    setModalContent(
      <EditBuyBillForm
        buyBill={bill}
        setIsVisibleModal={setIsVisibleModal}
        setReloadBuyBills={setReloadBuyBills}
      />
    );
  };

  const addAnulationBuyBill = async (bill) => {
    setIsVisibleModal(true);
    setModalTitle("Comprobante de compra - Anulación");
    setModalWidth(900);
    await setModalContent(
      <AddBuyBillForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadBuyBills={setReloadBuyBills}
        associatedBuyBill={bill}
      />
    );
  };

    const showDeleteConfirm = (buyBill) => {
    //const accessToken = getAccessToken();

    confirm({
      title: "Eliminando -> Comprobante de Compra",
      content: `¿Estás seguro que quieres eliminar a ${buyBill.code ? buyBill.code: "sin codigo"}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteBuyBillApi(accessToken, buyBill._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadBuyBills(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  return (
    <List
      className="buybill"
      itemLayout="horizontal"
      dataSource={buyBillsPaid}
      renderItem={(buyBill) => (
        <BuyBillPaid
          buyBill={buyBill}
          viewBuyBill={viewBuyBill}
          editState={editState}
          editBuyBill={editBuyBill}
          addAnulationBuyBill={addAnulationBuyBill}
          showDeleteConfirm={showDeleteConfirm}
          setReloadBuyBills={setReloadBuyBills}
          token={token}
          accessToken={accessToken}
          userToken={userToken}
          permissionsActive={permissionsActive}
          roleById={roleById}

        />
      )} //showDeleteConfirm={showDeleteConfirm}
    />
  );
}

function BuyBillPaid(props) {
  const {
    buyBill,
    viewBuyBill,
    editState,
    editBuyBill,
    addAnulationBuyBill,
    showDeleteConfirm,
    setReloadBuyBills,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props; //showDeleteConfirm
  var supplierName = "";

  if (buyBill.supplier) {
    supplierName = buyBill.supplier.denomination;
  }
  /*
  const unpaidbuyBill= () => {
    const accessToken = getAccessToken();

    paidbuyBillApi(accessToken, buyBill._id, false)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        editState(buyBill);
        //setReloadBuyBills(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };*/

  /*
  const showUnpaidConfirm = () => {
    const accessToken = getAccessToken();
    const select_state = "";
    const now_state = buyBill.state;

    confirm({
      title: `Estado de factura de compra ${buyBill.code}`,
      okText: "Aceptar",
      okType: "success",
      cancelText: "Cancelar",
      onOk() {
        paidStatebuyBillApi(accessToken, buyBill._id, false, select_state)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadBuyBills(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };*/

  /*
  const showDeleteConfirm = () => {
    const accessToken = getAccessToken();

    confirm({
      title: "Eliminando factura",
      content: `¿Estás seguro que quieres eliminar a la factura ${buyBill.code}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteBuyBillApi(accessToken, buyBill._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadbuyBills(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };*/

  /*
  const [supplierPaid, setSupplierPaid] = useState([]);

  if(buyBill.supplier){
    useEffect(() => {
      getSupplierApi(token, buyBill.supplier).then(response => {
        if(response.supplier){
          setSupplierPaid(response.supplier);
        }

      });
    }, [supplierPaid]);
  }*/

  //(moment(buyBill.date_buy).format("DD-MM-yyyy")) ? (moment(buyBill.date).format("DD-MM-yyyy"))

  const view_buybill = (
    <div>
      {" "}
      <p>Ver Comprobante</p>{" "}
    </div>
  );

  const changeState = (
    <div>
      {" "}
      <p>Editar Estado</p>{" "}
    </div>
  );

  const edit_buybill = (
    <div>
      {" "}
      <p>Editar Comprobante</p>{" "}
    </div>
  );
  const cancelBill = (
    <div>
      {" "}
      <p>Anular Comprobante</p>{" "}
    </div>
  );

  const download_buybill = (
    <div>
      {" "}
      <p>Descargar Comprobante</p>{" "}
    </div>
  );

  const delete_buybill = (
    <div>
      {" "}
      <p>Eliminar Comprobante</p>{" "}
    </div>
  );


  return (
    <List.Item
      actions={[
        <div>
          {checkAction(permissionsActive, userToken.role, "buybills", "all") ||
          checkAction(permissionsActive, userToken.role, "buybills", "view") || isAdmin(roleById) ? (
            <>
              <Popover content={view_buybill}>
                <Button type="primary" onClick={() => viewBuyBill(buyBill)}>
                  <EyeOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "buybills", "all") ||
          checkAction(permissionsActive, userToken.role, "buybills", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_buybill}>
                <Button type="default" onClick={() => editBuyBill(buyBill)}>
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "buybills", "all") ||
          checkAction(permissionsActive, userToken.role, "buybills", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={changeState}>
                <Button
                  className="bg-warning"
                  onClick={() => editState(buyBill, false)}
                >
                  <FileExclamationOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
        // <div>
        //   {checkAction(permissionsActive, userToken.role, "buybills", "all") ||
        //   checkAction(permissionsActive, userToken.role, "buybills", "delete") || isAdmin(roleById) ? (
        //     <>
        //       <Popover content={cancelBill}>
        //         <Button
        //           type="danger"
        //           onClick={() => addAnulationBuyBill(buyBill)}
        //         >
        //           <CloseSquareOutlined />
        //         </Button>
        //       </Popover>
        //     </>
        //   ) : (
        //     <>
        //     </>
        //   )}
        // </div>,
        <div>
        {checkAction(permissionsActive, userToken.role, "buybills", "all") ||
        checkAction(permissionsActive, userToken.role, "buybills", "delete") || isAdmin(roleById) ? (
          <>
            <Popover content={delete_buybill}>
              <Button
                type="danger"
                onClick={() => showDeleteConfirm(buyBill)}
              >
                <DeleteOutlined />
              </Button>
            </Popover>
          </>
        ) : (
          <>
          </>
        )}
      </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "buybills", "all") ||
          checkAction(permissionsActive, userToken.role, "buybills", "pdf") || isAdmin(roleById) ? (
            <>
              <Popover content={download_buybill}>
                <Button
                  type="primary"
                  onClick={() => {
                    buyBillDetailPdf(buyBill, false);
                  }}
                >
                  <DownloadOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
      ]}
    >
      <List.Item.Meta
        title={`
                  ${buyBill.code ? buyBill.code : "sin codigo"},
                  ${
                    formatDateView(buyBill.date)
                      ? formatDateView(buyBill.date)
                      : "sin fecha"
                  },
                  ${supplierName ? supplierName : "no hay proveedor"}
                `} //${supplierPaid.name ? supplierPaid.name : "no hay proveedor"}
        description={` ${
          "$" + buyBill.total ? "$" + buyBill.total : "no hay total"
        } , ${buyBill.state? buyBill.state.name : "sin estado"} `}
      />
    </List.Item>
  );
}

function BuyBillsUnpaid(props) {
  const {
    buyBillsUnpaid,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setModalWidth,
    setReloadBuyBills,
    token,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props; //showDeleteConfirm

  const viewBuyBill = (buyBill) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Ver Comprobante ${buyBill.code ? buyBill.code : "sin código"}`
    );
    setModalContent(<ViewBuyBillForm buyBill={buyBill} />);
    setModalWidth(900);
  };

  const editState = (buyBill, bpaid) => {
    setIsVisibleModal(true);
    setModalTitle(`Estado de Comprobante de compra ${buyBill.code}`);
    setModalWidth(400);
    setModalContent(
      <EditStateForm
        buyBill={buyBill}
        bpaid={bpaid}
        setIsVisibleModal={setIsVisibleModal}
        setReloadBuyBills={setReloadBuyBills}
      />
    );
  };

  const editBuyBill = (bill) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar ${bill.code ? bill.code : "sin código"}`);
    setModalWidth(900);
    setModalContent(
      <EditBuyBillForm
        buyBill={bill}
        setIsVisibleModal={setIsVisibleModal}
        setReloadBuyBills={setReloadBuyBills}
      />
    );
  };

  const addAnulationBuyBill = async (bill) => {
    setIsVisibleModal(true);
    setModalTitle("Comprobante de compra - Anulación");
    setModalWidth(900);
    await setModalContent(
      <AddBuyBillForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadBuyBills={setReloadBuyBills}
        associatedBuyBill={bill}
      />
    );
  };

  
  const showDeleteConfirm = (buyBill) => {
    //const accessToken = getAccessToken();

    confirm({
      title: "Eliminando -> Comprobante de Compra",
      content: `¿Estás seguro que quieres eliminar a ${buyBill.code ? buyBill.code: "sin codigo"}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteBuyBillApi(accessToken, buyBill._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadBuyBills(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  return (
    <List
      className="buybill"
      itemLayout="horizontal"
      dataSource={buyBillsUnpaid}
      renderItem={(buyBill) => (
        <BuyBillUnpaid
          buyBill={buyBill}
          viewBuyBill={viewBuyBill}
          editState={editState}
          editBuyBill={editBuyBill}
          addAnulationBuyBill={addAnulationBuyBill}
          showDeleteConfirm={showDeleteConfirm}
          setReloadBuyBills={setReloadBuyBills}
          token={token}
          accessToken={accessToken}
          userToken={userToken}
          permissionsActive={permissionsActive}
          roleById={roleById}
        />
      )} //showDeleteConfirm={showDeleteConfirm}
    />
  );
}

function BuyBillUnpaid(props) {
  const {
    buyBill,
    editState,
    viewBuyBill,
    editBuyBill,
    addAnulationBuyBill,
    showDeleteConfirm,
    setReloadBuyBills,
    token,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props; //showDeleteConfirm
  var supplierName = "";

  if (buyBill.supplier) {
    supplierName = buyBill.supplier.denomination;
  }

  /*
  const paidBuyBill= () => {
    const accessToken = getAccessToken();

    paidBuyBillApi(accessToken, buyBill._id, true)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setReloadBuyBills(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };*/

  /*
  const showDeleteConfirm = () => {
    const accessToken = getAccessToken();

    confirm({
      title: "Eliminando factura",
      content: `¿Estás seguro que quieres eliminar a la factura ${buyBill.code}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        buyBill(accessToken, buyBill._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadBuyBills(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };*/

  /*
  const [supplierUnpaid, setSupplierUnpaid] = useState([]);

  if(buyBill.supplier){
    useEffect(() => {
      getSupplierApi(token, buyBill.supplier).then(response => {
        setSupplierUnpaid(response.supplier);
      });
    }, [supplierUnpaid]);
  }*/

  const view_buybill = (
    <div>
      {" "}
      <p>Ver Comprobante</p>{" "}
    </div>
  );

  const changeState = (
    <div>
      {" "}
      <p>Editar estado</p>{" "}
    </div>
  );

  const edit_buybill = (
    <div>
      {" "}
      <p>Editar Comprobante</p>{" "}
    </div>
  );

  const download_buybill = (
    <div>
      {" "}
      <p>Descargar Comprobante</p>{" "}
    </div>
  );

  const cancel_buybill = (
    <div>
      {" "}
      <p>Anular Comprobante</p>{" "}
    </div>
  );

  const delete_buybill = (
    <div>
      {" "}
      <p>Eliminar Comprobante</p>{" "}
    </div>
  );

  return (
    <List.Item
      actions={[
        <div>
          {checkAction(permissionsActive, userToken.role, "buybills", "all") ||
          checkAction(permissionsActive, userToken.role, "buybills", "view") || isAdmin(roleById) ? (
            <>
              <Popover content={view_buybill}>
                <Button type="primary" onClick={() => viewBuyBill(buyBill)}>
                  <EyeOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "buyBills", "all") ||
          checkAction(permissionsActive, userToken.role, "buyBills", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_buybill}>
                <Button type="default" onClick={() => editBuyBill(buyBill)}>
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "buybills", "all") ||
          checkAction(permissionsActive, userToken.role, "buybills", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={changeState}>
                <Button
                  className="bg-warning"
                  onClick={() => editState(buyBill, true)}
                >
                  <FileExclamationOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
        // <div>
        //   {checkAction(permissionsActive, userToken.role, "buybills", "all") ||
        //   checkAction(permissionsActive, userToken.role, "buybills", "delete") || isAdmin(roleById) ? (
        //     <>
        //       <Popover content={cancel_buybill}>
        //         <Button
        //           type="danger"
        //           onClick={() => addAnulationBuyBill(buyBill)}
        //         >
        //           <CloseSquareOutlined />
        //         </Button>
        //       </Popover>
        //     </>
        //   ) : (
        //     <>
        //     </>
        //   )}
        // </div>,
         <div>
         {checkAction(permissionsActive, userToken.role, "buybills", "all") ||
         checkAction(permissionsActive, userToken.role, "buybills", "delete") || isAdmin(roleById) ? (
           <>
             <Popover content={delete_buybill}>
               <Button
                 type="danger"
                 onClick={() => showDeleteConfirm(buyBill)}
               >
                 <DeleteOutlined />
               </Button>
             </Popover>
           </>
         ) : (
           <>
           </>
         )}
       </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "buybills", "all") ||
          checkAction(permissionsActive, userToken.role, "buybills", "pdf") || isAdmin(roleById) ? (
            <>
              <Popover content={download_buybill}>
                <Button
                  type="primary"
                  onClick={() => {
                    buyBillDetailPdf(buyBill, false);
                  }}
                >
                  <DownloadOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ]}
    >
      <List.Item.Meta
        title={`
                  ${buyBill.code ? buyBill.code : "sin codigo"},
                  ${
                    formatDateView(buyBill.date)
                      ? formatDateView(buyBill.date)
                      : "sin fecha"
                  },
                  ${supplierName ? supplierName : "no hay proveedor"}
                `}
        description={` ${
          "$" + buyBill.total ? "$" + buyBill.total : "no hay total"
        }, ${buyBill.state? buyBill.state.name : "sin estado"} `}
      />
    </List.Item>
  );
}
