import React, { useState, useEffect } from "react";
import { Form, Descriptions, Table } from "antd";
//import { getSupplierApi } from "../../../../api/billing/supplier";
import { formatDateView } from "../../../../utils/formatDate";
import { convertState, convertCategory } from "../../../../utils/convert";
import "./ViewBillSellForm.scss";

export default function ViewBillSellForm(props) {
  const { billSell } = props;
  const [billSellData, setBillSellData] = useState({});

  //trae los datos de la factura
  useEffect(() => {
    setBillSellData({
      code: billSell.code,
      saleCondition: billSell.saleCondition,
      billType: billSell.billType,
      // concept: billSell.concept,
      // bills: billSell.bills,
      // subtotal: billSell.subtotal,
      total: billSell.total,
      state: billSell.state,
      // observation: billSell.observation,
      date: billSell.date,
      deliveryDate: billSell.deliveryDate,
      // uploadDate: billSell.uploadDate,
      expirationDate: billSell.expirationDate,
      periodFrom: billSell.periodFrom,
      periodUntil: billSell.periodUntil,
      client: billSell.client,
      client_name: billSell.client.denomination,
      address:billSell.address,
      // seller_name: billSell.seller.name,
      // salePoint_name: billSell.salePoint.fantasy_name,
      // salePoint_code: billSell.salePoint.salepoint_code,
      // salePoint_address: billSell.salePoint.address,
      // salePoint_number: billSell.salePoint.number,
      // salePoint_postalcode: billSell.salePoint.postal_code,
      // salePoint_locality: billSell.salePoint.locality,
      quantity: billSell.quantity,
      items: billSell.items,
      item: billSell.item,
    });
  }, [billSell]);

  const itemsBill = billSell.items;

  return (
    <div className="view-billsell-form">
      <ViewForm billSellData={billSellData} setBillSellData={setBillSellData} />
      <br/>
      {/* <label className="title">Comprobantes asociados</label>
      {billSellData.bills ? <Bills billsData={billSellData.bills} /> : null} */}
      <label className="title">Detalle</label>
      <Items itemsBill={itemsBill} />
    </div>
  );
}

function ViewForm(props) {
  const { billSellData, setBillSellData } = props;

  return (
    <Form className="form-view">
      <Descriptions column={2} layout="vertical" size="small" bordered>
        <label className="title">Tipo Comprobante</label>
        <Descriptions.Item className="normal">
          {billSellData.billType}
        </Descriptions.Item>
        <label className="title">Código</label>
        <Descriptions.Item className="normal">
          {billSellData.code}
        </Descriptions.Item>
        <label className="title">Cliente</label>
        <Descriptions.Item className="normal">
          {billSellData.client_name}
        </Descriptions.Item>
         <label className="title">Domicilio</label>
        <Descriptions.Item className="normal">
          {billSellData.address}
        </Descriptions.Item>
        {/* <label className="title">Vendedor</label>
        <Descriptions.Item className="normal">
          {billSellData.seller_name}
        </Descriptions.Item>
        <label className="title">Punto de venta</label>
        <Descriptions.Item className="normal">
          {billSellData.salePoint_code +
            " " +
            billSellData.salePoint_address +
            " " +
            billSellData.salePoint_number +
            " " +
            billSellData.salePoint_locality}
        </Descriptions.Item> */}
        <label className="title">Fecha de Emision</label>
        <Descriptions.Item className="normal">
          {formatDateView(billSellData.date)}
        </Descriptions.Item>
        <label className="title">Fecha de Entrega</label>
        <Descriptions.Item className="normal">
          {formatDateView(billSellData.deliveryDate)}
        </Descriptions.Item>
        <label className="title">Periodo desde</label>
        <Descriptions.Item className="normal">
          {billSellData.periodFrom
            ? formatDateView(billSellData.periodFrom)
            : "-"}
        </Descriptions.Item>
        <label className="title">Hasta</label>
        <Descriptions.Item className="normal">
          {billSellData.periodUntil
            ? formatDateView(billSellData.periodUntil)
            : "-"}
        </Descriptions.Item>
        <label className="title">Fecha Vencimiento</label>
        <Descriptions.Item className="normal">
          {billSellData.expirationDate
            ? formatDateView(billSellData.expirationDate)
            : "-"}
        </Descriptions.Item>
        {/* <label className="title">Conceptos</label>
        <Descriptions.Item className="normal">
          {billSellData.concept}
        </Descriptions.Item>
        <label className="title">Condición de venta</label>
        <Descriptions.Item className="normal">
          {billSellData.saleCondition ? billSellData.saleCondition : "-"}
        </Descriptions.Item>
        <label className="title">Estado de compra</label>
        <Descriptions.Item className="normal">
          {billSellData.state}
        </Descriptions.Item>
        <label className="title">Sub total</label>
        <Descriptions.Item className="normal">
          {"$ " + billSellData.subtotal}
        </Descriptions.Item> */}
        <label className="title">Total</label>
        <Descriptions.Item className="normal">
          {"$ " + billSellData.total}
        </Descriptions.Item>
        {/* <label className="title">Observaciones</label>
        <Descriptions.Item className="normal" span={2}>
          {billSellData.observation ? billSellData.observation : "-"}
        </Descriptions.Item> */}
      </Descriptions>
    </Form>
  );
}

function Items(props) {
  const { itemsBill } = props;
  //console.log(itemsBill[0]._item.description);

  const columns = [
    {
      title: "Producto",
      dataIndex: "_item",
      render: (_item) => _item.description,
    },
    { title: "Valor Unitario ($)", dataIndex: "unitValue" },
    { title: "Cantidad", dataIndex: "quantity" },
  ];
  return (
    <Table
      pagination={false}
      columns={columns}
      dataSource={itemsBill}
      rowKey="_item"
    />
  );
}

function Bills(props) {
  const { billsData } = props;
  const columns = [{ title: "Código", dataIndex: "code" }];
  return (
    <Table
      pagination={false}
      columns={columns}
      dataSource={billsData}
      rowKey="_id"
    />
  );
}
