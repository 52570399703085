import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getConceptsActiveApi } from "../../../api/billing/concept";
import ListConcepts from "../../../components/Billing/Concepts/ListConcepts";


export default function Concepts() {
    const [conceptsActive, setConceptsActive] = useState([]);
    const [conceptsInactive, setConceptsInactive] = useState([]);
    const [reloadConcepts, setReloadConcepts ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getConceptsActiveApi(token, true).then(response => {
            setConceptsActive(response.concepts);
        });
        getConceptsActiveApi(token, false).then(response => {
            setConceptsInactive(response.concepts);
        });
        setReloadConcepts(false);
    }, [token, reloadConcepts]);

    return (
        <div className="concepts">

            <ListConcepts conceptsActive={conceptsActive} conceptsInactive={conceptsInactive} setReloadConcepts={setReloadConcepts}
                />

        </div>
    );
}