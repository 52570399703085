import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom"; //useLocation
//import Error404 from "../pages/Error404";
import { Layout } from "antd"; //Tabs
import useAuth from "../hooks/useAuth";
import MenuTop from "../components/Admin/MenuTop";
import MenuSider from "../components/Admin/MenuSider";
import AdminSignIn from "../pages/Admin/SignIn";
import { getMenuViewApi } from "../api/menu"; //getMenuApi
import moment from "moment";
import 'moment/locale/es'; //importo el lenguaje en español
//import AdminUsers from "../pages/Admin/Users";
import RouteProvider from "../providers/RouterProvider";
import { getAccessToken } from '../api/auth'; //getRefreshToken
import "./LayoutAdmin.scss";

//import { PrivateRoute } from "../utils/accessControl";
//import Reset from '../pages/Admin/Reset/Reset';
//import { getUserByResetTokenApi } from "../api/user";
//import Logo from '../assets/img/png/icba.png';
//import { getPermissionsActiveApi } from "../api/permission";
//import AdminReset from "../pages/Admin/Reset";
//import Users from "../pages/Admin/Users";
//import routes from '../config/routes';

moment.locale('es'); //carga el lenguaje en español a la libreria moment

export default function LayoutAdmin(props) {
  const { routes } = props;
  const [menuCollapsed, setMenuCollapsed] = useState(false); //true: colapsado
  const { Header, Content, Footer } = Layout;
  const { isLoading, user } = useAuth(); //userAuth
  const [menuData, setMenuData] = useState([]);
  //const { TabPane } = Tabs;

  // console.log(routes);
  //console.log(isLoading);

  //const user = null; //si es null el user no está logueado

  // PRUEBA DE ACCESSTOKEN Y REFRESHTOKEN EN INSPECCION DE COD -> CONSOLA
  //const token = getAccessToken();
  //console.log('accessToken: ' + accessToken);
  //const refreshToken = getRefreshToken();
  //console.log('refreshToken: ' + refreshToken);

  /*
    if(user.resetPasswordToken !== '' || user.resetPasswordToken !== 'null') {
        return (
            <>
                <Route path='/reset' component={AdminReset} />
                <Redirect to="/reset" />
            </>
        );
    }
  */

  useEffect(() => {
    if (menuData.length === 0) {
      const token = getAccessToken();
      getMenuViewApi(token).then((response) => {
        setMenuData(response.menu);
      });
    }
  }, []);

  /*
  const reload = () => {
    window.location.reload(true);
  }
  */

  /*
  if ((<Route path = "/reset/*"/>)){
    console.log("no está reseteando la passw");
    return (
      <Reset />
    );
  }
  */

  //console.log(useLocation().pathname);

  if (!useAuth().user && !isLoading) {
    //si el usuario no está logueado
    /*
    console.log("no está logueado");
    if (useLocation().pathname === "/reset/:resetPasswordToken") {
      console.log("useLocation");
    }
    if (!(<Route path = "/reset/:resetPasswordToken" />)){
      console.log("route path");
    }
    if (!window.location.href === "/reset/:resetPasswordToken"){
      console.log("window.location");
    }
    */
    return (
      <>
        <Route path="/admin/login" component={AdminSignIn} />
        <Redirect to="/admin/login" />
      </>
    );
  }

  if (useAuth().user && !isLoading) {
    return (
      <Layout className="layout-init" httpEquiv="refresh">
        <MenuSider
          menuCollapsed={menuCollapsed}
          user={user}
          menu={menuData}
        />
        {/*<h2>Menu Sider Admin</h2>*/}
        <Layout
          className="layout-admin"
          style={{ marginLeft: menuCollapsed ? "30px" : "60px" }}
        >
          <Header
            style={{ position: "fixed", zIndex: 1, width: "100%" }}
            className="layout-admin__header"
          >
            <MenuTop
              menuCollapsed={menuCollapsed}
              setMenuCollapsed={setMenuCollapsed}
            />
          </Header>
          <Content className="layout-admin__content">
            <LoadRoutes routes={routes} user={user} />
          </Content>
          <Footer className="layout-admin__footer">
            <Footer className="layout-basic__footer">
              &copy; {moment().format('LLLL')}
            </Footer>
          </Footer>
        </Layout>
      </Layout>
    );
  }
  return null;
}

function LoadRoutes({ 
  routes, 
  //user, 
  //menu 
}) {
  // console.log(routes);

  return (
    <Switch>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          // component={route.component} {window.location.reload()}
          name={route.name}
          render={(props) => (
            <RouteProvider titleRoute={route.title}>
              <route.component {...props} />{" "}  
            </RouteProvider>
              //{window.location.reload()}
          ) 
        }
          
        />
        // </RouteContext>
      ))}
    </Switch>
  );
}

