import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { Button, Result } from 'antd';
import {
  //getCoursesActiveApi, getCategoryExportApi, getCategoriesApi,
   getCategoriesActiveAndIsGeneralByBusinessApi,getCategoriesAvailableApi
} from "../../../api/category";
import ListCategories from "../../../components/Admin/Category/ListCategories";
import { useBranch } from "../../../hooks/useBranch";
import { useBusiness } from "../../../hooks/useBusiness";
import Error403 from "../../../components/Errors/Error403";

export default function Categories(props) {
  const [categoriesActive, setCategoriesActive] = useState([]);
  const [categoriesInactive, setCategoriesInactive] = useState([]);
  const [reloadCategories, setReloadCategories] = useState(false);
  const token = getAccessToken();

  const { branch } = useBranch();
  const { business } = useBusiness();

  useEffect(() => {
    let isMounted = true;
    getCategoriesActiveAndIsGeneralByBusinessApi(token, true, true, business).then((response) => {
      if (isMounted) {
        setCategoriesActive(response.categories);
      }
    });
    setReloadCategories(false);
    return () => {
      isMounted = false;
    };
  }, [token, reloadCategories]);

  useEffect(() => {
    let isMounted = true;
    getCategoriesActiveAndIsGeneralByBusinessApi(token, false, true, business).then((response) => {
      if (isMounted) {
        setCategoriesInactive(response.categories);
      }
    });
    setReloadCategories(false);
    return () => {
      isMounted = false;
    };
  }, [token, reloadCategories]);

  useEffect(() => {
    if (branch && branch !== 0 && branch !== "0") {
      return (window.location.href = "/admin/digitalmenu/branch-categories");
    }
  }, [branch]);

  if(business && business!=="0" && business!==0){
    return (
      <div className="categories">
        <h3>Listado de Categorias</h3>
        <ListCategories
          categoriesActive={categoriesActive}
          categoriesInactive={categoriesInactive}
          setReloadCategories={setReloadCategories}
        />
      </div>
    );
  }
  return (
    <Error403/>
  )

}
