import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import {
  //getCoursesActiveApi, getCategoryExportApi, getCategoriesApi,
  getCategoriesActiveApi,
} from "../../../api/category";
import ListBranchCategories from "../../../components/Admin/Category/ListBranchCategories";
import { useBranch } from "../../../hooks/useBranch";
import { getBranchMenuByBranchApi } from "../../../api/branchMenu";

export default function BranchCategories(props) {
  const [categoriesActive, setCategoriesActive] = useState([]);
  const [categoriesInactive, setCategoriesInactive] = useState([]);
  const [reloadCategories, setReloadCategories] = useState(false);
  const token = getAccessToken();
  const { branch } = useBranch();

  useEffect(() => {
    let isMounted = true;
    if (branch && branch !== "") {
      getBranchMenuByBranchApi(token, branch).then((response) => {
        if (response && response.branchMenu) {
          const categories=response.branchMenu.categories?response.branchMenu.categories : [];
          const listActive = categories.filter(
            (item) => item.available === true
          );
          const listActive2=listActive.map((i)=>i.category);
          const listInactive = categories.filter(
            (item) => item.available === false
          );
          const listInactive2=listInactive.map((i)=>i.category);
          if (isMounted) {
            setCategoriesActive(listActive2);
            setCategoriesInactive(listInactive2);
          }
        }
      });
    }
    setReloadCategories(false);
    return () => {
      isMounted = false;
    };
  }, [token, branch,  reloadCategories]);

  useEffect(() => {
    if(branch && (branch===0 || branch==="0")){
     return window.location.href = "/admin/digitalmenu/categories";
    }
  }, [branch]);


  return (
    <div className="categories">
      <h3>Listado de Categorias de la sucursal</h3>
      <ListBranchCategories
        categoriesActive={categoriesActive}
        categoriesInactive={categoriesInactive}
        setReloadCategories={setReloadCategories}
      />
    </div>
  );
}
