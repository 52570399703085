import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getSalePointsActiveApi } from "../../../api/billing/salePoint";
import ListSalePoints from "../../../components/Billing/SalePoints/ListSalePoints";

export default function SalePoints() {
  const [salePointsActive, setSalePointsActive] = useState([]);
  const [salePointsInactive, setSalePointsInactive] = useState([]);
  const [reloadSalePoints, setReloadSalePoints] = useState(false);
  const token = getAccessToken();

  useEffect(() => {
    getSalePointsActiveApi(token, true).then((response) => {
      setSalePointsActive(response.salePoints);
    });
    getSalePointsActiveApi(token, false).then((response) => {
      setSalePointsInactive(response.salePoints);
    });
    setReloadSalePoints(false);
  }, [token, reloadSalePoints]);

  return (
    <div className="salepoints">
      <ListSalePoints
        salePointsActive={salePointsActive}
        salePointsInactive={salePointsInactive}
        setReloadSalePoints={setReloadSalePoints}
      />
    </div>
  );
}
