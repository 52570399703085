import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import {
  //getAbsencesActiveApi,
  getBusinessesActiveApi,
} from "../../../api/business";
import ListBusinesses from "../../../components/Admin/Businesses/ListBusinesses/ListBusinesses";
import {useBranch} from "../../../hooks/useBranch";

export default function Businesses(props) {
  //const {setReloadBranch}=props;
  const [businessesActive, setBusinessesActive] = useState([]);
  const [businessesInactive, setBusinessesInactive] = useState([]);
  const [reloadBusinesses, setReloadBusinesses] = useState(false);
  //const [branch, setBranch] = useState({});
  const token = getAccessToken();
  const {branch, changeBranch}=useBranch();
  //  const businessId = props.location.state? props.location.state.id : false;

  useEffect(() => {
    getBusinessesActiveApi(token, true).then((response) => {
      setBusinessesActive(response.businesses);
      // if(response.businesses && response.businesses.length === 0 && branch!==""){
      //   changeBranch("");
      // }
    });
    setReloadBusinesses(false);
  }, [token, reloadBusinesses]);

  useEffect(() => {
    getBusinessesActiveApi(token, false).then((response) => {
      setBusinessesInactive(response.businesses);
    });
    setReloadBusinesses(false);
  }, [token, reloadBusinesses]);

  return (
    <div className="businesses">
      <ListBusinesses
        setReloadBusinesses={setReloadBusinesses}
        businessesActive={businessesActive}
        businessesInactive={businessesInactive}
      />
    </div>
  );
}
