import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditSellerForm from "../EditSellerForm";
import AddSellerForm from "../AddSellerForm";
import { activateSellerApi, deleteSellerApi } from "../../../../api/billing/seller";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListSellers.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListSellers(props) {
  const {
    sellersActive,
    sellersInactive,
    setReloadSellers,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewSellersActives, setViewSellersActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [sellersActiveFilter, setSellersActiveFilter] = useState(
    []
  );
  const [ sellersInactiveFilter, setSellersInactiveFilter ] = useState([]);
  const [typeFilter, setMethodFilter] = useState("name");

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, [roleById]);

  useEffect(() => {
    const listActive = [];
    if (sellersActive) {
      sellersActive.forEach((element) => {
        if (typeFilter === "name") {
          if (
            element.name
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        } else {
          if (
            element.seller_code
              .toString()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        }
      });
    }
    setSellersActiveFilter(listActive);
  }, [filterText, sellersActive]);

  useEffect(() => {
    const listInactive = [];
    if (sellersInactive) {
      sellersInactive.forEach((element) => {
        if (typeFilter === "name") {
          if (
            element.name
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        } else {
          if (
            element.item_code
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        }
      });
    }
    setSellersInactiveFilter(listInactive);
  }, [filterText, sellersInactive]);

  const addSellerModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Vendedor");
    setModalContent(
      <AddSellerForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadSellers={setReloadSellers}
      />
    );
  };

  return (
    <div className="list-sellers">
      <div className="list-sellers__header">
      {checkAction(permissionsActive, userToken.role, "sellers", "all") ||
      checkAction(permissionsActive, userToken.role, "sellers", "enable") || isAdmin(roleById) ? (
        <>
            <div className="list-sellers__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewSellersActives(!viewSellersActives)
                }
              />
              <span>
                {viewSellersActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
        </>
        ) : (
        <>
        </>
      )}
      {checkAction(permissionsActive, userToken.role, "sellers", "all") ||
      checkAction(permissionsActive, userToken.role, "sellers", "add") || isAdmin(roleById) ? (
        <>
          <Button type="primary" icon={<PlusOutlined/>} onClick={addSellerModal}>
            Nuevo
          </Button>
        </>
      ) : (
        <>
        </>
      )}
      </div>

      {checkAction(permissionsActive, userToken.role, "sellers", "all") ||
      checkAction(permissionsActive, userToken.role, "sellers", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-sellers__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <Radio.Group
              options={[
                { label: "Nombre", value: "name" },
                { label: "Código", value: "code" },
              ]}
              onChange={(e) => {
                setMethodFilter(e.target.value);
              }}
              value={typeFilter}
            />
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewSellersActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "sellers", "all") ||
          checkAction(permissionsActive, userToken.role, "sellers", "view") || isAdmin(roleById) ? (
            <>
              <SellersActive
                sellersActive={sellersActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadSellers={setReloadSellers}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                />
            </>
          ) : (
            <>
            </>
          )}
        </>
        ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "sellers", "all") ||
          checkAction(permissionsActive, userToken.role, "sellers", "view") || isAdmin(roleById) ? (
            <>
              <SellersInactive
                sellersInactive={sellersInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadSellers={setReloadSellers}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function SellersActive(props) {
  const {
    sellersActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadSellers,
    accessToken,
    userToken,
    permissionsActive,
    roleById,
  } = props;

  const editSeller = (seller) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${seller.name ? seller.name : "sin nombre"}`
    );
    setModalContent(
      <EditSellerForm
        seller={seller}
        setIsVisibleModal={setIsVisibleModal}
        setReloadSellers={setReloadSellers}
      />
    );
  };

  const desactivateSeller = (seller) => {
    const accessToken = getAccessToken();

    activateSellerApi(accessToken, seller._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadSellers(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (seller) => {
    const accessToken = getAccessToken();
    confirm({
      title: "Eliminando Vendedor",
      content: `¿Estás seguro que quieres eliminar a ${seller.name}?`,
      okText: "Eliminar",
      okMethod: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteSellerApi(accessToken, seller._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadSellers(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const edit_seller = (
    <div>
      {" "}
      <p>Editar Vendedor</p>{" "}
    </div>
  );

  const desactivate_seller = (
    <div>
      {" "}
      <p>Pasar a Vendedores inactivos</p>{" "}
    </div>
  );

  const delete_seller = (
    <div>
      {" "}
      <p>Eliminar Vendedor</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "seller_code",
    },
    {
      title: "Nombre",
      dataIndex: "name",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, seller) => (
          <div>
            {checkAction(permissionsActive, userToken.role, "sellers", "all") ||
            checkAction(permissionsActive, userToken.role, "sellers", "edit") || isAdmin(roleById) ? (
              <>
                <Popover content={edit_seller}>
                  <Button
                    className="button-seller"
                    type="default"
                    onClick={() => editSeller(seller)}
                  >
                    <EditOutlined />
                  </Button>
                </Popover>
              </>
            ) : (
              <>
              </>
            )}

            {checkAction(permissionsActive, userToken.role, "sellers", "all") ||
            checkAction(permissionsActive, userToken.role, "sellers", "enable") || isAdmin(roleById) ? (
              <>
                <Popover content={desactivate_seller}>
                  <Button
                    className="button-seller"
                    type="primary"
                    onClick={() => desactivateSeller(seller)}
                  >
                    <StopOutlined />
                  </Button>
                </Popover>
              </>
            ) : (
              <>
              </>
            )}

            {checkAction(permissionsActive, userToken.role, "sellers", "all") ||
            checkAction(permissionsActive, userToken.role, "sellers", "delete") || isAdmin(roleById) ? (
              <>
                <Popover content={delete_seller}>
                  <Button
                    className="button-seller"
                    type="danger"
                    onClick={() => showDeleteConfirm(seller)}
                  >
                    <DeleteOutlined />
                  </Button>
                </Popover>
              </>
              ) : (
                <>
                </>
              )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={sellersActive}
      columns={columns}
      rowKey="_id"
      accessToken={accessToken}
      userToken={userToken}
      permissionsActive={permissionsActive}
      roleById={roleById}
    />
  );
}

function SellersInactive(props) {
  const { sellersInactive,
          setReloadSellers,
          accessToken,
          userToken,
          permissionsActive,
          roleById,
        } = props;

  const activateSeller = (seller) => {
    const accessToken = getAccessToken();

    activateSellerApi(accessToken, seller._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadSellers(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (seller) => {
    const accessToken = getAccessToken();
    confirm({
      title: "Eliminando Vendedor",
      content: `¿Estás seguro que quieres eliminar a ${seller.name}?`,
      okText: "Eliminar",
      okMethod: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteSellerApi(accessToken, seller._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadSellers(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const activate_seller = (
    <div>
      {" "}
      <p>Pasar a Vendedores activos</p>{" "}
    </div>
  );

  const delete_seller = (
    <div>
      {" "}
      <p>Eliminar Vendedor</p>
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "seller_code",
    },
    {
      title: "Nombre",
      dataIndex: "name",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, seller) => (
        <div>
            {checkAction(permissionsActive, userToken.role, "sellers", "all") ||
            checkAction(permissionsActive, userToken.role, "sellers", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_seller}>
                <Button
                  className="button-seller"
                  type="primary"
                  onClick={() => activateSeller(seller)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
              </>
            ) : (
              <>
              </>
            )}

            {checkAction(permissionsActive, userToken.role, "sellers", "all") ||
            checkAction(permissionsActive, userToken.role, "sellers", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_seller}>
                <Button
                  className="button-seller"
                  type="danger"
                  onClick={() => showDeleteConfirm(seller)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
            ) : (
              <>
              </>
            )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={sellersInactive}
      columns={columns}
      rowKey="_id"
      accessToken={accessToken}
      userToken={userToken}
      permissionsActive={permissionsActive}
      roleById={roleById}
    />
  );
}
