import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditStateBillForm from "../EditStateBillForm";
import AddStateBillForm from "../AddStateBillForm";
import { activateStateBillApi, deleteStateBillApi } from "../../../../api/billing/stateBill";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListStateBills.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListStateBills(props) {
  const {
    stateBillsActive,
    stateBillsInactive,
    setReloadStateBills,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewStateBillsActives, setViewStateBillsActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [stateBillsActiveFilter, setStateBillsActiveFilter] = useState(
    []
  );
  const [ stateBillsInactiveFilter, setStateBillsInactiveFilter ] = useState([]);
  const [typeFilter, setMethodFilter] = useState("name");

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, [roleById]);

  useEffect(() => {
    const listActive = [];
    if (stateBillsActive) {
      stateBillsActive.forEach((element) => {
        if (typeFilter === "name") {
          if (
            element.name
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        }
      });
    }
    setStateBillsActiveFilter(listActive);
  }, [filterText, stateBillsActive]);

  useEffect(() => {
    const listInactive = [];
    if (stateBillsInactive) {
      stateBillsInactive.forEach((element) => {
        if (typeFilter === "name") {
          if (
            element.name
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        } 
      });
    }
    setStateBillsInactiveFilter(listInactive);
  }, [filterText, stateBillsInactive]);

  const addStateBillModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Estado");
    setModalContent(
      <AddStateBillForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadStateBills={setReloadStateBills}
      />
    );
  };

  return (
    <div className="list-statebills">
      <div className="list-statebills__header">
        {checkAction(permissionsActive, userToken.role, "statebills", "all") ||
        checkAction(permissionsActive, userToken.role, "statebills", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-statebills__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewStateBillsActives(!viewStateBillsActives)
                }
              />
              <span>
                {viewStateBillsActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
            <>
            </>
        )}

        {checkAction(permissionsActive, userToken.role, "statebills", "all") ||
        checkAction(permissionsActive, userToken.role, "statebills", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addStateBillModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "statebills", "all") ||
      checkAction(permissionsActive, userToken.role, "statebills", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-statebills__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <Radio.Group
              options={[
                { label: "Nombre", value: "name" },
                //{ label: "Código", value: "code" },
              ]}
              onChange={(e) => {
                setMethodFilter(e.target.value);
              }}
              value={typeFilter}
            />
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> ejemplos de estados de comprobantes 'vigente, cancelada, rechazada, adeuda'
            </h3>
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewStateBillsActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "statebills", "all") ||
          checkAction(permissionsActive, userToken.role, "statebills", "view") || isAdmin(roleById) ? (
            <>
              <StateBillsActive
                stateBillsActive={stateBillsActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadStateBills={setReloadStateBills}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "statebills", "all") ||
          checkAction(permissionsActive, userToken.role, "statebills", "view") || isAdmin(roleById) ? (
            <>
              <StateBillsInactive
                stateBillsInactive={stateBillsInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadStateBills={setReloadStateBills}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function StateBillsActive(props) {
  const {
    stateBillsActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadStateBills,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editStateBill = (stateBill) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${stateBill.name ? stateBill.name : "sin nombre"}`
    );
    setModalContent(
      <EditStateBillForm
        stateBill={stateBill}
        setIsVisibleModal={setIsVisibleModal}
        setReloadStateBills={setReloadStateBills}
      />
    );
  };

  const desactivateStateBill = (stateBill) => {
    const accessToken = getAccessToken();

    activateStateBillApi(accessToken, stateBill._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadStateBills(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (stateBill) => {
    const accessToken = getAccessToken();
    confirm({
      title: "Eliminando Estado",
      content: `¿Estás seguro que quieres eliminar a ${stateBill.name}?`,
      okText: "Eliminar",
      okMethod: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteStateBillApi(accessToken, stateBill._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadStateBills(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const edit_statebill = (
    <div>
      {" "}
      <p>Editar Estado</p>{" "}
    </div>
  );

  const desactivate_statebill = (
    <div>
      {" "}
      <p>Pasar a Estados inactivos</p>{" "}
    </div>
  );

  const delete_statebill = (
    <div>
      {" "}
      <p>Eliminar Estado</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, stateBill) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "statebills", "all") ||
          checkAction(permissionsActive, userToken.role, "statebills", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_statebill}>
                <Button
                  className="button-statebill"
                  type="default"
                  onClick={() => editStateBill(stateBill)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "statebills", "all") ||
          checkAction(permissionsActive, userToken.role, "statebills", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_statebill}>
                <Button
                  className="button-statebill"
                  type="primary"
                  onClick={() => desactivateStateBill(stateBill)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "statebills", "all") ||
          checkAction(permissionsActive, userToken.role, "statebills", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_statebill}>
                <Button
                  className="button-statebill"
                  type="danger"
                  onClick={() => showDeleteConfirm(stateBill)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={stateBillsActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function StateBillsInactive(props) {
  const { stateBillsInactive,
          setReloadStateBills,
          accessToken,
          userToken,
          permissionsActive,
          roleById
        } = props;

  const activateStateBill = (stateBill) => {
    const accessToken = getAccessToken();

    activateStateBillApi(accessToken, stateBill._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadStateBills(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (stateBill) => {
    const accessToken = getAccessToken();
    confirm({
      title: "Eliminando Estado",
      content: `¿Estás seguro que quieres eliminar a ${stateBill.name}?`,
      okText: "Eliminar",
      okMethod: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteStateBillApi(accessToken, stateBill._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadStateBills(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const activate_statebill = (
    <div>
      {" "}
      <p>Pasar a Estados activos</p>{" "}
    </div>
  );

  const delete_statebill = (
    <div>
      {" "}
      <p>Eliminar Estado</p>
    </div>
  );

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, stateBill) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "statebills", "all") ||
          checkAction(permissionsActive, userToken.role, "statebills", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_statebill}>
                <Button
                  className="button-statebill"
                  type="primary"
                  onClick={() => activateStateBill(stateBill)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "statebills", "all") ||
          checkAction(permissionsActive, userToken.role, "statebills", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_statebill}>
                <Button
                  className="button-statebill"
                  type="danger"
                  onClick={() => showDeleteConfirm(stateBill)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={stateBillsInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
