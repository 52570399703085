import { switchPath, apiVersion } from './config';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../utils/constants';
import jwtDecode from 'jwt-decode';


export function getAccessToken() {
    //return 'toma el accessToken';
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    //puede ser que devuelva false o null, entonces:
    if (!accessToken || accessToken === "null"){
        return null;
    }
    //console.log(accessToken);
    return willExpireToken(accessToken) ? null : accessToken; //true o false
}

export function getRefreshToken() {
    //return 'toma el refreshToken';
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    //puede ser que devuelva false o null, entonces:
    if (!refreshToken || refreshToken === "null"){
        return null;
    }
    //console.log(refreshToken);
    //return willExpireToken(refreshToken) ? null : refreshToken; //true o false
    return hasTokenExpired(refreshToken) ? null : refreshToken; //true o false
}

//peticion de refreshToken
export async function refreshAccessToken(refreshToken){
    const url = `${switchPath()}/${apiVersion}/refresh-access-token`;
    const bodyObj = {
        refreshToken: refreshToken
    };

    const params = {
        method: "POST",
        body: JSON.stringify(bodyObj),
        headers: {
            "Content-Type": "application/json"
        }
    };

    try {
        const response = await fetch(url, params);
        if (response.status === 200) {
            const result = await response.json();
            if (result) {
                const { accessToken, refreshToken } = result;
                localStorage.setItem(ACCESS_TOKEN, accessToken);
                localStorage.setItem(REFRESH_TOKEN, refreshToken);
            } else {
                logout();
                window.location.reload();
            }
        } else {
            // Manejo de error en caso de una respuesta no exitosa (por ejemplo, 401 Unauthorized)
            logout();
            window.location.reload();
        }
    } catch (error) {
        console.error("Error al refrescar el token:", error);
        logout();
        window.location.reload();
    }
    /*
    fetch(url, params)
        .then(response => {
            if (response.status !== 200) {
                return null;
            }
            return response.json();
        })
        .then(result => {
            if (!result) { //token caducado
                console.log("!result", !result);
                logout();
            } else {
                console.log("result", result);
                const { accessToken, refreshToken } = result;
                localStorage.setItem(ACCESS_TOKEN, accessToken);
                localStorage.setItem(REFRESH_TOKEN, refreshToken);
            }
        });
    */
}

//desloguea al user si el token caducó
export function logout() {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
}

function willExpireToken(token) {
    const seconds = 60;
    const metaToken = jwtDecode(token);
    //console.log(metaToken);
    const { exp } = metaToken;
    //const expCaducado = exp - 1000000000; //prueba caducacion
    //console.log("Date.now()", Date.now());
    const now = (Date.now() + seconds) / 1000; //div x 1000 para obtener seg
    //console.log("now", now);
    //console.log("exp", exp);
    //console.log("now > exp", now > exp);
    //return now > expCaducado; // prueba caducacion
    return now > exp;
}

function hasTokenExpired(token) {
    const currentTimestamp = Math.floor(Date.now() / 1000); // Tiempo actual en segundos
    const tokenExpiration = jwtDecode(token).exp;
    return currentTimestamp > tokenExpiration;
}