import { downloadPdf, openPdf , getStyles, getHeader, getLogo} from "../../../../utils/pdfManager"; //docDefinitionTable
//import { formatDateView } from "../../../../utils/formatDate";
//import Logo from '../../../../assets/img/png/icba.png';
//import moment from "moment";

export function listUsersPdf(data, isDownload) {
  // console.log(data);
  const _format = (data) => {
    return data.map((item) => {
      return [
        { text: item.username},
        // { text: item.role.name },
        { text: item.email },
      ];
    });
  };

  const formattedData = _format(data);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "vertical",
    header:getHeader(),
    content: [
        getLogo(),
        [
            { text:'Listado de Usuarios', style:'title'},
        ],
      '\n',
      '\n',
      {
        table: {
          headerRow: 1,
          widths:['*','*'],
          body: [
            [
              { text: "Usuario", style: "tableHeader" },
              // { text: "Rol", style: "tableHeader" },
              { text: "Email", style: "tableHeader" },
            ],
            ...formattedData,
          ],
        },
      },
    ],
    styles:getStyles()
  };
  if (isDownload) {
    downloadPdf(docDefinition, "ListadoUsuarios");
  } else {
    openPdf(docDefinition);
  }
}

export function userDetailPdf(data, isDownload) {
  const _format = (data) => {
    return [
      { text: `Usuario: ${data.username ? data.username : ""}`, style:'list' },
      { text: `Rol: ${data.role ? data.role.name : ""}` , style:'list'},
      { text: `E-mail: ${data.email ? data.email : ""}`, style:'list' },
    ];
  };

  const formattedData = _format(data);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "vertical",
    header:getHeader(),
    content: [
      getLogo(),
      '\n',
      [
          { text:'Detalle de Usuario', style:'title'},
      ],
      '\n',
      '\n',
      {
        type: "none",
        ol: [...formattedData],
      },
    ],
    styles:getStyles()
  };
  let title = `${data.name}`;
  if (isDownload) {
    downloadPdf(docDefinition, title);
  } else {
    openPdf(docDefinition);
  }
}
