import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
/*
import { 
    //getAbsencesActiveApi, 
    getAbsencesPaginateApi 
} from "../../../api/absence";
*/
import { 
    //getAbsencesActiveApi, 
    getBusinessApi 
} from "../../../api/business";
import AddEditBusinessFormPage from "../../../components/Admin/Businesses/AddEditBusinessFormPage";
// import EditSocialMediaForm from "../../../components/Admin/Items/EditSocialMedia/EditSocialMediaForm";


export default function AddEditBusiness(props) {
     //const {setReloadBusiness}=props;
     const [businessId, setBusinessId] = useState();
     const [reloadBusiness, setReloadBusiness ] = useState(false);
     const [business, setBusiness ] = useState({});
     const token = getAccessToken();


    useEffect(() => {
      setBusinessId( props.location.state? props.location.state.id : false);
    }, [token, props.location.state]);

    useEffect(() => {
      let isMounted=true;
        if (businessId) {
          getBusinessApi(token, businessId).then(
              (response) => {
                console.log(response)
                if(isMounted){
                  setBusiness(response.business);
                }
              }
          );
        }
        setReloadBusiness(false);
        return ()=>{
          isMounted=false;
      }
    }, [token, reloadBusiness, businessId]);

    

    return (
        <div className="business">
            <AddEditBusinessFormPage setReloadBusiness={setReloadBusiness} business={business} businessId={businessId} setBusinessId={setBusinessId}/>
        </div>
    );
}