import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getRolesActiveApi } from "../../../api/role";
import ListRoles from "../../../components/Admin/Role/ListRoles";


export default function Roles() {
    const [rolesActive, setRolesActive] = useState([]);
    const [rolesInactive, setRolesInactive] = useState([]);
    const [reloadRoles, setReloadRoles ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getRolesActiveApi(token, true).then(response => {
            setRolesActive(response.roles);
        });
        getRolesActiveApi(token, false).then(response => {
            setRolesInactive(response.roles);
        });
        setReloadRoles(false);
    }, [token, reloadRoles]);

    return (
        <div className="roles">
            <h3>Listado de Roles</h3>
            <ListRoles rolesActive={rolesActive} rolesInactive={rolesInactive} setReloadRoles={setReloadRoles}
                />

        </div>
    );
}