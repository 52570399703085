import React, { useState , useEffect} from "react";
import { Form, Input, InputNumber, Select, Button, Row, Col, notification } from "antd";

import { updateMovementApi} from "../../../../api/billing/movement";
import { getAccessToken } from "../../../../api/auth";
import { convertOrigin, convertMovementType } from "../../../../utils/convert";

import moment from "moment";

import "./EditMovementForm.scss";

export default function EditMovementForm(props) {
    const { movement, setIsVisibleModal, setReloadMovements } = props;

    const [movementData, setMovementData]=useState({});

   //estado relacionado a si el usuario realizó modificación en los inputs que deben ser validados
    const [modifDataToValid, setModifDataToValid] = useState({

    });
    //estado que almacena el resultado de validacion de los campos correspondientes
    const [validData, setValidData] = useState({

    });

  //trae los datos del inventario
  useEffect(() => {
    setMovementData({
      date:movement.date,
      movement_type: movement.movement_type,
      item: movement.item,
      quantity: movement.quantity,
      origin: movement.origin,
      buyBill:movement.buyBill,
      billSell:movement.billSell,
      user:movement.user,
      observation: movement.observation

    });
  }, [movement]);


    const isFormValid = (e) => {
        let errorExists = false;
        // if (!movementData.item) {
        //         notification["error"]({
        //             message: "Obligatorios: Producto"
        //     });
        //     errorExists = true;
        // }
        return errorExists;
    };

    const editMovement = async e => {
        e.preventDefault();

        if(!isFormValid()){
            const accessToken = getAccessToken();
            await updateMovementApi(accessToken, movement._id,movementData)
                .then((response) => {
                    if(response.message=== "ERR_CONNECTION_REFUSED" || response.message === "Failed to fetch" || response === undefined) {
                        notification["error"]({
                          message: "Servidor caido"
                      });
                    }
                    else if (response.code !== 200)
                        {
                            notification["error"]({
                                message: response.message
                        });
                    }
                    else {
                        notification["success"]({
                            //message: response
                            message: "Movimiento actualizado"
                        });
                        setIsVisibleModal(false);
                    }
                    setReloadMovements(true);
                })
                .catch(err => {
                    notification["error"]({
                        message: err
                    });
                });
        }
    };


    return (

        <div className="edit-movement-form">
            <EditForm
                movementData={movementData}
                setMovementData={setMovementData}
                editMovement={editMovement}
            />

        </div>
    );
}


function EditForm(props) {
    const { movementData, setMovementData, editMovement} = props;
    const { Option } = Select;

    return (
        <Form className="form-edit" onSubmit={editMovement}>

      <Row gutter={24}>
        <Col span={12}>
          <label className="control-label">Fecha</label>
          <Form.Item>
            <Input
              placeholder="Fecha"
              value={moment(new Date(movementData.date)).utc().format("DD/MM/YYYY")}
              onChange={(e) =>
                setMovementData({ ...movementData, date: e.target.value })
              }
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">Tipo</label>
          <Form.Item>
            <Input
              placeholder="Tipo de movimiento"
              value={convertMovementType(movementData.movement_type)}
              disabled={true}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={12}>
          <label className="control-label">Origen</label>
          <Form.Item>
            <Input
              name="origin"
              placeholder="Origen "
              value={convertOrigin(movementData.origin)}
              disabled={true}
            />

          </Form.Item>
        </Col>
        {movementData.buyBill?
        <Col span={12}>
          <label className="control-label">Factura de Compra</label>
          <Form.Item>
            <Input
              name="buyBill"
              value={movementData.buyBill? movementData.buyBill.code: null}
            />

          </Form.Item>
        </Col>
        : null}
        {movementData.billSell?
        <Col span={12}>
          <label className="control-label"> Factura de Venta</label>
          <Form.Item>
            <Input
              placeholder="Factura"
              name="billSell"
              value={movementData.billSell? movementData.billSell.billsell_code: null}
              disabled={true}
            />
          </Form.Item>
        </Col>
        :null}
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <label className="control-label">Producto</label>
            <Form.Item>
              <Input
                name="item"
                placeholder="Producto "
                value={movementData.item? `${movementData.item.item_code}-${movementData.item.description}`: null}
                disabled={true}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label className="control-label">Cantidad</label>
            <Form.Item>
              <InputNumber
                placeholder="Cantidad"
                name="quantity"
                value={movementData.quantity}
                size="middle"
                decimalSeparator=","
                disabled={true}
              />
            </Form.Item>
          </Col>
      </Row>

      {/* <Row gutter={24}>
        <Col span={24}>
          <label className="control-label">Usuario</label>
          <Form.Item>
            <Input
              placeholder="Usuario"
              name="user"
              value={movementData.user}
            />
          </Form.Item>
        </Col>
     </Row> */}
      <Row gutter={24}>
        <Col span={24}>
          <label className="control-label">Observaciones</label>
          <Form.Item>
            <Input
              placeholder="Observaciones"
              name="observation"
              value={movementData.observation}
              onChange={(e) =>
                setMovementData({ ...movementData, observation: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
            <Form.Item>
                <Button type="primary" htmlType="submit" className="btn-submit" onClick={editMovement}>
                   Actualizar
                </Button>
            </Form.Item>
        </Form>
    );

}

