import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import {
  TagOutlined,
} from "@ant-design/icons";
import { updateDocumentTypeApi } from "../../../../api/billing/documentType";
import { getAccessToken } from "../../../../api/auth";

import "./EditDocumentTypeForm.scss";

export default function EditDocumentTypeForm(props) {
  const { documentType, setIsVisibleModal, setReloadDocumentTypes } = props;
  const [ documentTypeData, setDocumentTypeData ] = useState({});


  //trae los datos de tipo de documento
  useEffect(() => {
    setDocumentTypeData({
      documentType_code: documentType.documentType_code,
      description: documentType.description
    });
  }, [documentType]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!documentTypeData.documenttype_code || !documentTypeData.description) {
      notification["error"]({
        message: "Obligatorios: Código y Descripción.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const updateDocumentType = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let documentTypeUpdate = documentTypeData; //es let porque se va actualizando

      updateDocumentTypeApi(token, documentTypeUpdate, documentType._id).then((result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //console.log("respuesta: " + result.message);
          setIsVisibleModal(false);
          setReloadDocumentTypes(true);
        }
      });
    }
  };

  return (
    <div className="edit-documentType-form">
      <EditForm
        documentTypeData={documentTypeData}
        setDocumentTypeData={setDocumentTypeData}
        updateDocumentType={updateDocumentType}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    documentTypeData,
    setDocumentTypeData,
    updateDocumentType,
  } = props;

  return (
    <Form className="form-edit" onSubmit={updateDocumentType}>
      <Row gutter={24}>
        <Col span={12}>
          <label className="control-label">Código</label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Código"
              value={documentTypeData.documenttype_code}
              onChange={(e) =>
                setDocumentTypeData({ ...documentTypeData, documenttype_code: e })
              }
              min={1}
              style={{ width: "90%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label"><span className="control-required">* </span>Descripción</label>
          <Form.Item>
            <Input
              placeholder="Descripción"
              value={documentTypeData.description}
              onChange={(e) =>
                setDocumentTypeData({ ...documentTypeData, description: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateDocumentType}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
