import React, { useState } from "react";
import { Form, Input, Button, Row, Col, notification } from "antd";
import {
  TagOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { addVariationApi } from "../../../../api/variation";
import { getAccessToken } from "../../../../api/auth";
import { addVariationBranchMenuAllBranchesApi, addVariationBranchMenuByBranchApi} from "../../../../api/branchMenu";

import "./AddVariationForm.scss";
import { useBusiness } from "../../../../hooks/useBusiness";

export default function AddVariationForm(props) {
  const { setIsVisibleModal, setReloadVariations , branch} = props;

  const [inputs, setInputs] = useState({
    variation_code: "",
    name: "",
    available:true
  });

  const {business}=useBusiness();


  const isFormValid = (e) => {
    //console.log(inputs);
    let errorExists = false;
    if (!inputs.name) {
      notification["error"]({
        message: "Obligatorio: nombre.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addVariation = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      const accessToken = getAccessToken();
  
      if(branch && branch!=="" && branch!==0 && branch!=="0"){
        await addVariationApi(accessToken, inputs)
        .then((response) => {
          if (
            response.message === "ERR_CONNECTION_REFUSED" ||
            response.message === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (!response.variation) {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              message: "Variacion creada",
            });
            setIsVisibleModal(false);
          }
          //console.log(response);
          return response.variation;
          //setReloadCategories(true);
        }).then(async (variation)=>{
          if(variation){
            let newData={
              available:true,
              variation:variation._id
            };
            const result=await addVariationBranchMenuByBranchApi(accessToken,branch,newData);
            return result;
          }
        }).then((result)=>{
          if (
            result.message === "ERR_CONNECTION_REFUSED" ||
            result.message === "Failed to fetch" ||
            result === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (result.code !==200) {
            notification["error"]({
              message: result.message,
            });
          } else {
            // notification["success"]({
            //   message: "Categoria asociada al negocio",
            // });
            setIsVisibleModal(false);
          }
          //console.log(response);
          setReloadVariations(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
      }else{
      //es una variacion general
      if(business && business!=="" && business!==0 && business!=="0"){
        inputs.business=business;
      }
      inputs.isGeneral=true;
      await addVariationApi(accessToken, inputs)
        .then((response) => {
          // console.log(response)
          if (
            response.message === "ERR_CONNECTION_REFUSED" ||
            response.message === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (!response.variation) {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              message: "Categoria creada",
            });
            setIsVisibleModal(false);
          }
          //console.log(response);
          return response.variation;
          //setReloadCategories(true);
        }).then(async (variation)=>{
          if(variation){
            let newData={
              available:true,
              variation:variation._id
            };
            const result=await addVariationBranchMenuAllBranchesApi(accessToken,newData, business);
            return result;
          }
        }).then((result)=>{
          console.log(result)
          if (
            result.message === "ERR_CONNECTION_REFUSED" ||
            result.message === "Failed to fetch" ||
            result === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (result.code !==200) {
            notification["error"]({
              message: result.message,
            });
          } else {
            // notification["success"]({
            //   message: "Categoria asociada al negocio",
            // });
            setIsVisibleModal(false);
          }
          //console.log(response);
          setReloadVariations(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }}
  };

  return (
    <div className="add-variation-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        addVariation={addVariation}
      />
    </div>
  );
}

function AddForm(props) {
  const { inputs, setInputs, addVariation } = props;

  return (
    <Form className="form-add" onSubmit={addVariation}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Nombre
          </label>
          <Form.Item>
            <Input
              value={inputs.name}
              onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
            />
          </Form.Item>
        </Col>
        {/* <Col span={12}>
          <Switch
            checkedChildren="Disponible"
            unCheckedChildren="No disponible"
            style={{
              marginTop:30,
              marginLeft: 10,
            }}
            checked={inputs.available}
            onChange={(e) => setInputs({ ...inputs, available: e })}
          />
        </Col> */}
      <Col span={12}>
          <label className="control-label"> <span className="control-required">*</span>Precio</label>
          <Form.Item>
            <Input
              type="number"
              addonBefore="$"
              style={{ width: "90%" }}
              value={inputs.price}
              step="0.01"
              onChange={(e) => setInputs({ ...inputs, price: e.target.value  })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
             Minima Cantidad
          </label>
          <Form.Item>
            <Input
              value={inputs.minQuantity}
              type="number"
              style={{ width: "100%" }}
              step="0.01"
              onChange={(e) => setInputs({ ...inputs, minQuantity: e })}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">Maxima Cantidad</label>
          <Form.Item>
            <Input
              type="number"
              style={{ width: "100%" }}
              value={inputs.maxQuantity}
              step="0.01"
              onChange={(e) => setInputs({ ...inputs, maxQuantity: e })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addVariation}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
