import React, { useState , useEffect} from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import {
  UserOutlined,
  MailOutlined,
  CarOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import { addClientApi } from "../../../../api/billing/client";
import { getPersonsActiveApi } from "../../../../api/person";
import { getIvaConditionsActiveApi } from "../../../../api/billing/ivaCondition";
import { getAccessToken } from "../../../../api/auth";

import "./AddClientForm.scss";

export default function AddClientForm(props) {
  const { setIsVisibleModal, setReloadClients } = props;
  const [ listPersonsActive, setListPersonsActive ] = useState([]);
  const [ ivaConditionsActive, setIvaConditionsActive ] = useState([]);

  useEffect(() => {
    const accessToken=getAccessToken();
    getPersonsActiveApi(accessToken,true).then((response) => {
      setListPersonsActive(response.persons);
    });
  }, []);

  useEffect(() => {
    const accessToken=getAccessToken();
    getIvaConditionsActiveApi(accessToken,true).then((response) => {
      setIvaConditionsActive(response.ivaConditions);
    });
  }, []);

  const [inputs, setInputs] = useState({
    person: "",
    denomination: "",
    ivaCondition: "",
    taxes: "",
  });

  const isFormValid = (e) => {
    let errorExists = false;
    if (!inputs.person || !inputs.denomination || !inputs.ivaCondition) { //inputs.perception_bsas ===null || inputs.perception_caba ===null
      notification["error"]({
        message: "Obligatorios: Persona, denominacion y condicion de iva",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addClient = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      const accessToken = getAccessToken();

      await addClientApi(accessToken, inputs)
        .then((response) => {
          if (
            response === "ERR_CONNECTION_REFUSED" ||
            response === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          }
          else {
            notification["success"]({
              message: "Cliente creado",
            });
            setIsVisibleModal(false);
          }
          setReloadClients(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  return (
    <div className="add-client-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        addClient={addClient}
        listPersonsActive={listPersonsActive}
        ivaConditionsActive={ivaConditionsActive}
      />
    </div>
  );
}

function AddForm(props) {
  const { inputs, setInputs, listPersonsActive, addClient , ivaConditionsActive } = props;
  const { Option } = Select;

  return (
    <Form className="form-add" onSubmit={addClient}>
     <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
            <span className="control-required">*</span>Persona
          </label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona una persona"
              value={inputs.person}
              onChange={(e) => setInputs({ ...inputs, person: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listPersonsActive
                ? listPersonsActive.map((person) => {
                    return (
                      <Option key={person._id}>
                        {person.name + " " + person.lastname}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
            <span className="control-required">*</span> Denominacion
          </label>
          <Form.Item>
            <Input
              // prefix={<EnvironmentOutlined />}
              placeholder="Denominacion"
              value={inputs.denomination}
              onChange={(e) =>
                setInputs({ ...inputs, denomination: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
            <span className="control-required">*</span> Condicion de IVA
          </label>
          <Form.Item>
          <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona una condicion de IVA"
              value={inputs.ivaCondition}
              onChange={(e) => setInputs({ ...inputs, ivaCondition: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {ivaConditionsActive
                ? ivaConditionsActive.map((condition) => {
                    return (
                      <Option key={condition._id}>
                        {condition.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">Impuestos</label>
          <Form.Item>
            <Input
              name="taxes"
              placeholder="Impuestos"
              onChange={(e) =>
                setInputs({ ...inputs, taxes: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addClient}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
