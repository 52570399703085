import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Row,
  Col,
  notification,
  Select
} from "antd";
import { TagOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { addSalePointApi } from "../../../../api/billing/salePoint";
//import { getSellersActiveApi } from "../../../../api/billing/seller";
import { getUsersActiveApi } from "../../../../api/user";
import { getBillSystemsActiveApi } from "../../../../api/billing/billSystem";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";
//import { numberValidation } from "../../../../utils/formValidation";

import "./AddSalePointForm.scss";

export default function AddSalePointForm(props) {
  const { setIsVisibleModal, setReloadSalePoints } = props;
  //const [activeSellers, setActiveSellers] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [activeBillSystems, setActiveBillSystems] = useState([]);

  const accessToken = getAccessToken();
  //const metaToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  /*
  useEffect(() => {
    getSellersActiveApi(accessToken, true).then((response) => {
      setActiveSellers(response.sellers);
    });
  }, []);
  */

  useEffect(() => {
    getUsersActiveApi(accessToken, true).then((response) => {
      setActiveUsers(response.users);
    });
  }, []);

  useEffect(() => {
    getBillSystemsActiveApi(accessToken, true).then((response) => {
      setActiveBillSystems(response.billSystems);
    });
  }, []);

  const [inputs, setInputs] = useState({
    salepoint_code: "",
    //seller: "",
    user: "",
    fantasy_name: "",
    address: "",
    number: "",
    postal_code: "",
    locality: "",
    province: "",
    system_bill: ""
  });

  //  const [formValid, setFormValid] = useState({
  //    item_code: false,
  //    name: false
  //  });

  // const inputValidation = (e) => {
  //   const { type, name } = e.target;

  //   setInputs({
  //     ...inputs,
  //     [name]: e.target.value,
  //   });

  //   setFormValid({
  //     ...formValid,
  //     [item_code_valid]:"sucess"
  //   });
  // };
  /*
  const [formValid, setFormValid] = useState({
    phone: false
  });*/

  const inputValidation = (e) => {
    const { type, name } = e.target;

    setInputs({
      ...inputs,
      [name]: e.target.value,
    });
    /*
    if (name === "phone"){
      setFormValid({
        ...formValid,
            [name]: numberValidation(e.target)
        });
    }*/
  };

  const isFormValid = (e) => {
    //console.log(inputs);
    let errorExists = false;
    if (
        !inputs.fantasy_name ||
        !inputs.user ||
        !inputs.address ||
        !inputs.number ||
        !inputs.postal_code ||
        !inputs.locality ||
        !inputs.province ||
        !inputs.system_bill) {
      notification["error"]({
        message: "Obligatorios: Todos.",
      });
      errorExists = true;
    }
    /*
    if (!formValid.phone && inputs.phone !== "") {
      notification["error"]({
        message: "Ingreso de telefono inválido",
      });
      errorExists = true;
    }*/
    return errorExists;
  };

  const addSalePoint= async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      const accessToken = getAccessToken();

      await addSalePointApi(accessToken, inputs)
        .then((response) => {
          if (response === "ERR_CONNECTION_REFUSED" ||response === "Failed to fetch" ||response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          }
          else {
            notification["success"]({
              message: "Punto de venta creado",
            });
            setIsVisibleModal(false);
          }
          setReloadSalePoints(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  return (
    <div className="add-salepoint-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        inputValidation={inputValidation}
        addSalePoint={addSalePoint}
        //activeSellers={activeSellers}
        activeUsers={activeUsers}
        activeBillSystems={activeBillSystems}
      />
    </div>
  );
}

function AddForm(props) {
  const { inputs, setInputs, addSalePoint, inputValidation, activeUsers, activeBillSystems } = props;
  const { Option } = Select;

  return (
    <Form className="form-add" onSubmit={addSalePoint}>
      <Row gutter={24}>
        <Col span={12}>
          <label className="control-label"> Código</label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={inputs.salepoint_code}
              onChange={(e) =>
                setInputs({ ...inputs, salepoint_code: e })
              }
              min={1}
              style={{ width: '100%' }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label"><span className="control-required">*</span> Nombre de fantasía</label>
          <Form.Item >
            <Input
              placeholder="Nombre de fantasía"
              value={inputs.fantasy_name}
              onChange={(e) =>
                setInputs({ ...inputs, fantasy_name: e.target.value })
              }
              required
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <label className="control-label"><span className="control-required">*</span> Calle</label>
          <Form.Item>
            <Input
              prefix={<EnvironmentOutlined />}
              placeholder="Calle"
              name="address"
              value={inputs.address}
              onChange={(e) =>
                setInputs({ ...inputs, address: e.target.value })
              }
              required
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label"><span className="control-required">*</span> Altura (número)</label>
          <Form.Item>
            <Input
              prefix={<EnvironmentOutlined />}
              placeholder="Altura"
              name="number"
              value={inputs.number}
              onChange={(e) => setInputs({ ...inputs, number: e.target.value })}
              //onChange={inputValidation}
              required
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <label className="control-label"><span className="control-required">*</span> Código Postal</label>
          <Form.Item>
            <Input
              prefix={<EnvironmentOutlined />}
              placeholder="Código Postal"
              name="postal_code"
              value={inputs.postal_code}
              onChange={(e) => setInputs({ ...inputs, postal_code: e.target.value })}
              //onChange={inputValidation}
              required
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label"><span className="control-required">*</span> Localidad</label>
          <Form.Item>
            <Input
              prefix={<EnvironmentOutlined />}
              placeholder="Localidad"
              name="locality"
              value={inputs.locality}
              onChange={(e) =>
                setInputs({ ...inputs, locality: e.target.value })
              }
              required
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <label className="control-label"><span className="control-required">*</span> Provincia</label>
          <Form.Item>
            <Input
              prefix={<EnvironmentOutlined />}
              placeholder="Provincia"
              name="province"
              value={inputs.province}
              onChange={(e) =>
                setInputs({ ...inputs, province: e.target.value })}
              required
            />
          </Form.Item>
        </Col>

      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Vendedor (usuario)
          </label>
          <Form.Item>
            <Select
              placeholder="Vendedor"
              name="user"
              value={inputs.user}
              onChange={(e) => setInputs({ ...inputs, user: e })}
              required
            >
              {activeUsers
                ? activeUsers.map((i) => {
                    return <Option key={i._id}>{i.person.name + " " +  i.person.lastname}</Option>;
                  })
                : "No hay vendedores (usuarios) activos"}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label"><span className="control-required">*</span> Sistema de facturación</label>
          <Form.Item>
            <Select
              placeholder="Sistema de facturación"
              name="system_bill"
              value={inputs.system_bill}
              onChange={(e) =>
                setInputs({ ...inputs, system_bill: e })
              }
              required
              >
              {activeBillSystems
                ? activeBillSystems.map((i) => {
                    return <Option key={i._id}>{i.description}</Option>;
                  })
                : "No hay sistemas de facturación activos"}
            </Select>

          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addSalePoint}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
