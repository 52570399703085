import React, { useState } from "react";
import { 
  //InputNumber, 
  Form, Input, Button, Row, Col, notification } from "antd";
//import { TagOutlined, EnvironmentOutlined, PhoneOutlined } from "@ant-design/icons";
import { addCategoryApi } from "../../../../api/category";
import { getAccessToken } from "../../../../api/auth";
//import { numberValidation } from "../../../../utils/formValidation";
import { updateBranchMenu, addCategoryBranchMenuByBranchApi, addCategoryBranchMenuAllBranchesApi } from "../../../../api/branchMenu";

import { useBranch } from "../../../../hooks/useBranch";

import "./AddCategoryForm.scss";
import { useBusiness } from "../../../../hooks/useBusiness";

const { TextArea } = Input;

export default function AddCategoryForm(props) {
  const { setIsVisibleModal, setReloadCategories } = props;

  const [inputs, setInputs] = useState({
    name: "",
    description: "",
  });

  /*
  const [formValid, setFormValid] = useState({
    name: false,
  });
  */
  const {branch}=useBranch();
  const {business}=useBusiness();

  const inputValidation = (e) => {
    const { 
      //type, 
      name
     } = e.target;

    setInputs({
      ...inputs,
      [name]: e.target.value,
    });
  };

  const isFormValid = (e) => {
    //console.log(inputs);
    let errorExists = false;
    if (!inputs.name) {
      notification["error"]({
        message: "Obligatorio: Nombre.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addCategory = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      const accessToken = getAccessToken();
      if(branch && branch!=="" && branch!==0 && branch!=="0"){
        await addCategoryApi(accessToken, inputs)
        .then((response) => {
          if (
            response.message === "ERR_CONNECTION_REFUSED" ||
            response.message === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (!response.category) {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              message: "Categoria creada",
            });
            setIsVisibleModal(false);
          }
          //console.log(response);
          return response.category;
          //setReloadCategories(true);
        }).then(async (category)=>{
          if(category){
            let newData={
              available:true,
              category:category._id
            };
            const result=await addCategoryBranchMenuByBranchApi(accessToken,branch,newData);
            return result;
          }
        }).then((result)=>{
          if (
            result.message === "ERR_CONNECTION_REFUSED" ||
            result.message === "Failed to fetch" ||
            result === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (result.code !==200) {
            notification["error"]({
              message: result.message,
            });
          } else {
            // notification["success"]({
            //   message: "Categoria asociada al negocio",
            // });
            setIsVisibleModal(false);
          }
          //console.log(response);
          setReloadCategories(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
      }else{
        //es una categoria general por lo tanto guardo datos del negocio
      if(business && business!=="" && business!==0 && business!=="0"){
        inputs.business=business;
      }
      inputs.isGeneral=true;
      await addCategoryApi(accessToken, inputs)
        .then((response) => {
          // console.log(response)
          if (
            response.message === "ERR_CONNECTION_REFUSED" ||
            response.message === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (!response.category) {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              message: "Categoria creada",
            });
            setIsVisibleModal(false);
          }
          //console.log(response);
          return response.category;
          //setReloadCategories(true);
        }).then(async (category)=>{
          if(category){
            let newData={
              available:true,
              category:category._id
            };
            const result=await addCategoryBranchMenuAllBranchesApi(accessToken,newData, business);
            return result;
          }
        }).then((result)=>{
          if (
            result.message === "ERR_CONNECTION_REFUSED" ||
            result.message === "Failed to fetch" ||
            result === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (result.code !==200) {
            notification["error"]({
              message: result.message,
            });
          } else {
            setIsVisibleModal(false);
          }
          setReloadCategories(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }}
  };

  return (
    <div className="add-category-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        inputValidation={inputValidation}
        addCategory={addCategory}
      />
    </div>
  );
}

function AddForm(props) {
  const { 
    inputs, setInputs, addCategory, 
    //inputValidation
   } = props;

  return (
    <Form className="form-add" onSubmit={addCategory}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {/* <Col span={12}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={inputs.department_code}
              onChange={(e) => setInputs({ ...inputs, department_code: e })}
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col> */}
        <Col span={24}>
          <label className="control-label">
            <span className="control-required">*</span> Nombre
          </label>
          <Form.Item>
            <Input
              placeholder="Nombre"
              value={inputs.name}
              onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
            Descripción
          </label>
          <Form.Item>
          <TextArea
              maxLength={100}
              onChange={(e) => setInputs({ ...inputs, description: e.target.value })}
              style={{ height: 120, resize: 'none' , marginBottom:20}}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addCategory}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
