import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  BackTop,
  Row,
  Col,
  //Select
} from "antd";
import {
  Link,
  //withRouter
} from "react-router-dom";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { Popover } from "antd";

import Modal from "../../../Modal";
import AddEditBusinessForm from "../AddEditBusinessForm";
import {
  activateBusinessApi,
  deleteBusinessApi,
} from "../../../../api/business";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
//import { getCourseApi } from "../../../../api/course";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";
import "./ListBusinesses.scss";

const { confirm } = ModalAntd;
const { Search } = Input;
//const { Option } = Select;

export default function ListBusinesses(props) {
  const {
    businessesActive,
    businessesInactive,
    setReloadBusinesses,
    //setPaginationActive, setPaginationInactive
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewBusinessesActives, setViewBusinessesActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [modalWidth, setModalWidth] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [businessesActiveFilter, setBusinessesActiveFilter] = useState([]);
  const [businessessInactiveFilter, setBusinessesInactiveFilter] = useState([]);
  //const [propsPaginationActive, setPropsPaginationActive] = useState({});
  //const [propsPaginationInactive, setPropsPaginationInactive] = useState({});

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado
  //console.log("userToken", userToken);

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then((response) => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then((response) => {
      setRoleById(response.role);
    });
  }, []);

  // useEffect(() => {
  //   getStateCoursesActiveApi(accessToken, true).then((response) => {
  //     setListStateCoursesActive(response.stateCourses);
  //   });
  // }, []);

  // useEffect(() => {
  //   const listActive = [];
  //   if (absencesActive) {
  //     absencesActive.forEach((element) => {
  //         if (
  //           (element.course && element.course.name.toUpperCase().indexOf(filterText.toUpperCase()) !==
  //             -1 ) ||
  //           filterText === ""
  //         ) {
  //           listActive.push(element);
  //         }
  //     });
  //   }
  //   setAbsencesActiveFilter(listActive);
  // }, [filterText, absencesActive]);

  // useEffect(() => {
  //   const listInactive = [];
  //   if (absencesInactive) {
  //     absencesInactive.forEach((element) => {
  //         if (
  //           (element.course && element.course.name.toUpperCase().indexOf(filterText.toUpperCase()) !==
  //             -1 )||
  //           filterText === ""
  //         ) {
  //           listInactive.push(element);
  //         }
  //     });
  //   }
  //   setAbsencesInactiveFilter(listInactive);
  // }, [filterText, absencesInactive]);

  useEffect(() => {
    const listActive = [];
    if (businessesActive) {
      businessesActive.forEach((element) => {
        if (
          element.name.toUpperCase().includes(filterText.toUpperCase()) ||
          filterText === ""
        ) {
          listActive.push(element);
        }
      });
    }
    setBusinessesActiveFilter(listActive);
  }, [filterText, businessesActive]);

  useEffect(() => {
    const listInactive = [];
    if (businessesInactive) {
      businessesInactive.forEach((element) => {
        if (
          element.name.toUpperCase().includes(filterText.toUpperCase()) ||
          filterText === ""
        ) {
          listInactive.push(element);
        }
      });
    }
    setBusinessesInactiveFilter(listInactive);
  }, [filterText, businessesInactive]);

  const addBusinessModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Negocio");
    setModalWidth(1000);
    setModalContent(
      <AddEditBusinessForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadBusinesses={setReloadBusinesses}
      />
    );
  };

  return (
    <div className="list-businesses">
      <div className="list-businesses__header">
        {checkAction(permissionsActive, userToken.role, "absences", "all") ||
        checkAction(permissionsActive, userToken.role, "absences", "enable") ||
        isAdmin(roleById) ? (
          <>
            <div className="list-businesses__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewBusinessesActives(!viewBusinessesActives)
                }
              />
              <span>{viewBusinessesActives ? "Activos" : "Inactivos"}</span>
            </div>
          </>
        ) : (
          <></>
        )}

        {checkAction(permissionsActive, userToken.role, "absences", "all") ||
        checkAction(permissionsActive, userToken.role, "absences", "add") ||
        isAdmin(roleById) ? (
          <>
            {/* <Link to="/admin/config/business"> */}
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={addBusinessModal}
              >
                Nuevo
              </Button>
            {/* </Link> */}
          </>
        ) : (
          <></>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "absences", "all") ||
      checkAction(permissionsActive, userToken.role, "absences", "view") ||
      isAdmin(roleById) ? (
        <>
          <div className="list-businesses__search">
            <Row gutter={24}>
              <Col span={24}>
                <Search
                  className="Buscar"
                  allowClear
                  placeholder="Buscar"
                  onSearch={(e) => setFilterText(e)}
                  enterButton
                />
              </Col>
            </Row>
            <h2></h2>
            <h3>
              <strong>Info:</strong>
            </h3>
          </div>
        </>
      ) : (
        <></>
      )}

      {viewBusinessesActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "absences", "all") ||
          checkAction(permissionsActive, userToken.role, "absences", "view") ||
          isAdmin(roleById) ? (
            <>
              <BusinessesActive
                businessesActive={businessesActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadBusinesses={setReloadBusinesses}
                setModalWidth={setModalWidth}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                filterText={filterText}
                //showDeleteConfirm={showDeleteConfirm}
              />
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "absences", "all") ||
          checkAction(permissionsActive, userToken.role, "absences", "view") ||
          isAdmin(roleById) ? (
            <>
              <BusinessesInactive
                businessesInactive={businessessInactiveFilter}
                setReloadBusinesses={setReloadBusinesses}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                filterText={filterText}
                // listStateCoursesActive={listStateCoursesActive}
                //showDeleteConfirm={showDeleteConfirm}
              />
            </>
          ) : (
            <></>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        modalWidth={modalWidth}
      >
        {modalContent}
      </Modal>

      <BackTop />
    </div>
  );
}

function BusinessesActive(props) {
  const {
    businessesActive,
    setReloadBusinesses,
    setIsVisibleModal,
    setModalTitle,
    setModalWidth,
    setModalContent,
    accessToken,
    userToken,
    permissionsActive,
    roleById,
    setPagination,
    propsPagination,
    filterText,
  } = props; //showDeleteConfirm

  const editBusiness = (business) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar : ${business.name ? business.name : "negocio"}`);
    setModalWidth(1000);
    setModalContent(
      <AddEditBusinessForm
        business={business}
        setIsVisibleModal={setIsVisibleModal}
        setReloadBusinesses={setReloadBusinesses}
      />
    );
  };

  const desactivateBusiness = (business) => {
    //const accessToken = getAccessToken();

    activateBusinessApi(accessToken, business._id, false)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setReloadBusinesses(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = (business) => {
    //const accessToken = getAccessToken();

    confirm({
      title: "Eliminando -> Negocio",
      content: `¿Estás seguro que quieres eliminar a ${
        business.name ? business.name : "negocio"
      }?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteBusinessApi(accessToken, business._id)
          .then((response) => {
            notification["success"]({
              message: response.message,
            });
            if (response.code === 200) {
            }
            setReloadBusinesses(true);
            setIsVisibleModal(false);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const edit_business = (
    <div>
      {" "}
      <p>Editar negocio</p>{" "}
    </div>
  );

  const desactivate_business = (
    <div>
      {" "}
      <p>Pasar a negocio inactivos</p>{" "}
    </div>
  );

  const delete_business = (
    <div>
      {" "}
      <p>Eliminar negocio</p>{" "}
    </div>
  );
  // const download_absence = (
  //   <div>
  //     {" "}
  //     <p>Descargar detalle inasistencia</p>{" "}
  //   </div>
  // );
  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, business) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "absences", "all") ||
          checkAction(permissionsActive, userToken.role, "absences", "edit") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={edit_business}>
                  <Button
                    className="button-edit"
                    onClick={() => editBusiness(business)}
                  >
                    <EditOutlined />
                  </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {checkAction(permissionsActive, userToken.role, "absences", "all") ||
          checkAction(
            permissionsActive,
            userToken.role,
            "absences",
            "enable"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_business}>
                <Button
                  className="button-business"
                  type="primary"
                  onClick={() => desactivateBusiness(business)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {checkAction(permissionsActive, userToken.role, "absences", "all") ||
          checkAction(
            permissionsActive,
            userToken.role,
            "absences",
            "delete"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={delete_business}>
                <Button
                  className="button-business"
                  type="danger"
                  onClick={() => showDeleteConfirm(business)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={() => {
        return businessesActive
          ? `Total de registros: ${businessesActive.length}`
          : false;
      }}
      pagination={{
        ...propsPagination,
        showSizeChanger: false,
        onChange: (newPage) => {
          setPagination({ page: newPage, state: 1, filter: filterText });
        },
      }}
      dataSource={businessesActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function BusinessesInactive(props) {
  const {
    businessesInactive,
    setReloadBusinesses,
    accessToken,
    userToken,
    permissionsActive,
    roleById,
    propsPagination,
    setPagination,
    filterText,
  } = props; //showDeleteConfirm

  const activateBusiness = (business) => {
    activateBusinessApi(accessToken, business._id, true)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setReloadBusinesses(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = (business) => {
    confirm({
      title: "Eliminando -> Negocio",
      content: `¿Estás seguro que quieres eliminar a ${
        business.name ? business.name : "negocio"
      }?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteBusinessApi(accessToken, business._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            if (response.code === 200) {
            }

            setReloadBusinesses(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const activate_business = (
    <div>
      {" "}
      <p>Pasar a negocios activos</p>{" "}
    </div>
  );

  const delete_business = (
    <div>
      {" "}
      <p>Eliminar negocio</p>{" "}
    </div>
  );
  // const download_absence = (
  //   <div>
  //     {" "}
  //     <p>Descargar detalle inasistencia</p>{" "}
  //   </div>
  // );

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, business) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "absences", "all") ||
          checkAction(
            permissionsActive,
            userToken.role,
            "absences",
            "enable"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={activate_business}>
                <Button
                  className="button-activate"
                  onClick={() => activateBusiness(business)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {checkAction(permissionsActive, userToken.role, "absences", "all") ||
          checkAction(
            permissionsActive,
            userToken.role,
            "absences",
            "delete"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={delete_business}>
                <Button
                  className="button-business"
                  type="danger"
                  onClick={() => showDeleteConfirm(business)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={() => {
        return businessesInactive
          ? `Total de registros: ${businessesInactive.length}`
          : false;
      }}
      pagination={{
        ...propsPagination,
        showSizeChanger: false,
        onChange: (newPage) => {
          setPagination({ page: newPage, state: 2, filter: filterText });
        },
      }}
      dataSource={businessesInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
