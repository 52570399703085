import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getIvaConditionsActiveApi } from "../../../api/billing/ivaCondition";
import ListIvaConditions from "../../../components/Billing/IvaConditions/ListIvaConditions";


export default function IvaConditions() {
    const [ivaConditionsActive, setIvaConditionsActive] = useState([]);
    const [ivaConditionsInactive, setIvaConditionsInactive] = useState([]);
    const [reloadIvaConditions, setReloadIvaConditions ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getIvaConditionsActiveApi(token, true).then(response => {
            setIvaConditionsActive(response.ivaConditions);
        });
        getIvaConditionsActiveApi(token, false).then(response => {
            setIvaConditionsInactive(response.ivaConditions);
        });
        setReloadIvaConditions(false);
    }, [token, reloadIvaConditions]);

    return (
        <div className="ivaConditions">

            <ListIvaConditions ivaConditionsActive={ivaConditionsActive} ivaConditionsInactive={ivaConditionsInactive} setReloadIvaConditions={setReloadIvaConditions} 
                />

        </div>
    );
}