import React, { useState, useEffect } from "react";
import { Form, Input, InputNumber, Button, Row, Col, notification } from "antd";
import { TagOutlined } from "@ant-design/icons";
import { updateBillTypeApi } from "../../../../api/billing/billType";
import { getAccessToken } from "../../../../api/auth";

import "./EditBillTypeForm.scss";

export default function EditBillTypeForm(props) {
  const { billType, setIsVisibleModal, setReloadBillTypes } = props;
  const [billTypeData, setBillTypeData] = useState({});

  //trae los datos de tipo de factura
  useEffect(() => {
    setBillTypeData({
      billtype_code: billType.billtype_code,
      description: billType.description,
    });
  }, [billType]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!billTypeData.billtype_code || !billTypeData.description) {
      notification["error"]({
        message: "Obligatorios: Código y Descripción.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const updateBillType = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let billTypeUpdate = billTypeData; //es let porque se va actualizando

      updateBillTypeApi(token, billTypeUpdate, billType._id).then((result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //console.log("respuesta: " + result.message);
          setIsVisibleModal(false);
          setReloadBillTypes(true);
        }
      });
    }
  };

  return (
    <div className="edit-billtype-form">
      <EditForm
        billTypeData={billTypeData}
        setBillTypeData={setBillTypeData}
        updateBillType={updateBillType}
      />
    </div>
  );
}

function EditForm(props) {
  const { billTypeData, setBillTypeData, updateBillType } = props;

  return (
    <Form className="form-edit" onSubmit={updateBillType}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="codigo"
              value={billTypeData.billtype_code}
              onChange={(e) =>
                setBillTypeData({ ...billTypeData, billtype_code: e })
              }
              min={1}
              style={{ width: "90%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">* </span>Descripción
          </label>
          <Form.Item>
            <Input
              placeholder="Descripción"
              value={billTypeData.description}
              onChange={(e) =>
                setBillTypeData({
                  ...billTypeData,
                  description: e.target.value.toUpperCase(),
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateBillType}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
