import {
  downloadPdf,
  openPdf,
  getLogo,
  getHeader,
  getStyles,
} from "../../../../utils/pdfManager";
import { convertState, convertPaid } from "../../../../utils/convert";
import { formatDateView } from "../../../../utils/formatDate";
import Logo from "../../../../assets/img/png/ball.png";

function itemsPdf(data) {
  return data.map((item) => {
    return [
      {
        text: `${item._item.description ? item._item.description : ""} -> ${
          item.quantity ? item.quantity : ""
        } x $${item.unitValue ? item.unitValue : ""}`,
      },
      //{text:`Cantidad: ${item.quantity? item.quantity : ""}`},
      //{text:`Nombre: ${item._item.description? item._item.description : ""}`},
      //{text:`Valor Unitario: ${item.unitValue? item.unitValue : ""}`}
    ];
  });
}
function itemsDetailsPdf(data) {
  return data.map((item) => {
    return [
      { text: `${item._item.description ? item._item.description : ""}` },
      { text: `${item._item.description ? item._item.description : ""}` },
      { text: `${item.unitValue ? item.unitValue : ""}` },
      { text: `${item.quantity ? item.quantity : ""}` },
    ];
  });
}

export function listBillSellsPdf(data, isDownload) {
  console.log(data);
  const _format = (data) => {
    return data.map((item) => {
      return [
        { text: item.code },
        { text: item.client },
        // { text: item.seller },
        // { text: item.salePoint },
        // { text: item.user },
        // { text: item.subtotal },
        { text: item.total },
        // { text: convertState(item.state) },
        { text: item.state.name },
        { text: formatDateView(item.date) },
        // { text: convertPaid(item.paid) },
        // { text: item.observation },
        { text: item.items },
      ];
    });
  };

  const formattedData = _format(data);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "landscape",
    header: getHeader(),
    content: [
      { text: "Listado de Facturas de ventas" },
      "\n",
      {
        table: {
          headerRow: 1,
          dontBreakRows: true,
          body: [
            [
              { text: "Código", style: "tableHeader" },
              { text: "Cliente", style: "tableHeader" },
              // { text: "Vendedor", style: "tableHeader" },
              // { text: "Punto de venta", style: "tableHeader" },
              // { text: "Usuario", style: "tableHeader" },
              // { text: "Sub total", style: "tableHeader" },
              { text: "Total", style: "tableHeader" },
              { text: "Estado", style: "tableHeader" },
              { text: "Fecha", style: "tableHeader" },
              // { text: "¿Pagado?", style: "tableHeader" },
              // { text: "Observaciones", style: "tableHeader" },
              { text: "Items", style: "tableHeader" },
            ],
            ...formattedData,
          ],
        },
      },
    ],
  };
  if (isDownload) {
    downloadPdf(docDefinition, "ListadoFacturasDeVenta");
  } else {
    openPdf(docDefinition);
  }
}

export function billSellDetailPdf(data, isDownload) {
  //console.log(data.items);

  const _format = (data) => {
    return [
      { text: `Código: ${data.code ? data.code : ""}` },
      { text: `Cliente: ${data.client.name ? data.client.name : ""}` },
      // { text: `Vendedor: ${data.seller.name ? data.seller.name : ""}` },
      // {
      //   text: `Punto de venta: ${
      //     data.salePoint.salepoint_code ? data.salePoint.address : ""
      //   }`,
      // },
      // { text: `Usuario: ${data.user.name ? data.user.name : ""}` },
      // { text: `Sub total: $ ${data.subtotal ? data.subtotal : ""}` },
      { text: `Total: $ ${data.total ? data.total : ""}` },
      { text: `Estado: ${data.state? data.state.name : ""}` },
      { text: `Fecha: ${formatDateView(data.date)}` },
      // { text: `¿Pagado?: ${convertPaid(data.paid)}` },
      // { text: `Observaciones: ${data.observation ? data.observation : ""}` },
      //{text:`Items: ${itemsPdf(data.items)}`},
    ];
  };

  const formattedData2 = itemsDetailsPdf(data.items);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "vertical",
    content: [
      {
        table: {
          widths: ["*", 25, 25, "*"],
          headerRows: 1,
          // keepWithHeaderRows: 1,
          body: [
            [{ text: "ORIGINAL", colSpan: 4, alignment: "center" }, {}, {}, {}],
            [
              {
                columns: [{ image: Logo, width: 40, heigth: 40 }],
                border: [true, false, false, false],
              },
              { text: "A", colSpan: 2, alignment: "center" },
              {},
              { text: "", border: [true, false, true, false] },
            ],
            [
              {
                text: [
                  {
                    text: `NOMBRE DE LA EMPRESA\n`,
                    alignament: "center",
                    bold: true,
                  },
                  { text: ` Direccion: `, bold: true },
                  { text: ` Calle Nro BS AS\n` },
                  { text: ` Condicion frente al IVA `, bold: true },
                  { text: ` xxxxx@xxxxx.com\n` },
                ],
                alignament: "center",
                border: [true, false, true, true],
                colSpan: 2,
              },
              {},
              {
                text: [
                  { text: `Nro: `, bold: true, fontSize: 14 },
                  {
                    text: `${data.code ? data.code : ""}\n`,
                    bold: true,
                    fontSize: 14,
                  },
                  { text: `Fecha de emision: `, bold: true },
                  { text: `${formatDateView(data.date)}\n` },
                  { text: `CUIT: `, bold: true },
                  { text: `${data.state? data.state.name : ""}\n` },
                  { text: ` Ingresos Brutos `, bold: true },
                  { text: ` xxxxx\n` },
                  { text: ` Fecha de inicio de actividades `, bold: true },
                  { text: ` xx/xx/xxxx\n` },
                ],
                alignament: "center",
                colSpan: 2,
                border: [false, false, true, true],
              },
              {},
            ],
          ],
        },
      },
      "\n",
      {
        text: "DATOS DEL CLIENTE",
        bold: true,
      },
      {
        table: {
          widths: ["*", "*"],
          headerRows: 1,
          // keepWithHeaderRows: 1,
          body: [
            [
              {
                text: [
                  { text: `CUIT/CUIL:`, alignament: "center", bold: true },
                  {
                    text: `${
                      data.client.person.cuit_cuil
                        ? data.client.person.cuit_cuil
                        : ""
                    } \n`,
                    bold: true,
                  },
                  { text: `Condicion frente al I.V.A:`, bold: true },
                  { text: `xxxxxx:\n ` },
                  { text: `Condicion de venta:`, bold: true },
                  { text: ` xxxxxxxx\n` },
                  { text: `Orden de compra: `, bold: true },
                  { text: ` xxxx-xxxx\n` },
                  { text: `Remito: `, bold: true },
                  { text: ` xxxx-xxxx\n` },
                ],
                border: [true, true, false, true],
              },
              {
                text: [
                  { text: `Razón Social: `, bold: true, fontSize: 14 },
                  {
                    text: `${data.client.denomination ? data.client.denomination : ""}\n`,
                    bold: true,
                    fontSize: 14,
                  },
                  { text: `Domicilio: `, bold: true },
                  {
                    text: ` ${
                      data.address ? data.address : ""
                    }\n`,
                  },
                ],
                border: [false, true, true, true],
              },
            ],
          ],
        },
      },
      "\n",
      // {
      //   text: "DATOS DEL VENDEDOR",
      //   bold: true,
      // },
      // {
      //   table: {
      //     widths: ["*", "*"],
      //     headerRows: 1,
      //     // keepWithHeaderRows: 1,
      //     body: [
      //       [
      //         {
      //           text: [
      //             { text: `Nombre:`, alignament: "center", bold: true },
      //             { text: `${data.seller.name ? data.seller.name : ""}: \n` },
      //             { text: `Telefono`, bold: true },
      //             {
      //               text: ` ${
      //                 data.salePoint.salepoint_code
      //                   ? data.salePoint.salepoint_code
      //                   : ""
      //               }\n`,
      //             },
      //             { text: `Punto de venta:`, bold: true },
      //             {
      //               text: ` ${
      //                 data.salePoint.salepoint_code
      //                   ? data.salePoint.salepoint_code
      //                   : ""
      //               }\n`,
      //             },
      //           ],
      //           border: [true, true, false, true],
      //         },
      //         {
      //           text: [
      //             { text: `Nombre de fantasía:`, bold: true },
      //             {
      //               text: ` ${
      //                 data.salePoint.fantasy_name
      //                   ? data.salePoint.fantasy_name
      //                   : ""
      //               }\n`,
      //             },
      //             { text: `Calle:`, bold: true },
      //             {
      //               text: ` ${
      //                 data.salePoint.address ? data.salePoint.address : ""
      //               }\n`,
      //             },
      //             { text: `Altura:`, bold: true },
      //             {
      //               text: ` ${
      //                 data.salePoint.number ? data.salePoint.number : ""
      //               }\n`,
      //             },
      //             { text: `Código Postal:`, bold: true },
      //             {
      //               text: ` ${
      //                 data.salePoint.postal_code
      //                   ? data.salePoint.postal_code
      //                   : ""
      //               }\n`,
      //             },
      //             { text: `Localidad:`, bold: true },
      //             {
      //               text: ` ${
      //                 data.salePoint.locality ? data.salePoint.locality : ""
      //               }\n`,
      //             },
      //           ],
      //           border: [false, true, true, true],
      //         },
      //       ],
      //     ],
      //   },
      // },
      "\n",
      { text: "DETALLE", bold: true },
      {
        table: {
          widths: ["*", "*", "*", "*"],
          dontBreakRows: true,
          body: [
            [
              { text: `Codigo`, style: "tableHeader" },
              { text: "Descripcion", style: "tableHeader" },
              { text: "Cantidad", style: "tableHeader" },
              { text: "Valor unitario", style: "tableHeader" },
            ],
            ...formattedData2,
            [
              {
                text: [
                  { text: `observaciones:\n` },
                  { text: `xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx` },
                ],
                colSpan: 4,
              },
            ],
            [
              {
                columns: [
                  {
                    text: [{ text: `Son ............................. pesos` }],
                  },
                  {
                    text: [
                      { text: `SubTotal:`, bold: true },
                      { text: `00000 \n`, bold: true },
                      { text: `Importe No Gravado:`, bold: true },
                      { text: `xxxxxx:\n ` },
                      { text: `Importe Neto Gravado:`, bold: true },
                      { text: ` xxxxxxxx\n` },
                      { text: `I.V.A 21%: `, bold: true },
                      { text: ` xxxx-xxxx\n` },
                      { text: `I.V.A 10,5%: `, bold: true },
                      { text: ` xxxx-xxxx\n` },
                      { text: `Perc. IIBB BS AS 0,00%: `, bold: true },
                      { text: ` xxxx-xxxx\n` },
                      { text: `Perc. IIBB CABA 0,00%: `, bold: true },
                      { text: ` xxxx-xxxx\n` },
                      { text: `Importe Total: `, bold: true },
                      { text: `${data.total ? data.total : ""}` },
                    ],
                  },
                ],
                colSpan: 4,
                alignament: "right",
              },
            ],
          ],
        },
      },
      "\n",
      "\n",
      "\n",
    ],
    styles: getStyles(),
  };
  let title = `${data.name}`;
  if (isDownload) {
    downloadPdf(docDefinition, title);
  } else {
    openPdf(docDefinition);
  }
}
