import { basePath, apiVersion } from '../config';

/**FUNCIONES PARA MANEJO DEL MODULO BILLBUY QUE CONSUMEN LOS ENDPOINTS DEL SERVER*/

export function getBuyBillExportApi(token) {
    const url = `${basePath}/${apiVersion}/buybill-export-csv`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "text/csv",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.text();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'buyBill.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(err => {
            return err.message;
        });
}

export function postBuyBillImportApi(token, file) {
    const url = `${basePath}/${apiVersion}/buybill-import-csv`;
    const formData = new FormData();
    formData.append("file", file, file.name);

    const params = {
        method: "POST",
        body: formData,
        headers: {
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch(err => {
            return err.message;
        });
}

export function addBuyBillApi(token, data, userId){
    const url = `${basePath}/${apiVersion}/add-buybill/${userId}`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
    };

    return fetch(url, params)
        .then(response => {
            //console.log(response);
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return {
                ok: false,
                message: err.message
            };
        });
}

export function getBuyBillsApi(token) {
    const url = `${basePath}/${apiVersion}/buybills`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getBuyBillsActiveApi(token, status) {
    const url = `${basePath}/${apiVersion}/buybills-active?active=${status}`;
    //console.log(url);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getBuyBillsPaidApi(token, status) {
    const url = `${basePath}/${apiVersion}/buybills-paid?paid=${status}`;
    //console.log(url);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function updateBuyBillApi(token, buyBillData, buyBillId) {
    const url = `${basePath}/${apiVersion}/update-buybill/${buyBillId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(buyBillData)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function activateBuyBillApi(token, buyBillId, status) {
    const url = `${basePath}/${apiVersion}/activate-buybill/${buyBillId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            active: status
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result.message;
        })
        .catch(err => {
            return err.message;
        });
}

export function paidBuyBillApi(token, buyBillId, status) {
    const url = `${basePath}/${apiVersion}/paid-buybill/${buyBillId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            paid: status
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result.message;
        })
        .catch(err => {
            return err.message;
        });
}

export function paidStateBuyBillApi(token, buyBillId, state, paid) {
    const url = `${basePath}/${apiVersion}/paid-state-buybill/${buyBillId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            state: state,
            paid: paid
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result.message;
        })
        .catch(err => {
            return err.message;
        });
}

export function deleteBuyBillApi(token, buyBillId) {
    const url = `${basePath}/${apiVersion}/delete-buybill/${buyBillId}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result.message;
        })
        .catch(err => {
            return err.message;
        });
}

export function itemExistInBuyBillsApi(token,idItem) {
    const url = `${basePath}/${apiVersion}/buybills-exist-item/${idItem}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getBuyBillsSupplierApi(token, idSupplier) {
    const url = `${basePath}/${apiVersion}/buybills-supplier/${idSupplier}`;
    //console.log(url);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}