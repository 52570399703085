//import { responsiveArray } from 'antd/lib/_util/responsiveObserve';
//import { Result } from 'antd';
import { switchPath, apiVersion } from './config';

//console.log(switchPath())

//FUNCIONES DEL MODULO USUARIO QUE CONSUMEN LOS ENDPOINTS DEL SERVER

export function getUserExportApi(token) {
    const url = `${switchPath()}/${apiVersion}/user-export-csv`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "text/csv",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.text();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'user.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(err => {
            return err.message;
        });
}

export function postUserImportApi(token, file) {
    const url = `${switchPath()}/${apiVersion}/user-import-csv`;
    const formData = new FormData();
    formData.append("file", file, file.name);

    const params = {
        method: "POST",
        body: formData,
        headers: {
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch(err => {
            return err.message;
        });
}

//REGISTRO DE USER
export function signUpApi(data) {
    const url = `${switchPath()}/${apiVersion}/sign-up`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json"
        }
    };
    //console.log(data);

    return fetch(url, params)
        .then(response => {
            //console.log(response);
            return response.json();
        })
        .then(result => {
            if (result.user) {
                return {
                    ok: true,
                    message: "¡Usuario creado!"
                };
            }
            return {
                ok: false,
                message: result.message
            };
        })
        .catch(err => {
            return {
                ok: false,
                message: err.message
            };
        });
}

export function verifyEmailApi(data) {
    const url = `${switchPath()}/${apiVersion}/verify-email`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json"
        }
    };
    //console.log(data);

    return fetch(url, params)
        .then(response => {
            //console.log("api response");
            //console.log(response);
            return response.json();
        })
        .then(result => {
            //console.log("api result");
            //console.log(result);
            if (result.message === "e-mail encontrado") {
                return {
                    ok: true,
                    message: "¡e-mail encontrado!"
                };
            }
            return {
                ok: false,
                message: result.message
            };
        }) 
        .catch(err => {
            return {
                ok: false,
                message: err.message
            };
        });
}

export function sendEmailApi(data){
    const url = `${switchPath()}/${apiVersion}/send-email`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json"
        }
    };
    //console.log(data);

    return fetch(url, params)
        .then(response => {
            //console.log(response);
            return response.json();
        })
        .then(result => {
            if (result.message === "email de recuperación enviado") {
                return {
                    ok: true,
                    //message: "¡Email enviado!"
                    message: result.message
                };
            }
            return {
                ok: false,
                message: result.message
            };
        })
        .catch(err => {
            return {
                ok: false,
                message: err.message
            };
        });
}

export function getUserByResetTokenApi(resetToken) {
    const url = `${switchPath()}/${apiVersion}/user-by-reset-token/${resetToken}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        }
    };

    return fetch(url, params)
        .then(response => {
            //return res.redirect('/login');
            //res.render('/reset', {
            //    user: req.user
            //});
            return response.json();
        })
        .then(result => {
            //console.log("result: ", result);
            return result;
        })
        .catch(err => {
            //console.log(err);
            return err.message;
        });
}

export function getUserByIdApi(token, userId) {
    const url = `${switchPath()}/${apiVersion}/user-by-id/${userId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getUsersByRoleApi(token, roleId) {
    const url = `${switchPath()}/${apiVersion}/users-role/${roleId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

//LOGIN
export function signInApi(data){
    const url = `${switchPath()}/${apiVersion}/sign-in`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json"
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getUsersApi(token) {
    const url = `${switchPath()}/${apiVersion}/users`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getUsersActiveApi(token, status) {
    const url = `${switchPath()}/${apiVersion}/users-active?active=${status}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function uploadUserAvatarApi(token, avatar, userId) {
    const url = `${switchPath()}/${apiVersion}/upload-avatar-user/${userId}`;
    const formData = new FormData();
    formData.append("avatar", avatar, avatar.name);
    console.log(formData);
    const params = {
        method: "PUT",
        body: formData,
        headers: {
            Authorization: token
        }
    }
    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getUserAvatarApi(avatarName) {
    const url = `${switchPath()}/${apiVersion}/get-avatar-user/${avatarName}`;
    return fetch(url)
    .then(response => {
        return response.url;
    })
    .catch(err => {
        return err.message;
    });
}

export function updateUserApi(token, user, userId) {
    const url = `${switchPath()}/${apiVersion}/update-user/${userId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(user)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function updatePasswordByTokenApi(user, resetToken) {
    const url = `${switchPath()}/${apiVersion}/update-password-by-token/${resetToken}`;
    const params = {
        method: "PUT",
        body: JSON.stringify(user),
        headers: {
            "Content-Type": "application/json"
        }
    };
    //console.log("params: ", params)

    return fetch(url, params)
    .then(response => {
        //console.log("responseAPI: ", response);
        return response.json();
    })
    .then(result => {
        //console.log("resultAPI: ", result);
        return result;
    })
    .catch(err => {
        //console.log("errAPI: ", err);
        return err;
    });
}

export function activateUserApi(token, userId, status) {
    const url = `${switchPath()}/${apiVersion}/activate-user/${userId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            active: status
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result.message;
        })
        .catch(err => {
            return err.message;
        });
}

export function deleteUserApi(token, userId) {
    const url = `${switchPath()}/${apiVersion}/delete-user/${userId}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result.message;
        })
        .catch(err => {
            return err.message;
        });
}

export function signUpAdminApi(token, data) {
    const url = `${switchPath()}/${apiVersion}/sign-up-admin`;

    const params = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(data)
    }

    return fetch(url, params)
        .then(response => {
            return response.json()
        })
        .then(result => {
            return result.message;
        })
        .catch(err => {
            return err.message;
        });

}