import React, { useState, useEffect } from "react";
import { Form, Input, Select, Button, Row, Col, notification } from "antd";
import {
  UserOutlined,
  MailOutlined,
  EnvironmentOutlined,
  ContactsOutlined,
  DollarCircleOutlined,
  IdcardOutlined,
  PhoneOutlined,
  ScheduleOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { addSupplierApi, addSupplierApi2 } from "../../../../api/billing/supplier";
import { getAccessToken } from "../../../../api/auth";
import { getPersonsActiveApi } from "../../../../api/person";
import { getSupplierTypesActiveApi } from "../../../../api/billing/supplierType";
import { getIvaConditionsActiveApi } from "../../../../api/billing/ivaCondition";

import "./AddSupplierForm.scss";

export default function AddSupplierForm(props) {
  const { setIsVisibleModal, setReloadSuppliers } = props;

  const [listPersonsActive, setListPersonsActive] = useState([]);
  const [listSupplierTypesActive, setListSupplierTypesActive] = useState([]);

  useEffect(() => {
    const accessToken = getAccessToken();
    getPersonsActiveApi(accessToken, true).then((response) => {
      setListPersonsActive(response.persons);
    });
  }, []);

  useEffect(() => {
    const accessToken = getAccessToken();
    getSupplierTypesActiveApi(accessToken, true).then((response) => {
      setListSupplierTypesActive(response.supplierTypes);
    });
  }, []);

  const [inputs, setInputs] = useState({
    person: "",
    denomination: "",
    taxPosition: "",
  });

  const isFormValid = (e) => {
    let errorExists = false;
    if (!inputs.person || !inputs.denomination) {
      notification["error"]({
        message: "Obligatorios: persona y denominacion",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addSupplier = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      const accessToken = getAccessToken();

      await addSupplierApi(accessToken, inputs)
        .then((response) => {
          // if(response.message === "ERR_CONNECTION_REFUSED" || response === "Failed to fetch" || response === undefined) {
          //     notification["error"]({
          //         message: "Servidor caido"
          //     });
          // } else if (response.message === "El proveedor ya existe") {
          //     notification["error"]({
          //         message: "El proveedor ya existe."
          //     });
          if (
            response.message === "ERR_CONNECTION_REFUSED" ||
            response.message === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (!response.supplier) {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              //message: response
              message: "Proveedor creado",
            });
            setIsVisibleModal(false);
          }
          console.log(response);
          setReloadSuppliers(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  return (
    <div className="add-supplier-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        addSupplier={addSupplier}
        listSupplierTypesActive={listSupplierTypesActive}
        listPersonsActive={listPersonsActive}
      />
    </div>
  );
}

function AddForm(props) {
  const {
    form,
    inputs,
    setInputs,
    addSupplier,
    listPersonsActive,
    listSupplierTypesActive,
  } = props;
  const { Option } = Select;

  return (
    <Form className="form-add" onSubmit={addSupplier}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
            <span className="control-required">*</span>Persona
          </label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona una persona"
              value={inputs.person}
              onChange={(e) => setInputs({ ...inputs, person: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listPersonsActive
                ? listPersonsActive.map((person) => {
                    return (
                      <Option key={person._id}>
                        {person.name + " " + person.lastname}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
            <span className="control-required">*</span> Denominacion
          </label>
          <Form.Item>
            <Input
              // prefix={<EnvironmentOutlined />}
              placeholder="Denominacion"
              value={inputs.denomination}
              onChange={(e) =>
                setInputs({ ...inputs, denomination: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">Posicion de impuestos</label>
          <Form.Item>
            <Input
              name="taxPosition"
              placeholder="Posicion de impuestos"
              onChange={(e) =>
                setInputs({ ...inputs, taxPosition: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addSupplier}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
