import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  BackTop
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined,
  EyeInvisibleOutlined,
  EyeOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditVariationForm from "../EditVariationForm";
import AddVariationForm from "../AddVariationForm";
import { activateVariationApi, deleteVariationApi, availableVariationApi } from "../../../../api/variation";
import { availableVariationBranchMenuByBranchApi, deleteVariationBranchMenuAllBranchesApi,deleteVariationBranchMenuByBranchApi } from "../../../../api/branchMenu";
import { getItemsByVariationApi } from "../../../../api/item";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListVariations.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListBranchVariantions(props) {
  const {
    variationsAvailable,
    variationsNotAvailable,
    setReloadVariations,
    branch
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewVariationsAvailables, setViewVariationsAvailables] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [variationsAvailableFilter, setVariationsAvailableFilter] = useState(
    []
  );
  const [ variationsNotAvailableFilter, setVariationsNotAvailableFilter ] = useState([]);

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    const list= [];
    if (variationsAvailable) {
      variationsAvailable.forEach((element) => {
        if (
          element.name
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
          element.variation_code.toString()
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            list.push(element);
          }
      });
    }
    setVariationsAvailableFilter(list);
  }, [filterText, variationsAvailable]);

  useEffect(() => {
    const list = [];
    if (variationsNotAvailable) {
      variationsNotAvailable.forEach((element) => {
        if (
            element.name
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
          element.variation_code.toString()
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            list.push(element);
          }
      });
    }
    setVariationsNotAvailableFilter(list);
  }, [filterText, variationsNotAvailable]);

  const addVariationModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Variacion");
    setModalContent(
      <AddVariationForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadVariations={setReloadVariations}
        branch={branch}
      />
    );
  };

  return (
    <div className="list-variations">
      <div className="list-variations__header">
        {checkAction(permissionsActive, userToken.role, "variations", "all") ||
        checkAction(permissionsActive, userToken.role, "variations", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-variations__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewVariationsAvailables(!viewVariationsAvailables)
                }
              />
              <span>
                {viewVariationsAvailables
                  ? "Disponibles"
                  : "No disponibles"}
              </span>
            </div>
          </>
          ) : (
            <>
            </>
        )}

        {checkAction(permissionsActive, userToken.role, "variations", "all") ||
        checkAction(permissionsActive, userToken.role, "variations", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addVariationModal}>
              Nueva Variación
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "variations", "all") ||
      checkAction(permissionsActive, userToken.role, "variations", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-variations__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <h2>

            </h2>
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewVariationsAvailables ? (
        <>
          {checkAction(permissionsActive, userToken.role, "variations", "all") ||
          checkAction(permissionsActive, userToken.role, "variations", "view") || isAdmin(roleById) ? (
            <>
              <VariationsAvailable
                variationsAvailable={variationsAvailableFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadVariations={setReloadVariations}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                branch={branch}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "variations", "all") ||
          checkAction(permissionsActive, userToken.role, "variations", "view") || isAdmin(roleById) ? (
            <>
              <VariationsNotAvailable
                variationsNotAvailable={variationsNotAvailableFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadVariations={setReloadVariations}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                branch={branch}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>

      <BackTop/>
    </div>
  );
}

function VariationsAvailable(props) {
  const {
    variationsAvailable,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadVariations,
    accessToken,
    userToken,
    permissionsActive,
    roleById,
    branch
  } = props;

  const editVariation = (variation) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${variation.name ? variation.name : "sin nombre"}`
    );
    setModalContent(
      <EditVariationForm
        variation={variation}
        setIsVisibleModal={setIsVisibleModal}
        setReloadVariations={setReloadVariations}
      />
    );
  };

  const notAvailableVariation = (variation) => {
    availableVariationBranchMenuByBranchApi(accessToken, branch,variation._id, false)
    //availableVariationApi(accessToken, variation._id, false)
      .then((response) => {
        notification["success"]({
          message:"La variacion no se encuentra disponible",
        });
        setReloadVariations(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (variation) => {
    let result = await getItemsByVariationApi(accessToken, variation._id);
    let response=result.items? result.items.length > 0? false : true : false;
    //let response = true;
    if (response === false) {
      confirm({
        title: "Eliminando -> Variacion",
        content: `No se puede eliminar '${variation.name}' porque existen productos asociados ¿Deseas desactivarlo?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          // desactivateAdviser(adviser);
        },
      });
    } else {
      confirm({
        title: "Eliminando -> Variacion",
        content: `¿Estás seguro que quieres eliminar a ${variation.name}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          //Luego elimino la categoria de todos los branch menu para eliminar definitivamente
          deleteVariationBranchMenuByBranchApi(accessToken,branch,variation._id)
          .then((response) => {
            if (response.code === 200) {
              return true;
            } else if (response.code === 404 || response.code === 500) {
              // notification["error"]({
              //   message: response.message,
              // });
              return true;
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
              return false;
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
              return false;
            }
          })
          .then(async (result)=>{
            if(result===true){
              //Si no es general lo elimino directamente
              if(!variation.isGeneral){
                let response= await deleteVariationApi(accessToken, variation._id);
                if (response.code === 200) {
                  notification["success"]({
                    message: response.message,
                  });
                  setReloadVariations(true);
                } else if (response.code === 404 || response.code === 500) {
                  notification["error"]({
                    message: response.message,
                  });
                } else if (
                  response === "ERR_CONNECTION_REFUSED" ||
                  response === "Failed to fetch" ||
                  response === undefined
                ) {
                  notification["error"]({
                    message: "Servidor caido",
                  });
                } else {
                  notification["error"]({
                    message: "Error del servidor",
                  });
                }
              }else{
                notification["success"]({
                  message: "La variacion ha sido eliminada de la sucursal",
                });
                setReloadVariations(true);
              }
            }else{
              notification["error"]({
                message: "Error al eliminar la variacion",
              });
            }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
          })
        },
      });
    }
  };

  const edit_variation= (
    <div>
      {" "}
      <p>Editar Variacion</p>{" "}
    </div>
  );

  const not_available_variation = (
    <div>
      {" "}
      <p>Pasar a Variacion no disponibles</p>{" "}
    </div>
  );

  const delete_variation = (
    <div>
      {" "}
      <p>Eliminar Variacion</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
    },
    {
      title: "Precio",
      dataIndex: "price",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, variation) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "variations", "all") ||
          checkAction(permissionsActive, userToken.role, "variations", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_variation}>
                <Button
                  className="button-edit"
                  onClick={() => editVariation(variation)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "variations", "all") ||
          checkAction(permissionsActive, userToken.role, "variations", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={not_available_variation}>
                <Button
                  className="button-typecourse"
                  type="primary"
                  onClick={() => notAvailableVariation(variation)}
                >
                  {/* <StopOutlined /> */}
                  <EyeInvisibleOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "variations", "all") ||
          checkAction(permissionsActive, userToken.role, "variations", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_variation}>
                <Button
                  className="button-variation"
                  type="danger"
                  onClick={() => showDeleteConfirm(variation)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={variationsAvailable}
      columns={columns}
      rowKey="_id"
    />
  );
}

function VariationsNotAvailable(props) {
  const { variationsNotAvailable,
          setReloadVariations,
          accessToken,
          userToken,
          permissionsActive,
          roleById,
          branch
        } = props;

  const availableVariation = (variation) => {
    availableVariationBranchMenuByBranchApi(accessToken, branch,variation._id, true)
    //availableVariationApi(accessToken, variation._id, true)
      .then((response) => {
        notification["success"]({
          message: "La variacion se encuentra disponible",
        });
        setReloadVariations(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (variation) => {
    let result = await getItemsByVariationApi(accessToken, variation._id);
    let response=result.items? result.items.length > 0? false : true : false;
    //let response = true;
    if (response === false) {
      confirm({
        title: "Eliminando -> Variacion",
        content: `No se puede eliminar '${variation.name}' porque existen productos asociados ¿Deseas desactivarlo?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          // desactivateAdviser(adviser);
        },
      });
    } else {
      confirm({
        title: "Eliminando -> Variacion",
        content: `¿Estás seguro que quieres eliminar a ${variation.name}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          //Luego elimino la categoria del branch menu para eliminar definitivamente en caso de que sea solo crerado por el branch
          deleteVariationBranchMenuByBranchApi(accessToken,branch, variation._id)
          .then((response) => {
            if (response.code === 200) {
              // notification["success"]({
              //   message: response.message,
              // });
              return true;
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
              return false;
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
              return false;
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
              return false;
            }
          })
          .then(async (result)=>{
            if(result===true){
              //Si no es general lo elimino directamente
              if(!variation.isGeneral){
                let response= await deleteVariationApi(accessToken, variation._id);
                if (response.code === 200) {
                  notification["success"]({
                    message: response.message,
                  });
                  setReloadVariations(true);
                } else if (response.code === 404 || response.code === 500) {
                  notification["error"]({
                    message: response.message,
                  });
                } else if (
                  response === "ERR_CONNECTION_REFUSED" ||
                  response === "Failed to fetch" ||
                  response === undefined
                ) {
                  notification["error"]({
                    message: "Servidor caido",
                  });
                } else {
                  notification["error"]({
                    message: "Error del servidor",
                  });
                }
              }else{
                notification["success"]({
                  message: "La variacion ha sido eliminada",
                });
                setReloadVariations(true);
              }
            }else{
              notification["error"]({
                message: "Error al eliminar la variacion",
              });
            }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
          })
        },
      });
    }
  };

  const available_variation = (
    <div>
      {" "}
      <p>Pasar a Variacion disponibles</p>{" "}
    </div>
  );

  const delete_variation = (
    <div>
      {" "}
      <p>Eliminar variacion</p>
    </div>
  );

  const columns = [
    // {
    //   title: "Código",
    //   dataIndex: "typecourse_code",
    // },
    {
      title: "Nombre",
      dataIndex: "name",
    },
    {
      title: "Precio",
      dataIndex: "price",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, variation) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "variations", "all") ||
          checkAction(permissionsActive, userToken.role, "variations", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={available_variation}>
                <Button
                  className="button-activate"
                  onClick={() => availableVariation(variation)}
                >
                  <EyeOutlined />
                  {/* <CheckOutlined /> */}
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "variations", "all") ||
          checkAction(permissionsActive, userToken.role, "variations", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_variation}>
                <Button
                  className="button-typecourse"
                  type="danger"
                  onClick={() => showDeleteConfirm(variation)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return variationsNotAvailable.length > 0? `Total de registros: ${variationsNotAvailable.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={variationsNotAvailable}
      columns={columns}
      rowKey="_id"
    />
  );
}
