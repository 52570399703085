import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getStockInventoriesApi } from "../../../api/billing/stockInventory";
import ListStockInventory from "../../../components/Admin/StockInventory/ListStockInventory";


export default function StockInventory() {
    const [stockInventories, setStockInventories] = useState([]);
    const [reloadStockInventory, setReloadStockInventory ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getStockInventoriesApi(token, true).then(response => {
            setStockInventories(response.stockInventories);
        });
        setReloadStockInventory(false);
    }, [token, reloadStockInventory]);

    return (
        <div className="items-inventory">

            <ListStockInventory stockInventories={stockInventories} setReloadStockInventory={setReloadStockInventory} 
                />
        </div>
    );
}