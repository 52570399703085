import React, { useState, useEffect } from "react";
import {
  Switch,
  List,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Space,
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  DownloadOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditSupplierForm from "../EditSupplierForm";
import AddSupplierForm from "../AddSupplierForm";
import {
  activateSupplierApi,
  deleteSupplierApi,
} from "../../../../api/billing/supplier";
import { listSuppliersPdf, supplierDetailPdf } from "./pdfSuppliers";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListSuppliers.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListSuppliers(props) {
  const { suppliersActive, suppliersInactive, setReloadSuppliers } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewSuppliersActives, setViewSuppliersActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [modalWidth, setModalWidth] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [suppliersActiveFilter, setSuppliersActiveFilter] = useState([]);
  const [suppliersInactiveFilter, setSuppliersInactiveFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState("name");

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, [roleById]);

  useEffect(() => {
    const listActive = [];
    if (suppliersActive) {
      suppliersActive.forEach((element) => {
        if (typeFilter === "name") {
          if (
            (element.person.name && element.person.name.toUpperCase().indexOf(filterText.toUpperCase()) !==
              -1) ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        } else if (typeFilter === "documentNumber") {
          if (
            (element.person.documentNumber && element.person.documentNumber.toString().indexOf(filterText) !== -1 )||
            filterText === ""
          ) {
            listActive.push(element);
          }

        } else if (typeFilter === "cuit_cuil") {
          if (
            (element.person.cuit_cuil && element.person.cuit_cuil.toString().indexOf(filterText) !== -1 )||
            filterText === ""
          ) {
            listActive.push(element);
          }
        }
      });
    }
    setSuppliersActiveFilter(listActive);
  }, [filterText, suppliersActive]);

  useEffect(() => {
    const listInactive = [];
    if (suppliersInactive) {
      suppliersInactive.forEach((element) => {
        if (typeFilter === "name") {
          if (
            (element.person.name && element.person.name.toUpperCase().indexOf(filterText.toUpperCase()) !==
              -1) ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        } else if (typeFilter === "documentNumber") {
          if (
            (element.person.documentNumber && element.person.documentNumber.toString().indexOf(filterText) !== -1) ||
            filterText === ""
          ) {
            listInactive.push(element);
          }

        } else if (typeFilter === "cuit_cuil") {
          if (
            (element.person.cuit_cuil && element.person.cuit_cuil.toString().indexOf(filterText) !== -1) ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        }
      });
    }
    setSuppliersInactiveFilter(listInactive);
  }, [filterText, suppliersInactive]);

  const addSupplierModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear -> Proveedor");
    setModalWidth(800);
    setModalContent(
      <AddSupplierForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadSuppliers={setReloadSuppliers}
      />
    );
  };

  return (
    <div className="list-suppliers">
      <div className="list-suppliers__header">
        {checkAction(permissionsActive, userToken.role, "suppliers", "all") ||
        checkAction(permissionsActive, userToken.role, "suppliers", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-suppliers__header-switch">
              <Switch
                defaultChecked
                onChange={() => setViewSuppliersActives(!viewSuppliersActives)}
              />
              <span>
                {viewSuppliersActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
          <>
          </>
        )}

        {checkAction(permissionsActive, userToken.role, "suppliers", "all") ||
        checkAction(permissionsActive, userToken.role, "suppliers", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addSupplierModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "suppliers", "all") ||
      checkAction(permissionsActive, userToken.role, "suppliers", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-suppliers__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <Radio.Group
              options={[
                { label: "Nombre", value: "name" },
                { label: "Número de Documento", value: "documentNumber" },
                { label: "CUIL/CUIT", value: "cuit_cuil" },
              ]}
              onChange={(e) => {
                setTypeFilter(e.target.value);
              }}
              value={typeFilter}
            />
          </div>
        </>
        ) : (
          <>
          </>
      )}

      {viewSuppliersActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "suppliers", "all") ||
          checkAction(permissionsActive, userToken.role, "suppliers", "pdf") || isAdmin(roleById) ? (
            <>
              <Space align="center" style={{ marginBottom: 16 }}>
                <Button
                  type="default"
                  shape="round"
                  onClick={() => {
                    listSuppliersPdf(suppliersActiveFilter, false);
                  }}
                >
                  <DownloadOutlined /> Listado Proveedores
                </Button>
              </Space>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "suppliers", "all") ||
          checkAction(permissionsActive, userToken.role, "suppliers", "view") || isAdmin(roleById) ? (
            <>
              <SuppliersActive
                suppliersActive={suppliersActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadSuppliers={setReloadSuppliers}
                setModalWidth={setModalWidth}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "suppliers", "all") ||
          checkAction(permissionsActive, userToken.role, "suppliers", "view") || isAdmin(roleById) ? (
            <>
              <SuppliersInactive
                suppliersInactive={suppliersInactiveFilter}
                setReloadSuppliers={setReloadSuppliers}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        modalWidth={modalWidth}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function SuppliersActive(props) {
  const {
    suppliersActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadSuppliers,
    setModalWidth,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editSupplier = (supplier) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar proveedor ${supplier.person.name ? supplier.denomination : "sin nombre"}`);
    setModalWidth(900);
    setModalContent(
      <EditSupplierForm
        supplier={supplier}
        setIsVisibleModal={setIsVisibleModal}
        setReloadSuppliers={setReloadSuppliers}
      />
    );
  };


  return (
    <List
      className="suppliers-active"
      itemLayout="horizontal"
      dataSource={suppliersActive}
      renderItem={(supplier) => (
        <SupplierActive
          supplier={supplier}
          editSupplier={editSupplier}
          setReloadSuppliers={setReloadSuppliers}
          accessToken={accessToken}
          userToken={userToken}
          permissionsActive={permissionsActive}
          roleById={roleById}
        />
      )} 
    />
  );
}

function SupplierActive(props) {
  const {
          supplier,
          editSupplier,
          setReloadSuppliers,
          userToken,
          permissionsActive,
          roleById
        } = props; //showDeleteConfirm
  

  const desactivateSupplier = () => {
    const accessToken = getAccessToken();

    activateSupplierApi(accessToken, supplier._id, false)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setReloadSuppliers(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = () => {
    const accessToken = getAccessToken();

    confirm({
      title: "Eliminando -> Proveedor",
      content: `¿Estás seguro que quieres eliminar a ${supplier.denomination ? supplier.person.name : "proveedor"}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteSupplierApi(accessToken, supplier._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadSuppliers(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const edit_supplier = (
    <div> <p>Editar proveedor</p> </div>
  );

  const desactivate_supplier = (
    <div> <p>Pasar a proveedores inactivos</p> </div>
  );

  const delete_supplier = (
    <div> <p>Eliminar proveedor</p> </div>
  );

  const download_supplier = (
    <div>
      {" "}
      <p>Descargar detalle proveedor</p>{" "}
    </div>
  );

  return (
    <List.Item
      actions={[
        <div>
          {checkAction(permissionsActive, userToken.role, "suppliers", "all") ||
          checkAction(permissionsActive, userToken.role, "suppliers", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_supplier} >
                <Button type="default" onClick={() => editSupplier(supplier)}>
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "suppliers", "all") ||
          checkAction(permissionsActive, userToken.role, "suppliers", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_supplier} >
                <Button type="primary" onClick={desactivateSupplier}>
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "suppliers", "all") ||
          checkAction(permissionsActive, userToken.role, "suppliers", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_supplier} >
                <Button type="danger" onClick={showDeleteConfirm}>
                  <DeleteOutlined />
                </Button>
              </Popover>,
            </>
          ) : (
            <>
            </>
          )}
        </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "suppliers", "all") ||
          checkAction(permissionsActive, userToken.role, "suppliers", "pdf") || isAdmin(roleById) ? (
            <>
              <Popover content={download_supplier}>
                <Button
                  type="primary"
                  onClick={() => {
                    supplierDetailPdf(supplier, false);
                  }}
                >
                  <DownloadOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
      ]}
    >
      <List.Item.Meta
        title={`
                    ${supplier.person ? supplier.person.name + " " + supplier.person.lastname: "..."}
                `}
        description={supplier.denomination}
      />
    </List.Item>
  );
}

function SuppliersInactive(props) {
  const { suppliersInactive, setReloadSuppliers, accessToken, userToken, permissionsActive, roleById } = props; //showDeleteConfirm
  return (
    <List
      className="suppliers-active"
      itemLayout="horizontal"
      dataSource={suppliersInactive}
      renderItem={(supplier) => (
        <SupplierInactive
          supplier={supplier}
          setReloadSuppliers={setReloadSuppliers}
          accessToken={accessToken}
          userToken={userToken}
          permissionsActive={permissionsActive}
          roleById={roleById}
        />
      )}
    />
  );
}

function SupplierInactive(props) {
  const { supplier, setReloadSuppliers, userToken, permissionsActive, roleById } = props; //showDeleteConfirm

  const activateSupplier = () => {
    const accessToken = getAccessToken();

    activateSupplierApi(accessToken, supplier._id, true)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setReloadSuppliers(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = () => {
    const accessToken = getAccessToken();

    confirm({
      title: "Eliminando -> Proveedor",
      content: `¿Estás seguro que quieres eliminar a ${supplier.denomination ? supplier.person.name : "proveedor"}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteSupplierApi(accessToken, supplier._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadSuppliers(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const activate_supplier = (
    <div> <p>Pasar a proveedores activos</p> </div>
  );

  const delete_supplier = (
    <div> <p>Eliminar proveedor</p> </div>
  );

  return (
    <List.Item
      actions={[
        <div>
          {checkAction(permissionsActive, userToken.role, "suppliers", "all") ||
          checkAction(permissionsActive, userToken.role, "suppliers", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_supplier} >
                <Button type="primary" onClick={activateSupplier}>
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
        <div>
          {checkAction(permissionsActive, userToken.role, "suppliers", "all") ||
          checkAction(permissionsActive, userToken.role, "suppliers", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_supplier} >
                <Button type="danger" onClick={showDeleteConfirm}>
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>,
      ]}
    >
      <List.Item.Meta
        title={`
                ${supplier.person ? supplier.person.name + " " + supplier.person.lastname : "..."}
            `}
        description={supplier.denomination}
      />
    </List.Item>
  );
}
