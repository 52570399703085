import React, { useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import {
  TagOutlined,
} from "@ant-design/icons";
import { addBillSystemApi } from "../../../../api/billing/billSystem";
import { getAccessToken } from "../../../../api/auth";

import "./AddBillSystemForm.scss";

export default function AddBillSystemForm(props) {
  const { setIsVisibleModal, setReloadBillSystems } = props;

  const [inputs, setInputs] = useState({
    billsystem_code: "",
    description: "",
  });

  const isFormValid = (e) => {
    //console.log(inputs);
    let errorExists = false;
    if (!inputs.description) {
      notification["error"]({
        message: "Obligatorios: descripción.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addBillSystem= async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      const accessToken = getAccessToken();

      await addBillSystemApi(accessToken, inputs)
        .then((response) => {
          if (response === "ERR_CONNECTION_REFUSED" ||response === "Failed to fetch" ||response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          }
          else {
            notification["success"]({
              message: "Sistema de facturación creado",
            });
            setIsVisibleModal(false);
          }
          setReloadBillSystems(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  return (
    <div className="add-billsystem-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        //inputValidation={inputValidation}
        addBillSystem={addBillSystem}
      />
    </div>
  );
}

function AddForm(props) {
  const { inputs, setInputs, addBillSystem } = props;

  return (
    <Form className="form-add" onSubmit={addBillSystem}>
      <Row gutter={24}>
        <Col span={12}>
          <label className="control-label">Código</label>
          <Form.Item rules={[{required:true}]} >
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={inputs.billsystem_code}
              onChange={(e) =>
                setInputs({ ...inputs, billsystem_code: e })
              }
              min={1}
              disabled={true}
              style={{ width: "90%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label"><span className="control-required">*</span> Descripción</label>
          <Form.Item >
            <Input
              placeholder="Descripción"
              value={inputs.description}
              onChange={(e) =>
                setInputs({ ...inputs, description: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addBillSystem}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
