import { switchPath, apiVersion } from './config';


// ROL

export function getRoleExportApi(token) {
    const url = `${switchPath()}/${apiVersion}/role-export-csv`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "text/csv",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.text();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'role.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(err => {
            return err.message;
        });
}

export function postRoleImportApi(token, file) {
    const url = `${switchPath()}/${apiVersion}/role-import-csv`;
    const formData = new FormData();
    formData.append("file", file, file.name);

    const params = {
        method: "POST",
        body: formData,
        headers: {
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch(err => {
            return err.message;
        });
}

export function addRoleApi(token, data) {
    const url = `${switchPath()}/${apiVersion}/add-role`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
    };

    return fetch(url, params)
        .then(response => {
            //console.log(response);
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return {
                ok: false,
                message: err.message
            };
        });
}

export function getRoleByIdApi(token, roleId) {
    const url = `${switchPath()}/${apiVersion}/role-by-id/${roleId}`;
    //console.log(roleId);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getRolesApi(token) {
    const url = `${switchPath()}/${apiVersion}/roles`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getRolesActiveApi(token, status) {
    const url = `${switchPath()}/${apiVersion}/roles-active?active=${status}`;
    //console.log(url);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function updateRoleApi(token, roleData, roleId) {
    const url = `${switchPath()}/${apiVersion}/update-role/${roleId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(roleData)
    }

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function activateRoleApi(token, roleId, status) {
    const url = `${switchPath()}/${apiVersion}/activate-role/${roleId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            active: status
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function deleteRoleApi(token, roleId) {
    const url = `${switchPath()}/${apiVersion}/delete-role/${roleId}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });
}