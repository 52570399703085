import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getSupplierTypesActiveApi } from "../../../api/billing/supplierType";
import ListSupplierTypes from "../../../components/Billing/SupplierTypes/ListSupplierTypes";


export default function SupplierTypes() {
    const [supplierTypesActive, setSupplierTypesActive] = useState([]);
    const [supplierTypesInactive, setSupplierTypesInactive] = useState([]);
    const [reloadSupplierTypes, setReloadSupplierTypes ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getSupplierTypesActiveApi(token, true).then(response => {
            setSupplierTypesActive(response.supplierTypes);
        });
        getSupplierTypesActiveApi(token, false).then(response => {
            setSupplierTypesInactive(response.supplierTypes);
        });
        setReloadSupplierTypes(false);
    }, [token, reloadSupplierTypes]);

    return (
        <div className="supplierTypes">

            <ListSupplierTypes supplierTypesActive={supplierTypesActive} supplierTypesInactive={supplierTypesInactive} setReloadSupplierTypes={setReloadSupplierTypes} 
                />

        </div>
    );
}