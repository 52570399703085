import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Layout, Menu } from "antd";
import {
  TeamOutlined,
  //UserOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  ExportOutlined,
  ProfileOutlined,
  ImportOutlined,
  TagsOutlined,
  SwapOutlined,
  OrderedListOutlined,
  ContainerOutlined,
  SkinOutlined,
  GlobalOutlined,
  FileTextOutlined,
  AuditOutlined,
  ShopOutlined,
} from "@ant-design/icons"; //MenuOutlined, IdcardOutlined, ContactsOutlined,

//import { getMenuApi } from "../../../api/menu";
//import menu from "../../../utils/menu.json";
import { getAccessToken } from "../../../api/auth";
import { getRoleByIdApi } from "../../../api/role";
import { getPermissionsActiveApi } from "../../../api/permission";
import { getBranchesApi } from "../../../api/branch";
import {
  checkAction,
  isAdmin,
  //checkUserPermission,
  //checkUserPermission2,
  //checkPermission,
} from "../../../utils/checkUserPermission";
import jwtDecode from "jwt-decode";
import { menuIcons } from "../../../utils/icons";

import "./MenuSider.scss";
import { getBusinessApi, getBusinessesActiveApi } from "../../../api/business";
import { getBranchesActiveApi } from "../../../api/branch";
import { useBusiness } from "../../../hooks/useBusiness";

const { SubMenu } = Menu;

function MenuSider(props) {
  const {
    menuCollapsed,
    location,
    //user,
    menu,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  //console.log(props);
  //console.log(location.pathname);
  const { Sider } = Layout;
  // const [menuData, setMenuData] = useState([]);
  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado
  const [listBusinesses, setListBusinesses] = useState([]);
  const [listBusinessBranches, setListBusinessBranches] = useState([]);
  const [data, setData] = useState({ negocios: [], sucursales: {} });
  const [loading, setLoading] = useState(true);
  //const [reloadListStore, setReloadListStore ] = useState(false);

  //console.log("userToken.role", userToken.role);

  const {business}=useBusiness();

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then((response) => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then((response) => {
      setRoleById(response.role);
    });
  }, []);

  // useEffect(() => {
  //   getBranchesApi(accessToken).then((response) => {
  //     setListBusinesses(response.branches);
  //   });
  // }, []);

  useEffect(() => {
    getBusinessesActiveApi(accessToken, true).then((response) => {
      setListBusinesses(response.businesses);
    });
  }, []);

  useEffect(() => {
    getBranchesActiveApi(accessToken, true).then((response) => {
      //setListBusinesses(response.branches);
      // Organizar sucursales por negocio
      const sucursalesMap = {};
      if (response.branches) {
        response.branches.forEach((branch) => {
          if(branch.business){
            if (!sucursalesMap[branch.business._id]) {
              sucursalesMap[branch.business._id] = [];
            }
            sucursalesMap[branch.business._id].push(branch);
          }
        });
        setData({ listBusinesses, branches: sucursalesMap });
      }
    });
  }, [listBusinesses]);

  useEffect(() => {
    getBusinessesActiveApi(accessToken, true).then((response) => {
      setListBusinesses(response.businesses);
    });
  }, []);

  return (
    <Sider width={240} collapsed={menuCollapsed} className="layout-init">
      <Menu
        theme="light"
        mode="inline"
        breakpoint="lg"
        inlineIndent={15}
        defaultSelectedKeys={[location.pathname]}
        style={{
          width: menuCollapsed ? "5%" : "auto",
          position: "fixed",
          zIndex: 1,
        }}
        className="admin-sider"
      >
        {menu
          ? menu.map((item, i) => {
              if (item.active) {
                if (item.childs.length > 0) {
                  return checkAction(
                    permissionsActive,
                    userToken.role,
                    item.nameModule,
                    "all"
                  ) ||
                    checkAction(
                      permissionsActive,
                      userToken.role,
                      item.nameModule,
                      "menu-sider"
                    ) ||
                    isAdmin(roleById) ? (
                    <SubMenu
                      title={item.title}
                      icon={
                        menuIcons[item.nameModule] ? (
                          menuIcons[item.nameModule]
                        ) : (
                          <UnorderedListOutlined />
                        )
                      }
                    >
                      {item.childs
                        .sort((a, b) => a.order - b.order)
                        .map(function (subMenu, i) {
                          return checkAction(
                            permissionsActive,
                            userToken.role,
                            subMenu.nameModule,
                            "all"
                          ) ||
                            checkAction(
                              permissionsActive,
                              userToken.role,
                              subMenu.nameModule,
                              "menu-sider"
                            ) ||
                            isAdmin(roleById) ? (
                            <Menu.Item
                              key={subMenu.title}
                              className="menu-top-web__item"
                            >
                              <Link to={subMenu.url}>
                                {menuIcons[subMenu.nameModule] ? (
                                  menuIcons[subMenu.nameModule]
                                ) : (
                                  <ProfileOutlined />
                                )}
                                <span className="nac-text">
                                  {subMenu.title}
                                </span>
                              </Link>
                            </Menu.Item>
                          ) : null;
                        })}
                    </SubMenu>
                  ) : null;
                } else {
                  return checkAction(
                    permissionsActive,
                    userToken.role,
                    item.nameModule,
                    "all"
                  ) ||
                    checkAction(
                      permissionsActive,
                      userToken.role,
                      item.nameModule,
                      "menu-sider"
                    ) ||
                    isAdmin(roleById) ? (
                    <Menu.Item key={item.title} className="menu-top-web__item">
                      <Link to={item.url}>
                        {menuIcons[item.nameModule] ? (
                          menuIcons[item.nameModule]
                        ) : (
                          <ProfileOutlined />
                        )}
                        <span className="nac-text">{item.title}</span>
                      </Link>
                    </Menu.Item>
                  ) : null;
                }
              }
              return true;
            })
          : null}

        {/*
        {checkUserPermission(user.role, "route.admin") ? (
          <Menu.Item key="/admin">
            <Link to={"/admin"}>
              <HomeOutlined />
              <span className="nav-text">Home</span>
            </Link>
          </Menu.Item>
        ) : null}
        */}
        {checkAction(permissionsActive, userToken.role, "users", "all") ||
        checkAction(permissionsActive, userToken.role, "users", "menu-sider") ||
        isAdmin(roleById) ? (
          //checkPermission("component.users", permissionsActive, userToken.role, "users", "menu-sider") ? (
          //&& checkAction(permissionsActive, userToken.role, "users", "menu-sider")) || isAdmin(roleById)
          <Menu.Item key="/admin/users">
            <Link to={"/admin/users"}>
              <TeamOutlined />
              <span className="nac-text">{"Usuarios"}</span>
            </Link>
          </Menu.Item>
        ) : null}

        {/* MENU DIGITAL */}
        {business && business!==0 && business!=="0"? checkAction(
          permissionsActive,
          userToken.role,
          "roles", //cambiar por el nombre correcto
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "permissions", //cambiar por el nombre correcto
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "config-menu", //cambiar por el nombre correcto
          "menu-sider"
        ) ||
        isAdmin(roleById) 
        ? (
          <SubMenu
            key="/admin/digitalmenu/#"
            title={
              <>
                {" "}
                <SettingOutlined />{" "}
                <span className="nac-text">Menu Digital</span>
              </>
            }
          >
            {/* MI PAGINA DE PRODUCTOS */}
            {checkAction(
              permissionsActive,
              userToken.role,
              "roles", //cambiar por el nombre correcto
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "roles",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/digitalmenu/items">
                <Link to={"/admin/digitalmenu/items"}>
                  <ProfileOutlined />
                  <span className="nac-text">Mi pagina de productos</span>
                </Link>
              </Menu.Item>
            ) : null}
            {/* CIERRA PRODUCTOS */}

            {/* Categorias */}
            {checkAction(
              permissionsActive,
              userToken.role,
              "categories",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "categories",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/digitalmenu/categories">
                <Link to={"/admin/digitalmenu/categories"}>
                  <ProfileOutlined />
                  <span className="nac-text">Categorias</span>
                </Link>
              </Menu.Item>
            ) : null}
            {/* CIERRA categorias */}

            {/* Variacion */}
            {checkAction(
              permissionsActive,
              userToken.role,
              "variations",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "variations",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/digitalmenu/variations">
                <Link to={"/admin/digitalmenu/variations"}>
                  <ProfileOutlined />
                  <span className="nac-text">Variacion</span>
                </Link>
              </Menu.Item>
            ) : null}
            {/* CIERRA VARIACIONES */}
          </SubMenu>
        ) : null : null}
        {/* CIERRA MENU DIGITAL */}

        {/* CONFIGURACIÓN */}
        {checkAction(permissionsActive, userToken.role, "roles", "all") ||
        checkAction(permissionsActive, userToken.role, "permissions", "all") ||
        checkAction(permissionsActive, userToken.role, "days", "all") ||
        checkAction(permissionsActive, userToken.role, "weeks", "all") ||
        checkAction(
          permissionsActive,
          userToken.role,
          "config-menu",
          "menu-sider"
        ) ||
        isAdmin(roleById) ? (
          <SubMenu
            key="/admin/config/#"
            title={
              <>
                {" "}
                <SettingOutlined />{" "}
                <span className="nac-text">Configuración</span>
              </>
            }
          >
            {/* ROLES */}
            {checkAction(permissionsActive, userToken.role, "roles", "all") ||
            checkAction(
              permissionsActive,
              userToken.role,
              "roles",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/config/roles">
                <Link to={"/admin/config/roles"}>
                  <ProfileOutlined />
                  <span className="nac-text">Roles</span>
                </Link>
              </Menu.Item>
            ) : null}
            {/* CIERRA ROLES */}

            {/* PERMISOS */}
            {checkAction(
              permissionsActive,
              userToken.role,
              "permissions",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "permissions",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/config/permissions">
                <Link to={"/admin/config/permissions"}>
                  <ProfileOutlined />
                  <span className="nac-text">Permisos</span>
                </Link>
              </Menu.Item>
            ) : null}
            {/* CIERRA PERMISOS */}

            {/* DIAS */}
            {checkAction(permissionsActive, userToken.role, "days", "all") ||
            checkAction(
              permissionsActive,
              userToken.role,
              "days",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/config/days">
                <Link to={"/admin/config/days"}>
                  <ProfileOutlined />
                  <span className="nac-text">Días</span>
                </Link>
              </Menu.Item>
            ) : null}
            {/* CIERRA DIAS */}

            {/* NEGOCIO */}
            {checkAction(permissionsActive, userToken.role, "days", "all") ||
            checkAction(
              permissionsActive,
              userToken.role,
              "days",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <SubMenu
                key="/admin/config/businesses"
                title={
                  <>
                    {" "}
                    <GlobalOutlined />{" "}
                    <span className="nac-text">Mis negocios</span>
                  </>
                }
              >
                {/* Negocio */}
                {checkAction(
                  permissionsActive,
                  userToken.role,
                  "days",
                  "all"
                ) ||
                checkAction(
                  permissionsActive,
                  userToken.role,
                  "days",
                  "menu-sider"
                ) ||
                isAdmin(roleById) ? (
                  <>
                    {listBusinesses
                      //.sort((a, b) => a.order - b.order)
                      .map(function (business, i) {
                        return checkAction(
                          permissionsActive,
                          userToken.role,
                          "days",
                          "all"
                        ) ||
                          checkAction(
                            permissionsActive,
                            userToken.role,
                            "days",
                            "menu-sider"
                          ) ||
                          isAdmin(roleById) ? (
                          <SubMenu
                            key={`/admin/config/business/${business._id}`}
                            title={
                              <>
                                {" "}
                                <GlobalOutlined />{" "}
                                <span className="nac-text">
                                  {business.name}
                                </span>
                              </>
                            }
                          >
                            {data.branches &&
                            data.branches[business._id] &&
                            data.branches[business._id].length > 0 ? (
                              data.branches[business._id].map((branch) =>
                                !branch.name && branch.isMain ? (
                                  <Menu.Item key={branch._id}>
                                    <Link
                                      to={{
                                        pathname: `/admin/config/business/${branch.business.name}`,
                                        state: { id: branch.business._id },
                                      }}
                                    >
                                      <span className="nac-text">
                                        {"Principal"}
                                      </span>
                                    </Link>
                                  </Menu.Item>
                                ) : (
                                  <Menu.Item key={branch._id}>
                                    <Link
                                      to={{
                                        pathname: `/admin/config/branch/${branch.name}`,
                                        state: { id: branch._id },
                                      }}
                                    >
                                      <span className="nac-text">
                                      <ShopOutlined />
                                        {branch.name}
                                      </span>
                                    </Link>
                                  </Menu.Item>
                                )
                              )
                            ) : (
                              <Menu.Item disabled>No hay sucursales</Menu.Item>
                            )}

                            {checkAction(
                              permissionsActive,
                              userToken.role,
                              "roles",
                              "all"
                            ) ||
                            checkAction(
                              permissionsActive,
                              userToken.role,
                              "roles",
                              "menu-sider"
                            ) ||
                            isAdmin(roleById) ? (
                              <Menu.Item
                                key={`/admin/config/business-branches/${business._id}`}
                              >
                                <Link
                                  to={{
                                    pathname: `/admin/config/business-branches/${business.name}`,
                                    state: { id: business._id },
                                  }}
                                >
                                    <UnorderedListOutlined />
                                  <span className="nac-text">
                                   Mis Sucursales
                                  </span>
                                </Link>
                              </Menu.Item>
                            ) : null}
                          </SubMenu>
                        ) : null;
                      })}
                  </>
                ) : null}

                {checkAction(
                  permissionsActive,
                  userToken.role,
                  "roles",
                  "all"
                ) ||
                checkAction(
                  permissionsActive,
                  userToken.role,
                  "roles",
                  "menu-sider"
                ) ||
                isAdmin(roleById) ? (
                  <Menu.Item key="/admin/config/businesses">
                    <Link to={"/admin/config/businesses"}>
                    <UnorderedListOutlined />
                      <span className="nac-text">Mis Negocios</span>
                    </Link>
                  </Menu.Item>
                ) : null}
                {/* Nuevo negocio */}
                {/* {checkAction(
                  permissionsActive,
                  userToken.role,
                  "roles",
                  "all"
                ) ||
                checkAction(
                  permissionsActive,
                  userToken.role,
                  "roles",
                  "menu-sider"
                ) ||
                isAdmin(roleById) ? (
                  <Menu.Item key="/admin/config/business">
                    <Link to={"/admin/config/business"}>
                      <PlusOutlined />
                      <span className="nac-text"> Nuevo Negocio</span>
                    </Link>
                  </Menu.Item>
                ) : null} */}
              </SubMenu>
            ) : null}

            {/* CIERRA NEGOCIO */}

            {/* NEGOCIO */}

            {/* CIERRA NEGOCIO */}

            {/* SEMANAS
            {checkAction(
              permissionsActive,
              userToken.role,
              "weeks", "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "weeks",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/config/weeks">
                <Link to={"/admin/config/weeks"}>
                  <ProfileOutlined />
                  <span className="nac-text">Semanas</span>
                </Link>
              </Menu.Item>
            ) : null}
            CIERRA SEMANAS 
            */}
          </SubMenu>
        ) : null}
        {/* CIERRA CONFIGURACION */}

        {/* REPORTES
        {checkAction(
          permissionsActive,
          userToken.role,
          "roles",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "permissions",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "days",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "weeks",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "config-menu",
          "menu-sider"
        ) || isAdmin(roleById) ? (
          <SubMenu
            key="/admin/reports/#"
            title={
              <>
                {" "}
                <TagsOutlined /> <span>Reportes</span>
              </>
            }
          >
            DOCENTES
            {checkAction(
              permissionsActive,
              userToken.role,
              "teachers",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "teachers",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/reports/teachers">
                <Link to={"/admin/reports/teachers"}>
                  <ProfileOutlined />
                  <span className="nac-text">Docentes</span>
                </Link>
              </Menu.Item>
            ) : null}
            CIERRA DOCENTES

            COLABORADORES
            {checkAction(
              permissionsActive,
              userToken.role,
              "permissions",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "permissions",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/reports/teachercollabs">
                <Link to={"/admin/reports/teachercollabs"}>
                  <ProfileOutlined />
                  <span className="nac-text">Colaboradores</span>
                </Link>
              </Menu.Item>
            ) : null}
            CIERRA COLABORADORES

            ESTUDIANTES
            {checkAction(
              permissionsActive,
              userToken.role,
              "students",
              "all"
              ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "students",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/reports/students">
                <Link to={"/admin/reports/students"}>
                  <ProfileOutlined />
                  <span className="nac-text">Estudiantes</span>
                </Link>
              </Menu.Item>
            ) : null}
            CIERRA ESTUDIANTES
          </SubMenu>
        ) : null}
        CIERRA REPORTES 
        */}

        {/* EXPORTAR */}
        {checkAction(
          permissionsActive,
          userToken.role,
          "exports",
          "menu-sider"
        ) || isAdmin(roleById) ? (
          <Menu.Item key="/admin/exports">
            <Link to={"/admin/exports"}>
              <ExportOutlined />
              <span className="nac-text">Exportar</span>
            </Link>
          </Menu.Item>
        ) : null}
        {/* CIERRA EXPORTAR */}

        {/* IMPORTAR */}
        {checkAction(
          permissionsActive,
          userToken.role,
          "imports",
          "menu-sider"
        ) || isAdmin(roleById) ? (
          <Menu.Item key="/admin/imports">
            <Link to={"/admin/imports"}>
              <ImportOutlined />
              <span className="nac-text">Importar</span>
            </Link>
          </Menu.Item>
        ) : null}
        {/* CIERRA IMPORTAR */}

        {/* EDITAR MENÚ */}
        {checkAction(
          permissionsActive,
          userToken.role,
          "editmenues",
          "menu-sider"
        ) || isAdmin(roleById) ? (
          <Menu.Item key="/admin/editmenu">
            <Link to={"/admin/editmenu"}>
              <SettingOutlined />
              <span className="nac-text">Editar menu</span>
            </Link>
          </Menu.Item>
        ) : null}
        {/* CIERRA EDITAR MENÚ */}

{/* MENU FACTURACION */}
{checkAction(
          permissionsActive,
          userToken.role,
          "roles", //cambiar por el nombre correcto
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "permissions", //cambiar por el nombre correcto
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "days", //cambiar por el nombre correcto
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "weeks", //cambiar por el nombre correcto
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "config-menu", //cambiar por el nombre correcto
          "menu-sider"
        ) ||
        isAdmin(roleById) ? (
          <SubMenu
            key="/admin/billing/#"
            title={
              <>
                {" "}
                <SettingOutlined />{" "}
                <span className="nac-text">Facturacion</span>
              </>
            }
          >
            {/* PERSONAS */}
            {checkAction(
              permissionsActive,
              userToken.role,
              "persons", //cambiar por el nombre correcto
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "persons",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/billing/persons">
                <Link to={"/admin/billing/persons"}>
                  <ProfileOutlined />
                  <span className="nac-text">Personas</span>
                </Link>
              </Menu.Item>
            ) : null}
            {/* CIERRA PERSONA */}

            {/* Clientes */}
            {checkAction(
              permissionsActive,
              userToken.role,
              "clients",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "clients",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/billing/clients">
                <Link to={"/admin/billing/clients"}>
                  <ProfileOutlined />
                  <span className="nac-text">Clientes</span>
                </Link>
              </Menu.Item>
            ) : null}
            {/* CIERRA Clientes */}

            {checkAction(
          permissionsActive,
          userToken.role,
          "suppliers-menu",
          "menu-sider"
        ) || isAdmin(roleById) ? (
          <SubMenu
            key="/admin/billing/suppliers/#"
            title={
              <>
                {" "}
                <ImportOutlined /> <span>Proveedores</span>
              </>
            }
          >
            {checkAction(
              permissionsActive,
              userToken.role,
              "suppliers",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "suppliers",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/billing/suppliers">
                <Link to={"/admin/billing/suppliers"}>
                  <ImportOutlined />
                  <span className="nac-text">Listado</span>
                </Link>
              </Menu.Item>
            ) : null}
            {checkAction(
              permissionsActive,
              userToken.role,
              "suppliertypes",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "suppliertypes",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/billing/config/supplierTypes">
                <Link to={"/admin/billing/config/supplierTypes"}>
                  <ProfileOutlined />
                  <span className="nac-text">Tipo de proveedor</span>
                </Link>
              </Menu.Item>
            ) : null}
          </SubMenu>
        ) : null}

        {checkAction(
          permissionsActive,
          userToken.role,
          "buy-menu",
          "menu-sider"
        ) || isAdmin(roleById) ? (
          <SubMenu
            key="/admin/buy/#"
            title={
              <>
                {" "}
                <UnorderedListOutlined /> <span>Compra</span>
              </>
            }
          >
            {checkAction(
              permissionsActive,
              userToken.role,
              "buybills",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "buybills",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/billing/buyBills">
                <Link to={"/admin/billing/buyBills"}>
                  <FileTextOutlined />
                  <span className="nac-text">Facturas de Compra</span>
                </Link>
              </Menu.Item>
            ) : null}
             {checkAction(
              permissionsActive,
              userToken.role,
              "buybills",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "buybills",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/billing/buyOrders">
                <Link to={"/admin/billing/buyOrders"}>
                  <FileTextOutlined />
                  <span className="nac-text">Ordenes de Compra</span>
                </Link>
              </Menu.Item>
            ) : null}


            <SubMenu
              key="/admin/billing/buy/config/#"
              title={
                <>
                  {" "}
                  <UnorderedListOutlined /> <span>Configuración</span>
                </>
              }
            >
              {checkAction(
                permissionsActive,
                userToken.role,
                "deliveryterms",
                "all"
              ) ||
              checkAction(
                permissionsActive,
                userToken.role,
                "deliveryterms",
                "menu-sider"
              ) ||
              isAdmin(roleById) ? (
                <Menu.Item key="/admin/billing/config/deliveryTerms">
                  <Link to={"/admin/billing/config/deliveryTerms"}>
                    <ProfileOutlined />
                    <span className="nac-text">Plazo de entrega</span>
                  </Link>
                </Menu.Item>
              ) : null}
            {checkAction(
              permissionsActive,
              userToken.role,
              "statebuyorders",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "statebuyorders",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/billing/config/stateBuyOrders">
                <Link to={"/admin/billing/config/stateBuyOrders"}>
                  <ProfileOutlined />
                  <span className="nac-text">Estado de Órdenes de Compra</span>
                </Link>
              </Menu.Item>
            ) : null}
            {/* condicion de compra */}
            {checkAction(
              permissionsActive,
              userToken.role,
              "concepts",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "concepts",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/billing/concepts">
                <Link to={"/admin/billing/concepts"}>
                  <AuditOutlined />
                  <span className="nac-text">Conceptos</span>
                </Link>
              </Menu.Item>
            ) : null}
            {checkAction(
              permissionsActive,
              userToken.role,
              "statebills",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "statebills",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/billing/stateBills">
                <Link to={"/admin/billing/stateBills"}>
                  <AuditOutlined />
                  <span className="nac-text">Estado de facturas</span>
                </Link>
              </Menu.Item>
            ) : null}
            {checkAction(
              permissionsActive,
              userToken.role,
              "billtypes",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "billtypes",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/billing/config/billTypes">
                <Link to={"/admin/billing/config/billTypes"}>
                  <AuditOutlined />
                  <span className="nac-text">Tipo de facturas</span>
                </Link>
              </Menu.Item>
            ) : null}
            </SubMenu>

          </SubMenu>
        ) : null}

        {checkAction(
          permissionsActive,
          userToken.role,
          "sell-menu",
          "menu-sider"
        ) || isAdmin(roleById) ? (
          <SubMenu
            key="/admin/billing/sell/#"
            title={
              <>
                {" "}
                <UnorderedListOutlined /> <span>Venta</span>
              </>
            }
          >
            {checkAction(
              permissionsActive,
              userToken.role,
              "billsells",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "billsells",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/billing/admin/billSells">
                <Link to={"/admin/billing/billSells"}>
                  <AuditOutlined />
                  <span className="nac-text">Facturas de venta</span>
                </Link>
              </Menu.Item>
            ) : null}
            {checkAction(
              permissionsActive,
              userToken.role,
              "statebills",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "statebills",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/billing/stateBills">
                <Link to={"/admin/billing/stateBills"}>
                  <AuditOutlined />
                  <span className="nac-text">Estado de facturas</span>
                </Link>
              </Menu.Item>
            ) : null}
            {checkAction(
              permissionsActive,
              userToken.role,
              "billtypes",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "billtypes",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/billing/billTypes">
                <Link to={"/admin/billing/billTypes"}>
                  <AuditOutlined />
                  <span className="nac-text">Tipo de facturas</span>
                </Link>
              </Menu.Item>
            ) : null}
            {checkAction(
              permissionsActive,
              userToken.role,
              "saleconditions",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "saleconditions",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/billing/config/saleConditions">
                <Link to={"/admin/billing/config/saleConditions"}>
                  <AuditOutlined />
                  <span className="nac-text">Condición de venta</span>
                </Link>
              </Menu.Item>
            ) : null}
          </SubMenu>
        ) : null}

        {checkAction(
          permissionsActive,
          userToken.role,
          "payments-menu",
          "menu-sider"
        ) || isAdmin(roleById) ? (
          <SubMenu
            key="/admin/billing/payment/#"
            title={
              <>
                {" "}
                <UnorderedListOutlined /> <span>Pagos/cobros</span>
              </>
            }
          >
            {checkAction(
              permissionsActive,
              userToken.role,
              "paymentorders",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "paymentorders",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/billing/paymentOrders">
                <Link to={"/admin/billing/paymentOrders"}>
                  <AuditOutlined />
                  <span className="nac-text">Orden de pago</span>
                </Link>
              </Menu.Item>
            ) : null}

            {checkAction(
              permissionsActive,
              userToken.role,
              "paymenttypes",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "paymenttypes",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/billing/config/paymentTypes">
                <Link to={"/admin/billing/config/paymentTypes"}>
                  <FileTextOutlined />
                  <span className="nac-text">Tipos de cobro</span>
                </Link>
              </Menu.Item>
            ) : null}
          </SubMenu>
        ) : null}

        {checkAction(
          permissionsActive,
          userToken.role,
          "stock-menu",
          "menu-sider"
        ) || isAdmin(roleById) ? (
          <SubMenu
            key="/admin/billing/items/#"
            title={
              <>
                {" "}
                <SkinOutlined /> <span>Stock</span>
              </>
            }
          >
           {checkAction(permissionsActive, userToken.role, "items", "all") ||
            checkAction(
              permissionsActive,
              userToken.role,
              "items",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/billing/items">
                <Link to={"/admin/billing/items"}>
                  <ContainerOutlined />
                  <span className="nac-text">Productos</span>
                </Link>
              </Menu.Item>
            ) : null}
            {checkAction(
              permissionsActive,
              userToken.role,
              "categories",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "categories",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/billing/items/categories">
                <Link to={"/admin/billing/items/categories"}>
                  <TagsOutlined />
                  <span className="nac-text">Rubros</span>
                </Link>
              </Menu.Item>
            ) : null}
            {checkAction(
              permissionsActive,
              userToken.role,
              "stockinventories",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "stockinventories",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/billing/items/inventory-stock">
                <Link to={"/admin/billing/items/inventory-stock"}>
                  <OrderedListOutlined />
                  <span className="nac-text">Inventario</span>
                </Link>
              </Menu.Item>
            ) : null}
            {checkAction(
              permissionsActive,
              userToken.role,
              "movements",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "movements",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/billing/items/inventory-movements">
                <Link to={"/admin/billing/items/inventory-movements"}>
                  <SwapOutlined />
                  <span className="nac-text">Movimientos</span>
                </Link>
              </Menu.Item>
            ) : null}
          </SubMenu>
        ) : null}

        {checkAction(
          permissionsActive,
          userToken.role,
          "config-menu",
          "menu-sider"
        ) || isAdmin(roleById) ? (
          <SubMenu
            key="/admin/config/#"
            title={
              <>
                {" "}
                <SettingOutlined /> <span>Configuración</span>
              </>
            }
          >
            {checkAction(
              permissionsActive,
              userToken.role,
              "documenttypes",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "documenttypes",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/billing/config/documentType">
                <Link to={"/admin/billing/config/documentType"}>
                  <ProfileOutlined />
                  <span className="nac-text">Tipo de documento</span>
                </Link>
              </Menu.Item>
            ) : null}
            {checkAction(
              permissionsActive,
              userToken.role,
              "ivaconditions",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "ivaconditions",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/billing/config/ivaConditions">
                <Link to={"/admin/billing/config/ivaConditions"}>
                  <ProfileOutlined />
                  <span className="nac-text">Condición IVA</span>
                </Link>
              </Menu.Item>
            ) : null}
            {checkAction(
              permissionsActive,
              userToken.role,
              "salepoints",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "salepoints",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/billing/config/salePoints">
                <Link to={"/admin/billing/config/salePoints"}>
                  <ProfileOutlined />
                  <span className="nac-text">Puntos de venta</span>
                </Link>
              </Menu.Item>
            ) : null}
            {checkAction(
              permissionsActive,
              userToken.role,
              "billsystems",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "billsystems",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/billing/config/billSystems">
                <Link to={"/admin/billing/config/billSystems"}>
                  <ProfileOutlined />
                  <span className="nac-text">Sistema de facturación</span>
                </Link>
              </Menu.Item>
            ) : null}

          </SubMenu>
        ) : null}

          </SubMenu>
        ) : null}
        {/* CIERRA FACTURACION */}


      </Menu>

      

     
    </Sider>
  );
}

export default withRouter(MenuSider);
