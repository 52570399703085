import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  notification,
  Divider,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import {
  TagOutlined,
  UserOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { updateBuyBillApi } from "../../../../api/billing/buyBill";
import { getSuppliersActiveApi } from "../../../../api/billing/supplier";
import { getSalePointsByUserApi } from "../../../../api/billing/salePoint";
import { getBuyOrdersActiveApi, getBuyOrdersActiveByStateApi } from "../../../../api/billing/buyOrder";
import { getStateBuyOrderByCodeApi } from "../../../../api/billing/stateBuyOrder";
import { getItemsActiveApi } from "../../../../api/item";
import { getBillTypesActiveApi } from "../../../../api/billing/billType";
import { getAccessToken } from "../../../../api/auth";
import { formatDateCalendar , formatDateView} from "../../../../utils/formatDate";
import { COINS } from "../../../../utils/constants";
import {
  updateAddStockInventoryApi,
  updateSubStockInventoryApi,
  getStockInventoriesApi,
} from "../../../../api/billing/stockInventory";
import { addMovementApi } from "../../../../api/billing/movement";
import moment from "moment";
import jwtDecode from "jwt-decode";

import "./EditBuyBillForm.scss";
import TextArea from "antd/lib/input/TextArea";
const { Text } = Typography;

export default function EditBuyBillForm(props) {
  const { buyBill, setIsVisibleModal, setReloadBuyBills } = props;
  const [buyBillData, setBuyBillData] = useState({
    items: [],
  });
  const [listStockInventory, setListStockInventory] = useState([]);
  const [activeSuppliers, setActiveSuppliers] = useState([]);
  const [activeUserSalePoints, setActiveUserSalePoints] = useState([]);
  const [activeItems, setActiveItems] = useState([]);
  const [activeBuyOrders, setActiveBuyOrders] = useState([]);
  const [activeBillTypes, setActiveBillTypes] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);

  const accessToken = getAccessToken();
  const metaToken = jwtDecode(accessToken);

  const [updateItems, setUpdateItems] = useState(false);

  useEffect(() => {
    getSuppliersActiveApi(accessToken, true).then((response) => {
      setActiveSuppliers(response.suppliers);
    });
  }, []);

  useEffect(() => {
    getStateBuyOrderByCodeApi(accessToken, 1).then((response) => {
      if(response.stateBuyOrder){
        getBuyOrdersActiveByStateApi(accessToken, true, response.stateBuyOrder._id).then((response) => {
          setActiveBuyOrders(response.buyOrders);
        });
      }
  });
  }, []);

  useEffect(() => {
    getSalePointsByUserApi(accessToken, metaToken._id).then((response) => {
      setActiveUserSalePoints(response.salePoints);
    });
  }, [buyBill]);

  useEffect(() => {
    getItemsActiveApi(accessToken, true).then((response) => {
      setActiveItems(response.items);
    });
  }, []);

  useEffect(() => {
    getBillTypesActiveApi(accessToken, true).then((response) => {
      setActiveBillTypes(response.billTypes);
    });
  }, []);

  useEffect(() => {
    getStockInventoriesApi(accessToken).then((response) => {
      setListStockInventory(response.stockInventories);
    });
  }, []);

  useEffect(() => {
    
    let dataBuyOrders = [];
    buyBill.buyOrders.map((i) => {
      dataBuyOrders.push(i._id);
    });

    setBuyBillData({
      code: buyBill.code,
      billType: buyBill.billType,
      items: buyBill.items,
      total: buyBill.total,
      date: formatDateCalendar(buyBill.date),
      expirationDate: buyBill.expirationDate
        ? formatDateCalendar(buyBill.expirationDate)
        : null,
      periodFrom: buyBill.periodFrom
        ? formatDateCalendar(buyBill.periodFrom)
        : null,
      periodoUntil: buyBill.periodUntil
        ? formatDateCalendar(buyBill.periodoUntil)
        : null,
      inputDate: formatDateCalendar(buyBill.inputDate),
      supplier: buyBill.supplier._id,
      coin: buyBill.coin,
      quotation: buyBill.quotation,
      salePoint: buyBill.salePoint._id,
      _item: buyBill._item,
      //paid: buyBill.paid,
      active: buyBill.active,
      // discount: buyBill.discount ? buyBill.discount : 0,
      quantity: 1,
      buyOrders: dataBuyOrders
    });
  
  }, [buyBill]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (
      !buyBillData.code ||
      !buyBillData.billType ||
      !buyBillData.date ||
      !buyBillData.inputDate ||
      !buyBillData.supplier ||
      !buyBillData.salePoint
    ) {
      notification["error"]({
        message:
          "Obligatorios: Codigo, Tipo de comprobante, Proveedor, Fecha, Fecha de ingreso y Punto de venta.",
      });
      errorExists = true;
    }
    if (!buyBillData.items.length > 0) {
      notification["error"]({
        message: "Obligatorios: debe seleccionar al menos un producto.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const updateBuyBill = async (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      // setButtonLoading(true);

      const token = getAccessToken();
      buyBillData.supplier = buyBillData.supplier._id;
      let buyBillUpdate = buyBillData;

      await updateBuyBillApi(token, buyBillUpdate, buyBill._id).then(
        (result) => {
          if (
            result.message === "ERR_CONNECTION_REFUSED" ||
            result.message === "Failed to fetch" ||
            result.message === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
            setIsVisibleModal(true);
          } else if (result.code !== 200) {
            notification["error"]({
              message: result.message,
            });
            setIsVisibleModal(true);
          } else {
            notification["success"]({
              message: result.message, //el mensaje que viene del server
            });
            //console.log("respuesta: " + result.message);
            setIsVisibleModal(false);
            setUpdateItems(true);
            setReloadBuyBills(true);
          }
        }
      );
    }
  };

  const addStock = (accessToken, inventoryId, data, item) => {
    updateAddStockInventoryApi(accessToken, inventoryId, data)
      .then((response) => {
        if (
          response.message === "ERR_CONNECTION_REFUSED" ||
          response.message === "Failed to fetch" ||
          response === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          return false;
        } else if (response.code !== 200) {
          notification["error"]({
            message: response.message,
          });
          return false;
        } else {
          // notification["success"]({
          //   message: "Stock actualizado",
          // });
          let movementData = {
            date: formatDateCalendar(moment()),
            movement_type: "input",
            item: item._item,
            origin: "buyBill",
            buyBill: buyBill._id,
            quantity: data.quantity,
            user: jwtDecode(accessToken).id,
            observation: "comprobante modificado",
          };
          return addMovementApi(accessToken, movementData);
        }
      })
      .then((response) => {
        if (response) {
          if (
            response.message === "ERR_CONNECTION_REFUSED" ||
            response.message === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          } else {
            // notification["success"]({
            //   message: "Movimiento creado",
            // });
          }
        }
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const subStock = (accessToken, inventoryId, data, item) => {
    updateSubStockInventoryApi(accessToken, inventoryId, data)
      .then((response) => {
        if (
          response.message === "ERR_CONNECTION_REFUSED" ||
          response.message === "Failed to fetch" ||
          response === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          return false;
        } else if (response.code !== 200) {
          notification["error"]({
            message: response.message,
          });
          return false;
        } else {
          // notification["success"]({
          //   message: "Stock actualizado",
          // });
          let movementData = {
            date: formatDateCalendar(moment()),
            movement_type: "output",
            item: item._item,
            origin: "buyBill",
            buyBill: buyBill._id,
            quantity: data.quantity,
            user: jwtDecode(accessToken).id,
            observation: "comprobante modificado",
          };
          return addMovementApi(accessToken, movementData);
        }
      })
      .then((response) => {
        if (response) {
          if (
            response.message === "ERR_CONNECTION_REFUSED" ||
            response.message === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          } else {
            // notification["success"]({
            //   message: "Movimiento creado",
            // });
          }
        }
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const checkForDeleted = () => {
    buyBill.items.map((item) => {
      //busco si item que se elimino
      let itemDelete = buyBillData.items.find((i) => i._id === item._id);
      if (!itemDelete) {
        //verifico que tenga inventario
        let inventory = listStockInventory.find(
          (i) => i.item._id === item._item._id || i.item._id === item._item._id
        );
        if (inventory) {
          let data = {
            quantity: item.quantity,
          };
          subStock(accessToken, inventory._id, data, item);
        }
      }
      return true;
    });
  };

  useEffect(() => {
    //verifico que el estado sea vigente
    //if (buyBillData.state === "Vigente") {
    //verifico que exista actualizacion en los items
    if (updateItems) {
      //Verifico si existen items que han sido eliminados del detalle
      checkForDeleted();
      //Verifico el nuevo detalle de items
      buyBillData.items.map((item) => {
        //primero verifico si tiene inventario
        let inventory = listStockInventory.find(
          (i) => i.item._id === item._item || i.item._id === item._item._id
        );

        if (inventory) {
          //verifico que este en la lista anterior de items sino lo agrego
          let exits = buyBill.items.find(
            (i) => i._item._id === item._item || i._item === item._item
          );
          if (exits) {
            //console.log("busco valor anterior");
            let preValue = buyBill.items.find(
              (i) => i._item._id === item._item || i._item === item._item
            );
            //si tiene valor anterior
            if (preValue) {
              let difference = 0;
              if (preValue.quantity !== item.quantity) {
                difference = preValue.quantity - item.quantity;
                console.log(difference);
                if (difference !== 0) {
                  let data = {
                    quantity: Math.abs(difference),
                  };

                  if (difference <= -1) {
                    addStock(accessToken, inventory._id, data, item);
                  } else if (difference >= 1) {
                    subStock(accessToken, inventory._id, data, item);
                  }
                }
              }
            }
          } else {
            // console.log("es nuevo");
            let data = {
              quantity: item.quantity,
            };
            addStock(accessToken, inventory._id, data, item);
          }
        }
        return true;
      });
    }
  }, [updateItems]);

  return (
    <div className="edit-buybill-form">
      <EditForm
        buyBillData={buyBillData}
        setBuyBillData={setBuyBillData}
        updateBuyBill={updateBuyBill}
        activeSuppliers={activeSuppliers}
        activeItems={activeItems}
        activeBuyOrders={activeBuyOrders}
        activeBillTypes={activeBillTypes}
        activeUserSalePoints={activeUserSalePoints}
        accessToken={accessToken}
        buttonLoading={buttonLoading}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    buyBillData,
    setBuyBillData,
    updateBuyBill,
    activeSuppliers,
    activeItems,
    activeBuyOrders,
    activeBillTypes,
    activeUserSalePoints,
    accessToken,
    buttonLoading
  } = props;

  const { Option } = Select;
  const [optionsActiveBuyOrders, setOptionsActiveBuyOrders] = useState([]);

  // useEffect(() => {
  //    setOptionsActiveBuyOrders(activeBuyOrders);
  //  }, [activeBuyOrders]);

  //Actualizar items de acuerdo a las ordenes de compra seleccionadas
  useEffect(() => {
    if (buyBillData.buyOrders) {
      if (buyBillData.buyOrders.length > 0) {
        let updateItems = [];
        buyBillData.buyOrders.map((buyorder) => {
          let order = activeBuyOrders.find((i) => i._id === buyorder);
          if (order) {
            updateItems = updateItems.concat(order.items);
            buyBillData.supplier? setBuyBillData({
              ...buyBillData,
              items: updateItems,
            }) : setBuyBillData({
              ...buyBillData,
              supplier: order.supplier._id,
              items: updateItems,
            });
          }
          return true;
        });
      } else {
        setBuyBillData({ ...buyBillData, items: [] , supplier: null});
        setOptionsActiveBuyOrders(activeBuyOrders);
      }
    }
  }, [buyBillData.buyOrders]);

  //Filtrar lista por Proveedor
  useEffect(() => {
    if(activeBuyOrders && buyBillData.supplier){
      setOptionsActiveBuyOrders(
        activeBuyOrders.filter((i) => i.supplier._id === buyBillData.supplier)
      );
    //   // setInputs({ ...inputs, buyOrders: [] });
    }
  }, [buyBillData.supplier, activeBuyOrders]);

  const setListItems = (items) => {
    setBuyBillData({ ...buyBillData, items: items });
  };

  const addItems = () => {
    if (buyBillData.item && buyBillData.unitPrice && buyBillData.quantity) {
      //Verifico si el item no esta en el detalle
      let itemExistInDetail = buyBillData.items.find(
        (i) => i._item === buyBillData.item || i._item._id === buyBillData.item
      );
      if (!itemExistInDetail) {
        let item = activeItems.filter((i) => i._id === buyBillData.item)[0];
        let description = item.description;
        let item_code = item.item_code;

        const newData = {
          _item: buyBillData.item,
          item_code: item_code,
          description: description,
          unitValue: buyBillData.unitValue,
          unitPrice: buyBillData.unitPrice,
          quantity: buyBillData.quantity,
        };
        // setItemsData([...itemsData, newData]);
        setBuyBillData({
          ...buyBillData,
          items: [...buyBillData.items, newData],
        });
      } else {
        notification["error"]({
          message: "El producto ya se encuentra en el detalle de la factura",
        });
      }
    } else {
      notification["error"]({
        message:
          "Debe elegir al menos un producto e ingresar la cantidad requerida",
      });
    }
  };

  useEffect(() => {
    let totals = 0;
    buyBillData.items.forEach(({ unitPrice, quantity }) => {
      totals += unitPrice * quantity;
    });

    setBuyBillData({ ...buyBillData, total: totals });
  }, [buyBillData.items]);

  return (
    <Form className="form-edit" size={"middle"} onSubmit={updateBuyBill}>
      {/* <Divider orientation="left">Datos</Divider> */}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Código
          </label>
          <Form.Item>
            <InputNumber
              name="code"
              placeholder="Código"
              value={buyBillData.code}
              onChange={(e) => setBuyBillData({ ...buyBillData, code: e })}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Tipo de Comprobante</label>
          <Form.Item rules={[{ required: true }]}>
            <Select
              showSearch
              name="billType"
              value={buyBillData.billType}
              onChange={(e) => setBuyBillData({ ...buyBillData, billType: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {activeBillTypes
                ? activeBillTypes.map((i) => {
                    return <Option key={i.description}>{i.description}</Option>;
                  })
                : "No tipo de comprobantes"}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Punto de venta
          </label>
          <Form.Item>
            <Select
              placeholder="Punto de venta"
              value={
                buyBillData.salePoint
                  ? buyBillData.salePoint
                  : ""
              }
              //defaultValue={supplierDB.name}
              onChange={(e) => setBuyBillData({ ...buyBillData, salePoint: e })}
            >
              {activeUserSalePoints
                ? activeUserSalePoints.map((i) => {
                    return (
                      <Option key={i._id}>
                        {i.salepoint_code} {i.address} {i.number}{" "}
                        {i.postal_code} {i.locality}
                      </Option>
                    );
                  })
                : "No hay puntos de venta disponibles"}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Orden de Compra</label>
          <Form.Item>
            <Select
              showSearch
              mode="multiple"
              name="buyOrders"
              value={buyBillData.buyOrders}
              onChange={(e) =>
                setBuyBillData({
                  ...buyBillData,
                  buyOrders: e,
                })
              }
              maxTagCount="responsive"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {optionsActiveBuyOrders
                ? optionsActiveBuyOrders.map((i) => {
                    return (
                      <Option key={i._id}>
                        {i.code + " - " + i.supplier.denomination}
                      </Option>
                    );
                  })
                : "No hay ordenes de compra disponibles"}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Proveedor
          </label>
          <Form.Item>
            <Select
              placeholder="Seleccione el proveedor"
              prefix={<UserOutlined />}
              value={buyBillData.supplier ? buyBillData.supplier : ""}
              //defaultValue={supplierDB.name}
              onChange={(e) => setBuyBillData({ ...buyBillData, supplier: e })}
            >
              {activeSuppliers
                ? activeSuppliers.map((i) => {
                    return <Option key={i._id}>{i.denomination}</Option>;
                  })
                : "No hay proveedores activos"}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Fecha
          </label>
          <Form.Item rules={[{ required: true }]}>
            <Input
              type="date"
              name="date"
              placeholder="Fecha"
              value={buyBillData.date}
              onChange={(e) =>
                setBuyBillData({
                  ...buyBillData,
                  date: e.target.value,
                })
              }
              required
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Fecha Ingreso
          </label>
          <Form.Item rules={[{ required: true }]}>
            <Input
              type="date"
              name="inputDate"
              placeholder="Fecha de ingreso"
              value={buyBillData.inputDate}
              onChange={(e) =>
                setBuyBillData({
                  ...buyBillData,
                  inputDate: e.target.value,
                })
              }
              required
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Fecha Vencimiento</label>
          <Form.Item rules={[{ required: true }]}>
            <Input
              type="date"
              name="expirationDate"
              placeholder="Fecha de vencimiento"
              value={buyBillData.expirationDate}
              onChange={(e) =>
                setBuyBillData({
                  ...buyBillData,
                  expirationDate: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">Período desde</label>
          <Form.Item>
            <Input
              type="date"
              name="periodFrom"
              placeholder="Periodo desde"
              value={buyBillData.periodFrom}
              onChange={(e) =>
                setBuyBillData({
                  ...buyBillData,
                  periodFrom: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Hasta
          </label>
          <Form.Item>
            <Input
              type="date"
              placeholder="Periodo hasta"
              value={buyBillData.periodUntil}
              onChange={(e) =>
                setBuyBillData({
                  ...buyBillData,
                  periodUntil: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Moneda</label>
          <Form.Item rules={[{ required: true }]}>
            <Select
              showSearch
              name="coin"
              value={buyBillData.coin}
              onChange={(e) =>
                setBuyBillData({ ...buyBillData, coin: e })
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {COINS
                ? COINS.map((i) => {
                    return <Option key={i}>{i}</Option>;
                  })
                : "No hay monedas disponibles"}
            </Select>
          </Form.Item>
        </Col>
        {buyBillData.coin !== "pesos" && buyBillData.coin ? (
          <Col span={6}>
            <label className="control-label">Cotizacion</label>
            <Form.Item rules={[{ required: true }]}>
              <InputNumber
                placeholder="Cotizacion"
                value={buyBillData.quotation}
                onChange={(e) =>
                  setBuyBillData({ ...buyBillData, quotation: e })
                }
                decimalSeparator={","}
                style={{ width: "100%" }}
                required
              />
            </Form.Item>
          </Col>
        ) : null}
      </Row>

      <Divider orientation="left" style={{ margin: 5 }}></Divider>

      <Row gutter={{ xs: 8, sm: 8, md: 24, lg: 32 }}>
        <Col span={5}>
          <label className="control-label">Producto/Servicio</label>
          <Form.Item>
            <Select
              // placeholder="Seleccione un Producto/servicio"
              value={buyBillData.item}
              onChange={(e) =>
                setBuyBillData({
                  ...buyBillData,
                  item: e
                })
              }
            >
              {activeItems
                ? activeItems.map((i) => {
                    return <Option key={i._id}>{i.description}</Option>;
                  })
                : "No hay productos disponibles"}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <label className="control-label">Valor Unitario</label>
          <Form.Item>
            <Input
              name="unitValue"
              value={buyBillData.unitValue}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <label className="control-label">Precio Unitario</label>
          <Form.Item>
            <Input
              name="unitPrice"
              value={buyBillData.unitPrice}
              onChange={(e) =>
                setBuyBillData({ ...buyBillData, unitPrice: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <label className="control-label">Cantidad</label>
          <Form.Item>
            <InputNumber
              placeholder="Cantidad"
              value={buyBillData.quantity}
              defaultValue={1}
              onChange={(e) => setBuyBillData({ ...buyBillData, quantity: e })}
              min={1}
              max={100}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item>
            <Button
              type="primary"
              className="button-add"
              size="middle"
              onClick={addItems}
            >
              <PlusOutlined /> Agregar
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          {/* {buyBillData.items.length > 0 ? ( */}
          <Items
            itemsData={buyBillData.items}
            setItemsData={setListItems}
            // showDeleteConfirm={showDeleteConfirm}
          />
          {/* ) : null} */}
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">TOTAL</label>
          <Form.Item>
            <Input
              type="text"
              placeholder="Total"
              name="total"
              value={buyBillData.total}
              size="middle"
              decimalSeparator=","
              disabled={true}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateBuyBill}
          // loading={buttonLoading}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}

function Items(props) {
  const { itemsData, setItemsData } = props;

  const columns = [
    {
      title: "Código",
      dataIndex: "item_code",
      render: (_item, row) =>
        row.item_code ? row.item_code : row._item.item_code,
      width: "10%",
      editable: false,
    },
    {
      title: "Producto",
      dataIndex: "description",
      render: (_item, row) =>
        row.description ? row.description : row._item.description,
      width: "20%",
      editable: false,
    },
    {
      title: "Valor Unitario",
      dataIndex: "unitValue",
      render: (_item, row) =>
      row.unitValue ? "$" + row.unitValue.value + " " + formatDateView(row.unitValue.date) : null,
      width: "20%",
      editable: false,
    },
    {
      title: "Precio Unitario",
      dataIndex: "unitPrice",
      width: "15%",
      editable: true,
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      width: "10%",
      editable: true,
    },
    {
      title: "Total",
      // dataIndex:"quantity",
      render: (_item, row) =>
        row.unitPrice ? row.unitPrice * row.quantity : 0,
      width: "10%",
      editable: false,
    },
  ];
  return (
    <EditableItemTable
      columnsData={columns}
      data={itemsData}
      setData={setItemsData}
      rowKey="_item"
    />
  );
}

function EditableItemTable(props) {
  const { data, setData, columnsData, rowKey } = props;
  const [form] = Form.useForm();
  // const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Por favor ingrese ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const isEditing = (record) => record._item === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      description: "",
      unitValue: "",
      quantity: "",
      ...record,
    });
    setEditingKey(record._item);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item._item);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      // console.log('Validate Failed:', errInfo);
    }
  };

  const showDeleteConfirm = (record) => {
    const d = data.filter((i) => i !== record);
    if (d.length === 0) {
      setData([]);
    } else {
      setData(d);
    }
  };

  const columns = [
    ...columnsData,
    {
      title: "Acción",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              type="primary"
              size="small"
              onClick={() => save(record._item)}
            >
              <SaveOutlined />
            </Button>
            <Popconfirm title="Está seguro de cancelar?" onConfirm={cancel}>
              <Button type="danger" size="small">
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Button
              type="default"
              size="small"
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <EditOutlined />
            </Button>
            <Button
              className="button-item"
              type="danger"
              size="small"
              onClick={(e) => showDeleteConfirm(record)}
            >
              <DeleteOutlined />
            </Button>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "quantity" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        style={{ margin: 20 }}
        size="small"
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        rowKey={rowKey}
        scroll={{ y: 150 }}
        summary={(pageData) => {
          let total = 0;

          pageData.forEach(({ unitPrice, quantity }) => {
            total += unitPrice * quantity;
          });
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell>Total</Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text type="danger">{total}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </Form>
  );
}
