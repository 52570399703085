import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  DownloadOutlined,
  PlusOutlined

} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import AddPaymentOrderForm from "../AddPaymentOrderForm";
import EditPaymentOrderForm from "../EditPaymentOrderForm";
import {
  deletePaymentOrderApi,
  activatePaymentOrderApi,
} from "../../../../api/billing/paymentOrder";
import { paymentOrderDetailPdf } from "./pdfPaymentOrder";
import { getAccessToken } from "../../../../api/auth";
import { formatDateView } from "../../../../utils/formatDate";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListPaymentOrders.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListPaymentOrders(props) {
  const {
    paymentOrdersActive,
    paymentOrdersInactive,
    setReloadPaymentOrders,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewPaymentOrdersActives, setViewPaymentOrdersActives] = useState(
    true
  );
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [modalWidth, setModalWidth] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [paymentOrdersActiveFilter, setPaymentOrdersActiveFilter] = useState(
    []
  );
  const [
    paymentOrdersInactiveFilter,
    setPaymentOrdersInactiveFilter,
  ] = useState([]);
  const [typeFilter, setMethodFilter] = useState("code");
  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, [roleById]);

  useEffect(() => {
    const listActive = [];
    if (paymentOrdersActive) {
      paymentOrdersActive.forEach((element) => {
        if (typeFilter === "supplier") {
            if (
              element.supplier.denomination
                .toUpperCase()
                .indexOf(filterText.toUpperCase()) !== -1 ||
              filterText === ""
            ) {
              listActive.push(element);
            }
        } else {
          if (
            element.paymentorder_code
              .toString()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listActive.push(element);
          }
        }
      });
    }
    setPaymentOrdersActiveFilter(listActive);
  }, [filterText, paymentOrdersActive]);

  useEffect(() => {
    const listInactive = [];
    if (paymentOrdersInactive) {
      paymentOrdersInactive.forEach((element) => {
        if (typeFilter === "supplier") {
          if (
            element.supplier.denomination
              .toUpperCase()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        } else {
          if (
            element.paymentorder_code
              .toString()
              .indexOf(filterText.toUpperCase()) !== -1 ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        }
      });
    }
    setPaymentOrdersInactiveFilter(listInactive);
  }, [filterText, paymentOrdersInactive]);

  const addPaymentOrderModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Orden de pago");
    setModalWidth(1000);
    setModalContent(
      <AddPaymentOrderForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadPaymentOrders={setReloadPaymentOrders}
      />
    );
  };

  return (
    <div className="list-paymentorders">
      <div className="list-paymentorders__header">
        {checkAction(permissionsActive, userToken.role, "paymentorders", "all") ||
        checkAction(permissionsActive, userToken.role, "paymentorders", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-paymentorders__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewPaymentOrdersActives(!viewPaymentOrdersActives)
                }
              />
              <span>
                {viewPaymentOrdersActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
        ) : (
          <>
          </>
        )}

        {checkAction(permissionsActive, userToken.role, "paymentorders", "all") ||
        checkAction(permissionsActive, userToken.role, "paymentorders", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addPaymentOrderModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "paymentorders", "all") ||
      checkAction(permissionsActive, userToken.role, "paymentorders", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-paymentorders__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <Radio.Group
              options={[
                { label: "Código", value: "code" },
                { label: "Proveedor", value: "supplier" },
              ]}
              onChange={(e) => {
                setMethodFilter(e.target.value);
              }}
              value={typeFilter}
            />
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewPaymentOrdersActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "paymentorders", "all") ||
          checkAction(permissionsActive, userToken.role, "paymentorders", "view") || isAdmin(roleById) ? (
            <>
              <PaymentOrdersActive
                paymentOrdersActive={paymentOrdersActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadPaymentOrders={setReloadPaymentOrders}
                setModalWidth={setModalWidth}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "paymentorders", "all") ||
          checkAction(permissionsActive, userToken.role, "paymentorders", "view") || isAdmin(roleById) ? (
            <>
              <PaymentOrdersInactive
                paymentOrdersInactive={paymentOrdersInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadPaymentOrders={setReloadPaymentOrders}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        modalWidth={modalWidth}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function PaymentOrdersActive(props) {
  const {
    paymentOrdersActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadPaymentOrders,
    setModalWidth,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editPaymentOrder = (paymentOrder) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${
        paymentOrder.paymentorder_code
          ? paymentOrder.paymentorder_code
          : "sin codigo"
      }`
    );
    setModalWidth(1000);
    setModalContent(
      <EditPaymentOrderForm
        paymentOrder={paymentOrder}
        setIsVisibleModal={setIsVisibleModal}
        setReloadPaymentOrders={setReloadPaymentOrders}
      />
    );
  };

  const desactivatePaymentOrder = (paymentMethod) => {
    const accessToken = getAccessToken();

    activatePaymentOrderApi(accessToken, paymentMethod._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadPaymentOrders(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (paymentOrder) => {
    const accessToken = getAccessToken();
    confirm({
      title: "Eliminando Orden de pago",
      content: `¿Estás seguro que quieres eliminar la Orden de Pago Nro. ${paymentOrder.paymentorder_code}?`,
      okText: "Eliminar",
      okMethod: "danger",
      cancelText: "Cancelar",
      onOk() {
        deletePaymentOrderApi(accessToken, paymentOrder._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadPaymentOrders(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const edit_paymentOrder = (
    <div>
      {" "}
      <p>Editar Orden de Pago</p>{" "}
    </div>
  );

  const desactivate_paymentOrder = (
    <div>
      {" "}
      <p>Pasar a Orden de pago inactivos</p>{" "}
    </div>
  );

  const delete_paymentOrder = (
    <div>
      {" "}
      <p>Eliminar Orden de pago</p>{" "}
    </div>
  );

  const download_paymentOrder = (
    <div>
      {" "}
      <p>Descargar Orden de pago</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "paymentorder_code",
    },
    {
      title: "Fecha",
      dataIndex: "date",
      render: (date) => formatDateView(date),
    },
    {
      title: "Proveedor",
      dataIndex: "supplier",
      render: (supplier) => supplier.denomination,
    },
    {
      title: "Acción",
      key: "action",
      render: (text, paymentOrder) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "paymentorders", "all") ||
          checkAction(permissionsActive, userToken.role, "paymentorders", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_paymentOrder}>
                <Button
                  className="button-paymentorder"
                  type="default"
                  onClick={() => editPaymentOrder(paymentOrder)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "paymentorders", "all") ||
          checkAction(permissionsActive, userToken.role, "paymentorders", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_paymentOrder}>
                <Button
                  className="button-paymentorder"
                  type="primary"
                  onClick={() => desactivatePaymentOrder(paymentOrder)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "paymentorders", "all") ||
          checkAction(permissionsActive, userToken.role, "paymentorders", "pdf") || isAdmin(roleById) ? (
            <>
              <Popover content={download_paymentOrder}>
                <Button
                  type="primary"
                  onClick={() => {
                    paymentOrderDetailPdf(paymentOrder, false);
                  }}
                >
                  <DownloadOutlined />
                </Button>
              </Popover>,
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "paymentorders", "all") ||
          checkAction(permissionsActive, userToken.role, "paymentorders", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_paymentOrder}>
                <Button
                  className="button-paymentorder"
                  type="danger"
                  onClick={() => showDeleteConfirm(paymentOrder)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={paymentOrdersActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function PaymentOrdersInactive(props) {
  const {
          paymentOrdersInactive,
          setReloadPaymentOrders,
          userToken,
          permissionsActive,
          roleById
        } = props;

  const activatePaymentOrder = (paymentOrder) => {
    const accessToken = getAccessToken();

    activatePaymentOrderApi(accessToken, paymentOrder._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadPaymentOrders(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (paymentOrder) => {
    const accessToken = getAccessToken();
    confirm({
      title: "Eliminando Orden de pago",
      content: `¿Estás seguro que quieres eliminar la Orden de Pago Nro. ${paymentOrder.paymentorder_code}?`,
      okText: "Eliminar",
      okMethod: "danger",
      cancelText: "Cancelar",
      onOk() {
        deletePaymentOrderApi(accessToken, paymentOrder._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadPaymentOrders(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const activate_paymentOrder = (
    <div>
      {" "}
      <p>Pasar a Orden de pago activos</p>{" "}
    </div>
  );

  const delete_paymentOrder = (
    <div>
      {" "}
      <p>Eliminar Orden de pago</p>
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "paymentorder_code",
    },
    {
      title: "Fecha",
      dataIndex: "date",
      render: (date) => formatDateView(date),
    },
    {
      title: "Proveedor",
      dataIndex: "supplier",
      render: (supplier) => supplier.name,
    },
    {
      title: "Acción",
      key: "action",
      render: (text, paymentOrder) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "paymentorders", "all") ||
          checkAction(permissionsActive, userToken.role, "paymentorders", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_paymentOrder}>
                <Button
                  className="button-paymentorder"
                  type="primary"
                  onClick={() => activatePaymentOrder(paymentOrder)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "paymentorders", "all") ||
          checkAction(permissionsActive, userToken.role, "paymentorders", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_paymentOrder}>
                <Button
                  className="button-paymentorder"
                  type="danger"
                  onClick={() => showDeleteConfirm(paymentOrder)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={paymentOrdersInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
