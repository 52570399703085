import { basePath, apiVersion } from '../config';
import { responsiveArray } from 'antd/lib/_util/responsiveObserve';
import { Result } from 'antd';

//FUNCIONES QUE CONSUMEN LOS ENDPOINTS DEL SERVER

export function getSupplierExportApi(token) {
    const url = `${basePath}/${apiVersion}/supplier-export-csv`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "text/csv",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.text();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'supplier.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(err => {
            return err.message;
        });
}

export function postSupplierImportApi(token, file) {
    const url = `${basePath}/${apiVersion}/supplier-import-csv`;
    const formData = new FormData();
    formData.append("file", file, file.name);

    const params = {
        method: "POST",
        body: formData,
        headers: {
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch(err => {
            return err.message;
        });
}

export function addSupplierApi(token, data) {
    const url = `${basePath}/${apiVersion}/add-supplier`;

    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json()
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return {
                ok: false,
                message: err.message
            };
        });
}

export function addSupplierApi2(token, data){
    const url = `${basePath}/${apiVersion}/add-supplier`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };
    //console.log(data);

    return fetch(url, params)
        .then(response => {
            //console.log(response);
            return response.json();
        })
        .then(result => {
            if (result.supplier) {
                return {
                    ok: true,
                    message: "¡Proveedor creado!"
                };
            }
            return {
                ok: false,
                message: result.message
            };
        })
        .catch(err => {
            return {
                ok: false,
                message: err.message
            };
        });

        /*
        fetch(url, params)
            .then(response => {
                return response.json();
            }).then(result => {
                return result;
            })*/
}

export function getSuppliersApi(token) {
    const url = `${basePath}/${apiVersion}/suppliers`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getSupplierApi(token, supplierId) {
    const url = `${basePath}/${apiVersion}/supplier/${supplierId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getSuppliersActiveApi(token, status) {
    const url = `${basePath}/${apiVersion}/suppliers-active?active=${status}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function uploadSupplierAvatarApi(token, avatar, supplierId) {
    const url = `${basePath}/${apiVersion}/upload-avatar-supplier/${supplierId}`;
    const formData = new FormData();
    formData.append("avatar", avatar, avatar.name);

    const params = {
        method: "PUT",
        body: formData,
        headers: {
            Authorization: token
        }
    }
    return fetch(url, params) //tira aca el error cuando actualizo el supplier
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}
export function getSupplierAvatarApi(avatarName) {
    const url = `${basePath}/${apiVersion}/get-avatar-supplier/${avatarName}`;
    return fetch(url)
    .then(response => {
        return response.url;
    })
    .catch(err => {
        return err.message;
    });
}

export function updateSupplierApi(token, supplier, supplierId) {
    const url = `${basePath}/${apiVersion}/update-supplier/${supplierId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(supplier)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function activateSupplierApi(token, supplierId, status) {
    const url = `${basePath}/${apiVersion}/activate-supplier/${supplierId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            active: status
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result.message;
        })
        .catch(err => {
            return err.message;
        });
}

export function deleteSupplierApi(token, supplierId) {
    const url = `${basePath}/${apiVersion}/delete-supplier/${supplierId}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result.message;
        })
        .catch(err => {
            return err.message;
        });
}

