import React, {
  useState,
  useEffect,
  useCallback,
  //useRef
} from "react";
import "antd/dist/antd.css";
import {
  Switch,
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  BackTop,
  Avatar,
  Row,
  Col,
  List,
  Card,
  Space,
  Collapse,
  Upload,
  message,
  Image,
  Popover,
  Divider,
  Form,
  Select,
  
  //Popconfirm, Table, Radio, Select, Badge, Tag, InputNumber, Form,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  EyeOutlined,
  WhatsAppOutlined,
  FacebookOutlined,
  InstagramOutlined,
  FilePdfOutlined,
  InfoCircleTwoTone,
  StarFilled,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import NoAvatar from "../../../../assets/img/png/no-avatar.png";
import { useDropzone } from "react-dropzone";
//import { Popover } from "antd";
import Modal from "../../../Modal";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import {
  uploadBranchesCoverApi,
  uploadBranchesLogoApi,
} from "../../../../api/branch";
import {
  uploadLogoApi,
  getLogoApi,
  getCoverApi,
  uploadCoverApi,
  getBusinessApi,
  updateBusinessApi,
} from "../../../../api/business";
import {
  availableCategoryApi,
  deleteCategoryApi,
  getCategoriesIsGeneralByBusinessApi
} from "../../../../api/category";
import {
  getItemsByCategoryApi,
  deleteItemApi,
  activateItemApi,
  getItemsGeneralByCategoryApi
} from "../../../../api/item";
import {
  availableCategoryBranchMenuAllBranchesApi,
  deleteCategoryBranchMenuAllBranchesApi,
} from "../../../../api/branchMenu";
import jwtDecode from "jwt-decode";
import {EditSocialMediaForm, EditSocialMediaBusinessForm} from "../EditSocialMedia/EditSocialMediaForm";
import EditItemForm from "../../Items/EditItemForm";
import EditCategoryForm from "../../Category/EditCategoryForm";
import AddItemCategoryForm from "../../Items/AddItemCategoryForm";
// import AddItemGeneralForm from "../../Items/EditItemGeneralForm"
import NoImage from "../../../../assets/img/png/no-image.png";
import { useBranch } from "../../../../hooks/useBranch";
import { useBusiness } from "../../../../hooks/useBusiness";

import "./ListItems.scss";

const { confirm } = ModalAntd;
const { TextArea } = Input;
const { Panel } = Collapse;
const { Meta } = Card;

export default function ListItems(props) {
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [modalWidth, setModalWidth] = useState(0);


  const [imageCoverData, setImageCoverData] = useState(null);
  const [imageCoverUrl, setImageCoverUrl] = useState(null);

  const [imageLogoData, setImageLogoData] = useState(null);
  const [imageLogoUrl, setImageLogoUrl] = useState(null);

  const [categoriesActive, setCategoriesActive] = useState([]);
  const [categoriesInactive, setCategoriesInactive] = useState([]);

  const [filterText, setFilterText] = useState("");
  const [specialtiesActiveFilter, setSpecialtiesActiveFilter] = useState([]);
  const [specialtiesInactiveFilter, setSpecialtiesInactiveFilter] = useState(
    []
  );
  const [reloadCategories, setReloadCategories] = useState([]);
  const [businessData, setBusinessData] = useState({});
  const [branchMenuData, setBranchMenuData] = useState({});
  const [reloadStore, setReloadStore] = useState(false);

  const { branch } = useBranch();
  const { business } = useBusiness();


  useEffect(() => {
    //aca se deberia buscar en el branch menu el listado de categorias----TODO
    if (reloadCategories === true && business && business !== "" && business!==0 && business!=="0") {
      getCategoriesIsGeneralByBusinessApi(accessToken,true, business).then((response) => {
        const listActive = response.categories.filter(
          (item) => item.available === true
        );
        const listInactive = response.categories.filter(
          (item) => item.available === false
        );
          setCategoriesActive(listActive);
          setCategoriesInactive(listInactive);
      });
      setReloadCategories(false);
    }
  }, [reloadCategories]);

  useEffect(() => {
    //aca se deberia buscar en el branch menu el listado de categorias----TODO
    if (business && business !== "" && business!==0 && business!=="0") {
      getCategoriesIsGeneralByBusinessApi(accessToken, true,business).then((response) => {
        const listActive = response.categories.filter(
          (item) => item.available === true
        );
        //const listActive2=listActive.map((i)=>i.category);
        const listInactive = response.categories.filter(
          (item) => item.available === false
        );
        //const listInactive2=listInactive.map((i)=>i.category);
          setCategoriesActive(listActive);
          setCategoriesInactive(listInactive);
        //setCategoriesActive(response.categories);
      });
    }
  }, [business]);


  useEffect(() => {
    if (business && business !== "" && business!==0 && business!=="0") {
      getBusinessApi(accessToken, business).then((response) => {
        setBusinessData(response.business);
      });
    }
  }, [business]);

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  //si tiene logo lo trae, sino lo deja vacio
  useEffect(() => {
    if (business && business !== "" && business!==0 && business!=="0") {
      getBusinessApi(accessToken, business).then((result) => {
        if (result.business.logo) {
          getLogoApi(result.business.logo).then((response) => {
            setImageLogoUrl(response);
          });
          setImageLogoData(result.business.logo);
        }
      });
    }
  }, [business, imageLogoUrl]);

  //si tiene logo lo trae, sino lo deja vacio
  useEffect(() => {
    if (business && business !== "" && business!==0 && business!=="0") {
      getBusinessApi(accessToken, business).then((result) => {
        if (result.business.cover) {
          getCoverApi(result.business.cover).then((response) => {
            setImageCoverUrl(response);
          });
          setImageCoverData(result.business.cover);
        }
      });
    }
  }, [business, imageCoverUrl]);

  const handlePressEnter = () => {
    const updateData = businessData;
    updateBusinessApi(accessToken, updateData, businessData._id).then((result) => {
      getBusinessApi(accessToken, updateData._id).then((response) => {
        setBusinessData(response.business);
      });
    });
  };

  const handleImageUpload = (url) => {
    setImageCoverUrl(url);
  };

  const handleImageLogoUpload = (url) => {
    setImageLogoUrl(url);
  };

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then((response) => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then((response) => {
      setRoleById(response.role);
    });
  }, []);

 

  const editSocialMedia = () => {
    setIsVisibleModal(true);
    setModalTitle(`Editar Redes sociales`);
    setModalContent(
      <EditSocialMediaBusinessForm
        setIsVisibleModal={setIsVisibleModal}
        business={businessData}
        setReloadStore={setReloadStore}
      />
    );
  };

  return (
    <div className="list-items">
      <div
      // className="list-items__header"
      >
        {checkAction(permissionsActive, userToken.role, "items", "all") ||
        checkAction(permissionsActive, userToken.role, "items", "enable") ||
        isAdmin(roleById) ? (
          <>
            <div>
              <UploadImagenes
                onImageUpload={handleImageUpload}
                imageCoverUrl={imageCoverUrl}
                setImageCover={setImageCoverData}
                onImageLogoUpload={handleImageLogoUpload}
                imageLogoUrl={imageLogoUrl}
                setImageLogo={setImageLogoData}
                businessData={businessData}
              />
            </div>
            <div>
              <Row>
                <Col span={7}>
                  <label style={{ color: "black" }}>Nombre del Negocio</label>
                  <Input
                    showCount
                    maxLength={50}
                    value={businessData.name ? businessData.name : ""}
                    onChange={(e) =>
                      setBusinessData({ ...businessData, name: e.target.value })
                    }
                    onPressEnter={handlePressEnter}
                  />
                </Col>
                <Col span={3}>
                  <Space
                    style={{
                      margin: "15px 0px 0px 10px",
                      padding: "8px 8px 8px",
                      //border: "1px solid #40a9ff"
                    }}
                  >
                    <StarFilled style={{ fontSize: "16px", color: "yellow" }} />
                    <Switch defaultUnChecked />
                  </Space>
                </Col>
                <Col span={5} offset={9}>
                  <Space
                    direction="horizontal"
                    style={{
                      margin: "15px 0px 0px 10px",
                      padding: "8px 8px 8px",
                    }}
                  >
                    <>
                      <Popover content={"Editar redes sociales"}>
                        <Button
                          type="link"
                          //className="button-edit"
                          onClick={() => editSocialMedia()}
                        >
                          {" "}
                          Editar
                        </Button>
                        <Divider type="vertical" />
                        <WhatsAppOutlined style={{ background: "black" }} />
                        <Divider type="vertical" />
                        <FacebookOutlined style={{ background: "black" }} />
                        <Divider type="vertical" />
                        <InstagramOutlined style={{ background: "black" }} />
                      </Popover>
                    </>
                  </Space>
                </Col>
              </Row>

              <Row className="my-row">
                <Col span={3}>
                  <Button>Cambiar Colores</Button>
                </Col>
                <Col span={8} offset={1}>
                  <label>Mostrar SKU</label> <InfoCircleTwoTone />{" "}
                  <Switch defaultUnChecked />
                </Col>
                <Col span={2} offset={6}>
                  <Button type="default" style={{ width: "80%" }}>
                    <FilePdfOutlined />
                  </Button>
                </Col>
                <Col span={4}>
                  <Button
                    type="primary"
                    style={{ backgroundColor: "red", width: "100%" }}
                  >
                    <EyeOutlined /> Ver en linea
                  </Button>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <></>
        )}

      </div>

      {checkAction(permissionsActive, userToken.role, "items", "all") ||
      checkAction(permissionsActive, userToken.role, "items", "view") ||
      isAdmin(roleById) ? (
        <>
          <div className="list-items__categories">
          <Divider orientation="left">Disponibles</Divider>
            <Categories
              categoriesActive={categoriesActive}
              setIsVisibleModal={setIsVisibleModal}
              setModalTitle={setModalTitle}
              setModalContent={setModalContent}
              setReloadCategories={setReloadCategories}
              accessToken={accessToken}
              userToken={userToken}
              permissionsActive={permissionsActive}
              roleById={roleById}
              setModalWidth={setModalWidth}
              business={business}
            />
          <Divider orientation="left">No disponibles</Divider>
            <Categories
              categoriesActive={categoriesInactive}
              setIsVisibleModal={setIsVisibleModal}
              setModalTitle={setModalTitle}
              setModalContent={setModalContent}
              setReloadCategories={setReloadCategories}
              accessToken={accessToken}
              userToken={userToken}
              permissionsActive={permissionsActive}
              roleById={roleById}
              setModalWidth={setModalWidth}
              business={business}
            />
            {/* <ListDraggable someData={listData} /> */}
          </div>
        </>
      ) : (
        <></>
      )}
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        modalWidth={modalWidth}
      >
        {modalContent}
      </Modal>
      <BackTop />
    </div>
  );
}


function UploadImagenes(props) {
  const {
    onImageUpload,
    imageCoverUrl,
    setImageCover,
    imageLogoUrl,
    setImageLogo,
    onImageLogoUpload,
    businessData,
  } = props;
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (imageCoverUrl) {
      setPreviewImage(imageCoverUrl);
    } else {
      setPreviewImage("");
    }
  }, [imageCoverUrl]);


  // Maneja el cambio en el estado del archivo
  const handleChange = (info) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      setFileList([info.file]); // Solo mostrar una imagen
      setPreviewImage(URL.createObjectURL(info.file.originFileObj));
      setEditing(false); // Termina el estado de edición
      onImageUpload(info.file.name); // Guardar el nombre del archivo
      uploadBranches(info.file.name)
      setImageCover(info.file.name);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const customRequest = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append("file", file);
    const accessToken = getAccessToken();
    try {
      const response = await uploadCoverApi(
        accessToken,
        formData,
        businessData._id
      );
      if (response.status === 200) {
        //actualizo en todas 
        let result = await uploadBranches(formData);
        if(result){
          onSuccess();
        }
      } else {
        onError(new Error("Upload failed"));
      }
    } catch (error) {
      onError(error);
    }
  };

  const uploadBranches = async (file) => {
    const accessToken = getAccessToken();
    try {
      const response = await uploadBranchesCoverApi(
        accessToken,
        file,
        businessData._id
      );
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // onError(error);
    }
  };

  const handleRemove = () => {
    setFileList([]);
    setPreviewImage(null);
    onImageUpload("");
    message.success("Image removed successfully");
  };

  // Maneja la edición de la imagen
  const handleEdit = () => {
    setEditing(true);
  };
  return (
    <div className="cover-container">
      <Image
        width="100%"
        height="280px"
        src={previewImage}
        preview={false}
        className="cover-image"
      />
      <Upload
        className="upload-button"
        customRequest={customRequest}
        onChange={handleChange}
        showUploadList={false} // No mostrar la lista de archivos
        accept="image/*"
        multiple={false} // Permitir solo un archivo
        disabled={editing} // Deshabilitar la carga durante la edición
      >
        <Button icon={<EditOutlined />}>Editar Imagen 1440 x 360px</Button>
      </Upload>
      <UploadImageAvatar
        onImageUpload={onImageLogoUpload}
        imageUrl={imageLogoUrl}
        setImage={setImageLogo}
        businessData={businessData}
      />
    </div>
  );
}

function UploadImageAvatar(props) {
  const { onImageUpload, imageUrl, setImage, businessData } = props;
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (imageUrl) {
      setPreviewImage(imageUrl);
    } else {
      setPreviewImage("");
    }
  }, [imageUrl]);

  // Maneja el cambio en el estado del archivo
  const handleChange = (info) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} el archivo ha subido correctamente`);
      setFileList([info.file]); // Solo mostrar una imagen
      setPreviewImage(URL.createObjectURL(info.file.originFileObj));
      setEditing(false); // Termina el estado de edición
      onImageUpload(URL.createObjectURL(info.file.originFileObj));
      setImage(info.file.name); // Guardar el nombre del archivo
      setLoading(false);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} la carga del archivo falló.`);
      setLoading(false);
    }
  };

  const customRequest = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append("file", file);
    const accessToken = getAccessToken();
    try {
      const response = await uploadLogoApi(
        accessToken,
        formData,
        businessData._id
      );
      if (response.status === 200) {
        let result = await uploadBranches(formData);
        if(result){
          onSuccess();
        }
      } else {
        onError(new Error("La carga falló."));
      }
    } catch (error) {
      onError(error);
    }
  };

  const uploadBranches = async (file) => {
    const accessToken = getAccessToken();
    try {
      const response = await uploadBranchesLogoApi(
        accessToken,
        file,
        businessData._id
      );
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // onError(error);
    }
  };


  // Maneja la edición de la imagen
  const handleEdit = () => {
    setEditing(true);
  };

  return (
    <div 
     className="avatar"
    >
      <Upload
        customRequest={customRequest}
        onChange={handleChange}
        showUploadList={false} // No mostrar la lista de archivos
        accept="image/*"
        multiple={false} // Permitir solo un archivo
        disabled={editing} // Deshabilitar la carga durante la edición
      >
        {/* <div 
        //className="avatar"
        > */}
          {previewImage && previewImage !== null ? (
            <>
              <Avatar
                size={220}
                src={previewImage !== "" ? previewImage : NoImage}
                shape="square"
                //style={{ display: "block", marginBottom: 8 }}
                style={{ marginTop: 50 , objectFit:"cover"}}
              />
              {/* <Text>{fileName}</Text> */}
              <div className="button-edit-avatar">
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={handleEdit}
                  // style={{ marginRight: 8 }}
                ></Button>
                {/* <Button
                  type="link"
                  icon={<DeleteOutlined />}
                  onClick={handleRemove}
                  // style={{ position: 'absolute', top: 0, right: 0 }}
                /> */}
              </div>
            </>
          ) : (
            //   <button
            //   style={{
            //     width:"100%",
            //     height:"100%",
            //     border: 0,
            //     background: 'none',
            //     backgroundColor:"red",
            //   }}
            //   type="button"
            // >
            //   {loading ? <LoadingOutlined /> : <PlusOutlined />}
            //   <div
            //     style={{
            //       marginTop: 8,
            //     }}
            //   >
            //     Upload
            //   </div>
            // </button>
            <>
              <Avatar
                size={{
                  xs: 160,
                  sm: 160,
                  md: 200,
                  lg: 200,
                  xl: 220,
                  xxl: 220,
                }}
                // size={220}
                src={NoImage}
                shape="square"
                style={{ marginTop: 50 , objectFit:"cover" }}
                // style={{ display: "block", marginBottom: 8 }}
              />
              <div className="button-edit-avatar">
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={handleEdit}
                  // style={{ marginRight: 8 }}
                ></Button>
              </div>
            </>
          )}
        {/* </div> */}
      </Upload>
    </div>
  );
}

function Categories(props) {
  const {
    categoriesActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadCategories,
    accessToken,
    userToken,
    permissionsActive,
    roleById,
    setModalWidth,
    reloadCategories,
    business,
  } = props;

  const [listData, setListData] = useState([]);
  const [reload, setReload] = useState(false); //para que se actualicen los items dentro del panel
  const [reloadItems, setReloadItems] = useState(false);

  useEffect(() => {
    const renderFiltering = categoriesActive.map((filter, index) => {
      // const list= await getItemsByCategory(filter);
      return (
        <Panel className="ant-panel" header={filter.name} key={filter._id} extra={extraNode(filter)}>
          <ListFinalItems
            categoryId={filter._id}
            accessToken={accessToken}
            setIsVisibleModal={setIsVisibleModal}
            setModalTitle={setModalTitle}
            setModalContent={setModalContent}
            setModalWidth={setModalWidth}
            business={business}
            reloadItems={reloadItems}
            setReloadItems={setReloadItems}
          />
        </Panel>
      );
    });
    setListData(renderFiltering);
  }, [categoriesActive, reloadCategories]);

  const handleExtraNodeClick = (panelKey) => {
    //console.log(`Haz hecho clic en un nodo extra del panel ${panelKey._id}`);
    // Aquí puedes agregar la lógica correspondiente al nodo extra del panel
  };

  const handleDeleteCategory = async (category) => {
    //Verifico que la categoria no tenga productos generales o de cada sucursal
    let result = await getItemsByCategoryApi(accessToken, category._id);
    let response=result.items? result.items.length > 0? false : true : true;
    console.log(result)
    //let response = true;
    if (response === false) {
      confirm({
        title: "Eliminando -> Categoria",
        content: `No se puede eliminar '${category.name}' porque existen productos asociados de otras sucursales ¿Deseas desactivarlo?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          // desactivateAdviser(adviser);
        },
      });
    } else {
      confirm({
        title: "Eliminando -> Categoria",
        content: `¿Estás seguro que quieres eliminar a ${category.name}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          //Luego elimino la categoria de todos los branch menu para eliminar definitivamente
          deleteCategoryBranchMenuAllBranchesApi(accessToken,business,category._id)
          .then((response) => {
            if (response.code === 200) {
              // notification["success"]({
              //   message: response.message,
              // });
              return true;
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .then(async (result)=>{
            if(result===true){
              let response= await deleteCategoryApi(accessToken, category._id);
                if (response.code === 200) {
                  notification["success"]({
                    message: response.message,
                  });
                  setReloadCategories(true);
                } else if (response.code === 404 || response.code === 500) {
                  notification["error"]({
                    message: response.message,
                  });
                } else if (
                  response === "ERR_CONNECTION_REFUSED" ||
                  response === "Failed to fetch" ||
                  response === undefined
                ) {
                  notification["error"]({
                    message: "Servidor caido",
                  });
                } else {
                  notification["error"]({
                    message: "Error del servidor",
                  });
                }
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
          })
        },
      });
    }
  };


  const handleEditCategory = (category) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar ${category.name ? category.name : "sin nombre"}`);
    setModalContent(  
       <EditCategoryForm
         category={category}
         setIsVisibleModal={setIsVisibleModal}
         setReloadCategories={setReloadCategories}
       />);

  };

  const handleAddItemCategory = (category) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Agregar Producto ${category.name ? category.name : "sin nombre"}`
    );
    setModalWidth(1000);
    setModalContent(
      <AddItemCategoryForm
        category={category}
        categories={categoriesActive}
        setIsVisibleModal={setIsVisibleModal}
        setReloadCategories={setReloadCategories}
        setReloadItems={reloadItems}
        width={1000}
        business={business}
      />
      // <AddItemGeneralForm
      //   category={category}
      //   categories={categoriesActive}
      //   setIsVisibleModal={setIsVisibleModal}
      //   setReloadCategories={setReloadCategories}
      //   setReloadItems={reloadItems}
      //   width={1000}
      //   business={business}
      // />
    );
  };

  const handleDesactivateCategory = async (category) => {
    availableCategoryBranchMenuAllBranchesApi(accessToken,business,category._id,false)
    .then((response)=>{
      if(response && response.code===200){
        return true;
      }
      else{
        return false;
      }
    }).then(async(result)=>{
      if(result){
        let responseFinal= await availableCategoryApi(accessToken, category._id, false)
        if(responseFinal && responseFinal.code===200){
          notification["success"]({
            message: responseFinal.message,
          });
          setReloadCategories(true);
        }
      }
    }).catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const handleActivateCategory = (category) => {
    availableCategoryBranchMenuAllBranchesApi(accessToken,business,category._id,true)
    .then((response)=>{

      if(response && response.code===200){
        return true;
      }
      else{
        return false;
      }
    }).then(async(result)=>{
      if(result){
        let responseFinal= await availableCategoryApi(accessToken, category._id, true);
        if(responseFinal && responseFinal.code===200){
          notification["success"]({
            message: responseFinal.message,
          });
          setReloadCategories(true);
        }
      }
    })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const extraNode = (category) => (
    <div onClick={(e) => e.stopPropagation()}>
      <Button
      className="button-available"
        onClick={() => {
          category.available
            ? handleDesactivateCategory(category)
            : handleActivateCategory(category);
        }}
      >
        {category.available === true ? <EyeOutlined /> : <EyeInvisibleOutlined />}
      </Button>
      <Button 
      className="button-add"
      onClick={() => handleAddItemCategory(category)}>
        <PlusOutlined />
      </Button>
      <Button 
      className="button-edit"
      onClick={() => handleEditCategory(category)}>
        <EditOutlined />
      </Button>
      <Button 
      className="button-delete"
      onClick={() => handleDeleteCategory(category)}>
        <DeleteOutlined />
      </Button>
    </div>
  );

  const handleButtonClick = (panelKey) => {
    console.log(`Acción en el panel ${panelKey}`);
    // Aquí podrías agregar lógica adicional según la acción realizada en el panel
  };

  return (
    <Collapse className="ant-collapse" collapsible="header" defaultActiveKey={["1"]} accordion>
      {listData}
    </Collapse>
  );
}

function ListFinalItems(props) {
  const {
    categoryId,
    accessToken,
    setIsVisibleModal,
    setModalContent,
    setModalTitle,
    setModalWidth,
    reload,
    business,
    reloadItems,
    setReloadItems
  } = props;

  const [listData, setListData] = useState([]);
  // const [reloadItems, setReloadItems] = useState(false);

  useEffect(() => {
    getItemsGeneralByCategoryApi(accessToken, categoryId, true).then(
      (response) => {
        setListData(response.items);
      }
    );
    setReloadItems(false);
  }, [categoryId, reloadItems]);

  useEffect(() => {
    getItemsGeneralByCategoryApi(accessToken, categoryId, true).then(
      (response) => {
        setListData(response.items);
      }
    );
  }, []);

  return (
    <List
      // grid={{ gutter: 16, column: 3 }} //
      itemLayout="vertical"
      size="large"
      dataSource={listData}
      renderItem={(item, index) => (
        <List.Item>
          <ItemCard
            item={item}
            index={index}
            setReloadItems={setReloadItems}
            accessToken={accessToken}
            setModalWidth={setModalWidth}
            setIsVisibleModal={setIsVisibleModal}
            setModalTitle={setModalTitle}
            setModalContent={setModalContent}
          />
        </List.Item>
      )}
    />
  );
}

function ItemCard(props) {
  const {
    item,
    index,
    setReloadItems,
    setIsVisibleModal,
    setModalContent,
    setModalTitle,
    setModalWidth,
    accessToken,
  } = props;

  const handleDeleteItem = async (item) => {
    // let response = await getStudentsByAdviserApi(accessToken, adviser._id);
    let response = true;
    if (response === false) {
      confirm({
        title: "Eliminando -> Producto",
        content: `No se puede eliminar '${item.name}' porque existen ... asociados ¿Deseas desactivarlo?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          // desactivateAdviser(adviser);
        },
      });
    } else {
      confirm({
        title: "Eliminando -> Producto",
        content: `¿Estás seguro que quieres eliminar a ${item.name}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteItemApi(accessToken, item._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadItems(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const handleEditItem = (item) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar ${item.name ? item.name : "sin nombre"}`);
    setModalWidth(1000);
    setModalContent();
    // <EditItemForm
    //   item={item}
    //   setIsVisibleModal={setIsVisibleModal}
    //   setReloadItems={setReloadItems}
    // />
  };

  const handleDesactivateItem = (item) => {
    activateItemApi(accessToken, item._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadItems(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const handleActivateItem = (item) => {
    activateItemApi(accessToken, item._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadItems(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  return (
    <Card
    style={{
      width: "100%",
      borderRadius: '10px',
      borderWidth: "2px"
    }}
  >
    <EditItemForm
      item={item}
      setIsVisibleModal={setIsVisibleModal}
      setReloadItems={setReloadItems}
      isEditingModal={false}
      handleDeleteItem={handleDeleteItem}
      handleActivateItem={handleActivateItem}
      handleDesactivateItem={handleDesactivateItem}
    />
    </Card>
    // <Col key={index} span={8}>
    // <Card
    //   // style={{
    //   //   width: 300,
    //   // }}
    //   cover={
    //     <img
    //       alt="example"
    //       src={imageUrl? imageUrl : NoImage}
    //       style={{ width: "100%", height: "200px", objectFit: "contain"}}
    //     />
    //   }
    //   actions={[
    //     <Button style={{ width: '100%' }}  type="link" onClick={()=>{handleEditItem(item)}} ghost><EditOutlined key="edit" /></Button>,
    //     <Button style={{ width: '100%' }}  type="link" onClick={()=>{handleDeleteItem(item)}} danger><DeleteOutlined key="delete" /></Button>,
    //   ]}
    // >
    //   <Meta
    //     title={item.name}
    //     description={item.description}
    //   />
    // </Card>
    // </Col>
  );
}
