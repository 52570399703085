import React, { useState, useEffect } from "react";
import {
  Form,
  //Input,
  //InputNumber,
  Select,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import { updateBillSellApi } from "../../../../api/billing/billsell";
import { getAccessToken } from "../../../../api/auth";
import { getStateBillsActiveApi } from "../../../../api/billing/stateBill";

import "./EditStateForm.scss";
import { convertState } from "../../../../utils/convert";
//import TextArea from "antd/lib/input/TextArea";

export default function EditStateForm(props) {
  const { billSell, setIsVisibleModal, setReloadBillSells } = props; //bpaid
  const [billSellData, setBillSellData] = useState({});
  const [activeStateBills, setActiveStateBills] = useState([]);
  const accessToken = getAccessToken();

  //trae los datos del Producto
  useEffect(() => {
    setBillSellData({
      id: billSell._id,
      state: billSell.state? billSell.state._id : null,
      //observation: billSell.observation,
      //paid: billSell.paid,
    });
  }, [billSell]);

  useEffect(() => {
    getStateBillsActiveApi(accessToken, true).then((response) => {
      setActiveStateBills(response.stateBills);
    });
  }, []);

  //console.log("billSellData")
  //console.log(billSellData)

  /*
  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!billSellData.state) {
      notification["error"]({
        message: "Cambiar el estado es obligatorio.",
      });
      errorExists = true;
    }
    return errorExists;
  };*/


  const updateStatePaid = async (p) => {
    //e.preventDefault();
    //const error = isFormValid();
    //if (!error) {
      const token = getAccessToken();
      let billSellUpdate = billSellData; //es let porque se va actualizando
      billSellUpdate.paid = p;

      await updateBillSellApi(token, billSellUpdate, billSell._id).then((result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //console.log("respuesta: " + result.message);
          setIsVisibleModal(false);
          setReloadBillSells(true);
        }
      });
    //}
  };

  return (
    <div className="edit-billsell-form">
      <EditForm
        billSellData={billSellData}
        //bpaid={bpaid}
        setBillSellData={setBillSellData}
        updateStatePaid={updateStatePaid}
        activeStateBills={activeStateBills}
      />
    </div>
  );
}

function EditForm(props) {
  const { updateStatePaid, billSellData, setBillSellData, activeStateBills} = props;
  const { Option } = Select;

  var options;
  // options = <Select
  //             name="state"
  //             value={convertState(billSellData.state)}
  //             onChange={(e) =>
  //             setBillSellData({ ...billSellData, state: e })
  //             }
  //             required
  //             >
  //             <Option value="valid">Vigente</Option>
  //             <Option value="rejected">Rechazada</Option>
  //             <Option value="cancelled">Cancelada</Option>
  //             <Option value="owes">Adeuda</Option>
  //           </Select>;

  options = <Select
              name="state"
              //value={convertState(buyBillData.state)}
              value={billSellData.state}
              onChange={(e) =>
                setBillSellData({ ...billSellData, state: e })
              }
              required
              >
              {activeStateBills
                ? activeStateBills.map((i) => {
                    return <Option key={i._id}>{i.name}</Option>;
                  })
                : "No hay estados disponibles"}
            </Select>;

  return (
    <Form className="form-edit">
      <Row gutter={24}>
        <Col span={24}>
          <label className="control-label">Modifique el estado de la venta</label>
          <Form.Item>
            {options}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item>
            <Button
              htmlType="submit"
              className="bg-success"
              onClick={() => updateStatePaid(true)}
            >
              Pagada
            </Button>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            <Button
              type="danger"
              onClick={() => updateStatePaid(false)}
            >
              No pagada
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}