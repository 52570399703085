import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import { TagOutlined } from "@ant-design/icons";
import { updateRoleApi } from "../../../../api/role";
import { getAccessToken } from "../../../../api/auth";

import "./EditRoleForm.scss";

export default function EditRoleForm(props) {
  const { role, setIsVisibleModal, setReloadRoles } = props;
  const [ roleData, setRoleData ] = useState({});

  //trae los datos de rol
  useEffect(() => {
    setRoleData({
      role_code: role.role_code,
      name: role.name,
      description: role.description,
    });
  }, [role]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!roleData.role_code || !roleData.name) {
      notification["error"]({
        message: "Obligatorios: Nombre.",
      });
      errorExists = true;
    }
    else {
      errorExists = false;
    }
    return errorExists;
  };

  const updateRole = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let roleUpdate = roleData; //es let porque se va actualizando

      updateRoleApi(token, roleUpdate, role._id).then((result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //console.log("respuesta: " + result.message);
          setIsVisibleModal(false);
          setReloadRoles(true);
        }
      });
    }
  };

  return (
    <div className="edit-role-form">
      <EditForm
        roleData={roleData}
        setRoleData={setRoleData}
        updateRole={updateRole}
        //modifDataToValid={modifDataToValid}
        //setModifDataToValid={setModifDataToValid}
        //validData={validData}
        //setValidData={setValidData}
      />
    </div>
  );
}

function EditForm(props) {
  const { roleData, setRoleData, updateRole } = props; //setModifDataToValid, modifDataToValid, validData, setValidData

  /*
  //validaciones de inputs
  const inputValidation = (e) => {
    const { name } = e.target; //type

    setRoleData({
      ...roleData,
      [name]: e.target.value,
    });
    setModifDataToValid({
      ...modifDataToValid,
      [name]: true,
    });
  };*/

  return (
    <Form className="form-edit" onSubmit={updateRole}>
      <Row gutter={24}>
        <Col span={12}>
          <label className="control-label">Código</label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Código"
              value={roleData.role_code}
              onChange={(e) =>
                setRoleData({ ...roleData, role_code: e })
              }
              min={1}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label"><span className="control-required">* </span>Nombre</label>
          <Form.Item>
            <Input
              placeholder="Nombre"
              value={roleData.name}
              onChange={(e) =>
                setRoleData({ ...roleData, name: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <label className="control-label">Descripción</label>
          <Form.Item>
            <Input
              //prefix={<EnvironmentOutlined />}
              placeholder="Descripción"
              name="description"
              value={roleData.description}
              onChange={(e) =>
                setRoleData({ ...roleData, description: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateRole}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
