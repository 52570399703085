import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getStateBillsActiveApi } from "../../../api/billing/stateBill";
import ListStateBills from "../../../components/Billing/StateBills/ListStateBills";


export default function StateBills() {
    const [stateBillsActive, setStateBillsActive] = useState([]);
    const [stateBillsInactive, setStateBillsInactive] = useState([]);
    const [reloadStateBills, setReloadStateBills ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getStateBillsActiveApi(token, true).then(response => {
            setStateBillsActive(response.stateBills);
        });
        getStateBillsActiveApi(token, false).then(response => {
            setStateBillsInactive(response.stateBills);
        });
        setReloadStateBills(false);
    }, [token, reloadStateBills]);

    return (
        <div className="statebills">

            <ListStateBills stateBillsActive={stateBillsActive} stateBillsInactive={stateBillsInactive} setReloadStateBills={setReloadStateBills}
                />

        </div>
    );
}